import React, {
  FC,
  PropsWithChildren,
  createContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import ModalWrapper from 'components/Modals/ModalWrapper';

interface ModalContextInterface {
  hideModal: () => void;
  isModalOpen: boolean;
  showModal: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.Component<any, any, any> | React.FC<any>,
    options: ModalProps
  ) => void;
  showLargeModal: (component: React.Component, options: ModalProps) => void;
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ModalContext = createContext<ModalContextInterface>(undefined!);

interface ModalProps {
  className?: string;
  icon?: React.JSX.Element;
  mobileFullView?: boolean;
  onClose?: (didClose: boolean) => void;
  preventOverlayClose?: boolean;
  showCloseIcon?: boolean;
  size?: 'small' | 'large';
  [key: string]: unknown;
}

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({});
  const componentRef = useRef<React.Component | null>(null);
  const { current: ModalComponent } = componentRef;
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });

  function showModal(component: React.Component, options: ModalProps = {}) {
    //! options.mobileFullView added here to allow scrolling on ios devices
    const mobileFullViewDesktop = options.mobileFullView && !isDesktop;
    if (options && !mobileFullViewDesktop) {
      disableBodyScroll(ModalWrapper);
    }
    componentRef.current = component;
    setIsModalOpen(true);
    setModalProps(options || {});
  }

  function showLargeModal(component: React.Component, options: ModalProps) {
    showModal(component, {
      ...options,
      mobileFullView: false,
      preventOverlayClose: true,
      showCloseIcon: true,
      size: 'large',
    });
  }

  function hideModal() {
    enableBodyScroll(ModalWrapper);
    setIsModalOpen(false);
  }

  const contextValues: ModalContextInterface = useMemo(
    () => ({ isModalOpen, showModal, showLargeModal, hideModal }),
    []
  );

  return (
    <ModalContext.Provider value={contextValues}>
      {children}
      {ModalComponent && isModalOpen && (
        <ModalWrapper
          className={modalProps.className}
          onClose={modalProps.onClose}
          showCloseIcon={modalProps.showCloseIcon}
          preventOverlayClose={modalProps.preventOverlayClose}
          mobileFullView={modalProps.mobileFullView}
          size={modalProps.size}
        >
          {/* eslint-disable-next-line  react/jsx-props-no-spreading */}
          <ModalComponent {...modalProps} />
        </ModalWrapper>
      )}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
