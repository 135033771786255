import styled from 'styled-components';
import { borderRadius, screen, space } from 'styles/variables';
import { label } from 'styles/types';
import { semanticColors } from 'styles/colors';
import { ReactComponent as IconArrowOutComp } from 'images/icon-arrow-out-sm.svg';
import StripeButton from 'containers/Restaurant/PaymentProcessing/Content/StripeButton';

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screen.smMin}) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space.x5};

  @media (min-width: ${screen.mdMin}) {
    max-width: 336px;
  }

  @media (min-width: ${screen.lgMin}) {
    margin-bottom: 0;
    max-width: 456px;
  }
`;

export const Header = styled.div`
  font: ${label.semi.x20};
  margin-bottom: ${space.x2};
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space.x2};
  margin-top: ${space.x3};

  button {
    width: 100%;
  }

  @media (min-width: ${screen.xsMin}) {
    flex-direction: row;

    button {
      width: 220px;
    }
  }
`;

export const HelpCard = styled.div`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  padding: ${space.x3};
  width: 100%;

  @media (min-width: ${screen.smMin}) {
    margin-left: ${space.x6};
    width: 375px;
  }
`;

export const HelpCardHeader = styled.h3`
  font: ${label.semi.x14};
  letter-spacing: 0.2px;
  margin-bottom: ${space.x2};
`;

export const OrderedList = styled.ol`
  margin-bottom: 0;
  padding-inline-start: 22px;
`;

export const ListItem = styled.li`
  list-style: numbered;
  margin: 0 0 ${space.x2} 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const IconArrowOut = styled(IconArrowOutComp)`
  margin-left: ${space.x1};

  path {
    fill: ${semanticColors.content.inversePrimary};
  }
`;

export const ActivateButton = styled(StripeButton)`
  @media (min-width: ${screen.lgMin}) {
    max-width: 220px;
  }
`;
