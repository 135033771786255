import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonSnippets from '../ButtonSnippets';

import styles from './styles.module.scss';

export default function ButtonSection({ companyId }) {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [buttonText, setButtonText] = useState('Order Online');
  const [textTransformCheckbox, setTextTransformCheckbox] = useState(true);
  const [outlineCheckbox, setOutlineCheckbox] = useState(false);
  const [buttonStyles, setButtonStyles] = useState({
    backgroundColor: '#0b2135',
    border: 'none',
    borderRadius: '4px',
    color: '#fff',
    textTransform: 'uppercase'
  });

  function toggleModal() {
    setIsModalShowing(!isModalShowing);
  }

  function updateText(e) {
    setButtonText(e.target.value);
  }

  function updateBackgroundColor(e) {
    setButtonStyles({
      ...buttonStyles,
      backgroundColor: e.target.value
    });
  }

  function updateFontColor(e) {
    const border =
      buttonStyles.border === 'none' ? 'none' : `solid 2px ${e.target.value}`;
    setButtonStyles({
      ...buttonStyles,
      border: border,
      color: e.target.value
    });
  }

  function updateTextTransform() {
    const textTransform =
      buttonStyles.textTransform === 'none' ? 'uppercase' : 'none';
    setTextTransformCheckbox(!textTransformCheckbox);
    setButtonStyles({
      ...buttonStyles,
      textTransform: textTransform
    });
  }

  function updateOutline() {
    const fontColor = outlineCheckbox
      ? '#fff'
      : `${buttonStyles.backgroundColor}`;
    const backgroundColor = outlineCheckbox
      ? `${buttonStyles.color}`
      : 'transparent';
    const border = outlineCheckbox
      ? 'none'
      : `solid 2px ${buttonStyles.backgroundColor}`;
    setButtonStyles({
      ...buttonStyles,
      backgroundColor: backgroundColor,
      border: border,
      color: fontColor
    });
    setOutlineCheckbox(!outlineCheckbox);
  }

  function updateBorderRadiusViaInput(e) {
    const inputValue = e.target.value;
    setButtonStyles({
      ...buttonStyles,
      borderRadius: inputValue
    });
  }

  return (
    <div className={styles.sectionContent}>
      {isModalShowing ? (
        <div>
          <div className={styles.modalOverlay} onClick={toggleModal} />
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={toggleModal}>
              <span role="img" aria-label="close">
                ✖️
              </span>
            </button>
            <div className={styles.buttonCard}>
              <span className={styles.subLabel}>Preview</span>
              <button
                className={styles.orderButton}
                style={{ ...buttonStyles }}
                disabled
              >
                {buttonText}
              </button>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputSection}>
                <label>Button Text</label>
                <input
                  type="text"
                  placeholder="Enter Button Text"
                  value={buttonText}
                  onChange={updateText}
                  className={styles.input}
                />
              </div>
              <div className={styles.checkboxSection}>
                <div>
                  <input
                    type="checkbox"
                    checked={textTransformCheckbox}
                    onChange={updateTextTransform}
                  />
                  <label>Uppercase</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={outlineCheckbox}
                    onChange={updateOutline}
                  />
                  <label>Outlined Style</label>
                </div>
              </div>
              <div className={styles.inputSection}>
                <label>Background Color</label>
                <input
                  type="text"
                  placeholder="Enter Background Color"
                  value={buttonStyles.backgroundColor}
                  onChange={updateBackgroundColor}
                  className={styles.input}
                />
              </div>
              <div className={styles.inputSection}>
                <label>Font Color</label>
                <input
                  type="text"
                  placeholder="Enter Font Color"
                  value={buttonStyles.color}
                  onChange={updateFontColor}
                  className={styles.input}
                />
              </div>
              <div className={styles.inputSection}>
                <label>Border Radius</label>
                <input
                  type="text"
                  placeholder="Enter Border Radius"
                  value={buttonStyles.borderRadius}
                  onChange={updateBorderRadiusViaInput}
                  className={styles.input}
                />
              </div>
              <div className={styles.borderRadiusOptions}>
                <button
                  onClick={() =>
                    setButtonStyles({ ...buttonStyles, borderRadius: '0' })
                  }
                >
                  Square
                </button>
                <button
                  style={{ borderRadius: '4px' }}
                  onClick={() =>
                    setButtonStyles({
                      ...buttonStyles,
                      borderRadius: '4px'
                    })
                  }
                >
                  Rounded
                </button>
                <button
                  style={{ borderRadius: '50px' }}
                  onClick={() =>
                    setButtonStyles({
                      ...buttonStyles,
                      borderRadius: '50px'
                    })
                  }
                >
                  Pill
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className={styles.buttonCard}>
        <button
          onClick={toggleModal}
          className={styles.orderButton}
          style={{ ...buttonStyles }}
        >
          Design Me
        </button>
      </div>
      <ButtonSnippets
        companyId={companyId}
        buttonStyles={buttonStyles}
        buttonText={buttonText}
      />
    </div>
  );
}

ButtonSection.propTypes = {
  companyId: PropTypes.string
};
