import React, { useEffect, useState, ReactElement } from 'react';
import { Checkbox } from '@chownow/cn-web-components';
import { FilterProps } from '../Filter';
import { InputType } from '../FilterTypes';

import {
  Filters,
  SelectAll,
  Label,
  StyledHeadingLabel,
  Filter,
  Input,
} from './styles';

const SELECT_ALL = 'Select All';
export interface FilterListProps {
  /** If true, show checkboxes, else show radio buttons */
  allowMultipleFilters?: boolean;
  /* A Filter component */
  children: ReactElement<FilterProps> | Array<ReactElement<FilterProps>>;
  /** Optional CSS class to be applied */
  className?: string;
  /** A key for grouping selected filters and also for naming radio button inputs. */
  filterCategory?: string;
  /** Label used for More Filter Button */
  label?: string;
  /** Pass the filter type back to parent FilterButton */
  setInputType?: (inputType: InputType) => void;
  /** show the select all option if allowMultipleFilters === true */
  showSelectAll?: boolean;
}

const FilterList = ({
  allowMultipleFilters = false,
  children,
  className = '',
  filterCategory = undefined,
  label = undefined,
  setInputType = () => { /* Default empty function */ },
  showSelectAll = false,
}: FilterListProps) => {
  const [selectedFilter, setSelectedFilter] = useState(false);
  const inputType = allowMultipleFilters ? 'checkbox' : 'radio';
  const filterCat = filterCategory || label;

  useEffect(() => {
    setInputType(inputType);
  }, [inputType]);

  const onSelectAll = () => {
    setSelectedFilter(!selectedFilter);
  };

  return (
    <>
      {label && <StyledHeadingLabel>{label}</StyledHeadingLabel>}
      <Filters className={className}>
        {showSelectAll && allowMultipleFilters && (
          <SelectAll>
            <Filter>
              <Label htmlFor={SELECT_ALL}>
                <Input>
                  <Checkbox
                    id={SELECT_ALL}
                    name={SELECT_ALL}
                    value={SELECT_ALL}
                    onChange={onSelectAll}
                  />
                </Input>
                {SELECT_ALL}
              </Label>
            </Filter>
          </SelectAll>
        )}
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { inputType, filterCategory: filterCat, selectedFilter })
        )}
      </Filters>
    </>
  );
};

export default FilterList;
