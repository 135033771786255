import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { screen, space, zIndex } from 'styles/variables';
import Input from 'components/Input';
import Button from '@chownow/cocina-react-button';
import { label, paragraph } from 'styles/types';
import { Modal } from '@chownow/cn-web-components';

const dividerStyles = `
  background-color: ${semanticColors.border.primary};
  height: 1px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleDivider = styled.div`
  ${dividerStyles};

  margin-top: ${space.x3};
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x2};

  > * {
    flex: 1 1 calc(33.33% - ${space.x2});
    min-width: 0;
  }

  &&& > div > label {
    background-color: ${semanticColors.surface.primary};
  }
`;

export const StyledInput = styled(Input)`
  margin-top: ${space.x5};

  /* prevents content shift when error message is displayed */
  min-height: 88px;
`;

export const ApplyButton = styled(Button)`
  margin-top: ${space.x2};
  width: 100%;
`;

export const ApplyButtonContainer = styled.div`
  margin: 0 ${space.x2} ${space.x2} ${space.x2};
`;

export const ApplyButtonTopDivider = styled.div`
  ${dividerStyles};

  margin-top: ${space.x2};
`;

export const StyledModal = styled(Modal)`
  &&& {
    background-color: ${semanticColors.surface.primary};
    position: fixed;

    > button {
      right: ${space.x5};
      top: ${space.x3};
    }

    > div {
      padding: ${space.x3} 0 0 0;
    }

    @media (max-width: ${screen.xsMax}) {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: unset;
    }
  }

  /* Cover component has a z-index of 1 which is making the "Accept or Cancel" button show ontop of the Modal */
  z-index: ${zIndex.modalBase};
`;

export const Title = styled.h1`
  color: ${semanticColors.content.primary};
  font: ${label.semi.x20};
  margin-left: ${space.x5};
`;

export const Subtitle = styled.h2`
  color: ${semanticColors.content.primary};
  font: ${paragraph.reg.x14};
  margin: ${space.x5} 0 ${space.x2};
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${space.x5};
`;
