import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const cardStyles = `
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  color: ${semanticColors.content.primary};
  display: flex;
  flex-direction: column;
  padding: ${space.x5};
  text-align: left;
`;

export const CheckboxRow = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${space.x3};
`;

export const CheckboxText = styled.p`
  font: ${label.reg.x14};
  margin-left: ${space.x1};
`;

export const Heading = styled.h2`
  font: ${label.semi.x16};
  width: 100%;
`;

export const PreviouslySubmittedText = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${label.md.x16};
  margin: ${space.x3} 0 0;
`;

export const Subtext = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x1} 0 0;
`;
