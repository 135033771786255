import styled from 'styled-components';
import { label } from 'styles/types';
import { containerWidths, screen, space } from 'styles/variables';
import { Button } from '@chownow/cn-web-components';

export const ErrorTitle = styled.p`
  font: ${label.semi.x20};
  margin: 0;
`;

export const ErrorMessage = styled.p`
  font: ${label.reg.x16};
  margin-top: ${space.x2};
  max-width: ${containerWidths.containerWidthNarrow};
  text-align: center;
`;

export const RefreshPageButton = styled(Button)`
  margin-top: ${space.x3};
  width: 100%;

  @media (min-width: ${screen.xsMin}) {
    max-width: 220px;
  }
`;

export const ErrorContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
