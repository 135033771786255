export interface LogoFile {
  filename: string;
  file_id: string;
  key: string;
  upload_status: 'uploaded' | 'upload_failed';
  created_at: string | number;
  updated_at: string | number;
}

export interface HasLogoResponse {
  // Accounting for the id property added by requestCustomApi -
  // this allows us to remove it before passing it to the UI
  id?: number;
  has_logo: true;
  logo_file: LogoFile;
}

export interface NoLogoResponse {
  id?: number;
  has_logo: false;
  logo_file: null;
}

export type LogoEmptyResponse = {
  id?: number;
};

export interface Logo400Response {
  id?: number;
  code: string;
  message: string;
  data_path: string;
  data_value: string;
  definition: Record<string, never>;
  validation_message: string;
}

export interface Logo404Response {
  id?: number;
  message: string;
}

export interface Logo500Response {
  id?: number;
  code: string;
  message: string;
}

export type LogoGetErrorResponse = Logo400Response | Logo404Response | Logo500Response;

export type LogoGetPreviouslySubmittedResponse = HasLogoResponse | NoLogoResponse;

export type LogoGetSuccessResponse = HasLogoResponse | NoLogoResponse | LogoEmptyResponse;

export type LogoGetResponse =
  LogoGetSuccessResponse |
  LogoGetErrorResponse;

export const isLogo400Response =
  (response: LogoGetResponse): response is Logo400Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&

    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.prototype.hasOwnProperty.call(response, 'data_path') &&
    Object.prototype.hasOwnProperty.call(response, 'data_value') &&
    Object.prototype.hasOwnProperty.call(response, 'definition') &&
    Object.prototype.hasOwnProperty.call(response, 'validation_message')
  );

export const isLogo404Response =
  (response: LogoGetResponse): response is Logo404Response => (
    Object.prototype.hasOwnProperty.call(response, 'message') && Object.keys(response).length === 1
  );

export const isLogo500Response =
  (response: LogoGetResponse): response is Logo500Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&
    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.keys(response).length === 2
  );

export const isGetLogoPreviouslySubmittedResponse =
  (response: LogoGetResponse | undefined): response is LogoGetPreviouslySubmittedResponse => (
    !!response &&
    Object.prototype.hasOwnProperty.call(response, 'has_logo') &&
    Object.prototype.hasOwnProperty.call(response, 'logo_file')
  );
