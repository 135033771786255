import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useGetRestaurantHours } from 'api/onboarding';

import { RestaurantHoursContext, RestaurantHoursContextValue } from '.';
import {
  areNoHoursSelected,
  generateDefaultHoursState,
  transformRestaurantHoursGetData,
  updateDayHours,
} from '../helpers';
import { DaysOfTheWeek, HoursSection } from '../constants';
import { HoursData } from '../types';

interface RestaurantHoursContextProviderProps extends PropsWithChildren {
  uuid: string;
}

export const RestaurantHoursContextProvider: FC<
  RestaurantHoursContextProviderProps
> = ({ children, uuid }) => {
  const { hasGetRequestError, isGetRequestLoading, getRequestResponse } =
    useGetRestaurantHours(uuid);
  const [hasPreviouslySubmittedHours, setHasPreviouslySubmittedHours] =
    useState<boolean>(false);
  const [deliveryHours, setDeliveryHours] = useState<Map<string, HoursData>>(
    generateDefaultHoursState()
  );
  const [pickupHours, setPickupHours] = useState<Map<string, HoursData>>(
    generateDefaultHoursState()
  );
  const [isSameHours, setIsSameHours] = useState<boolean>(false);
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [isDeliveryEnabled, setIsDeliveryEnabled] = useState<boolean>(false);
  const [isPickupEnabled, setIsPickupEnabled] = useState<boolean>(false);

  useEffect(() => {
    const isResponseDeliveryEnabled =
      getRequestResponse?.schedule?.delivery?.is_enabled ?? false;
    const responseDeliveryHours = getRequestResponse?.schedule?.delivery?.hours;

    const isResponsePickupEnabled =
      getRequestResponse?.schedule?.pickup?.is_enabled ?? false;
    const responsePickupHours = getRequestResponse?.schedule?.pickup?.hours;

    const hasExistingHours = !isEmpty(getRequestResponse?.schedule);

    if (hasExistingHours) {
      setIsDeliveryEnabled(isResponseDeliveryEnabled);
      setIsPickupEnabled(isResponsePickupEnabled);

      setDeliveryHours(currentDeliveryHours =>
        transformRestaurantHoursGetData(
          isResponseDeliveryEnabled,
          responseDeliveryHours,
          currentDeliveryHours
        )
      );
      setPickupHours(currentPickupHours =>
        transformRestaurantHoursGetData(
          isResponsePickupEnabled,
          responsePickupHours,
          currentPickupHours
        )
      );
    }

    setHasPreviouslySubmittedHours(hasExistingHours);
  }, [getRequestResponse]);

  useEffect(() => {
    const areNoPickupHoursSelected = areNoHoursSelected(pickupHours);
    const areNoDeliveryHoursSelected = areNoHoursSelected(deliveryHours);

    if (
      isDeliveryEnabled &&
      isPickupEnabled &&
      areNoPickupHoursSelected &&
      areNoDeliveryHoursSelected
    ) {
      setIsFormCompleted(false);
    } else if (
      !isDeliveryEnabled &&
      isPickupEnabled &&
      areNoPickupHoursSelected
    ) {
      setIsFormCompleted(false);
    }
  }, [deliveryHours, pickupHours, isDeliveryEnabled, isPickupEnabled]);

  const resetAllHours = () => {
    setDeliveryHours(generateDefaultHoursState());
    setPickupHours(generateDefaultHoursState());
    setIsFormCompleted(false);
  };

  const setDayClosed = (day: string, hoursSection: string) => {
    const updatedClosedData: HoursData = {
      is_closed: true,
      ranges: [],
    };

    if (hoursSection === HoursSection.Pickup) {
      setPickupHours(prevHours =>
        updateDayHours(day, prevHours, updatedClosedData)
      );
    } else if (hoursSection === HoursSection.Delivery) {
      setDeliveryHours(prevHours =>
        updateDayHours(day, prevHours, updatedClosedData)
      );
    } else if (hoursSection === HoursSection.PickupDelivery) {
      setPickupHours(prevHours =>
        updateDayHours(day, prevHours, updatedClosedData)
      );
      setDeliveryHours(prevHours =>
        updateDayHours(day, prevHours, updatedClosedData)
      );
    }

    setIsFormCompleted(true);
  };

  const setDayOpen = (day: string, hoursSection: string) => {
    const updatedResetData: HoursData = {
      is_closed: false,
      ranges: [
        {
          start: '',
          end: '',
        },
      ],
    };

    if (hoursSection === HoursSection.Pickup) {
      setPickupHours(prevHours =>
        updateDayHours(day, prevHours, updatedResetData)
      );
    } else if (hoursSection === HoursSection.Delivery) {
      setDeliveryHours(prevHours =>
        updateDayHours(day, prevHours, updatedResetData)
      );
    } else if (hoursSection === HoursSection.PickupDelivery) {
      setPickupHours(prevHours =>
        updateDayHours(day, prevHours, updatedResetData)
      );
      setDeliveryHours(prevHours =>
        updateDayHours(day, prevHours, updatedResetData)
      );
    }

    setIsFormCompleted(true);
  };

  const getDayStartTime = (
    day: string,
    hoursSection: string
  ): string | undefined =>
    (hoursSection === HoursSection.Pickup
      ? pickupHours.get(day)?.ranges[0]?.start
      : deliveryHours.get(day)?.ranges[0]?.start);

  const getDayEndTime = (
    day: string,
    hoursSection: string
  ): string | undefined =>
    (hoursSection === HoursSection.Pickup
      ? pickupHours.get(day)?.ranges[0]?.end
      : deliveryHours.get(day)?.ranges[0]?.end);

  const getDayIsCopyable = (day: string, hoursSection: string): boolean =>
    (hoursSection === HoursSection.Pickup
      ? !!pickupHours.get(day)?.is_copyable
      : !!deliveryHours.get(day)?.is_copyable);

  const setDayStartTime = (
    day: string,
    hoursSection: string,
    startTime: string
  ) => {
    if (hoursSection === HoursSection.Pickup) {
      setPickupHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: startTime,
              end: prevHours.get(day)?.ranges[0]?.end ?? '',
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    }

    if (hoursSection === HoursSection.Delivery) {
      setDeliveryHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: startTime,
              end: prevHours.get(day)?.ranges[0]?.end ?? '',
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    }

    if (hoursSection === HoursSection.PickupDelivery) {
      setPickupHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: startTime,
              end: prevHours.get(day)?.ranges[0]?.end ?? '',
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });

      setDeliveryHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: startTime,
              end: prevHours.get(day)?.ranges[0]?.end ?? '',
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    }

    setIsFormCompleted(true);
  };

  const setDayEndTime = (
    day: string,
    hoursSection: string,
    endTime: string
  ) => {
    if (hoursSection === HoursSection.Pickup) {
      setPickupHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: prevHours.get(day)?.ranges[0]?.start ?? '',
              end: endTime,
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    } else if (hoursSection === HoursSection.Delivery) {
      setDeliveryHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: prevHours.get(day)?.ranges[0]?.start ?? '',
              end: endTime,
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    } else if (hoursSection === HoursSection.PickupDelivery) {
      setPickupHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: prevHours.get(day)?.ranges[0]?.start ?? '',
              end: endTime,
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });

      setDeliveryHours(prevHours => {
        const updatedData: HoursData = {
          is_closed: false,
          ranges: [
            {
              start: prevHours.get(day)?.ranges[0]?.start ?? '',
              end: endTime,
            },
          ],
        };

        return updateDayHours(day, prevHours, updatedData);
      });
    }
  };

  const checkDayCopyable = (day: string | null, hoursSection: string) => {
    const updateFunction = (prevHours: Map<string, HoursData>) => {
      let newHours = prevHours;
      Array.from(prevHours.keys()).forEach(d => {
        const is_closed = prevHours.get(d)?.is_closed;
        const start = prevHours.get(d)?.ranges[0]?.start;
        const end = prevHours.get(d)?.ranges[0]?.end;
        const updatedData: HoursData = {
          is_closed,
          is_copyable: !is_closed && !!start && !!end && day === d,
          ranges: [
            {
              start: start ?? '',
              end: end ?? '',
            },
          ],
        };
        newHours = updateDayHours(d, newHours, updatedData);
      });
      return newHours;
    };

    if (
      hoursSection === HoursSection.Pickup ||
      hoursSection === HoursSection.PickupDelivery
    ) {
      setPickupHours(updateFunction);
    }

    if (hoursSection === HoursSection.Delivery ||
        hoursSection === HoursSection.PickupDelivery
    ) {
      setDeliveryHours(updateFunction);
    }
  };

  const copyDayToAll = (day: string, hoursSection: string) => {
    const updateFunction = (prevHours: Map<string, HoursData>) => {
      let newHours = prevHours;
      const hoursData = prevHours.get(day);

      if (hoursData && hoursData?.is_copyable) {
        const copyData = { ...hoursData, is_copyable: false };

        Array.from(Object.values(DaysOfTheWeek)).forEach(d => {
          if (d !== day && !prevHours.get(d)?.is_closed) {
            newHours = updateDayHours(d, newHours, copyData);
          }
        });
      }
      return newHours;
    };

    if (
      hoursSection === HoursSection.Pickup ||
      hoursSection === HoursSection.PickupDelivery
    ) {
      setPickupHours(updateFunction);
    }
    if (hoursSection === HoursSection.Delivery ||
        hoursSection === HoursSection.PickupDelivery
    ) {
      setDeliveryHours(updateFunction);
    }
  };

  const RestaurantHoursValue = useMemo<RestaurantHoursContextValue>(
    () => ({
      checkDayCopyable,
      copyDayToAll,
      deliveryHours,
      getDayEndTime,
      getDayStartTime,
      getDayIsCopyable,
      hasGetRequestError,
      hasPreviouslySubmittedHours,
      isDeliveryEnabled,
      isFormCompleted,
      isGetRequestLoading,
      isPickupEnabled,
      isSameHours,
      pickupHours,
      setDayOpen,
      resetAllHours,
      setDayClosed,
      setDayEndTime,
      setDayStartTime,
      setDeliveryHours,
      setIsDeliveryEnabled,
      setIsPickupEnabled,
      setIsSameHours,
      setPickupHours,
      uuid,
    }),
    [
      checkDayCopyable,
      copyDayToAll,
      deliveryHours,
      getDayEndTime,
      getDayStartTime,
      getDayIsCopyable,
      hasGetRequestError,
      hasPreviouslySubmittedHours,
      isDeliveryEnabled,
      isFormCompleted,
      isGetRequestLoading,
      isPickupEnabled,
      isSameHours,
      pickupHours,
      setDayOpen,
      resetAllHours,
      setDayClosed,
      setDayEndTime,
      setDayStartTime,
      setDeliveryHours,
      setIsDeliveryEnabled,
      setIsPickupEnabled,
      setIsSameHours,
      setPickupHours,
      uuid,
    ]
  );

  return (
    <RestaurantHoursContext.Provider value={RestaurantHoursValue}>
      {children}
    </RestaurantHoursContext.Provider>
  );
};

export const useRestaurantHoursContext = () =>
  useContext(RestaurantHoursContext);
