import { formatError, request } from 'helpers/api';

/**
 * Order refund by partial amount
 * @param  {string} orderId - ChowNow Order ID
 * @param  {string} payload - { name, subtotal, tax, delivery_fee, fee, tip, refund_type }
 */
export async function refundOrder(orderId, payload) {
  const requestBody = {
    ...payload,
    // The version number will be fixed in a future release
    app_version: '1.0',
  };

  try {
    return await request(`order/${orderId}/refund`, requestBody, 'POST');
  } catch (err) {
    return formatError(err);
  }
}
