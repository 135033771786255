import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { space } from 'styles/variables';
import Button from '@chownow/cocina-react-button';

const dividerStyles = `
  background-color: ${semanticColors.border.primary};
  height: 1px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleDivider = styled.div`
  ${dividerStyles};

  margin-top: ${space.x3};
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x2};

  > :first-child, > :nth-child(2) {
    flex-basis: calc(50% - 8px);
  }
`;

export const ButtonGroupExtraTime = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x2};

  > * {
    flex: 1;
  }
`;

export const AcceptButton = styled(Button)`
  margin-top: ${space.x2};
  width: 100%;
`;

export const AcceptButtonTopDivider = styled.div`
  ${dividerStyles};

  margin-top: ${space.x5};
`;
