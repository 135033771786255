import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

import DialogModal from 'components/Modals/DialogModal';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';
import { ADMIN_PRIVILEGES_REQUIRED, formatError, request } from 'helpers/api';
import { initLaunchDarklyUser } from 'helpers/featureFlags';
import { getUrlParam } from 'helpers/url';
import { getDisplayName } from 'helpers/util';

/**
 * For Required auth scenarios, keep in mind this will be handled inherintly in the Python Admin app as
 * well at a layer above this embedded UI but should also be enforced here as well.
 */
const withUser = (WrappedComponent, isRequired) => {
  function WithUser(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { showModal } = useContext(ModalContext);
    const { user, setUser } = useContext(UserContext);
    const flags = useFlags();
    const currentURL = window.location.href;
    const { ldClient } = props;
    const restaurantId = getUrlParam('restaurant');
    const companyId = getUrlParam('company');

    useEffect(() => {
      if (user) return;

      request('user/me')
        .then(userData => {
          setUser(userData);
          initHeapUser(userData);
          setIsLoading(false);
          handleRequiredAuthCheck(userData);
        })
        .catch(err => {
          const errors = formatError(err);

          console.warn('User Fetch Error: ', err);
          setUser({});
          setIsLoading(false);
          handleRequiredAuthCheck(errors);
        });
    }, []);

    useEffect(() => {
      if (user) {
        // add the currentRestaurant custom attribute
        user.currentRestaurant = restaurantId;
        user.company = companyId;
        initLaunchDarklyUser(user, ldClient);
      }
    }, [user]);

    useEffect(() => {
      if (ldClient && user && flags.allowPendo) {
        initPendoUser(user, restaurantId);
      }
    }, [ldClient, user, restaurantId]);

    // Associates ChowNow user_id to Heap identity
    function initHeapUser({
      id,
      company_id,
      group_id,
      is_chownow_admin,
      is_region_admin,
      is_super_admin,
    }) {
      // TODO: Add heap.resetIdentity() on logout feature
      window.heap.identify(id);
      window.heap.addUserProperties({
        company_id,
        group_id,
        is_chownow_admin,
        is_region_admin,
        is_super_admin,
      });
    }

    function initPendoUser(
      { id, email, first_name, last_name, group_id, company_id },
      restaurantId
    ) {
      const isReady = window.pendo?.isReady();

      // Add Google Address API script with dynamic key
      const script = document.createElement('script');
      const apiKey = process.env.REACT_APP_PENDO_API_KEY;
      script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (!isReady) {
          window.pendo?.initialize({
            visitor: {
              id: id,
              email: email,
              full_name: `${first_name} ${last_name}`,
              role: group_id,
              hq_id: company_id,
              location_id: restaurantId,
            },
          });
        } else {
          window.pendo?.updateOptions({
            visitor: {
              id: id,
              email: email,
              full_name: `${first_name} ${last_name}`,
              role: group_id,
              hq_id: company_id,
              location_id: restaurantId,
            },
          });
        }
      };

      return () => {
        document.head.removeChild(script);
      };
    }

    function handleRequiredAuthCheck(userData) {
      const userId = get(userData, 'id');
      const error = get(userData, 'errors[0]');
      /**
       * If auth is required and the user is not logged in, re-direct them to the login page w/ the next
       * param set to bring them back to the same page after successful login.
       *
       * TODO: CN-7206 - User access permissions based on allowed resto / company ids for non-CN Admins
       */

      if (isRequired && !userId) {
        if (error.code === ADMIN_PRIVILEGES_REQUIRED) {
          window.location.replace(
            `/login?next=${encodeURIComponent(currentURL)}`
          );
        } else {
          showModal(DialogModal, {
            title: 'Something went wrong.',
            message: 'Failed to fetch data',
            onClose: () => window.location.reload(),
          });
        }
      }
    }

    const userId = get(user, 'id');

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!userId && !isLoading && isRequired) {
      /**
       * If auth is required and user is not logged in, do not render UI while re-direct is taking
       * place to avoid initializing any components requiring a user when there isn't one.
       */
      return null;
    }

    return <WrappedComponent {...props} />;
  }

  WithUser.displayName = `WithUser(${getDisplayName(WrappedComponent)})`;

  WithUser.propTypes = {
    ldClient: PropTypes.shape({}),
  };

  return withLDConsumer()(WithUser);
};

export default withUser;
