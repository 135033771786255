import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { addMinutes, format, isToday } from 'date-fns';

/**
 * If the date is today, output will display: "12:30PM, Today"
 * If the date is within the last 7 days, output will display: "12:30PM, Tue" with the 3 letter abreviation for the day of the week
 * If the date is in the future or before the last 7 days, output will display: "12:30PM, 10/31/2023" with the full date
 *
 * @param {Date | null} dateTime - The Date object to be formatted
 */
export const formatDate = (dateTime: Date | null) => {
  const sevenDaysBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const todaysDate: Date = new Date(Date.now());
  let dateFormat = 'h:mm a, MM/dd/yyyy';

  if (dateTime === null) {
    return '';
  } else if (isToday(dateTime)) {
    dateFormat = "h:mm a, 'Today'";
  } else if (
    // if date is in the future or before the last 7 days, show full date
    dateTime > todaysDate ||
    dateTime <= sevenDaysBefore
  ) {
    dateFormat = 'h:mm a, MM/dd/yyyy';
  } else {
    // if date is within the last 7 days, show 3 letter abreviation for the day of the week
    dateFormat = 'h:mm a, E';
  }

  return format(dateTime, dateFormat);
};

/**
 * @param {string} timestamp - The timestamp in standardized ISO 8601-format YYYY-MM-DDThh:mm:ss.sssZ i.e. 2023-10-25T00:47:00.000Z
 */
export const formatISODateTime = (timestamp: string) => {
  try {
    return formatDate(new Date(timestamp));
  } catch {
    return '';
  }
};

/**
 * @param {string} timestamp - The timestamp in the format of %Y%m%d%H%M i.e. 202310311230
 */
export const formatDateTime = (timestamp: string) => (
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
  timestamp === '' ? '' : formatDate(getDateForApiDateStr(timestamp))
);

/**
 * @param {string} timestamp - The timestamp in the format of YYYYMMDDHHMM e.g. 202310311230
 */
export function formatOrderDate(timestamp: string) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    const getDateTime = getDateForApiDateStr(timestamp) as Date;
    if (Number.isNaN(getDateTime.getTime())) {
      throw new Error('Invalid date');
    }
    const orderDate = format(getDateTime, 'EEE, MMM d');
    return orderDate;
  } catch (error) {
    return timestamp;
  }
}

/**
 * Converts a timestamp from YYYYMMDDHHMM format to a formatted time string (e.g., 8:30 AM).
 * @param {string} timestamp - The timestamp in the format of YYYYMMDDHHMM, e.g. 202403010830
 * @returns {string} The formatted time string: 8:30 AM.
 */
export function formatOrderTime(timestamp: string): string {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    const date = getDateForApiDateStr(timestamp) as Date;
    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    const orderTime = format(date, 'h:mm a');
    return orderTime;
  } catch (error) {
    return timestamp;
  }
}

export const getCurrentTimestamp = () => {
  const currentTime = new Date(Date.now());
  const currentMonth = currentTime.getMonth() + 1;
  const monthString =
    currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
  const dateString =
    currentTime.getDate() < 10
      ? `0${currentTime.getDate()}`
      : `${currentTime.getDate()}`;
  const hoursString =
    currentTime.getHours() < 10
      ? `0${currentTime.getHours()}`
      : `${currentTime.getHours()}`;
  const minutesString =
    currentTime.getMinutes() < 10
      ? `0${currentTime.getMinutes()}`
      : `${currentTime.getMinutes()}`;

  return `${currentTime.getFullYear()}${monthString}${dateString}${hoursString}${minutesString}`;
};

/**
 * @param {string} timestamp - The timestamp in the format of YYYYMMDDHHMM e.g. 202310311230
 * @param {string} offsetMinutes - The offset in minutes to add to the timestamp
 * @returns {string} The formatted time string that includes the offset time: 8:30 AM.
 */
export const formatRequestedTimeWithOffset = (
  timestamp: string,
  offsetMinutes: string
): string => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    const originalDate = getDateForApiDateStr(timestamp) as Date;
    if (Number.isNaN(originalDate.getTime())) {
      throw new Error('Invalid date');
    }
    const offset = parseInt(offsetMinutes, 10);
    if (Number.isNaN(offset)) {
      throw new Error('Invalid offset');
    }
    const adjustedDate = addMinutes(originalDate, offset);
    return format(adjustedDate, 'h:mm a').toUpperCase();
  } catch (error) {
    return timestamp;
  }
};

/**
 * @returns {string} The formatted time string: 8:30 AM.
 */
export const getCurrentTime = (): string => format(new Date(), 'h:mm a');
