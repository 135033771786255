export const transformHours12To24 = (time: string, addSpace: boolean | null = false) => {
  if (time) {
    const suffix = time.slice(-2).toUpperCase();
    const timeArr = time.slice(0, -2).split(':');
    const numericHour = Number(timeArr[0]);

    if (suffix === 'AM' && timeArr[0] === '12') {
      timeArr[0] = '00';
    } else if (suffix === 'AM' && numericHour < 10) {
      timeArr[0] = `0${numericHour}`;
    } else if (suffix === 'PM') {
      timeArr[0] = ((numericHour % 12) + 12).toString().padStart(2, '0');
    }

    const formattedTime = timeArr.join(':') + (addSpace ? ' ' : '');
    return formattedTime;
  } else {
    return '';
  }
};
