import React, { FC } from 'react';

import { InputCard, StyledInput } from './styles';

interface SalesTaxInputProps {
  invalidSalesTaxMessage: string;
  salesTaxInputValue: string | undefined;
  onInputChange: (value: string) => void;
}

const SalesTaxInput: FC<SalesTaxInputProps> = ({
  invalidSalesTaxMessage,
  salesTaxInputValue,
  onInputChange,
}) => (
  <InputCard>
    <StyledInput
      id="sales-tax-input"
      label="Sales Tax Rate (%)"
      name="salesTax"
      onChange={value => onInputChange(value.trim())}
      value={salesTaxInputValue}
      placeholder="e.g. 7.5"
      inputMode="decimal"
      dataTestId="sales-tax-input"
      errorText={invalidSalesTaxMessage}
    />
  </InputCard>
);

export default SalesTaxInput;
