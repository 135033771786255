import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { format, isAfter } from 'date-fns';

import LegacyFieldset from 'components/LegacyFieldset';

import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { stripeProcessorsType } from 'helpers/prop-types';
import { request } from 'helpers/api';
import { PAYMENT_PROCESSORS } from 'helpers/payments';

import styles from './styles.module.scss';

export default function LegacyStripeProcessor({
  restaurantId,
  stripeProcessor,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateButtonLoading, setIsCreateButtonLoading] = useState(false);

  const handleCreateStripeAccountClick = async () => {
    if (isCreateButtonLoading) {
      return;
    }
    setIsCreateButtonLoading(true);

    try {
      const results = await request(
        `restaurant/${restaurantId}/stripe-account`,
        {},
        'POST'
      );
      window.location.reload();
      setIsCreateButtonLoading(false);
    } catch (err) {
      setIsCreateButtonLoading(false);
    }
  };

  function handleVerificationFormClick() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // Check if URL is expired and re-generate if so.
    if (
      isAfter(
        new Date(),
        new Date(stripeProcessor.hosted_onboarding.expires_at)
      )
    ) {
      request(`restaurant/${restaurantId}/merchant-account`)
        .then(merchantAccount => {
          const stripeProcessor = find(merchantAccount.payment_processors, {
            id: PAYMENT_PROCESSORS.STRIPE,
          });

          // Re-direct to Stripe Hosted Onboarding
          window.top.location.href = stripeProcessor.hosted_onboarding.url;
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      // Re-direct to Stripe Hosted Onboarding
      window.top.location.href = stripeProcessor.hosted_onboarding.url;
    }
  }

  return (
    <>
      <legend>Stripe</legend>
      {!stripeProcessor ? (
        <div className="line">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleCreateStripeAccountClick}
            disabled={isCreateButtonLoading}
          >
            Create Stripe Account
          </button>
        </div>
      ) : (
        <>
          <div className="line">
            <label>Account Information: </label>
            <span>
              <a
                href={`${process.env.REACT_APP_ADMIN_PYTHON_APP_URL}/restaurant/${restaurantId}/verification`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Verify Account
              </a>
            </span>
          </div>
          <LegacyFieldset
            inputLabel="Account ID"
            value={stripeProcessor.x_id}
          />
          <LegacyFieldset
            inputLabel="Account Status"
            value={stripeProcessor.x_status}
          />
          <LegacyFieldset
            inputLabel="Activation Date"
            value={
              stripeProcessor.activation_date &&
              format(
                getDateForApiDateStr(stripeProcessor.activation_date),
                'MMM do, yyyy h:mm a'
              )
            }
          />
          <LegacyFieldset
            inputLabel="Last Update"
            value={
              stripeProcessor.updated_at &&
              format(
                getDateForApiDateStr(stripeProcessor.updated_at),
                'MMM do, yyyy h:mm a'
              )
            }
          />
          {stripeProcessor.cdd_required && !stripeProcessor.cdd_compliant && (
            <div className={styles.verificationFormBtnWrapper}>
              <button
                className="btn btn-primary"
                onClick={handleVerificationFormClick}
                type="button"
                disabled={isLoading}
              >
                Verification Form
              </button>
              {isLoading && <div className="loading-spinner" />}
            </div>
          )}
        </>
      )}
    </>
  );
}

LegacyStripeProcessor.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  stripeProcessor: stripeProcessorsType,
};
