import React from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './styles.module.scss';

import { restaurantType } from 'helpers/prop-types';
import Outlink from 'components/Outlink';

function OrdersUnauthorized(props) {
  const { restaurant } = props;

  return (
    <div className={styles.container}>
      <Helmet title="Unauthorized" />
      <h1 className={styles.icon}>
        <span role="img" aria-label="access prohibited">
          🚫
        </span>
      </h1>
      <p className={styles.message}>
        <strong>ChowNow internal users:</strong> this page is not yet supported
        for your account type.
        <br />
        Visit
        <Outlink
          className={styles.outlink}
          to={`
              ${process.env.REACT_APP_ADMIN_PYTHON_APP_URL}/restaurant/${restaurant.id}/orders/
              `}
        >
          orders on admin
        </Outlink>
        or log in with a company/region account.
      </p>
    </div>
  );
}

OrdersUnauthorized.propTypes = {
  restaurant: restaurantType
};

export default OrdersUnauthorized;
