import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconCheck } from './icon-check.svg';

import styles from './styles.module.scss';

const { bool, string } = PropTypes;

const Checkbox = ({ name, isRadio, isSelected, disabled }) => (
  <>
    <input
      type={isRadio ? 'radio' : 'checkbox'}
      name={name}
      checked={isSelected}
      className={styles.defaultCheckbox}
      readOnly
      disabled={disabled}
      hidden
    />
    <div
      tabIndex={0}
      className={classNames(styles.checkbox, {
        [styles.radio]: isRadio,
        [styles.disabled]: disabled,
      })}
    >
      <IconCheck className={styles.iconCheck} />
    </div>
  </>
);

Checkbox.propTypes = {
  disabled: bool,
  isRadio: bool,
  isSelected: bool,
  name: string,
};

export default Checkbox;
