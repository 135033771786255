import React, { FC, useContext, useState } from 'react';
import Input from 'components/Input';
import { MenuDataContext } from 'context/MenuDataContext';
import { CancelButton, MenuInputContainer } from './styles';

interface MenuInputProps {
  errorMessage?: string;
  fileName?: string;
  onClickCancel: () => void;
  isDisabled?: boolean;
}

const MenuInput: FC<MenuInputProps> = ({
  errorMessage,
  fileName,
  onClickCancel,
  isDisabled = false,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { setMenuLink, setMenuNotes } = useContext(MenuDataContext);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setMenuLink(value);
  };

  const handleInputCancel = () => {
    setInputValue('');
    setMenuLink('');
    setMenuNotes('');
    onClickCancel();
  };

  return (
    <MenuInputContainer>
      <Input
        onChange={handleInputChange}
        id="input-standard"
        name="input-standard"
        label="Add menu"
        value={fileName || inputValue}
        disabled={isDisabled}
        placeholder="Add Menu"
        shouldHideLabel
        errorText={errorMessage}
      />
      <CancelButton
        label="Cancel"
        onClick={handleInputCancel}
        size="small"
        variant="link"
      />
    </MenuInputContainer>
  );
};

export default MenuInput;
