import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { logException } from 'helpers/loggers';

const { node } = PropTypes;

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    children: node
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    const errorDesc = error.message ? error.message : error;

    logException(`ErrorBoundary - err: ${errorDesc}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1>Something went wrong. Please reload the page and try again.</h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
