// eslint-disable jsx-a11y/anchor-is-valid
import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { find, has, keyBy, mapValues, omit, partial, set } from 'lodash';
import { useParams } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import BootstrapFormikInput from 'components/FormFields/BootstrapFormikInput';
import LegacyFieldset from 'components/LegacyFieldset';
import LegacyStripeProcessor from 'components/LegacyStripeProcessor';
import LoadingSpinner from 'components/LoadingSpinner';

import { request } from 'helpers/api';
import { UserContext } from 'context/UserContext';
import { nullToEmptyString } from 'helpers/format';
import { PAYMENT_PROCESSORS } from 'helpers/payments';
import { disbursementSchema } from 'helpers/schemas';

import styles from './styles.module.scss';

function Disbursements() {
  // Form Options
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState();

  // Form values
  const [merchantAccount, setMerchantAccount] = useState({});
  const [sentFinanceEmail, setSentFinanceEmail] = useState(false);
  const [stripeProcessor, setStripeProcessor] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({});
  const { restaurantId } = useParams();
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Load the Restaurant and Merchant Account information
    Promise.all([
      request(`restaurant/${restaurantId}`),
      request(`restaurant/${restaurantId}/merchant-account`),
    ])
      .then(([restaurant, merchantAccount]) => {
        const initialValues = prepareFormData(merchantAccount);
        const stripeProcessor = find(merchantAccount.payment_processors, {
          id: PAYMENT_PROCESSORS.STRIPE,
        });
        setValidationSchema(disbursementSchema());
        setMerchantAccount(merchantAccount);
        setRestaurant(restaurant);
        setInitialValues(initialValues);
        setStripeProcessor(stripeProcessor);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.warn('Error occurred loading the restaurant: ', err);
        alert('An error occurred. Please refresh to try again.');
      });
  }, []);

  function handleEditToggle() {
    setIsEditMode(!isEditMode);
  }

  async function handleFinanceEmail(e) {
    e.preventDefault();

    try {
      const response = await request(
        `restaurant/${restaurantId}/email-merchant-account-info`,
        {},
        'POST'
      );

      if (response.status === 'ok') {
        setSentFinanceEmail('success');
      } else {
        throw response;
      }
    } catch {
      setSentFinanceEmail('failure');
    }
  }

  function handleFormReset(resetForm) {
    handleEditToggle();
    resetForm();
  }

  async function handleOnSubmit(values, { setErrors, setSubmitting }) {
    const formData = omit(values, [
      'address',
      'legal_name',
      'restaurant_id',
      'tin',
      'version',
      'payment_processors',
      'disburse_to.account_iv',
      'rep',
    ]);

    // Set / update Account Number if provided
    if (values.disburse_to.account !== '') {
      formData.disburse_to.account = values.disburse_to.account;
    }

    try {
      const results = await request(
        `restaurant/${restaurantId}/merchant-account-v2`,
        { ...formData },
        'PUT'
      );
      setMerchantAccount(prepareFormData(results));
      handleEditToggle();
      setSubmitting(false);
    } catch (err) {
      const errors = err.errors;

      if (errors) {
        const formattedErrors = mapValues(keyBy(errors, 'field'), 'message');

        setErrors(formattedErrors);
      }

      setSubmitting(false);
    }
  }

  function prepareFormData(data) {
    const formattedData = nullToEmptyString(data);

    set(formattedData, 'disburse_to.account', '');

    return formattedData;
  }

  const { is_chownow_admin: isChowNowAdmin } = user;

  if (isLoading || !has(merchantAccount, 'restaurant_id')) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner isAbsolute={false} withWrapper={false} secondary />
      </div>
    );
  }

  if (restaurant.is_post_pay) {
    return (
      <p>
        This is a post-pay restaurant. Disbursement information is not required.
      </p>
    );
  }

  return (
    <ErrorBoundary>
      <div className="tabbable">
        <ul className="nav nav-tabs">
          <li className="active selected">
            <a href="#disbursements" data-toggle="tab">
              Disbursements
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id="disbursements" className="tab-pane selected">
            {!isChowNowAdmin && (
              <div className="row">
                <div className="span6">
                  <div className="alert alert-info">
                    {`If you wish to update bank information, please email `}
                    <a href="mailto:billing@chownow.com">billing@chownow.com</a>
                    {` for instructions.`}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  errors,
                }) => (
                  <form className="span6" onSubmit={handleSubmit}>
                    <div className="formset">
                      <legend>
                        Bank Account
                        {isChowNowAdmin && !isEditMode && (
                          <a className="edit-form" onClick={handleEditToggle}>
                            <i className="icon-edit-sign" /> Edit
                          </a>
                        )}
                      </legend>
                      <LegacyFieldset
                        inputLabel="Bank Name"
                        value={merchantAccount.disburse_to.bank_name}
                        isEditMode={isEditMode}
                      >
                        <Field
                          type="text"
                          name="disburse_to.bank_name"
                          component={BootstrapFormikInput}
                        />
                      </LegacyFieldset>
                      <LegacyFieldset
                        inputLabel="Routing #"
                        value={merchantAccount.disburse_to.routing}
                        isEditMode={isEditMode}
                      >
                        <Field
                          type="text"
                          name="disburse_to.routing"
                          component={BootstrapFormikInput}
                        />
                      </LegacyFieldset>
                      <LegacyFieldset
                        inputLabel="Account #"
                        value={
                          merchantAccount.disburse_to.account_iv
                            ? `Ending in ${merchantAccount.disburse_to.account_iv}`
                            : ''
                        }
                        isEditMode={isEditMode}
                      >
                        <Field
                          type="text"
                          name="disburse_to.account"
                          placeholder={
                            merchantAccount.disburse_to.account_iv
                              ? '**********'
                              : ''
                          }
                          component={BootstrapFormikInput}
                        />
                      </LegacyFieldset>
                      {!isEditMode &&
                        isChowNowAdmin &&
                        (sentFinanceEmail ? (
                          [
                            sentFinanceEmail === 'success' ? (
                              <div
                                className="alert alert-success"
                                key="success"
                              >
                                Email notification sent
                              </div>
                            ) : (
                              <div className="alert alert-error" key="failure">
                                Email notification failed to send
                              </div>
                            ),
                          ]
                        ) : (
                          <a onClick={handleFinanceEmail}>
                            <i className="icon-envelope" /> Email account to
                            finance
                          </a>
                        ))}
                      {isEditMode && (
                        <>
                          <div className="line-submit form-actions">
                            <div className="row">
                              <div className="span1">
                                <button
                                  className="cancel back btn"
                                  onClick={partial(handleFormReset, resetForm)}
                                  type="button"
                                >
                                  Cancel
                                </button>
                              </div>
                              <div className="span1">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Save
                                </button>
                              </div>
                              <div className="span1">
                                {isSubmitting && (
                                  <div className="loading-spinner" />
                                )}
                              </div>
                            </div>
                          </div>
                          {errors.null && (
                            <div className="alert alert-error">
                              {' '}
                              {errors.null}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {!isEditMode && isChowNowAdmin && (
              <div className="row">
                <div className="span6">
                  <LegacyStripeProcessor
                    restaurantId={restaurantId}
                    stripeProcessor={stripeProcessor}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Disbursements;
