import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';
import { EMAIL_VALIDATION_REGEX } from '@chownow/cn-web-utils/validation';
import { resetPassword } from 'api/login';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getSearchParam } from 'helpers/url';

import styles from './styles.module.scss';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const redirectUri = getSearchParam('next');
  const forgotPasswordRedirectUri = redirectUri
    ? `&next=${encodeURIComponent(redirectUri)}`
    : '';

  const handleResetPassword = async () => {
    const response = await resetPassword(email, redirectUri);
    setIsLoading(true);

    if (response.errors) {
      setIsLoading(false);
      setEmailError('Sorry, an error has occurred. Please try again.');
    } else if (!response.errors) {
      setIsLoading(false);
      navigate(
        `/login/reset-password?email=${encodeURIComponent(
          email
        )}${forgotPasswordRedirectUri}`
      );
    }
  };

  function handleOnLoginClick(e) {
    e.preventDefault(); // do not refresh on submit
    if (!email) {
      setEmailError('Email is required.');
    } else if (!EMAIL_VALIDATION_REGEX.test(email)) {
      setEmailError('Invalid email address.');
    } else {
      handleResetPassword();
    }
  }

  return (
    <>
      <Helmet title="Login" />
      <div className={styles.main}>
        <h1 className={styles.header}>Forgot Password</h1>
        <div className={styles.subheader}>
          In order to change your password, we need to verify your identity.
          Enter the email address associated with your ChowNow account.
        </div>
        {isLoading ? (
          <LoadingSpinner isAbsolute={false} withWrapper={false} />
        ) : (
          <form className={styles.card}>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                errorText={emailError}
                label="Email Address"
                onChange={setEmail}
                type="email"
                value={email}
              />
            </div>
            <Button
              className={styles.button}
              isFullWidth
              label="Send Verification Code"
              onClick={handleOnLoginClick}
              type="submit"
            />
          </form>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
