import { find, pick } from 'lodash';

import { formatError, request } from 'helpers/api';
import { ORDER_ACTION } from 'helpers/constants';

function getModifiers(itemCategoryIds, allCategories, allModifiers) {
  let itemModifiers = [];

  itemCategoryIds?.forEach(id => {
    const category = find(allCategories, { id }) || [];

    category?.modifiers?.forEach(modifierId => {
      const modifier = find(allModifiers, { id: modifierId });
      if (modifier) {
        itemModifiers?.push({
          ...modifier,
          categoryName: category?.name,
        });
      }
    });
  });
  return itemModifiers;
}

/**
 * Fetch order details from an order ID
 * @param  {string} orderId - ChowNow Order ID
 * @returns {object} - returns order object with order details based off of orderId
 */
export async function requestOrderDetails(orderId) {
  try {
    return await request(`order/${orderId}`);
  } catch (err) {
    return formatError(err);
  }
}

// updateOrderStatus has been refactored in orderDetails.ts
// remove this one once OrderDetailsOld and OrderDetailsRefunds/CTABlock/
// has been deprecated/refactored
/**
 * Update order status
 * @param  {string} orderId - ChowNow Order ID
 * @param  {string} action - Action selected, 'accept' or 'cancel'
 */
export async function updateOrderStatus(orderId, action, eta) {
  try {
    if (action === ORDER_ACTION.accept) {
      return await request(
        `order/${orderId}/acknowledge`,
        {
          action: action,
          eta: eta,
          device_id: 'dashboard',
        },
        'POST'
      );
    } else {
      return await request(
        `order/${orderId}/acknowledge`,
        {
          action: `${action}`,
          eta: null,
          device_id: 'dashboard',
        },
        'POST'
      );
    }
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Check for temp closures at restaurant location
 * @param  {string} restaurantId - ChowNow Restaurant ID
 */
export async function requestTempClosures(restaurantId) {
  try {
    return await request(
      `restaurant/${restaurantId}/temporary-closures`,
      null,
      'GET'
    );
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Pause Orders at a restaurant location
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} fulfillMethod - Should orders be paused for delivery only or restaurant-wide (null)
 * @param  {string} duration - Duration in integer format in mins to pause orders for (ex: 5 for 5 mins)
 * @param  {string} reason - Internally visible reason for the temp closure.
 */
export async function pauseOrders(
  restaurantId,
  fulfillMethod,
  duration,
  reason
) {
  try {
    return await request(
      `restaurant/${restaurantId}/order-pause`,
      {
        fulfill_method: fulfillMethod,
        duration,
        reason,
      },
      'POST'
    );
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Delete Temp Closure from Restaurant
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} closureId - ID specific to the particular temp closure
 */
export async function deleteTempClosure(restaurantId, closureId) {
  try {
    return await request(
      `restaurant/${restaurantId}/temporary-closure/${closureId}`,
      null,
      'DELETE'
    );
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Toggle menu visibilty for CN Item
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} id - ChowNow Item ID
 * @param  {boolean} isVisible - isVisible request option
 */
export async function updateItemVisibility(restaurantId, id, isVisible) {
  try {
    return await request(
      `restaurant/${restaurantId}/catalog/item/${id}/toggle-visibility`,
      {
        is_visible: !isVisible,
        expire_datetime: null, // Placeholder- not included for MVP
      },
      'POST'
    );
  } catch (error) {
    return formatError(error);
  }
}

/**
 * Toggle menu visibilty for CN Modifier
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} id - ChowNow Item ID
 * @param  {boolean} isVisible - isVisible request option
 */
export async function updateModifierVisibility(restaurantId, id, isVisible) {
  try {
    return await request(
      `restaurant/${restaurantId}/catalog/modifier/${id}/toggle-visibility`,
      {
        is_visible: !isVisible,
      },
      'POST'
    );
  } catch (error) {
    return formatError(error);
  }
}

/**
 * Fetch all menu items from a restaurant location
 * @param  {string} restaurantId - ChowNow Restaurant ID
 */
export async function requestCatalog(restaurantId) {
  let response;

  try {
    response = await request(`restaurant/${restaurantId}/catalog`);
  } catch (err) {
    return formatError(err);
  }

  const {
    items,
    modifier_categories: allModifierCategories,
    modifiers: allModifiers,
    menu_categories,
    error,
  } = response;

  if (error) return formatError(error);

  const sizes = [];

  // Finds all meta-items and saves their associated sizes
  const metaItems = items.reduce((accumulator, item) => {
    if (item.is_meta) {
      const { child_ids, default_id } =
        item.meta_item_details.serving_size_group;

      child_ids.forEach(id => {
        if (id !== default_id) sizes.push(id);
      });
      accumulator.push({
        ...item,
        defaultId: default_id,
      });
    }
    return accumulator;
  }, []);

  // Removes all meta-items and non-default sizes, formats data
  const CNItems = items.reduce((accumulator, item) => {
    if (!item.is_meta && !sizes.includes(item.id)) {
      const modifiers = getModifiers(
        item.modifier_categories,
        allModifierCategories,
        allModifiers
      );

      // Find corresponding meta-item for default size
      const metaItem = find(metaItems, { defaultId: item.id });
      if (metaItem) {
        const { child_ids, default_id } =
          metaItem.meta_item_details.serving_size_group;
        // Finds non-default size items
        const childData = child_ids.map(child_id => {
          return find(items, { id: child_id });
        });
        const servingSizes = childData.map(child => {
          return pick(child, 'price', 'size', 'id', 'x_id');
        });

        accumulator.push({
          ...item,
          serving_sizes: servingSizes,
          sizeId: default_id,
          modifiers,
          image: metaItem.image || null,
          meta_id: metaItem.id,
        });
      } else {
        accumulator.push({
          ...item,
          serving_sizes: [],
          modifiers,
        });
      }
    }
    return accumulator;
  }, []);

  return {
    CNItems,
    menuCategories: menu_categories || [],
    modifiers: allModifiers || [],
    modifierCategories: allModifierCategories || [],
  };
}

/**
 * Post photo details for menu item
 * @param {string} companyID - ChowNow Company ID
 * @param {string} itemID - Menu Item ID
 * @param {string} filename - image name for S3
 * @param {string} userFileName - original image name
 * @param {object} crop - user selected crop options
 * @param {number} width - natural image width
 * @param {number} crop - natural image height
 */
export async function updatePhotoDetails(
  path,
  filename,
  userFileName,
  crop,
  width,
  height,
  itemId,
  method
) {
  const isRestaurantAsset = itemId === 'cover' || itemId === 'logo';
  const cropData = {
    crop_x: crop.x,
    crop_y: crop.y,
    crop_width: crop.width,
    crop_height: crop.height,
  };
  const fileData = {
    filename,
    user_filename: userFileName,
    width,
    height,
    ...(isRestaurantAsset && { usage_type: itemId }),
  };

  const payload = { ...cropData, ...fileData };

  try {
    return await request(path, payload, method);
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Remove photo details for menu item
 * @param {string} companyID - ChowNow Company ID
 * @param {string} itemID - Menu Item ID
 */
export async function removePhotoDetails(path) {
  try {
    return await request(path, null, 'DELETE');
  } catch (err) {
    return formatError(err);
  }
}

export function getPhotoEndpointUrl(path, mediaType, image) {
  return `${path}/${mediaType}${
    mediaType === 'media' && image ? `/${image.id}` : ''
  }`;
}

export function getRestaurantMedia(restaurantId) {
  try {
    return request(`restaurant/${restaurantId}/media`);
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Get ready time for restaurant location
 * @param {string} restaurantID - ChowNow Restaurant ID
 */
export async function getRestaurantReadyTime(restaurantId) {
  try {
    return await request(`restaurant/${restaurantId}/prep-time`, null, 'GET');
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Set ready time offset at a restaurant location
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} readyTime - Ready Time needed in mins
 */
export async function setReadyTimeOffset(restaurantId, readyTime) {
  try {
    return await request(
      `restaurant/${restaurantId}/prep-time`,
      {
        prep_time_offset: readyTime,
      },
      'PUT'
    );
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Get list of integrations for restaurant
 * @param {string} restaurantID - ChowNow Restaurant ID
 */
export async function getRestaurantIntegrations(restaurantId) {
  try {
    return await request(`pos/restaurant/${restaurantId}/integrations`);
  } catch (err) {
    return formatError(err);
  }
}
