/**
 * @param {string} fullDay - The full day of the week i.e. "Monday"
 */
export const getDayAbbreviation = (fullDay: string): string => {
  const daysMap: { [key: string]: string } = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
  };

  return daysMap[fullDay] || fullDay;
};
