import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, space } from 'styles/variables';
import { ReactComponent as DownArrowIcon } from 'images/icon-chevron.svg';

export const Container = styled.div`
  background-color: ${semanticColors.surface.primary};
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.primary};
  font: ${label.reg.x14};
  height: fit-content;
  line-height: 1.15;
  width: 100%;
`;

export const Title = styled.div`
  font: ${label.semi.x16};
  padding: ${space.x3};
  position: relative;
  text-transform: capitalize;
`;

export const DownArrow = styled(DownArrowIcon)<{ $isActive: boolean }>`
  cursor: pointer;
  height: ${space.x2};
  margin-top: 2px;
  padding: ${space.x2};
  position: absolute;
  right: ${space.x1};
  top: ${space.x1};
  transition: transform 0.3s;
  width: ${space.x2};

  ${({ $isActive }) => ($isActive ? 'transform: rotate(-90deg);' : 'transform: rotate(-270deg);')}
`;

export const Divider = styled.div`
  background-color: ${semanticColors.border.primary};
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ChildrenWrap = styled.div`
  padding: ${space.x3};
  position: relative;
`;

export const ExpandWrap = styled.div`
  transition: height 0.3s, opacity 0.2s;
`;
