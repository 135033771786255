import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

interface CardArticleProps {
  title: string;
  summary: string;
  imgSrc: string;
  altText?: string;
  linkLocation?: string;
  internalLinkLocation?: string;
}

const CardArticle: FC<CardArticleProps> = ({
  title,
  summary,
  imgSrc,
  altText,
  linkLocation,
  internalLinkLocation,
}) => {
  const linkContent = (
    <>
      <img src={imgSrc} alt={altText || title} />
      <div className={styles.description}>
        <span className={styles.title}>{title}</span>
        <p className={styles.summary}>{summary}</p>
      </div>
    </>
  );

  return (
    <div className={styles.cardArticle}>
      {internalLinkLocation ? (
        <Link to={internalLinkLocation}>{linkContent}</Link>
      ) : (
        <a href={linkLocation} target="_blank" rel="noreferrer">
          {linkContent}
        </a>
      )}
    </div>
  );
};

export default CardArticle;
