import React, { useEffect, useRef, useState } from 'react';
import {
  ChildrenWrap,
  Container,
  Divider,
  DownArrow,
  ExpandWrap,
  Title,
} from './styles';

export interface CardInfoProps {
  /** The content of the card */
  children: React.ReactNode;
  /** Optional CSS class to be applied */
  className?: string;
  /** Card title */
  title: string;
}

const CardInfo = ({ children, title, className }: CardInfoProps) => {
  const childrenRef = useRef<HTMLDivElement>(null);
  const [initialHeight, setInitialHeight] = useState(0);
  const [height, setHeight] = useState(0);
  const isCollapsed = height === 0;

  function handleResize() {
    const elemHeight = childrenRef?.current?.offsetHeight || 0;

    setInitialHeight(elemHeight);
    setHeight(elemHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [children, childrenRef.current?.offsetHeight]);

  function handleOnClick() {
    setHeight(height ? 0 : initialHeight);
  }

  return (
    <Container className={className}>
      <Title>
        {title}
        <DownArrow
          $isActive={isCollapsed}
          onClick={handleOnClick}
          data-testid="expand"
        />
      </Title>
      <ExpandWrap
        style={{
          height,
          opacity: isCollapsed ? 0 : 1,
        }}
      >
        <ChildrenWrap ref={childrenRef}>
          <Divider />
          {children}
        </ChildrenWrap>
      </ExpandWrap>
    </Container>
  );
};

export default CardInfo;
