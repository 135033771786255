import React, { FC, PropsWithChildren, useContext } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import SlideUpIn from 'components/Animations/SlideUpIn';
import { ModalContext } from 'context/ModalContext';

import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

interface ModalWrapperProps extends PropsWithChildren {
  className?: string;
  mobileFullView?: boolean;
  onClose?: (returnVal: boolean) => void;
  preventOverlayClose?: boolean;
  showCloseIcon?: boolean;
  size?: 'small' | 'large';
}

const ModalWrapper: FC<ModalWrapperProps> = ({
  children,
  className = '',
  mobileFullView = false,
  onClose = () => {
    /* do nothing */
  },
  preventOverlayClose = false,
  showCloseIcon = false,
  size = 'small',
}) => {
  const { hideModal } = useContext(ModalContext);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.mdMin });

  function handleOnClose(didConfirm: boolean) {
    if (onClose) onClose(didConfirm);
    hideModal();
  }

  function handleOverlayClick() {
    if (!preventOverlayClose) {
      handleOnClose(false);
    }
  }

  const ModalContent = (
    <div
      className={classNames(styles.modal, className, {
        [styles.large]: size === 'large',
      })}
    >
      {showCloseIcon && (
        <IconClose
          className={styles.iconClose}
          onClick={() => handleOnClose(false)}
        />
      )}
      {children}
    </div>
  );

  return (
    <>
      {/* eslint-disable-next-line */}
      <div className={styles.overlay} onClick={handleOverlayClick} />
      {mobileFullView && !isDesktop ? (
        <SlideUpIn
          className={classNames(styles.modalWrap, styles.mobileFullView)}
        >
          {ModalContent}
        </SlideUpIn>
      ) : (
        <div className={styles.modalWrap}>{ModalContent}</div>
      )}
    </>
  );
};

export default ModalWrapper;
