import { login } from 'api/login';
import Terms from 'containers/UserAuth/Terms';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { ERROR_CODES, JOSIAHS_BAD_PASSWORDS } from 'helpers/constants';
import { getSearchParam } from 'helpers/url';
import { numericStringRegex } from 'helpers/validators';

import { ReactComponent as CheckBadge } from 'images/check-badge.svg';

import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';

import LoadingSpinner from 'components/LoadingSpinner';

import styles from './styles.module.scss';

const ResetPassword = () => {
  const location = useLocation();
  const userEmail = new URLSearchParams(location.search).get('email');
  const verificationCodeParam = new URLSearchParams(location.search).get(
    'verificationcode'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const redirectUri = getSearchParam('next');

  // prefills verification code from query param if user came from reset pw email
  useEffect(() => {
    verificationCodeParam && setVerificationCode(verificationCodeParam);
  }, []);

  const handleLogin = async () => {
    const response = await login({
      email: userEmail,
      password: password,
      uniqueKey: verificationCode,
    });
    setIsLoading(true);

    if (response.errors) {
      if (response.errors[0]?.code === ERROR_CODES.invalidVerificationCode) {
        setIsLoading(false);
        setVerificationCodeError('Your verification code is invalid');
      } else {
        setIsLoading(false);
        setVerificationCodeError(
          'Whoops, something went wrong. Please try again.'
        );
      }
    } else if (!response.errors) {
      setIsLoading(false);
      redirectUri
        ? window.location.replace(redirectUri)
        : window.location.replace(`/`);
    }
  };

  const handleOnLoginClick = e => {
    e.preventDefault();
    if (!verificationCode) {
      setVerificationCodeError('Verification code is required.');
    } else {
      setVerificationCodeError('');
    }

    if (!password) {
      setPasswordError('Password is required.');
      return;
    } else if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters.');
      return;
    } else if (!!numericStringRegex.test(password)) {
      // check to see if pw is all numbers
      setPasswordError('You need at least some letters in your password.');
      return;
    } else if (
      (password.toUpperCase() === password ||
        password.toLowerCase() === password) &&
      !password.match(/\d/)
    ) {
      // check to see if pw is all uppercase or lowercase with no numbers
      setPasswordError('Please choose a less common password.');
      return;
    } else if (JOSIAHS_BAD_PASSWORDS.indexOf(password) > -1) {
      // check to see if pw is in the list of bad passwords
      setPasswordError('Please choose a less common password.');
      return;
    } else {
      setPasswordError('');
    }

    if (!!verificationCode) {
      handleLogin(verificationCode, password);
    }
  };

  return (
    <>
      <Helmet title="Login" />
      <div className={styles.main}>
        <h1 className={styles.header}>Reset Password</h1>
        <div className={styles.subheader}>
          <CheckBadge className={styles.badge} /> We emailed your verification
          code to {userEmail}
        </div>
        {isLoading ? (
          <LoadingSpinner isAbsolute={false} withWrapper={false} />
        ) : (
          <form className={styles.card}>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                errorText={verificationCodeError}
                helpText="Check your email's spam or junk folder if you don't receive it within a few minutes"
                label="Verification Code"
                onChange={setVerificationCode}
                type="text"
                value={verificationCode}
                id="Verification Code"
              />
            </div>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                errorText={passwordError}
                helpText="Your password must be at least 8 characters"
                label="Create Password"
                link={{
                  label: showPassword ? 'Hide' : 'Show',
                  onClick: () => setShowPassword(!showPassword),
                }}
                onChange={setPassword}
                type={!showPassword ? 'password' : ''}
                value={password}
                id="Create Password"
              />
            </div>
            <Button
              className={styles.button}
              isFullWidth
              label="Log In"
              onClick={handleOnLoginClick}
              type="submit"
            />
            <Terms />
          </form>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
