import styled from 'styled-components';
import { space } from 'styles/variables';
import { label } from 'styles/types';
import { semanticColors } from 'styles/colors';

export const Filters = styled.ul`
  font: ${label.reg.x14};
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SelectAll = styled.div`
  border-bottom: 1px solid ${semanticColors.border.primary};
  margin-bottom: $space-3;
`;

export const Label = styled.label`
  align-items: center;
  color: ${semanticColors.content.primary};
  display: flex;
  font: ${label.reg.x14};
  letter-spacing: ${space.x1};
  margin: 0 0 ${space.x3} 0;
  padding: 0;
`;

export const StyledHeadingLabel = styled.h3`
  align-items: center;
  color: ${semanticColors.content.primary};
  display: flex;
  font: ${label.reg.x14};
  letter-spacing: ${space.x1};
  margin: 0 0 ${space.x3} 0;
  padding: 0;
`;

export const Filter = styled.li`
  cursor: pointer;
  margin-bottom: ${space.x3};
`;

export const Input = styled.span`
  margin-right: ${space.x1};
  margin-top: -2px;
`;
