import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import Checkbox from 'components/Checkbox';
import {
  ModifierCategory,
  OrderItemNestedModsV5,
  SelectedNestedModifier,
  TabletOrderItemV5,
} from 'types/order-details';
import { NestedRefundInterface, RefundItemInterface } from '../util';
import {
  Amount,
  CategoryName,
  CheckboxWrap,
  DetailsWrap,
  Flex,
  IndentSpacer,
  ItemName,
  OriginalAmount,
  Quantity,
  SpecialInstructions,
  SubItem,
} from './styles';
import { getModifierCount } from './helpers';

interface RefundSubItemProps {
  parentQuantity?: number;
  item: OrderItemNestedModsV5 | TabletOrderItemV5 | SelectedNestedModifier;
  refundedItems: RefundItemInterface[];
  parentId?: string;
  handleRefundUpdate: (
    qty: number,
    itemId: string,
    modifierId?: string
  ) => void;
  handleRefundAll?: (qty: number, id: string) => void;
  indentLevel: number;
  catagoryName?: string;
  isReadOnly?: boolean;
}

const RefundSubItem: FC<RefundSubItemProps> = ({
  parentQuantity,
  item,
  refundedItems,
  parentId,
  handleRefundUpdate,
  handleRefundAll,
  indentLevel,
  catagoryName,
  isReadOnly = false,
}) => {
  const { modifier_categories, price, id } = item;
  const quantity = 'quantity' in item ? item.quantity : 1;
  const item_sum_total = 'item_sum_total' in item ? item.item_sum_total : 0;
  const size = 'size' in item ? item.size : '';
  const [refundItem, setRefundItem] = useState<
    RefundItemInterface | ModifierCategory
  >();
  const [isChecked, setIsChecked] = useState(false);

  const hasModifiers = modifier_categories && !!modifier_categories.length;
  const itemQuantity = parentQuantity || quantity;
  const isRootItem = !!handleRefundAll && 'modifier_categories' in item;
  const total = handleRefundAll ? item_sum_total : (price ?? 0) * itemQuantity;
  const refundQuantity =
    refundItem && 'refund_quantity' in refundItem
      ? refundItem?.refund_quantity
      : 0;
  const adjustedTotal =
    total -
    (total / itemQuantity) *
      (refundItem && 'refund_quantity' in refundItem ? refundQuantity : 0);
  const isParentItem = hasModifiers && handleRefundAll;
  const isSelected = isParentItem ? isChecked : refundQuantity > 0;
  const itemSize = size === 'Regular' ? '' : size;
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  let displayTotal = '';
  if (total) {
    if (isSelected) {
      displayTotal = adjustedTotal.toFixed(2);
    } else {
      displayTotal = total.toFixed(2);
    }
  }

  useEffect(() => {
    const searchForItem = (refundModifiers: NestedRefundInterface[]) => {
      refundModifiers.forEach(m => {
        if (m.id === id) {
          setRefundItem(m);
        } else if (m.refund_modifiers) {
          searchForItem(m.refund_modifiers);
        }
      });
    };

    refundedItems.forEach(i => {
      if (i.id === id) {
        setRefundItem(i);
      } else if (i.refund_modifiers) {
        searchForItem(i.refund_modifiers);
      }
    });
  }, [refundedItems]);

  const handleOnClick = () => {
    const qty = isSelected ? 0 : itemQuantity;

    // If parentItem, checkbox selects all modifiers
    if (isParentItem) {
      setIsChecked(!isChecked);
      handleRefundAll(qty, id);
    } else if (parentId) {
      handleRefundUpdate(qty, parentId, id);
    } else {
      handleRefundUpdate(qty, id);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const qty = parseInt(e.target.value, 10);
    if (parentId) {
      handleRefundUpdate(qty, parentId, id);
    } else {
      handleRefundUpdate(qty, id);
    }
  };

  return (
    <SubItem $hasNoMods={!hasModifiers && isRootItem}>
      <Flex $disabledForMobile={!isReadOnly}>
        <Flex>
          <IndentSpacer $indentLevel={indentLevel} />
          <ItemName
            $isFree={!total}
            $isParent={!!isParentItem}
            $isReadOnly={isReadOnly}
            $indentLevel={indentLevel}
          >
            {isRootItem && itemQuantity}{' '}
            {indentLevel > 0 && catagoryName && (
              <CategoryName>{catagoryName} —</CategoryName>
            )}{' '}
            {item.name} {itemSize && `(${itemSize})`}
            {isRootItem &&
            hasModifiers &&
            refundItem &&
            'refund_modifiers' in refundItem
              ? ` (${getModifierCount(refundItem.refund_modifiers)} modifier${
                getModifierCount(refundItem.refund_modifiers) === 1 ? '' : 's'
              })`
              : ''}
          </ItemName>
        </Flex>

        {(!!total || isReadOnly) && (
          <DetailsWrap $isReadOnly={isReadOnly} $indentLevel={indentLevel}>
            {!!total && !isReadOnly && (
              <div>
                <CheckboxWrap onClick={handleOnClick}>
                  <Checkbox name={`item_${id}`} isSelected={isSelected} />
                </CheckboxWrap>
                Refund {isRootItem && hasModifiers && ' all'}
              </div>
            )}

            <div>
              {!!total &&
                isSelected &&
                itemQuantity > 1 &&
                !isParentItem &&
                !isReadOnly && (
                  <Quantity
                    onChange={handleOnChange}
                    value={
                      refundItem && 'refund_quantity' in refundItem
                        ? refundItem.refund_quantity || 0
                        : 0
                    }
                  >
                    {[...Array<number>(itemQuantity)].map((_, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <option value={i + 1} key={i}>
                        {i + 1}
                      </option>
                    ))}
                  </Quantity>
              )}
            </div>

            <div>
              {!!total && isSelected && isDesktop && !isReadOnly && (
                <OriginalAmount>{total.toFixed(2)}</OriginalAmount>
              )}

              <Amount $isSelected={isSelected} $isParent={!!isParentItem}>
                {displayTotal}
              </Amount>
            </div>
          </DetailsWrap>
        )}
      </Flex>
      {isReadOnly && isRootItem && 'special_instructions' in item && item.special_instructions && (
        <SpecialInstructions>{item.special_instructions}</SpecialInstructions>
      )}
    </SubItem>
  );
};

export default RefundSubItem;
