import React, { FC, useEffect, useState } from 'react';
import pictogram from 'images/pictograms/picto-img-lg.svg';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import { useGetFoodImage, usePutFoodImage } from 'api/onboarding/foodImageApis';
import { useGetLogo, usePutLogo } from 'api/onboarding/logoApis';
import Banner from '@chownow/cocina-react-banner';
import {
  NoFoodImageOptions,
  isGetFoodImagePreviouslySubmittedResponse,
} from 'types/food-image';
import { isGetLogoPreviouslySubmittedResponse } from 'types/logo';
import { usePostFileAsset } from 'api/onboarding';

import OnboardingContainer from '../OnboardingContainer';
import PageIntro from '../PageIntro';
import { useWizardNavigationContext } from '../WizardNavigationContext';
import ErrorCard from '../ErrorCard';
import LoadingState from '../LoadingState';
import PageFooterLogoAndFoodImage from '../PageFooter/PageFooterLogoAndFoodImage';
import RestaurantLogoCard from './content/RestaurantLogoCard';
import FoodImageCard from './content/FoodImageCard';
import { BannerMessages } from './constants';
import {
  getErrorBannerMessage,
  getFoodImagePreviouslySubmittedText,
  getLogoPreviouslySubmittedText,
  getSubmittedBannerMessage,
} from './helpers';

interface LogoAndFoodImageProps {
  uuid: string | null;
}

const LogoAndFoodImage: FC<LogoAndFoodImageProps> = ({ uuid }) => {
  const [isFormCompeleted, setIsFormCompleted] = useState<boolean>(false);
  const [hasNoLogoFile, setHasNoLogoFile] = useState<boolean>(false);
  const [hasNoFoodImageFile, setHasNoFoodImageFile] = useState<boolean>(false);
  const [selectedNoFoodImageOption, setSelectedNoFoodImageOption] = useState<
    NoFoodImageOptions | undefined
  >();
  const [hasNoFoodImageSelectionError, setHasNoFoodImageSelectionError] =
    useState<boolean>(false);
  const [submittedBannerMessage, setSubmittedBannerMessage] =
    useState<string>('');
  const [foodImageFile, setFoodImageFile] = useState<File>();
  const [logoFile, setLogoFile] = useState<File>();
  const [logoUploadSucceeded, setLogoUploadSucceeded] =
    useState<boolean>(false);
  const [logoUploadFailed, setLogoUploadFailed] = useState<boolean>(false);
  const [foodImageUploadSucceeded, setFoodImageUploadSucceeded] =
    useState<boolean>(false);
  const [foodImageUploadFailed, setFoodImageUploadFailed] =
    useState<boolean>(false);

  const {
    hasGetFoodImageRequestError,
    isGetFoodImageRequestLoading,
    getFoodImageRequestResponse,
  } = useGetFoodImage(uuid);
  const { isPutFoodImageRequestLoading, putFoodImage } = usePutFoodImage(uuid);
  const {
    hasGetLogoRequestError,
    isGetLogoRequestLoading,
    getLogoRequestResponse,
  } = useGetLogo(uuid);
  const { isPutLogoRequestLoading, putLogo } = usePutLogo(uuid);
  const { postFileAsset, isPostFileAssetLoading } = usePostFileAsset();
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();

  const isLoading =
    isGetFoodImageRequestLoading ||
    isPutFoodImageRequestLoading ||
    isGetLogoRequestLoading ||
    isPutLogoRequestLoading ||
    isPostFileAssetLoading ||
    isWizNavProgressRequestLoading;
  const hasError =
    hasGetFoodImageRequestError ||
    hasGetLogoRequestError ||
    hasWizNavProgressRequestError;

  useEffect(() => {
    const completed =
      hasNoLogoFile || logoFile || hasNoFoodImageFile || foodImageFile;
    setIsFormCompleted(!!completed);
  }, [
    hasNoLogoFile,
    hasNoFoodImageFile,
    selectedNoFoodImageOption,
    logoFile,
    foodImageFile,
  ]);

  useEffect(() => {
    if (!isLoading && !hasError) {
      const bannerMessage = getSubmittedBannerMessage(
        getFoodImageRequestResponse,
        getLogoRequestResponse
      );

      if (bannerMessage) {
        setSubmittedBannerMessage(bannerMessage);
      }
    }
  }, [getFoodImageRequestResponse, getLogoRequestResponse]);

  const saveLogo = async () => {
    const hasSubmitted =
      logoUploadSucceeded ||
      isGetLogoPreviouslySubmittedResponse(getLogoRequestResponse);
    const shouldSubmitLogo = !hasSubmitted && (logoFile || hasNoLogoFile);
    let hasLogoError = false;

    if (shouldSubmitLogo) {
      if (hasNoLogoFile) {
        hasLogoError = await putLogo(!hasNoLogoFile);
      } else if (logoFile && typeof logoFile !== 'string') {
        // Upload logo file
        hasLogoError = await postFileAsset(uuid, logoFile, {
          assetType: 'logo_file',
          filename: logoFile.name,
        });
      }
      setLogoUploadSucceeded(!hasLogoError);
      setLogoUploadFailed(hasLogoError);
    }
    return hasLogoError;
  };

  const saveFoodImage = async () => {
    const hasSubmitted =
      foodImageUploadSucceeded ||
      isGetFoodImagePreviouslySubmittedResponse(getFoodImageRequestResponse);
    const shouldSubmitFoodImage =
      !hasSubmitted && (foodImageFile || selectedNoFoodImageOption);
    let hasFoodImageError = false;

    // Handle Food Image
    if (shouldSubmitFoodImage) {
      if (selectedNoFoodImageOption) {
        hasFoodImageError = await putFoodImage(
          !hasNoFoodImageFile,
          selectedNoFoodImageOption
        );
      } else if (foodImageFile && typeof foodImageFile !== 'string') {
        // Upload food image file
        hasFoodImageError = await postFileAsset(uuid, foodImageFile, {
          assetType: 'food_image_file',
          filename: foodImageFile.name,
        });
      }
      setFoodImageUploadSucceeded(!hasFoodImageError);
      setFoodImageUploadFailed(hasFoodImageError);
    }
    return hasFoodImageError;
  };

  const handleOnSave = async () => {
    let hasOnSaveError = false;

    if (hasNoFoodImageFile && !selectedNoFoodImageOption) {
      setHasNoFoodImageSelectionError(true);
      hasOnSaveError = true;
    } else {
      hasOnSaveError = await saveLogo();
      hasOnSaveError = (await saveFoodImage()) || hasOnSaveError;
    }

    if (hasOnSaveError) {
      window.scrollTo(0, 0);
    }

    return hasOnSaveError;
  };

  let content;

  if (isLoading) {
    content = <LoadingState />;
  } else if (hasError) {
    content = <ErrorCard />;
  } else {
    content = (
      <>
        <RestaurantLogoCard
          hasNoLogoFile={hasNoLogoFile}
          logoFile={logoFile}
          previouslySubmittedText={getLogoPreviouslySubmittedText(
            getLogoRequestResponse,
            logoUploadSucceeded,
            logoFile?.name,
            hasNoLogoFile
          )}
          setHasNoLogoFile={setHasNoLogoFile}
          setLogoFile={setLogoFile}
        />
        <FoodImageCard
          foodImageFile={foodImageFile}
          hasNoFoodImageFile={hasNoFoodImageFile}
          hasNoFoodImageSelectionError={hasNoFoodImageSelectionError}
          previouslySubmittedText={getFoodImagePreviouslySubmittedText(
            getFoodImageRequestResponse,
            foodImageUploadSucceeded,
            foodImageFile?.name,
            selectedNoFoodImageOption
          )}
          setHasNoFoodImageFile={setHasNoFoodImageFile}
          setHasNoFoodImageSelectionError={setHasNoFoodImageSelectionError}
          selectedNoFoodImageOption={selectedNoFoodImageOption}
          setSelectedNoFoodImageOption={setSelectedNoFoodImageOption}
          setFoodImageFile={setFoodImageFile}
        />
      </>
    );
  }

  return (
    <>
      {!!submittedBannerMessage && (
        <Banner variant="success">{submittedBannerMessage}</Banner>
      )}
      {hasNoFoodImageSelectionError && (
        <Banner variant="caution">
          {BannerMessages.NO_FOOD_IMAGE_SELECTION}
        </Banner>
      )}
      {(logoUploadFailed || foodImageUploadFailed) && (
        <Banner variant="caution">
          {getErrorBannerMessage(logoUploadFailed, foodImageUploadFailed)}
        </Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Logo and Food Image"
          title={ONB_WIZARD_INTRO.logoAndFoodImageTitle}
          description={ONB_WIZARD_INTRO.logoAndFoodImageDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterLogoAndFoodImage
            hasPresubmittedData={
              isGetFoodImagePreviouslySubmittedResponse(
                getFoodImageRequestResponse
              ) && isGetLogoPreviouslySubmittedResponse(getLogoRequestResponse)
            }
            isLoading={false}
            isFormCompleted={isFormCompeleted}
            onSave={handleOnSave}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default LogoAndFoodImage;
