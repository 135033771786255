import styled from 'styled-components';
import { space } from 'styles/variables';
import { ReactComponent as IconTime } from 'images/icon-time-16.svg';
import { ReactComponent as IconBadgeClose } from 'images/icon-badge-close-16.svg';
import { ReactComponent as IconBadgeCheck } from 'images/icon-badge-check-16.svg';
import { semanticColors } from 'styles/colors';

export const StyledIconTime = styled(IconTime)`
  margin-right: ${space.x1};

  path {
    fill: ${semanticColors.content.success};
  }
`;

export const StyledIconBadgeClose = styled(IconBadgeClose)`
  margin-right: ${space.x1};

  path {
    fill: ${semanticColors.content.caution};
  }
`;

export const StyledIconBadgeCheck = styled(IconBadgeCheck)`
  margin-right: ${space.x1};

  path {
    fill: ${semanticColors.content.secondary};
  }
`;
