import { useEffect, useState } from 'react';
import { formatError, request } from 'helpers/api';
import { OrderDetailsV6GetSuccessResponse, UpdateOrderResponse } from 'types/order-details';
import { ORDER_ACTION } from 'helpers/constants';

export type ErrorType = {
  errors: {
    message: string;
  }[];
};

/**
 * Get order details data for a specific order
 * @param {string} orderId - Order ID that we want details for
 */
export const useGetOrderDetails = (orderId: string | undefined) => {
  const [isGetOrderDetailsRequestLoading, setIsGetOrderDetailsRequestLoading] =
    useState<boolean>(true);
  const [hasGetOrderDetailsRequestError, setHasGetOrderDetailsRequestError] =
    useState<boolean>(false);
  const [getOrderDetailsRequestResponse, setGetOrderDetailsRequestResponse] =
    useState<OrderDetailsV6GetSuccessResponse>();

  const getOrderDetails = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: OrderDetailsV6GetSuccessResponse = await request(
        `order/${orderId}`,
        {},
        'GET',
        undefined,
        undefined,
        undefined,
        '6.0'
      );

      setGetOrderDetailsRequestResponse(response);
    } catch (err) {
      setHasGetOrderDetailsRequestError(true);
    } finally {
      setIsGetOrderDetailsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      void getOrderDetails();
    }
  }, []);

  return {
    hasGetOrderDetailsRequestError,
    isGetOrderDetailsRequestLoading,
    getOrderDetailsRequestResponse,
    getOrderDetails,
  };
};

/**
 * Update order status
 * @param {string} orderId - ChowNow Order ID
 * @param {string} action - Action selected, 'accept' or 'cancel'
 * @param {string | null} eta - Estimated Time of Arrival, or null
 */
export const useUpdateOrderStatus = () => {
  const [isUpdateOrderStatusRequestLoading, setIsUpdateOrderStatusRequestLoading] =
    useState<boolean>(false);

  const updateOrderStatus = async (
    orderId: string,
    action: 'accept' | 'cancel',
    eta: number | null
  ): Promise<string> => {
    let errorMessage = '';

    setIsUpdateOrderStatusRequestLoading(true);

    try {
      await request(
        `order/${orderId}/acknowledge`,
        {
          // action: action === 'accept' ? action : `${action}`,
          // note: originally this was the line above, but I simplified it to the line below
          // double check to see if it changes the functionality
          action: `${action}`,
          eta: action === ORDER_ACTION.accept ? eta : null,
          device_id: 'dashboard',
        },
        'POST'
      ) as UpdateOrderResponse;
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      errorMessage = formatError(err)?.errors?.[0]?.message as string;
    } finally {
      setIsUpdateOrderStatusRequestLoading(false);
    }

    return errorMessage;
  };

  return {
    isUpdateOrderStatusRequestLoading,
    updateOrderStatus,
  };
};
