import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function LoadingBar(props) {
  const { meta } = props;
  const { percent, status } = meta;

  // Hide progress bar on 'done' to prevent it from showing after image has uploaded
  return status === 'done' ? null : (
    <div className={styles.loadingBarWrapper}>
      <div
        className={styles.loadingBar}
        style={{
          width: `${percent || 0}%`
        }}
      />
    </div>
  );
}

LoadingBar.propTypes = {
  meta: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
    status: PropTypes.string
  })
};

export default LoadingBar;
