import React, { FC, useEffect, useState } from 'react';

import {
  useGetWebsiteAccess,
  usePutWebsiteAccess,
} from 'api/onboarding/websiteAccessApis';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import PageFooterWebsiteAccess from 'containers/Onboarding/PageFooter/PageFooterWebsiteAccess';
import PageIntro from 'containers/Onboarding/PageIntro';
import pictogram from 'images/pictograms/picto-order-direct-lg.svg';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import Restaurant from 'types/restaurant';
import { WebsiteAccessResponse } from 'types/website-access';
import Banner from '@chownow/cocina-react-banner';
import { emailValidator } from 'helpers/onboarding/emailValidator';
import { nameValidator } from 'helpers/onboarding/nameValidator';
import { phoneNumberValidator } from 'helpers/onboarding/phoneNumberValidator';
import { websiteAccessAdminUrlValidator } from 'helpers/onboarding/websiteAccessAdminUrlValidator';
import { websiteAccessAdminUsernameValidator } from 'helpers/onboarding/websiteAccessAdminUsernameValidator';
import { websiteAccessAdminPasswordValidator } from 'helpers/onboarding/websiteAccessAdminPasswordValidator';

import { useWizardNavigationContext } from '../WizardNavigationContext';
import {
  WEBSITE_ACCESS_BANNERS,
  WebsiteAccessBannerMessages,
  WebsiteAccessOptions,
} from './constants';
import WebsiteAccessCard from './content/WebsiteAccessCard';
import InfoCard from './content/InfoCard';
import SavedWebsiteAccessCard from './content/SavedWebsiteAccessCard';
import ErrorCard from '../ErrorCard';
import LoadingState from '../LoadingState';

interface WebsiteAccessProps {
  restaurant: Restaurant;
}

const WebsiteAccess: FC<WebsiteAccessProps> = ({ restaurant }) => {
  const [selectedOption, setSelectedOption] = useState<WebsiteAccessOptions>();
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [isErrorBannerDisplayed, setIsErrorBannerDisplayed] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();
  const uuid = restaurant.sfdc_restaurant_uuid;
  const [websiteAdminUrl, setWebsiteAdminUrl] = useState<string>('');
  const [websiteAdminUrlError, setWebsiteAdminUrlError] = useState<string>('');
  const [websiteAdminPasswordError, setWebsiteAdminPasswordError] =
    useState<string>('');
  const [websiteAdminPassword, setWebsiteAdminPassword] = useState<string>('');
  const [websiteAdminUsernameError, setWebsiteAdminUsernameError] =
    useState<string>('');
  const [websiteAdminUsername, setWebsiteAdminUsername] = useState<string>('');
  const {
    isGetWebsiteAccessRequestLoading,
    hasGetWebsiteAccessRequestError,
    getWebsiteAccessRequestResponse,
  } = useGetWebsiteAccess(uuid);
  const { isPutWebsiteAccessRequestLoading, submitWebsiteAccessRequest } =
    usePutWebsiteAccess();
  const responsibleParty = getWebsiteAccessRequestResponse?.responsible_party;

  useEffect(() => {
    if (selectedOption === WebsiteAccessOptions.RESTAURANT_PARTNER) {
      if (email && !isFormCompleted) {
        setIsFormCompleted(true);
      } else if (!email && isFormCompleted) {
        setIsFormCompleted(false);
      }

      if (!email && emailError) {
        setEmailError('');
      }
    }
  }, [email]);

  useEffect(() => {
    if (selectedOption === WebsiteAccessOptions.THIRD_PARTY_DEVELOPER) {
      if ((name || email) && !isFormCompleted) {
        setIsFormCompleted(true);
      } else if (!name && !email && isFormCompleted) {
        setIsFormCompleted(false);
      }
    }
  }, [name, email, phoneNumber]);

  useEffect(() => {
    if (selectedOption === WebsiteAccessOptions.CHOWNOW) {
      if (
        (websiteAdminUrl || websiteAdminUsername || websiteAdminPassword) &&
        !isFormCompleted
      ) {
        setIsFormCompleted(true);
      } else if (
        !websiteAdminUrl &&
        !websiteAdminUsername &&
        !websiteAdminPassword &&
        isFormCompleted
      ) {
        setIsFormCompleted(false);
      }
    }
  }, [websiteAdminUrl, websiteAdminUsername, websiteAdminPassword]);

  const handleSelectionChange = (option: WebsiteAccessOptions) => {
    setEmail('');
    setEmailError('');
    setName('');
    setNameError('');
    setPhoneNumber('');
    setPhoneNumberError('');
    setSelectedOption(option);
    setWebsiteAdminUrl('');
    setWebsiteAdminUrlError('');
    setWebsiteAdminUsername('');
    setWebsiteAdminUsernameError('');
    setWebsiteAdminPassword('');
    setWebsiteAdminPasswordError('');
    setIsFormCompleted(false);
  };

  const handleOnSave = async () => {
    if (selectedOption === WebsiteAccessOptions.CHOWNOW) {
      const truncatedWebsiteAdminUrl = websiteAdminUrl.length > 255
        ? websiteAdminUrl.slice(0, 255)
        : websiteAdminUrl;

      const hasInvalidWebsiteAdminUrl = websiteAccessAdminUrlValidator(
        truncatedWebsiteAdminUrl,
        setWebsiteAdminUrlError
      );
      const hasInvalidWebsiteUsername = websiteAccessAdminUsernameValidator(
        websiteAdminUsername,
        setWebsiteAdminUsernameError
      );
      const hasInvalidWebsitePassword = websiteAccessAdminPasswordValidator(
        websiteAdminPassword,
        setWebsiteAdminPasswordError
      );
      let hasPutWebsiteAccessRequestError = false;

      if (
        !hasInvalidWebsiteAdminUrl &&
        !hasInvalidWebsiteUsername &&
        !hasInvalidWebsitePassword
      ) {
        const submittedWebsiteAccessData: WebsiteAccessResponse = {
          responsible_party: 'chownow',
          metadata: {
            website_admin_url: truncatedWebsiteAdminUrl,
            website_admin_username: websiteAdminUsername,
            website_admin_password: websiteAdminPassword,
          },
        };

        hasPutWebsiteAccessRequestError = await submitWebsiteAccessRequest(
          uuid,
          submittedWebsiteAccessData
        );

        if (hasPutWebsiteAccessRequestError) {
          setIsErrorBannerDisplayed(true);
        }
      }

      return (
        hasPutWebsiteAccessRequestError ||
        hasInvalidWebsiteAdminUrl ||
        hasInvalidWebsiteUsername ||
        hasInvalidWebsitePassword
      );
    } else if (selectedOption === WebsiteAccessOptions.RESTAURANT_PARTNER) {
      const hasError = emailValidator(email, setEmailError);
      let hasPutWebsiteAccessRequestError = false;

      if (!hasError) {
        const submittedWebsiteAccessData: WebsiteAccessResponse = {
          responsible_party: 'restaurant_partner',
          metadata: {
            email_address: email,
          },
        };

        hasPutWebsiteAccessRequestError = await submitWebsiteAccessRequest(
          uuid,
          submittedWebsiteAccessData
        );

        if (hasPutWebsiteAccessRequestError) {
          setIsErrorBannerDisplayed(true);
        }
      }

      return hasPutWebsiteAccessRequestError || hasError;
    } else {
      const hasInvalidEmail = emailValidator(email, setEmailError);
      const hasInvalidName = nameValidator(name, setNameError);
      const hasInvalidPhoneNumber = phoneNumberValidator(
        phoneNumber,
        setPhoneNumberError
      );
      let hasPutWebsiteAccessRequestError = false;

      if (!hasInvalidEmail && !hasInvalidName && !hasInvalidPhoneNumber) {
        const submittedWebsiteAccessData: WebsiteAccessResponse = {
          responsible_party: 'third_party_developer',
          metadata: {
            full_name: name,
            email_address: email,
            phone_number: phoneNumber,
          },
        };

        hasPutWebsiteAccessRequestError = await submitWebsiteAccessRequest(
          uuid,
          submittedWebsiteAccessData
        );

        if (hasPutWebsiteAccessRequestError) {
          setIsErrorBannerDisplayed(true);
        }
      }

      return (
        hasPutWebsiteAccessRequestError ||
        hasInvalidEmail ||
        hasInvalidName ||
        hasInvalidPhoneNumber
      );
    }
  };

  const isLoading =
    isWizNavProgressRequestLoading ||
    isGetWebsiteAccessRequestLoading;
  const hasError =
    hasWizNavProgressRequestError || hasGetWebsiteAccessRequestError;

  let content = (
    <>
      <WebsiteAccessCard
        selectedOption={selectedOption}
        onSelectionChange={handleSelectionChange}
        websiteAccessResponse={getWebsiteAccessRequestResponse}
      />
      {selectedOption && (
        <InfoCard
          email={email}
          emailError={emailError}
          name={name}
          nameError={nameError}
          phoneNumber={phoneNumber}
          phoneNumberError={phoneNumberError}
          selectedOption={selectedOption}
          setEmail={setEmail}
          setEmailError={setEmailError}
          setName={setName}
          setNameError={setNameError}
          setPhoneNumber={setPhoneNumber}
          setPhoneNumberError={setPhoneNumberError}
          setWebsiteAdminUrl={setWebsiteAdminUrl}
          setWebsiteAdminUrlError={setWebsiteAdminUrlError}
          setWebsiteAdminUsername={setWebsiteAdminUsername}
          setWebsiteAdminUsernameError={setWebsiteAdminUsernameError}
          setWebsiteAdminPassword={setWebsiteAdminPassword}
          setWebsiteAdminPasswordError={setWebsiteAdminPasswordError}
          websiteAdminUrl={websiteAdminUrl}
          websiteAdminUrlError={websiteAdminUrlError}
          websiteAdminUsername={websiteAdminUsername}
          websiteAdminUsernameError={websiteAdminUsernameError}
          websiteAdminPassword={websiteAdminPassword}
          websiteAdminPasswordError={websiteAdminPasswordError}
        />
      )}
    </>
  );

  if (isLoading) {
    content = <LoadingState />;
  } else if (hasError) {
    content = <ErrorCard />;
  } else if (responsibleParty) {
    content = (
      <SavedWebsiteAccessCard response={getWebsiteAccessRequestResponse} />
    );
  }

  return (
    <>
      {responsibleParty && (
        <Banner variant="success">
          {WEBSITE_ACCESS_BANNERS[responsibleParty]}
        </Banner>
      )}
      {isErrorBannerDisplayed && (
        <Banner variant="caution">
          {WebsiteAccessBannerMessages.ERROR_MESSAGE}
        </Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Website Access"
          title={ONB_WIZARD_INTRO.websiteAccessTitle}
          description={ONB_WIZARD_INTRO.websiteAccessDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterWebsiteAccess
            hasPresubmittedData={!!responsibleParty}
            isLoading={isPutWebsiteAccessRequestLoading}
            isFormCompleted={isFormCompleted}
            onSave={handleOnSave}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default WebsiteAccess;
