import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import MANAGED_DELIVERY_FIXTURE from './GET-200-managed-delivery-new';
import { ORDER_AHEAD_FIXTURE } from './GET-200-base-fixure';

const MANAGED_DELIVERY_FUTURE_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...MANAGED_DELIVERY_FIXTURE,
  is_order_ahead: true,
  fulfillment_times: {
    ...ORDER_AHEAD_FIXTURE,
    original_estimated_arrival: '202311101413',
  },
  managed_delivery: {
    actual_delivery_time: null,
    actual_pickup_time: null,
    courier_name: 'Lilo Pelekai',
    estimated_delivery_time: null,
    estimated_pickup_time: null,
    id: '6',
    label: 'Uber',
    last_updated_at: null,
    message: 'This order will be picked up by Uber - please package accordingly. All tips go to courier.',
    status: null,
    tracking_url: null,
  },
};

export default MANAGED_DELIVERY_FUTURE_FIXTURE;
