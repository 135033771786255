import React, { FC } from 'react';
import { getSearchParam } from 'helpers/url';
import { useGetStripeVerificationUrl } from 'api/stripe';
import { ReactComponent as BadgeInfo } from 'images/icon-badge-info-md.svg';
import ErrorContent from './ErrorContent';
import {
  ActivateButton,
  Checkmarks,
  ContentLeft,
  ContentRight,
  IconArrowOut,
  IconCheck,
  InfoCard,
  InfoLabel,
  Status,
  StatusButtons,
  Subtext,
} from './styles';
import { StatusContentProps } from './types';

const InactiveContent: FC<StatusContentProps> = ({ restaurant, urlPath }) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const {
    hasGetStripeVerificationUrlRequestError,
    isGetStripeVerificationUrlRequestLoading,
    getStripeVerificationUrl,
  } = useGetStripeVerificationUrl();

  const handleActivateClick = () => {
    const navigateToStripeVerificationUrl = async () => {
      const response = await getStripeVerificationUrl(restaurant, urlPath);

      if (response?.url) {
        window.location.href = `${response.url}${isComingFromHomepage ? '&origin=homepage' : ''}`;
      }
    };

    void navigateToStripeVerificationUrl();
  };

  if (hasGetStripeVerificationUrlRequestError) {
    return (
      <ErrorContent
        buttonText="Try again"
        errorMessage="Oops! There was an issue redirecting you to Stripe"
        isLoading={isGetStripeVerificationUrlRequestLoading}
        onClick={handleActivateClick}
      />
    );
  }

  return (
    <>
      <ContentLeft>
        <BadgeInfo />
        <Status>Your Stripe account is inactive</Status>
        <Subtext>
          The button below will link out to submit your details and activate
          your account within their system.
        </Subtext>
        <StatusButtons>
          <ActivateButton
            isLoading={isGetStripeVerificationUrlRequestLoading}
            /** Due to the way the Button component is written we can't pass the icon
             * through the 'icon' prop because it places it on the wrong side of the text.
             */
            label={(
              <>
                <span>Activate Stripe Account</span>
                <IconArrowOut />
              </>
            )}
            onClick={handleActivateClick}
          />
        </StatusButtons>
      </ContentLeft>
      <ContentRight>
        <InfoCard>
          <InfoLabel>Have these items ready:</InfoLabel>
          <Checkmarks>
            <li>
              <IconCheck />
              <span>Social Security Number</span>
            </li>
            <li>
              <IconCheck />
              <span>Driver&apos;s License / Photo ID</span>
            </li>
          </Checkmarks>
        </InfoCard>
      </ContentRight>
    </>
  );
};

export default InactiveContent;
