import { useEffect, useState } from 'react';
import { requestCustomApi } from 'helpers/api';
import { PromotionResponse, PromotionPutRequestBody } from 'types/promotion';

/**
 * Get promotion data for Direct restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const useGetPromotion = (uuid: string | null) => {
  const [
    isGetPromotionRequestLoading,
    setIsGetPromotionRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetPromotionRequestError, setHasGetPromotionRequestError] =
    useState<boolean>(false);
  const [getPromotionRequestResponse, setGetPromotionRequestResponse] =
    useState<PromotionResponse>();

  useEffect(() => {
    const getPromotion = async () => {
      try {
        const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: PromotionResponse = await requestCustomApi(
          `v1/restaurant/${uuid}/promotion`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        setGetPromotionRequestResponse(response);
      } catch (err) {
        setHasGetPromotionRequestError(true);
      } finally {
        setIsGetPromotionRequestLoading(false);
      }
    };

    if (uuid) {
      void getPromotion();
    }
  }, []);

  return {
    hasGetPromotionRequestError,
    isGetPromotionRequestLoading,
    getPromotionRequestResponse,
  };
};

/**
 * Onboarding Wizard PUT promotion API
 * @param  {string} uuid - ChowNow Restaurant UUID
 * @param  {PromotionPutRequestBody} selectedPromotion - Currently selected promotion
 */
export const usePutPromotion = () => {
  const [isPutPromotionRequestLoading, setIsPutPromotionRequestLoading] =
    useState<boolean>(false);

  const postOnboardingPromotion = async (
    uuid: string | null,
    requestBody: PromotionPutRequestBody
  ) => {
    let hasError = false;
    const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

    setIsPutPromotionRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/promotion`,
        env,
        'PUT',
        requestBody,
        // TODO: remove these once requestCustomApi is converted over to TS with optional params
        undefined,
        undefined
      );
    } catch (err) {
      hasError = true;
    } finally {
      setIsPutPromotionRequestLoading(false);
    }

    return hasError;
  };

  return {
    isPutPromotionRequestLoading,
    postOnboardingPromotion,
  };
};
