import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';

import { Button } from '@chownow/cn-web-components';
import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

function DialogModalSecondary(props) {
  const { hideModal } = useContext(ModalContext);
  const {
    dataTestId,
    icon,
    title,
    message,
    onClose,
    closeIcon,
    confirmLabel,
    cancelLabel,
    children,
  } = props;

  function handleOnClose(didConfirm) {
    if (onClose) onClose(didConfirm);
    hideModal();
  }

  return (
    <div
      className={classNames(styles.dialogModalSecondary, {
        [styles.extraSpace]: closeIcon,
      })}
    >
      {closeIcon && (
        <IconClose className={styles.iconClose} onClick={() => hideModal()} />
      )}
      {icon && <div className={styles.icon}>{icon}</div>}
      {title && <h1 className={styles.title}>{title}</h1>}
      {message && (
        <p className={styles.message} data-testid={dataTestId}>
          {message}
        </p>
      )}
      {children}
      <div>
        <Button
          style={{ width: cancelLabel ? 'initial' : '100%' }}
          label={confirmLabel || 'OK'}
          onClick={() => handleOnClose(true)}
        />

        {cancelLabel && (
          <Button
            variant="outline"
            onClick={() => handleOnClose(false)}
            label={cancelLabel}
          />
        )}
      </div>
    </div>
  );
}

DialogModalSecondary.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  closeIcon: PropTypes.bool,
  confirmLabel: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default DialogModalSecondary;
