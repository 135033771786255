import { logException } from 'helpers/loggers';
import { Buffer } from 'buffer';

/**
 * @param {HTMLImageElement} image - Image File Object
 * @returns {Blob} - URL reference to Binary Data chunk
 */
export function convertImageToJpeg(image) {
  let canvas;

  try {
    // uses canvas to recreate the image to save as a new file type
    canvas = document.createElement('canvas');
    const width = image.naturalWidth;
    const height = image.naturalHeight;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, 0, 0, width, height);
  } catch (err) {
    logException(`Failed to create Canvas: ${err}`);
  }

  const imageUrl = canvas.toDataURL('image/jpeg');

  //  Convert to readable binary for S3
  return Buffer.from(
    imageUrl.replace(/^data:image\/\w+;base64,/, ''),
    'base64'
  );
}

export function getBaseUrl(image) {
  if (image) {
    return `${image.base_url}f=jpeg/${image.file_key}`;
  }
}
