import styled from 'styled-components';
import LoadingState from 'containers/Restaurant/LoadingState';
import ErrorState from 'containers/Restaurant/ErrorState';
import { space } from 'styles/variables';

export const StyledErrorState = styled(ErrorState)`
  padding: 196px ${space.x2} ${space.x2};
`;

export const StyledLoadingState = styled(LoadingState)`
  display: flex;
  justify-content: center;
  padding: 196px ${space.x2} ${space.x2};
`;
