import React, { FC } from 'react';
import styles from './styles.module.scss';

interface CoverPhotoInstructionsProps {
  blogLabel: string;
  blogURL: string;
}

const CoverPhotoInstructions: FC<CoverPhotoInstructionsProps> = ({ blogURL, blogLabel }) => (
  <div className={styles.coverPhotoInstructions}>
    <p>
      <span className={styles.boldText}>Please include only food items in this photo.</span>
      <span> Our team will ask you to replace logos, storefronts or other non-food images.</span>
    </p>
    <p>
      <span>For more info, read our </span>
      <a href={blogURL} aria-label={blogLabel}>
        {blogLabel}
      </a>
      <span>.</span>
    </p>
  </div>
);

export default CoverPhotoInstructions;
