import { Button } from '@chownow/cn-web-components';
import React, { FC, useEffect, useState } from 'react';
import { usePostRefundOrder } from 'api/orders/refunds';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import {
  Inputs,
  Message,
  StyledInput,
  StyledModal,
  StyledTextArea,
  Title,
} from './styles';
import ConfirmRefundModal from '../ConfirmRefundModal';

interface FullRefundModalProps {
  order: OrderDetailsV6GetSuccessResponse;
  onClose: () => void;
  onRefundSuccess: () => Promise<void>;
  setErrorMessage: (message: string) => void;
  setSuccessMessage: (message: string) => void;
}

const FullRefundModal: FC<FullRefundModalProps> = ({
  order,
  onClose,
  onRefundSuccess,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const [approvedBy, setApprovedBy] = useState('');
  const [reason, setReason] = useState('');
  const [approvedByErrorMessage, setApprovedByErrorMessage] = useState('');
  const [reasonErrorMessage, setReasonErrorMessage] = useState('');
  const [shouldShowConfirmModal, setShouldShowConfirmModal] = useState(false);

  const {
    updateOrderStatusErrorMessage,
    isUpdateOrderStatusRequestLoading,
    refundOrder,
  } = usePostRefundOrder();

  // Pass error message to parent
  useEffect(() => {
    if (updateOrderStatusErrorMessage) {
      setErrorMessage(updateOrderStatusErrorMessage);
    }
  }, [updateOrderStatusErrorMessage]);

  // Clear errors when new values are entered
  useEffect(() => {
    if (approvedBy) {
      setApprovedByErrorMessage('');
    }

    if (reason) {
      setReasonErrorMessage('');
    }
  }, [approvedBy, reason]);

  const submitRefund = async () => {
    if (approvedBy && reason) {
      if (order?.id) {
        const resp = await refundOrder(order.id, {
          refund_type: 'full',
          name: approvedBy,
          reason,
        });

        if (resp) {
          setSuccessMessage('You have successfully refunded your order');
          await onRefundSuccess();
          onClose();
        }
      }
    }
  };

  const confirmRefund = () => {
    if (!approvedBy) {
      setApprovedByErrorMessage('Approved by required');
    }

    if (!reason) {
      setReasonErrorMessage('Reason required');
    }

    if (approvedBy && reason) {
      setShouldShowConfirmModal(true);
    }
  };

  const onButtonPress = () => {
    if (approvedBy && reason) {
      void submitRefund();
    }
  };

  return shouldShowConfirmModal ? (
    <ConfirmRefundModal
      isLoading={isUpdateOrderStatusRequestLoading}
      onClose={() => setShouldShowConfirmModal(false)}
      onConfirm={onButtonPress}
    />
  ) : (
    <StyledModal onModalClose={onClose}>
      <Title>Full Refund</Title>
      <Message>
        Please enter your name and reason for refund. A copy of the receipt will
        automatically be sent to the diner.
      </Message>
      <Inputs>
        <StyledInput
          type="text"
          label="Approved by (required)"
          name="approvedBy"
          onChange={setApprovedBy}
          value={approvedBy}
          helpText="Diners will not see this"
          errorText={approvedByErrorMessage}
        />
        <StyledTextArea
          type="text"
          label="Reason (required)"
          name="reason"
          onChange={setReason}
          value={reason}
          errorText={reasonErrorMessage}
          isMultiLine
        />
      </Inputs>
      <Button
        size="medium"
        label={`Refund $${(order.total ?? 0).toFixed(2)}`}
        isFullWidth
        isLoading={isUpdateOrderStatusRequestLoading}
        onClick={confirmRefund}
      />
    </StyledModal>
  );
};

export default FullRefundModal;
