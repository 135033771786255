import React, { FC } from 'react';

import { RestaurantHoursContextProvider } from './RestaurantHoursContext/RestaurantHoursContextProvider';
import RestaurantHours from './RestaurantHours';

interface RestaurantHoursContainerProps {
  uuid: string;
}

const RestaurantHoursContainer: FC<RestaurantHoursContainerProps> = ({
  uuid,
}) => (
  <RestaurantHoursContextProvider uuid={uuid}>
    <RestaurantHours />
  </RestaurantHoursContextProvider>
);

export default RestaurantHoursContainer;
