import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';
import { ORDER_ACTION } from 'helpers/constants';
import { orderChooseTimeValidator } from 'helpers/validators';
import { Formik, Form, Field } from 'formik';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { Button } from '@chownow/cn-web-components';

import styles from './styles.module.scss';

function AcceptOrderModal(props) {
  const { acceptOrCancelOrder } = props;
  const { hideModal } = useContext(ModalContext);

  function acceptOrder(time) {
    acceptOrCancelOrder(ORDER_ACTION.accept, time);
    hideModal();
  }

  return (
    <div className={styles.acceptOrderModal}>
      <button className={styles.iconClose} onClick={() => hideModal()}>
        <IconClose />
      </button>
      <h3 className={styles.modalTitle}>Choose time</h3>
      <Formik
        validate={orderChooseTimeValidator}
        initialValues={{
          time: '',
          customTime: '',
        }}
        onSubmit={values => {
          acceptOrder(values.time);
        }}
      >
        {({ values, handleChange, isValid, dirty, errors, setFieldValue }) => (
          <Form>
            {errors.time && (
              <div className={styles.errorMessage} data-testid="errorMessage">
                {errors.time}
              </div>
            )}
            <div role="group" aria-labelledby="preset-time-group">
              <div className={styles.sectionTitle}>Minutes until ready</div>
              <div className={styles.timeButtons}>
                <Field
                  id="10"
                  type="radio"
                  name="time"
                  value="10"
                  checked={values.time === '10'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="10" className={styles.timeLabel}>
                  <div className={styles.time}>10</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="15"
                  type="radio"
                  name="time"
                  value="15"
                  checked={values.time === '15'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="15" className={styles.timeLabel}>
                  <div className={styles.time}>15</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="20"
                  type="radio"
                  name="time"
                  value="20"
                  checked={values.time === '20'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="20" className={styles.timeLabel}>
                  <div className={styles.time}>20</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="30"
                  type="radio"
                  name="time"
                  value="30"
                  checked={values.time === '30'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="30" className={styles.timeLabel}>
                  <div className={styles.time}>30</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="45"
                  type="radio"
                  name="time"
                  value="45"
                  checked={values.time === '45'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="45" className={styles.timeLabel}>
                  <div className={styles.time}>45</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="60"
                  type="radio"
                  name="time"
                  value="60"
                  checked={values.time === '60'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="60" className={styles.timeLabel}>
                  <div className={styles.time}>60</div>
                  <div className={styles.mins}>Mins</div>
                </label>
              </div>
            </div>

            <label className={styles.sectionTitle}>Custom Time</label>
            <Field
              id="customTime"
              type="text"
              name="customTime"
              className={styles.customTimeInput}
              onChange={e => {
                handleChange(e);
                setFieldValue('time', e.target.value);
              }}
              placeholder="Enter numbers up to 180 minutes"
            />

            <Button
              disabled={!(isValid && dirty)}
              type="submit"
              className={styles.button}
              label={'Accept'}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

AcceptOrderModal.propTypes = {
  acceptOrCancelOrder: PropTypes.func,
};

export default AcceptOrderModal;
