import React, { useContext, useState, useEffect } from 'react';
import { Route, Link, Routes, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { get } from 'lodash';

import { requestOrderDetails, updateOrderStatus } from 'api/restaurant';
import { ModalContext } from 'context/ModalContext';
import useErrorHandler from 'hooks/useErrorHandler';
import LoadingSpinner from 'components/LoadingSpinner';
import { prettifyPhoneNumber } from '@chownow/cn-web-utils/format';
import { ORDER_ACTION, ORDER_STATUS } from 'helpers/constants';
import { getOrderDateTime } from 'helpers/dateTimeOld';
import {
  CONNECTION_FAILED_MODAL_COPY,
  DIALOG_MODAL_COPY,
} from 'helpers/modals';
import { restaurantType } from 'helpers/prop-types';

import { UserContext } from 'context/UserContext';
import ConnectionFailedModal from 'components/Modals/ConnectionFailedModal';
import DialogModal from 'components/Modals/DialogModal';
import EmbeddedAdmin from 'containers/EmbeddedAdmin';
import AcceptOrderModal from 'components/Modals/AcceptOrderModal';
import AcceptOrderAheadModal from 'components/Modals/AcceptOrderAheadModal';
import Outlink from 'components/Outlink';
import ListItem from 'containers/Restaurant/Orders/OrderDetailsRefunds/ListItem';
import OrderTotals from 'containers/Restaurant/Orders/OrderDetailsRefunds/OrderTotalsOld';
import OrderInfo from 'containers/Restaurant/Orders/OrderDetailsRefunds/OrderInfoOld';
import ContentContainer from 'components/ContentContainer';

import styles from './styles.module.scss';

function OrderDetails(props) {
  const { restaurant } = props;
  const {
    user: { is_chownow_admin, is_super_admin },
  } = useContext(UserContext);
  const { orderId } = useParams();
  const orderListRoute = `/restaurant/${restaurant.id}/orders`;
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState();
  const { hideModal, showModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({
    discounts: [],
    fulfillment_times: {},
    items: [{}],
  });
  const customerId = get(orderDetails, 'customer.id');
  const orderSubmitted =
    orderDetails.status === ORDER_STATUS.submitted &&
    !orderDetails.is_order_ahead;
  const orderAheadSubmitted =
    orderDetails.status === ORDER_STATUS.submitted &&
    orderDetails.is_order_ahead;
  const totalItems = orderDetails.items
    .map(item => item.quantity)
    .reduce((acc, quantity) => acc + quantity, 0);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderStatus, restaurant]);

  useErrorHandler(hasError, () => {
    showModal(ConnectionFailedModal, {
      message: CONNECTION_FAILED_MODAL_COPY.orderDetailsError.message,
      title: 'Something went wrong.',
    });
  });

  async function fetchOrderDetails() {
    setIsLoading(true);
    const orderDetails = await requestOrderDetails(orderId);

    if (!orderDetails.errors && orderDetails.restaurant.id !== restaurant.id) {
      navigate(orderListRoute);
    } else if (!orderDetails.errors) {
      setOrderDetails(orderDetails);
    } else {
      setHasError(true);
    }

    setIsLoading(false);
  }

  async function acceptOrCancelOrder(action, eta) {
    setIsLoading(true);
    const etaNum = parseInt(eta, 10);

    const response = await updateOrderStatus(orderId, action, etaNum);

    if (response.errors) {
      setIsLoading(false);
      hideModal();
      showModal(DialogModal, {
        message: `${
          response.errors[0]
            ? response.errors[0].message
            : 'error updating status'
        }`,
        onClose: () => {
          window.location.reload();
          hideModal();
        },
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
    setIsLoading(false);
    setOrderStatus(orderDetails.status);
  }

  function chooseTime() {
    showModal(AcceptOrderModal, {
      acceptOrCancelOrder: acceptOrCancelOrder,
    });
  }

  function acceptTime() {
    showModal(AcceptOrderAheadModal, {
      acceptOrCancelOrder: acceptOrCancelOrder,
      requestedFulfillTime:
        orderDetails.fulfillment_times.requested_fulfillment,
    });
  }

  function confirmCancelOrder() {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.cancelOrder,
      onClose: handleCancelOrder,
    });
  }

  function handleCancelOrder(didConfirm) {
    if (didConfirm) {
      acceptOrCancelOrder(ORDER_ACTION.cancel);
    }
  }

  function getOrderGrabbedBy() {
    // Strip out everything except user email
    const grabbedByEmail =
      orderDetails.grabbed_by &&
      orderDetails.grabbed_by.replace(/@[^@]+@[^@]*$/, '');

    // Target only device
    // tablet returns an empty string for device name, if grabbed_by ends
    // in @ without anything after, it is from tablet
    const grabbedByDevice =
      orderDetails.grabbed_by && orderDetails.grabbed_by.match(/[@]$/)
        ? 'tablet'
        : orderDetails.grabbed_by && orderDetails.grabbed_by.replace(/.*@/, '');

    // If email has chownow.com domain, hide email and return 'ChowNow team'
    const grabbedBy = /^[A-Za-z0-9._%+-]+@chownow.com$/.test(grabbedByEmail)
      ? 'ChowNow team'
      : grabbedByEmail;

    // only show chownow emails to super users and CN admins
    const grabbedByPermissionFilter =
      is_super_admin || is_chownow_admin ? grabbedByEmail : grabbedBy;

    const autoAccept = orderDetails.grabbed_by === 'CN Auto Accept';

    // if order status is canceled and there is no designated grabbed_by user,
    // that means that the order was automatically canceled due to inactivity.
    if (
      orderDetails.status === ORDER_STATUS.canceled &&
      !orderDetails.grabbed_by
    ) {
      return 'Order was automatically canceled due to time limit';
    } else if (orderDetails.grabbed_by) {
      return `Last action ${
        autoAccept ? '' : `by ${grabbedByPermissionFilter}`
      } via ${grabbedByDevice}
      on
      ${getOrderDateTime(orderDetails.fulfillment_times.updated_at)}`;
    } else {
      return '';
    }
  }

  if (hasError) {
    return null;
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <ContentContainer>
        <Routes>
          <Route
            path={`/customer/${customerId}`}
            element={
              <EmbeddedAdmin
                src={`customer_by_cust_id/${customerId}`}
                title="Customer Info"
                key={`${customerId}/customer_by_cust_id`}
              />
            }
          />
          <Route
            path="/*"
            element={
              <div className={styles.container}>
                <Helmet title="Order Details" />
                <h2 className={styles.pageHeader}>Order #{orderId}</h2>
                <div className={styles.blocksContainer}>
                  <div className={styles.customerBlock}>
                    <h4 className={styles.blockTitle}>Customer</h4>
                    <div className={styles.customerDetails}>
                      <Link
                        className={classNames(styles.customerDetailsTitle)}
                        to={`/customer/${customerId}`}
                      >
                        {`${orderDetails.customer.first_name} `}
                        {`${orderDetails.customer.last_name} `}
                      </Link>
                      {prettifyPhoneNumber(orderDetails.customer.phone_number)}
                    </div>
                    {orderDetails.customer.delivery && (
                      <div className={styles.customerDetails}>
                        <span className={styles.customerDetailsTitle}>
                          Address:
                        </span>
                        <span className={styles.customerAddress}>
                          <p className={styles.infoParagraph}>
                            {
                              orderDetails.customer.delivery.address
                                .street_address1
                            }
                          </p>
                          {orderDetails.customer.delivery.address
                            .street_address2 && (
                            <p className={styles.infoParagraph}>
                              {
                                orderDetails.customer.delivery.address
                                  .street_address2
                              }
                            </p>
                          )}
                          {`${orderDetails.customer.delivery.address.city},
                  ${orderDetails.customer.delivery.address.state}
                  ${orderDetails.customer.delivery.address.zip}`}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={styles.ctaBlock}>
                    {orderAheadSubmitted && (
                      <>
                        <div
                          className={styles.chooseTimeButton}
                          onClick={() => {
                            acceptTime();
                          }}
                        >
                          Confirm Time
                        </div>
                        <div
                          className={styles.declineButton}
                          onClick={() => {
                            confirmCancelOrder();
                          }}
                        >
                          Decline
                        </div>
                      </>
                    )}
                    {orderSubmitted && (
                      <>
                        <div
                          className={styles.chooseTimeButton}
                          onClick={() => {
                            chooseTime();
                          }}
                        >
                          Choose Time
                        </div>
                        <div
                          className={styles.declineButton}
                          onClick={() => {
                            confirmCancelOrder();
                          }}
                        >
                          Decline
                        </div>
                      </>
                    )}
                    <Outlink
                      className={classNames(styles.detailsButton, {
                        [styles.detailsButtonStyled]:
                          orderDetails.status !== ORDER_STATUS.submitted,
                      })}
                      to={`
              ${process.env.REACT_APP_ADMIN_PYTHON_APP_URL}/restaurant/${restaurant.id}/order/${orderId}
              `}
                    >
                      View Full Order Details
                    </Outlink>
                  </div>
                  <div className={styles.orderInfo}>
                    <h4 className={styles.blockTitle}>Order Info</h4>
                    <OrderInfo orderDetails={orderDetails} />
                  </div>
                  <div className={styles.orderItems}>
                    <h4 className={styles.blockTitle}>Items ({totalItems})</h4>
                    <div className={styles.itemList}>
                      <ul>
                        {orderDetails.items.map(item => (
                          <ListItem item={item} key={item.id} />
                        ))}
                      </ul>
                      <OrderTotals orderDetails={orderDetails} />
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>{getOrderGrabbedBy()}</div>
              </div>
            }
          />
        </Routes>
      </ContentContainer>
    );
  }
}

OrderDetails.propTypes = {
  restaurant: restaurantType,
};

export default OrderDetails;
