import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Login from './Login';
import ActivateAccount from './ActivateAccount';
import ActivateAccountCredentials from './ActivateAccountCredentials';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import LogoTopBar from 'components/LogoTopBar';

import styles from './styles.module.scss';

const UserAuth = () => {
  return (
    <>
      <LogoTopBar />
      <div className={styles.main}>
        <Routes>
          <Route exact path={``} element={<Login />} />
          <Route
            exact
            path={`/activate-account`}
            element={<ActivateAccount />}
          />
          <Route
            exact
            path={`/activate-account-credentials`}
            element={<ActivateAccountCredentials />}
          />
          <Route exact path={`/forgot-password`} element={<ForgotPassword />} />
          <Route exact path={`/reset-password`} element={<ResetPassword />} />
          {/* TODO: Sign-up Component */}
        </Routes>
      </div>
    </>
  );
};

export default UserAuth;
