import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';

const getColorForCircle = (type: string, isOpen: boolean) => {
  if (type === 'moreInfo') {
    if (isOpen) {
      return semanticColors.content.active;
    } else {
      return semanticColors.content.primary;
    }
  } else if (type === 'tablet' || type === 'middleWareAndTablet') {
    return ''; // no fill
  }
  return semanticColors.content.caution;
};

const getColorForPath = (type: string, isOpen: boolean) => {
  if ((type === 'tablet' || type === 'middleWareAndTablet') && isOpen) {
    return semanticColors.content.active;
  }
  return semanticColors.content.primary;
};

export const PopoverWrapper = styled.div<{ $type: string; $isOpen: boolean }>`
  display: inline-block;
  position: relative;

  /* Adds blue hover state to popover icon */
  ${({ $type, $isOpen }) =>
    `
      circle {
        fill: ${getColorForCircle($type, $isOpen)};
      }
      path {
        fill: ${getColorForPath($type, $isOpen)};
      }
    `}

  /* Adds blue hover state to popover icon */
  ${({ $type }) =>
    $type === 'moreInfo' &&
    `
      &:hover circle {
        fill: ${semanticColors.content.active};
      }
    `}

  /* Adds blue hover state to tablet icon path */
  ${({ $type }) =>
    ($type === 'tablet' || $type === 'middleWareAndTablet') &&
    `
      &:hover path {
        fill: ${semanticColors.content.active};
      }
    `}
`;

export const PopoverContent = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: ${space.x1};
  min-width: 240px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 100;
`;

export const StyledButton = styled.button<{ $isOpen: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;

  &:focus-visible {
    outline: 1px dashed ${semanticColors.content.primary};
    outline-offset: 2px;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    path {
      fill: ${semanticColors.content.active};
    }
  `};
`;
