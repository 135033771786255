import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';

import { EDIT_TYPE } from 'helpers/constants';
import styles from './styles.module.scss';

function CategoryTypeSelector(props) {
  const { onSelectType, editMode } = props;

  const indicatorRef = useRef();
  const positionRight = '100px';
  const itemsProps = { transform: 'translateX(0px)' };
  const modifiersProps = { transform: `translateX(${positionRight})` };
  const defaultProps =
    editMode === EDIT_TYPE.modifiers ? modifiersProps : itemsProps;
  const [springStyles, api] = useSpring(() => ({
    ...defaultProps
  }));

  const divStyles = {
    height: '2px',
    position: 'absolute',
    bottom: '-1px',
    backgroundColor: '#222',
    transform: springStyles.transform,
    width: '100px'
  };

  if (editMode === EDIT_TYPE.items) {
    api.start(itemsProps);
  } else if (editMode === EDIT_TYPE.modifiers) {
    api.start(modifiersProps);
  }

  function handleOnClick(type) {
    onSelectType(type);
  }

  return (
    <div className={styles.categoryTypeSelector}>
      <animated.div ref={indicatorRef} style={divStyles} />
      <button
        onClick={() => handleOnClick(EDIT_TYPE.items)}
        className={styles.typeButton}
      >
        {EDIT_TYPE.items}
      </button>
      <button
        onClick={() => handleOnClick(EDIT_TYPE.modifiers)}
        className={styles.typeButton}
      >
        {EDIT_TYPE.modifiers}
      </button>
    </div>
  );
}

CategoryTypeSelector.propTypes = {
  editMode: PropTypes.string,
  onSelectType: PropTypes.func.isRequired
};

export default CategoryTypeSelector;
