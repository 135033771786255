import { createPortal } from 'react-dom';

const PhotoButtonPortal = ({ children }) => {
  const portal = document.getElementById('photo-button-portal');

  return portal
    ? createPortal(children, document.getElementById('photo-button-portal'))
    : null;
};

export default PhotoButtonPortal;
