import React, { FC } from 'react';
import { MenuPrimary } from '@chownow/cn-web-components';
import Restaurant from 'types/restaurant';
import { OnboardingWizardPages } from 'types/progress';
import { ReactComponent as PictoMenuIcon } from 'images/pictograms/picto-menu-md.svg';
import { ReactComponent as PictoClipboardIcon } from 'images/pictograms/picto-clipboard-md.svg';
import { ReactComponent as PictoHoursIcon } from 'images/pictograms/picto-hours-md.svg';
import { ReactComponent as PictoImageIcon } from 'images/pictograms/picto-image-md.svg';
import { ReactComponent as PictoPaymentIcon } from 'images/pictograms/picto-payment-md.svg';
import { ReactComponent as PictoOrderDirectIcon } from 'images/pictograms/picto-order-direct-md.svg';
import { ReactComponent as PictoPromoIcon } from 'images/pictograms/picto-promo-md.svg';

import styles from './styles.module.scss';

export interface MissingDetailsContentProps {
  missingWizardDetails: string[];
  restaurant: Restaurant;
}

const MissingDetailsContent: FC<MissingDetailsContentProps> = ({
  missingWizardDetails,
  restaurant,
}) => {
  const missingDetailsMessage = () => (
    restaurant?.is_direct_live
      ? "Once you've submitted all of your details, a member of our onboarding team will contact you to coordinate the installation of the ordering widget for your website and finalize your account details."
      : "Once you've submitted all of your details, a member of our onboarding team will contact you to finalize your account details.");

  const onboardingUrlPrefix = `${process.env.PUBLIC_URL}restaurant/${restaurant?.id}/onboarding/`;

  const handleShareMenuClick = () => {
    window.location.href = `${onboardingUrlPrefix}share-menu?origin=homepage`;
  };

  const handleSalesTaxClick = () => {
    window.location.href = `${onboardingUrlPrefix}sales-tax?origin=homepage`;
  };

  const handleRestaurantHoursClick = () => {
    window.location.href = `${onboardingUrlPrefix}restaurant-hours?origin=homepage`;
  };

  const handleWebsiteAccessClick = () => {
    window.location.href = `${onboardingUrlPrefix}website-access?origin=homepage`;
  };

  const handleFoodAndLogoClick = () => {
    window.location.href = `${onboardingUrlPrefix}logo-and-food-image?origin=homepage`;
  };

  const handlePromoClick = () => {
    window.location.href = `${onboardingUrlPrefix}promotion?origin=homepage`;
  };

  const handlePaymentProcessingClick = () => {
    window.location.href = `${onboardingUrlPrefix}payment-processing?origin=homepage`;
  };

  return (
    <>
      <h2 className={styles.missingDetailsHeading}>
        We&apos;re missing some details
      </h2>
      <p className={styles.missingDetailsSubhead}>{missingDetailsMessage()}</p>
      <ul className={styles.detailsContainer}>
        {missingWizardDetails.map(step => {
          let returnedDetail = null;

          if (step === OnboardingWizardPages.SHARED_MENU) {
            returnedDetail = (
              <li className={styles.step} key="share-your-menu">
                <MenuPrimary
                  handleClick={handleShareMenuClick}
                  icon={PictoMenuIcon}
                  label="Share your menu"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.TAX_RATE) {
            returnedDetail = (
              <li className={styles.step} key="sales-tax">
                <MenuPrimary
                  handleClick={handleSalesTaxClick}
                  icon={PictoClipboardIcon}
                  label="Sales tax rate"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.SCHEDULE) {
            returnedDetail = (
              <li className={styles.step} key="restaurant-hours">
                <MenuPrimary
                  handleClick={handleRestaurantHoursClick}
                  icon={PictoHoursIcon}
                  label="Restaurant hours"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.WEBSITE_ACCESS) {
            returnedDetail = (
              <li className={styles.step} key="website-access">
                <MenuPrimary
                  handleClick={handleWebsiteAccessClick}
                  icon={PictoOrderDirectIcon}
                  label="Website access"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.LOGO_AND_FOOD_IMAGE) {
            returnedDetail = (
              <li className={styles.step} key="food and logo">
                <MenuPrimary
                  handleClick={handleFoodAndLogoClick}
                  icon={PictoImageIcon}
                  label="Logo and image"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.PROMOTION) {
            returnedDetail = (
              <li className={styles.step} key="promotion">
                <MenuPrimary
                  handleClick={handlePromoClick}
                  icon={PictoPromoIcon}
                  label="Promotion"
                  showArrow
                />
              </li>
            );
          } else if (step === OnboardingWizardPages.STRIPE_VERIFICATION) {
            returnedDetail = (
              <li className={styles.step} key="payment-processing">
                <MenuPrimary
                  handleClick={handlePaymentProcessingClick}
                  icon={PictoPaymentIcon}
                  label="Payment processing"
                  showArrow
                />
              </li>
            );
          }

          return returnedDetail;
        })}
      </ul>
    </>
  );
};

export default MissingDetailsContent;
