import React from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './styles.module.scss';

export default function Pending() {
  return (
    <div className={styles.container}>
      <Helmet title="Restaurant Could Not Be Verified" />
      <div className={styles.icon}>
        <span role="img" aria-label="light bulb">
          💡
        </span>
      </div>
      <p className={styles.message}>
        Your restaurant is still in the process of getting set up on the ChowNow
        platform. Please reach out to your Account Coordinator if you have
        questions.
      </p>
    </div>
  );
}
