import { useEffect, useState } from 'react';
import { request } from 'helpers/api';
import { IntegrationErrorResponse } from 'types/pos/integration-errors';

/**
 * Get Toast integration errors for restaurant location
 * @param {string} restaurantId - ChowNow Restaurant ID
 */
export const useGetToastIntegrationErrors = (restaurantId: string) => {
  const [
    isGetToastIntegrationErrorsRequestLoading,
    setIsGetToastIntegrationErrorsRequestLoading,
  ] = useState<boolean>(true);
  const [
    hasGetToastIntegrationErrorsRequestError,
    setHasGetToastIntegrationErrorsRequestError,
  ] = useState<boolean>(false);
  const [
    getToastIntegrationErrorsRequestResponse,
    setGetToastIntegrationErrorsRequestResponse,
  ] = useState<IntegrationErrorResponse>();

  useEffect(() => {
    const getIntegrationErrors = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: IntegrationErrorResponse = await request(
          `pos/toast/restaurant/${restaurantId}/integration-errors`
        );

        setGetToastIntegrationErrorsRequestResponse(response);
      } catch (err) {
        setHasGetToastIntegrationErrorsRequestError(true);
      } finally {
        setIsGetToastIntegrationErrorsRequestLoading(false);
      }
    };

    if (restaurantId) {
      void getIntegrationErrors();
    }
  }, []);

  return {
    hasGetToastIntegrationErrorsRequestError,
    isGetToastIntegrationErrorsRequestLoading,
    getToastIntegrationErrorsRequestResponse,
  };
};
