import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const ActivateAccountCardContainer = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 142px;
  padding: ${space.x5};
  text-align: left;

  h2 {
    font: ${label.semi.x14};
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: ${space.x5};
  max-width: 376px;
  width: 100%;
`;

export const Reminders = styled.div`
  li {
    margin-top: ${space.x3};
  }

  svg {
    margin-right: ${space.x1};
    vertical-align: text-bottom;
  }
`;

export const ActivateButton = styled.div`
font: ${label.semi.x14};
  margin-top: ${space.x3};

  // Necessary to override svg path styles on the Button
  svg path {
    fill: url('#stripe-logo_svg__a');
  }
`;
