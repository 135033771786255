import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CATALOG_FILTERS } from 'helpers/constants';
import { ReactComponent as SquareLogo } from 'images/logos/square-logo.svg';

import styles from './styles.module.scss';

export default function LinkedStatus({
  CNId,
  isReadOnly,
  linkedOrPartialItem,
  linkedItemStatus,
  partialItemStatus,
  pos,
  unlinkItem
}) {
  const linkedText = isReadOnly
    ? 'Square'
    : linkedOrPartialItem
    ? linkedOrPartialItem.size || linkedOrPartialItem.name
    : '-';

  function handleUnlinkItem(e) {
    e.stopPropagation();
    unlinkItem(CNId);
  }

  function getLinkedStatusText() {
    if (partialItemStatus) {
      return CATALOG_FILTERS.partial;
    } else if (linkedItemStatus || linkedOrPartialItem) {
      return CATALOG_FILTERS.linked;
    } else {
      return CATALOG_FILTERS.unlinked;
    }
  }

  return (
    <div className={styles.linkedSection}>
      <div className={styles.linkDrop}>
        {pos ? (
          <div>Square Item</div>
        ) : (
          <>
            <div>
              <div className={styles.linkedText}>
                {isReadOnly ? 'pulled from' : 'linked with'}
              </div>
              <div className={styles.itemEmptyLink}>
                <SquareLogo className={styles.logo} />
                <div className={styles.linkText}>{linkedText}</div>
              </div>
            </div>

            <div className={styles.row}>
              <div
                className={classNames(styles.unlink, {
                  // Hidden to preserve proper spacing
                  [styles.hidden]: !linkedOrPartialItem || !unlinkItem
                })}
                onClick={handleUnlinkItem}
                data-testid="unlinkButton"
              >
                Unlink
              </div>

              <div
                className={classNames(styles.linkStatus, {
                  [styles.linked]: linkedOrPartialItem,
                  [styles.partiallyLinked]: partialItemStatus
                })}
              >
                <div className={styles.circle} />
                <span data-testid="linkedStatus">{getLinkedStatusText()}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

LinkedStatus.propTypes = {
  CNId: PropTypes.string,
  isReadOnly: PropTypes.bool,
  linkedItemStatus: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  linkedOrPartialItem: PropTypes.shape(),
  partialItemStatus: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  pos: PropTypes.shape(),
  unlinkItem: PropTypes.func
};
