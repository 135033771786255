import { createContext } from 'react';
import { SelectedFiltersType } from './FilterTypes';

type FilterContextType = {
  onSelectFilter: (filterCategory: string, label: string, value: string, checked: boolean) => void;
  selectedFilters: SelectedFiltersType;
};

export const FilterContext = createContext<FilterContextType>({
  onSelectFilter: () => { /* Default empty function */ },
  selectedFilters: {},
});
