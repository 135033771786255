import React, { FC } from 'react';

import { WebsiteAccessResponse } from 'types/website-access';

import { Heading, Data } from '../styles';
import { WebsiteAccessOptions } from '../../constants';
import { TruncationDisclaimer } from './styles';

interface SavedInfoCardProps {
  response: WebsiteAccessResponse;
}

const SavedInfoCard: FC<SavedInfoCardProps> = ({ response }) => {
  const { metadata, responsible_party } = response;

  let content;

  if (responsible_party === WebsiteAccessOptions.RESTAURANT_PARTNER) {
    content = (
      <>
        <Heading>
          Which email address should we send the unique coding instructions to?
        </Heading>
        <Data>{metadata.email_address}</Data>
      </>
    );
  } else if (
    responsible_party === WebsiteAccessOptions.THIRD_PARTY_DEVELOPER
  ) {
    content = (
      <>
        <Heading>
          What is your web developer or 3rd party&apos;s contact information?
        </Heading>
        <Data>{metadata.full_name}</Data>
        <Data>{metadata.email_address}</Data>
        <Data>{metadata.phone_number}</Data>
      </>
    );
  } else if (responsible_party === WebsiteAccessOptions.CHOWNOW) {
    content = (
      <>
        <Heading>What are your website credentials?</Heading>
        <Data>{metadata.website_admin_url}</Data>
        {metadata.website_admin_url.length === 255 && (
          <TruncationDisclaimer>
            ChowNow formatted this URL for you so it&apos;s compatible within our system
          </TruncationDisclaimer>
        )}
        <Data>Username is hidden for security purposes</Data>
        <Data>Password is hidden for security purposes</Data>
      </>
    );
  } else {
    content = <Heading>An error occured</Heading>;
  }

  return content;
};

export default SavedInfoCard;
