import { Button /* , Checkbox */ } from '@chownow/cn-web-components';
import React, { FC, useEffect, useState } from 'react';
import { usePostRefundOrder } from 'api/orders/refunds';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import {
  Label,
  LineItem,
  Message,
  Revision,
  // Row,
  RowTopAligned,
  Section,
  StyledInput,
  StyledModal,
  StyledTextArea,
  StyledToggleButton,
  Title,
} from './styles';
import ConfirmRefundModal from '../ConfirmRefundModal';
import { applyPercentage, getRefundAmount, getRevisedAmount } from './util';
import RefundByAmountInput from './RefundByAmountInput';

interface RefundByAmountModalProps {
  order: OrderDetailsV6GetSuccessResponse;
  onClose: () => void;
  onRefundSuccess: () => Promise<void>;
  setErrorMessage: (message: string) => void;
  setSuccessMessage: (message: string) => void;
}

const RefundByAmountModal: FC<RefundByAmountModalProps> = ({
  order,
  onClose,
  onRefundSuccess,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const [amount, setAmount] = useState(0);
  const [amountType, setAmountType] = useState('%' as '%' | '$');
  const [isForSubtotalOnly, setIsForSubtotalOnly] = useState(true);
  const [approvedBy, setApprovedBy] = useState('');
  const [reason, setReason] = useState('');
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [approvedByErrorMessage, setApprovedByErrorMessage] = useState('');
  const [reasonErrorMessage, setReasonErrorMessage] = useState('');
  const [shouldShowConfirmModal, setShouldShowConfirmModal] = useState(false);

  const {
    updateOrderStatusErrorMessage,
    isUpdateOrderStatusRequestLoading,
    refundOrder,
  } = usePostRefundOrder();

  // Pass error message to parent
  useEffect(() => {
    if (updateOrderStatusErrorMessage) {
      setErrorMessage(updateOrderStatusErrorMessage);
    }
  }, [updateOrderStatusErrorMessage]);

  // Clear errors when new values are entered
  useEffect(() => {
    if (approvedBy) {
      setApprovedByErrorMessage('');
    }

    if (reason) {
      setReasonErrorMessage('');
    }
  }, [approvedBy, reason]);

  const submitRefund = async () => {
    if (approvedBy && reason) {
      if (order?.id) {
        const resp = await refundOrder(order.id, {
          refund_type: 'partial_by_amount',
          subtotal: parseFloat(
            getRevisedAmount(amount, amountType, order, true)
          ),
          tax:
            amountType === '$' || isForSubtotalOnly
              ? order.sales_tax
              : applyPercentage(amount, order.sales_tax || 0),
          delivery_fee:
            amountType === '$' || isForSubtotalOnly
              ? order.delivery_fee
              : applyPercentage(amount, order.delivery_fee || 0),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          tip:
            amountType === '$' || isForSubtotalOnly
              ? order.tip
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              : applyPercentage(amount, order.tip || 0),
          fee:
            amountType === '$' || isForSubtotalOnly
              ? order.misc_fee?.amount || 0
              : applyPercentage(amount, order.misc_fee?.amount || 0),
          name: approvedBy,
          reason,
        });

        if (resp) {
          setSuccessMessage('You have successfully refunded your order');
          await onRefundSuccess();
          onClose();
        }
      }
    }
  };

  const confirmRefund = () => {
    if (!approvedBy) {
      setApprovedByErrorMessage('Approved by required');
    }

    if (!reason) {
      setReasonErrorMessage('Reason required');
    }

    if (!amount) {
      setAmountErrorMessage('Amount required');
    }

    if (approvedBy && reason && amount) {
      setShouldShowConfirmModal(true);
    }
  };

  const onButtonPress = () => {
    if (amount && !amountErrorMessage && approvedBy && reason) {
      void submitRefund();
    }
  };

  const onAmountTypeToggle = (option: string) => {
    setAmountType(option as '%' | '$');
    if (option === '$') {
      setIsForSubtotalOnly(true);
    }
    setAmountErrorMessage('');
  };

  const onAmountChange = (value: number) => {
    setAmount(value);
    if (
      order &&
      amountType === '$' &&
      value > (isForSubtotalOnly ? order.subtotal : order.total)
    ) {
      setAmountErrorMessage('Cannot be over the original amount');
    } else {
      setAmountErrorMessage('');
    }
  };

  const onCancel = () => {
    setShouldShowConfirmModal(false);
    onClose();
  };

  return shouldShowConfirmModal ? (
    <ConfirmRefundModal
      isLoading={isUpdateOrderStatusRequestLoading}
      onClose={onCancel}
      onConfirm={onButtonPress}
    />
  ) : (
    <StyledModal onModalClose={onClose}>
      <Title>Refund by Amount</Title>
      {/* See https://chownow.atlassian.net/browse/CN-36161 for decisions around removing this feature temporarily
      <Message>
        Refunds will be applied to the total of an order unless otherwise
        specified.
      </Message> */}
      <Message>Refunds will be applied to the subtotal of an order.</Message>
      <RowTopAligned>
        <RefundByAmountInput
          amountType={amountType}
          onChange={onAmountChange}
          errorText={amountErrorMessage}
        />
        <StyledToggleButton
          toggleOptions={[
            {
              label: '%',
            },
            {
              label: '$',
            },
          ]}
          handleClick={onAmountTypeToggle}
        />
      </RowTopAligned>
      {/* See https://chownow.atlassian.net/browse/CN-36161 for decisions around removing this feature temporarily
      <Row>
        <Checkbox
          checked={isForSubtotalOnly}
          value="Only apply to subtotal"
          name="subtotalOnly"
          disabled={amountType === '$'}
          onChange={() => setIsForSubtotalOnly(!isForSubtotalOnly)}
        />
        <Label htmlFor="subtotalOnly">Only apply to subtotal</Label>
      </Row> */}
      <Section>
        <LineItem>
          <Label>{isForSubtotalOnly ? 'Subtotal' : 'Total'}</Label>
          <span>${isForSubtotalOnly ? order.subtotal : order.total}</span>
        </LineItem>
        {!amountErrorMessage && amount !== 0 && (
          <Revision>
            <Label>Revised {isForSubtotalOnly ? 'Subtotal' : 'Total'}</Label>
            <span>
              ${getRevisedAmount(amount, amountType, order, isForSubtotalOnly)}
            </span>
          </Revision>
        )}
      </Section>
      <Section>
        <StyledInput
          type="text"
          label="Approved by (required)"
          name="approvedBy"
          onChange={setApprovedBy}
          value={approvedBy}
          helpText="Diners will not see this"
          errorText={approvedByErrorMessage}
        />
        <StyledTextArea
          type="text"
          label="Reason (required)"
          name="reason"
          onChange={setReason}
          value={reason}
          errorText={reasonErrorMessage}
          isMultiLine
        />
      </Section>
      <Button
        size="medium"
        label={`Refund $${getRefundAmount(
          amount,
          amountType,
          isForSubtotalOnly
            ? order.subtotal
            : (order.total || 0) -
                (order.misc_fee?.label === 'Support Local Fee'
                  ? order.misc_fee?.amount || 0
                  : 0)
        ).toFixed(2)}`}
        isFullWidth
        isLoading={isUpdateOrderStatusRequestLoading}
        onClick={confirmRefund}
        disabled={!!amountErrorMessage || !amount}
      />
    </StyledModal>
  );
};

export default RefundByAmountModal;
