import styled from 'styled-components';
import { label, paragraph } from 'styles/types';
import { space } from 'styles/variables';
import { Button } from '@chownow/cn-web-components';

export const Heading = styled.h2`
  font: ${label.semi.x20};
  letter-spacing: 0.2px;
`;

export const LocationsIntro = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x2} 0 ${space.x3} 0;
`;

export const LocationWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space.x1};
`;

export const LocationAddress = styled.span`
  font: ${label.reg.x14};
  margin-top: ${space.x1};
`;

export const LocationName = styled.span`
  font: ${label.md.x14};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${space.x3} 0;
`;

export const RadioListItem = styled.li`
  display: flex;
  margin-bottom: ${space.x3}
`;

export const LocationPickerWrapper = styled.div`
  max-width: 456px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
