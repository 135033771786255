import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { ReactComponent as BadgePlus } from 'images/icon-badge-plus-sm.svg';

export const AddMenuCardContainer = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${space.x1};
  cursor: pointer;
  display: flex;
  line-height: ${space.x3};
  margin-bottom: ${space.x3};
  padding: ${space.x3};

  p {
    color: ${semanticColors.content.primary};
    display: inline;
    font: ${label.semi.x14};
    line-height: ${space.x3};
    margin: 0;
  }

  svg {
    margin-top: ${space.half};
  }
`;

export const BadgePlusIcon = styled(BadgePlus)`
  margin-right: ${space.x2};

  path {
    fill: ${semanticColors.content.primary};
  }
`;
