import { useEffect } from 'react';

/**
 * Hook for temporarily disabling all click events
 * @param  {boolean} isDisabled - toggle click disable
 */
export default function useClickDisable(isDisabled) {
  useEffect(() => {
    if (isDisabled) {
      document.addEventListener('click', clickHandler, true);
    } else {
      document.removeEventListener('click', clickHandler, true);
    }
    return () => document.removeEventListener('click', clickHandler, true);
  }, [isDisabled]);

  function clickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
  }
}
