import React, { FC } from 'react';
import { getAppOrderDirectDomain } from 'helpers/url';

import {
  Divider,
  LinkWrapper,
  StyledIconArrowOut,
  StyledLink,
} from './styles';

interface PopoverContentProps {
  companyId: string;
  restaurantId: string | null;
}

const PopoverContent: FC<PopoverContentProps> = ({ companyId, restaurantId }) => (
  <>
    <LinkWrapper>
      <StyledLink
        href="https://support.chownow.com/restaurant/s/"
        rel="noreferrer"
        target="_blank"
      >
        Visit Support Site
      </StyledLink>
      <StyledIconArrowOut />
    </LinkWrapper>
    <LinkWrapper>
      <StyledLink
        href="https://www.surveymonkey.com/r/N7B7MVQ"
        rel="noreferrer"
        target="_blank"
      >
        Give Feedback
      </StyledLink>
      <StyledIconArrowOut />
    </LinkWrapper>
    {restaurantId && (
      <>
        <Divider />
        <LinkWrapper>
          <StyledLink
            href={`${getAppOrderDirectDomain()}/${companyId}/locations/${restaurantId}?menu_status=preview`}
            rel="noreferrer"
            target="_blank"
          >
            Preview Menu
          </StyledLink>
          <StyledIconArrowOut />
        </LinkWrapper>
      </>
    )}
  </>
);

export default PopoverContent;
