type DateRangeMapping = {
  [key: string]: string;
};

export const mapDateRangeToLabel = (value: string): string => {
  const mapping: DateRangeMapping = {
    '24_hours': 'Last 24 Hours',
    '1_week': 'Last 7 Days',
    '1_month': 'Last 30 Days',
    '3_months': 'Last 3 Months',
    '6_months': 'Last 6 Months',
    '1_year': 'Last Year',
  };

  return mapping[value] || value;
};

interface Filters {
  order_status?: string | null;
  date_range?: string;
  offset?: string | null;
  order_by?: string | null;
  sort_order?: string | null;
}

export const buildFilterQuery = (filters: Filters): string => {
  const { order_status, date_range, offset, order_by, sort_order } = filters;
  const queryParams = [];

  if (order_status) {
    queryParams.push(`filterby=${order_status}`);
  }

  if (date_range) {
    queryParams.push(`date_range=${date_range}`);
  }

  if (offset) {
    queryParams.push(`offset=${offset}`);
  }

  if (order_by) {
    queryParams.push(`order_by=${order_by}`);
  }

  if (sort_order) {
    queryParams.push(`sort_order=${sort_order}`);
  }

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
};
