import React, { useEffect, useState, useContext, FC } from 'react';
import { sumBy } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ModalContext } from 'context/ModalContext';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import { Button, Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import ContentContainer from 'components/ContentContainer';
import DialogModal from 'components/Modals/DialogModal';
import ConfirmPartialRefundModal from 'components/Modals/ConfirmPartialRefundModal';
import { PartialRefundByItem } from 'types/refunds';
import Restaurant from 'types/restaurant';
import Banner from '@chownow/cocina-react-banner';

import {
  ModifierCategory,
  OrderDetailsV6GetSuccessResponse,
} from 'types/order-details';
import FeeItem from './FeeItem';

import {
  ButtonWrap,
  CancelButton,
  Item,
  PartialRefundsContainer,
  RefundFeesSectionHeader,
  RefundItemsSectionHeader,
  RevisedRow,
  SubItem,
  Totals,
  TotalsRow,
} from './styles';
import {
  NestedRefundInterface,
  RefundFee,
  RefundItemInterface,
  convertToPayloadStructure,
  getModifierSelections,
  getRevisedTotal,
  validateItems,
} from './util';
import { formatMoney } from '../OrderDetailsContainer/OrderDetails/OrderCard/helpers';
import RefundItems from './RefundItems';

interface PartialRefundsProps {
  restaurant: Restaurant;
  orderDetails: OrderDetailsV6GetSuccessResponse;
}

const PartialRefunds: FC<PartialRefundsProps> = ({
  restaurant,
  orderDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useContext(ModalContext);
  const { customer, delivery_fee, tips } = orderDetails;
  const serviceFee = orderDetails.misc_fee?.amount || 0;
  const OrderDetailsRoute = `/restaurant/${restaurant.id}/orders/details/${orderDetails.id}`;
  const [refundedItems, setRefundedItems] = useState<RefundItemInterface[]>([]);
  const [refundFees, setRefundFees] = useState<RefundFee[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const customerRoute = `${location.pathname.replace(
    '/partial-refunds',
    ''
  )}/customer/${customer?.id}`;

  const convertModifiersToRefundedItems = (
    modifier_categories: ModifierCategory[]
  ): NestedRefundInterface[] =>
    getModifierSelections(modifier_categories).map(modifier => ({
      id: modifier.id,
      refund_quantity: 0,
      price: modifier.price,
      refund_modifiers: modifier.modifier_categories
        ? convertModifiersToRefundedItems(modifier.modifier_categories)
        : [],
    }));

  const buildRefundItems = () => {
    // map order items and their modifiers to the following payload structure
    // { id, price, refund_quantity = 0 }
    setRefundedItems(
      orderDetails.items?.map(item => ({
        id: item.id,
        refund_quantity: 0,
        price: item.price,
        refund_modifiers: convertModifiersToRefundedItems(
          item.modifier_categories
        ),
      })) || []
    );
  };

  const buildRefundFees = () => {
    // may order fees to the following payload structure
    // { name, amount = 0, refund = 0 }
    const fees = [
      {
        name: 'Service Fee',
        amount: serviceFee || 0,
        refund: serviceFee || 0,
      },
      {
        name: 'Delivery Fee',
        amount: delivery_fee || 0,
        refund: delivery_fee || 0,
      },
      {
        name: tips?.managed_delivery_tip?.label || 'Courier Tip',
        amount: tips?.managed_delivery_tip?.amount || 0,
        refund: tips?.managed_delivery_tip?.amount || 0,
      },
      {
        name: tips?.restaurant_tip?.label || 'Restaurant Tip',
        amount: tips?.restaurant_tip?.amount || 0,
        refund: tips?.restaurant_tip?.amount || 0,
      },
    ];

    setRefundFees(fees);
  };

  useEffect(() => {
    // create formatted objects for refund payload
    buildRefundItems();
    buildRefundFees();
  }, []);

  const handleRefundModifier = (
    qty: number,
    nestedRefunds: NestedRefundInterface[],
    modifierId?: string
  ) => {
    nestedRefunds.forEach(item => {
      if (!modifierId || item.id === modifierId) {
        item.refund_quantity = qty;
      }
      if (item.refund_modifiers) {
        handleRefundModifier(qty, item.refund_modifiers, modifierId);
      }
    });
  };

  const handleRefundUpdate = (
    qty: number,
    itemId: string,
    modifierId?: string
  ) => {
    const updatedItems = [...refundedItems];
    const qtyInt = Math.floor(qty);

    // update refund item or modifier quantity
    updatedItems.forEach(item => {
      if (item.id === itemId && !modifierId) {
        item.refund_quantity = qtyInt;
      }
      if (item.id === itemId && modifierId) {
        handleRefundModifier(qtyInt, item.refund_modifiers, modifierId);
      }
    });

    setRefundedItems(updatedItems);
  };

  const handleRefundAll = (qty: number, id: string) => {
    // when a root level refund item is selected, set the item and all of it's modifiers to max quantity
    const updatedItems = [...refundedItems];

    updatedItems.forEach(item => {
      if (item.id === id) {
        const qtyInt = Math.floor(qty);
        item.refund_quantity = qtyInt;
        handleRefundModifier(qty, item.refund_modifiers);
      }
    });

    setRefundedItems(updatedItems);
  };

  const handleUpdateFee = (name: string, amount: number) => {
    const updatedFees = [...refundFees];

    updatedFees.forEach(fee => {
      if (fee.name === name) {
        fee.refund = amount;
      }
    });

    setRefundFees(updatedFees);
  };

  const handleOnSubmit = () => {
    if (!validateItems(refundedItems)) {
      showModal(DialogModal, {
        ...DIALOG_MODAL_COPY.refundError,
      });
    }

    // create final payload
    const payload: PartialRefundByItem = {
      refund_type: 'partial_by_item',
      refund_items: convertToPayloadStructure(refundedItems),
      fee: refundFees[0].refund,
      delivery_fee: refundFees[1].refund,
      managed_delivery_tip: refundFees[2]?.refund,
      restaurant_tip: refundFees[3]?.refund,
      name: '',
      reason: '',
    };

    showModal(ConfirmPartialRefundModal, {
      showCloseIcon: true,
      orderId: orderDetails.id,
      payload,
      redirectUrl: OrderDetailsRoute,
      setErrorMessage,
      setSuccessMessage,
    });
  };

  return (
    <PartialRefundsContainer>
      {errorMessage && (
        <Banner variant="caution" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Banner>
      )}
      {successMessage && (
        <Banner variant="success" onClose={() => setSuccessMessage('')}>
          {successMessage}
        </Banner>
      )}
      <Cover
        title={`Refund by Item for #${orderDetails.id}`}
        body={(
          <span>
            This order is for{' '}
            <Link to={customerRoute}>
              {customer?.first_name} {customer?.last_name}
            </Link>
          </span>
        )}
      />
      <ContentContainer>
        <DashboardGrid>
          <Grid sm={4} md={12}>
            <RefundItemsSectionHeader>Refund Items</RefundItemsSectionHeader>
            <RefundItems
              order={orderDetails}
              refundedItems={refundedItems}
              handleRefundAll={handleRefundAll}
              handleRefundUpdate={handleRefundUpdate}
            />
            <Totals>
              <TotalsRow>
                <div>Subtotal:</div>
                <div>{formatMoney(orderDetails.subtotal || 0)}</div>
              </TotalsRow>

              {formatMoney(getRevisedTotal(orderDetails, refundedItems)) !==
                formatMoney(orderDetails.subtotal || 0) && (
                <RevisedRow>
                  <div>Revised Subtotal:</div>
                  <div>
                    {formatMoney(getRevisedTotal(orderDetails, refundedItems))}
                  </div>
                </RevisedRow>
              )}
            </Totals>

            {sumBy(refundFees, 'amount') !== 0 && (
              <>
                <RefundFeesSectionHeader>Refund Fees</RefundFeesSectionHeader>
                <Item>
                  {refundFees
                    .filter(r => r.amount)
                    .map(refundFee => (
                      refundFee.amount ? (
                        <SubItem key={refundFee.name}>
                          <FeeItem
                            fee={refundFee}
                            handleUpdateFee={handleUpdateFee}
                          />
                        </SubItem>
                      ) : null
                    ))}
                </Item>
              </>
            )}
            <ButtonWrap>
              <CancelButton
                label="Cancel"
                variant="outline"
                onClick={() => navigate(OrderDetailsRoute)}
              />
              <Button label="Refund Payment" onClick={handleOnSubmit} />
            </ButtonWrap>
          </Grid>
        </DashboardGrid>
      </ContentContainer>
    </PartialRefundsContainer>
  );
};

export default PartialRefunds;
