import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DialogModal from 'components/Modals/DialogModal';

import styles from './styles.module.scss';

function SherlockModal(props) {
  const { initialValue, onChange, onClose } = props;
  const [url, setUrl] = useState(initialValue || '');

  function handleOnChange(e) {
    onChange(e);
    setUrl(e.target.value);
  }

  return (
    <DialogModal
      message={`This URL does not need to match the URL in Salesforce.
  It should be whichever page their ChowNow ordering is
  on (i.e. their SalesForce URL could be
  hudsonscafe.com, but their Sherlock URL could be
  hudsonscafe.com/menu)`}
      title={`Update Sherlock URL`}
      confirmLabel={'Update'}
      cancelLabel={'Cancel'}
      onClose={onClose}
    >
      <input
        className={styles.urlInput}
        type="text"
        placeholder="Sherlock URL"
        value={url}
        onChange={handleOnChange}
      />
    </DialogModal>
  );
}

SherlockModal.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func
};

export default SherlockModal;
