import React, { FC } from 'react';

import { FileName, FileNotes, MenuCardContainer } from './styles';

interface MenuCardProps {
  fileName: string;
  fileNotes: string;
}

const MenuCard: FC<MenuCardProps> = ({ fileName, fileNotes }) => (
  <MenuCardContainer>
    <FileName>{fileName}</FileName>
    <FileNotes>{fileNotes}</FileNotes>
  </MenuCardContainer>
);

export default MenuCard;
