import { Dispatch, SetStateAction } from 'react';

export const websiteAccessAdminPasswordValidator = (
  websiteAdminPassword: string,
  setWebsiteAdminPasswordError: Dispatch<SetStateAction<string>>
) => {
  if (!websiteAdminPassword) {
    setWebsiteAdminPasswordError('Please enter a website password');

    return true;
  } else if (websiteAdminPassword.length > 50) {
    setWebsiteAdminPasswordError(
      'Website password has a maximum length of 50 characters'
    );

    return true;
  }

  return false;
};
