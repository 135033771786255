// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loadingBarWrapper_13594289223743{bottom:0;left:10px;position:absolute;width:calc(100% - 20px)}.loadingBar_13594289223743{background:#000;height:2px;transition:width .3s}", "",{"version":3,"sources":["webpack://./src/components/ImageUploader/LoadingBar/styles.module.scss"],"names":[],"mappings":"AAEA,kCACE,QAAA,CACA,SAAA,CACA,iBAAA,CACA,uBAAA,CAGF,2BACE,eAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":["@import '~styles/variables';\n\n.loadingBarWrapper {\n  bottom: 0;\n  left: 10px; // adjust for border-radius\n  position: absolute;\n  width: calc(100% - 20px); // 100% - adjustment * 2\n}\n\n.loadingBar {\n  background: #000;\n  height: 2px;\n  transition: width 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingBarWrapper": "loadingBarWrapper_13594289223743",
	"loadingBar": "loadingBar_13594289223743"
};
export default ___CSS_LOADER_EXPORT___;
