import { Button } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { space, zIndex } from 'styles/variables';

export const Card = styled.div`
  background-color: ${semanticColors.surface.primary};
  bottom: 0;
  color: ${semanticColors.content.primary};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndex.raised};
`;

export const Title = styled.h2`
  color: ${semanticColors.content.primary};
  font: ${label.semi.x20};
  width: 100%;
`;

export const RefundButton = styled.button`
  background: none;
  border: none;
  display: flex;
  font: ${label.reg.x14};
  padding: ${space.x2};
  width: 100%;
`;

export const Heading = styled.div`
  align-items: center;
  border-bottom: 1px solid ${semanticColors.border.primary};
  display: flex;
  justify-content: space-between;
  padding: ${space.x3} ${space.x2};
`;

export const CloseButton = styled(Button)`
  &&& {
    padding: 0;
  }
`;
