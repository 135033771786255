import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';
import { pauseOrdersValidator } from 'helpers/validators';
import { Formik, Form, Field } from 'formik';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { Button } from '@chownow/cn-web-components';

import styles from './styles.module.scss';

function PauseOrdersModal(props) {
  const {
    handlePauseOrders,
    restaurantFulfillmentDelivery,
    restaurantFulfillmentPickup,
  } = props;
  const { hideModal } = useContext(ModalContext);
  const hasPickupAndDelivery =
    restaurantFulfillmentDelivery && restaurantFulfillmentPickup;
  const initialFulfillmentValue = hasPickupAndDelivery ? '' : 'null';

  function submitPauseOrders(fulfillMethod, duration, reason) {
    handlePauseOrders(
      fulfillMethod === 'delivery' ? 'delivery' : null,
      parseInt(duration),
      reason
    );
    hideModal();
  }

  return (
    <div className={styles.acceptOrderModal}>
      <button className={styles.iconClose} onClick={() => hideModal()}>
        <IconClose />
      </button>
      <h3 className={styles.modalTitle}>Pause New Orders</h3>
      <Formik
        validate={pauseOrdersValidator}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          time: '',
          reason: '',
          fulfillMethod: `${initialFulfillmentValue}`,
        }}
        onSubmit={values => {
          submitPauseOrders(values.fulfillMethod, values.time, values.reason);
        }}
      >
        {({ values, handleChange, errors, setFieldValue, dirty }) => (
          <Form>
            {errors && (
              <div className={styles.errorMessage}>{errors.pauseOrders}</div>
            )}
            <div role="group" aria-labelledby="preset-time-group">
              <div className={styles.sectionTitle}>
                How long would you like to pause new orders?
              </div>
              <div className={styles.timeButtons}>
                <Field
                  id="15"
                  type="radio"
                  name="time"
                  value="15"
                  checked={values.time === '15'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="15" className={styles.timeLabel}>
                  <div className={styles.time}>15</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="30"
                  type="radio"
                  name="time"
                  value="30"
                  checked={values.time === '30'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="30" className={styles.timeLabel}>
                  <div className={styles.time}>30</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="45"
                  type="radio"
                  name="time"
                  value="45"
                  checked={values.time === '45'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="45" className={styles.timeLabel}>
                  <div className={styles.time}>45</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="60"
                  type="radio"
                  name="time"
                  value="60"
                  checked={values.time === '60'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="60" className={styles.timeLabel}>
                  <div className={styles.time}>60</div>
                  <div className={styles.mins}>Mins</div>
                </label>
                <Field
                  id="90"
                  type="radio"
                  name="time"
                  value="90"
                  checked={values.time === '90'}
                  onClick={() => setFieldValue('customTime', '')}
                />
                <label htmlFor="90" className={styles.timeLabel}>
                  <div className={styles.time}>90</div>
                  <div className={styles.mins}>Mins</div>
                </label>
              </div>
            </div>

            <label className={styles.sectionTitle}>Reason (required)</label>
            <Field
              id="reason"
              type="text"
              name="reason"
              className={styles.customTimeInput}
              onChange={e => {
                handleChange(e);
                setFieldValue('reason', e.target.value);
              }}
              placeholder="Reason for pausing orders"
            />

            <div role="group" aria-labelledby="preset-time-group">
              <div className={styles.sectionTitle}>
                Apply to which fulfillment methods?
              </div>
              <div className={styles.fulfillMethod}>
                <Field
                  id="pickupDelivery"
                  type="radio"
                  name="fulfillMethod"
                  value="null"
                  checked={values.fulfillMethod === 'null'}
                />
                <label htmlFor="pickupDelivery">
                  {hasPickupAndDelivery ? 'Pickup & Delivery' : 'Pickup Only'}
                </label>
                {hasPickupAndDelivery && (
                  <>
                    <Field
                      id="delivery"
                      type="radio"
                      name="fulfillMethod"
                      value="delivery"
                      checked={values.fulfillMethod === 'delivery'}
                    />
                    <label htmlFor="delivery">Delivery Only</label>
                  </>
                )}
              </div>
            </div>

            <Button
              type="submit"
              className={styles.button}
              disabled={!dirty || !values.reason || !values.fulfillMethod}
              label={'Accept'}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

PauseOrdersModal.propTypes = {
  handlePauseOrders: PropTypes.func,
  restaurantFulfillmentDelivery: PropTypes.bool,
  restaurantFulfillmentPickup: PropTypes.bool,
};

export default PauseOrdersModal;
