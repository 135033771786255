import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { MenuDataContext } from 'context/MenuDataContext';
import { Label, MenuNotesWrapper, MessageArea } from './styles';

interface MenuNotesProps {
  isDisabled: boolean;
}

const MenuNotes: FC<MenuNotesProps> = ({ isDisabled }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const { setMenuNotes, menuNotes } = useContext(MenuDataContext);
  const characterLimit = 1000;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    setMenuNotes(value);

    if (value?.length === characterLimit) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <MenuNotesWrapper data-testid="menu-notes">
      <Label htmlFor="menuNotes">Menu Notes (optional)</Label>
      <textarea
        name="menuNotes"
        id="menuNotes"
        maxLength={characterLimit}
        placeholder="e.g. Nuances of modifiers, specials, etc."
        disabled={isDisabled}
        value={menuNotes}
        onChange={handleChange}
      />
      <MessageArea $error={hasError} data-testid="message-area">
        {hasError && <p>You’ve hit the maximum characters allowed.</p>}
        <div data-testid="character-counter">
          {menuNotes?.length}/{characterLimit}
        </div>
      </MessageArea>
    </MenuNotesWrapper>
  );
};

export default MenuNotes;
