import styled from 'styled-components';
import { space } from 'styles/variables';

import { cardStyles } from '../styles';

export const CardWrapper = styled.div<{ $isBottomCard?: boolean }>`
  ${cardStyles};

  margin-bottom: ${({ $isBottomCard }) => ($isBottomCard ? '142px' : space.x3)};
`;
