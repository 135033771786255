import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from 'images/icon-chevron.svg';

import styles from './styles.module.scss';

const { string } = PropTypes;

function Back({ label = 'Back', to }) {
  const navigate = useNavigate();

  return (
    <div className={styles.breadcrumbs}>
      <BackArrow className={styles.breadcrumbsArrow} />
      <div
        className={styles.breadcrumbsLink}
        onClick={() => {
          to ? navigate(to) : navigate(-1);
        }}
      >
        {label}
      </div>
    </div>
  );
}

export default Back;

Back.propTypes = {
  label: string,
  to: string,
};
