import { Modal } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { screen, space, zIndex } from 'styles/variables';

export const StyledModal = styled(Modal)`
  &&& {
    background-color: ${semanticColors.surface.primary};
    color: ${semanticColors.content.primary};
    position: fixed;

    @media (max-width: ${screen.xsMax}) {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: unset;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }

  z-index: ${zIndex.modalBase};
`;

export const Title = styled.div`
  border-bottom: 1px solid ${semanticColors.border.primary};
  font: ${label.semi.x20};
  padding: ${space.x3} ${space.x5};
  width: 100%;
`;

export const Content = styled.div`
  overflow-y: auto;
  padding: ${space.x5};
`;

export const Footer = styled.div`
  border-top: 1px solid ${semanticColors.border.primary};
  padding: ${space.x2};
`;
