import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { OrderCustomer } from 'types/order-details';
import { PhoneNumberFormat, prettifyPhoneNumber } from 'helpers/phoneNumber';

import {
  InfoContent,
  InfoRow,
  LeftContent,
  RightContent,
  StyledCardInfo,
} from '../styles';
import { formatCustomerAddress } from '../helpers';

interface CustomerInfoCardProps {
  customer: OrderCustomer | null | undefined;
}

const CustomerInfoCard: FC<CustomerInfoCardProps> = ({
  customer,
}) => {
  const location = useLocation();

  return (
    <StyledCardInfo title="Customer Info">
      <InfoContent>
        <InfoRow $isFirstElement>
          <LeftContent>Name</LeftContent>
          <RightContent>
            <Link
              to={`${location.pathname}/customer/${customer?.id}`}
            >
              {`${customer?.first_name} ${customer?.last_name}`}
            </Link>
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Phone number</LeftContent>
          <RightContent>
            {prettifyPhoneNumber(
              customer?.phone_number ?? '',
              PhoneNumberFormat.PARENS
            )}
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Email</LeftContent>
          <RightContent>{customer?.email}</RightContent>
        </InfoRow>
        {!!customer?.delivery && (
          <InfoRow>
            <LeftContent>Address</LeftContent>
            <RightContent>
              {formatCustomerAddress(customer?.delivery?.address)}
            </RightContent>
          </InfoRow>
        )}
      </InfoContent>
    </StyledCardInfo>
  );
};

export default CustomerInfoCard;
