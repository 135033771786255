import React from 'react';

import { ErrorContent, ErrorMessage, ErrorStateWrapper, ResetButton } from './styles';

const handleClick = () => {
  window.location.reload();
};

const ErrorState = () => (
  <ErrorStateWrapper>
    <ErrorContent>
      <ErrorMessage>
        Oops! There was an issue loading your data.
      </ErrorMessage>
      <ResetButton
        label="Refresh Page"
        onClick={handleClick}
      />
    </ErrorContent>
  </ErrorStateWrapper>
);

export default ErrorState;
