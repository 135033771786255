import React, { FC } from 'react';
import { Button } from '@chownow/cn-web-components';

import styles from './styles.module.scss';

const ErrorContent: FC = () => {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <div className={styles.errorContent}>
      <h2 className={styles.errorMessage}>Oops! Something is not right.</h2>
      <Button
        className={styles.reloadButton}
        label="Reload Page"
        onClick={handleRefreshClick}
      />
    </div>
  );
};

export default ErrorContent;
