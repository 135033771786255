import React from 'react';

import { Cover } from '@chownow/cn-web-components';
import ContentContainer from 'components/ContentContainer';

import styles from './styles.module.scss';

import { ReactComponent as IconDo } from 'images/icon-green-check-do.svg';
import { ReactComponent as IconDont } from 'images/icon-red-x-dont.svg';

import coverDesktop from 'images/photos-upload/cover-desktop.png';
import coverMobile from 'images/photos-upload/cover-mobile.png';
import clearDo from 'images/photos-upload/clear-do.png';
import clearDont from 'images/photos-upload/clear-dont.png';
import conciseDo from 'images/photos-upload/concise-do.png';
import conciseDont from 'images/photos-upload/concise-dont.png';
import positionDo from 'images/photos-upload/position-do.png';
import positionDont from 'images/photos-upload/position-dont.png';
import focusDo from 'images/photos-upload/focus-do.png';
import focusDont from 'images/photos-upload/focus-dont.png';
import croppingDo from 'images/photos-upload/cropping-do.png';
import croppingDont from 'images/photos-upload/cropping-dont.png';

const PhotosGuidelines = ({ restaurantId }) => {
  const managePhotosRoute = `/restaurant/${restaurantId}/menu/manage-photos/upload`;

  return (
    <>
      <div className={styles.coverContainer}>
        <Cover
          backLinkUrl={managePhotosRoute}
          title="Menu Photo Guidelines"
          body="Follow these three simple guidelines for optimal menu photos. Our team
    will review your photos - we’ll get in touch if there are opportunities
    to optimize your images or selections."
        />
      </div>
      <ContentContainer>
        <div className={styles.guidelines}>
          <div className={styles.whatDinersSeeSection}>
            <h3>What Diners See</h3>
            <div className={styles.deviceExamples}>
              <img
                src={coverDesktop}
                className={styles.coverDesktop}
                alt="what diners see desktop"
              />
              <img
                src={coverMobile}
                className={styles.coverMobile}
                alt="what diners see mobile"
              />
            </div>
          </div>
          <div className={styles.guidelineSection}>
            <div className={styles.guidelineLeft}>
              <h3>Clear</h3>
              <ul className={styles.guidelineList}>
                <li>Use high resolution images (.JPG preferred).</li>
                <li>
                  File size: <strong>under 10 MB.</strong>
                </li>
                <li>
                  Height & width minimum: <strong>1200 x 1200 pixels.</strong>
                </li>
                <li>
                  For background, aim for natural colors and try to shoot with
                  good lighting conditions. Avoid extreme filters.
                </li>
                <li>
                  Aim for a clear, crisp photo. If it’s blurry or grainy, try
                  again.
                </li>
              </ul>
            </div>
            <div className={styles.guidelineRight}>
              <div className={styles.do}>
                <img
                  src={clearDo}
                  alt="example of a clear well lit shot of pizza"
                />
                <div className={styles.doDontBlock}>
                  <IconDo className={styles.doDontIcon} /> Do
                </div>
                <p>Use clear, well-lit, high-res photos</p>
              </div>
              <div className={styles.dont}>
                <img
                  src={clearDont}
                  alt="example of a blurry dim shot of pizza"
                />
                <div className={styles.doDontBlock}>
                  <IconDont className={styles.doDontIcon} /> Don't
                </div>
                <p>Use blurry photos or low lighting</p>
              </div>
            </div>
          </div>
          <div className={styles.guidelineSection}>
            <div className={styles.guidelineLeft}>
              <h3>Concise</h3>
              <ul className={styles.guidelineList}>
                <li>
                  Avoid copyrighted materials such as packaging or labels. Your
                  own restaurant logo is A-okay! (You’re responsible for your
                  images, so make sure you respect intellectual property.)
                </li>
                <li>No text or watermarks overlaid on images.</li>
                <li>Stick to one photo per menu item, not collages.</li>
              </ul>
            </div>
            <div className={styles.guidelineRight}>
              <div className={styles.do}>
                <img
                  src={conciseDo}
                  alt="example using a branded cup to display the drink"
                />
                <div className={styles.doDontBlock}>
                  <IconDo className={styles.doDontIcon} /> Do
                </div>
                <p>
                  Use a clear container or container provided by your company
                </p>
              </div>
              <div className={styles.dont}>
                <img
                  src={conciseDont}
                  alt="coke bottle, an example using copyrighted labels"
                />
                <div className={styles.doDontBlock}>
                  <IconDont className={styles.doDontIcon} /> Don't
                </div>
                <p>
                  Use any photos that you don't have rights for, as you will be
                  legally liable for such uses
                </p>
              </div>
            </div>
          </div>
          <div className={styles.guidelineSectionGroup}>
            <h3>Composition</h3>

            <div className={styles.guidelineSubSection}>
              <div className={styles.guidelineLeft}>
                <h4 className={styles.guidelineSubHeading}>
                  Position & perspective
                </h4>
                <ul className={styles.guidelineList}>
                  <li>
                    Center the item in frame while leaving space around it.
                  </li>
                  <li>
                    Stick to the following angles: overhead, straight-on, and
                    low-angled.
                  </li>
                  <li>
                    To avoid distortion, do not tilt the camera sideways while
                    shooting.
                  </li>
                </ul>
              </div>
              <div className={styles.guidelineRight}>
                <div className={styles.do}>
                  <img
                    src={positionDo}
                    alt="example of a centered overhead shot of pancakes"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDo className={styles.doDontIcon} /> Do
                  </div>
                  <p>Use a centered overhead shot</p>
                </div>
                <div className={styles.dont}>
                  <img
                    src={positionDont}
                    alt="example of an off center tilted shot of pancakes"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDont className={styles.doDontIcon} /> Don't
                  </div>
                  <p>
                    Position items off to the side or tilt the camera sideways
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.guidelineSubSection}>
              <div className={styles.guidelineLeft}>
                <h4 className={styles.guidelineSubHeading}>
                  Focus & depth of field
                </h4>
                <ul className={styles.guidelineList}>
                  <li>
                    Give your item plenty of empty space around the borders for
                    more cropping options in layout.
                  </li>
                  <li>
                    Avoid busy backgrounds and keep the focus directly on the
                    item.
                  </li>
                </ul>
              </div>
              <div className={styles.guidelineRight}>
                <div className={styles.do}>
                  <img
                    src={focusDo}
                    alt="example of a focused shot of a hamburger"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDo className={styles.doDontIcon} /> Do
                  </div>
                  <p>Use focus to highlight your item or plate</p>
                </div>
                <div className={styles.dont}>
                  <img
                    src={focusDont}
                    alt="example of a busy shot of food with blurred background"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDont className={styles.doDontIcon} /> Don't
                  </div>
                  <p>Blur other items that are also included</p>
                </div>
              </div>
            </div>

            <div className={styles.guidelineSubSection}>
              <div className={styles.guidelineLeft}>
                <h4 className={styles.guidelineSubHeading}>Cropping</h4>
                <ul className={styles.guidelineList}>
                  <li>
                    Each photo should have a clear, single item in its entirety.
                  </li>
                  <li>
                    Give your item plenty of empty space around the borders for
                    more cropping options in layout.
                  </li>
                  <li>
                    Use our built-in cropping tool to adjust non-square images.
                  </li>
                </ul>
              </div>
              <div className={styles.guidelineRight}>
                <div className={styles.do}>
                  <img
                    src={croppingDo}
                    alt="example of a shot of a plate of food with empty space around plate"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDo className={styles.doDontIcon} /> Do
                  </div>
                  <p>Leave empty space around plate or item</p>
                </div>
                <div className={styles.dont}>
                  <img
                    src={croppingDont}
                    alt="example of a close up shot of food that is cropped too close to the subject"
                  />
                  <div className={styles.doDontBlock}>
                    <IconDont className={styles.doDontIcon} /> Don't
                  </div>
                  <p>Crop too close</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default PhotosGuidelines;
