import React from 'react';
import Outlink from '@chownow/cocina-react-outlink';
import styles from './styles.module.scss';

const CropPhotoBlogLink = ({ blogURL, blogLabel, endText }) => (
  <span data-testid="crop-photo-blog-link">
    {`Please read our `}
    <Outlink to={blogURL} ariaLabel={blogLabel}>
      <span className={styles.labelStyle}>{blogLabel}</span>
    </Outlink>
    {endText}
  </span>
);

export default CropPhotoBlogLink;
