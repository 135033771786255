import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';

const { bool, func, string } = PropTypes;

const ToggleButton = ({ className, itemId, onToggle, toggleValue }) => {
  const toggleId = uuidv4();
  return (
    <div className={className} onClick={e => e.stopPropagation()}>
      <input
        className={styles.toggleButton}
        type="checkbox"
        id={toggleId}
        checked={toggleValue}
        onChange={onToggle}
        data-testid={'toggle'}
      />
      <label
        className={styles.btnToggle}
        htmlFor={toggleId}
        data-testid="ShowHideItemsToggle"
      />
    </div>
  );
};

ToggleButton.propTypes = {
  className: string,
  itemId: string,
  onToggle: func,
  toggleValue: bool
};

export default ToggleButton;
