import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { fontWeight } from 'styles/types';

export const MenuNotesWrapper = styled.div`
  padding: ${space.x5} 0;
  width: 100%;

  label {
    color: ${semanticColors.content.primary};
    font-weight: ${fontWeight.semiBold};
  }

  textarea {
    border-radius: ${space.x1};
    margin-top: ${space.oneAndHalf};
    min-height: 104px;
    padding: ${space.x2};
    width: 100%;
    
    :disabled {
      background-color: ${semanticColors.surface.tertiary};
      color: ${semanticColors.content.secondary};
    }
  }
`;

export const Label = styled.label<{ $disabled?: boolean; }>`
  color: ${props => (props.$disabled ? semanticColors.content.secondary : semanticColors.content.primary)};
  font-weight: ${fontWeight.semiBold};
`;

export const MessageArea = styled.div<{ $error?: boolean; }>`
  color: ${props => (props.$error ? semanticColors.content.caution : semanticColors.content.secondary)};
  display: flex;
  font-size: ${space.oneAndHalf};
  justify-content: ${props => (props.$error ? 'space-between' : 'right')};

  p {
    margin: 0;
  }
`;
