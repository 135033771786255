import { useState } from 'react';
import { request } from 'helpers/api';
import {
  RefundRequest,
  RefundResponse,
  RefundSuccessResponse,
} from 'types/refunds';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';

const MAX_POLLING_ATTEMPTS = 20;
const ASSET_UPLOAD_DELAY = 1000;

/**
 * Create a partial refund for an order
 * @param {string} orderId - ChowNow Order ID
 * @param {RefundRequest} refundRequest - Partial refund request object
 */
export const usePostRefundOrder = () => {
  const [isPostRefundOrderLoading, setIsPostRefundOrderLoading] =
    useState<boolean>(false);
  const [hasPostRefundOrderError, setHasPostRefundOrderError] =
    useState<boolean>(false);
  const [postRefundOrderErrorMessage, setPostRefundOrderErrorMessage] =
    useState<string | null>(null);

  const refundOrder = async (
    orderId: string,
    refundRequest: RefundRequest
  ): Promise<RefundResponse | undefined> => {
    setIsPostRefundOrderLoading(true);

    try {
      const postResp = (await request(
        `order/${orderId}/refund`,
        { ...refundRequest, app_version: '1.0' },
        'POST',
        undefined,
        undefined,
        undefined,
        '6.0'
      )) as RefundSuccessResponse;

      let response: OrderDetailsV6GetSuccessResponse | undefined;
      let attempts = 0;
      while (
        !response ||
        (!(response.refund_info &&
          response.refund_info?.refund_type === refundRequest.refund_type) &&
          attempts < MAX_POLLING_ATTEMPTS)
      ) {
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise(resolve => setTimeout(resolve, ASSET_UPLOAD_DELAY));
        // eslint-disable-next-line no-await-in-loop
        response = (await request(
          `order/${orderId}`,
          {},
          'GET',
          undefined,
          undefined,
          undefined,
          '6.0'
        )) as OrderDetailsV6GetSuccessResponse;
        attempts += 1;
      }

      return postResp;
    } catch (err) {
      setHasPostRefundOrderError(true);
      setPostRefundOrderErrorMessage(
        'Your refund could not be processed. Please contact customer support at 888-707-2649 to resolve this issue.'
      );
    } finally {
      setIsPostRefundOrderLoading(false);
    }

    return undefined;
  };

  return {
    hasUpdateOrderStatusError: hasPostRefundOrderError,
    isUpdateOrderStatusRequestLoading: isPostRefundOrderLoading,
    refundOrder,
    updateOrderStatusErrorMessage: postRefundOrderErrorMessage,
  };
};
