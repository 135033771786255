import React, { FC, useContext } from 'react';
import { formatDateTime } from 'helpers/dateTime';
import { UserContext } from 'context/UserContext';

import Outlink from '@chownow/cocina-react-outlink';
import {
  InfoContent,
  InfoRow,
  LeftContent,
  LeftContentWrap,
  RightContent,
  StyledCardInfo,
} from '../styles';
import { getLastAction, getStatusIcon, getStatusText } from './helpers';
import { FormattedFulfillMethods, Order, OrderStatus } from '../types';

interface OrderInfoCardProps {
  order: Order;
}

const OrderInfoCard: FC<OrderInfoCardProps> = ({ order }) => {
  const { user: { is_chownow_admin, is_super_admin } } = useContext(UserContext);
  const isAcceptedOrder = order.status === OrderStatus.accepted;
  const isCurbsideOrder = order.fulfill_method === FormattedFulfillMethods.curbside;
  const isDineInOrder = order.fulfill_method === FormattedFulfillMethods.dine_in;
  const isDeliveryOrder = order.fulfill_method === FormattedFulfillMethods.delivery;
  const isPickupOrder = order.fulfill_method === FormattedFulfillMethods.pickup;
  const isManagedDeliveryOrder =
    order.fulfill_method === FormattedFulfillMethods.delivery &&
    order.managed_delivery !== null;
  const isFutureOrder = order.is_order_ahead;
  const lastAction = getLastAction(
    order.grabbed_by,
    is_chownow_admin,
    is_super_admin,
    order.status,
    order.updated_at
  );

  return (
    <StyledCardInfo title="Order Info">
      <InfoContent>
        <InfoRow $isFirstElement>
          <LeftContent>Status</LeftContent>
          <RightContent>
            {getStatusIcon(order.status, isFutureOrder)}
            {getStatusText(order.status, isFutureOrder)}
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Type</LeftContent>
          <RightContent>
            {order.managed_delivery ?
              `${order.managed_delivery.label} Delivery` : order.fulfill_method}
          </RightContent>
        </InfoRow>
        {isDineInOrder && !!order.table_name && (
          <InfoRow>
            <LeftContent>Table info</LeftContent>
            <RightContent>{order.table_name}</RightContent>
          </InfoRow>
        )}
        {isCurbsideOrder && (
          <InfoRow>
            <LeftContent>Vehicle info</LeftContent>
            <RightContent>{order.vehicle}</RightContent>
          </InfoRow>
        )}
        <InfoRow>
          <LeftContent>Source</LeftContent>
          <RightContent>{order.product_source}</RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Placed</LeftContent>
          <RightContent>{formatDateTime(order.created_at)}</RightContent>
        </InfoRow>
        {isFutureOrder && (
          <InfoRow>
            <LeftContent>Requested for</LeftContent>
            <RightContent>
              {formatDateTime(order.requested_fulfillment)}
            </RightContent>
          </InfoRow>
        )}
        {isAcceptedOrder && isManagedDeliveryOrder && (
          <InfoRow>
            <LeftContent>Pickup ETA</LeftContent>
            <RightContent>
              {formatDateTime(order.estimated_pickup)}
            </RightContent>
          </InfoRow>
        )}
        {isAcceptedOrder && isManagedDeliveryOrder && (
          <InfoRow>
            <LeftContent>Delivery ETA</LeftContent>
            <RightContent>
              {formatDateTime(order?.original_estimated_arrival)}
            </RightContent>
          </InfoRow>
        )}
        {isAcceptedOrder && (isPickupOrder || isCurbsideOrder) && (
          <InfoRow>
            <LeftContent>Ready By</LeftContent>
            <RightContent>
              {formatDateTime(order.estimated_pickup)}
            </RightContent>
          </InfoRow>
        )}
        {isAcceptedOrder && (isDeliveryOrder && !isManagedDeliveryOrder) && (
          <InfoRow>
            <LeftContent>Deliver By</LeftContent>
            <RightContent>
              {formatDateTime(order.estimated_arrival)}
            </RightContent>
          </InfoRow>
        )}
        {order.delivery_instructions && (
          <InfoRow>
            <LeftContentWrap>
              <span>Delivery</span>
              <span>Instruction</span>
            </LeftContentWrap>
            <RightContent>
              {order.delivery_instructions}
            </RightContent>
          </InfoRow>
        )}
        {order.managed_delivery?.tracking_url && (
          <InfoRow>
            <LeftContent>Tracking Link</LeftContent>
            <RightContent>
              <Outlink
                to={order.managed_delivery.tracking_url}
                ariaLabel="Delivery tracking link"
              >
                {order.managed_delivery.tracking_url}
              </Outlink>
            </RightContent>
          </InfoRow>
        )}
        {!!order.refund_info?.refunded_at && (
          <InfoRow>
            <LeftContent>Refunded</LeftContent>
            <RightContent>
              {formatDateTime(order.refund_info?.refunded_at)}
            </RightContent>
          </InfoRow>
        )}
        {order.status !== 'Submitted' && !!lastAction && (
          <InfoRow>
            <LeftContent>Last action</LeftContent>
            <RightContent>
              {lastAction}
            </RightContent>
          </InfoRow>
        )}
      </InfoContent>
    </StyledCardInfo>
  );
};

export default OrderInfoCard;
