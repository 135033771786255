import styled from 'styled-components';
import { space } from 'styles/variables';
import { label } from 'styles/types';
import StatusDot from '@chownow/cocina-react-statusdot';

export const StyledUnorderedList = styled.ul`
  margin: ${space.x2};
`;

export const StyledList = styled.li<{ $noStatusDot?: boolean }>`
  display: flex;
  font: ${label.reg.x12};
  margin-bottom: ${space.x1};

  &:last-child {
    margin-top: ${space.x2};
  }

  ${({ $noStatusDot }) => $noStatusDot && `margin-left: ${space.x3};`}
`;

export const StyledListMiddleware = styled.li`
  display: flex;
  margin-bottom: ${space.x2};
`;

export const StyledStatusDot = styled(StatusDot)`
  margin-right: ${space.x1};
`;

export const TagContainer = styled.div`
  margin-right: ${space.x1};
`;
