import React, { FC, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { animated, useSpring } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@chownow/cn-web-components';
import { useLogout } from 'api/logout';

import DialogModal from 'components/Modals/DialogModal';

import breakpoints from 'helpers/breakpoints';
import { Company } from 'types/company';
import { getNameFromLocations } from 'helpers/restaurant';
import { getUrlParam } from 'helpers/url';

import { ModalContext } from 'context/ModalContext';

import { ReactComponent as CNLogo } from 'images/logos/cn-logo-mark.svg';
import { ReactComponent as IconSwitch } from 'images/switch.svg';

import NavList from './NavList';

import styles from './styles.module.scss';

interface SidebarProps {
  company: Company;
  isOpen: boolean;
  onRequestClose: () => void;
  handlePickerClick: () => void;
}

const Sidebar: FC<SidebarProps> = ({ company, isOpen, onRequestClose, handlePickerClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentURL = window.location.href;
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });
  const { showModal } = useContext(ModalContext);
  const { logout } = useLogout();
  const restaurantId = getUrlParam('restaurant') ?? '';
  const defaultProps = {
    transform: `translateX(${isOpen || isDesktop ? '0%' : '100%'})`,
    opacity: isOpen ? 1 : 0,
    immediate: isOpen && isDesktop,
  };
  const [springProps, set] = useSpring(() => ({
    ...defaultProps,
    config: isOpen
      ? { friction: 28, tension: 250 }
      : { friction: 38, tension: 350 },
  }));

  const handleLogout = async () => {
    const { hasLogoutError, logoutErrorMessage } = await logout();
    if (hasLogoutError) {
      showModal(DialogModal, {
        title: 'Something went wrong',
        message: logoutErrorMessage,
      });
    } else {
      navigate(`/login?next=${encodeURIComponent(currentURL)}`);
    }
  };

  useEffect(() => {
    set(defaultProps);
  }, [isOpen]);

  useEffect(() => {
    set({
      ...defaultProps,
      immediate: true,
    });
  }, [isDesktop]);

  return (
    <>
      <animated.div
        className={classNames(styles.overlay, {
          [styles.touchable]: isOpen,
        })}
        onClick={onRequestClose}
        style={{
          opacity: springProps.opacity,
        }}
      />
      <animated.div
        className={classNames(styles.sidebar, {
          [styles.sidebarMobile]: !isDesktop,
        })}
        style={{
          transform: springProps.transform,
        }}
        id="sidebar"
      >
        <div className={styles.fixed}>
          <div>
            {isDesktop && (
              <CNLogo name={company.name} className={styles.cnLogo} />
            )}
            <div className={styles.topSection}>
              {isDesktop ? (
                <span className={styles.companyName}>{company.name}</span>
              ) : (
                <CNLogo className={styles.iconLogo} />
              )}
            </div>
            <Button
              onClick={handlePickerClick}
              icon={IconSwitch}
              className={styles.pickerButton}
              label={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                restaurantId
                  ? getNameFromLocations(restaurantId, company)
                  : 'Headquarters'
              }
            />
            <NavList
              url={pathname}
              restaurantId={restaurantId}
              companyId={company.id}
              handleRequestClose={onRequestClose}
            />
          </div>
          <div className={styles.dashboardUser}>
            <div className={styles.dashboardUserWrap}>
              <Button
                className={styles.dashboardLogout}
                onClick={() => { void handleLogout(); }}
                label="Log out"
                variant="link"
              />
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default Sidebar;
