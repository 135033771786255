import React, { FC } from 'react';

import { PromotionMessages, PromotionOptions } from '../../constants';
import { CardWrapper } from '../styles';
import { SelectedPromoDescription } from './styles';

interface SubmittedPromotionCardProps {
  submittedPromo: PromotionOptions;
}

const SubmittedPromotionCard: FC<SubmittedPromotionCardProps> = ({ submittedPromo }) => (
  <CardWrapper>
    <SelectedPromoDescription>{PromotionMessages[submittedPromo]}</SelectedPromoDescription>
  </CardWrapper>
);

export default SubmittedPromotionCard;
