import React, { ChangeEvent, useState, useEffect, useContext } from 'react';

import { Checkbox, Radio } from '@chownow/cn-web-components';
import { FilterContext } from '../FilterContext';
import { InputType } from '../FilterTypes';

import {
  StyledFilter,
  Label,
  Input,
} from './styles';

export interface FilterProps {
  /** The label of the Filter Item */
  children: string;
  /** Set by FilterList. A key for grouping selected filters and also for naming radio button inputs. */
  filterCategory?: string;
  /** Radio or checkbox. Set by the FilterList component */
  inputType?: InputType;
  /** if the value is selected */
  selectedFilter?: boolean;
  /** The value of the filter */
  value: string;
  /** Indicates if the filter should be selected by default */
  defaultFilter?: boolean;
}

const Filter = ({
  children,
  filterCategory = '',
  inputType,
  selectedFilter = false,
  value,
  defaultFilter = false,
}: FilterProps) => {
  const [isSelected, setIsSelected] = useState(defaultFilter || selectedFilter); // Use defaultFilter in the initial state
  const { onSelectFilter, selectedFilters } = useContext(FilterContext);
  const InputComponent = inputType === 'radio' ? Radio : Checkbox;
  const name = inputType === 'radio' ? filterCategory : children;
  const controlId = `filter_${name}_${value}`;

  // if something sets the props.selected = true, update isSelected
  useEffect(() => {
    setIsSelected(defaultFilter || selectedFilter);
    onSelectFilter(filterCategory, children, value, defaultFilter || selectedFilter);
  }, [defaultFilter, selectedFilter]);

  const onChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsSelected(!isSelected);
    onSelectFilter(filterCategory, children, target.value, target.checked);
  };

  function getSelectedFilter(): string | null {
    if (selectedFilters?.[name]) return selectedFilters[name][0]?.value as string;
    return null;
  }

  return (
    <StyledFilter key={children}>
      <Label htmlFor={controlId}>
        <Input>
          <InputComponent
            checked={value === getSelectedFilter()}
            name={name}
            value={value}
            onChange={onChangeHandler}
            id={controlId}
          />
        </Input>
        {children}
      </Label>
    </StyledFilter>
  );
};

export default Filter;
