import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BadgeInfo } from 'images/icon-badge-info-md.svg';
import { StatusContentProps } from './types';
import {
  AccountStatusButton,
  ContentLeft,
  Status,
  Subtext,
} from './styles';

const AccountInReviewContent: FC<StatusContentProps> = ({
  restaurant,
  urlPath,
}) => {
  const navigate = useNavigate();

  const handleCheckStatusClick = () => {
    navigate(
      `/restaurant/${restaurant?.id}/${urlPath}`
    );
  };

  return (
    <ContentLeft>
      <BadgeInfo />
      <Status>Stripe is reviewing your business information</Status>
      <Subtext>
        We will let you know if you need to provide additional details.
        You can check your status by clicking on the button below.
      </Subtext>
      <AccountStatusButton
        isFullWidth
        label="Check Stripe Status"
        onClick={handleCheckStatusClick}
      />
    </ContentLeft>
  );
};

export default AccountInReviewContent;
