import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const { shape, string, func } = PropTypes;

const BootstrapFormikInput = ({ field, ...props }) => (
  <>
    <input {...field} {...props} />
    <ErrorMessage name={field.name}>
      {msg => <div className="alert alert-error">{msg}</div>}
    </ErrorMessage>
  </>
);

BootstrapFormikInput.propTypes = {
  field: shape({
    name: string,
    value: string,
    onChange: func,
    onBlur: func
  })
};

export default BootstrapFormikInput;
