import React from 'react';
import { PHOTO_TYPE } from 'helpers/constants';
import CropPhotoBlogLink from 'components/ImageUploader/CropPhotoBlogLink';
import CoverPhotoInstructions from 'components/ImageUploader/CoverPhotoInstructions';

function getCropPhotoBlogLink(item = null, restrictions = null) {
  if (item === PHOTO_TYPE.cover) {
    return (
      <CoverPhotoInstructions
        blogLabel="Cover Photo Guidelines"
        blogURL={
          'https://support.chownow.com/restaurant/s/article/What-are-our-cover-photo-requirements-and-guidelines'
        }
      />
    );
  } else {
    return (
      <CropPhotoBlogLink
        blogLabel="Logo Guidelines"
        blogURL={
          'https://support.chownow.com/restaurant/s/article/What-are-our-logo-requirements-and-guidelines'
        }
        endText={`, which also show where logos appear on ChowNow. Logos should be at
      least ${restrictions?.minWidth} x ${restrictions?.minHeight} pixels. You can edit after uploading to make sure it looks perfect.`}
      />
    );
  }
}

function getCropInstructions(item, restrictions = null) {
  switch (item) {
    case 'coverFirstInstructions':
      const coverBlogLink = getCropPhotoBlogLink(PHOTO_TYPE.cover);
      return coverBlogLink;
    case 'coverSecondInstructions':
      return `Drag the corners to adjust the crop. Size minimum is 1200 x ${restrictions?.minHeight}
        pixels. Try to leave empty space around the plate or item.`;
    case 'logoFirstInstructions':
      const logoBlogLink = getCropPhotoBlogLink(_, restrictions);
      return logoBlogLink;
    case 'logoSecondInstructions':
      return `Diners will only see what's in the circle below. Move and resize your logo to make sure
      it's perfect.`;
  }
}

export function getHelperText(
  inImageEditMode,
  isRestaurantAsset,
  itemId,
  restrictions
) {
  if (inImageEditMode) {
    return itemId === PHOTO_TYPE.logo
      ? getCropInstructions('logoSecondInstructions')
      : getCropInstructions('coverSecondInstructions', restrictions);
  } else {
    return isRestaurantAsset && itemId === PHOTO_TYPE.cover
      ? getCropInstructions('coverFirstInstructions')
      : getCropInstructions('logoFirstInstructions', restrictions);
  }
}
