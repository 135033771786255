import React from 'react';
import { format } from 'date-fns';
import { Button } from '@chownow/cn-web-components';
import CardInfo from '@chownow/cocina-react-cardinfo';
import { useNavigate } from 'react-router';

import { getFormattedDate, getFormattedPhoneNumber } from '../helpers';

import {
  ListDetails,
  ListItem,
  ListTitle,
  MenuRow,
  StyledStatusDot,
} from './styles';

interface IntegrationDetailsCardInfoProps {
  is_active: boolean | undefined;
  address:
    | {
        address_line_1?: string;
        address_line_2?: string;
        locality?: string;
        administrative_district_level_1?: string;
        postal_code?: string;
      }
    | undefined;
  phone_number: string | null | undefined;
  updated_at: string | undefined;
  token_expiration: string | null | undefined;
  last_menu_sync_at: string | undefined;
  user:
    | {
        is_chownow_admin?: boolean;
      }
    | undefined;
  restaurant: {
    name: string;
  };
  menu_link?: string;
  authentication?: string;
}

const IntegrationDetailsCardInfo: React.FC<IntegrationDetailsCardInfoProps> = ({
  is_active,
  address,
  phone_number,
  updated_at,
  token_expiration,
  last_menu_sync_at,
  user,
  restaurant,
  menu_link,
  authentication,
}) => {
  const navigate = useNavigate();

  const navigateToMenu = () => {
    if (menu_link) {
      navigate(menu_link);
    }
  };

  return (
    <CardInfo title="Integration Info">
      <ul>
        <ListItem>
          <ListTitle>Status</ListTitle>
          <ListDetails>
            <StyledStatusDot
              size="small"
              variant={is_active ? 'success' : 'caution'}
            />
            {is_active
              ? 'Orders are being forwarded to your POS'
              : 'Orders are not being forwarded to your POS. Click Send Orders to resume receiving orders.'}
          </ListDetails>
        </ListItem>
        <ListItem>
          <ListTitle>Authentication</ListTitle>
          <ListDetails>
            Authenticated to ChowNow as: {authentication ?? restaurant.name}
          </ListDetails>
        </ListItem>
        <ListItem>
          <ListTitle>Business Address</ListTitle>
          {address && (
            <ListDetails>
              {address.address_line_1 && `${address.address_line_1}, `}
              {address.address_line_2 && `${address.address_line_2}, `}
              {address.locality && `${address.locality}, `}
              {address.administrative_district_level_1 &&
                `${address.administrative_district_level_1}, `}
              {address.postal_code}
            </ListDetails>
          )}
        </ListItem>
        {phone_number && (
          <ListItem>
            <ListTitle>Business Phone Number</ListTitle>
            <ListDetails>{getFormattedPhoneNumber(phone_number)}</ListDetails>
          </ListItem>
        )}
        <ListItem>
          <ListTitle>Integration Sync</ListTitle>
          <ListDetails>
            {updated_at && (
              <>
                Last Updated&nbsp;
                {getFormattedDate(updated_at)}
              </>
            )}
            {token_expiration
              ? `, Scheduled to Refresh on ${format(
                new Date(token_expiration),
                'MMM do, yyyy'
              )}`
              : null}
          </ListDetails>
        </ListItem>
        {user?.is_chownow_admin && (
          <ListItem>
            <ListTitle>Menu</ListTitle>
            <MenuRow>
              {menu_link ? (
                <Button
                  variant="link"
                  label="View & Edit"
                  size="small"
                  onClick={navigateToMenu}
                />
              ) : (
                <div>
                  Last Updated {getFormattedDate(last_menu_sync_at || '')}
                </div>
              )}
            </MenuRow>
          </ListItem>
        )}
      </ul>
    </CardInfo>
  );
};

export default IntegrationDetailsCardInfo;
