import React, { FC } from 'react';
import { ProgressResponse, WizardNavPagePaths } from 'types/progress';
import { getWizardPages } from 'containers/Onboarding/helpers';

import { ReactComponent as CompletedIcon } from 'images/icon-onboarding-step-completed.svg';
import { ReactComponent as CurrentIcon } from 'images/icon-onboarding-step-current.svg';
import { ReactComponent as IncompleteIcon } from 'images/icon-onboarding-step-incomplete.svg';
import {
  CloseButton,
  Paragraph,
  Step,
  StepLabel,
  Title,
  WizardChecklistShade,
  WizardChecklistWrapper,
} from './styles';

interface WizardChecklistProps {
  className?: string;
  getProgressRequestResponse: ProgressResponse;
  isOpen: boolean;
  onClose: () => void;
}

const STEP_NAMES: {[key: string]: string} = {
  [WizardNavPagePaths.SHARE_MENU]: 'Share Your Menu',
  [WizardNavPagePaths.SALES_TAX]: 'Tax Rate',
  [WizardNavPagePaths.RESTAURANT_HOURS]: 'Restaurant Hours',
  [WizardNavPagePaths.WEBSITE_ACCESS]: 'Website Access',
  [WizardNavPagePaths.LOGO_AND_FOOD_IMAGE]: 'Logo & Image',
  [WizardNavPagePaths.PROMOTION]: 'Promotion',
  [WizardNavPagePaths.PAYMENT_PROCESSING]: 'Payment Processing',
};

export const WizardChecklist: FC<WizardChecklistProps> = ({
  className,
  getProgressRequestResponse,
  isOpen,
  onClose,
}) => {
  const steps = getWizardPages(getProgressRequestResponse);

  const stepStatusMap: {[key: string]: string | null} = {
    [WizardNavPagePaths.SHARE_MENU]:
      getProgressRequestResponse.shared_menu.status,
    [WizardNavPagePaths.SALES_TAX]: getProgressRequestResponse.tax_rate.status,
    [WizardNavPagePaths.RESTAURANT_HOURS]:
      getProgressRequestResponse.schedule.status,
    [WizardNavPagePaths.WEBSITE_ACCESS]:
      getProgressRequestResponse.website_access.status,
    [WizardNavPagePaths.LOGO_AND_FOOD_IMAGE]:
      getProgressRequestResponse.logo_and_food_image.status,
    [WizardNavPagePaths.PROMOTION]: getProgressRequestResponse.promotion.status,
    [WizardNavPagePaths.PAYMENT_PROCESSING]:
      getProgressRequestResponse.stripe_verification.status,
  };

  const getStepIcon = (step: WizardNavPagePaths) => {
    const isCurrentStep = window.location.pathname.indexOf(step) !== -1;
    if (isCurrentStep) {
      return <CurrentIcon />;
    }

    const status = stepStatusMap[step];
    return status === 'completed' ? <CompletedIcon /> : <IncompleteIcon />;
  };

  const getStepName = (step: WizardNavPagePaths) => STEP_NAMES[step] || step;

  return (
    <>
      {isOpen && <WizardChecklistShade onClick={onClose} />}
      <WizardChecklistWrapper className={className} $isOpen={isOpen}>
        <CloseButton
          data-testid="onboarding-checklist-close"
          onClick={onClose}
        />
        <Title>Onboarding Checklist</Title>
        <Paragraph>
          Use this list to track what&apos;s needed for you to go live on
          ChowNow. If you need assistance, contact your account coordinator.
        </Paragraph>
        {steps.slice(1).map(step => (
          <Step key={step}>
            {getStepIcon(step)}
            <StepLabel>{getStepName(step)}</StepLabel>
          </Step>
        ))}
      </WizardChecklistWrapper>
    </>
  );
};
