import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';
import { label, paragraph } from 'styles/types';
import { Button } from '@chownow/cn-web-components';

export const ErrorCard = styled.div`
  border: 1px solid ${semanticColors.border.caution};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.caution};
  font: ${paragraph.reg.x14};
  margin-bottom: ${space.x5};
  margin-top: -${space.x3};
  padding: ${space.x3};
`;

export const Title = styled.h3`
  color: ${semanticColors.content.primary};
  font: ${label.md.x16};
  line-height: ${space.x5};
  margin-bottom: ${space.x2};
`;

export const Instructions = styled.div`
  color: ${semanticColors.content.primary};
  font: ${paragraph.reg.x14};

  p {
    margin-bottom: ${space.x1};
  }
`;

export const Section = styled.div`
  margin-top: ${space.x5};

  ul {
    list-style: inside;
  }
`;

export const Subtitle = styled.h4`
  color: ${semanticColors.content.primary};
  font: ${label.md.x14};
  margin-bottom: ${space.x2};
`;

export const ErrorText = styled.li`
  display: flex;
  margin-top: ${space.x1};
  text-align: left;

  span {
    margin-left: 0;
  }

  p {
    display: inline-block;
    margin: 0;
    margin-left: ${space.x1};
  }

  ::before {
    content: '•';
  }
`;

export const ErrorButton = styled(Button)`
  color: ${semanticColors.content.caution};
  display: inline-block;
  font: ${paragraph.reg.x14};
  z-index: 0;
`;
