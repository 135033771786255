import React, { FC } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

import styles from './styles.module.scss';

interface InfoBlockHoverProps {
  children: React.ReactNode;
  className?: string;
}

const InfoBlockHover: FC<InfoBlockHoverProps> = ({ children, className }) => (
  <div className={styles.infoToolTip}>
    <IconInfo className={styles.iconInfo} />
    <p className={classNames(styles.toolTip, className)}>{children}</p>
  </div>
);

export default InfoBlockHover;
