import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const cardStyles = `
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  color: ${semanticColors.content.primary};
  display: flex;
  flex-direction: column;
  padding: ${space.x5};
`;

export const Data = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.italic.x14};
  margin-bottom: 0;
  margin-top: ${space.x3};
  text-align: left;
  width: 100%;
  word-break: break-word;
`;

export const Heading = styled.h2`
  font: ${label.semi.x16};
  text-align: left;
  width: 100%;
`;
