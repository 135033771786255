import React, { useContext, useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';

import { ModalContext } from 'context/ModalContext';
import breakpoints from 'helpers/breakpoints';
import DialogModal from 'components/Modals/DialogModal';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import styles from './styles.module.scss';

const Tooltip = props => {
  const {
    alignment = 'bottom',
    disabled = false,
    title,
    message,
    children,
  } = props;
  const { showModal } = useContext(ModalContext);
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipHeight, setTooltipHeight] = useState();
  const isVerticalAligned = alignment === 'top' || alignment === 'bottom';
  const isTablet = useMediaQuery({ maxWidth: breakpoints.mdMax });
  const tooltipRef = useRef();

  useEffect(() => {
    if (!disabled) {
      const elemHeight = tooltipRef.current.offsetHeight;

      setTooltipHeight(elemHeight);
    }
  }, [children, disabled]);

  function handleOnClick() {
    if (isTablet) {
      showModal(DialogModal, {
        confirmLabel: 'Got It',
        message,
        title,
      });
    }
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={() => {
        setIsVisible(true);
      }}
      onMouseLeave={() => {
        setIsVisible(false);
      }}
      onClick={handleOnClick}
    >
      {!disabled && (
        <div
          ref={tooltipRef}
          className={classnames(styles.tooltip, {
            [styles.isVisible]: isVisible && !isTablet,
          })}
          style={{
            left: isVerticalAligned ? 0 : 'unset',
            right: isVerticalAligned ? 0 : 'unset',
            top: isVerticalAligned ? 'unset' : 0,
            bottom: isVerticalAligned ? 'unset' : 0,
            [alignment]: `-${tooltipHeight + 20}px`,
          }}
        >
          <IconInfo className={styles.icon} />
          <div className={styles.title}>{title}</div>
          <div>{message}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
