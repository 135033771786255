import { ProgressResponse, OnboardingWizardPages } from 'types/progress';

const getMissingWizardDetails = (progressResponse: ProgressResponse) =>
  Object.values(OnboardingWizardPages).filter(detail => {
    const responseDetail = progressResponse[detail as keyof ProgressResponse];

    return (
      responseDetail?.is_required &&
      responseDetail?.is_displayable &&
      responseDetail?.status === null
    );
  });

export default getMissingWizardDetails;
