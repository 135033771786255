import styled from 'styled-components';
import { space } from 'styles/variables';

export const StyledFilter = styled.li`
  cursor: pointer;
  margin-bottom: ${space.x3};
`;

export const Label = styled.label`
  align-items: center;
  display: flex;
`;

export const Input = styled.span`
  margin-right: ${space.x1};
  margin-top: -2px;
`;
