import styled from 'styled-components';
import Button from '@chownow/cocina-react-button';
import { screen, space } from 'styles/variables';

export const ReadyTimeButton = styled(Button)<{ isLoading: boolean }>`
  margin: 0 ${space.x1} 0 0;

  ${({ isLoading }) => isLoading && 'width: 151px;'}

  @media (min-width: ${screen.lgMin}) {
    margin: 0 0 0 ${space.x1};
  }
`;
