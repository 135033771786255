import styled from 'styled-components';
import { space } from 'styles/variables';

export const LoadingStateWrapper = styled.div`
  margin: auto;
`;

export const LoadingContent = styled.div`
  height: 48px;
  /* Accounts for 12px top padding on Footer to mirror the 64px top padding on ContentContainer */
  margin-bottom: ${space.x6};
  position: relative;
  width: 48px;
`;
