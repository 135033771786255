export type PromotionEmptyResponse = Record<string, never>;

export interface PromotionNoPromoResponse {
  discount_type: null;
  discount_value: 0.0;
  minimum_subtotal: 0.0;
  single_use: boolean;
}

export interface Promotion2DollarAmountResponse {
  discount_type: 'dollar_amount';
  discount_value: 2.0;
  minimum_subtotal: 10.0;
  single_use: boolean;
}

export interface Promotion5DollarAmountResponse {
  discount_type: 'dollar_amount';
  discount_value: 5.0;
  minimum_subtotal: 20.0;
  single_use: boolean;
}

export interface Promotion10PercentageResponse {
  discount_type: 'percentage';
  discount_value: 10.0;
  minimum_subtotal: 0.0;
  single_use: boolean;
}

export interface Promotion20PercentageResponse {
  discount_type: 'percentage';
  discount_value: 20.0;
  minimum_subtotal: 0.0;
  single_use: boolean;
}

export interface Promotion400Response {
  code: string;
  message: string;
  data_path: string;
  data_value: string;
  definition: Record<string, never>;
  validation_message: string;
}

export interface Promotion404Response {
  message: string;
}

export interface Promotion500Response {
  code: string;
  message: string;
}

export type PromotionPreviouslySubmittedResponse =
  PromotionNoPromoResponse
  | Promotion2DollarAmountResponse
  | Promotion5DollarAmountResponse
  | Promotion10PercentageResponse
  | Promotion20PercentageResponse;

export type PromotionErrorResponse =
  Promotion400Response
  | Promotion404Response
  | Promotion500Response;

export type PromotionResponse =
  PromotionPreviouslySubmittedResponse
  | PromotionEmptyResponse
  | PromotionErrorResponse;

export const isPromotion400Response =
  (response: PromotionResponse): response is Promotion400Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&
    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.prototype.hasOwnProperty.call(response, 'data_path') &&
    Object.prototype.hasOwnProperty.call(response, 'data_value') &&
    Object.prototype.hasOwnProperty.call(response, 'definition') &&
    Object.prototype.hasOwnProperty.call(response, 'validation_message')
  );

export const isPromotion404Response =
  (response: PromotionResponse): response is Promotion404Response => (
    Object.prototype.hasOwnProperty.call(response, 'message') && Object.keys(response).length === 1
  );

export const isPromotion500Response =
  (response: PromotionResponse): response is Promotion500Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&
    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.keys(response).length === 2
  );

export const isPromotionPreviouslySubmittedResponse =
  (response: PromotionResponse): response is PromotionPreviouslySubmittedResponse => (
    Object.prototype.hasOwnProperty.call(response, 'discount_type') &&
    Object.prototype.hasOwnProperty.call(response, 'discount_value') &&
    Object.prototype.hasOwnProperty.call(response, 'minimum_subtotal') &&
    Object.prototype.hasOwnProperty.call(response, 'single_use')
  );

export interface PromotionNoPromoPutRequestBody {
  discount_type: null;
  discount_value: 0;
  minimum_subtotal: 0;
  single_use: boolean;
}

export interface Promotion5DollarAmountPutRequestBody {
  discount_type: 'dollar_amount';
  discount_value: 5;
  minimum_subtotal: 20;
  single_use: boolean;
}

export interface Promotion10PercentagePutRequestBody {
  discount_type: 'percentage';
  discount_value: 10;
  minimum_subtotal: 0;
  single_use: boolean;
}

export interface Promotion20PercentagePutRequestBody {
  discount_type: 'percentage';
  discount_value: 20;
  minimum_subtotal: 0;
  single_use: boolean;
}

export type PromotionPutRequestBody =
  PromotionNoPromoPutRequestBody
  | Promotion5DollarAmountPutRequestBody
  | Promotion10PercentagePutRequestBody
  | Promotion20PercentagePutRequestBody;

export type PromotionPutRequestResponse =
  PromotionNoPromoPutRequestBody
  | Promotion5DollarAmountPutRequestBody
  | Promotion10PercentagePutRequestBody
  | Promotion20PercentagePutRequestBody
  | PromotionErrorResponse;
