import React, { FC } from 'react';
import { Radio } from '@chownow/cn-web-components';
import { WebsiteAccessResponse } from 'types/website-access';

import {
  WebsiteAccessOptions,
  WEBSITE_ACCESS_CHOWNOW_OPTION,
  WEBSITE_ACCESS_RESTAURANT_PARTNER_OPTION,
  WEBSITE_ACCESS_THIRD_PARTY_OPTION,
} from '../../constants';
import {
  CardWrapper,
  RadioRow,
  RadioWrapper,
} from './styles';
import { Heading } from '../styles';

interface WebsiteAccessCardProps {
  onSelectionChange: (option: WebsiteAccessOptions) => void;
  selectedOption: WebsiteAccessOptions | undefined;
  websiteAccessResponse?: WebsiteAccessResponse;
}

const WebsiteAccessCard: FC<WebsiteAccessCardProps> = ({
  onSelectionChange,
  selectedOption,
  websiteAccessResponse,
}) => (
  <CardWrapper $hasSelectedOption={!!selectedOption}>
    <Heading>Who will be adding your ordering link to your website?</Heading>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={
            (selectedOption || websiteAccessResponse?.responsible_party) ===
            WebsiteAccessOptions.CHOWNOW
          }
          name="website-access-radio-button"
          onChange={() => onSelectionChange(WebsiteAccessOptions.CHOWNOW)}
          value="websiteAccessChowNow"
          disabled={!!websiteAccessResponse?.responsible_party}
        />
      </RadioWrapper>
      {WEBSITE_ACCESS_CHOWNOW_OPTION}
    </RadioRow>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={
            (selectedOption || websiteAccessResponse?.responsible_party) ===
            WebsiteAccessOptions.RESTAURANT_PARTNER
          }
          name="website-access-radio-button"
          onChange={() =>
            onSelectionChange(WebsiteAccessOptions.RESTAURANT_PARTNER)}
          value="websiteAccessMyself"
          disabled={!!websiteAccessResponse?.responsible_party}
        />
      </RadioWrapper>
      {WEBSITE_ACCESS_RESTAURANT_PARTNER_OPTION}
    </RadioRow>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={
            (selectedOption || websiteAccessResponse?.responsible_party) ===
            WebsiteAccessOptions.THIRD_PARTY_DEVELOPER
          }
          name="website-access-radio-button"
          onChange={() =>
            onSelectionChange(WebsiteAccessOptions.THIRD_PARTY_DEVELOPER)}
          value="websiteAccessThirdPartyContact"
          disabled={!!websiteAccessResponse?.responsible_party}
        />
      </RadioWrapper>
      {WEBSITE_ACCESS_THIRD_PARTY_OPTION}
    </RadioRow>
  </CardWrapper>
);

export default WebsiteAccessCard;
