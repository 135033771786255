import styled from 'styled-components';
import { paragraph } from 'styles/types';
import { screen, space } from 'styles/variables';
import { Button } from '@chownow/cn-web-components';

export const ErrorContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 ${space.x2};
`;

export const ErrorContent = styled.div<{ $isManagePhotosToast: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  @media (min-width: ${screen.smMin}) {
    ${({ $isManagePhotosToast }) => ($isManagePhotosToast ? `padding: ${space.x2} 0;` : `padding: ${space.x6} 0;`)}
  }
`;

export const ErrorMessage = styled.p`
  font: ${paragraph.reg.x16};
  margin: 0;
  max-width: 500px;
  padding: ${space.x2} 0;
  text-align: center;
  width: 100%;
`;

export const ViewIntegrationButton = styled(Button)`
  margin-top: ${space.x1};
`;
