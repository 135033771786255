import React, { useEffect, useRef, useState, MouseEventHandler, ChangeEvent } from 'react';
import classNames from 'classnames';

import { ReactComponent as IconClose } from 'images/icon-close-16.svg';
import { Button } from '@chownow/cn-web-components';
import styles from './styles.module.scss';

export interface LinkType {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export interface InputSharedProps {
  /** data-testid */
  dataTestId?: string;
  /** Error message to display */
  errorText?: string;
  /** Help message to display */
  helpText?: string;
  /** ID for input. */
  id?: string;
  /** Hints at the type of data that might be entered which allows the browser to display an appropriate virtual keyboard */
  inputMode?: 'decimal' | 'email' | 'none' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
  /** Link to be displayed inside input on right side. Example: "Forgot" link for password field. requires 'lable' and 'url' properties. */
  link?: LinkType;
  /** Callback function when input is changed. */
  onChange?: (value: string) => void;
  onKeyDown?: (value: string) => void;
  /** Input type */
  type?: 'text' | 'password' | 'email' | 'number';
  /** Initial value of input */
  value?: string;
}

export interface InputProps extends InputSharedProps {
  /** Sets character limit for field. Displays an indicator below input with current char count. */
  characterLimit?: number | undefined;
  /** CSS class to be applied to input. */
  className?: string;
  /** Disable input field */
  disabled?: boolean;
  /** Icon to be displayed inside of input. */
  icon?: React.ReactNode;
  /** Display a multi line text input */
  isMultiLine?: boolean;
  /** Label to be displayed above input - is required for a11y, but can use shouldHideLabel to visually hide it. */
  label: string;
  /**  Input name */
  name: string;
  /** Placeholder text to be displayed in input when empty. */
  placeholder?: string;
  /** Visually hides label but keeps it accessible to screen readers. */
  shouldHideLabel?: boolean;
}

const Input: React.FC<InputProps> = ({
  characterLimit,
  className = '',
  dataTestId,
  disabled = false,
  errorText,
  helpText,
  icon,
  id,
  inputMode,
  isMultiLine = false,
  label,
  link,
  name,
  onChange = () => { /* default */ },
  onKeyDown = () => { /* default */ },
  placeholder,
  shouldHideLabel = false,
  type = 'text',
  value = '',
}) => {
  const [inputValue, setInputValue] = useState(value);
  const showClearLink = !!inputValue.length && !disabled && !link && !isMultiLine;
  const isMounted = useRef(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const elementType = isMultiLine ? 'textarea' : 'input';
  const isNumbertype = type === 'number';
  const inputType = isNumbertype ? 'text' : type; // don't allow <Input type=number />. use formatValue() to enforce numeric input

  // don't allow 0 or negative numbers for maxLength
  const maxLength = characterLimit && characterLimit > 0 ? characterLimit : undefined;

  // side effects for when value changes
  useEffect(() => {
    if (isMounted.current && value !== inputValue) {
      setInputValue(value);
      if (inputRef.current) {
        const input = inputRef.current;
        const start = input.selectionStart;
        // update the state and then restore the cursor position
        setTimeout(() => {
          if (start !== null && input.selectionStart !== start) {
            input.selectionStart = start;
            input.selectionEnd = start;
          }
        }, 0);
      }
    } else {
      isMounted.current = true;
    }
  }, [value]);

  // numbers only when type="number"
  const formatValue = (val: string) => (isNumbertype ? val.replace(/[^0-9.]/g, '') : val);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formattedValue = formatValue(e.target.value);
    setInputValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div className={className} data-testid={dataTestId}>
      <label
        htmlFor={id}
        className={classNames(styles.label, {
          [styles.disabled]: disabled,
          [styles.srOnly]: shouldHideLabel,
        })}
      >
        {label}
      </label>
      <div
        className={classNames(styles.inputContainer, {
          [styles.visibleLabel]: !shouldHideLabel,
        })}
      >
        {icon && (
          <div
            className={classNames(styles.icon, { [styles.placeholderIcon]: !inputValue.length })}
          >
            {icon}
          </div>
        )}
        {React.createElement(
          elementType,
          {
            ref: inputRef,
            className: classNames(styles.input, {
              [styles.indent]: !!icon,
              [styles.error]: errorText,
              [styles.withLink]: link || showClearLink,
              [styles.multi]: isMultiLine,
            }),
            'aria-describedby': 'input-help-text',
            id,
            inputMode,
            maxLength,
            name,
            onChange: handleInputChange,
            onKeyDown,
            placeholder,
            type: inputType,
            value: inputValue,
            disabled,
            'aria-label': id,
          },
          isMultiLine ? inputValue : null // children argument for textarea input
        )}
        {link && (
          <div className={styles.link}>
            <Button
              label={link.label}
              onClick={link.onClick}
              platform="brand"
              size="xsmall"
              variant="link"
            />
          </div>
        )}
        {showClearLink && (
          <div className={styles.clearIconWrapper}>
            <IconClose
              className={styles.clearIcon}
              onClick={() => {
                setInputValue('');
                onChange('');
              }}
              role="button"
            />
          </div>
        )}
      </div>
      {(!!errorText || !!helpText || !!characterLimit) && (
        <p className={styles.helpSection}>
          <span
            className={classNames(styles.helpText, {
              [styles.error]: errorText,
            })}
            id="input-help-text"
          >
            {errorText || helpText}
          </span>
          {!!characterLimit && (
            <span className={styles.characterLimit}>
              {inputValue.length}/{characterLimit}
            </span>
          )}
        </p>
      )}
    </div>
  );
};

export default Input;
