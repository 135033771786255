import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';
import { prepTimeValidator } from 'helpers/validators';
import { Formik, Form, Field } from 'formik';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { Button } from '@chownow/cn-web-components';
import ToggleButton from 'components/ToggleButton';

import styles from './styles.module.scss';

function PrepTimeModal(props) {
  const { handleSetPrepTime, prepTimeSet } = props;
  const { hideModal } = useContext(ModalContext);
  const [prepTimeStatus, setPrepTimeStatus] = useState(prepTimeSet || false);

  function submitPrepTime(prepTime) {
    handleSetPrepTime(prepTime);
    hideModal();
  }

  function handlePrepTimeToggle() {
    setPrepTimeStatus(!prepTimeStatus);
    // if prep time has already been set, toggling will send null to prep time endpoint
    if (prepTimeSet) {
      handleSetPrepTime(null);
    }
  }

  function getPrepTimeRange(prepTime) {
    // we want to show restaurants a range of -5min to +5mins from selected preptime
    const min = parseInt(prepTime) - 5;
    const max = parseInt(prepTime) + 5;
    return `${min}-${max}`;
  }

  return (
    <div className={styles.prepTimeModal}>
      <button className={styles.iconClose} onClick={() => hideModal()}>
        <IconClose />
      </button>
      <Formik
        validate={prepTimeValidator}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          time: '',
          customTime: '',
        }}
        onSubmit={values => {
          submitPrepTime(values.time);
        }}
      >
        {({ values, handleChange, errors, setFieldValue, dirty }) => (
          <Form>
            <div className={styles.formHeader}>
              <h3 className={styles.modalTitle}>Prep Time</h3>
              <ToggleButton
                className={styles.togglePosition}
                toggleValue={prepTimeStatus}
                onToggle={handlePrepTimeToggle}
              />
            </div>

            {errors && (
              <div className={styles.errorMessage}>{errors.prepTime}</div>
            )}
            <div
              className={classNames(styles.prepTimeForm, {
                [styles.disabled]: prepTimeStatus === false,
              })}
            >
              <div className={styles.sectionText}>
                Give diners an estimate of how long it's taking for food to be
                prepared.
              </div>
              <div role="group" aria-labelledby="preset-time-group">
                <div className={styles.sectionTitle}>
                  How much prep time do you need?
                </div>
                <div className={styles.sectionText}>
                  A buffer of 10 minutes will be added to your selected prep
                  time. For example, if you select “10” your range will show as
                  “ASAP (5-15 minutes)” to your diners.
                </div>
                <div className={styles.timeButtons}>
                  <Field
                    id="10"
                    type="radio"
                    name="time"
                    value="10"
                    checked={values.time === '10'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="10" className={styles.timeLabel}>
                    <div className={styles.time}>10</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="20"
                    type="radio"
                    name="time"
                    value="20"
                    checked={values.time === '20'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="20" className={styles.timeLabel}>
                    <div className={styles.time}>20</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="30"
                    type="radio"
                    name="time"
                    value="30"
                    checked={values.time === '30'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="30" className={styles.timeLabel}>
                    <div className={styles.time}>30</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="40"
                    type="radio"
                    name="time"
                    value="40"
                    checked={values.time === '40'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="40" className={styles.timeLabel}>
                    <div className={styles.time}>40</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="50"
                    type="radio"
                    name="time"
                    value="50"
                    checked={values.time === '50'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="50" className={styles.timeLabel}>
                    <div className={styles.time}>50</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="60"
                    type="radio"
                    name="time"
                    value="60"
                    checked={values.time === '60'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="60" className={styles.timeLabel}>
                    <div className={styles.time}>60</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="70"
                    type="radio"
                    name="time"
                    value="70"
                    checked={values.time === '70'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="70" className={styles.timeLabel}>
                    <div className={styles.time}>70</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                  <Field
                    id="80"
                    type="radio"
                    name="time"
                    value="80"
                    checked={values.time === '80'}
                    onClick={() => setFieldValue('customTime', '')}
                  />
                  <label htmlFor="80" className={styles.timeLabel}>
                    <div className={styles.time}>80</div>
                    <div className={styles.mins}>Min</div>
                  </label>
                </div>
              </div>

              <label className={styles.sectionTitle}>
                Add a custom time (in minutes)
              </label>
              <Field
                id="customTime"
                type="text"
                name="customTime"
                className={styles.customTimeInput}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('time', e.target.value);
                }}
                placeholder="Enter numbers up to 180 minutes"
              />
              <div className={styles.modalFooter}>
                <Button
                  type="submit"
                  className={styles.button}
                  label={'Apply'}
                  disabled={!dirty}
                />
                <div className={styles.prepTimeBufferCopy}>
                  {values.time && values.time >= 5 && values.time <= 180 && (
                    <span>
                      Diners will see {getPrepTimeRange(values.time)}min for
                      order prep time.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

PrepTimeModal.propTypes = {
  handlePauseOrders: PropTypes.func,
  prepTimeSet: PropTypes.bool,
};

export default PrepTimeModal;
