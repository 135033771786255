import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { fontWeight, label } from 'styles/types';
import { space } from 'styles/variables';

export const StyledModal = styled.div`
  text-align: left;
`;

export const Title = styled.h1`
  font: ${label.semi.x20};
  font-weight: ${fontWeight.semiBold};
  margin-bottom: ${space.x2};
  text-transform: capitalize;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Total = styled(Row)`
  margin-top: ${space.x3};
`;

export const RevisedTotal = styled(Row)`
  color: ${semanticColors.content.active};
  font-weight: ${fontWeight.semiBold};
  margin: ${space.x2} 0 ${space.x3};
`;
