import React from 'react';
import classNames from 'classnames';

import { orderDetailsType } from 'helpers/prop-types';
import { ORDER_STATUS } from 'helpers/constants';
import { getOrderDateTime, getOrderDateTimeBanner } from 'helpers/dateTimeOld';
import { getFulfillmentTime } from 'helpers/ordersList';

import styles from './styles.module.scss';

function OrderInfo(props) {
  const { orderDetails } = props;

  function getPaymentType() {
    if (orderDetails.is_post_pay) {
      return 'CASH';
    } else if (orderDetails.customer.billing) {
      return `Card: ${orderDetails.customer.billing.card_type}`;
    } else if (orderDetails.marketplace.id === '1') {
      // marketplace.id '1' are orders placed on yelp, '2' is discover orders, and '3' is google food
      return 'Payment via Yelp';
    }
  }

  return (
    <>
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Status:</span>
        <div
          className={classNames(styles.orderStatus, {
            [styles.statusSubmitted]:
              `${orderDetails.status}` === ORDER_STATUS.submitted,
            [styles.statusAccepted]:
              `${orderDetails.status}` === ORDER_STATUS.accepted,
            [styles.statusCanceled]:
              `${orderDetails.status}` === ORDER_STATUS.canceled,
          })}
        >
          {orderDetails.status}
        </div>
      </div>
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Time of Order:</span>
        {getOrderDateTime(orderDetails.fulfillment_times.created_at)}
      </div>
      {orderDetails.managed_delivery && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>
            Delivery Pickup Time:
          </span>
          <span className={styles.deliveryInstructionsCopy}>
            {getOrderDateTime(orderDetails.fulfillment_times.estimated_pickup)}
          </span>
        </div>
      )}
      {!!orderDetails.is_order_ahead && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>Requested Time:</span>
          <span className={styles.deliveryInstructionsCopy}>
            {getOrderDateTime(
              orderDetails.fulfillment_times.requested_fulfillment
            )}
          </span>
        </div>
      )}
      {!orderDetails.managed_delivery && (
        <div className={styles.infoRow}>
          <span className={styles.infoRowTitle}>
            {orderDetails.fulfill_method === 'dine_in'
              ? 'Dine-in '
              : `${orderDetails.fulfill_method} `}
            Time:
          </span>
          {orderDetails.status === ORDER_STATUS.accepted
            ? getOrderDateTime(getFulfillmentTime(orderDetails))
            : '--'}
        </div>
      )}
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Type:</span>
        {orderDetails.fulfill_method === 'dine_in' ? (
          'Dine-in'
        ) : (
          <span className={styles.capitalize}>
            {orderDetails.fulfill_method}
          </span>
        )}
      </div>
      {orderDetails.managed_delivery && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>Delivery By:</span>
          <span className={styles.deliveryInstructionsCopy}>
            {orderDetails.managed_delivery.label}
          </span>
        </div>
      )}
      {orderDetails.delivery_instructions && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>
            Delivery Instruction:
          </span>
          <span className={styles.deliveryInstructionsCopy}>
            {orderDetails.delivery_instructions}
          </span>
        </div>
      )}
      {orderDetails.customer.curbside && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>Curbside Car Info:</span>
          <span className={styles.deliveryInstructionsCopy}>
            <p className={styles.infoParagraph}>
              Color: {orderDetails.customer.curbside.vehicle.color}
            </p>
            <p className={styles.infoParagraph}>
              Type: {orderDetails.customer.curbside.vehicle.vehicle_type}
            </p>
          </span>
        </div>
      )}
      {orderDetails.customer.dine_in && (
        <div className={styles.infoRow}>
          <span className={styles.instructionsTitle}>Dine-in Table:</span>
          <span className={styles.deliveryInstructionsCopy}>
            {orderDetails.customer.dine_in.table_name}
          </span>
        </div>
      )}
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Payment Type:</span>
        <span className={styles.capitalize}>{getPaymentType()}</span>
      </div>
      {/* If .billing is not null, this means that the customer used apple pay or a cc.
            If it returns null, it means they used a post pay method (cash) */}
      {orderDetails.customer.billing && (
        <div className={styles.infoRow}>
          <span className={styles.infoRowTitle}>Card Number:</span>
          {orderDetails.customer.billing.card_number}
        </div>
      )}
      {/* Show banner for order ahead orders*/}
      {orderDetails.is_order_ahead && (
        <div className={styles.orderInstructions}>
          <span className={styles.instructionsTitle}>Order Instructions:</span>
          <div className={styles.instructionsCopy}>
            {`CUSTOMER REQUESTED: ${orderDetails.fulfill_method} on
            ${getOrderDateTimeBanner(
              orderDetails.fulfillment_times.requested_fulfillment
            )}`}
          </div>
        </div>
      )}
    </>
  );
}

OrderInfo.propTypes = {
  orderDetails: orderDetailsType,
};

export default OrderInfo;
