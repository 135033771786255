export function getFulfillmentTime(order) {
  const {
    estimated_arrival,
    estimated_pickup,
    requested_fulfillment,
    updated_at,
  } = order.fulfillment_times;

  return (
    estimated_pickup || estimated_arrival || requested_fulfillment || updated_at
  );
}
