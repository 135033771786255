import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';

export const CardWrapper = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  color: ${semanticColors.content.primary};
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
  padding: ${space.x5};
`;
