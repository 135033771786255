import styled from 'styled-components';
import { space } from 'styles/variables';
import Input from 'components/Input';

import { cardStyles } from '../styles';

export const BottomCardWrapper = styled.div`
  ${cardStyles};

  margin-bottom: 142px;
  margin-top: ${space.x3};
`;

export const StyledInput = styled(Input)`
  margin-top: ${space.x3};
`;
