import React, { ComponentType, ReactElement, createContext } from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from '@chownow/cn-web-components';

import {
  TableWrapper,
  EmptyCard,
  LoadingSpinnerWrapper,
  LoadingWrapper,
} from './styles';

export interface TableProps {
  /** Table Header content */
  children: ReactElement | ReactElement[] | string;
  /** CSS class to be applied to input. */
  className?: string;
  /** Used to display a loading state. */
  isLoading?: boolean;
  /** Icon to display on top of the empty message */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyIcon?: ComponentType<any>;
  /** A message to display when there are no rows in the table */
  emptyMessage?: string;
}

const hasNoTableData = (
  children:
    | string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
) => {
  if (Array.isArray(children)) {
    const secondChild = children[1] as React.ReactElement<
      { children?: React.ReactNode },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      string | React.JSXElementConstructor<any>
    >;

    if (
      React.isValidElement(secondChild) &&
      secondChild.props.children &&
      Array.isArray(secondChild.props.children) &&
      secondChild.props.children.length > 0
    ) {
      return false;
    }
  }

  return true;
};

export const TableContext = createContext({});

const Table = ({
  children,
  className = '',
  isLoading = false,
  emptyIcon: EmptyIcon,
  emptyMessage = 'No Data',
}: TableProps) => {
  let content;

  if (isLoading) {
    content = (
      <LoadingSpinnerWrapper>
        <LoadingWrapper>
          <LoadingSpinner isCentered size="large" />
        </LoadingWrapper>
      </LoadingSpinnerWrapper>
    );
  } else if (hasNoTableData(children)) {
    content = (
      <EmptyCard>
        {EmptyIcon && <EmptyIcon />}
        {emptyMessage}
      </EmptyCard>
    );
  }

  return (
    <>
      <TableWrapper className={classNames(className)}>
        {children}
      </TableWrapper>
      {content}
    </>
  );
};

export default Table;
