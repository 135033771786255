import React from 'react';

import { DaysOfTheWeek, HoursSection } from '../constants';
import HoursRow from '../HoursRow';

const PickupHours = () => (
  <div data-testid="pickup-hours">
    {Object.keys(DaysOfTheWeek).map((day) => <HoursRow day={day} key={`pickup-${day}`} hoursSection={HoursSection.Pickup} />)}
  </div>
);

export default PickupHours;
