// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".labelStyle_4603265886476{text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/components/ImageUploader/CropPhotoBlogLink/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,yBAAA","sourcesContent":[".labelStyle {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": "labelStyle_4603265886476"
};
export default ___CSS_LOADER_EXPORT___;
