import React, { FC, useEffect, useState } from 'react';
import { Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetOrderDetails } from 'api/orders/orderDetails';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Banner from '@chownow/cocina-react-banner';
import PartialRefunds from 'containers/Restaurant/Orders/PartialRefunds';

import Restaurant from 'types/restaurant';
import ErrorState from 'containers/Restaurant/ErrorState';
import LoadingState from 'containers/Restaurant/LoadingState';
import CustomerInfoCard from './CustomerInfoCard';
import {
  canRefundOrder,
  canRefundOrderByAmount,
  canRefundOrderByItem,
  canRefundOrderFull,
  getOrderInfo,
} from './helpers';
import { StyledContentContainer, OrderDetailsContent } from './styles';
import OrderInfoCard from './OrderInfo';
import OrderCard from './OrderCard';
import RefundMenu from './RefundMenu';
import AcknowledgeMenu from './AcknowledgeMenu';
import RefundedOrder from './OrderCard/RefundedOrder';

interface OrderDetailsProps {
  restaurant: Restaurant;
}

const OrderDetails: FC<OrderDetailsProps> = ({ restaurant }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [refundMessage, setRefundMessage] = useState('');
  const [hasCheckedEligibility, setHasCheckedEligibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const flags = useFlags();
  const { orderId } = useParams();
  const {
    hasGetOrderDetailsRequestError,
    isGetOrderDetailsRequestLoading,
    getOrderDetailsRequestResponse,
    getOrderDetails,
  } = useGetOrderDetails(orderId);

  useEffect(() => {
    void getOrderDetails();
  }, []);

  const updateRefundMessage = () => {
    if (getOrderDetailsRequestResponse?.status === 'Accepted') {
      let msg = '';
      if (
        getOrderDetailsRequestResponse &&
        !canRefundOrder(
          getOrderDetailsRequestResponse,
          flags.refundByAmountRedesign as boolean
        )
      ) {
        msg =
          'If you would like to refund this order please contact Customer Support by calling 1.888.707.2469';
      } else if (
        getOrderDetailsRequestResponse &&
        canRefundOrderFull(getOrderDetailsRequestResponse) &&
        !canRefundOrderByAmount(getOrderDetailsRequestResponse) &&
        !canRefundOrderByItem(getOrderDetailsRequestResponse)
      ) {
        msg =
          'Full refund is available, for other types of refunds please contact Customer Support by calling 1.888.707.2469';
      }
      setRefundMessage(msg);
    }
  };

  // Determine messaging around refunds
  useEffect(() => {
    if (!hasCheckedEligibility && getOrderDetailsRequestResponse) {
      updateRefundMessage();
      setHasCheckedEligibility(true);
    }
  }, [getOrderDetailsRequestResponse]);

  useEffect(() => {
    if (!successMessage) {
      updateRefundMessage();
    }
  }, [successMessage]);

  let content;

  if (hasGetOrderDetailsRequestError) {
    content = (
      <Grid md={12}>
        <ErrorState />
      </Grid>
    );
  } else {
    content = (
      <>
        <Grid md={7}>
          <CustomerInfoCard
            customer={getOrderDetailsRequestResponse?.customer}
          />
          <OrderInfoCard order={getOrderInfo(getOrderDetailsRequestResponse)} />
        </Grid>
        <Grid md={5}>
          {getOrderDetailsRequestResponse?.refund_info && (
            <RefundedOrder order={getOrderDetailsRequestResponse} />
          )}
          <OrderCard order={getOrderDetailsRequestResponse} />
        </Grid>
      </>
    );
  }

  let secondaryCTAElement: JSX.Element | undefined;

  if (
    getOrderDetailsRequestResponse?.status === 'Accepted' &&
    canRefundOrder(
      getOrderDetailsRequestResponse,
      flags.refundByAmountRedesign as boolean
    )
  ) {
    secondaryCTAElement = (
      <RefundMenu
        order={getOrderDetailsRequestResponse}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        onRefundSuccess={getOrderDetails}
      />
    );
  } else if (getOrderDetailsRequestResponse?.status === 'Submitted') {
    secondaryCTAElement = (
      <AcknowledgeMenu
        orderDetails={getOrderDetailsRequestResponse}
        orderId={orderId}
        onError={setErrorMessage}
        onSuccess={getOrderDetails}
        setIsLoading={setIsLoading}
      />
    );
  }

  return (
    <Routes>
      {getOrderDetailsRequestResponse && (
        <Route
          path="/partial-refunds"
          element={(
            <PartialRefunds
              restaurant={restaurant}
              orderDetails={getOrderDetailsRequestResponse}
            />
          )}
        />
      )}
      <Route
        index
        element={(
          <>
            {!successMessage && refundMessage && (
              <Banner variant="neutral" onClose={() => setRefundMessage('')}>
                {refundMessage}
              </Banner>
            )}
            {errorMessage && (
              <Banner variant="caution" onClose={() => setErrorMessage('')}>
                {errorMessage}
              </Banner>
            )}
            {successMessage && (
              <Banner variant="success" onClose={() => setSuccessMessage('')}>
                {successMessage}
              </Banner>
            )}
            <Cover
              title={`Order #${orderId}`}
              secondaryCtaElement={secondaryCTAElement}
            />
            <StyledContentContainer
              $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}
            >
              {(isGetOrderDetailsRequestLoading || isLoading) ? (
                <LoadingState />
              ) : (
                <OrderDetailsContent>
                  <DashboardGrid>{content}</DashboardGrid>
                </OrderDetailsContent>
              )}
            </StyledContentContainer>
          </>
        )}
      />
    </Routes>
  );
};

export default OrderDetails;
