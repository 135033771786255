import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'helpers/breakpoints';
import { Button } from '@chownow/cn-web-components';
import { ReactComponent as IconCaretSmall } from 'images/icon-caret-small.svg';
import { ReactComponent as IconCaret } from 'images/icon-caret.svg';

import getRoutes from './routes';
import { getDisplayedBreadcrumbs } from './helpers';
import styles from './styles.module.scss';

interface BreadcrumbProps {
  hasHomeLink?: boolean,
  restaurantName?: string,
  restaurantId?: string,
}

const Breadcrumbs = ({ hasHomeLink = false, restaurantName = '', restaurantId = '' }: BreadcrumbProps) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });
  const routes = getRoutes(
    restaurantName, '/company/:companyId', '/restaurant/:restaurantId'
  );

  const fullBreadcrumbs = useBreadcrumbs(routes).filter(crumb => !!crumb.match.route);

  const displayedBreadcrumbs = getDisplayedBreadcrumbs(fullBreadcrumbs, hasHomeLink, restaurantId);

  // Get previous route for mobile/tablet
  const backCrumb = displayedBreadcrumbs[displayedBreadcrumbs.length - 2];
  const backRoute = backCrumb?.match?.route?.breadcrumb as string;

  if (isDesktop) {
    return displayedBreadcrumbs.map(({ key, match }) => (
      <div className={styles.breadcrumbWrap} key={key}>
        <Button
          className={styles.breadcrumb}
          label={match?.route?.breadcrumb}
          onClick={() => navigate(key)}
          variant="link"
        />
        <IconCaretSmall className={styles.caret} />
      </div>
    ));
  } else if (backRoute) {
    return (
      <div className={styles.backButtonWrap}>
        <IconCaret className={styles.caret} />
        <Button
          className={styles.breadcrumb}
          label={`Back to ${backRoute}`}
          onClick={() => navigate(backCrumb.key)}
          variant="link"
        />
      </div>
    );
  }
  return null;
};

export default Breadcrumbs;
