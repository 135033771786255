import React, { useContext, useState } from 'react';

import { ModalContext } from 'context/ModalContext';
import InputSearch from 'components/InputSearch';
import { Button } from '@chownow/cn-web-components';
import Radio from '@chownow/cocina-react-radio';

import styles from './styles.module.scss';

interface OrdersFilterModalProps {
  filters: {q: string; search: string;};
  setFilters: (filters: {q: string; search: string;}) => void;
}

const OrdersFilterModal = ({ filters, setFilters }: OrdersFilterModalProps) => {
  const { hideModal } = useContext(ModalContext);
  const [searchQuery, setSearchQuery] = useState(filters.q);
  const [searchCategory, setSearchCategory] = useState(filters.search);

  const handleOnButtonClick = () => {
    setFilters({ ...filters, q: searchQuery, search: searchCategory });
    hideModal();
  };

  return (
    <div className={styles.main}>
      <div>
        <div className={styles.title}>Search</div>
        <div className={styles.divider} />
        <div className={styles.inputRow}>
          <Radio
            name="radio"
            value="customer"
            onChange={() => setSearchCategory('customer')}
            checked={searchCategory === 'customer'}
          />
          Customer Name
        </div>
        <div className={styles.inputRow}>
          <Radio
            name="radio"
            value="order"
            onChange={() => setSearchCategory('order')}
            checked={searchCategory === 'order'}
          />
          Order #
        </div>
        <div className={styles.inputRow}>
          <Radio
            name="radio"
            value="total"
            onChange={() => setSearchCategory('total')}
            checked={searchCategory === 'total'}
          />
          Order Total
        </div>
        <div className={styles.divider} />
        <div className={styles.searchBar}>
          <InputSearch
            onChange={query => setSearchQuery(query)}
            value={searchQuery}
          />
        </div>
      </div>
      <div>
        <div className={styles.divider} />
        <Button isFullWidth label="Done" onClick={handleOnButtonClick} />
      </div>
    </div>
  );
};

export default OrdersFilterModal;
