import { noop } from 'lodash';
import { Dispatch, createContext, SetStateAction } from 'react';
import { HoursData } from '../types';

export interface RestaurantHoursContextValue {
  checkDayCopyable: (day: string, hourOrderType: string) => void;
  copyDayToAll: (day: string, hourOrderType: string) => void;
  deliveryHours: Map<string, HoursData>;
  getDayEndTime: (day: string, hoursOrderType: string) => string | undefined;
  getDayStartTime: (day: string, hoursOrderType: string) => string | undefined;
  getDayIsCopyable: (day: string, hoursOrderType: string) => boolean;
  hasGetRequestError: boolean;
  hasPreviouslySubmittedHours: boolean;
  isDeliveryEnabled: boolean;
  isFormCompleted: boolean;
  isGetRequestLoading: boolean;
  isPickupEnabled: boolean;
  isSameHours: boolean;
  pickupHours: Map<string, HoursData>;
  resetAllHours: () => void;
  setDayClosed: (day: string, hoursOrderType: string) => void;
  setDayEndTime: (day: string, hoursOrderType: string, endTime: string) => void;
  setDayOpen: (day: string, hoursOrderType: string) => void;
  setDayStartTime: (
    day: string,
    hoursOrderType: string,
    startTime: string
  ) => void;
  setDeliveryHours: Dispatch<SetStateAction<Map<string, HoursData>>>;
  setIsDeliveryEnabled: Dispatch<SetStateAction<boolean>>;
  setIsPickupEnabled: Dispatch<SetStateAction<boolean>>;
  setIsSameHours: Dispatch<SetStateAction<boolean>>;
  setPickupHours: Dispatch<SetStateAction<Map<string, HoursData>>>;
  uuid: string;
}

export const RestaurantHoursContext =
  createContext<RestaurantHoursContextValue>({
    checkDayCopyable: noop,
    copyDayToAll: noop,
    deliveryHours: new Map<string, HoursData>(),
    getDayEndTime: () => undefined,
    getDayStartTime: () => undefined,
    getDayIsCopyable: () => false,
    hasGetRequestError: false,
    hasPreviouslySubmittedHours: false,
    isDeliveryEnabled: false,
    isFormCompleted: true,
    isGetRequestLoading: true,
    isPickupEnabled: false,
    isSameHours: false,
    pickupHours: new Map<string, HoursData>(),
    resetAllHours: noop,
    setDayClosed: noop,
    setDayEndTime: noop,
    setDayOpen: noop,
    setDayStartTime: noop,
    setDeliveryHours: noop,
    setIsDeliveryEnabled: noop,
    setIsPickupEnabled: noop,
    setIsSameHours: noop,
    setPickupHours: noop,
    uuid: '',
  });
