import React, { FC, useEffect, useState } from 'react';
import { Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import { getSearchParam } from 'helpers/url';
import { useGetStripeStatus } from 'api/stripe';
import { useGetProgress, usePostProgress } from 'api/onboarding/progressApis';
import {
  OnboardingWizardPages,
  OnboardingProgressStatus,
} from 'types/progress';
import Restaurant from 'types/restaurant';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AccountInReviewContent from './Content/AccountInReviewContent';
import AccountFailedContent from './Content/AccountFailedContent';
import ActiveContent from './Content/ActiveContent';
import InactiveContent from './Content/InactiveContent';
import RestrictedContent from './Content/RestrictedContent';
import WarningContent from './Content/WarningContent';
import NoAccountContent from './Content/NoAccountContent';
import ErrorState from '../ErrorState';
import LoadingState from '../LoadingState';
import { PaymentProcessingContent, StyledContentContainer } from './styles';

const URL_PATH = 'settings/payment-processing';

interface PaymentProcessProps {
  restaurant: Restaurant;
}

const PaymentProcessing: FC<PaymentProcessProps> = ({ restaurant }) => {
  const flags = useFlags();
  const [hasPostProgressRequestError, setHasPostProgressRequestError] =
    useState(false);
  const statusSearchParam = getSearchParam('status');
  const {
    getStripeStatusRequestResponse,
    isGetStripeStatusRequestLoading,
    hasGetStripeStatusRequestError,
  } = useGetStripeStatus(
    restaurant?.id,
    statusSearchParam
  );
  const {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
  } = useGetProgress(
    restaurant?.sfdc_restaurant_uuid,
    !!restaurant?.sfdc_restaurant_uuid
  );
  const { isPostProgressRequestLoading, postOnboardingProgress } =
    usePostProgress();
  let content;

  useEffect(() => {
    const syncProgress = async () => {
      if (
        restaurant?.sfdc_restaurant_uuid &&
        !isGetStripeStatusRequestLoading &&
        !isGetProgressRequestLoading &&
        !hasGetStripeStatusRequestError &&
        !hasGetProgressRequestError
      ) {
        if (
          getStripeStatusRequestResponse?.x_status === 'verified' &&
          getProgressRequestResponse?.stripe_verification?.status !==
            'completed'
        ) {
          const hasPostError = await postOnboardingProgress(
            restaurant?.sfdc_restaurant_uuid,
            OnboardingWizardPages.STRIPE_VERIFICATION,
            OnboardingProgressStatus.COMPLETED
          );

          setHasPostProgressRequestError(hasPostError);
        }
      }
    };

    void syncProgress();
  }, [isGetStripeStatusRequestLoading, isGetProgressRequestLoading]);

  const isLoading =
    isGetStripeStatusRequestLoading || isGetProgressRequestLoading || isPostProgressRequestLoading;
  const hasError =
    hasGetStripeStatusRequestError ||
    hasGetProgressRequestError ||
    hasPostProgressRequestError ||
    getStripeStatusRequestResponse?.x_status === 'unknown';

  if (hasError) {
    content = (<ErrorState />);
  } else if (statusSearchParam === 'success') {
    content = (
      <AccountInReviewContent restaurant={restaurant} urlPath={URL_PATH} />
    );
  } else if (statusSearchParam === 'failure') {
    content = (
      <AccountFailedContent restaurant={restaurant} urlPath={URL_PATH} />
    );
  } else if (getStripeStatusRequestResponse?.errors) {
    content = <NoAccountContent />;
  } else {
    switch (getStripeStatusRequestResponse?.x_status) {
      case 'verified':
        content = <ActiveContent restaurant={restaurant} urlPath={URL_PATH} />;
        break;
      case 'payouts_disabled':
        content = (
          <RestrictedContent restaurant={restaurant} urlPath={URL_PATH} />
        );
        break;
      case 'restricted_soon':
        content = <WarningContent restaurant={restaurant} urlPath={URL_PATH} />;
        break;
      case 'unverified':
        content = (
          <InactiveContent restaurant={restaurant} urlPath={URL_PATH} />
        );
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Cover
        title="Payment Processing"
        body="We have a partnership with Stripe, a secure payment processor. An
          active account is required to accept and process orders on ChowNow."
      />
      <StyledContentContainer $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}>
        {isLoading ? (
          <LoadingState />
        ) : (
          <DashboardGrid>
            <Grid md={12}>
              <PaymentProcessingContent>
                {content}
              </PaymentProcessingContent>
            </Grid>
          </DashboardGrid>
        )}
      </StyledContentContainer>
    </>
  );
};

export default PaymentProcessing;
