import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { containerWidths, screen, space } from 'styles/variables';

export const StyledOnboardingContainer = styled.div`
  color: ${semanticColors.content.primary};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${containerWidths.containerWidth};
  min-height: calc(100vh - 70px);
  padding: 0 ${space.x2};
  text-align: center;

  @media (min-width: ${screen.smMin}) {
    min-height: calc(100vh - 72px);
    padding: inherit;
    width: ${containerWidths.onboardingContainerWidth};
  }
`;
