import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, screen, space } from 'styles/variables';
import { label, paragraph } from 'styles/types';

export const MenusWrapper = styled.div`
  margin: ${space.x10} 0 ${space.x5};
`;

export const Title = styled.h3`
  color: ${semanticColors.content.primary};
  font: ${label.semi.x20};
  margin: 0 ${space.x2} ${space.x3} 0;
`;

export const SubTitle = styled.p`
  color: ${semanticColors.content.primary};
  font: ${label.reg.x14};
`;

export const MenusCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x3};
  justify-content: center;
  margin-top: ${space.x5};

  @media (min-width: ${screen.xsMin}) {
    justify-content: flex-start;
  }
`;

export const StateWrapperLoading = styled.div`
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  display: flex;
  margin-top: ${space.x5};
  padding: ${space.x11} ${space.x5} ${space.x5};
  width: 100%;
`;

export const StateWrapperEmpty = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${space.x5};
  padding: ${space.x5};
`;

export const EmptyStateMessage = styled.div`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.reg.x14};
  text-align: center;
`;
