import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useGetOrderDetails } from 'api/orders/orderDetails';
import EmbeddedAdmin from 'containers/EmbeddedAdmin';

import { StyledErrorState, StyledLoadingState } from './styles';

const CustomerInfo: FC = () => {
  const { orderId } = useParams();
  const {
    getOrderDetailsRequestResponse,
    isGetOrderDetailsRequestLoading,
    hasGetOrderDetailsRequestError,
  } = useGetOrderDetails(orderId);

  if (hasGetOrderDetailsRequestError) {
    return <StyledErrorState />;
  } else if (isGetOrderDetailsRequestLoading) {
    return (
      <StyledLoadingState />
    );
  } else {
    const customerId = getOrderDetailsRequestResponse?.customer?.id;

    return (
      <EmbeddedAdmin
        src={`customer_by_cust_id/${customerId}`}
        title="Customer Info"
        key={`${customerId}/customer_by_cust_id`}
      />
    );
  }
};

export default CustomerInfo;
