import React, { FC, useEffect, useState } from 'react';
import { Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import ContentContainer from 'components/ContentContainer';
import PosLocations from 'containers/Restaurant/Integrations/PosLocations';
import { useGetIntegrations } from 'api/posIntegrations/integrations';
import { useGetToastDetails } from 'api/posIntegrations/toast';
import {
  Integration,
  isIntegrationsListResponse,
} from 'types/pos/integrations';
import Restaurant from 'types/restaurant';
import ErrorState from '../../ErrorState';
import LoadingState from '../../LoadingState';
import ToastDetails from './ToastDetails';

import ToastPosLandingPageContent from './ToastPosLandingPageContent';

interface ToastPosProps {
  restaurant: Restaurant;
}

const ToastPos: FC<ToastPosProps> = ({ restaurant }) => {
  const [focusState, setFocusState] = useState(false);
  const {
    hasGetIntegrationsRequestError,
    isGetIntegrationsRequestLoading,
    getIntegrationsRequestResponse,
    getIntegrations,
  } = useGetIntegrations(restaurant.id);
  const {
    hasGetToastDetailsRequestError,
    isGetToastDetailsRequestLoading,
    getToastDetailsRequestResponse,
    getToastDetailsRequestErrorMessage,
  } = useGetToastDetails(restaurant.id);
  const isLoading =
    isGetIntegrationsRequestLoading || isGetToastDetailsRequestLoading;

  const [toastIntegration, setToastIntegration] = useState<Integration | null>(
    null
  );

  const fetchIntegrations = () => {
    void getIntegrations();
  };

  // Check if integrations list response contains Toast integration
  useEffect(() => {
    if (isIntegrationsListResponse(getIntegrationsRequestResponse)) {
      const foundToastIntegration = getIntegrationsRequestResponse.find(
        integration => integration.name === 'Toast'
      );
      setToastIntegration(foundToastIntegration || null);
    }
  }, [getIntegrationsRequestResponse]);

  const toastDetailsResponse = getToastDetailsRequestResponse;

  const { authorization_instructions } = toastIntegration || {
    authorization_instructions: null,
  };

  const onFocus = () => {
    setFocusState(true);
  };

  const onBlur = () => {
    setFocusState(false);
  };

  useEffect(() => {
    // User has switched back to the tab
    window.addEventListener('focus', onFocus);
    window.addEventListener('onBlur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('onBlur', onBlur);
    };
  }, []);

  useEffect(() => {
    if (
      focusState &&
      !toastDetailsResponse?.locations?.length &&
      !toastIntegration?.is_authorized
    ) {
      window.location.reload();
    }
  }, [focusState, toastDetailsResponse, toastIntegration]);

  if (toastIntegration?.is_authorized) {
    return <ToastDetails restaurant={restaurant} />;
  }

  let content = (
    <ToastPosLandingPageContent
      authorizationInstructions={authorization_instructions}
    />
  );

  if (
    !toastIntegration?.is_authorized &&
    !!toastDetailsResponse?.locations &&
    !!toastDetailsResponse?.locations.length
  ) {
    content = (
      <PosLocations
        locations={toastDetailsResponse.locations}
        restaurant={restaurant}
        onLocationSelected={fetchIntegrations}
        posName="toast"
      />
    );
  }

  if (hasGetIntegrationsRequestError || hasGetToastDetailsRequestError) {
    content = <ErrorState errorMessage={getToastDetailsRequestErrorMessage} />;
  }

  return (
    <>
      <Cover title="Toast" />
      <ContentContainer>
        {isLoading ? (
          <LoadingState />
        ) : (
          <DashboardGrid>
            <Grid md={12}>{content}</Grid>
          </DashboardGrid>
        )}
      </ContentContainer>
    </>
  );
};

export default ToastPos;
