import Button from '@chownow/cocina-react-button';
import Footer from 'components/Footer';
import styled from 'styled-components';
import { headline, label } from 'styles/types';
import { screen, space } from 'styles/variables';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${space.x6});
  justify-content: center;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 598px;
  padding: ${space.x2};
  position: relative;
  text-align: center;
`;

export const Message = styled.div`
  font: ${headline.md.x24};
  margin: ${space.x7} 0;

  @media (max-width: ${screen.mdMax}) {
    font: ${label.md.x20};
    margin: ${space.x3} 0;
  }
`;

export const StyledButton = styled(Button)`
  margin: auto;
`;

export const StyledFooter = styled(Footer)`
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 936px;
  padding: ${space.x1};
  position: fixed;
  right: 0;
`;
