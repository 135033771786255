import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CATALOG_FILTERS } from 'helpers/constants';

import styles from './styles.module.scss';

export default function FilterItem(props) {
  const {
    CNFilter,
    itemCount,
    itemCountLinked,
    itemCountPartial,
    itemCountUnlinked,
    onFilter
  } = props;

  const dropdownFilter = Object.keys(CATALOG_FILTERS).map(key => {
    let catalogItemCount;
    if (CATALOG_FILTERS[key] === CATALOG_FILTERS.all) {
      catalogItemCount = itemCount;
    } else if (CATALOG_FILTERS[key] === CATALOG_FILTERS.linked) {
      catalogItemCount = itemCountLinked();
    } else if (CATALOG_FILTERS[key] === CATALOG_FILTERS.partial) {
      catalogItemCount = itemCountPartial();
    } else if (CATALOG_FILTERS[key] === CATALOG_FILTERS.unlinked) {
      catalogItemCount = itemCountUnlinked();
    }

    return (
      <div
        className={classNames(styles.filterItem, {
          [styles.activeFilter]: CATALOG_FILTERS[key] === CNFilter
        })}
        key={CATALOG_FILTERS[key]}
        onClick={() => {
          onFilter(CATALOG_FILTERS[key]);
        }}
        value={CATALOG_FILTERS[key]}
      >
        <div>{CATALOG_FILTERS[key]}</div>
        <div>{catalogItemCount}</div>
      </div>
    );
  });

  return dropdownFilter;
}

FilterItem.propTypes = {
  CNFilter: PropTypes.string,
  itemCount: PropTypes.number,
  itemCountLinked: PropTypes.func,
  itemCountPartial: PropTypes.func,
  itemCountUnlinked: PropTypes.func,
  onFilter: PropTypes.func
};
