import styled from 'styled-components';
import { space } from 'styles/variables';
import { ReactComponent as IconTime } from 'images/icon-time.svg';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import PaymentMethod from 'components/PaymentMethod';
import Button from '@chownow/cocina-react-button';

export const StatusIconWrapper = styled.div`
  margin-right: ${space.x1};
`;

export const StyledIconTime = styled(IconTime)`
  path {
    fill: ${semanticColors.content.success};
  }
`;

export const TextBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 ${space.x3} ${space.x2};
  transform: translateX(-${space.x3});
  width: 100%;

  &:last-child {
    margin-bottom: ${space.x3};
    padding: 0 ${space.x3} ${space.x3};
  }
`;

export const InstructionContainer = styled.div`
  & > div:last-child {
    border-bottom: 1px solid ${semanticColors.border.primary};
  }
`;

export const InstructionIcon = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${space.x2};
`;

export const Price = styled.span<{ $isReduction?: boolean }>`
  color: ${({ $isReduction }) => ($isReduction ? semanticColors.content.success : semanticColors.content.primary)};
  flex: 1;
  justify-content: flex-end;
  text-align: right;
`;

export const StyledHR = styled.hr`
  border: none;
  border-top: 1px solid ${semanticColors.border.primary};
  margin: ${space.x3} -${space.x3};
`;

export const StyledPaymentMethod = styled(PaymentMethod)`
  font: ${label.reg.x14};
  margin: ${space.x3} ${space.x1} ${space.x3} 0;
  padding: ${space.x3};

  p {
    margin: 0 0 0 ${space.x2};
  }
`;

export const FullOrderButton = styled(Button)`
  margin-top: ${space.x3};
`;

export const RefundNote = styled.div`
  color: ${semanticColors.content.primary};
  display: flex;
`;

export const Info = styled.div`
  flex: 1;
  font: ${label.reg.x12};
`;

export const Credits = styled.div`
  border-top: 1px solid ${semanticColors.border.primary};
  margin-top: ${space.x2};
`;
