// https://github.com/ChowNow/Hermosa/blob/main/data/response_json/tablet_order_detail_v5.json

import {
  HermosaBadRequestError,
  HermosaErrorResponses,
  isHermosaBadRequestError,
  isHermosaInternalServerError,
} from '../errors/hermosa';

export interface MarketplaceDinerFee {
  amount: number;
  description: string;
  disclaimer?: string;
  disclaimer_receipt?: string;
  label: string;
}

export interface MarketplaceDinerSavings {
  amount: number;
}

export enum RefundStatus {
  PENDING_AUTOMATIC_PROCESSING = 'Pending Automatic Processing',
  PENDING_CUSTOMER_SUPPORT = 'Pending Customer Support',
  AUTOMATICALLY_PROCESSED = 'Automatically Processed',
  PENDING_RESTAURANT_FEEDBACK = 'Pending Restaurant Feedback',
  PROCESSED_MANUALLY_BY_CS = 'Processed Manually By CS',
}

export enum RefundType {
  FULL = 'full',
  PARTIAL_BY_AMOUNT = 'partial_by_amount',
  PARTIAL_BY_ITEM = 'partial_by_item',
}

export interface RefundInfo {
  name: string | null;
  reason: string | null;
  refund_status?: string | null;
  refund_type?: string | null;
  refunded_at?: Timestamp | null;
}

export interface RefundEligibilityInfo {
  full: boolean;
  partial_by_item: boolean;
  partial_by_amount: boolean;
  reasons: string[] | null;
}

export interface RestaurantStatus {
  id?: string;
  code?: string;
  name?: string;
}

export type Country = 'USA' | 'CAN';

export type CountryAlpha2 = 'US' | 'CA';

export interface RestaurantAddress {
  address_string: string | null;
  city: string;
  country: Country;
  country_alpha2: CountryAlpha2;
  formatted_address: string | null;
  geo: string;
  id: string;
  is_google_place: boolean;
  latitude: number; // min -90, max 90
  longitude: number; // min -180, max 180
  name: string | null;
  place_formatted_address: string | null;
  state: string;
  street_address1: string;
  street_address2: string | null;
  zip: string;
}

export interface OrderRestaurant {
  id?: string;
  is_discover_live?: boolean;
  is_direct_live?: boolean;
  name?: string | null;
  short_name?: string | null;
  locale_name?: string;
  phone?: string | null;
  company_id?: string;
  status?: RestaurantStatus;
  address?: RestaurantAddress;
}

export interface BillingAddress {
  id?: string | null;
  address_string?: string | null;
  city?: string | null;
  country?: Country | '' | null;
  country_alpha2?: CountryAlpha2 | null;
  formatted_address?: string | null;
  geo?: string | null;
  is_google_place?: boolean;
  latitude?: number | null; // min -90, max 90
  longitude?: number | null; // min -180, max 180
  name?: string | null;
  place_formatted_address?: string | null;
  state?: string | null;
  street_address1?: string | null;
  street_address2?: string | null;
  zip: string;
}

export interface Card {
  id: string | null;
  cvv_valid: boolean;
  exp_month: number | null; // Integer
  exp_year: number | null; // Integer
  is_visible: boolean;
  last_four: string | null;
  name: string;
  type:
    | 'American Express'
    | 'Discover'
    | 'MasterCard'
    | 'Visa'
    | 'ApplePay'
    | 'AndroidPay'
    | 'ChowNow Apple Pay'
    | 'GooglePay'
    | 'JCB';
  type_id: string;
  is_last_submitted: boolean;
  address?: BillingAddress | null;
}

export interface CustomerBilling {
  id?: string | null;
  cardholder_name?: string | null;
  card_number?: string | null;
  card_type?:
    | 'American Express'
    | 'Discover'
    | 'MasterCard'
    | 'Visa'
    | 'ApplePay'
    | 'AndroidPay'
    | 'ChowNow Apple Pay'
    | 'GooglePay'
    | 'JCB';
  card_exp_date?: string | null;
  card?: Card;
  is_last_submitted?: boolean;
  address?: BillingAddress | null;
}

export interface DeliveryAddress {
  id?: string | null;
  street_address1: string;
  street_address2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: Country;
  is_google_place?: boolean | null;
  name?: string | null;
  latitude?: number | null; // min -90, max 90
  longitude?: number | null; // min -180, max 180
  geo?: string;
  place_formatted_address?: string | null;
  formatted_address?: string | null;
  country_alpha2?: CountryAlpha2;
  address_string?: string | null;
  is_last_submitted?: boolean;
}

export interface Delivery {
  address: DeliveryAddress;
}

export interface Vehicle {
  vehicle_type: string;
  color: string;
}

export interface CurbsideVariant1 {
  instructions: string;
  vehicle: null;
}

export interface CurbsideVariant2 {
  instructions: null;
  vehicle: Vehicle;
}

export interface OrderDineIn {
  table_name: string | null; // max length 32
}

export interface OrderCustomer {
  id?: string;
  first_name?: string | null;
  last_name?: string | null;
  is_optout?: boolean | null;
  email?: string | null;
  billing?: CustomerBilling | null;
  delivery?: Delivery | null;
  phone_number?: string | null; // min length 10, max length 10
  curbside?: CurbsideVariant1 | CurbsideVariant2 | null;
  dine_in?: OrderDineIn | null;
}

export interface ManagedDeliveryWithPickupAndDeliveryTimes
  extends ManagedDeliveryWithoutPickupAndDeliveryTimes {
  courier_name: string | null;
  actual_pickup_time: Timestamp | null;
  actual_delivery_time: Timestamp | null;
  estimated_pickup_time: Timestamp | null;
  estimated_delivery_time: Timestamp | null;
  last_updated_at: Timestamp | null;
}

export interface ManagedDeliveryWithoutPickupAndDeliveryTimes {
  id?: string;
  label?: string | null;
  message?: string | null;
  tracking_url?: string | null;
  status?: string | null;
}

export interface Marketplace {
  id?: '1' | '2' | '3';
  x_id?: string | null;
}

export interface Item {
  instructions: string | null; // max length 80
}

export interface SelectedNestedModifier {
  id: string;
  name: string;
  price: number | null;
  description: string | null;
  is_taxable: boolean;
  // This is "required" in the JSON schema but the
  // live response doesn't return this property as of right now
  modifier_categories?: SelectedNestedModifierCategory[];
}

export interface SelectedNestedModifierCategory {
  id: string;
  name: string;
  selections: SelectedNestedModifier[];
}

export interface ModifierCategory {
  id?: string;
  name?: string;
  selections?: SelectedNestedModifier[];
}

export interface OrderItemNestedModsV5 extends Item {
  id: string;
  name: string;
  size: string;
  price: number;
  quantity: number; // Integer
  per_item_total: number;
  item_sum_total: number;
  description: string | null;
  special_instructions: string | null; // max length 80
  modifier_categories: ModifierCategory[];
}

export interface TabletOrderItemV5 extends Item {
  id: string;
  name: string;
  size: string;
  price: number;
  quantity: number; // Integer
  tax_rate?: number | null;
  is_taxable?: boolean;
  per_item_total: number;
  item_sum_total: number;
  description: string | null;
  special_instructions: string | null; // max length 80
  modifier_categories: ModifierCategory[];
}

export interface Discount {
  id?: string;
  name?: string;
  description?: string | null;
  amount?: number;
  is_membership?: boolean;
  one_time_use?: boolean | null;
  promo_code?: string | null;
  type?: string;
  short_description?: string;
}

export interface MiscFee {
  label?: string;
  amount?: number | null;
}

export type Timestamp = string; // min length 12, max length 12

export interface FulfillmentTimes {
  estimated_pickup?: Timestamp | null;
  ordered?: Timestamp | null;
  estimated_arrival?: Timestamp | null;
  created_at?: Timestamp | null;
  original_estimated_arrival?: Timestamp | null;
  updated_at?: Timestamp | null;
  // This property isn't mentioned in the JSON Schema but comes through in live response
  // for some reason so I just added it here for now
  requested_fulfillment?: Timestamp | null;
}

export interface TipOption {
  rate?: number;
  amount?: number;
  is_current_tip?: boolean;
}

export interface Tip {
  description?: string;
  tip_options?: TipOption[];
}

export interface Adjustments {
  can_adjust_tip?: boolean;
  tip?: Tip | null;
}

export interface TipDetails {
  label: string;
  amount: number | null;
}

export interface TipOrderDetailsResponse {
  managed_delivery_tip: TipDetails;
  restaurant_tip: TipDetails;
  total: number;
}

export interface SingleUseItemsResponse {
  requested: boolean,
  label: string,
}

export interface OrderDetailsV6GetSuccessResponse {
  should_acknowledge?: boolean;
  grabbed_by?: string | null;
  accepted_at?: string | null;
  tax_rate?: number | null;
  product_source?: string | null;
  amount_for_transaction_fees?: number;
  refund_info?: RefundInfo | null;
  refund_eligibility?: RefundEligibilityInfo | null;
  id?: string;
  restaurant?: OrderRestaurant;
  fulfill_method?: 'curbside' | 'delivery' | 'dine_in' | 'pickup';
  special_instructions?: string | null; // Max length 80
  delivery_instructions?: string | null; // Max length 500
  is_post_pay?: boolean;
  is_order_ahead?: boolean;
  refund_amount?: number | null;
  customer?: OrderCustomer | null;
  managed_delivery?:
    | ManagedDeliveryWithPickupAndDeliveryTimes
    | ManagedDeliveryWithoutPickupAndDeliveryTimes
    | null;
  marketplace?: Marketplace | null;
  status?: string;
  items?: (OrderItemNestedModsV5 | TabletOrderItemV5)[];
  discounts?: Discount[];
  credits?: Discount[];
  sales_tax?: number | null;
  delivery_fee?: number | null;
  misc_fee: MiscFee | null;
  tips?: TipOrderDetailsResponse | null;
  single_use_items: SingleUseItemsResponse | null;
  subtotal?: number;
  total?: number;
  item_total: number;
  total_due: number;
  marketplace_diner_fee: MarketplaceDinerFee | null;
  marketplace_diner_savings: MarketplaceDinerSavings | null;
  fulfillment_times?: FulfillmentTimes | null;
  version?: string; // request method adds this version number to the response
  // For some reason these 4 properties aren't mentioned in the JSON Schema
  // but come through in live response so I just added them here for now
  tip_label?: string;
  item_count?: number;
  adjustments?: Adjustments;
  is_authenticated_order?: boolean;
}

export type OrderDetailsV5GetResponse =
  | OrderDetailsV6GetSuccessResponse
  | HermosaErrorResponses;

export const isOrderDetailsV6GetErrorResponse = (
  response: OrderDetailsV5GetResponse | undefined
): response is HermosaErrorResponses =>
  !!response && Object.prototype.hasOwnProperty.call(response, 'errors');

export const isOrderDetailsV6GetBadRequestError = (
  response: OrderDetailsV5GetResponse
): response is HermosaBadRequestError =>
  isOrderDetailsV6GetErrorResponse(response) &&
  isHermosaBadRequestError(response);

export const isOrderDetailsV6GetInternalServerError = (
  response: OrderDetailsV5GetResponse
): response is HermosaBadRequestError =>
  isOrderDetailsV6GetErrorResponse(response) &&
  isHermosaInternalServerError(response);

export const isOrderDetailsV6GetSuccessResponse = (
  response: OrderDetailsV5GetResponse | undefined
): response is OrderDetailsV6GetSuccessResponse =>
  !!response &&
  !isOrderDetailsV6GetErrorResponse(response) &&
  !!response.item_total &&
  !!response.total_due &&
  (!!response.marketplace_diner_fee ||
    response.marketplace_diner_fee === null) &&
  (!!response.marketplace_diner_savings ||
    response.marketplace_diner_savings === null);

export interface UpdateOrderResponse {
  status: string;
  version: string;
}
