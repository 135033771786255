export const ITEM_TYPE = {
  menuItem: 'Item',
  modifier: 'Modifier',
  size: 'Size',
};

export const PHOTO_TYPE = {
  logo: 'logo',
  cover: 'cover',
};

export const REFUND_TYPE = {
  partial_by_item: 'Refund by Item',
  partial_by_amount: 'Refund by Amount',
  full: 'Full Refund',
};

export const REFUND_STATUS = {
  pendingAutomaticProcessing: 'Pending Automatic Processing',
  pendingCustomerSupport: 'Pending Customer Support',
  pendingRestaurantFeedback: 'Pending Restaurant Feedback',
  automaticallyProcessed: 'Automatically Processed',
  processedManuallyByCs: 'Processed Manually By CS',
};

export const CATALOG_FILTERS = {
  all: 'All Items',
  linked: 'Linked',
  partial: 'Partially Linked',
  unlinked: 'Unlinked',
};

export const TIMEOUTS = {
  closeSideBar: 300,
};

export const EDIT_TYPE = {
  items: 'Items',
  modifiers: 'Modifiers',
};

export const CROP_VALIDATION_OPTIONS = {
  item: {
    minWidth: 1200,
    minHeight: 1200,
  },
  logo: {
    minWidth: 60,
    minHeight: 60,
  },
  cover: {
    minWidth: 1200,
    minHeight: 675,
  },
};

export const ORDER_STATUS = {
  new: 'New',
  accepted: 'Accepted',
  canceled: 'Canceled',
  future: 'Future',
  submitted: 'Submitted',
};

export const ORDER_ACTION = {
  accept: 'accept',
  cancel: 'cancel',
};

export const FULFILLMENT_METHODS = {
  curbside: 'curbside',
  delivery: 'delivery',
  dineIn: 'dine_in',
  pickup: 'pickup',
};

export const ONB_WIZARD_INTRO = {
  shareMenuTitle: 'Share Your Menu',
  shareMenuDesc:
    "Once we have your menu, we can start adding it to your ChowNow dashboard. Our menu team will do that for you and have it ready to review in 1-2 days. You'll be able to add menu photos and make edits once your menu is on your dashboard.",
  salesTaxTitle: 'Sales Tax Rate',
  salesTaxDesc:
    "We'll use this to process the sales tax on your customers' orders.",
  restaurantHoursTitle: 'Restaurant Hours',
  restaurantHoursDesc:
    'We just need to know the pickup and/or delivery hours for your location. You can make updates to your hours in your dashboard later.',
  websiteAccessTitle: 'Website Access',
  websiteAccessDesc:
    'The ChowNow ordering link needs to be added to your website. We can help to do that or provide the unique coding instructions for you or your web developer to implement.',
  logoAndFoodImageTitle: 'Logo and Image',
  logoAndFoodImageDesc:
    'Your restaurant logo and food image will be displayed on marketing materials.',
  promotionTitle: 'Promotion',
  promotionDesc: "We'll use this to set up your first time customer promo.",
  paymentProcessingTitle: 'Payment Processing',
  paymentProcessingDesc:
    'We have a partnership with Stripe, a secure payment processor. The button below will link out to submit your details and activate your account within their system, which is required to accept and process orders on ChowNow.',
  missingDetailsTitle: "We're missing some details",
  missingDetailsDesc:
    "We're missing some information from you that's required to launch on ChowNow. Please complete these details now if you can.",
};

export const ERROR_CODES = {
  invalidLogin: '20099',
  invalidVerificationCode: '20104',
  refundType: '53001',
  requiredFieldMissing: '10101',
};

export const JOSIAHS_BAD_PASSWORDS = [
  'trustno1',
  'passw0rd',
  'p@ssword',
  'p@ssw0rd',
  'pa55word',
  '1q2w3e4r',
  '1q2w3e4r5t',
  'pa55w0rd',
  'p@55w0rd',
];

export const CUBOH_BASE_URL: string =
  process.env.REACT_APP_DEPLOY_ENV === 'prod'
    ? 'https://app.chownow.com'
    : `https://cuboh.${process.env.REACT_APP_DEPLOY_ENV}.svpn.chownow.com`;

export const CUBOH_MENU_URL = `${CUBOH_BASE_URL}/menu/management`;
export const CUBOH_ANALYTICS_URL = `${CUBOH_BASE_URL}/analytics`;

export const LD_PRIVATE_ATTRIBUTES = ['email'];
export const USER_GROUP_ID = {
  company_admin: '2',
};

export const THIRD_PARTY_MENU_SOURCE = {
  cuboh: 'CUBOH',
  toast: 'TOAST',
};
