import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import WelcomeWizard from './WelcomeWizard';
import ShareMenu from './ShareMenu';
import SalesTax from './SalesTax';
import RestaurantHours from './RestaurantHours';
import PaymentProcessing from './PaymentProcessing';
import WebsiteAccess from './WebsiteAccess';
import WizardNavigationContextProvider from './WizardNavigationContext/WizardNavigationContextProvider';
import Promotion from './Promotion';
import LogoAndFoodImage from './LogoAndFoodImage';

const OnboardingWizard = ({ restaurant }) => {
  const navigate = useNavigate();
  const restaurantUuid = restaurant?.sfdc_restaurant_uuid;

  useEffect(() => {
    // If restaurant does not have a UUID, redirect them to the orders page
    if (restaurantUuid === null) {
      navigate(`/restaurant/${restaurant?.id}/orders`);
    }
  }, []);

  return (
    <WizardNavigationContextProvider uuid={restaurantUuid}>
      <Routes>
        <Route
          path="/welcome"
          element={<WelcomeWizard restaurant={restaurant} />}
        />
        <Route
          path="/"
          element={
            <Navigate
              to={`/restaurant/${restaurant.id}/onboarding/welcome`}
              replace
            />
          }
        />
        <Route
          path="/share-menu"
          element={<ShareMenu restaurant={restaurant} />}
        />
        <Route
          path="/sales-tax"
          element={<SalesTax restaurant={restaurant} />}
        />
        <Route
          path="/restaurant-hours"
          element={<RestaurantHours uuid={restaurantUuid} />}
        />
        <Route
          path="/website-access"
          element={<WebsiteAccess restaurant={restaurant} />}
        />
        <Route
          path="/logo-and-food-image"
          element={<LogoAndFoodImage uuid={restaurantUuid} />}
        />
        <Route
          path="/promotion"
          element={<Promotion restaurantUuid={restaurantUuid} />}
        />
        <Route
          path="/payment-processing"
          element={<PaymentProcessing restaurant={restaurant} />}
        />
      </Routes>
    </WizardNavigationContextProvider>
  );
};

export default OnboardingWizard;
