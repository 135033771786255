import React, { FC } from 'react';

import { PageIntroWrapper } from './styles';

interface PageIntroProps {
  pictogram: string;
  pictogramLabel: string;
  title: string;
  description: string;
}

const PageIntro: FC<PageIntroProps> = ({ pictogram, pictogramLabel, title, description }) => (
  <PageIntroWrapper data-testid="page-intro">
    <img src={pictogram} alt={pictogramLabel} />
    <h1>{title}</h1>
    <p>{description}</p>
  </PageIntroWrapper>
);

export default PageIntro;
