import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'helpers/breakpoints';
import Sticky from 'react-sticky-el';

import MenuSearchBar from 'components/MenuList/MenuSearchBar';

import { ReactComponent as IconPin } from 'images/icon-pin.svg';
import { ReactComponent as IconGlobe } from 'images/icon-globe.svg';
import styles from './styles.module.scss';

const LocationPicker = props => {
  const { modalProps, className } = props;
  const { handleOnClick, locations, restaurantId, title, showAllLocations } =
    modalProps;
  const [filteredList, setFilteredList] = useState(locations);
  const [searchQuery, setSearchQuery] = useState();
  const [parentRef, setParentRef] = useState();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });

  useEffect(() => {
    // We need the modal wrapper parent in order to trigger the sticky searchbar
    if (className) {
      const wrapperElement = document.getElementsByClassName(className)[0];
      setParentRef(wrapperElement.parentElement);
    }
  }, [className]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredList(
        locations.reduce((locationArray, location) => {
          const includesName = location.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const includesAddress = location.address.street_address1
            .toLowerCase()
            .includes(searchQuery.toLowerCase());

          if (includesName || includesAddress) {
            locationArray.push(location);
          }
          return locationArray;
        }, [])
      );
    } else {
      setFilteredList(locations);
    }
  }, [searchQuery]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>{title}</div>
      {locations.length > 5 && (
        <Sticky
          scrollElement={isDesktop ? `.${className}` : parentRef}
          stickyStyle={{ top: '0' }}
        >
          <div className={styles.menuSearchBarWrap}>
            <MenuSearchBar
              setSearchExpanded={() => {}}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              setActiveCategory={() => {}}
            />
          </div>
        </Sticky>
      )}
      <ul className={styles.list}>
        {showAllLocations && (
          <li
            className={classNames(styles.location, {
              [styles.active]: !restaurantId,
            })}
            onClick={() => handleOnClick()}
          >
            <IconGlobe className={styles.icon} />
            <span className={styles.name}>Headquarters</span>
          </li>
        )}
        <div className={styles.flexList}>
          {filteredList.map(location => {
            return (
              <li
                className={classNames(styles.location, {
                  [styles.active]: restaurantId === location.id,
                })}
                key={location.id}
                onClick={() => handleOnClick(location.id)}
              >
                <IconPin className={styles.icon} />
                <span className={styles.name}>{location.name}</span>
                <span className={styles.address}>
                  {location.address.street_address1}
                </span>
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
};

LocationPicker.propTypes = {
  modalProps: PropTypes.shape({
    companyId: PropTypes.string,
    handleOnClick: PropTypes.func,
    id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.object),
    restaurantId: PropTypes.string,
    showAllLocations: PropTypes.bool,
    title: PropTypes.string,
  }),
};

export default LocationPicker;
