import { FULFILLMENT_METHODS } from 'helpers/constants';
import { capitalize, get, isEmpty, pick } from 'lodash';
import { addMinutes, format } from 'date-fns';

export function getIsClosed(restaurant) {
  const {
    isPickupAvailableNow,
    isDeliveryAvailableNow,
    isPickupAheadAvailable,
    isDeliveryAheadAvailable,
  } = getFulfillmentAvailability(restaurant);

  return (
    !isPickupAvailableNow &&
    !isDeliveryAvailableNow &&
    !isPickupAheadAvailable &&
    !isDeliveryAheadAvailable
  );
}

export function getNameFromLocations(restaurantId, company) {
  if (restaurantId && company.locations) {
    const location = company.locations.find(
      location => location.id === restaurantId
    );
    return location && location.name;
  }
  return '';
}

export function getNextAvailableTime(restaurant, orderFulfillMethod) {
  const { isDeliveryOnly } = getFulfillmentAvailability(restaurant);
  const orderAhead = getOrderAhead(restaurant);

  if (getIsClosed(restaurant)) return {};

  let fulfillMethod;
  let nextAvailableTime;

  if (orderFulfillMethod) {
    fulfillMethod = orderFulfillMethod;
  } else if (isDeliveryOnly) {
    fulfillMethod = FULFILLMENT_METHODS.delivery; // Should default to pickup unless HQ is delivery only.
  } else {
    fulfillMethod = FULFILLMENT_METHODS.pickup;
  }

  if (
    get(
      getFulfillmentAvailability(restaurant),
      `is${capitalize(fulfillMethod)}AvailableNow`
    )
  ) {
    nextAvailableTime = null;
  } else if (orderAhead) {
    const { days, precision } = orderAhead;
    let firstAvailableBit = null;
    let date = null;

    for (let i = 0; i < days.length; i++) {
      const time = get(days[i], `${fulfillMethod}_index_ranges[0]`);
      if (time) {
        firstAvailableBit = time.from;
        date = days[i].date;
        break;
      }
    }

    nextAvailableTime = format(
      new Date(addMinutes(new Date(date), firstAvailableBit * precision)),
      'yyyyMMddHHmm'
    );
  }

  return nextAvailableTime;
}

export function getFulfillmentForMethod(restaurant, fulfillmentMethod) {
  return get(restaurant, `fulfillment.${fulfillmentMethod}`) || {};
}

export function getIsDeliveryOnly(restaurant) {
  return get(restaurant, 'delivery_only');
}

export function getIsPickupOnly(restaurant) {
  // If fulfillment.delivery object is empty, then is pickup only
  return isEmpty(
    getFulfillmentForMethod(restaurant, FULFILLMENT_METHODS.delivery)
  );
}

export function getOrderAhead(restaurant) {
  return get(restaurant, 'order_ahead');
}

export function getFulfillmentAvailability(restaurant) {
  const allowsOrderForNow = get(restaurant, 'allows_order_for_now');
  const isPickupOnly = getIsPickupOnly(restaurant);
  const isDeliveryOnly = getIsDeliveryOnly(restaurant);

  return {
    isPickupAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(restaurant, FULFILLMENT_METHODS.pickup),
        'is_available_now',
        false
      ),
    isCurbsideAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(restaurant, FULFILLMENT_METHODS.curbside),
        'is_available_now',
        false
      ),
    isDeliveryAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(restaurant, FULFILLMENT_METHODS.delivery),
        'is_available_now',
        false
      ),
    isDineInAvailableNow:
      allowsOrderForNow &&
      get(
        getFulfillmentForMethod(restaurant, FULFILLMENT_METHODS.dineIn),
        'is_available_now',
        false
      ),
    isPickupAheadAvailable: get(
      getOrderAhead(restaurant),
      'is_pickup_ahead_available',
      false
    ),
    isCurbsideAheadAvailable: get(
      getOrderAhead(restaurant),
      'is_curbside_ahead_available',
      false
    ),
    isDeliveryAheadAvailable: get(
      getOrderAhead(restaurant),
      'is_delivery_ahead_available',
      false
    ),
    isDineInAheadAvailable: get(
      getOrderAhead(restaurant),
      'is_dine_in_ahead_available',
      false
    ),
    allowsAllFulfillmentMethods: !isPickupOnly && !isDeliveryOnly,
    allowsOrderForNow,
    isAsapOnly: allowsOrderForNow && !getOrderAhead(restaurant),
    isDeliveryOnly,
    isPickupOnly,
    isOrderAheadOnly: !allowsOrderForNow,
  };
}

/**
 * Update catalog item with new image
 * @param {string} categories - menu categories
 * @param {string} itemID - menu utem ID
 * @param {object} imageData - s3 image data
 * @param {string} baseUrl - image CDN url
 * @param {object} fileKey - s3 file path
 */
export function updateMenuItemImage(categories, itemId, imageData) {
  // update local catalog with new image url and crop settings
  return categories.map(category => {
    return {
      ...category,
      items: category.items.map(item => {
        if (item.id === itemId || item.meta_id === itemId) {
          return {
            ...item,
            image: {
              ...pick(imageData, [
                'cropped_url',
                'crop_height',
                'crop_width',
                'crop_x',
                'crop_y',
                'height',
                'width',
                'base_url',
                'file_key',
                'filename',
                'user_filename',
              ]),
            },
          };
        } else {
          return item;
        }
      }),
    };
  });
}

/**
 * Update image from catalog item
 * @param {string} categories - menu categories
 * @param {string} itemID - menu utem ID
 */
export function removeMenuItemImage(categories, itemId) {
  return categories.map(category => {
    return {
      ...category,
      items: category.items.map(item => {
        if (item.id === itemId || item.meta_id === itemId) {
          return {
            ...item,
            image: null,
          };
        } else {
          return item;
        }
      }),
    };
  });
}
