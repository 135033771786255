/**
 * Created this file for us to start adding TS API functions to for Onboarding APIs.
 * Will eventually want to convert all the functions in onboarding.js and delete that
 * file and rename this one to onboarding.ts
 */
import { useEffect, useState } from 'react';
import { formatError, requestCustomApi } from 'helpers/api';
import { ScheduleResponse } from 'types/restaurant-hours';
import {
  MenuAssetsResponse,
  MenuAssetsUploadFields,
  MenuAssetsUploadUrlResponse,
  MenuLinksResponse,
} from 'types/share-menu';
import {
  FileAssetsUploadFields,
  FileAssetsUploadUrlResponse,
  FileUploadOptions,
} from 'types/asset-upload';

const MAX_POLLING_ATTEMPTS = 20;
const ASSET_UPLOAD_DELAY = 1000;
const ERROR_MESSAGE =
  'There was an issue submitting your menu. Please try again.';
const STATUS_SUCCESS = 'uploaded';

const onboardingApiEnv = process.env.REACT_APP_ONBOARDING_API_HOST ?? '';

/**
 * Restaurant Hours - Onboarding Wizard GET Schedule API
 * @param  {string | null} uuid - ChowNow Restaurant UUID
 */
export const useGetRestaurantHours = (uuid: string | null) => {
  const [isGetRequestLoading, setIsGetRequestLoading] = useState<boolean>(true);
  const [hasGetRequestError, setHasGetRequestError] = useState<boolean>(false);
  const [getRequestResponse, setGetRequestResponse] =
    useState<ScheduleResponse>();

  useEffect(() => {
    const getRestaurantHours = async () => {
      try {
        const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: ScheduleResponse = await requestCustomApi(
          `v1/restaurant/${uuid}/schedule`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        setGetRequestResponse(response);
        setIsGetRequestLoading(false);
      } catch (err) {
        setIsGetRequestLoading(false);
        setHasGetRequestError(true);
      }
    };

    if (uuid) {
      getRestaurantHours().catch(() => {
        /* do nothing */
      });
    }
  }, []);

  return { hasGetRequestError, isGetRequestLoading, getRequestResponse };
};

/**
 * Restaurant Hours - Onboarding Wizard GET Schedule API
 */
export const usePostRestaurantHours = () => {
  const [isPostRequestLoading, setIsPostRequestLoading] =
    useState<boolean>(false);

  const postRestaurantHours = async (
    uuid: string | null,
    submittedHours: ScheduleResponse
  ) => {
    let hasPostRequestError = false;
    const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

    setIsPostRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/schedule`,
        env,
        'POST',
        submittedHours,
        // TODO: remove these once requestCustomApi is converted over to
        // TS with optional params in CN-27549
        undefined,
        undefined
      );

      setIsPostRequestLoading(false);
    } catch (err) {
      hasPostRequestError = true;

      setIsPostRequestLoading(false);
    }

    return hasPostRequestError;
  };

  return { isPostRequestLoading, postRestaurantHours };
};

/**
 * Share Your Menu - Onboarding Wizard GET uploaded menu asset url and optional notes
 */
export const useGetMenuAssets = () => {
  const [isGetRequestMenuAssetsLoading, setIsGetRequestMenuAssetsLoading] =
    useState<boolean>(false);
  const [hasGetRequestMenuAssetsError, setHasGetRequestMenuAssetsError] =
    useState<boolean>(false);
  const [getRequestMenuAssetsResponse, setGetRequestMenuAssetsResponse] =
    useState<MenuAssetsResponse>();

  const getMenuAssets = async (uuid: string | null) => {
    let response: MenuAssetsResponse;

    if (uuid) {
      setIsGetRequestMenuAssetsLoading(true);

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await requestCustomApi(
          `v1/restaurant/${uuid}/assets-upload-url`,
          onboardingApiEnv,
          'GET',
          // TODO: remove these once requestCustomApi is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined
        );

        setGetRequestMenuAssetsResponse(response);
      } catch (err) {
        setHasGetRequestMenuAssetsError(true);
      } finally {
        setIsGetRequestMenuAssetsLoading(false);
      }
    }
  };

  return {
    isGetRequestMenuAssetsLoading,
    hasGetRequestMenuAssetsError,
    getRequestMenuAssetsResponse,
    getMenuAssets,
  };
};

/**
 * Share Your Menu - Onboarding Wizard GET uploaded menu link and optional notes
 */
export const useGetMenuLinks = () => {
  const [isGetRequestMenuLinksLoading, setIsGetRequestMenuLinksLoading] =
    useState(true);
  const [hasGetRequestMenuLinksError, setHasGetRequestMenuLinksError] =
    useState(false);
  const [getRequestMenuLinksResponse, setGetRequestMenuLinksResponse] =
    useState<MenuLinksResponse>();

  const getMenuLinks = async (uuid: string | null) => {
    let response: MenuLinksResponse;

    if (uuid) {
      setIsGetRequestMenuLinksLoading(true);

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await requestCustomApi(
          `v1/restaurant/${uuid}/menu-link`,
          onboardingApiEnv,
          'GET',
          // TODO: remove these once requestCustomApi is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined
        );

        setGetRequestMenuLinksResponse(response);
      } catch (err) {
        setHasGetRequestMenuLinksError(true);
      } finally {
        setIsGetRequestMenuLinksLoading(false);
      }
    }
  };

  return {
    isGetRequestMenuLinksLoading,
    hasGetRequestMenuLinksError,
    getRequestMenuLinksResponse,
    getMenuLinks,
  };
};

/**
 * Make a request with XMLHttpRequest to POST data to S3
 */
export const postS3 = async (
  url: string,
  method: string,
  body?: Document | XMLHttpRequestBodyInit | null
): Promise<Record<string, never>> =>
  new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url);

    // request finished event
    xhr.addEventListener('load', () => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      resolve(xhr.response.body || {});
    });

    // send POST request to server
    xhr.send(body);
  });

/**
 * Share Your Menu - Onboarding Wizard fetch presigned url for S3 and post image
 * @param uuid - ChowNow Restaurant UUID
 * @param menuFile - Restaurant menu file a user has uploaded.
 * @param menuNotes - Restaurant menu optional notes { example: Choice of sauce for any dish }
 */
export const usePostMenuAsset = () => {
  const [isPostMenuAssetLoading, setIsPostMenuAssetLoading] =
    useState<boolean>(false);
  const [hasPostMenuAssetError, setHasPostMenuAssetError] =
    useState<boolean>(false);
  const [postMenuAssetSuccess, setPostMenuAssetSuccess] =
    useState<boolean>(false);
  const [postMenuAssetError, setPostMenuAssetError] = useState<string>('');
  const [fileId, setFileId] = useState<string>('');

  const postMenuAsset = async (
    uuid: string | null,
    menuUpload: string | File,
    menuNotes: string
  ) => {
    let presignedUrlResponse: MenuAssetsUploadUrlResponse;

    const requestBody = {
      filename: menuUpload instanceof File ? menuUpload.name : menuUpload,
      notes: menuNotes,
    };

    setIsPostMenuAssetLoading(true);

    try {
      // Fetch presigned url for S3
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      presignedUrlResponse = await requestCustomApi(
        `v1/restaurant/${uuid}/assets-upload-url`,
        onboardingApiEnv,
        'POST',
        requestBody,
        // TODO: remove these once requestCustomApi is converted over to
        // TS with optional params in CN-27549
        undefined,
        undefined
      );

      const responseFields: MenuAssetsUploadFields =
        presignedUrlResponse?.fields;
      // logs file id to use when menu upload wait modal is looking for file id
      setFileId(presignedUrlResponse.file_id);

      // Create form-data for post body
      const s3FormData = new FormData();
      Object.keys(responseFields).forEach(key =>
        s3FormData.append(
          key,
          responseFields[key as keyof MenuAssetsUploadFields]
        )
      );

      // add image file to form
      const imageFile = new File(
        [menuUpload],
        menuUpload instanceof File ? menuUpload.name : menuUpload
      );
      s3FormData.append('file', imageFile);

      // post image to S3 - we expect a 204 with no content (empty object)
      // to be returned on success
      await postS3(
        // get S3 url from onboarding service response
        presignedUrlResponse.url,
        'POST',
        s3FormData
      );

      setPostMenuAssetSuccess(true);
      setIsPostMenuAssetLoading(false);
    } catch (err) {
      setPostMenuAssetSuccess(false);
      setIsPostMenuAssetLoading(false);
      setHasPostMenuAssetError(true);
      setPostMenuAssetError(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (formatError(err)?.errors?.[0]?.message?.message ??
          String(err)) as string
      );
    }
  };

  return {
    fileId,
    hasPostMenuAssetError,
    isPostMenuAssetLoading,
    postMenuAsset,
    postMenuAssetSuccess,
    postMenuAssetError,
  };
};

/**
 * Share Your Menu - Onboarding Wizard add menu link and optional notes
 * @param uuid - ChowNow Restaurant UUID
 * @param menuLink - Restaurant menu link, {example: https://alousycompetitor.com/menu/123}
 * @param menuNotes - Restaurant menu optional notes { example: Choice of sauce for any dish }
 */
export const usePostMenuLink = () => {
  const [isPostMenuLinkLoading, setIsPostMenuLinkLoading] =
    useState<boolean>(false);
  const [hasPostMenuLinkError, setHasPostMenuLinkError] =
    useState<boolean>(false);
  const [postMenuLinkSuccess, setPostMenuLinkSuccess] =
    useState<boolean>(false);
  const [postMenuLinkError, setPostMenuLinkError] = useState<string>('');

  const postMenuLink = async (
    uuid: string | null,
    menuLink: string | File,
    menuNotes: string
  ) => {
    const requestBody = {
      url: menuLink,
      notes: menuNotes,
    };

    setIsPostMenuLinkLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/menu-link`,
        onboardingApiEnv,
        'POST',
        requestBody,
        // TODO: remove these once requestCustomApi is converted over to
        // TS with optional params in CN-27549
        undefined,
        undefined
      );

      setPostMenuLinkSuccess(true);
    } catch (err) {
      setIsPostMenuLinkLoading(false);
      setPostMenuLinkSuccess(false);
      setHasPostMenuLinkError(true);
      setPostMenuLinkError(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (formatError(err)?.errors?.[0]?.message?.message ??
          String(err)) as string
      );
    }
  };

  return {
    hasPostMenuLinkError,
    isPostMenuLinkLoading,
    postMenuLink,
    postMenuLinkSuccess,
    postMenuLinkError,
  };
};

/**
 * Onboarding Service File Upload
 * @param uuid - ChowNow Restaurant UUID.
 * @param file - Restaurant file a user has uploaded.
 * @param options - Restaurant file data based on asset type.
 */
export const usePostFileAsset = () => {
  const [isPostFileAssetLoading, setIsPostFileAssetLoading] =
    useState<boolean>(false);

  const postFileAsset = async (
    uuid: string | null,
    file: File,
    options: FileUploadOptions
  ) => {
    let presignedUrlResponse: FileAssetsUploadUrlResponse;

    setIsPostFileAssetLoading(true);
    let hasError = false;

    try {
      const { assetType, ...body } = options;
      // Fetch presigned url for S3
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      presignedUrlResponse = await requestCustomApi(
        `v1/restaurant/${uuid}/asset-upload/${assetType}`,
        onboardingApiEnv,
        'POST',
        body,
        // TODO: remove these once requestCustomApi is converted over to
        // TS with optional params in CN-27549
        undefined,
        undefined
      );

      const { file_id, fields } = presignedUrlResponse;

      // Create form-data for post body
      const s3FormData = new FormData();
      Object.keys(fields).forEach(key =>
        s3FormData.append(key, fields[key as keyof FileAssetsUploadFields])
      );

      // add file to form
      s3FormData.append('file', file);

      // post image to S3 - we expect a 204 with no content (empty object)
      // to be returned on success
      await postS3(
        // get S3 url from onboarding service response
        presignedUrlResponse.url,
        'POST',
        s3FormData
      );

      let fetchAssetCount = 0;
      let assetLoaded = false;

      // Poll to check if asset has been uploaded successfully
      while (fetchAssetCount < MAX_POLLING_ATTEMPTS && !assetLoaded) {
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise(resolve => setTimeout(resolve, ASSET_UPLOAD_DELAY));

        // eslint-disable-next-line no-await-in-loop
        const getAssetResponse = (await requestCustomApi(
          `v1/restaurant/${uuid}/asset-upload/${assetType}`,
          onboardingApiEnv,
          'GET',
          undefined,
          // TODO: remove these once requestCustomApi is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined
        )) as MenuAssetsResponse;

        // asset file_id must match recently uploaded file_id and have a upload_status of uploaded
        assetLoaded = !!getAssetResponse?.find(
          asset =>
            asset.file_id === file_id && asset.upload_status === STATUS_SUCCESS
        );

        fetchAssetCount += 1;
      }

      if (!assetLoaded) {
        throw new Error(ERROR_MESSAGE);
      }
    } catch (err) {
      hasError = true;
    } finally {
      setIsPostFileAssetLoading(false);
    }

    return hasError;
  };

  return {
    isPostFileAssetLoading,
    postFileAsset,
  };
};
