import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const { bool, number, string } = PropTypes;
const LOADER_DELAY = 100;

class LoadingSpinner extends Component {
  static propTypes = {
    isAbsolute: bool,
    isDelayed: bool,
    isFixed: bool,
    isLarge: bool,
    isSmall: bool,
    isTransparent: bool,
    secondary: bool,
    spinnerClassName: string,
    withWrapper: bool,
    zIndex: number,
  };

  static defaultProps = {
    isAbsolute: true,
    isDelayed: true,
    isLarge: true,
    withWrapper: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayLoader: !this.props.isDelayed,
    };

    this.timer = setTimeout(() => {
      this.setState({ displayLoader: true });
      this.timer = null;
    }, LOADER_DELAY);
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  render() {
    const Spinner = () => (
      <div
        className={classNames(styles.spinner, this.props.spinnerClassName, {
          [styles.large]: this.props.isLarge,
          [styles.small]: this.props.isSmall,
          [styles.absolute]: this.props.isAbsolute,
          [styles.secondary]: this.props.secondary,
        })}
        data-testid="loading-spinner"
        style={{ zIndex: this.props.zIndex }}
      />
    );

    if (!this.state.displayLoader) {
      return null;
    }

    return this.props.withWrapper ? (
      <div
        className={classNames(styles.overlay, {
          [styles.isTransparent]: this.props.isTransparent,
          [styles.isFixed]: this.props.isFixed,
        })}
        style={{ zIndex: this.props.zIndex }}
      >
        <Spinner />
      </div>
    ) : (
      <Spinner />
    );
  }
}

export default LoadingSpinner;
