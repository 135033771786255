import React, { FC, useEffect, useState } from 'react';
import { useGetStripeStatus } from 'api/stripe';
import { useGetProgress, usePostProgress } from 'api/onboarding/progressApis';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import { getSearchParam } from 'helpers/url';
import pictogram from 'images/pictograms/picto-payment-lg.svg';
import {
  OnboardingWizardPages,
  OnboardingProgressStatus,
} from 'types/progress';
import Restaurant from 'types/restaurant';

import PageFooterPaymentProcessing from 'containers/Onboarding/PageFooter/PageFooterPaymentProcessing';
import ErrorCard from '../ErrorCard';
import LoadingState from '../LoadingState';
import PageIntro from '../PageIntro';
import ActivateAccountCard from './ActivateAccountCard';
import ActivationErrorCard from './ActivationErrorCard';
import NoAccountCard from './NoAccountCard';
import ReviewAccountCard from './ReviewAccountCard';
import VerifiedAccountCard from './VerifiedAccountCard';
import { useWizardNavigationContext } from '../WizardNavigationContext';

import ErrorBanner from './styles';

const ERROR_MESSAGE =
  "There was an issue activating your Stripe account. You'll need to go back to Stripe and update your information. If the issue persists, contact your account coordinator for assistance.";
const URL_PATH = 'onboarding/payment-processing';

interface PaymentProcessingProps {
  restaurant: Restaurant;
}

const PaymentProcessing: FC<PaymentProcessingProps> = ({ restaurant }) => {
  const statusSearchParam = getSearchParam('status');
  const {
    getStripeStatusRequestResponse,
    isGetStripeStatusRequestLoading,
    hasGetStripeStatusRequestError,
  } = useGetStripeStatus(restaurant?.id, statusSearchParam);
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();
  const {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
  } = useGetProgress(restaurant?.sfdc_restaurant_uuid);
  const { isPostProgressRequestLoading, postOnboardingProgress } =
    usePostProgress();
  const [isErrorBannerDisplayed, setIsErrorBannerDisplayed] = useState(false);
  const [hasPostProgressRequestError, setHasPostProgressRequestError] =
    useState(false);
  const [isFormCompleted, setIsFormCompleted] = useState(false);

  let content;

  useEffect(() => {
    void (async () => {
      if (
        !isGetStripeStatusRequestLoading &&
        !isGetProgressRequestLoading &&
        !isWizNavProgressRequestLoading
      ) {
        if (statusSearchParam === 'failure') {
          setIsErrorBannerDisplayed(true);
        } else if (getStripeStatusRequestResponse?.x_status === 'verified') {
          setIsFormCompleted(true);

          if (
            getProgressRequestResponse?.stripe_verification?.status !==
            'completed'
          ) {
            const hasError = await postOnboardingProgress(
              restaurant?.sfdc_restaurant_uuid,
              OnboardingWizardPages.STRIPE_VERIFICATION,
              OnboardingProgressStatus.COMPLETED
            );

            setHasPostProgressRequestError(hasError);
          }
        }
      }
    })();
  }, [
    isGetStripeStatusRequestLoading,
    isGetProgressRequestLoading,
    isWizNavProgressRequestLoading,
  ]);

  const hasRequestError =
    hasGetStripeStatusRequestError ||
    hasGetProgressRequestError ||
    hasPostProgressRequestError ||
    hasWizNavProgressRequestError;
  const isRequestLoading =
    isGetStripeStatusRequestLoading ||
    isGetProgressRequestLoading ||
    isPostProgressRequestLoading ||
    isWizNavProgressRequestLoading;
  const hasSubmittedToStripe =
    getProgressRequestResponse?.stripe_verification?.status === 'completed';

  if (hasRequestError) {
    content = <ErrorCard />;
  } else if (isRequestLoading) {
    content = <LoadingState />;
  } else if (statusSearchParam === 'success') {
    content = <ReviewAccountCard restaurant={restaurant} />;
  } else if (statusSearchParam === 'failure') {
    content = (
      <ActivationErrorCard restaurant={restaurant} urlPath={URL_PATH} />
    );
  } else if (getStripeStatusRequestResponse?.errors) {
    content = <NoAccountCard />;
  } else {
    switch (getStripeStatusRequestResponse?.x_status) {
      case 'verified':
        content = <VerifiedAccountCard />;
        break;
      case 'unknown':
        content = <ErrorCard />;
        break;
      default:
        if (hasSubmittedToStripe) {
          content = <ReviewAccountCard restaurant={restaurant} />;
        } else {
          content = (
            <ActivateAccountCard restaurant={restaurant} urlPath={URL_PATH} />
          );
        }
        break;
    }
  }

  return (
    <>
      {isErrorBannerDisplayed && (
        <ErrorBanner variant="caution">{ERROR_MESSAGE}</ErrorBanner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Clipboard"
          title={ONB_WIZARD_INTRO.paymentProcessingTitle}
          description={ONB_WIZARD_INTRO.paymentProcessingDesc}
        />
        {content}
        {!isRequestLoading && !hasWizNavProgressRequestError && (
          <PageFooterPaymentProcessing isFormCompleted={isFormCompleted} />
        )}
      </OnboardingContainer>
    </>
  );
};

export default PaymentProcessing;
