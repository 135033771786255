import React, { FC } from 'react';
import { Tag } from '@chownow/cn-web-components';
import { IconBadgeInfo } from 'images/IconBadgeInfo';
import { IconTablet } from 'images/IconTablet';
import TabletPopoverContent from '../TabletPopoverContent';

import { StyledTabletPopoverContent, StyledPopover, TagContainer } from './styles';

interface StatusContainerProps {
  isAutoAcceptEnabled?: boolean;
  isTablet?: boolean;
  isTabletOn: boolean;
  middlewareOrPosName?: Array<string>;
  tabletLastSeen: string;
  tabletVersion: string;
}

const StatusContainer: FC<StatusContainerProps> = ({
  isAutoAcceptEnabled,
  isTablet,
  isTabletOn,
  middlewareOrPosName,
  tabletLastSeen,
  tabletVersion,
}) => (
  isTablet ? (
    <StyledPopover ariaLabel="Click to view middleware and tablet information" icon={<IconBadgeInfo />} type="middlewareAndTablet">
      <StyledTabletPopoverContent
        isAutoAcceptEnabled={isAutoAcceptEnabled}
        isTablet
        isTabletOn={isTabletOn}
        middlewareOrPosName={middlewareOrPosName}
        tabletLastSeen={tabletLastSeen}
        tabletVersion={tabletVersion}
      />
    </StyledPopover>
  ) : (
    <>
      {middlewareOrPosName && Array.isArray(middlewareOrPosName) && (
        middlewareOrPosName.map((item) => (
          <TagContainer>
            <Tag key={item} label={item} size="small" variant="outlined" />
          </TagContainer>
        ))
      )}
      <StyledPopover ariaLabel="Click to view tablet information" icon={<IconTablet isTabletOn={isTabletOn} />} type="tablet">
        <TabletPopoverContent
          isAutoAcceptEnabled={isAutoAcceptEnabled}
          isTabletOn={isTabletOn}
          tabletLastSeen={tabletLastSeen}
          tabletVersion={tabletVersion}
        />
      </StyledPopover>
    </>
  )
);

export default StatusContainer;
