export const DIALOG_MODAL_COPY = {
  buildMenu: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Build',
    message: `Are you sure? Doing so will automatically generate ChowNow items,
    from the Square catalog. This action cannot be undone.`,
    title: 'Build ChowNow Menu',
  },
  disableIntegration: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Disable Integration',
    message:
      'Are you sure you want to disable the Square Integration for this location?',
    title: 'Disable Integration',
  },
  menuLoadError: {
    confirmLabel: 'Return to Integration Page',
    message: `An error occurred while trying to load the menu.
        <br />
        Please try again.`,
    title: 'Unable to Load Menu',
  },
  sendOrders: {
    cancelLabel: 'Turn on Later',
    confirmLabel: 'Start Sending Orders',
    message: 'Would you like to start sending orders to this system?',
    title: 'Integration Successful',
  },
  unlinkLocation: {
    cancelLabel: 'Cancel',
    confirmLabel: 'Unlink Location',
    message: `Are you sure? Doing so will disable sending orders and you will need to re-
      select a location.`,
    title: 'Unlink Location',
  },
  removeImage: {
    dataTestId: 'RemovePhotoText',
    cancelLabel: 'Cancel',
    confirmLabel: 'OK',
    message: 'Are you sure? Clicking OK will remove the photo',
    title: 'Remove Photo',
  },
  uploadPhotoError: {
    title: 'Something went wrong.',
    message: `An error occurred while trying to update your image.
    <br />
    Please try again.`,
  },
  cancelOrder: {
    title: 'Are you sure you want to cancel?',
    confirmLabel: 'Cancel Order',
    message:
      'The customer will be notified by email. Orders cannot be reopened.',
    closeIcon: true,
  },
  fullRefund: {
    title: 'Full Refund',
    confirmLabel: 'Next',
    message: `Please confirm you would like to issue a full refund, this cannot be reversed. The customer will be notified by email. Please allow 3-7 business days for the credit to go through, depending on their bank's processing time.`,
    closeIcon: true,
  },
  cancelClosure: {
    title: 'Are you sure you want to resume accepting orders?',
    confirmLabel: 'Resume orders',
    message: 'Orders will be resumed immediately.',
    closeIcon: true,
  },
  refundError: {
    title: 'Something went wrong',
    message: 'Please add at least one order item to process this refund.',
    confirmlabel: 'close',
    closeIcon: true,
  },
  refundPartnerError: {
    title: 'Unable to apply refund on partner delivery orders',
    message:
      'Please contact Customer Support agent through the chat window at the bottom of the screen or by calling 1.888.707.2469',
    confirmLabel: 'Close',
  },
  refundDiscountsError: {
    title: 'Unable to apply refund to orders with discounts',
    message:
      'Please contact Customer Support agent through the chat window at the bottom of the screen or by calling 1.888.707.2469',
    confirmLabel: 'Close',
  },
  refundTypeError: {
    title: 'Unable to apply for this order type',
    message:
      'Please contact Customer Support agent through the chat window at the bottom of the screen or by calling 1.888.707.2469',
    confirmLabel: 'Close',
  },
};

export const CONNECTION_FAILED_MODAL_COPY = {
  ordersListError: {
    message: `An error occurred while trying to load the Orders List.
    <br />
    Please try again.`,
  },
  orderDetailsError: {
    title: 'Something went wrong.',
    message: `An error occurred while trying to load Order Details.
  <br />
  Please try again.`,
  },
};

export const TOOLTIP_COPY = {
  customLabelFee: {
    message:
      'This restaurant adds this fee to cover costs and continue serving your community. 100% goes to the restaurant.',
  },
  deliveryFee: {
    title: 'Delivery Fee',
    message:
      'This restaurant adds this fee to support their drivers and serve more diners. 100% goes to the restaurant.',
  },
  supportLocal: {
    title: 'Help Restaurants Thrive',
  },
};
