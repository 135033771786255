export enum NoFoodImageOptions {
  STOCK_IMAGE = 'stock_image',
  NO_IMAGE = 'no_image',
}

export interface FoodImageFile {
  filename: string;
  file_id: string;
  key: string;
  upload_status: 'uploaded' | 'upload_failed';
  created_at: string | number;
  updated_at: string | number;
}

export interface HasFoodImageResponse {
  // Accounting for the id property added by requestCustomApi -
  // this allows us to remove it before passing it to the UI
  id?: number;
  has_food_image: true;
  food_image_alt_choice: null;
  food_image_file: FoodImageFile;
}

export interface NoFoodImageResponse {
  id?: number;
  has_food_image: false;
  food_image_alt_choice: NoFoodImageOptions;
  food_image_file: null;
}

export interface FoodImageEmptyResponse {
  id?: number;
}

export interface FoodImage400Response {
  id?: number;
  code: string;
  message: string;
  data_path: string;
  data_value: string;
  definition: Record<string, never>;
  validation_message: string;
}

export interface FoodImage404Response {
  id?: number;
  message: string;
}

export interface FoodImage500Response {
  id?: number;
  code: string;
  message: string;
}

export type FoodImageGetErrorResponse =
  FoodImage400Response |
  FoodImage404Response |
  FoodImage500Response;

export type FoodImageGetPreviouslySubmittedResponse = HasFoodImageResponse | NoFoodImageResponse;

export type FoodImageGetSuccessResponse =
  FoodImageGetPreviouslySubmittedResponse |
  FoodImageEmptyResponse;

export type FoodImageGetResponse =
  FoodImageGetSuccessResponse |
  FoodImageGetErrorResponse;

export const isFoodImage400Response =
  (response: FoodImageGetResponse): response is FoodImage400Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&
    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.prototype.hasOwnProperty.call(response, 'data_path') &&
    Object.prototype.hasOwnProperty.call(response, 'data_value') &&
    Object.prototype.hasOwnProperty.call(response, 'definition') &&
    Object.prototype.hasOwnProperty.call(response, 'validation_message')
  );

export const isFoodImage404Response =
  (response: FoodImageGetResponse): response is FoodImage404Response => (
    Object.prototype.hasOwnProperty.call(response, 'message') && Object.keys(response).length === 1
  );

export const isFoodImage500Response =
  (response: FoodImageGetResponse): response is FoodImage500Response => (
    Object.prototype.hasOwnProperty.call(response, 'code') &&
    Object.prototype.hasOwnProperty.call(response, 'message') &&
    Object.keys(response).length === 2
  );

export const isGetFoodImagePreviouslySubmittedResponse =
  (response: FoodImageGetResponse | undefined):
    response is FoodImageGetPreviouslySubmittedResponse => (
    !!response &&
    Object.prototype.hasOwnProperty.call(response, 'has_food_image') &&
    Object.prototype.hasOwnProperty.call(response, 'food_image_alt_choice') &&
    Object.prototype.hasOwnProperty.call(response, 'food_image_file')
  );
