import { Dispatch, SetStateAction } from 'react';

export const websiteAccessAdminUsernameValidator = (
  websiteAdminUsername: string,
  setWebsiteAdminUsernameError: Dispatch<SetStateAction<string>>
) => {
  if (!websiteAdminUsername) {
    setWebsiteAdminUsernameError('Please enter a website username');

    return true;
  } else if (websiteAdminUsername.length > 50) {
    setWebsiteAdminUsernameError(
      'Website username has a maximum length of 50 characters'
    );

    return true;
  }

  return false;
};
