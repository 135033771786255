import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import errorIcon from 'images/icon-404-error-gray.svg';

const { string } = PropTypes;

const Whoops = ({ heading, message }) => {
  return (
    <div className={styles.container} data-testid="badge">
      <img className={styles.img} src={errorIcon} alt="Broken Plate" />
      <h2 className={styles.heading}>
        {heading || 'Whoops, something went wrong'}
      </h2>
      <p className={styles.bodyText}>{message || 'Please try again.'}</p>
    </div>
  );
};

Whoops.propTypes = {
  heading: string,
  message: string
};

export default Whoops;
