import React, { FC, useEffect, useState } from 'react';
import { useGetProgress } from 'api/onboarding/progressApis';
import {
  getCompletedWizardPageCount,
  getWizardPages,
} from 'containers/Onboarding/helpers';
import { getSearchParam } from 'helpers/url';
import {
  ButtonContainer,
  Progress,
  ProgressBar,
  ProgressBarOutline,
  ProgressBarWrapper,
  StyledButton,
  Text,
} from './styles';
import { WizardChecklist } from './WizardChecklist';

interface WizardProgressBarProps {
  className?: string;
  restaurantUuid: string;
}

export const WizardProgressBar: FC<WizardProgressBarProps> = ({
  className,
  restaurantUuid,
}) => {
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const [percentage, setPercentage] = useState<number | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [shouldShowChecklist, setShouldShowChecklist] =
    useState<boolean>(false);

  const isComingFromHomepage = getSearchParam('origin') === 'homepage';

  const {
    getProgressRequestResponse,
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgress,
  } = useGetProgress(restaurantUuid);

  useEffect(() => {
    const wizardPages = getWizardPages(getProgressRequestResponse);
    const newTotalSteps = wizardPages.length - 1;
    const newCompletedSteps = getCompletedWizardPageCount(
      getProgressRequestResponse
    );

    if (newTotalSteps <= 0) {
      return;
    }

    setTotalSteps(newTotalSteps);
    setCompletedSteps(newCompletedSteps);
    const newPercentage = Math.floor((newCompletedSteps / newTotalSteps) * 100);

    // If not initial load, and new value, animate progress bar
    if (percentage !== null && newPercentage !== percentage) {
      setIsActive(true);
      setTimeout(() => {
        setIsActive(false);
      }, 500);
    }

    setPercentage(newPercentage);
  }, [getProgressRequestResponse]);

  useEffect(() => {
    void getProgress(restaurantUuid);
  }, [window.location.href, restaurantUuid]);

  if (
    !restaurantUuid ||
    isComingFromHomepage ||
    isGetProgressRequestLoading ||
    hasGetProgressRequestError ||
    !getProgressRequestResponse ||
    percentage === null
  ) {
    return null;
  }

  return (
    <>
      <ProgressBarWrapper className={className}>
        <WizardChecklist
          getProgressRequestResponse={getProgressRequestResponse}
          isOpen={shouldShowChecklist}
          onClose={() => setShouldShowChecklist(false)}
        />
        <ProgressBarOutline>
          <ProgressBar style={{ width: `${percentage}%` }} $isActive={isActive} />
          <Text>
            <Progress>{`${completedSteps} OF ${totalSteps} DONE`}</Progress>
          </Text>
        </ProgressBarOutline>
      </ProgressBarWrapper>
      <ButtonContainer>
        <StyledButton
          variant="link"
          label="Checklist"
          size="xsmall"
          onClick={() => setShouldShowChecklist(true)}
          isInverse
        />
      </ButtonContainer>
    </>
  );
};
