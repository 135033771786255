import React, { useState } from 'react';
import ToastMenuErrorModal from 'components/Modals/ToastMenuErrorModal';

import {
  IntegrationErrorSuccessResponse,
  MenuError,
} from 'types/pos/integration-errors';

import {
  ErrorButton,
  ErrorCard,
  ErrorText,
  Instructions,
  Section,
  Subtitle,
  Title,
} from './styles';

interface IntegrationErrorsCardProps {
  integrationErrorResponse: IntegrationErrorSuccessResponse;
}

const IntegrationErrorsCard: React.FC<IntegrationErrorsCardProps> = ({
  integrationErrorResponse,
}) => {
  const [modalError, setModalError] = useState<MenuError>();
  const hasConfigErrors = (integrationErrorResponse?.config_errors?.length ?? 0) > 0;
  const hasMenuErrors = (integrationErrorResponse?.menu_errors?.length ?? 0) > 0;

  if (!integrationErrorResponse.menu_errors?.length
    && !integrationErrorResponse.config_errors?.length) {
    return null;
  }

  const createErrorText = (error: MenuError) => {
    let errorText = error.display_text.endsWith('.')
      ? error.display_text.substring(0, error.display_text.length - 1)
      : error.display_text;

    errorText += ` on ${error.menu_name} `;
    return (
      <ErrorText key={error.display_text + error.menu_name}>
        <p>
          {errorText}
          <ErrorButton
            variant="link"
            label={`View ${error.error_items.length} Error${
              error.error_items.length > 1 ? 's' : ''
            }`}
            onClick={() => setModalError(error)}
          />
        </p>
      </ErrorText>
    );
  };

  return (
    <ErrorCard>
      {modalError && (
        <ToastMenuErrorModal
          menuError={modalError}
          setMenuError={setModalError}
        />
      )}
      <Title>Errors</Title>
      <Instructions>
        <p>
          There are errors saved in your Toast dashboard causing issues with the
          integration - you&apos;ll need to correct these issues on Toast.
        </p>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://support.chownow.com/restaurant/s/article/What-are-the-best-practices-to-sync-a-Toast-Menu"
        >
          View Instructions
        </a>
      </Instructions>
      {hasConfigErrors && (
        <Section>
          <Subtitle>Settings Errors</Subtitle>
          <ul>
            {integrationErrorResponse?.config_errors?.map(error => (
              <ErrorText key={error?.display_text}>
                <p>
                  {error?.display_text}
                </p>
              </ErrorText>
            ))}
          </ul>
        </Section>
      )}

      {hasMenuErrors && (
        <Section>
          <Subtitle>Menu Errors</Subtitle>
          <ul>
            {integrationErrorResponse?.menu_errors?.map(error =>
              createErrorText(error)
            )}
          </ul>
        </Section>
      )}
    </ErrorCard>
  );
};

export default IntegrationErrorsCard;
