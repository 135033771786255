import React from 'react';

import { NoAccountCardContainer, Status, Subtext } from './styles';

const NoAccountCard = () => (
  <NoAccountCardContainer>
    <Status>You do not have a Stripe account</Status>
    <Subtext data-testid="onboarding-support-email-text">
      Reach out to{' '}
      <a href="mailto:onboarding@chownow.com">onboarding@chownow.com</a> for
      support.
    </Subtext>
  </NoAccountCardContainer>
);

export default NoAccountCard;
