import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';
import { caption, label } from 'styles/types';

export const TableWrapper = styled.table`
  border-spacing: 0;
  color: ${semanticColors.content.secondary};
  font: ${caption.md.x12};
  text-align: left;
  width: 100%;

  td,
  th {
    padding-right: ${space.x5};
  }

  th {
    white-space: nowrap;
  }
`;

export const EmptyCard = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  color: ${semanticColors.content.secondary};
  display: flex;
  flex-direction: column;
  font: ${label.reg.x16};
  margin-top: ${space.x5};
  padding: ${space.x5};
  text-align: center;
  width: 100%;
`;

export const LoadingSpinnerWrapper = styled.div`
  height: 32px;
  position: relative;
  width: 32px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${space.x5} 0;
`;
