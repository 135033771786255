import { Button } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { space, screen } from 'styles/variables';

export const MenuInputContainer = styled.div`
  display: -moz-box;
  display: -webkit-box;
  justify-content: space-between;

  div:first-child {
    width: 200px;
  }

  @media (min-width: ${screen.smMin}) {
    div:first-child {
      width: 327px; /* Width should be 547px with new sized modal. */
    }
  }
`;

export const CancelButton = styled(Button)`
  margin-top: ${space.oneAndHalf};
`;
