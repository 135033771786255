import React, { ChangeEvent, FC, useContext } from 'react';

import { MenuDataContext } from 'context/MenuDataContext';

import {
  maxCharLimit50,
  assetFileNameRegex,
  menuFileUploadValidator,
} from 'helpers/validators';

import { CustomFileUpload, FileUploadContainer, Input } from './styles';

interface FileUploadProps {
  onClickUploadFile: (file: File) => void;
  onUploadError: (message: string) => void;
}

const FileUpload: FC<FileUploadProps> = ({
  onClickUploadFile,
  onUploadError,
}) => {
  const { setMenuUpload } = useContext(MenuDataContext);

  const validate = (file: File | undefined) => {
    // Remove error message and return to default if no file.
    if (!file) {
      onUploadError('');
      return;
    }

    let errorMessage = '';
    // FE validation for menu asset filename to match BE validation
    if (file instanceof File && !assetFileNameRegex.test(file?.name)) {
      errorMessage =
        "Invalid file name - make sure your file doesn't contain any special characters and is in one of the accepted file types";
    } else if (file instanceof File && !maxCharLimit50.test(file?.name)) {
      errorMessage =
        'Filename has to be shorter than or equal to 50 characters';
    } else {
      errorMessage = menuFileUploadValidator(file);
    }

    onClickUploadFile(file);
    if (errorMessage.length > 0) {
      onUploadError(errorMessage);
    } else {
      setMenuUpload(file);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    validate(file);
  };

  return (
    <FileUploadContainer>
      <CustomFileUpload htmlFor="file-upload">Upload File</CustomFileUpload>
      <Input
        type="file"
        name="file"
        role="button"
        id="file-upload"
        onChange={handleInputChange}
      />
    </FileUploadContainer>
  );
};

export default FileUpload;
