import { format, isToday } from 'date-fns';
import { FulfillmentDay } from 'types/restaurant';

export const getFormattedDate = (date: string): string => {
  if (!date) return '';

  return isToday(new Date(date))
    ? `Today at ${format(new Date(date), 'h:mm a')}`
    : format(new Date(date), 'MMM do, yyyy h:mm a');
};

export const sortByCustomDaysOrder = (displayHours: FulfillmentDay[]) => {
  const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return displayHours.sort((a, b) =>
    daysOrder.indexOf(String(a.dow)) - daysOrder.indexOf(String(b.dow)));
};

export const getCardTitle = (
  hasCurbsideSchedule: boolean,
  hasDineInSchedule: boolean
): string => {
  let title = '';
  if (hasCurbsideSchedule) {
    title += 'Curbside/';
  }
  if (hasDineInSchedule) {
    title += 'Dine-In/';
  }
  title += 'Pickup Hours';
  return title;
};
