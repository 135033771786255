import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import BASE_FIXTURE from './GET-200-base-fixure';

const DELIVERY_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...BASE_FIXTURE,
  customer: {
    ...BASE_FIXTURE.customer,
    delivery: {
      address: {
        zip: '90232',
        city: 'Culver City',
        country_alpha2: 'US',
        name: null,
        street_address1: '3585 Hayden Ave',
        formatted_address: '3585 Hayden Ave, Culver City CA 90232',
        address_string: '3585 Hayden Ave\nCulver City CA 90232',
        place_formatted_address: '3585 Hayden Ave, Culver City, CA 90232',
        state: 'CA',
        country: 'USA',
        geo: '34.0243950,-118.3823471',
        latitude: 34.024395,
        longitude: -118.3823471,
        id: '1987536',
        is_google_place: true,
        street_address2: '',
        is_last_submitted: false,
      },
    },
  },
  total: 62.23,
  items: [
    {
      size: 'Regular',
      name: 'Homemade Spaghetti with Meatballs',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 12.95,
      price: 12.95,
      per_item_total: 12.95,
      special_instructions: null,
      quantity: 1,
      instructions: null,
      id: '02c4e81cbc0e4b518dd1e15070d2adee',
      description: 'meat sauce, garlic and fresh basil',
      modifier_categories: [],
    },
    {
      size: 'Regular',
      name: 'Ricotta Cheese Ravioli with Mushrooms',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 12.95,
      price: 12.95,
      per_item_total: 12.95,
      special_instructions: null,
      quantity: 1,
      instructions: null,
      id: 'e46e33759b944883a60df30ef65334e9',
      description: 'creamy red wine sauce',
      modifier_categories: [],
    },
    {
      size: 'Regular',
      name: 'Chicken Wings',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 8.45,
      price: 6.95,
      per_item_total: 6.95,
      special_instructions: 'Test instructions',
      quantity: 1,
      instructions: 'Test instructions',
      id: '5a21d640ed6744daa7e64b433bc8acbf',
      description: 'Hot wings, deep fried',
      modifier_categories: [
        {
          id: '63580777b4b54fbf8450b16ec96da4ea',
          selections: [
            {
              name: 'Ranch',
              is_taxable: true,
              price: 0.5,
              id: 'bfc58d00baa64ab299aac2c102fd6bfa',
              description: null,
              modifier_categories: [
                {
                  id: '63580777b4b54fbf8450b16ec96da4ea',
                  selections: [
                    {
                      name: 'Extra',
                      is_taxable: true,
                      price: 0.5,
                      id: '63580777b4b54fbf8450b16ec96da4ef',
                      description: null,
                    },
                  ],
                  name: 'Dressing',
                },
              ],
            },
            {
              name: 'Marinara',
              is_taxable: true,
              price: 0.5,
              id: '8a1eff17895f46e0a52fa8526779bed3',
              description: null,
            },
          ],
          name: 'Dipping Sauce',
        },
      ],
    },
    {
      size: 'Regular',
      name: 'Spicy Grilled Chicken Pasta',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 13.95,
      price: 13.95,
      per_item_total: 13.95,
      special_instructions: null,
      quantity: 1,
      instructions: null,
      id: '4cc6d61c0ce144938b1b14ae6a32657c',
      description: 'tangy sweet sauce, peanuts and fresh vegetables',
      modifier_categories: [],
    },
    {
      size: 'Large',
      name: 'Fountain Soda',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 6.5,
      price: 3.25,
      per_item_total: 6.5,
      special_instructions: null,
      quantity: 2,
      instructions: null,
      id: 'd5c20118200e4d20921044a7f4335c79',
      description: null,
      modifier_categories: [
        {
          id: '9185c3ed8a054a03addba1bc9638144f',
          selections: [
            {
              name: 'Diet Coke',
              is_taxable: true,
              price: 0,
              id: '6e1cc1c7286341979fa9c01611780687',
              description: null,
              modifier_categories: [
                {
                  id: 'd5c20118200e4d20921044a7f4335c7a',
                  selections: [
                    {
                      name: 'Light Ice',
                      is_taxable: true,
                      price: 0,
                      id: 'd5c20118200e4d20921044a7f4335c80',
                      description: null,
                    },
                  ],
                  name: 'Ice',
                },
              ],
            },
          ],
          name: 'Soda Choices',
        },
      ],
    },
  ],
  delivery_instructions: 'Testing delivery order details',
  fulfill_method: 'delivery',
  status: 'Submitted',
  id: '5',
  tips: {
    managed_delivery_tip: {
      amount: null,
      label: 'Courier Tip',
    },
    restaurant_tip: {
      amount: 5,
      label: 'Restaurant Tip',
    },
    total: 0,
  },
  single_use_items: {
    requested: true,
    label: 'Include utensils, condiments, etc.',
  },
  fulfillment_times: {
    created_at: '202310241703',
    updated_at: '202310241704',
    requested_fulfillment: null,
    estimated_pickup: null,
    estimated_arrival: null,
    original_estimated_arrival: null,
  },
  sales_tax: 5.43,
  should_acknowledge: true,
  credits: [{
    name: 'ChowNow Bucks',
    amount: 5,
    description: 'ChowNow Bucks',
  }],
  delivery_fee: 2.5,
  tip_label: 'Restaurant Tip',
  item_count: 6,
  adjustments: {
    can_adjust_tip: true,
    tip: {
      description:
        'Your order is being delivered by The H Cafe — 100% of the tip supports the restaurant and their staff',
      tip_options: [
        {
          rate: 10,
          amount: 5.43,
          is_current_tip: false,
        },
        {
          rate: 15,
          amount: 8.15,
          is_current_tip: false,
        },
        {
          rate: 18,
          amount: 9.77,
          is_current_tip: false,
        },
        {
          rate: 20,
          amount: 10.86,
          is_current_tip: false,
        },
      ],
    },
  },
  subtotal: 54.8,
  total_due: 57.23,
  amount_for_transaction_fees: 62.23,
  item_total: 54.3,
};

export default DELIVERY_FIXTURE;
