import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';

export const getDisplayAmount = (amount: number, amountType: '%' | '$') => {
  if (amount === 0) {
    return '';
  } else if (amountType === '%') {
    return `${amount}%`;
  } else {
    return `$${amount.toFixed(2)}`;
  }
};

export const getRefundAmount = (
  refundAmount: number,
  amountType: '%' | '$',
  orderAmount: number | undefined
) => {
  if (!orderAmount || orderAmount < 0) {
    return 0;
  }
  if (amountType === '%') {
    return (orderAmount * refundAmount) / 100;
  }
  return refundAmount;
};

export const applyPercentage = (percentage: number, fee: number) => {
  if (fee <= 0) {
    return 0;
  }
  const adjustedFee = fee - (fee * percentage) / 100;

  // round two decimal places or return original amount or 0 if null
  return adjustedFee ? Math.round(adjustedFee * 1e2) / 1e2 : fee || 0;
};

export const getRevisedAmount = (
  refundAmount: number,
  amountType: '%' | '$',
  order: OrderDetailsV6GetSuccessResponse,
  isForSubtotalOnly: boolean
) => {
  let refundableAmount = order.subtotal;
  if (!isForSubtotalOnly) {
    refundableAmount = order.total || 0;
    if (refundableAmount > 0 && order.misc_fee?.label === 'Support Local Fee') {
      refundableAmount -= order.misc_fee?.amount || 0;
    }
  }
  return (
    ((isForSubtotalOnly ? order?.subtotal : order?.total) ?? 0) -
    Number(
      getRefundAmount(refundAmount, amountType, refundableAmount).toFixed(2)
    )
  ).toFixed(2);
};
