import React from 'react';
import { InputSharedProps } from 'components/Input';
import { ReactComponent as IconSearch } from 'images/icon-search-16.svg';

import {
  StyledInputSearch,
} from './styles';

export interface InputSearchProps extends InputSharedProps {
  /** Disable input field */
  disabled?: boolean;
  /** Label to be describe input. */
  label?: string;
  /** Placeholder text to be displayed in input when empty. */
  placeholder?: string;
  /** show a simply styled search input */
  isSimple?: boolean;
}

const InputSearch = ({
  disabled,
  helpText,
  label = 'Search',
  onChange,
  onKeyDown,
  placeholder,
  isSimple = false,
  value,
}: InputSearchProps) => (
  <StyledInputSearch
    $isSimple={isSimple}
    helpText={helpText}
    icon={<IconSearch />}
    id="search"
    label={label}
    name="search"
    onChange={onChange}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    disabled={disabled}
    shouldHideLabel
    value={value}
  />
);

export default InputSearch;
