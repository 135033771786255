import { login } from 'api/login';
import Terms from 'containers/UserAuth/Terms';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { ERROR_CODES } from 'helpers/constants';
import { passwordValidator } from 'helpers/onboarding/passwordValidator';
import { getSearchParam } from 'helpers/url';

import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';
import Banner from '@chownow/cocina-react-banner';

import LoadingSpinner from 'components/LoadingSpinner';

import styles from './styles.module.scss';

const ActivateAccountCredentials = () => {
  const location = useLocation();
  const userEmail = new URLSearchParams(location.search).get('email');
  const verificationCodeParam = new URLSearchParams(location.search).get(
    'verificationcode'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const redirectUri = getSearchParam('next');
  const activateAccountMessage = `We emailed your verification code to ${userEmail}`;

  // prefills verification code from query param if user came from reset pw email
  useEffect(() => {
    verificationCodeParam && setVerificationCode(verificationCodeParam);
  }, []);

  const handleLogin = async () => {
    const response = await login({
      email: userEmail,
      password: password,
      uniqueKey: verificationCode,
    });

    setIsLoading(true);

    if (response.errors) {
      if (response.errors[0]?.code === ERROR_CODES.invalidVerificationCode) {
        setIsLoading(false);
        setVerificationCodeError(
          'The verification code you entered is either invalid or expired. Please try again.'
        );
      } else {
        setIsLoading(false);
        setVerificationCodeError(
          'Whoops, something went wrong. Please try again.'
        );
      }
    } else if (!response.errors) {
      setIsLoading(false);
      redirectUri
        ? window.location.replace(redirectUri)
        : window.location.replace(`/`);
    }
  };

  const handleOnLoginClick = e => {
    e.preventDefault();

    let passwordError = '';

    if (!verificationCode) {
      setVerificationCodeError('Verification code is required.');
    } else {
      setVerificationCodeError('');
    }

    if (!password) {
      passwordError = 'Password is required.';
    } else {
      const validationErrorMessage = passwordValidator(password);
      passwordError = validationErrorMessage;
    }

    setPasswordError(passwordError);

    if (verificationCode && !passwordError) {
      handleLogin(verificationCode, password);
    }
  };

  return (
    <>
      <Helmet title="Login" />
      <Banner variant="success">{activateAccountMessage}</Banner>
      <div className={styles.main}>
        <h1 className={styles.header}>Activate your account</h1>
        {isLoading ? (
          <LoadingSpinner isAbsolute={false} withWrapper={false} />
        ) : (
          <>
            <form className={styles.card}>
              <div className={styles.inputWrap}>
                <Input
                  className={styles.input}
                  errorText={verificationCodeError}
                  helpText="Enter the verification code that was emailed to you. Check your spam or junk folder if you don't receive it within a few minutes."
                  label="Verification Code"
                  onChange={setVerificationCode}
                  type="text"
                  value={verificationCode}
                  id="Verification Code"
                />
              </div>
              <div className={styles.inputWrap}>
                <Input
                  className={styles.input}
                  errorText={passwordError}
                  helpText="Your password must be at least 8 characters and contain one number."
                  label="Create Password"
                  link={{
                    label: showPassword ? 'Hide' : 'Show',
                    onClick: () => setShowPassword(!showPassword),
                  }}
                  onChange={setPassword}
                  type={!showPassword ? 'password' : ''}
                  value={password}
                  id="Create Password"
                />
              </div>
              <Button
                className={styles.button}
                isFullWidth
                label="Log In"
                onClick={handleOnLoginClick}
                type="submit"
              />
              <Terms />
            </form>
            <div className={styles.note}>
              Already have a ChowNow account?{' '}
              <Link to={'/login'} aria-label="Log in">
                Log in here
              </Link>
              .
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ActivateAccountCredentials;
