import { useEffect, useRef } from 'react';

/**
 * Custom hook recommended from React docs
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * Takes value of prop and uses ref to store previous value
 * **/
export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
