import React from 'react';
import PropTypes from 'prop-types';

// import styles from './styles.scss';

function LegacyFieldset(props) {
  const { children, inputLabel, isEditMode, value } = props;
  return (
    <fieldset className="line">
      <label>{inputLabel}:</label>
      {isEditMode ? <div>{children}</div> : <span>{value}</span>}
    </fieldset>
  );
}

const { bool, node, string } = PropTypes;

LegacyFieldset.propTypes = {
  children: node,
  inputLabel: string,
  isEditMode: bool,
  value: string
};

export default LegacyFieldset;
