import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PHOTO_TYPE } from 'helpers/constants';
import { imageType } from 'helpers/prop-types';

import { ReactComponent as IconPencil } from 'images/pencil.svg';
import { ReactComponent as ImageTooltipArrow } from 'images/tooltip-arrow.svg';

import styles from './styles.module.scss';

function BannerLogo(props) {
  const { media, restaurant, toggleSideBar } = props;

  return (
    <div className={styles.outerWrap}>
      <div className={styles.main}>
        <div className={styles.editWrap}>
          <div
            className={classNames(styles.bannerSection, {
              [styles.withImage]: media.cover,
            })}
            style={{
              backgroundImage: media.cover && `url(${media.cover.cropped_url})`,
            }}
          >
            <div
              data-testid="coverPhotoButton"
              className={classNames(styles.newButton, styles.editButton)}
              onClick={() => {
                toggleSideBar({ id: PHOTO_TYPE.cover });
              }}
            >
              <IconPencil className={styles.icon} />
              {media.cover ? 'Edit' : 'Add'} cover photo
            </div>
          </div>
          <div className={styles.logoSection}>
            <div
              data-testid="logoPhotoButton"
              className={classNames(styles.newButton, styles.plusButton, {
                [styles.withImage]: media.logo,
              })}
              style={{
                backgroundImage: media.logo && `url(${media.logo.cropped_url})`,
              }}
              onClick={() => {
                toggleSideBar({ id: PHOTO_TYPE.logo });
              }}
            >
              <div className={styles.inner}>
                <div>No Logo</div>
                <svg className={styles.halfCircle}>
                  <path
                    d={`M1 1C1.97383 3.00972 3.49393 4.70455 5.38629 5.89041C7.27865 7.07627 9.46673
                    7.7052 11.7 7.7052C13.9332 7.7052 16.1213 7.07627 18.0137 5.89041C19.906 4.70455
                    21.4261 3.00972 22.4 1`}
                  />
                </svg>
              </div>
              <div className={styles.tooltip}>
                <ImageTooltipArrow className={styles.imageTooltip} />
                {media.logo ? 'Edit' : 'Add'} Logo
              </div>
            </div>
            <div className={styles.header}>Manage Photos</div>
            <div className={styles.body}>
              Photos advertise your food, set your restaurant apart from others,
              and help diners see what’s so unique about your delicious dishes.
            </div>
            <a
              className={styles.menuPhotoGuidelines}
              href={`/restaurant/${restaurant.id}/menu/manage-photos/guidelines`}
            >
              Menu Photo Guidelines
            </a>
            <a
              className={styles.coverPhotoGuidelines}
              href="https://support.chownow.com/restaurant/s/article/What-are-our-cover-photo-requirements-and-guidelines"
              target="_blank"
            >
              Cover Photo Guidelines
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

BannerLogo.propTypes = {
  media: imageType,
  toggleSideBar: PropTypes.func,
};

export default BannerLogo;
