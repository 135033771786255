import { useFlags } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useErrorHandler from 'hooks/useErrorHandler';
import usePrevious from 'hooks/usePrevious';

import ThirdPartySotMessaging from './ThirdPartyMenuSotMessaging';
import ConnectionFailedModal from 'components/Modals/ConnectionFailedModal';
import LoadingSpinner from 'components/LoadingSpinner';
import DialogModal from 'components/Modals/DialogModal';
import Whoops from 'components/Whoops';
import withUser from 'components/WithUser';
import EmbeddedAdmin from 'containers/EmbeddedAdmin';
import MenuEditor from 'containers/MenuEditor';
import OnboardingWizard from 'containers/Onboarding';
import Photos from 'containers/Photos';
import PhotosGuidelines from 'containers/PhotosGuidelines';
import Integrations from 'containers/Restaurant/Integrations';
import MerchantAccountVerification from 'containers/Restaurant/MerchantAccountVerification';
import Orders from 'containers/Restaurant/Orders';
import PaymentProcessing from 'containers/Restaurant/PaymentProcessing';
import Pending from 'containers/Restaurant/Pending';
import RestaurantHome from 'containers/Restaurant/RestaurantHome';
import Layout from 'components/Layout';
import { Cover } from '@chownow/cn-web-components';

import { ModalContext } from 'context/ModalContext';

import { request } from 'helpers/api';
import { THIRD_PARTY_MENU_SOURCE } from 'helpers/constants';
import { isOnboardingWizard } from 'helpers/onboarding';
import { hideHelpIcon, showHelpIcon } from 'helpers/helpIcon';

import styles from './styles.module.scss';

function Restaurant() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [restaurant, setRestaurant] = useState({});
  const [company, setCompany] = useState({});
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { showModal } = useContext(ModalContext);
  const { restaurantId } = useParams();
  const location = useLocation();
  const prevUrl = usePrevious(location.pathname);
  const navigate = useNavigate();
  const flags = useFlags();
  const restaurantPath = `/restaurant/${restaurant.id}`;

  // direct users to third party menu sot messaging if this is true
  const thirdPartyMenuSot = restaurant?.third_party_menu_source;

  const handleHeapEventTracking = restaurant => {
    window.heap.clearEventProperties();
    window.heap.addEventProperties({
      HeapRestaurantId: restaurant?.id,
      HeapIsDirect: !!restaurant?.is_direct_live,
    });
  };

  useLayoutEffect(() => {
    setIsOnboarding(isOnboardingWizard(window.location.pathname));
  }, [isOnboarding, window.location.pathname]);

  useEffect(() => {
    hideHelpIcon();
  }, []);

  useEffect(() => {
    // Load the Restaurant details
    if (location.pathname !== prevUrl) {
      request(`restaurant/${restaurantId}`)
        .then(restaurant => {
          request(
            `company/${restaurant.company_id}`,
            null,
            'GET',
            null,
            null,
            null,
            '4.0'
          )
            .then(company => {
              /**
               * In the future if the API returns location status, may need to check user role against this and
               * redirect non-CN Admin users to Pending page if location is in "Onboarding" status.
               * See CN-7214 / CN-7312 for details.
               */
              setCompany(company);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              // show different messaging if company id in url is not valid
              const message = isNaN(restaurant?.company_id)
                ? 'Invalid company ID, please check your url or company ID and try again.'
                : 'An error occurred loading the company information. Please refresh to try again.';
              showModal(DialogModal, {
                message: message,
                onClose: () => {
                  navigate(`/`);
                },
                title: 'Something went wrong',
                confirmLabel: 'Return to Dashboard',
              });
            });
          /**
           * In the future if the API returns location status, may need to check user role against this and
           * redirect non-CN Admin users to Pending page if location is in "Onboarding" status.
           * See CN-7214 / CN-7312 for details.
           */
          setRestaurant(restaurant);
          handleHeapEventTracking(restaurant);

          if (restaurant.is_onboarding) {
            hideHelpIcon();
          } else {
            showHelpIcon();
          }
        })
        .catch(() => {
          setIsLoading(false);
          // show different messaging if restaurant id in url is not valid
          if (isNaN(restaurantId)) {
            showModal(DialogModal, {
              message: `Invalid restaurant ID, please check your url or restaurant ID and try again.`,
              onClose: () => {
                navigate(`/`);
              },
              title: 'Something went wrong',
              confirmLabel: 'Back to Dashboard',
            });
          } else {
            setHasError(true);
          }
        });
    } else {
      if (restaurant.is_onboarding) {
        hideHelpIcon();
      } else {
        showHelpIcon();
      }
    }
    return () => showHelpIcon();
  }, [location]);

  useErrorHandler(hasError, () => {
    showModal(ConnectionFailedModal);
  });

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!get(restaurant, 'id')) {
    return (
      <div className={styles.whoopsContainer}>
        <Whoops message="Invalid restaurant ID, please check your url or restaurant ID and try again." />
      </div>
    );
  }

  return (
    <>
      <Layout
        company={company}
        restaurant={restaurant}
        isOnboarding={isOnboarding}
      >
        <Routes>
          <Route
            path="/orders/*"
            element={<Orders restaurant={restaurant} />}
          />
          <Route
            exact
            path="/"
            element={
              <RestaurantHome key={restaurant.id} restaurant={restaurant} />
            }
          />
          <Route exact path="/pending" element={<Pending />} />
          <Route
            path="/onboarding/*"
            element={<OnboardingWizard restaurant={restaurant} />}
          />
          <Route
            element={<MerchantAccountVerification />}
            exact
            path="/merchant-account/verification/:verificationResult"
          />
          <Route
            path="/settings/integrations/*"
            element={<Integrations restaurant={restaurant} />}
          />
          <Route
            path="/menu/hide-show-items"
            element={<MenuEditor restaurant={restaurant} />}
          />
          <Route
            path="/menu"
            element={
              <Navigate to={`${restaurantPath}/menu/hide-show-items`} replace />
            }
          />
          <Route
            path="/menu/edit"
            element={
              <Navigate to={`${restaurantPath}/menu/hide-show-items`} replace />
            }
          />
          <Route
            path="/menu/manage-photos/guidelines"
            element={<PhotosGuidelines restaurantId={restaurant.id} />}
          />
          <Route
            path="/menu/manage-photos/upload"
            element={<Photos exact restaurant={restaurant} />}
          />
          <Route
            path="/menu/photos/upload"
            element={
              <Navigate
                to={`${restaurantPath}/menu/manage-photos/upload`}
                replace
              />
            }
          />
          {/* ADMIN MENUS */}
          <Route
            exact
            path="/menu/edit-menus"
            element={
              thirdPartyMenuSot ? (
                <>
                  <Cover title="Edit Menus" />
                  <ThirdPartySotMessaging
                    isCuboh={
                      thirdPartyMenuSot === THIRD_PARTY_MENU_SOURCE.cuboh
                    }
                  />
                </>
              ) : (
                <EmbeddedAdmin
                  src={`restaurant/${restaurant.id}/menus`}
                  title="Edit Menus"
                  key={`${restaurant.id}/edit-menus`}
                />
              )
            }
          />
          <Route
            path="/menu/edit-items"
            element={
              thirdPartyMenuSot ? (
                <>
                  <Cover title="Edit Items" />
                  <ThirdPartySotMessaging
                    isCuboh={
                      thirdPartyMenuSot === THIRD_PARTY_MENU_SOURCE.cuboh
                    }
                  />
                </>
              ) : (
                <EmbeddedAdmin
                  src={`restaurant/${restaurant.id}/menu/items`}
                  title="Edit Items"
                  key={`${restaurant.id}/edit-items`}
                />
              )
            }
          />
          {/* ADMIN HOURS */}
          <Route
            path="/hours/edit-schedule"
            element={
              thirdPartyMenuSot ? (
                <>
                  <Cover title="Edit Schedule" />
                  <ThirdPartySotMessaging
                    isCuboh={
                      thirdPartyMenuSot === THIRD_PARTY_MENU_SOURCE.cuboh
                    }
                  />
                </>
              ) : (
                <EmbeddedAdmin
                  src={`restaurant/${restaurant.id}/menus/schedule`}
                  title="Edit Schedule"
                  key={`${restaurant.id}/edit-schedule`}
                />
              )
            }
          />
          <Route
            path="/hours/edit-closures"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}/menus/temp-closures`}
                title="Edit Closures"
                key={`${restaurant.id}/edit-closures`}
              />
            }
          />
          {/* ADMIN REPORTS */}
          <Route
            path="/reports/accounting"
            element={
              <EmbeddedAdmin
                src="report/disbursements_v2"
                title="Accounting"
                key={`${restaurant.id}/accounting`}
              />
            }
          />
          <Route
            path="/reports/promos"
            element={
              <EmbeddedAdmin
                src="report/promotions"
                title="Promos"
                key={`${restaurant.id}/promos`}
              />
            }
          />
          <Route
            path={`/reports/order-source`}
            element={
              <EmbeddedAdmin
                src="report/transaction_source"
                title="Order Source"
                key={`${restaurant.id}/order-source`}
              />
            }
          />
          <Route
            path="/reports/customer-spending"
            element={
              <EmbeddedAdmin
                src="report/customer_orders_report"
                title="Customer Spending"
                key={`${restaurant.id}/reports/customer-spending`}
              />
            }
          />
          <Route
            path="/reports/order-overview"
            element={
              <EmbeddedAdmin
                src="report/orders"
                title="Order Overview"
                key={`${restaurant.id}/order-overview`}
              />
            }
          />
          <Route
            path="/reports/emailed"
            element={
              <EmbeddedAdmin
                src={`report/email/restaurant/${restaurant.id}`}
                title="Emailed"
                key={`${restaurant.id}/emailed`}
              />
            }
          />
          {/* ADMIN CUSTOMERS */}
          <Route
            path="/customers"
            element={
              <EmbeddedAdmin
                src="report/customer_orders_report"
                title="Customers"
                key={`${restaurant.id}/customers`}
              />
            }
          />
          {/* ADMIN SETTINGS */}
          <Route
            path="/settings/location-info"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}`}
                title="Location Info"
                key={`${restaurant.id}/location-info`}
              />
            }
          />
          <Route
            path="/settings/payment-processing"
            element={<PaymentProcessing restaurant={restaurant} />}
          />
          <Route
            path="/settings/customizations"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}/customizations`}
                title="Customizations"
                key={`${restaurant.id}/customizations`}
              />
            }
          />
          <Route
            path="/settings/email-notifications"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}/emails`}
                title="Email Notifications"
                key={`${restaurant.id}/email-notifications`}
              />
            }
          />
          <Route
            path="/settings/manage-promos"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}/deals`}
                title="Manage Promos"
                key={`${restaurant.id}/manage-promos`}
              />
            }
          />
          {/* ADMIN CN-INTERNAL */}
          <Route
            path="/cn-internal/push-notifications"
            element={
              <EmbeddedAdmin
                src={`company/${restaurant.company_id}/push_notification`}
                title="Push Notifications"
                key={`${restaurant.id}/push-notifications`}
              />
            }
          />
          <Route
            path="/cn-internal/add-ons"
            element={
              <EmbeddedAdmin
                src={`restaurant/${restaurant.id}/addons`}
                title="Add-Ons"
                key={`${restaurant.id}/add-ons`}
              />
            }
          />
          <Route
            path="/cn-internal/restaurant-categories"
            element={
              <EmbeddedAdmin
                src="restaurant/categories"
                title="Restaurant Categories"
                key={`${restaurant.id}/restaurant-categories`}
              />
            }
          />
        </Routes>
      </Layout>
    </>
  );
}

export default withUser(Restaurant, true);
