import React, { useContext, useRef, useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { postImagetoS3 } from 'api/s3';
import {
  removePhotoDetails,
  updatePhotoDetails,
  getPhotoEndpointUrl,
} from 'api/restaurant';
import { updateMenuItemImage, removeMenuItemImage } from 'helpers/restaurant';
import { getBaseUrl, convertImageToJpeg } from 'helpers/image';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import { TIMEOUTS, PHOTO_TYPE } from 'helpers/constants';
import { getHelperText } from 'helpers/imageCropper';
import { s3ImageType } from 'helpers/prop-types';
import useErrorHandler from 'hooks/useErrorHandler';
import { CatalogContext } from 'context/CatalogContext';
import { CropImageContext } from 'context/CropImageContext';
import { ModalContext } from 'context/ModalContext';

import DialogModal from 'components/Modals/DialogModal';
import LoadingBar from 'components/ImageUploader/LoadingBar';
import LoadingSpinner from 'components/LoadingSpinner';
import PhotoButtonPortal from '../PhotoButtonPortal';
import CropInstructionsPortal from '../CropInstructionsPortal';

import { ReactComponent as UploadImage } from 'images/upload-image.svg';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './styles.module.scss';

function ImageCropper(props) {
  const { imageData, imageError, imageStatus, isLoading, setIsLoading } = props;
  const imageRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ percent: 0 });
  const [uploadError, setUploadError] = useState();
  const [validators, setValidators] = useState({
    minWidth: 0,
    minHeight: 0,
  });
  const { categories, setCategories, updateMedia } = useContext(CatalogContext);
  const { showModal } = useContext(ModalContext);
  const {
    closeSideBar,
    image,
    itemId,
    companyId,
    restaurantId,
    circleCrop,
    restrictions,
  } = useContext(CropImageContext);
  const inImageEditMode =
    (imageStatus === 'done' && imageData) || (image && editMode);
  const isRestaurantAsset =
    itemId === PHOTO_TYPE.logo || itemId === PHOTO_TYPE.cover;
  const requestPath = isRestaurantAsset
    ? `restaurant/${restaurantId}`
    : `company/${companyId}/item/${itemId}`;
  const [cropOptions, setCropOptions] = useState({
    aspect: 1, // aspect ratio
    unit: '%',
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  });

  function statusText() {
    switch (uploadProgress.status) {
      case 'preparing':
        return 'Preparing';
      case 'loading':
        return 'Uploading';
      default:
        return 'Publishing Your Photo';
    }
  }

  useErrorHandler(uploadError, () => {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.uploadPhotoError,
    });
  });

  useEffect(() => {
    setEditMode(false);
  }, [image]);

  useEffect(() => {
    setCropOptions({
      ...cropOptions,
      aspect: itemId === PHOTO_TYPE.cover ? null : 1,
    });
  }, [itemId]);

  function handleOnChange(crop, percentCrop) {
    // use percentCrop instead of pixel crop to maintain original dimensions
    setCropOptions(percentCrop);
  }

  async function handleUploadImage() {
    if (imageRef.current) {
      setUploadProgress({ status: 'preparing' });
      setIsLoading(true);

      let convertedImageUrl, updateItemResponse, S3Response;
      const { naturalWidth: width, naturalHeight: height } = imageRef.current;

      // Make sure cropOptions values do not go below 0
      const cropOptionsX = cropOptions.x < 0 ? 0 : cropOptions.x;
      const cropOptionsY = cropOptions.y < 0 ? 0 : cropOptions.y;

      // Convert percent values to pixel values
      const crop = {
        ...cropOptions,
        x: Math.round((cropOptionsX / 100) * width),
        y: Math.round((cropOptionsY / 100) * height),
        width: Math.round((Math.floor(cropOptions.width) / 100) * width),
        height: Math.round((Math.floor(cropOptions.height) / 100) * height),
      };

      // media = cover or logo photo
      // image = menu item photo
      const mediaType = isRestaurantAsset ? 'media' : 'image';

      if (!editMode) {
        convertedImageUrl = convertImageToJpeg(imageRef.current);

        if (convertedImageUrl.errors) {
          setIsLoading(false);
          return setUploadError(true);
        }

        S3Response = await postImagetoS3(
          `${requestPath}/${mediaType}-upload-url`,
          convertedImageUrl,
          imageData.file.name,
          setUploadProgress
        );

        if (S3Response.errors) {
          setIsLoading(false);
          return setUploadError(true);
        }
      }

      updateItemResponse = await updatePhotoDetails(
        getPhotoEndpointUrl(requestPath, mediaType, image),
        S3Response ? S3Response.filename : image.filename,
        imageData ? imageData.file.name : image.user_filename,
        crop,
        width,
        height,
        itemId,
        imageData && isRestaurantAsset ? 'POST' : 'PUT'
      );

      if (!updateItemResponse.errors) {
        if (isRestaurantAsset) {
          updateMedia(itemId, updateItemResponse);
        } else {
          updateCatalog(itemId, updateItemResponse);
        }

        editMode ? closeSideBar(false, 'UPDATE') : closeSideBar(false, 'POST');

        setTimeout(() => {
          setIsLoading(false);
        }, TIMEOUTS.closeSideBar);
      } else {
        setIsLoading(false);
        setUploadError(true);
      }
    }
  }

  function updateCatalog(itemId, response) {
    const updatedCategories = updateMenuItemImage(categories, itemId, response);

    setCategories(updatedCategories);
  }

  function handleRemovePhoto() {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.removeImage,
      onClose: removeItemImage,
    });
  }

  async function removeItemImage(didConfirm) {
    setIsLoading(true);
    if (!didConfirm) {
      return setIsLoading(false);
    }

    const response = await removePhotoDetails(
      isRestaurantAsset
        ? `${requestPath}/media/${image.id}`
        : `${requestPath}/image`
    );
    if (response.errors) {
      setIsLoading(false);
      return setUploadError(true);
    }

    if (!isRestaurantAsset) {
      const updatedCategories = removeMenuItemImage(categories, itemId);

      setCategories(updatedCategories);
    } else {
      updateMedia(itemId, null);
    }

    closeSideBar(false, 'DELETE');
    setIsLoading(false);
  }

  function onImageLoaded(imgFile) {
    imageRef.current = imgFile;
    const { naturalHeight, height, naturalWidth, width } = imgFile;
    const isLandscape = width > height;

    // if image already exists, use existing crop settings
    if (image) {
      setCropOptions({
        ...cropOptions,
        x: (image.crop_x / image.width) * 100,
        y: (image.crop_y / image.height) * 100,
        width: (image.crop_width / image.width) * 100,
        height: (image.crop_height / image.height) * 100,
      });
    } else {
      // calculate crop length as a percentage
      const cropLength = isLandscape
        ? (naturalHeight / naturalWidth) * 100
        : (naturalWidth / naturalHeight) * 100;
      const cropConfig =
        itemId === PHOTO_TYPE.cover
          ? // 16:9 config. 9/16 * 100 = 56.2
            {
              x: 0,
              y: (100 - 56.25) / 2,
              width: 100,
              height: 56.2,
            }
          : {
              // center crop based on crop length
              x: isLandscape ? (100 - cropLength) / 2 : 0,
              y: isLandscape ? 0 : (100 - cropLength) / 2,
              // set height and width to a 1:1 aspect ratio
              width: isLandscape ? cropLength : 100,
              height: !isLandscape ? cropLength : 100,
            };
      setCropOptions({
        ...cropOptions,
        ...cropConfig,
      });
    }

    // react crop uses display dimensions instead of natural dimensions for crop limits
    setValidators({
      minWidth: (width / naturalWidth) * restrictions.minWidth,
      minHeight: (height / naturalHeight) * restrictions.minHeight,
    });

    return false; // must return false when setting crop
  }

  return (
    <>
      <PhotoButtonPortal>
        {inImageEditMode && (
          <div
            className={classNames(styles.photoBtn, {
              [styles.disabled]: isLoading, // eslint-disable-line
            })}
            onClick={handleUploadImage}
          >
            Done
          </div>
        )}
        {image && !editMode && (
          <>
            <div
              className={classNames(styles.photoBtn, {
                [styles.disabled]: isLoading, // eslint-disable-line
              })}
              onClick={() => {
                setEditMode(true);
              }}
            >
              Edit Photo
            </div>
            <div className={styles.downloadBtnGroup}>
              <>
                <a
                  className={classNames(styles.photoBtn, styles.downloadBtn)}
                  href={getBaseUrl(image)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Original
                </a>
                <a
                  className={classNames(styles.photoBtn, styles.downloadBtn)}
                  href={image.cropped_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Cropped
                </a>
              </>
              <div
                className={classNames(styles.photoBtn, styles.removeBtn, {
                  [styles.disabled]: isLoading, // eslint-disable-line
                })}
                onClick={handleRemovePhoto}
              >
                Remove Photo
              </div>
            </div>
          </>
        )}
      </PhotoButtonPortal>

      <CropInstructionsPortal>
        {(inImageEditMode || isRestaurantAsset) && (
          <div className={styles.cropInstructions} data-testid="EditPhotoText">
            {getHelperText(
              inImageEditMode,
              isRestaurantAsset,
              itemId,
              restrictions
            )}
          </div>
        )}
      </CropInstructionsPortal>

      {(() => {
        if (image && !editMode) {
          // if item already has image show preview
          return (
            <>
              {/* show spinner when removing iamge */}
              {isLoading && <LoadingSpinner />}
              <img
                className={styles.previewImage}
                src={image.cropped_url}
                alt="item preview"
              />
            </>
          );
        } else if ((imageStatus === 'done' && imageData) || editMode) {
          // if is uploading image to S3
          return isLoading ? (
            <div className={styles.loadingBarWrap}>
              <LoadingSpinner
                withWrapper={false}
                isAbsolute={false}
                isDelayed={false}
                spinnerClassName={
                  uploadProgress.status !== 'complete' ? styles.hidden : ''
                }
              />
              <div className={styles.loadingStatus}>{statusText()}</div>
              <LoadingBar meta={uploadProgress} />
            </div>
          ) : (
            // if local image has been uploaded
            <ReactCrop
              crop={cropOptions}
              imageStyle={{
                maxHeight: 'calc(100vh - 240px)',
              }}
              keepSelection
              minWidth={validators.minWidth}
              minHeight={validators.minHeight}
              onChange={handleOnChange}
              circularCrop={circleCrop}
              onImageLoaded={onImageLoaded}
              src={get(imageData, 'meta.previewUrl') || getBaseUrl(image)}
            />
          );
        } else if (imageError) {
          return (
            <div className={styles.errorMessage}>
              <h3 className={styles.errorHeading}>Sorry about that.</h3>
              <p className={styles.errorParagraph}>
                {imageError}. If you're having trouble, please reference our
                photo guidelines!
              </p>
            </div>
          );
        } else {
          // show default image input box
          return <UploadImage className={styles.uploadImage} />;
        }
      })()}
    </>
  );
}

export default ImageCropper;

ImageCropper.propTypes = {
  imageData: s3ImageType,
  imageError: PropTypes.string,
  imageStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
};
