import React, {
  useState,
  useRef,
  useEffect,
  FC,
  ReactNode,
  Children,
  cloneElement,
  ReactElement,
} from 'react';

import useWindowSize from 'hooks/useWindowSize';

import { OrderItemNestedModsV5, TabletOrderItemV5 } from 'types/order-details';
import { ChevronIcon, IconExpand, Item } from './styles';

interface RefundItemProps {
  children: ReactNode[];
  item: OrderItemNestedModsV5 | TabletOrderItemV5;
  isOpen?: boolean;
}

const RefundItem: FC<RefundItemProps> = ({ children, item, isOpen }) => {
  const [isCollapsed, setIsCollapsed] = useState(!isOpen);
  const hasModifiers = !!item.modifier_categories.length;
  const validChildren = children.filter(Boolean);
  const firstChildRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    const padding = hasModifiers ? 24 : 48;
    const elemHeight = padding + (firstChildRef.current?.offsetHeight || 0);

    setHeight(elemHeight);
  }, [children, windowSize]);

  return (
    <Item style={{ height: isCollapsed ? height : 'initial' }}>
      {Children.map(validChildren, (child, index) => {
        const newChild = cloneElement(child as ReactElement, {
          setIsCollapsed,
        });
        // Wrap the first child with a ref to get the collapsed height
        if (index === 0) {
          return (
            <div key={newChild.key} ref={firstChildRef}>
              {newChild}
            </div>
          );
        } else {
          return newChild;
        }
      })}
      {hasModifiers && (
        <IconExpand onClick={() => setIsCollapsed(!isCollapsed)}>
          <ChevronIcon $isActive={isCollapsed} />
        </IconExpand>
      )}
    </Item>
  );
};

export default RefundItem;
