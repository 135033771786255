import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const NoAccountCardContainer = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  margin-bottom: 142px;
  padding: ${space.x5};
`;

export const Status = styled.h2`
  font: ${label.semi.x20};
`;

export const Subtext = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x2} 0 0 0;
`;
