import { forOwn, isFunction, isObject, omit } from 'lodash';
import { normalize } from 'normalizr';

import { apiRequest } from '@chownow/cn-web-utils/api';
import { param } from '@chownow/cn-web-utils/url';

export function formatError(err) {
  if (err) {
    const responseError = err.errors ? err.errors[0] : { message: err };
    return { errors: [responseError] };
  }
}

export async function request(
  path,
  params,
  method = 'GET',
  customHost,
  serializer,
  timeout,
  apiVersion
) {
  const { endpoint, ...options } = apiRequest(
    path,
    params,
    method,
    customHost,
    apiVersion
  );

  try {
    let result;

    if (timeout) {
      const controller = new AbortController();
      // abort request if no response returned after specified time
      const abortRequest = setTimeout(() => controller.abort(), timeout);

      result = await fetch(endpoint, {
        ...options,
        signal: controller.signal,
      });
      // clean up request abortion if fetch is successful
      clearTimeout(abortRequest);
    } else {
      result = await fetch(endpoint, options);
    }

    let payload = await result.json();

    // verify request had 2xx status code
    if (result.ok) {
      if (isFunction(serializer)) {
        payload = serializer(payload);
      } else if (isObject(serializer)) {
        payload = normalize(payload, serializer);
      }
    } else {
      // Imperatively navigate to Unauthorized page on 403
      if (result.status === 403) {
        window.location.replace('/unauthorized');
      }

      if (result.status >= 500) {
        throw new Error('Server error occurred. Try again later.');
      }
      throw payload;
    }

    return payload;
  } catch (err) {
    throw err;
  }
}

/**
 * Formatting function that returns an object formatted for the Fetch API for external services
 * @param {string} path - request path
 * @param {string} customHost - custom host URL
 * @param {Object | undefined} params - params
 * @param {string | undefined} method - request method, defaults to GET
 */
export function customApiRequest(
  path,
  customHost,
  params = {},
  method = 'GET'
) {
  const endpoint = `${customHost}/${path}`;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  };

  // Short circuit any GET requests regardless of domain
  if (method.toUpperCase() === 'GET') {
    return {
      method,
      headers,
      endpoint: Object.keys(params || {}).length
        ? `${endpoint}?${param(params)}`
        : endpoint,
    };
  }

  return {
    method,
    headers,
    endpoint,
    body: JSON.stringify(params),
  };
}

/**
 * Make a request to custom/external API
 * @param {string} path - request path
 * @param {string} customHost - custom host URL
 * @param {string | undefined} method - request method, defaults to GET
 * @param {Object | undefined} params - params
 * @param {function | Object | undefined} serializer - could be custom serializer or normalizr schema
 * @param {number | undefined} timeout - aborts request if no response after this amount of time
 * schema docs - https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#schema
 */
export async function requestCustomApi(
  path,
  customHost,
  method = 'GET',
  params,
  serializer,
  timeout
) {
  const { endpoint, ...options } = customApiRequest(
    path,
    customHost,
    params,
    method
  );

  try {
    let result;

    if (timeout) {
      const controller = new AbortController();
      // abort request if no response returned after specified time
      const abortRequest = setTimeout(() => controller.abort(), timeout);

      result = await fetch(endpoint, {
        ...options,
        signal: controller.signal,
      });
      // clean up request abort if fetch is successful
      clearTimeout(abortRequest);
    } else {
      result = await fetch(endpoint, options);
    }

    let payload = await result.json();

    // verify request had 2xx status code
    if (result.ok) {
      if (isFunction(serializer)) {
        payload = serializer(payload);
      } else if (isObject(serializer)) {
        payload = normalize(payload, serializer);
      }
    } else {
      // Imperatively navigate to Unauthorized page on 403
      if (result.status === 403) {
        window.location.replace('/unauthorized');
      }

      if (result.status >= 500) {
        throw new Error('Server error occurred. Try again later.');
      }
      throw payload;
    }

    return payload;
  } catch (err) {
    throw err;
  }
}

export async function uploadFile(file, fileId, bucket_type, store_name) {
  try {
    const formData = new FormData();
    const presignData = await request(
      'internal/s3-form-values',
      { bucket_type, store_name },
      'POST'
    );
    const s3Data = omit(presignData, ['access_url', 'action']);

    s3Data.file = file;
    // Build out the form data for the file upload
    // API policy required a Content-Type property set first
    formData.append('Content-Type', 'application/octet-stream');

    forOwn(s3Data, (value, key) => {
      formData.append(key, value);
    });

    const result = await fetch(presignData.access_url, {
      method: 'POST',
      headers: {},
      body: formData,
    });

    if (result.ok) {
      // Build out response data with all needed properties
      s3Data.access_url = presignData.access_url;
      s3Data.fileId = fileId;
      // API response uses this template format so disable lint rule
      // eslint-disable-next-line no-template-curly-in-string
      s3Data.relativePath = s3Data.key.replace('${filename}', s3Data.file.name);
      s3Data.link = encodeURI(`${s3Data.access_url}/${s3Data.relativePath}`);

      return s3Data;
    }

    throw result;
  } catch (err) {
    throw err;
  }
}

/**
 * Make a request with XMLHttpRequest and use setProgress callback to update progress
 * @param {string} url - request URL
 * @param {string} method - request method
 * @param {object} body - request body
 * @param {function} setProgress - callback for setting progress status & percent
 */
export async function fetchWithProgress(url, method, body, setProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let percentCompleted = 0;

    xhr.open(method, url);

    // upload progress event
    xhr.upload.addEventListener('progress', e => {
      percentCompleted = (e.loaded / e.total) * 100;
      if (setProgress) {
        setProgress({
          status: 'loading',
          percent: percentCompleted,
        });
      }
    });

    // request finished event
    xhr.addEventListener('load', e => {
      if (setProgress) {
        setProgress({
          status: 'complete',
          percent: percentCompleted,
        });
      }

      resolve(xhr.response.body || {});
    });

    // send POST request to server
    xhr.send(body);
  });
}

export const ADMIN_PRIVILEGES_REQUIRED = '25005';
