import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SmilingChefHat } from 'images/smiling-chef-hat.svg';
import {
  Container,
  Content,
  Message,
  StyledButton,
  StyledFooter,
} from './styles';

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <Helmet title="Unauthorized" />
        <div role="img" aria-label="access prohibited">
          <SmilingChefHat />
        </div>
        <Message>
          You do not have access to the requested content. If you believe you
          have reached this in error, please&nbsp;
          <a href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL}`}>
            contact support
          </a>
          .
        </Message>
        <StyledButton label="Go to Homepage" onClick={() => navigate('/')} />
      </Content>
      <StyledFooter />
    </Container>
  );
};

export default Unauthorized;
