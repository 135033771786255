import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from 'helpers/url';

const RestaurantRedirect = () => {
  const restaurantId = getUrlParam('restaurant');
  const navigate = useNavigate();

  const fetchAndRedirect = (restId: string | null) => {
    const urlParts = window.location.pathname.split('/');
    if (restId && urlParts[1] === 'company') {
      let newPathname = '';
      // start at index 3 to remove "company/{hqid}" from url
      for (let i = 3; i < urlParts.length; i += 1) {
        newPathname += '/';
        newPathname += urlParts[i];
      }
      navigate(`${newPathname}${window.location.search}`);
    }
  };

  useEffect(() => {
    void fetchAndRedirect(restaurantId);
  }, [restaurantId]);

  return null;
};

export default RestaurantRedirect;
