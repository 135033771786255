import * as Yup from 'yup';

import { numericStringRegex, validators } from 'helpers/validators';

const ONLY_NUMS_MSG = 'This field can only contain numbers';

// Overwrite original min test method to allow empty strings
Yup.addMethod(Yup.string, 'min', function (min, msg) {
  return this.test({
    name: 'min',
    exclusive: true,
    message: msg,
    test: (value) => !value || value.length >= min,
  });
});

export function disbursementSchema() {
  return Yup.object().shape({
    disburse_to: Yup.object().shape({
      account: Yup.string()
        .min(4, 'minimum 4 digits')
        .matches(numericStringRegex, ONLY_NUMS_MSG),
      routing: Yup.mixed().test(
        'bankRouting',
        validators.bankRouting.message,
        validators.bankRouting.validate
      ),
    }),
  });
}
