/* eslint-disable max-len */
import React from 'react';

export default function Blank() {
  return (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 1C0 0.5 0.5 0 1 0H31C31.5 0 32 0.5 32 1V21C32 21.5 31.5 22 31 22H1C0.5 22 0 21.5 0 21V1ZM1 1V21H31V1H1Z"
        fill="black"
      />
    </svg>
  );
}
