// Variables used for styled components

export const icon = {
  xs: '8px',
  sm: '16px',
  default: '24px',
  lg: '32px',
  xl: '40px',
};

export const space = {
  half: '4px',
  oneAndHalf: '12px',
  x1: '8px',
  x2: '16px',
  x3: '24px',
  x4: '32px',
  x5: '40px',
  x6: '48px',
  x7: '56px',
  x8: '64px',
  x9: '72px',
  x10: '80px',
  x11: '88px',
  x12: '96px',
};

// Screen Sizes
// NOTE: 'All devices' defaults are defined as 'xxs' (0px-575px).
export const screen = {
  xxsMax: '350px',
  xsMin: '576px',
  xsMax: '767px',
  smMin: '768px',
  smMax: '991px',
  mdMin: '992px',
  mdMax: '1199px',
  lgMin: '1200px',
  xlMin: '1440px',
};

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
export const zIndex = {
  raised: '1',
  dropdown: '1000 !default',
  sticky: '1020 !default',
  fixed: '1030 !default',
  modalBackdrop: '1040 !default',
  modal: '1050 !default',
  modalBase: '1051',
  popover: '1060 !default',
  tooltip: '1070 !default',
};

export const borderRadius = {
  small: space.x1,
  default: space.x2,
  extra: space.x3,
};

export const containerWidths = {
  sidebarWidth: '220px',
  containerWidth: '936px',
  containerWidthNarrow: '696px',
  unauthorizedContainerWidth: '475px',
  containerLoginWidth: '400px',
  onboardingContainerWidth: '696px',
  onboardingPageIntroWidth: '576px',
};

export const heights = {
  navBarHeight: '65px',
};
