export const salesTaxValidator = (inputValue: string): string => {
  const parsedValue = parseFloat(inputValue);
  const isNumber =
    /^([+]|[-])?\d*\.?\d+$/.test(inputValue) ||
    /^([+]|[-])?\d+\.?\d*$/.test(inputValue);
  const isInRange = parsedValue >= 0 && parsedValue <= 54;
  let validationErrorMessage = '';

  if (isNumber && !isInRange) {
    validationErrorMessage = 'Number must be between 0 and 54';
  }

  if (!isNumber) {
    validationErrorMessage = 'Not a valid decimal value';
  }

  return validationErrorMessage;
};
