import React, { createRef, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CUBOH_MENU_URL } from 'helpers/constants';

import Outlink from '@chownow/cocina-react-outlink';

import styles from './styles.module.scss';

function NavLink(props) {
  const {
    children,
    isMultiPage,
    isUsingCuboh,
    sublink: isSubLink,
    containerLink: isContainerLink,
    path,
    url,
    onNavItemClick,
    handleRequestClose,
  } = props;
  const isCurrentRoute = window.location.pathname === path;
  const isLinkActive = isCurrentRoute;
  const isActive = isMultiPage ? isCurrentRoute : isLinkActive;
  const linkRef = createRef();

  useEffect(() => {
    if (isLinkActive && isSubLink) {
      // simulate click to move animated bar to the correct positon
      onNavItemClick(linkRef.current);
    }
  }, [isLinkActive]);

  function handleOnClick(e) {
    handleRequestClose();
    if (isSubLink) onNavItemClick(e.target);
  }

  return (
    <div
      className={classNames(styles.link, {
        [styles.activeSublink]: isActive && isSubLink,
        [styles.activeLink]: isActive,
        [styles.sideBar]: isActive && !isSubLink,
        [styles.subLink]: isSubLink,
        [styles.containerLink]: isContainerLink,
      })}
      ref={linkRef}
      onClick={handleOnClick}
    >
      {isUsingCuboh ? (
        <Outlink to={CUBOH_MENU_URL} children={children} />
      ) : (
        <Link children={children} to={path} url={url} />
      )}
    </div>
  );
}

NavLink.propTypes = {
  children: PropTypes.node,
  containerLink: PropTypes.bool,
  handleRequestClose: PropTypes.func,
  isMultiPage: PropTypes.bool,
  onNavItemClick: PropTypes.func,
  path: PropTypes.string,
  sublink: PropTypes.bool,
  url: PropTypes.string,
};

export default NavLink;
