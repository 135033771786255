import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';
import { EMAIL_VALIDATION_REGEX } from '@chownow/cn-web-utils/validation';
import { login } from 'api/login';

import { ERROR_CODES } from 'helpers/constants';
import { getSearchParam } from 'helpers/url';

import { ReactComponent as IconCaution } from 'images/icon-caution-sm.svg';

import { ModalContext } from 'context/ModalContext';

import Terms from 'containers/UserAuth/Terms';

import LoadingSpinner from 'components/LoadingSpinner';
import DialogModal from 'components/Modals/DialogModal';

import styles from './styles.module.scss';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUri = getSearchParam('next');
  const { showModal } = useContext(ModalContext);
  const forgotPasswordRedirectUri = redirectUri
    ? `?next=${encodeURIComponent(redirectUri)}`
    : '';

  const handleLogin = async (email, password) => {
    const response = await login({
      email: email,
      password: password,
    });
    setIsLoading(true);

    if (response.errors) {
      const error = response.errors[0];

      if (error.code === ERROR_CODES.invalidLogin) {
        const errorMessage = 'Incorrect username or password';
        setIsLoading(false);
        setErrorMessage(errorMessage);
      } else {
        setIsLoading(false);
        showModal(DialogModal, {
          title: 'Something went wrong.',
          message: 'Please try again',
          onClose: () => window.location.reload(),
        });
      }
    } else if (!response.errors) {
      setIsLoading(false);
      // take user back to previous page on login success
      // if no prev page then take user back to root
      redirectUri
        ? window.location.replace(`${redirectUri}`)
        : window.location.replace(`/`);
    }
  };

  const handleOnLoginClick = e => {
    e.preventDefault();
    if (!email) {
      setEmailError('Email is required.');
      return;
    } else if (!EMAIL_VALIDATION_REGEX.test(email)) {
      setEmailError('Invalid email address.');
      return;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required.');
      return;
    } else {
      setPasswordError('');
    }

    if (password && email) {
      handleLogin(email, password);
    }
  };

  return (
    <>
      <Helmet title="Login" />
      <div className={styles.main}>
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>Log In to Dashboard</h1>
          {!!errorMessage && (
            <div className={styles.error}>
              <IconCaution className={styles.iconCaution} />
              <div>{errorMessage}</div>
            </div>
          )}
        </div>
        {isLoading ? (
          <LoadingSpinner isAbsolute={false} withWrapper={false} />
        ) : (
          <form className={styles.card}>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                errorText={emailError}
                label="Email Address"
                onChange={setEmail}
                type="email"
                value={email}
                id="email"
              />
            </div>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                errorText={passwordError}
                label="Password"
                onChange={setPassword}
                type="password"
                value={password}
                id="password"
              />
            </div>
            <Button
              className={styles.loginButton}
              isFullWidth
              label="Log In"
              onClick={handleOnLoginClick}
              type="submit"
            />
            <Link
              to={`forgot-password${forgotPasswordRedirectUri}`}
              className={styles.forgotPassword}
            >
              Forgot Password?
            </Link>
            <Terms />
          </form>
        )}
      </div>
    </>
  );
};

export default Login;
