import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { menuItemType } from 'helpers/prop-types';

import ToggleButton from 'components/ToggleButton';

import styles from './styles.module.scss';

function MenuItem(props) {
  const {
    onSelectItem,
    onOpenModal,
    item,
    isAnimateOnDelete,
    showModifiers,
    handleToggleHideItem,
    dataTestId
  } = props;
  const {
    id,
    image,
    meta_id: metaId,
    name,
    price,
    description,
    is_visible: isVisible
  } = item;
  const [isAnimated, setIsAnimated] = useState();
  const itemId = metaId || id;
  const showItem = isAnimated || isVisible;
  let itemTimeout;

  useEffect(() => {
    return () => {
      clearTimeout(itemTimeout);
    };
  }, []);

  function onToggleHideItem() {
    isAnimateOnDelete && setIsAnimated(true);

    itemTimeout = setTimeout(
      () => {
        isAnimateOnDelete && setIsAnimated();
        handleToggleHideItem(itemId, isVisible);
      },
      isAnimateOnDelete ? 700 : 0
    );
  }

  return (
    <li
      className={classNames(styles.itemContainer, {
        [styles.clickable]: !showModifiers,
        [styles.animate]: isAnimateOnDelete && isAnimated
      })}
      onClick={() => {
        !showModifiers
          ? onSelectItem({ id: metaId || id, name, image })
          : onOpenModal(name, description);
      }}
    >
      <div className={styles.flexWrapper}>
        <div className={styles.itemDetails}>
          <div className={styles.name} data-testid={dataTestId}>
            {name}
          </div>
          <div
            className={classNames(styles.descriptionContainer, {
              [styles.shortWidth]: !showModifiers
            })}
          >
            <span className={styles.description}>{description}</span>
          </div>
          <div className={styles.price}>{formatMoney(price)}</div>
        </div>
        {showModifiers ? (
          <ToggleButton
            className={styles.togglePosition}
            toggleValue={showItem}
            onToggle={onToggleHideItem}
            itemId={itemId}
          />
        ) : image ? (
          <img
            src={image.cropped_url}
            className={styles.photoImg}
            alt="preview"
          />
        ) : (
          <div className={styles.circlePlus} />
        )}
      </div>
    </li>
  );
}

MenuItem.propTypes = {
  dataTestId: PropTypes.string,
  handleToggleHideItem: PropTypes.func,
  isAnimateOnDelete: PropTypes.bool,
  item: menuItemType,
  onOpenModal: PropTypes.func,
  onSelectItem: PropTypes.func,
  showModifiers: PropTypes.bool
};

export default MenuItem;
