import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '@chownow/cocina-react-banner';
import PageIntro from 'containers/Onboarding/PageIntro';
import PageFooterSalesTax from 'containers/Onboarding/PageFooter/PageFooterSalesTax';
import ErrorCard from 'containers/Onboarding/ErrorCard';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import { useGetSalesTax, usePutSalesTax } from 'api/onboarding/salesTaxApis';
import { getSearchParam } from 'helpers/url';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import { formatSalesTax, getSalesTax } from 'helpers/onboarding/salesTax';
import { salesTaxValidator } from 'helpers/onboarding/salesTaxValidator';
import pictogram from 'images/pictograms/picto-clipboard-lg.svg';
import { useGetProgress, usePostProgress } from 'api/onboarding/progressApis';
import {
  OnboardingWizardPages,
  OnboardingProgressStatus,
  WizardNavPagePaths,
} from 'types/progress';
import Restaurant from 'types/restaurant';

import SalesTaxInput from './SalesTaxInput';
import LoadingState from '../LoadingState';
import { useWizardNavigationContext } from '../WizardNavigationContext';
import { getNavUrls } from '../helpers';

const ERROR_MESSAGE = 'There was an issue - please try again.';

interface SalesTaxContainerProps {
  restaurant: Restaurant;
}

const SalesTax: FC<SalesTaxContainerProps> = ({ restaurant }) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const {
    wizardPages,
    isWizNavProgressRequestLoading,
    hasWizNavProgressRequestError,
  } = useWizardNavigationContext();
  const restaurantId = restaurant.id;
  const restaurantUuid = restaurant.sfdc_restaurant_uuid;
  const homepageUrl = `/restaurant/${restaurantId}`;
  const navigate = useNavigate();
  const [hasSyncError, setHasSyncError] = useState<boolean>(false);
  const [salesTaxInputValue, setSalesTaxInputValue] = useState<string>();
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [hasPresubmittedSalesTax, setHasPresubmittedSalesTax] =
    useState<boolean>(false);
  const [invalidSalesTaxMessage, setInvalidSalesTaxMessage] =
    useState<string>('');
  const [isErrorBannerDisplayed, setIsErrorBannerDisplayed] =
    useState<boolean>(false);
  const { hasGetRequestError, isGetRequestLoading, getRequestResponse } =
    useGetSalesTax(restaurantId);
  const { isPutRequestLoading, putSalesTax } = usePutSalesTax();
  const { isPostProgressRequestLoading, postOnboardingProgress } =
    usePostProgress();
  const {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
  } = useGetProgress(restaurantUuid);

  let content;

  useEffect(() => {
    setHasPresubmittedSalesTax(
      !isFormCompleted && !!getSalesTax(getRequestResponse)
    );
  }, [isFormCompleted, getRequestResponse]);

  useEffect(() => {
    if (getSalesTax(getRequestResponse)) {
      setSalesTaxInputValue(getSalesTax(getRequestResponse));
    }
  }, [getRequestResponse]);

  // Once the GET requests for the sales tax and progress load,
  // we need to check if they are out of sync and fix if necessary
  useEffect(() => {
    const syncProgress = async () => {
      if (
        restaurantUuid &&
        !isGetRequestLoading &&
        !isGetProgressRequestLoading &&
        !hasGetRequestError &&
        !hasGetProgressRequestError &&
        !isWizNavProgressRequestLoading &&
        !hasWizNavProgressRequestError &&
        getSalesTax(getRequestResponse) &&
        getProgressRequestResponse?.tax_rate?.status !== 'completed'
      ) {
        const hasError = await postOnboardingProgress(
          restaurantUuid,
          OnboardingWizardPages.TAX_RATE,
          OnboardingProgressStatus.COMPLETED
        );

        if (hasError) {
          setHasSyncError(true);
        } else {
          const { nextUrl } = getNavUrls(
            WizardNavPagePaths.SALES_TAX,
            wizardPages
          );

          if (nextUrl) navigate(isComingFromHomepage ? homepageUrl : nextUrl);
        }
      }
    };

    void syncProgress();
  }, [
    isGetRequestLoading,
    isGetProgressRequestLoading,
    isWizNavProgressRequestLoading,
  ]);

  const validate = (value: string) => {
    if (value.length === 0) {
      setInvalidSalesTaxMessage('');
      setIsFormCompleted(false);
    } else {
      const validationErrorMessage = salesTaxValidator(value);

      setInvalidSalesTaxMessage(validationErrorMessage);
      setIsFormCompleted(!validationErrorMessage);
    }
  };

  const handleChange = (inputValue: string) => {
    setSalesTaxInputValue(inputValue);
    validate(inputValue);
  };

  const handleSave = async () => {
    let hasPutRequestError = false;

    if (salesTaxInputValue) {
      hasPutRequestError = await putSalesTax(
        restaurantId,
        formatSalesTax(salesTaxInputValue)
      );

      if (hasPutRequestError) {
        setIsErrorBannerDisplayed(true);
        window.scrollTo(0, 0);
      }
    }

    return hasPutRequestError;
  };

  const hasError =
    hasGetRequestError ||
    hasGetProgressRequestError ||
    hasSyncError ||
    hasWizNavProgressRequestError;

  const isLoading =
    isGetRequestLoading ||
    isGetProgressRequestLoading ||
    isWizNavProgressRequestLoading;

  if (hasError) {
    content = <ErrorCard />;
  } else if (isLoading) {
    content = <LoadingState />;
  } else {
    content = (
      <SalesTaxInput
        invalidSalesTaxMessage={invalidSalesTaxMessage}
        salesTaxInputValue={salesTaxInputValue}
        onInputChange={handleChange}
      />
    );
  }

  return (
    <>
      {isErrorBannerDisplayed && (
        <Banner variant="caution">{ERROR_MESSAGE}</Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Clipboard"
          title={ONB_WIZARD_INTRO.salesTaxTitle}
          description={ONB_WIZARD_INTRO.salesTaxDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterSalesTax
            isLoading={isPutRequestLoading || isPostProgressRequestLoading}
            isFormCompleted={isFormCompleted}
            hasPresubmittedSalesTax={hasError ? false : hasPresubmittedSalesTax}
            onSave={handleSave}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default SalesTax;
