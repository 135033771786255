import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { DropdownButton, DropdownButtonItem } from '@chownow/cn-web-components';
import { useUpdateOrderStatus } from 'api/orders/orderDetails';

import breakpoints from 'helpers/breakpoints';

import { ReactComponent as CheckIcon } from 'images/icon-badge-check.svg';
import { ReactComponent as UnavailableIcon } from 'images/icon-unavailable.svg';

import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';

import MobileAcknowledgeMenu from './components/MobileAcknowledgeMenu';
import { AcknowledgeIcon } from './styles';
import AcceptOrderModal from './components/AcceptOrderModal';
import AcceptOrderAheadModal from './components/AcceptOrderAheadModal';
import CancelOrderModal from './components/CancelOrderModal';

interface AcknowledgeMenuProps {
  orderDetails: OrderDetailsV6GetSuccessResponse;
  orderId?: string;
  onError: (message: string) => void;
  onSuccess: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}

const AcknowledgeMenu: FC<AcknowledgeMenuProps> = ({
  orderDetails,
  orderId,
  onError,
  onSuccess,
  setIsLoading,
}) => {
  const [shouldShowCancelOrderModal, setShouldShowCancelOrderModal] =
    useState<boolean>(false);
  const [shouldDisplayAcceptModal, setShouldDisplayAcceptModal] =
    useState<boolean>(false);
  const [
    shouldDisplayAcceptOrderAheadModal,
    setShouldDisplayAcceptOrderAheadModal,
  ] = useState<boolean>(false);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.mdMin });
  const isOrderAhead = orderDetails?.is_order_ahead;
  const isDineIn = orderDetails?.fulfill_method === 'dine_in';
  const { isUpdateOrderStatusRequestLoading, updateOrderStatus } =
    useUpdateOrderStatus();

  const handleCancelOrderSelection = () => {
    if (shouldDisplayAcceptModal) {
      setShouldDisplayAcceptModal(false);
    }

    setShouldShowCancelOrderModal(true);
  };

  const handleAcceptDineInOrderSelection = async () => {
    if (orderId) {
      const errorMessage = await updateOrderStatus(
        orderId,
        'accept',
        parseInt('0', 10)
      );

      if (errorMessage) {
        onError(errorMessage);
      } else {
        // If request is successful, re-fetch the order details response to get the updated order status
        await onSuccess();
      }
    }
  };

  const handleAcceptOrderSelection = () => {
    if (shouldShowCancelOrderModal) {
      setShouldShowCancelOrderModal(false);
    }

    setShouldDisplayAcceptModal(true);
  };

  const handleAcceptOrderAheadSelection = () => {
    if (shouldShowCancelOrderModal) {
      setShouldShowCancelOrderModal(false);
    }

    setShouldDisplayAcceptOrderAheadModal(true);
  };

  const handleOnError = (message: string) => {
    onError(message);

    // Close the modals if there is an error
    if (shouldDisplayAcceptModal) {
      setShouldDisplayAcceptModal(false);
    }

    if (shouldDisplayAcceptOrderAheadModal) {
      setShouldDisplayAcceptOrderAheadModal(false);
    }

    if (shouldShowCancelOrderModal) {
      setShouldShowCancelOrderModal(false);
    }
  };

  const handleOrderClick = () => {
    if (isDineIn) {
      void handleAcceptDineInOrderSelection();
    } else if (isOrderAhead) {
      handleAcceptOrderAheadSelection();
    } else {
      handleAcceptOrderSelection();
    }
  };

  // sets loading state on OrderDetails page when accepting dine-in order
  useEffect(() => {
    setIsLoading(isUpdateOrderStatusRequestLoading);
  }, [isUpdateOrderStatusRequestLoading, setIsLoading]);

  // NOTE: Any update to the secondaryCTAElement should also be reflected in MobileAcknowledgeMenu
  return (
    <>
      {shouldDisplayAcceptModal && (
        <AcceptOrderModal
          onClose={() => setShouldDisplayAcceptModal(false)}
          onError={handleOnError}
          onSuccess={onSuccess}
          orderId={orderId}
        />
      )}
      {shouldDisplayAcceptOrderAheadModal && (
        <AcceptOrderAheadModal
          onClose={() => setShouldDisplayAcceptOrderAheadModal(false)}
          onError={handleOnError}
          onSuccess={onSuccess}
          orderId={orderId}
          requestedFulfillTime={
            orderDetails?.fulfillment_times?.requested_fulfillment
          }
        />
      )}
      {shouldShowCancelOrderModal && (
        <CancelOrderModal
          onClose={() => setShouldShowCancelOrderModal(false)}
          onError={handleOnError}
          onSuccess={onSuccess}
          orderId={orderId}
        />
      )}
      {isDesktop ? (
        <DropdownButton
          alignMenu="right"
          label="Accept or Cancel"
          size="medium"
        >
          <DropdownButtonItem onClick={handleOrderClick}>
            <AcknowledgeIcon>
              <CheckIcon />
            </AcknowledgeIcon>
            Accept Order
          </DropdownButtonItem>
          <DropdownButtonItem onClick={handleCancelOrderSelection}>
            <AcknowledgeIcon>
              <UnavailableIcon />
            </AcknowledgeIcon>
            Cancel Order
          </DropdownButtonItem>
        </DropdownButton>
      ) : (
        <DropdownButton label="Accept or Cancel" size="medium">
          <MobileAcknowledgeMenu
            onAccept={handleOrderClick}
            onCancel={handleCancelOrderSelection}
          />
        </DropdownButton>
      )}
    </>
  );
};

export default AcknowledgeMenu;
