import styled from 'styled-components';
import { space } from 'styles/variables';

export const LoadingStateWrapper = styled.div`
  margin: auto 0;
`;

export const LoadingContent = styled.div`
  height: 48px;
  margin: ${space.x5} 0;
  position: relative;
`;
