import React, { useState, useRef, useEffect, FC } from 'react';
import { ReactComponent as IconClose } from 'images/icon-close.svg';

import {
  Card,
  ClickCapture,
  CloseButton,
  Container,
  Message,
  Row,
  StyledButton,
  Title,
} from './styles';

interface TooltipProps {
  alignment?: 'bottom' | 'top' | 'right' | 'left';
  disabled?: boolean;
  title: string;
  message: string;
  children: string;
}

const Tooltip: FC<TooltipProps> = ({
  alignment = 'bottom',
  disabled = false,
  title,
  message,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const isVerticalAligned = alignment === 'top' || alignment === 'bottom';
  const tooltipRef = useRef<HTMLElement>();

  useEffect(() => {
    if (!disabled) {
      const elemHeight = tooltipRef.current?.offsetHeight;

      setTooltipHeight(elemHeight || 0);
    }
  }, [children, disabled]);

  return (
    <Container>
      <ClickCapture id="click-capture" onClick={() => setIsVisible(false)} $isVisible={isVisible} />
      <StyledButton
        onClick={() => {
          setIsVisible(true);
        }}
        type="button"
        variant="linkSecondary"
        label={children}
        size="small"
        $isVisible={isVisible}
      />
      {!disabled && (
        <Card
          ref={tooltipRef}
          $isVisible={isVisible}
          style={{
            left: isVerticalAligned ? -4 : 'unset',
            right: isVerticalAligned ? -4 : 'unset',
            top: isVerticalAligned ? 'unset' : 0,
            bottom: isVerticalAligned ? 'unset' : 0,
            [alignment]: `-${tooltipHeight + 10}px`,
          }}
        >
          <Row>
            <div>
              <Title>{title}</Title>
              <Message>{message}</Message>
            </div>
            <CloseButton type="button" onClick={() => setIsVisible(false)}>
              <IconClose height={16} width={16} />
            </CloseButton>
          </Row>
        </Card>
      )}
    </Container>
  );
};

export default Tooltip;
