import { useEffect, useState } from 'react';
import Restaurant from 'types/restaurant';
import { StripeStatusResponse, StripeVerificationResponse } from 'types/stripe';
import { request } from 'helpers/api';

/**
 * Get Stripe status for restaurant location
 * @param restaurantId - ChowNow Restaurant ID
 * @param statusSearchParam - "status" query string param value
 */
export const useGetStripeStatus = (restaurantId: string, statusSearchParam: string | null) => {
  const [isGetStripeStatusRequestLoading, setIsGetStripeStatusRequestLoading] =
    useState<boolean>(true);
  const [hasGetStripeStatusRequestError, setHasGetStripeStatusRequestError] =
    useState<boolean>(false);
  const [getStripeStatusRequestResponse, setGetStripeStatusRequestResponse] =
    useState<StripeStatusResponse>();

  useEffect(() => {
    const getStripeStatus = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: StripeStatusResponse = await request(
          `restaurant/${restaurantId}/stripe-account`
        );

        setGetStripeStatusRequestResponse(response);
      } catch (err) {
        setHasGetStripeStatusRequestError(true);
      } finally {
        setIsGetStripeStatusRequestLoading(false);
      }
    };

    if (statusSearchParam === 'success' || statusSearchParam === 'failure') {
      setHasGetStripeStatusRequestError(false);
      setIsGetStripeStatusRequestLoading(false);
    } else {
      getStripeStatus().catch(() => {
        /* do nothing */
      });
    }
  }, [restaurantId, statusSearchParam]);

  return {
    hasGetStripeStatusRequestError,
    isGetStripeStatusRequestLoading,
    getStripeStatusRequestResponse,
  };
};

/**
 * Get Stripe verification url for restaurant location to
 * activate their Stripe account
 */
export const useGetStripeVerificationUrl = () => {
  const [isGetStripeVerificationUrlRequestLoading, setIsGetStripeVerificationUrlRequestLoading] =
    useState<boolean>(false);
  const [hasGetStripeVerificationUrlRequestError, setHasGetStripeVerificationUrlRequestError] =
    useState<boolean>(false);

  const getStripeVerificationUrl = async (restaurant: Restaurant, urlPath: string) => {
    const returnUrl = `${process.env.PUBLIC_URL}restaurant/${restaurant?.id}/${urlPath}`;
    let response: StripeVerificationResponse | undefined;

    setIsGetStripeVerificationUrlRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      response = await request(
        `restaurant/${restaurant?.id}/stripe-verification?return_url=${returnUrl}`
      );
    } catch (err) {
      setHasGetStripeVerificationUrlRequestError(true);
    } finally {
      setIsGetStripeVerificationUrlRequestLoading(false);
    }

    return response;
  };

  return {
    hasGetStripeVerificationUrlRequestError,
    isGetStripeVerificationUrlRequestLoading,
    getStripeVerificationUrl,
  };
};
