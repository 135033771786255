import {
  ManagedDeliveryWithPickupAndDeliveryTimes,
  ManagedDeliveryWithoutPickupAndDeliveryTimes,
  RefundInfo,
} from 'types/order-details';

export interface Order {
  created_at: string;
  delivery_instructions: string | null;
  estimated_arrival: string;
  estimated_pickup: string;
  fulfill_method: string;
  grabbed_by: string;
  is_order_ahead: boolean;
  managed_delivery:
    ManagedDeliveryWithPickupAndDeliveryTimes |
    ManagedDeliveryWithoutPickupAndDeliveryTimes |
    null;
  original_estimated_arrival: string;
  product_source: string;
  refund_info: RefundInfo | null;
  requested_fulfillment: string;
  status: string;
  table_name: string;
  updated_at: string;
  vehicle: string;
}

export enum FormattedFulfillMethods {
  curbside = 'Curbside',
  delivery = 'Delivery',
  dine_in = 'Dine-in',
  pickup = 'Pickup',
}

export enum OrderStatus {
  accepted = 'Accepted',
  canceled = 'Canceled',
  submitted = 'Submitted',
}
