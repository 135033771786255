import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as IconDropdown } from 'images/icon-dropdown.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';

import breakpoints from 'helpers/breakpoints';

import SlideInDown from 'components/Animations/SlideInDown';
import SlideUpIn from 'components/Animations/SlideUpIn';
import styles from './styles.module.scss';

// This is the new redesigned dropdown menu, which is slightly different from components/DropDown.
// The original DropDown component should be deprecated once this redesign is finalized and rolled out.

function DropDownMenu(props) {
  const { children, label, secondary, modalHeader, disabled } = props;
  const [isDropdownToggled, setIsDropdownToggled] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.mdMin });

  function toggleDropdown() {
    setIsDropdownToggled(!isDropdownToggled);
  }

  return (
    <div>
      <div
        className={classNames(styles.dropdownContainer, {
          [styles.dropdownContainerBlack]: secondary,
          [styles.dropdownDisabled]: disabled,
        })}
        onClick={toggleDropdown}
      >
        <div>
          <span
            className={classNames(styles.dropdownHeader, {
              [styles.dropdownHeaderBlack]: secondary,
            })}
          >
            {label}
          </span>
        </div>
        <IconDropdown
          className={classNames(styles.iconDropdown, {
            [styles.dropdownOpen]: isDropdownToggled,
            [styles.iconDropdownSecondary]: secondary,
          })}
        />

        {isDropdownToggled && isDesktop && (
          <SlideInDown className={styles.dropdownWrapper}>
            <div className={styles.dropdownContent} id="categoryDropdown">
              {children}
            </div>
          </SlideInDown>
        )}
      </div>
      <div
        className={classNames(styles.dropdownOverlay, {
          [styles.visible]: isDropdownToggled,
        })}
        onClick={toggleDropdown}
      />
      {isDropdownToggled && !isDesktop && (
        <SlideUpIn className={styles.slideWrapper}>
          <div className={styles.dropdownContent} id="categoryDropdown">
            <div className={styles.modalHeader}>{modalHeader}</div>
            <button className={styles.closeIcon} onClick={toggleDropdown}>
              <IconClose />
            </button>
            <div className={styles.mobileDropdownWrapper}>{children}</div>
          </div>
        </SlideUpIn>
      )}
    </div>
  );
}

DropDownMenu.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  modalHeader: PropTypes.string,
  secondary: PropTypes.bool,
};

export default DropDownMenu;
