import React, { useState, useContext, useEffect } from 'react';
import Banner from '@chownow/cocina-react-banner';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import PageIntro from 'containers/Onboarding/PageIntro';
import PageFooterShareMenu from 'containers/Onboarding/PageFooter/PageFooterShareMenu';
import AddMenuCard from 'containers/Onboarding/ShareMenu/AddMenuCard';
import MenuCard from 'containers/Onboarding/ShareMenu/MenuCard';
import ErrorCard from 'containers/Onboarding/ErrorCard';
import ShareMenuModal from 'components/Modals/ShareMenuModal';
import { ModalContext } from 'context/ModalContext';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import pictogram from 'images/pictograms/picto-menu-lg.svg';
import { useGetMenuAssets, useGetMenuLinks } from 'api/onboarding';

import Restaurant from 'types/restaurant';
import LoadingState from '../LoadingState';
import { useWizardNavigationContext } from '../WizardNavigationContext';
import { getMenuAssetsSuccess, getMenuItemsCount } from './helpers';
import { MenuItems, Separator } from './styles';

const ERROR_MESSAGE =
  'You’ve reached the maximum number of menus you can upload. Reach out to your account coordinator to share additional menus.';
const REVIEW_MESSAGE =
  'Your menu is being reviewed. Reach out to your account coordinator to make any updates.';

interface ShareMenuProps {
  restaurant: Restaurant;
}

const ShareMenu = ({ restaurant }: ShareMenuProps) => {
  const uuid = restaurant.sfdc_restaurant_uuid;
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();
  const { showModal } = useContext(ModalContext);
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [isMenusMaxReached, setIsMenusMaxReached] = useState<boolean>(false);
  const [isMenusInReview, setIsMenusInReview] = useState<boolean>(false);
  const {
    isGetRequestMenuAssetsLoading,
    hasGetRequestMenuAssetsError,
    getRequestMenuAssetsResponse,
    getMenuAssets,
  } = useGetMenuAssets();
  const {
    isGetRequestMenuLinksLoading,
    hasGetRequestMenuLinksError,
    getRequestMenuLinksResponse,
    getMenuLinks,
  } = useGetMenuLinks();

  let content;

  const getMenuLinksAndAssets = () => {
    void getMenuAssets(uuid);
    void getMenuLinks(uuid);
  };

  useEffect(() => {
    getMenuLinksAndAssets();
  }, []);

  useEffect(() => {
    const menuItemsCount = getMenuItemsCount(
      getRequestMenuAssetsResponse,
      getRequestMenuLinksResponse
    );

    if (menuItemsCount > 0) {
      setIsMenusInReview(true);
      setIsFormCompleted(true);
    }

    // show error banner if either menu links or assets with uploaded upload_status count reaches 5
    if (menuItemsCount >= 5) {
      setIsMenusMaxReached(true);
    }
  }, [getRequestMenuAssetsResponse, getRequestMenuLinksResponse]);

  const handleAddMenuClick = () => {
    showModal(ShareMenuModal, {
      uuid,
      showCloseIcon: true,
      getMenuLinksAndAssets,
      getRequestMenuAssetsResponse,
    });
  };

  const hasMenuAssetsOrLinks =
    !!getMenuAssetsSuccess(getRequestMenuAssetsResponse)?.length ||
    !!getRequestMenuLinksResponse?.length;

  const hasError =
    hasGetRequestMenuLinksError ||
    hasGetRequestMenuAssetsError ||
    hasWizNavProgressRequestError;

  const isLoading =
    isGetRequestMenuAssetsLoading ||
    isGetRequestMenuLinksLoading ||
    isWizNavProgressRequestLoading;

  if (hasError) {
    content = <ErrorCard />;
  } else if (isLoading) {
    content = <LoadingState />;
  } else {
    content = (
      <>
        {!isMenusMaxReached && (
          <AddMenuCard onAddMenuClick={handleAddMenuClick} />
        )}
        {hasMenuAssetsOrLinks && !isMenusMaxReached && <Separator />}
        {hasMenuAssetsOrLinks && (
          <MenuItems>
            {getRequestMenuAssetsResponse?.length > 0 &&
              getMenuAssetsSuccess(getRequestMenuAssetsResponse)?.map(asset => (
                <li key={asset.created_at}>
                  <MenuCard
                    key={asset.created_at}
                    fileName={asset.filename}
                    fileNotes={asset.notes}
                  />
                </li>
              ))}
            {getRequestMenuLinksResponse?.length > 0 &&
              getRequestMenuLinksResponse?.map(link => (
                <li key={link.created_at}>
                  <MenuCard
                    key={link.created_at}
                    fileName={link.url}
                    fileNotes={link.notes || ''}
                  />
                </li>
              ))}
          </MenuItems>
        )}
      </>
    );
  }

  return (
    <>
      {isMenusMaxReached && !hasError && (
        <Banner variant="caution">{ERROR_MESSAGE}</Banner>
      )}
      {isMenusInReview && !hasError && (
        <Banner variant="success">{REVIEW_MESSAGE}</Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Menu"
          title={ONB_WIZARD_INTRO.shareMenuTitle}
          description={ONB_WIZARD_INTRO.shareMenuDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterShareMenu
            isFormCompleted={hasError ? false : isFormCompleted}
            isLoading={isLoading}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default ShareMenu;
