import React, { FC } from 'react';

import { Button, Table, TableHeader } from '@chownow/cn-web-components';
import { MenuError } from 'types/pos/integration-errors';

import {
  Content,
  DisplayText,
  ErrorModal,
  Footer,
  Heading,
  MenuTitle,
} from './styles';
import { generateFileData } from './helpers';

interface ToastMenuErrorModalProps {
  menuError: MenuError;
  setMenuError: (menuError: MenuError | undefined) => void;
}

const ToastMenuErrorModal: FC<ToastMenuErrorModalProps> = ({
  menuError,
  setMenuError,
}) => {
  const downloadFile = () => {
    const data = generateFileData(menuError);
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: 'text/csv' });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = `${menuError.menu_name}-${menuError.display_text}.csv`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <ErrorModal onModalClose={() => setMenuError(undefined)}>
      <Heading>
        <MenuTitle>{menuError.menu_name}</MenuTitle>
        <DisplayText>{menuError.display_text}</DisplayText>
      </Heading>
      <Content>
        <Table>
          <thead>
            <tr>
              {Object.keys(menuError.error_items[0]).map(key => (
                <TableHeader key={key}>{key.replaceAll('_', ' ')}</TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {menuError.error_items.map(item => (
              <tr key={JSON.stringify(item)}>
                {Object.values(item).map(value => (
                  <td key={JSON.stringify(value)}>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Content>
      <Footer>
        <Button label="Download CSV" onClick={downloadFile} />
      </Footer>
    </ErrorModal>
  );
};

export default ToastMenuErrorModal;
