const HELP_ICON_CHECK_TIMEOUT = 50;

export const hideHelpIcon = () => {
  const fab = document.getElementById('esw-fab');
  if (!fab) {
    setTimeout(hideHelpIcon, HELP_ICON_CHECK_TIMEOUT);
    return;
  }
  fab.style.display = 'none';
};

export const showHelpIcon = () => {
  const fab = document.getElementById('esw-fab');
  if (!fab) {
    setTimeout(showHelpIcon, HELP_ICON_CHECK_TIMEOUT);
    return;
  }
  fab.style.display = 'block';
};
