import React from 'react';
import { ReactComponent as BadgeInfo } from 'images/icon-badge-info-md.svg';
import { ContentLeft, Status, Subtext } from './styles';

const NoAccountContent = () => (
  <ContentLeft>
    <BadgeInfo />
    <Status>You do not have a Stripe account</Status>
    <Subtext data-testid="onboarding-support-email-text">
      Reach out to <a href="mailto:onboarding@chownow.com">onboarding@chownow.com</a> for support.
    </Subtext>
  </ContentLeft>
);

export default NoAccountContent;
