import { Dispatch, SetStateAction } from 'react';
import { urlRegex } from 'helpers/validators';

export const websiteAccessAdminUrlValidator = (
  websiteAdminUrl: string,
  setWebsiteAdminUrlError: Dispatch<SetStateAction<string>>
) => {
  if (!websiteAdminUrl) {
    setWebsiteAdminUrlError('Please enter a website admin URL');

    return true;
  } else if (!urlRegex.test(websiteAdminUrl)) {
    setWebsiteAdminUrlError('Website admin URL is invalid');

    return true;
  }

  return false;
};
