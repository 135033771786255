import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, screen, space } from 'styles/variables';
import { RadioButton } from '@chownow/cn-web-components';

export const OrderTypeCardWrapper = styled.div<{ $hasSelectedOrderType: boolean }>`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $hasSelectedOrderType }) => ($hasSelectedOrderType ? space.x5 : '142px')};
  padding: ${space.x3};

  h2 {
    font: ${label.semi.x16};
    text-align: left;
  }

  @media (min-width: ${screen.xsMin}) {
    padding: ${space.x5};
  }
`;

export const StyledRadioButton = styled(RadioButton)`
  width: 100%;

  @media (min-width: 728px) {
    max-width: 300px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x2};
  justify-content: center;
  margin-top: ${space.x3};
  width: 100%;
`;
