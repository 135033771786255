export enum DaysOfTheWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum HoursSection {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  PickupDelivery = 'PICKUP_DELIVERY', // When user selects same hours checkbox
}

export const INVALID_HOURS_MESSAGE = 'Please set all restaurant hours. Make sure to uncheck the days that your restaurant is closed.';

export const RESPONSE_ERROR_MESSAGE = 'There was an issue - please try again.';

export const REVIEW_MESSAGE =
  'Your restaurant hours are being reviewed. Reach out to your account coordinator to make any updates.';
