import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';

export const MenuCardContainer = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${space.x1};
  line-height: ${space.x3};
  margin-bottom: ${space.x3};
  padding: ${space.x3};
  text-align: left;
`;

export const FileName = styled.p`
  font: ${label.semi.x14};
  margin: 0;
  word-wrap: break-word;
`;

export const FileNotes = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.italic.x14};
  margin: ${space.x1} 0 0 0;
  word-wrap: break-word;
`;
