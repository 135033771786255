import styled from 'styled-components';
import { space } from 'styles/variables';
import { label } from 'styles/types';
import { semanticColors } from 'styles/colors';

import { cardStyles } from '../styles';

export const TopCardWrapper = styled.div`
  ${cardStyles};

  margin-bottom: ${space.x3};
`;

export const NoLogoText = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${label.semi.x14};
  margin: ${space.x3} 0 0;
`;
