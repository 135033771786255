import { useEffect } from 'react';

/**
 * Handler for common API errors
 * @param  {boolean} hasError - if API returned an error
 * @param  {function} callback - callback function for when hasError is true
 */
export default function useErrorHandler(hasError, callback) {
  useEffect(() => {
    if (hasError) {
      callback();
    }
  }, [hasError]);
}
