import React, { FC } from 'react';
import { WebsiteAccessResponse } from 'types/website-access';

import { CardWrapper } from './styles';
import { Data, Heading } from '../styles';
import SavedInfoCard from '../SavedInfoCard';
import {
  WebsiteAccessOptions,
  WEBSITE_ACCESS_CHOWNOW_OPTION,
  WEBSITE_ACCESS_RESTAURANT_PARTNER_OPTION,
  WEBSITE_ACCESS_THIRD_PARTY_OPTION,
} from '../../constants';

const ANSWERS = {
  [WebsiteAccessOptions.CHOWNOW]: WEBSITE_ACCESS_CHOWNOW_OPTION,
  [WebsiteAccessOptions.RESTAURANT_PARTNER]:
    WEBSITE_ACCESS_RESTAURANT_PARTNER_OPTION,
  [WebsiteAccessOptions.THIRD_PARTY_DEVELOPER]:
    WEBSITE_ACCESS_THIRD_PARTY_OPTION,
  undefined: 'An error occurred',
};

interface SavedWebsiteAccessCardProps {
  response: WebsiteAccessResponse;
}

const SavedWebsiteAccessCard: FC<SavedWebsiteAccessCardProps> = ({
  response,
}) => (
  <>
    <CardWrapper>
      <Heading>Who will be adding your ordering link to your website?</Heading>
      <Data>{ANSWERS[response?.responsible_party]}</Data>
    </CardWrapper>
    <CardWrapper $isBottomCard>
      <SavedInfoCard response={response} />
    </CardWrapper>
  </>
);

export default SavedWebsiteAccessCard;
