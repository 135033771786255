export enum IntegrationStatus {
  ACTIVE = 'ACTIVE',
  AVAILABLE = 'AVAILABLE',
  ERRORS = 'ERRORS',
  PAUSED = 'PAUSED',
}

export interface IntegrationsEmptyResponse {
  id?: string;
  name: string;
  authorization_url: string | null;
}

export type IntegrationsEmptyResponseArray = [IntegrationsEmptyResponse];

interface AuthInstructions {
  action_link: string;
  action_label: string;
  header: string;
  message: string;
}

export interface Integration {
  id?: string;
  name: string;
  authorization_url: string | null;
  created_at: string | null;
  updated_at: string | null;
  is_authorized: boolean;
  is_active: boolean;
  is_enabled: boolean;
  authorization_instructions: AuthInstructions | null;
}

export type IntegrationsListResponse = Array<Integration>;

export type Integrations403Error = {
  code: string;
  message: string;
  reason: string;
}

export interface Integrations403Response {
  id?: string;
  errors: Integrations403Error[];
}

export type Integrations403ResponseArray = [Integrations403Response];

export type IntegrationsSuccessResponse = IntegrationsListResponse | IntegrationsEmptyResponseArray;

export type IntegrationsResponse =
  IntegrationsSuccessResponse |
  Integrations403ResponseArray;

export const isIntegrations403Response =
  (response: IntegrationsResponse): response is Integrations403ResponseArray => (
    !!response &&
    response.length === 1 &&
    Object.prototype.hasOwnProperty.call(response[0], 'errors')
  );

export const isIntegrationsListResponse =
  (response: IntegrationsResponse | undefined): response is IntegrationsListResponse => (
    !!response &&
    Array.isArray(response) &&
    response.length > 0 &&
    !isIntegrations403Response(response)
  );

export const isIntegrationsSuccessResponse =
  (response: IntegrationsResponse | undefined): response is IntegrationsSuccessResponse => (
    !!response &&
    Array.isArray(response) &&
    !isIntegrations403Response(response)
  );

export const isIntegrationsEmptyWithIdResponse =
  (response: IntegrationsResponse | undefined): response is IntegrationsEmptyResponseArray => (
    !!response &&
    Array.isArray(response) &&
    response.length === 1 &&
    Object.keys(response[0]).length === 1 &&
    Object.prototype.hasOwnProperty.call(response[0], 'id')
  );
