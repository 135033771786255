import React, { useContext, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find, get } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { CatalogContext } from 'context/CatalogContext';
import { ModalContext } from 'context/ModalContext';
import { ITEM_TYPE } from 'helpers/constants';
import { getUrlParam } from 'helpers/url';
import { findSquareItemId } from 'helpers/catalog';
import useErrorHandler from 'hooks/useErrorHandler';
import {
  postModifierLink,
  removeModifierLink,
  postSizeLink,
  removeSizeLink,
} from 'api/square';

import DialogModal from 'components/Modals/DialogModal';
import LinkedStatus from 'components/LinkedStatus';
import CatalogList from 'components/CatalogList';
import ListDropdown from 'components/ListDropdown';
import SquareList from 'components/SquareList';

import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

const catalogModalRef = createRef();

export default function CatalogItemModal({
  item,
  toggleShowItem,
  unlinkItem,
  restaurantId,
}) {
  const { showModal } = useContext(ModalContext);
  const catalog = useContext(CatalogContext);

  const itemModifiers = item.modifiers || [];
  const defaultSize =
    find(item.serving_sizes, { display_order: 1 }) ||
    find(item.serving_sizes, { id: item.sizeId }) ||
    {};
  const defaultPrice = defaultSize.price ? defaultSize.price : item.price;
  const squareItemId = findSquareItemId(item);
  const linkedOrPartialItem = find(catalog.squareItems, { id: squareItemId });
  const sizesAndModifiers = [...item.serving_sizes, ...item.modifiers];
  const linkedItemStatus =
    item.x_id && !find(sizesAndModifiers, { x_id: null });
  const partialItemStatus =
    item.x_id && find(sizesAndModifiers, { x_id: null });

  const [isDragMode, setIsDragMode] = useState(false);
  const [catalogError, setCatalogError] = useState();

  useErrorHandler(catalogError, () => {
    showModal(DialogModal, {
      title: 'Something went wrong.',
      message: catalogError.message,
    });
  });

  async function linkModifier(SquareModifierId, CNModifierId) {
    // prevent linking size
    if (CNModifierId.split('_').length > 1) return;
    const response = await postModifierLink(
      catalog,
      restaurantId,
      SquareModifierId,
      CNModifierId
    );
    if (response.errors) {
      setCatalogError(get(response.errors[0], 'message'));
    }
  }

  async function unlinkModifier(modifierId) {
    const response = await removeModifierLink(
      catalog,
      restaurantId,
      modifierId
    );
    if (response.errors) {
      setCatalogError(get(response.errors[0], 'message'));
    }
  }

  async function linkSize(squareSizeId, CNSizeId) {
    // prevent linking modifier
    if (CNSizeId.split('_').length < 2) return;
    const response = await postSizeLink(
      catalog,
      restaurantId,
      squareSizeId,
      CNSizeId
    );
    if (response.errors) {
      setCatalogError(get(response.errors[0], 'message'));
    }
  }

  async function unlinkSize(sizeId) {
    const response = await removeSizeLink(catalog, restaurantId, sizeId);

    if (response.errors) {
      setCatalogError(get(response.errors[0], 'message'));
    }
  }

  function toggleDragMode(isDragging) {
    setIsDragMode(isDragging);
  }

  function getLinkedModifier(modifier) {
    let linkedMod;
    if (modifier.x_id) {
      find(catalog.squareItems, sqItem => {
        linkedMod = find(sqItem.modifiers, { id: modifier.x_id });
        if (linkedMod) return true;
      });
    }

    return linkedMod;
  }

  function getLinkedSize(size) {
    let linkedSize;
    if (size.x_id) {
      const servingSizeId = size.x_id.split('_')[1];
      find(catalog.squareItems, sqItem => {
        linkedSize = find(sqItem.serving_sizes, { id: servingSizeId });
        if (linkedSize) return true;
      });
    }

    return linkedSize;
  }

  function handleUnlinkItem() {
    toggleShowItem();
    unlinkItem(item.id);
  }

  return (
    <>
      <div className={styles.modalOverlay} onClick={() => toggleShowItem()} />
      <div
        className={classNames(styles.catalogItemModal, {
          [styles.unlinked]: !linkedOrPartialItem,
        })}
        ref={catalogModalRef}
      >
        <IconClose
          className={styles.iconClose}
          onClick={() => toggleShowItem()}
        />
        <h1 className={styles.heading}>{item.name}</h1>
        {item.description && (
          <p className={styles.description}>{item.description}</p>
        )}
        <div className={styles.price}>{formatMoney(defaultPrice || 0)}</div>
        <LinkedStatus
          unlinkItem={handleUnlinkItem}
          linkedOrPartialItem={linkedOrPartialItem}
          linkedItemStatus={linkedItemStatus}
          partialItemStatus={partialItemStatus}
        />
        <ListDropdown
          label="Sizes"
          isDropdown
          itemCount={item.serving_sizes.length}
        >
          <div
            className={classNames(styles.listWrapper, {
              [styles.singleColumn]: !linkedOrPartialItem,
            })}
          >
            {linkedOrPartialItem && (
              <SquareList
                itemType={ITEM_TYPE.size}
                isDragMode={isDragMode}
                items={linkedOrPartialItem.serving_sizes || []}
                toggleDragMode={toggleDragMode}
                linkItem={linkSize}
                CNItem={item}
                scrollParent={catalogModalRef}
              />
            )}
            <CatalogList itemCount={item.serving_sizes.length} label="Sizes">
              {item.serving_sizes.map(size => {
                return (
                  <li
                    id="cn-item"
                    data-id={size.id}
                    key={size.id}
                    className={classNames(styles.itemContainer, {
                      [styles.dragMode]: isDragMode && !size.x_id,
                    })}
                  >
                    <div className={styles.itemName}>{size.size}</div>
                    <div className={styles.itemPrice}>
                      {formatMoney(size.price || 0)}
                    </div>
                    <LinkedStatus
                      // Prevent unlinking on default sizes
                      unlinkItem={size.id !== item.id ? unlinkSize : undefined}
                      pos={size.pos}
                      linkedOrPartialItem={getLinkedSize(size)}
                      linkedItemStatus={linkedItemStatus}
                      CNId={size.id}
                    />
                  </li>
                );
              })}
            </CatalogList>
          </div>
        </ListDropdown>
        <ListDropdown
          label="Modifiers"
          isDropdown
          itemCount={itemModifiers.length}
        >
          <div
            className={classNames(styles.listWrapper, {
              [styles.singleColumn]: !linkedOrPartialItem,
            })}
          >
            {linkedOrPartialItem && (
              <SquareList
                itemType={ITEM_TYPE.modifier}
                isDragMode={isDragMode}
                items={linkedOrPartialItem.modifiers || []}
                toggleDragMode={toggleDragMode}
                linkItem={linkModifier}
                CNItem={item}
                scrollParent={catalogModalRef}
              />
            )}
            <CatalogList itemCount={itemModifiers.length} label="Modifiers">
              {itemModifiers.map(modifier => {
                return (
                  <li
                    id="cn-item"
                    data-id={modifier.id}
                    key={modifier.id}
                    className={classNames(styles.itemContainer, {
                      [styles.dragMode]: isDragMode && !modifier.x_id,
                    })}
                  >
                    <div className={styles.itemName}>{modifier.name}</div>
                    <div className={styles.itemDetails}>
                      {modifier.categoryName}
                    </div>
                    <div className={styles.itemPrice}>
                      {formatMoney(modifier.price || 0)}
                    </div>
                    <LinkedStatus
                      pos={modifier.pos}
                      linkedOrPartialItem={getLinkedModifier(modifier)}
                      unlinkItem={unlinkModifier}
                      CNId={modifier.id}
                    />
                  </li>
                );
              })}
            </CatalogList>
          </div>
        </ListDropdown>
      </div>
    </>
  );
}

CatalogItemModal.propTypes = {
  item: PropTypes.shape(),
  toggleShowItem: PropTypes.func,
  unlinkItem: PropTypes.func,
};
