import React, { useContext } from 'react';
import classNames from 'classnames';

import { UserContext } from 'context/UserContext';
import { orderDetailsType } from 'helpers/prop-types';
import { ORDER_STATUS, REFUND_STATUS } from 'helpers/constants';
import { getOrderDateTime } from 'helpers/dateTimeOld';
import StatusDot from '@chownow/cocina-react-statusdot';

import styles from './styles.module.scss';

function OrderInfo(props) {
  const {
    orderDetails: {
      grabbed_by,
      fulfill_method,
      fulfillment_times,
      status,
      is_order_ahead,
      refund_info,
    },
  } = props;
  const {
    user: { is_chownow_admin, is_super_admin },
  } = useContext(UserContext);
  const statusText =
    refund_info?.refund_status === REFUND_STATUS.automaticallyProcessed ||
    refund_info?.refund_status === REFUND_STATUS.processedManuallyByCs
      ? 'Refunded'
      : status;

  function getOrderGrabbedBy() {
    // Strip out everything except user email
    const grabbedByEmail =
      grabbed_by && grabbed_by.replace(/@[^@]+@[^@]*$/, '');

    // Target only device
    // tablet returns an empty string for device name, if grabbed_by ends
    // in @ without anything after, it is from tablet
    const grabbedByDevice = grabbed_by?.match(/[@]$/)
      ? 'tablet'
      : grabbed_by?.replace(/.*@/, '');

    // If email has chownow.com domain, hide email and return 'ChowNow team'
    const grabbedBy = /^[A-Za-z0-9._%+-]+@chownow.com$/.test(grabbedByEmail)
      ? 'ChowNow team'
      : grabbedByEmail;

    // only show chownow emails to super users and CN admins
    const grabbedByPermissionFilter =
      is_super_admin || is_chownow_admin ? grabbedByEmail : grabbedBy;

    const autoAccept = grabbed_by === 'CN Auto Accept';

    // if order status is canceled and there is no designated grabbed_by user,
    // that means that the order was automatically canceled due to inactivity.
    if (status === ORDER_STATUS.canceled && !grabbed_by) {
      return 'Order was automatically canceled due to time limit';
    } else if (grabbed_by) {
      return `${
        autoAccept ? '' : `${grabbedByPermissionFilter}`
      } via ${grabbedByDevice}
      on
      ${getOrderDateTime(fulfillment_times.updated_at)}`;
    } else {
      return '';
    }
  }

  return (
    <>
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Status</span>
        <div className={styles.orderStatus}>
          {/* TODO: when updating this component, only use the "variant" prop to set the color */}
          <StatusDot
            size="small"
            className={classNames(styles.statusDot, {
              [styles.statusAccepted]:
                statusText == ORDER_STATUS.accepted ||
                statusText === 'Refunded',
              [styles.statusSubmitted]: status === ORDER_STATUS.submitted,
              [styles.statusCanceled]: statusText === ORDER_STATUS.canceled,
            })}
          />
          {statusText}
        </div>
      </div>
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Time of Order</span>
        {getOrderDateTime(fulfillment_times.created_at)}
      </div>
      {fulfillment_times.estimated_pickup && (
        <div className={styles.infoRow}>
          <span className={styles.infoRowTitle}>Expected Time</span>
          <span>{getOrderDateTime(fulfillment_times.estimated_pickup)}</span>
        </div>
      )}

      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Last Action</span>
        <span className={styles.infoRowBody}>{getOrderGrabbedBy()}</span>
      </div>

      {!!is_order_ahead && (
        <div className={styles.infoRow}>
          <span className={styles.infoRowTitle}>Requested Time</span>
          <span>
            {getOrderDateTime(fulfillment_times.requested_fulfillment)}
          </span>
        </div>
      )}
      <div className={styles.infoRow}>
        <span className={styles.infoRowTitle}>Type:</span>
        {fulfill_method === 'dine_in' ? (
          'Dine-in'
        ) : (
          <span className={styles.capitalize}>{fulfill_method}</span>
        )}
      </div>
    </>
  );
}

OrderInfo.propTypes = {
  orderDetails: orderDetailsType,
};

export default OrderInfo;
