import { formatError, request } from 'helpers/api';

/**
 * Log in to dashboard
 * @param  {string} email - User account email
 * @param  {string} password - User password
 * @param  {string} uniqueKey - verification code sent to user's email to reset password
 */
export async function login({ email, password, uniqueKey }) {
  try {
    return await request(
      'admin/login',
      {
        email,
        password,
        unique_key: uniqueKey,
      },
      'POST'
    );
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Reset password
 * @param  {string} email - User account email to send verification code to
 * @param  {string} redirectUrl - Referring url that the user will be redirected to after login completes
 * @param  {boolean} activateAccount - False for regular reset password and true for the activate account flow
 */
export async function resetPassword(email, redirectUrl, activateAccount) {
  try {
    return await request(
      `admin/reset-password`,
      {
        email: email,
        redirect_url: redirectUrl || '',
        activate_account: activateAccount,
      },
      'POST'
    );
  } catch (err) {
    return formatError(err);
  }
}
