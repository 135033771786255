import React, { useState, FC, ChangeEvent, useEffect, useContext } from 'react';

import { usePostRefundOrder } from 'api/orders/refunds';
import { ModalContext } from 'context/ModalContext';

import { PartialRefundByItem } from 'types/refunds';

import {
  ConfirmPartialRefundModal,
  Header,
  Input,
  Label,
  StyledButton,
  Textarea,
} from './styles';

interface ConfirmRefundModalProps {
  payload: PartialRefundByItem;
  orderId: string;
  redirectUrl?: string;
  setErrorMessage: (message: string) => void;
  setSuccessMessage: (message: string) => void;
}

const ConfirmRefundModal: FC<ConfirmRefundModalProps> = ({
  payload,
  orderId,
  redirectUrl,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { hideModal } = useContext(ModalContext);
  const [formData, setFormData] = useState({ name: '', reason: '' });

  const {
    updateOrderStatusErrorMessage,
    isUpdateOrderStatusRequestLoading,
    refundOrder,
  } = usePostRefundOrder();

  useEffect(() => {
    if (updateOrderStatusErrorMessage) {
      setErrorMessage(updateOrderStatusErrorMessage);
      hideModal();
    }
  }, [updateOrderStatusErrorMessage]);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = async () => {
    const response = await refundOrder(orderId, {
      ...payload,
      ...formData,
      refund_type: 'partial_by_item',
    });

    if (response) {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        setSuccessMessage('You have successfully refunded your order.');
      }
      hideModal();
    }
  };

  const submit = () => {
    void handleOnSubmit();
  };

  return (
    <ConfirmPartialRefundModal>
      <Header>Confirm Refund</Header>
      <div>
        Please enter your name and reason for refund. A copy of the receipt will
        automatically be sent to the diner.
      </div>
      <Label>Approved By</Label>
      <Input
        placeholder="John Smith"
        name="name"
        value={formData.name}
        onChange={handleOnChange}
        maxLength={100}
      />
      <Label>Reason</Label>
      <Textarea
        placeholder="Description..."
        name="reason"
        value={formData.reason}
        onChange={handleOnChange}
        maxLength={255}
      />
      <StyledButton
        onClick={submit}
        disabled={!formData.name || !formData.reason}
        isLoading={isUpdateOrderStatusRequestLoading}
        label="Confirm Refund"
        isFullWidth
      />
    </ConfirmPartialRefundModal>
  );
};

export default ConfirmRefundModal;
