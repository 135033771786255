import React, { FC } from 'react';

interface IconBadgeInfoProps {
  className?: string;
  height?: string;
  width?: string;
  fill?: string;
}

export const IconBadgeInfo: FC<IconBadgeInfoProps> = ({
  className,
  height = '24',
  width = '24',
  fill = '#142138',
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1158_962)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#142138"
      />
      <path d="M11.25 9.75H12.75L12.75 17.25H11.25L11.25 9.75Z" fill={fill} />
      <path d="M11.25 6.75L12.75 6.75V8.25H11.25V6.75Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_1158_962">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
