import React, { FC } from 'react';
import { ButtonSet, Button } from '@chownow/cn-web-components';
import { Filters, OrdersListResponse } from 'types/orders-list';
import { ReactComponent as IconCaretRight } from 'images/icon-caret-right-16.svg';
import { ReactComponent as IconCaretLeft } from 'images/icon-caret-left-16.svg';

import {
  StyledPagination,
} from './styles';

interface PaginationProps {
  fetchOrdersList: (filters: Filters, url?: string) => void;
  filters: Filters;
  orders: OrdersListResponse;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const Pagination: FC<PaginationProps> = ({
  fetchOrdersList,
  filters,
  orders,
  setFilters,
}) => {
  const handlePagination = (direction: 'previous' | 'next') => {
    const url = (orders)[direction];

    if (typeof url !== 'string') {
      return;
    }

    // Get offset value from the URL in orders.next or orders.previous
    const offsetMatch = url.match(/offset=(\d+)/);

    // Check if matches are found
    const offset = offsetMatch ? offsetMatch[1] : '';

    fetchOrdersList(filters, url);
    setFilters({ ...filters, offset });
  };

  return (
    <StyledPagination>
      <ButtonSet size="small">
        <Button
          icon={IconCaretLeft}
          disabled={!orders.previous}
          onClick={() => handlePagination('previous')}
        />
        <Button
          icon={IconCaretRight}
          disabled={!orders.next}
          onClick={() => handlePagination('next')}
        />
      </ButtonSet>
    </StyledPagination>
  );
};

export default Pagination;
