/**
 * @param {Event object} e Event fired that causes this function to be executed.
 * @param {function} onClick The callback function to perform when the key is pressed.
 * @returns  Nothing. This is a void function that only performs a task.
 */
export function handleKeyDown(e, onClick) {
  const enterKey = 13;
  const spaceKey = 32;

  if (e.keyCode === enterKey || e.keyCode === spaceKey) {
    e.preventDefault();
    onClick(e);
  }
}
