import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'helpers/breakpoints';
import { Button } from '@chownow/cn-web-components';
import Restaurant from 'types/restaurant';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import { WizardNavPagePaths } from 'types/progress';
import welcomeImage from 'images/onboarding/welcome.jpg';
import StickerLocal from 'images/onboarding/StickerLocal';

import { getNavUrls } from '../helpers';
import styles from './styles.module.scss';
import { useWizardNavigationContext } from '../WizardNavigationContext';
import LoadingState from './LoadingState';
import ErrorState from './ErrorState';

const STICKER_LOCAL_MOBILE_SIZE = '80';
const STICKER_LOCAL_DESKTOP_SIZE = '120';

interface WelcomeWizardProps {
  restaurant: Restaurant;
}

const WelcomeWizard: FC<WelcomeWizardProps> = ({
  restaurant,
}) => {
  const welcomeMessage = restaurant?.is_direct_live ?
    'We\'ll take you through a few easy steps to get your ChowNow setup started - it usually takes less than 5 minutes and your progress will be saved if you need to take a break.' :
    'We\'ll take you through a few easy steps to get set up on ChowNow - it usually takes less than 5 minutes and your progress will be saved if you need to take a break.';
  const [nextUrl, setNextUrl] = useState<string>('');
  const {
    wizardPages,
    isWizNavProgressRequestLoading,
    hasWizNavProgressRequestError,
  } = useWizardNavigationContext();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  useEffect(() => {
    if (wizardPages && wizardPages.length > 1) {
      const { nextUrl: nextWizardUrl } = getNavUrls(WizardNavPagePaths.WELCOME, wizardPages);

      setNextUrl(nextWizardUrl);
    }
  }, [wizardPages]);

  const handleClickStart = () => {
    if (nextUrl) {
      navigate(nextUrl);
    }
  };

  let content;

  if (hasWizNavProgressRequestError) {
    content = <ErrorState />;
  } else if (isWizNavProgressRequestLoading) {
    content = <LoadingState />;
  } else {
    content = (
      <>
        <div className={styles.crop}>
          <StickerLocal
            className={styles.stickerLocal}
            height={isDesktop ? STICKER_LOCAL_DESKTOP_SIZE : STICKER_LOCAL_MOBILE_SIZE}
            width={isDesktop ? STICKER_LOCAL_DESKTOP_SIZE : STICKER_LOCAL_MOBILE_SIZE}
          />
          <img
            src={welcomeImage}
            className={styles.welcomeImage}
            alt="onboarding welcome"
          />
        </div>
        <div className={styles.getStartedContainer}>
          <h1>Welcome to ChowNow!</h1>
          <p>{welcomeMessage}</p>
          <Button
            className={styles.getStartedButton}
            label="Get Started"
            platform="brand"
            size="medium"
            variant="fill"
            onClick={handleClickStart}
          />
        </div>
      </>
    );
  }

  return (
    <OnboardingContainer>
      {content}
    </OnboardingContainer>
  );
};

export default WelcomeWizard;
