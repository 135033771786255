import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParam, getUrlParam } from 'helpers/url';
import { WizardNavPagePaths } from 'types/progress';

import { getNavUrls } from '../../helpers';
import { useWizardNavigationContext } from '../../WizardNavigationContext';
import PageFooterBase from '../PageFooterBase';
import { ActiveButtonTypes } from '../types';

interface PageFooterWebsiteAccessProps {
  hasPresubmittedData: boolean;
  isFormCompleted: boolean;
  isLoading: boolean;
  onSave: () => Promise<boolean>;
}

const PageFooterWebsiteAccess: React.FC<PageFooterWebsiteAccessProps> = ({
  hasPresubmittedData,
  isLoading,
  isFormCompleted,
  onSave,
}) => {
  const [nextUrl, setNextUrl] = useState<string>('');
  const [prevUrl, setPrevUrl] = useState<string>('');
  const restaurantId = getUrlParam('restaurant');
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const homepageUrl = `/restaurant/${restaurantId}`;
  const navigate = useNavigate();
  const { wizardPages } = useWizardNavigationContext();

  useEffect(() => {
    if (isComingFromHomepage) {
      setNextUrl(homepageUrl);
      setPrevUrl('');
    } else if (wizardPages && wizardPages.length > 1) {
      const { nextUrl: nextWizardUrl, prevUrl: prevWizardUrl } = getNavUrls(
        WizardNavPagePaths.WEBSITE_ACCESS,
        wizardPages
      );

      setNextUrl(nextWizardUrl);
      setPrevUrl(prevWizardUrl);
    }
  }, [isComingFromHomepage, wizardPages]);

  const handleClickPrevious = () => {
    navigate(prevUrl);
  };

  const handleClickSkip = () => {
    navigate(nextUrl);
  };

  const handleOnSave = async () => {
    if (onSave) {
      const hasError = await onSave();
      if (!hasError) {
        navigate(nextUrl);
      }
    }
  };

  const hasPrevUrl = prevUrl.length > 0;

  const activeButtons: ActiveButtonTypes[] = [];

  if (isComingFromHomepage) {
    activeButtons.push('cancel');

    if (isFormCompleted) {
      activeButtons.push('save');
    }
  } else {
    if (hasPrevUrl) {
      activeButtons.push('previous');
    }

    if (hasPresubmittedData) {
      activeButtons.push('continue');
    } else if (isFormCompleted) {
      activeButtons.push('save and continue');
    } else {
      activeButtons.push('skip for now');
    }
  }

  return (
    <PageFooterBase
      activeButtons={activeButtons}
      hasPrevUrl={hasPrevUrl}
      isFormCompleted={isFormCompleted}
      isLoading={isLoading}
      nextUrl={nextUrl}
      onPreviousClick={handleClickPrevious}
      onNextClick={handleClickSkip}
      onSaveClick={handleOnSave}
    />
  );
};

export default PageFooterWebsiteAccess;
