import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from 'components/NotFound';
import Disbursements from 'containers/LegacyEmbed/Disbursements';
import withUser from 'components/WithUser';

// eslint-disable-next-line no-unused-vars
import styles from './styles.module.scss';

function LegacyEmbed() {
  /**
   * Override global document height needed for app layouts in embedded view where it is unneeded and
   * causes undesirable  additional scrollbars to be displayed in some cases.
   */
  return (
    <div className="legacy-wrapper">
      <style type="text/css">
        {'html, body, #root, #root > div { height: inherit; }'}
      </style>
      <Routes>
        <Route path={'*'} element={<Disbursements />} />
        <Route
          path={'/restaurant/:restaurantId/disbursements'}
          element={<Disbursements />}
        />
      </Routes>
    </div>
  );
}

export default withUser(LegacyEmbed, true);
