import React, { useState, createRef, useEffect } from 'react';
import classNames from 'classnames';
import { useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';

import { ReactComponent as IconCaret } from 'images/icon-caret.svg';

import styles from './styles.module.scss';

function NavSubList(props) {
  const {
    children,
    onToggle,
    subMenuTitle,
    path,
    companyId,
    activeSublist,
    setActiveSublist,
  } = props;
  const match = useMatch({ path, end: false });
  const isCurrentRoute = match;
  const navSubListRef = createRef();
  const [isNavSubListOpen, setIsNavSubListOpen] = isCurrentRoute
    ? useState(true)
    : useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [prevPos, setPrevPos] = useState(0);
  const expandedHeight = React.Children.toArray(children).length * 50;

  // EXPAND ANIMATION
  const defaultExpandProps = {
    height: '0px',
    config: { friction: 38, tension: 350 },
  };
  const [expandProps, setExpandProps] = useSpring(() => defaultExpandProps);

  // EXPAND ANIMATION
  useEffect(() => {
    if (activeSublist !== subMenuTitle) {
      setIsNavSubListOpen(false);
    }
    setExpandProps({
      ...defaultExpandProps,
      immediate: !hasAnimated,
      height: isNavSubListOpen ? `${expandedHeight}px` : '0px',
    });
  }, [isNavSubListOpen, activeSublist]);

  // BAR ANIMATION
  const defaultBarProps = {
    transform: 'translate3d(0px, 0px, 0px)',
    opacity: 0,
    immediate: true,
    config: { friction: 38, tension: 350 },
  };
  const [barProps, setBarProps] = useSpring(() => defaultBarProps);

  // BAR ANIMATION
  useEffect(() => {
    if (activeSublist !== subMenuTitle && isNavSubListOpen) {
      updateBarPos(0, true);
    }
  }, [isCurrentRoute]);

  useEffect(() => {
    // if active link is in dropdown, start with dropdown expanded
    if (isCurrentRoute) {
      setIsNavSubListOpen(true);
      setActiveSublist(subMenuTitle);
    }

    // prevents dropdown from animating on initial page load
    if (companyId) {
      setTimeout(() => {
        if (!hasAnimated) setHasAnimated(true);
      }, 0);
    }
  }, [companyId]);

  function updateBarPos(position, animate) {
    setBarProps({
      ...defaultBarProps,
      opacity: 1,
      immediate: !animate,
      transform: `translate3d(0px, ${position}px, 0px)`,
    });
  }

  function toggleNavSubList() {
    setIsNavSubListOpen(!isNavSubListOpen);
    onToggle(subMenuTitle);
    if (isCurrentRoute) {
      isNavSubListOpen ? updateBarPos(0, true) : updateBarPos(prevPos, true);
    }
  }

  function handleOnClick(e) {
    setPrevPos(e.offsetTop);
    if (isCurrentRoute) updateBarPos(e.offsetTop);
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onNavItemClick: handleOnClick,
        sublink: true,
      });
    }
    return child;
  });

  return (
    <div ref={navSubListRef} id="navlist" className={styles.navSublist}>
      {isCurrentRoute && (
        <animated.div className={styles.animatedBar} style={barProps} />
      )}
      <div
        className={classNames(styles.navItem, {
          [styles.navSubListOpen]: isNavSubListOpen,
        })}
        onClick={() => {
          toggleNavSubList();
        }}
      >
        <div className={styles.navItemTitle}>
          {subMenuTitle}
          <IconCaret className={styles.iconCaret} />
        </div>
      </div>
      <animated.div
        className={classNames(styles.navItemList, {
          [styles.navItemListOpen]: isNavSubListOpen,
        })}
        style={expandProps}
      >
        {childrenWithProps}
      </animated.div>
    </div>
  );
}

NavSubList.propTypes = {
  activeSublist: PropTypes.string,
  children: PropTypes.node,
  companyId: PropTypes.string,
  onToggle: PropTypes.func,
  path: PropTypes.string,
  setActiveSublist: PropTypes.func,
  subMenuTitle: PropTypes.string,
};

export default NavSubList;
