import { useEffect, useState } from 'react';
import { requestCustomApi } from 'helpers/api';
import { WebsiteAccessResponse } from 'types/website-access';

/**
 * Get website access data for restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const useGetWebsiteAccess = (uuid: string | null) => {
  const [
    isGetWebsiteAccessRequestLoading,
    setIsGetWebsiteAccessRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetWebsiteAccessRequestError, setHasGetWebsiteAccessRequestError] =
    useState<boolean>(false);
  const [getWebsiteAccessRequestResponse, setGetWebsiteAccessRequestResponse] =
    useState<WebsiteAccessResponse>();

  useEffect(() => {
    const getWebsiteAccess = async () => {
      try {
        const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: WebsiteAccessResponse = await requestCustomApi(
          `v1/restaurant/${uuid}/website-access`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        setGetWebsiteAccessRequestResponse(response);
      } catch (err) {
        setHasGetWebsiteAccessRequestError(true);
      } finally {
        setIsGetWebsiteAccessRequestLoading(false);
      }
    };

    if (uuid) {
      void getWebsiteAccess();
    }
  }, []);

  return {
    hasGetWebsiteAccessRequestError,
    isGetWebsiteAccessRequestLoading,
    getWebsiteAccessRequestResponse,
  };
};

/**
 * Submit (PUT) website access data for restaurant location
 * @param  {string} uuid - ChowNow Restaurant UUID
 * @param  {object} submittedWebsiteAccessData - submitted website access data
 */
export const usePutWebsiteAccess = () => {
  const [
    isPutWebsiteAccessRequestLoading,
    setIsPutWebsiteAccessRequestLoading,
  ] = useState<boolean>(false);

  const submitWebsiteAccessRequest = async (
    uuid: string | null,
    submittedWebsiteAccessData: WebsiteAccessResponse
  ) => {
    let hasPutRequestError = false;
    const env = process.env.REACT_APP_ONBOARDING_API_HOST || '';

    setIsPutWebsiteAccessRequestLoading(true);

    try {
      await requestCustomApi(
        `v1/restaurant/${uuid}/website-access`,
        env,
        'PUT',
        submittedWebsiteAccessData,
        undefined,
        undefined
      );
    } catch (err) {
      hasPutRequestError = true;
    } finally {
      setIsPutWebsiteAccessRequestLoading(false);
    }

    return hasPutRequestError;
  };

  return {
    isPutWebsiteAccessRequestLoading,
    submitWebsiteAccessRequest,
  };
};
