const routes = (
  restaurantName: string,
  companyPath: string,
  restaurantPath: string
) => [
  // COMPANY ROUTES
  {
    path: `${companyPath}`,
    breadcrumb: 'Headquarters',
  },
  {
    path: `${companyPath}/orders`,
    breadcrumb: 'Orders',
  },
  {
    path: `${companyPath}/reports/accounting`,
    breadcrumb: 'Reports: Accounting',
  },
  {
    path: `${companyPath}/reports/customer-spending`,
    breadcrumb: 'Reports: Customer Spending',
  },
  {
    path: `${companyPath}/reports/emailed`,
    breadcrumb: 'Reports: Emailed',
  },
  {
    path: `${companyPath}/reports/promos`,
    breadcrumb: 'Reports: Promos',
  },
  {
    path: `${companyPath}/reports/order-source`,
    breadcrumb: 'Reports: Order Source',
  },
  {
    path: `${companyPath}/reports/order-overview`,
    breadcrumb: 'Reports: Order Overview',
  },
  {
    path: `${companyPath}/customers`,
    breadcrumb: 'Customers',
  },
  {
    path: `${companyPath}/settings/hq-info`,
    breadcrumb: 'Settings: HQ-Info',
  },
  {
    path: `${companyPath}/settings/locations`,
    breadcrumb: 'Settings: Locations',
  },
  {
    path: `${companyPath}/settings/Integrations`,
    breadcrumb: 'Settings: Integrations',
  },
  {
    path: `${companyPath}/settings/Users`,
    breadcrumb: 'Settings: Users',
  },
  {
    path: `${companyPath}/sherlock`,
    breadcrumb: 'CN-Internal: Sherlock',
  },
  {
    path: `${companyPath}/cn-internal/push-notifications`,
    breadcrumb: 'CN-Internal: Push Notifications',
  },
  {
    path: `${companyPath}/cn-internal/restaurant-categories`,
    breadcrumb: 'CN-Internal: Restaurant Categories',
  },
  {
    path: `${companyPath}/customer`,
    breadcrumb: 'Customer Details',
  },
  // RESTAURANT ROUTES
  {
    path: `${restaurantPath}`,
    breadcrumb: restaurantName,
  },
  {
    path: `${restaurantPath}/orders`,
    breadcrumb: 'Orders',
  },
  {
    path: `${restaurantPath}/orders/details/:orderId`,
    breadcrumb: 'Order Details',
  },
  {
    path: `${restaurantPath}/orders/details/:orderId/customer/:customerId`,
    breadcrumb: 'Customer Info',
  },
  {
    path: `${restaurantPath}/orders/details/:orderId/partial-refunds`,
    breadcrumb: 'Partial Refunds',
  },
  {
    path: `${restaurantPath}/menu/v2/preview`,
    breadcrumb: 'Menu: Preview',
  },
  {
    path: `${restaurantPath}/menu/v2/items`,
    breadcrumb: 'Menu: Items',
  },
  {
    path: `${restaurantPath}/menu/edit-menus`,
    breadcrumb: 'Menu: Edit Menus',
  },
  {
    path: `${restaurantPath}/menu/edit-items`,
    breadcrumb: 'Menu: Edit Items',
  },
  {
    path: `${restaurantPath}/menu/manage-photos/upload`,
    breadcrumb: 'Menu: Manage Photos',
  },
  {
    path: `${restaurantPath}/menu/manage-photos/guidelines`,
    breadcrumb: 'Menu: Photo Guidelines',
  },
  {
    path: `${restaurantPath}/menu/hide-show-items`,
    breadcrumb: 'Menu: Hide/Show Items',
  },
  {
    path: `${restaurantPath}/hours/edit-schedule`,
    breadcrumb: 'Hours: Edit Schedule',
  },
  {
    path: `${restaurantPath}/hours/edit-closures`,
    breadcrumb: 'Hours: Edit Closures',
  },
  {
    path: `${restaurantPath}/reports/accounting`,
    breadcrumb: 'Reports: Accounting',
  },
  {
    path: `${restaurantPath}/reports/customer-spending`,
    breadcrumb: 'Reports: Customer Spending',
  },
  {
    path: `${restaurantPath}/reports/emailed`,
    breadcrumb: 'Reports: Emailed',
  },
  {
    path: `${restaurantPath}/reports/promos`,
    breadcrumb: 'Reports: Promos',
  },
  {
    path: `${restaurantPath}/reports/order-source`,
    breadcrumb: 'Reports: Order Source',
  },
  {
    path: `${restaurantPath}/reports/order-overview`,
    breadcrumb: 'Reports: Order Overview',
  },
  {
    path: `${restaurantPath}/customers`,
    breadcrumb: 'Customers',
  },
  {
    path: `${restaurantPath}/settings/location-info`,
    breadcrumb: 'Settings: Location Info',
  },
  {
    path: `${restaurantPath}/settings/payment-processing`,
    breadcrumb: 'Settings: Payment Processing',
  },
  {
    path: `${restaurantPath}/settings/customizations`,
    breadcrumb: 'Settings: Customizations',
  },
  {
    path: `${restaurantPath}/settings/integrations`,
    breadcrumb: 'Settings: Integrations',
  },
  {
    path: `${restaurantPath}/settings/integrations/square`,
    breadcrumb: 'Square',
  },
  {
    path: `${restaurantPath}/settings/integrations/square/menu`,
    breadcrumb: 'Menu',
  },
  {
    path: `${restaurantPath}/settings/integrations/toast`,
    breadcrumb: 'Toast',
  },
  {
    path: `${restaurantPath}/settings/manage-promos`,
    breadcrumb: 'Settings: Manage Promos',
  },
  {
    path: `${restaurantPath}/settings/email-notifications`,
    breadcrumb: 'Settings: Email Notifications',
  },
  {
    path: `${restaurantPath}/cn-internal/push-notifications`,
    breadcrumb: 'CN-Internal: Push Notifications',
  },
  {
    path: `${restaurantPath}/cn-internal/add-ons`,
    breadcrumb: 'CN-Internal: Add Ons',
  },
  {
    path: `${restaurantPath}/cn-internal/restaurant-categories`,
    breadcrumb: 'CN-Internal: Restaurant Categories',
  },
];

export default routes;
