import React, { FC } from 'react';
import Button from '@chownow/cocina-react-button';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import RefundItems from 'containers/Restaurant/Orders/PartialRefunds/RefundItems';
import { noop } from 'lodash';
import { Content, Footer, StyledModal, Title } from './styles';

interface FullOrderModalProps {
  order: OrderDetailsV6GetSuccessResponse;
  onClose: () => void;
}

const FullOrderModal: FC<FullOrderModalProps> = ({ order, onClose }) => (
  <StyledModal onModalClose={onClose}>
    <Title>Full Order</Title>
    <Content>
      <RefundItems
        order={order}
        refundedItems={[]}
        handleRefundAll={noop}
        handleRefundUpdate={noop}
        isReadOnly
        isOpen
      />
    </Content>
    <Footer>
      <Button label="Okay" onClick={onClose} isFullWidth />
    </Footer>
  </StyledModal>
);

export default FullOrderModal;
