import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { space } from 'styles/variables';
import PriceLine from '../PriceLine';

export const Refund = styled(PriceLine)`
  &&&,
  span {
    color: ${semanticColors.content.primary};
  }
`;

export const RevisedTotal = styled(PriceLine)`
  &&&, span {
    color: ${semanticColors.content.accent};
  }
`;

export const TextBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: ${space.x2} ${space.x3} 0 ${space.x3};
  transform: translateX(-${space.x3});
  width: 100%;

  &:first-child {
    border-top: 1px solid ${semanticColors.border.primary};
    margin: ${space.x3} 0 0 0;
    padding: ${space.x3} ${space.x3} 0 ${space.x3};
  }
`;

export const InstructionContainer = styled.div`
  border-top: none;
`;

export const InstructionIcon = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${space.x2};
`;
