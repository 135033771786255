import React, { FC } from 'react';

import { useGetMenuThirdParty } from 'api/posIntegrations/menuThirdParty';
import Restaurant from 'types/restaurant';
import { getAppOrderDirectDomain } from 'helpers/url';
import LoadingState from 'containers/Restaurant/LoadingState';
import RenderHours from '../RenderHours';

import {
  EmptyStateMessage,
  MenusCardWrapper,
  MenusWrapper,
  StateWrapperEmpty,
  StateWrapperLoading,
  SubTitle,
  Title,
} from './styles';

interface MenusProps {
  restaurant: Restaurant;
  updateErrorMessage: (error: string) => void;
}

const Menus: FC<MenusProps> = ({ restaurant, updateErrorMessage }) => {
  const { company_id: companyId, id: restaurantId } = restaurant;
  const {
    hasGetMenuThirdPartyRequestError,
    isGetMenuThirdPartyRequestLoading,
    getMenuThirdPartyRequestResponse,
  } = useGetMenuThirdParty(restaurant.id, 'toast');

  const hasMenus = getMenuThirdPartyRequestResponse &&
    getMenuThirdPartyRequestResponse?.menus?.length > 0;
  const noMenusState = (
    <StateWrapperEmpty>
      <EmptyStateMessage>No Menus</EmptyStateMessage>
    </StateWrapperEmpty>
  );

  let content = null;

  if (isGetMenuThirdPartyRequestLoading) {
    content = (
      <StateWrapperLoading>
        <LoadingState />
      </StateWrapperLoading>
    );
  } else if (hasGetMenuThirdPartyRequestError) {
    updateErrorMessage('There was an issue updating Sync Menus Automatically. Please try again.');
    content = (noMenusState);
  } else if (hasMenus) {
    content = (
      <MenusCardWrapper>
        {getMenuThirdPartyRequestResponse &&
          getMenuThirdPartyRequestResponse.menus.map((menu) => (
            <RenderHours
              key={menu.x_menu_id}
              title={menu.name}
              displayHours={menu.display_hours}
              xMenuId={menu.x_menu_id}
            />
          ))}
      </MenusCardWrapper>
    );
  } else {
    content = (noMenusState);
  }

  return (
    <MenusWrapper>
      <Title>Menus</Title>
      <SubTitle>
        View what your diner can see now:{' '}
        <a
          href={`${getAppOrderDirectDomain()}/${companyId}/locations/${restaurantId}?menu_status=preview`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Active Menu
        </a>
      </SubTitle>
      {content}
    </MenusWrapper>
  );
};

export default Menus;
