import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';
import Restaurant from 'types/restaurant';
import { useGetProgress } from 'api/onboarding/progressApis';
import Footer from 'components/Footer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setDirectPagesNotRequired } from 'containers/Onboarding/helpers';

import MissingDetailsContent from './Content/MissingDetailsContent';
import WizardCompletedContent from './Content/WizardCompletedContent';
import getMissingWizardDetails from './helpers';
import ErrorContent from './Content/ErrorContent';
import { FooterWrapper, StyledContentContainer } from './styles';

export interface RestaurantHomeProps {
  restaurant: Restaurant;
}

const RestaurantHome: FC<RestaurantHomeProps> = ({ restaurant }) => {
  const flags = useFlags();
  const isOnboarding = restaurant?.is_onboarding;
  const navigate = useNavigate();
  const {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
  } = useGetProgress(restaurant?.sfdc_restaurant_uuid, isOnboarding);

  let content;

  useEffect(() => {
    if (!isOnboarding || !restaurant?.sfdc_restaurant_uuid) {
      navigate(
        `/restaurant/${restaurant.id}/orders`
      );
    }
  }, [restaurant?.sfdc_restaurant_uuid]);

  if (isGetProgressRequestLoading) {
    content = <LoadingSpinner size="x-large" isCentered />;
  } else if (hasGetProgressRequestError) {
    content = <ErrorContent />;
  } else if (isOnboarding && getProgressRequestResponse) {
    const missingWizardDetails = getMissingWizardDetails(
      setDirectPagesNotRequired(getProgressRequestResponse)
    );

    if (missingWizardDetails.length > 0) {
      content = (
        <MissingDetailsContent
          restaurant={restaurant}
          missingWizardDetails={missingWizardDetails}
        />
      );
    } else {
      content = <WizardCompletedContent restaurant={restaurant} />;
    }
  }

  return (
    <>
      <Cover
        title="Welcome to ChowNow"
        body="This dashboard is your way to manage your restaurant on ChowNow."
      />
      <StyledContentContainer
        $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}
      >
        <DashboardGrid>
          <Grid md={12}>{content}</Grid>
        </DashboardGrid>
      </StyledContentContainer>
      <DashboardGrid>
        <Grid md={12}>
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        </Grid>
      </DashboardGrid>
    </>
  );
};

export default RestaurantHome;
