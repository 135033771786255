import React, { FC } from 'react';

interface IconMoreProps {
  className?: string;
  height?: string;
  width?: string;
}

export const IconMore: FC<IconMoreProps> = ({ className, height = '24', width = '24' }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="12" r="2" fill="#1F1F1F" />
    <circle cx="12" cy="12" r="2" fill="#1F1F1F" />
    <circle cx="20" cy="12" r="2" fill="#1F1F1F" />
  </svg>
);
