import {
  DeliveryAddress,
  ModifierCategory,
  OrderDetailsV6GetSuccessResponse,
} from 'types/order-details';
import { FormattedFulfillMethods, Order } from '../types';

export const getOrderInfo = (
  response: OrderDetailsV6GetSuccessResponse | undefined
): Order => ({
  created_at: response?.fulfillment_times?.created_at ?? '',
  delivery_instructions: response?.delivery_instructions ?? null,
  estimated_arrival: response?.fulfillment_times?.estimated_arrival ?? '',
  estimated_pickup: response?.fulfillment_times?.estimated_pickup ?? '',
  original_estimated_arrival:
    response?.fulfillment_times?.original_estimated_arrival ?? '',
  fulfill_method: response?.fulfill_method
    ? FormattedFulfillMethods[response.fulfill_method]
    : '',
  grabbed_by: response?.grabbed_by ?? '',
  is_order_ahead: response?.is_order_ahead ?? false,
  managed_delivery: response?.managed_delivery ?? null,
  product_source: response?.product_source ?? '',
  refund_info: response?.refund_info ?? null,
  requested_fulfillment:
    response?.fulfillment_times?.requested_fulfillment ?? '',
  status: response?.status ?? '',
  table_name: response?.customer?.dine_in?.table_name
    ? response.customer.dine_in.table_name.split('Table ')[1]
    : '',
  updated_at: response?.fulfillment_times?.updated_at ?? '',
  vehicle: response?.customer?.curbside?.vehicle
    ? `${response.customer.curbside.vehicle.color} ${response.customer.curbside.vehicle.vehicle_type}`
    : '',
});

export const formatCustomerAddress = (
  customerAddress: DeliveryAddress | undefined
) =>
  `${customerAddress?.street_address1},${
    customerAddress?.street_address2
      ? ` ${customerAddress.street_address2},`
      : ''
  } ${customerAddress?.city} ${customerAddress?.state} ${customerAddress?.zip}`;

export const canRefundOrder = (
  order: OrderDetailsV6GetSuccessResponse,
  refundByAmountFlagEnabled: boolean
) =>
  !order.refund_eligibility ||
  order.refund_eligibility.full ||
  (order.refund_eligibility.partial_by_amount && refundByAmountFlagEnabled) ||
  order.refund_eligibility.partial_by_item;

export const canRefundOrderFull = (order: OrderDetailsV6GetSuccessResponse) =>
  !order.refund_eligibility || order.refund_eligibility.full;

export const canRefundOrderByAmount = (
  order: OrderDetailsV6GetSuccessResponse
) => !order.refund_eligibility || order.refund_eligibility.partial_by_amount;

export const canRefundOrderByItem = (order: OrderDetailsV6GetSuccessResponse) =>
  !order.refund_eligibility || order.refund_eligibility.partial_by_item;

export const getModifierCount = (
  modifierCategories: ModifierCategory[]
): number =>
  modifierCategories.reduce((total, category) => {
    const selectionsCount = category.selections?.length ?? 0;
    const nestedModifiersCount =
      category.selections?.reduce(
        (nestedTotal, selection) =>
          nestedTotal +
          (selection.modifier_categories
            ? getModifierCount(selection.modifier_categories)
            : 0),
        0
      ) ?? 0;
    return total + selectionsCount + nestedModifiersCount;
  }, 0);
