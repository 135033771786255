import React, { Dispatch, FC, SetStateAction } from 'react';
import { Checkbox } from '@chownow/cn-web-components';
import { sanitize } from 'dompurify';
import { NoFoodImageOptions } from 'types/food-image';

import {
  BottomCardWrapper,
  NoFoodImageHeading,
  NoFoodImageRadioButtonsRow,
  StyledRadioButton,
} from './styles';
import {
  CheckboxRow,
  CheckboxText,
  Heading,
  PreviouslySubmittedText,
  Subtext,
} from '../styles';
import FileSelector from '../FileSelector';
import { validateFoodImage } from '../../helpers';

interface FoodImageCardProps {
  foodImageFile: File | undefined;
  hasNoFoodImageFile: boolean;
  hasNoFoodImageSelectionError: boolean;
  previouslySubmittedText: string;
  setHasNoFoodImageFile: Dispatch<SetStateAction<boolean>>;
  setHasNoFoodImageSelectionError: Dispatch<SetStateAction<boolean>>;
  selectedNoFoodImageOption: NoFoodImageOptions | undefined;
  setSelectedNoFoodImageOption: Dispatch<
    SetStateAction<NoFoodImageOptions | undefined>
  >;
  setFoodImageFile: (file: File | undefined) => void;
}

const FoodImageCard: FC<FoodImageCardProps> = ({
  foodImageFile,
  hasNoFoodImageFile,
  hasNoFoodImageSelectionError,
  previouslySubmittedText,
  setHasNoFoodImageFile,
  setHasNoFoodImageSelectionError,
  selectedNoFoodImageOption,
  setSelectedNoFoodImageOption,
  setFoodImageFile,
}) => {
  const handleCheckboxOnChange = () => {
    if (hasNoFoodImageFile) {
      setSelectedNoFoodImageOption(undefined);

      if (hasNoFoodImageSelectionError) {
        setHasNoFoodImageSelectionError(false);
      }
    }

    setHasNoFoodImageFile(!hasNoFoodImageFile);
  };

  const handleNoFoodImageOptionSelection = (option: NoFoodImageOptions) => {
    setSelectedNoFoodImageOption(option);

    if (hasNoFoodImageSelectionError) {
      setHasNoFoodImageSelectionError(false);
    }
  };

  const validateFoodImageSelection = (
    file: File | undefined
  ): Promise<string> =>
    new Promise(resolve => {
      if (file) {
        try {
          const img = new Image();
          img.src = sanitize(URL.createObjectURL(file));
          img.onload = () => resolve(validateFoodImage(file, img));
          return;
        } catch (error) {
          resolve('An error occured.');
        }
      }
      resolve('');
    });

  return (
    <BottomCardWrapper>
      <Heading>Food image</Heading>
      {previouslySubmittedText ? (
        <PreviouslySubmittedText>
          {previouslySubmittedText}
        </PreviouslySubmittedText>
      ) : (
        <>
          <Subtext>
            Food image file can be .jpg or .png and must be at least
            750px by 1200px (portrait).
          </Subtext>
          <FileSelector
            isDisabled={hasNoFoodImageFile}
            file={foodImageFile}
            filesAccepted=".jpg,.png"
            setFile={setFoodImageFile}
            validate={validateFoodImageSelection}
          >
            Upload Image
          </FileSelector>
          <CheckboxRow>
            <Checkbox
              checked={hasNoFoodImageFile}
              onChange={handleCheckboxOnChange}
              value=""
              disabled={!!foodImageFile}
            />
            <CheckboxText>I don&apos;t have a food image</CheckboxText>
          </CheckboxRow>
          {hasNoFoodImageFile && (
            <>
              <NoFoodImageHeading
                $hasNoFoodImageSelectionError={hasNoFoodImageSelectionError}
              >
                Select an option below for your food image:
              </NoFoodImageHeading>
              <NoFoodImageRadioButtonsRow>
                <StyledRadioButton
                  checked={
                    selectedNoFoodImageOption === NoFoodImageOptions.STOCK_IMAGE
                  }
                  label="Stock Image"
                  name="stock-food-image-radio-button"
                  onChange={() =>
                    handleNoFoodImageOptionSelection(
                      NoFoodImageOptions.STOCK_IMAGE
                    )}
                  value="stockImage"
                />
                <StyledRadioButton
                  checked={
                    selectedNoFoodImageOption === NoFoodImageOptions.NO_IMAGE
                  }
                  label="No Image"
                  name="no-food-image-radio-button"
                  onChange={() =>
                    handleNoFoodImageOptionSelection(
                      NoFoodImageOptions.NO_IMAGE
                    )}
                  value="noImage"
                />
              </NoFoodImageRadioButtonsRow>
            </>
          )}
        </>
      )}
    </BottomCardWrapper>
  );
};

export default FoodImageCard;
