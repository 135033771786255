import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';

export const Separator = styled.hr`
  border-top: 1px solid ${semanticColors.border.primary};
  margin-bottom: ${space.x3};
  margin-top: 0;
  width: 100%;
`;

export const MenuItems = styled.ul`
  li:last-child {
    margin-bottom: 142px;
  }
`;
