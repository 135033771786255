import styled from 'styled-components';
import Button from '@chownow/cocina-react-button';
import Outlink from 'components/Outlink';
import { semanticColors } from 'styles/colors';

export const PauseOrdersButton = styled(Button)<{ isLoading: boolean }>`
  text-transform: capitalize;

  ${({ isLoading }) => isLoading && 'width: 151px;'}
`;

export const OrderingNotLive = styled.div`
  opacity: 0.5;
  pointer-events: none;
`;

export const StyledOutlink = styled(Outlink)`
  color: ${semanticColors.content.primary};
  text-decoration: none;
`;
