export enum PromotionOptions {
  PERCENTAGE_20 = 'PERCENTAGE_20',
  PERCENTAGE_10 = 'PERCENTAGE_10',
  DOLLAR_AMOUNT_5 = 'DOLLAR_AMOUNT_5',
  DOLLAR_AMOUNT_2 = 'DOLLAR_AMOUNT_2',
  NO_PROMO = 'NO_PROMO',
}

export const PromotionMessages = {
  [PromotionOptions.PERCENTAGE_20]: '20% off your first online or mobile order',
  [PromotionOptions.PERCENTAGE_10]: '10% off your first online or mobile order',
  [PromotionOptions.DOLLAR_AMOUNT_5]: '$5 off your first online or mobile order (min order amount $20+)',
  [PromotionOptions.DOLLAR_AMOUNT_2]: '$2 off your first online or mobile order (min order amount $10+)',
  [PromotionOptions.NO_PROMO]: 'No promo',
};

export enum BannerMessage {
  SUCCESS = 'This information has been submitted to our onboarding team - contact your account coordinator to make any updates.',
  ERROR = 'There was an issue - please try again.',
}
