import React, { FC } from 'react';
import { useGetStripeVerificationUrl } from 'api/stripe';
import Restaurant from 'types/restaurant';
import { ReactComponent as IconCheck } from 'images/icon-check-sm.svg';
import ErrorCard from 'containers/Onboarding/ErrorCard';

import StripeButton from '../StripeButton';
import {
  ActivateAccountCardContainer,
  ButtonContainer,
  Reminders,
} from './styles';

interface ActivateAccountCardProps {
  restaurant: Restaurant;
  urlPath: string;
}

const ActivateAccountCard: FC<ActivateAccountCardProps> = ({
  restaurant,
  urlPath,
}) => {
  const {
    hasGetStripeVerificationUrlRequestError,
    isGetStripeVerificationUrlRequestLoading,
    getStripeVerificationUrl,
  } = useGetStripeVerificationUrl();

  const handleActivateClick = () => {
    const navigateToStripeVerificationUrl = async () => {
      const response = await getStripeVerificationUrl(restaurant, urlPath);

      if (response?.url) {
        window.location.href = response.url;
      }
    };

    void navigateToStripeVerificationUrl();
  };

  if (hasGetStripeVerificationUrlRequestError) return <ErrorCard />;

  return (
    <ActivateAccountCardContainer>
      <ButtonContainer>
        <h2>Activate your account</h2>
        <StripeButton
          isLoading={isGetStripeVerificationUrlRequestLoading}
          onClick={handleActivateClick}
        />
      </ButtonContainer>
      <Reminders>
        <h2>Have these items ready:</h2>
        <ul>
          <li>
            <IconCheck />
            <span>Social Security Number</span>
          </li>
          <li>
            <IconCheck />
            <span>Driver&apos;s License / Photo ID</span>
          </li>
        </ul>
      </Reminders>
    </ActivateAccountCardContainer>
  );
};

export default ActivateAccountCard;
