import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import {
  deleteTempClosure,
  getRestaurantReadyTime,
  pauseOrders,
  requestTempClosures,
  setReadyTimeOffset,
} from 'api/restaurant';
import { requestOrdersList } from 'api/orders/ordersList';
import { ModalContext } from 'context/ModalContext';
import { ORDER_STATUS } from 'helpers/constants';
import { getCurrentTime } from 'helpers/dateTime';
import {
  CONNECTION_FAILED_MODAL_COPY,
  DIALOG_MODAL_COPY,
} from 'helpers/modals';
import { calculateCountdown } from './helpers';
import useErrorHandler from 'hooks/useErrorHandler';
import useInterval from 'hooks/useInterval';

import { Cover } from '@chownow/cn-web-components';
import Banner from '@chownow/cocina-react-banner';

import ConnectionFailedModal from 'components/Modals/ConnectionFailedModal';
import DialogModal from 'components/Modals/DialogModal';
import PauseOrdersModal from 'components/Modals/PauseOrdersModal';
import PrepTimeModal from 'components/Modals/PrepTimeModal';
import OrdersList from 'components/OrdersList';
import CoverCTA from './CoverCTA';

const ORDER_POLL_DELAY = 120000;
const ORDER_MAX_POLLS = 100;
const COUNTDOWN_INTERVAL = 1000;
const TEMPCLOSURES_INTERVAL = 60000;
const READY_TIME_INTERVAL = 60000;

const OrdersLocation = ({ restaurant }) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState({});
  const [ordersList, setOrdersList] = useState([]); // get orders.results and set as orders list
  const { showModal } = useContext(ModalContext);
  const { hideModal } = useContext(ModalContext);
  const [orderPollCount, setOrderPollCount] = useState(0);
  const [ordersPaused, setOrdersPaused] = useState();
  const [closureEndTime, setClosureEndTime] = useState();
  const [tempClosureId, setTempClosureId] = useState();
  const [closureFulfillMethod, setClosureFulfillMethod] = useState();
  const [countdown, setCountdown] = useState();
  const [readyTime, setReadyTime] = useState();
  const [pollTimeStamp, setPollTimeStamp] = useState(getCurrentTime());
  const companyId = restaurant.company_id;

  // redirect users with the New or Future query param to the base orders page to avoid them bookmarking the wrong page
  const redirectToOrdersList = () => {
    window.location.href = `${process.env.PUBLIC_URL}restaurant/${restaurant.id}/orders`;
  };

  const orderPollCondition = orderPollCount >= ORDER_MAX_POLLS;
  // live restaurants must have either pickup or delivery fulfillment option available
  const liveRestaurant =
    !!restaurant.fulfillment.pickup || !!restaurant.fulfillment.delivery;

  const queryParams = new URLSearchParams(useLocation().search);
  const queryParamDateRange = queryParams.get('date_range');
  const queryParamOffset = queryParams.get('offset');
  const queryParamOrderStatus = queryParams.get('filterby');
  const queryParamSortOrder = queryParams.get('sort_order');
  const defaultSortOrder =
    queryParamSortOrder === 'asc' || queryParamSortOrder === 'desc'
      ? queryParamSortOrder
      : 'desc';
  const queryParamOrderBy = queryParams.get('order_by');
  // if queryParamOrderStatus is New or Future from an old bookmark, clear it to avoid error modal
  const filteredQueryParamOrderStatus = ['New', 'Future'].includes(
    queryParamOrderStatus
  )
    ? redirectToOrdersList()
    : queryParamOrderStatus;

  const orderParams = {
    order_status: filteredQueryParamOrderStatus,
    date_range: queryParamDateRange,
    offset: queryParamOffset,
    sort_order: defaultSortOrder,
    order_by: queryParamOrderBy,
  };

  useEffect(() => {
    // refresh orders list, check temp closures and ready time every time user switches locations
    checkTempClosures();
    getReadyTime();
    setOrderPollCount(0);

    const previousRestaurantId = localStorage.getItem('previousRestaurantId');
    if (previousRestaurantId !== restaurant?.id) {
      localStorage.setItem('previousRestaurantId', restaurant?.id);
      fetchOrdersList(orderParams);
    }
  }, [restaurant?.id]);

  useInterval(
    () => {
      fetchOrdersList(orderParams);
      setOrderPollCount(orderPollCount + 1);
      setPollTimeStamp(getCurrentTime());
    },
    // do not poll accepted, canceled, or if it has reached max polls
    orderPollCondition ? null : ORDER_POLL_DELAY
  );

  useInterval(() => {
    closureEndTime && checkCountdown();
  }, COUNTDOWN_INTERVAL);

  // check for temp closure updates from admin every 2 mins
  useInterval(() => {
    checkTempClosures();
  }, TEMPCLOSURES_INTERVAL);

  // check for ready time updates every 2 mins
  useInterval(() => {
    getReadyTime();
  }, READY_TIME_INTERVAL);

  useErrorHandler(hasError, () => {
    showModal(ConnectionFailedModal, {
      message: CONNECTION_FAILED_MODAL_COPY.ordersListError.message,
      title: 'Something went wrong.',
    });
  });

  const checkTempClosures = async () => {
    const tempClosures = await requestTempClosures(restaurant.id);
    const currentDate = new Date();

    if (!tempClosures.errors) {
      const activeTempClosures = tempClosures.temp_closures.filter(
        closure =>
          // check to see if current date is within any set temp closures
          !!(
            currentDate <= getDateForApiDateStr(closure.end) &&
            currentDate >= getDateForApiDateStr(closure.start)
          )
      );
      const closureEndTime =
        activeTempClosures.length === 1
          ? getDateForApiDateStr(activeTempClosures[0].end)
          : '';
      if (activeTempClosures.length === 1) {
        setOrdersPaused('single');
        setClosureEndTime(closureEndTime);
        setTempClosureId(activeTempClosures[0].id); // set temp closure id to use in temp closure deletion
        setClosureFulfillMethod(activeTempClosures[0].fulfill_method || '');
      } else if (activeTempClosures.length > 1) {
        setOrdersPaused('multiple');
      } else {
        setOrdersPaused('notPaused');
      }
    } else {
      setHasError(true);
    }
  };

  const checkCountdown = () => {
    const currentDate = new Date();
    const endTime = closureEndTime ? new Date(closureEndTime) : null;

    const countdownResult = calculateCountdown(currentDate, endTime);
    const tempClosureCountdown = countdownResult.countdown;
    const timerEnd = countdownResult.timerEnd;

    timerEnd
      ? setOrdersPaused('notPaused')
      : setCountdown(tempClosureCountdown);
  };

  const fetchOrdersList = async (query, prevNextUrl) => {
    setIsLoading(true);
    // remove the first part of the api url since it's not needed
    const paginatedUrl =
      prevNextUrl &&
      prevNextUrl.replace(`${process.env.REACT_APP_API_HOST}/`, '');
    const orders = await requestOrdersList(
      'restaurant',
      restaurant.id,
      query,
      paginatedUrl
    );

    if (!orders.errors) {
      setOrders(orders); // first set orders to get the prev/next urls from endpoint
      const acceptedOrCanceled =
        query.order_status == ORDER_STATUS.accepted ||
        query.order_status == ORDER_STATUS.canceled;
      if (!acceptedOrCanceled) {
        // loop through orders to find future or new orders
        // should_acknowledge is used by POS client in conjuction with the property is_order_ahead
        // to determine whether an ahead order need to be placed in the new tab (means ready to
        // acknowledge by admin) or future tab (means not ready to be prepared for fulfillment yet)
        let newOrders = [];
        let futureOrders = [];
        orders.results.forEach(order =>
          (order.is_order_ahead && !order.should_acknowledge
            ? futureOrders
            : newOrders
          ).push(order)
        );

        setOrdersList(orders.results);
        setPollTimeStamp(getCurrentTime());
      } else {
        setOrdersList(orders.results);
        setPollTimeStamp(getCurrentTime());
      }
    } else {
      setHasError(true);
    }
    setIsLoading(false);
  };

  const showPauseOrdersModal = () => {
    if (ordersPaused === 'notPaused') {
      showModal(PauseOrdersModal, {
        handlePauseOrders,
        restaurantFulfillmentDelivery: !!restaurant.fulfillment.delivery,
        restaurantFulfillmentPickup: !!restaurant.fulfillment.pickup,
      });
    } else if (ordersPaused === 'single') {
      showModal(DialogModal, {
        ...DIALOG_MODAL_COPY.cancelClosure,
        onClose: cancelPauseOrders,
        buttonColor: 'black',
      });
    } else if (ordersPaused === 'multiple') {
      return null;
    }
  };

  const showReadyTimeModal = () => {
    const readyTimeSet = readyTime !== null;
    showModal(PrepTimeModal, {
      handleSetPrepTime: handleSetReadyTime,
      prepTimeSet: readyTimeSet,
    });
  };

  const getReadyTime = async () => {
    const readyTime = await getRestaurantReadyTime(restaurant.id);

    if (!readyTime.errors) {
      setReadyTime(readyTime.prep_time_offset);
    } else {
      setHasError(true);
    }
  };

  const handleSetReadyTime = async readyTime => {
    setIsLoading(true);
    try {
      await setReadyTimeOffset(restaurant.id, parseInt(readyTime));
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      showModal(DialogModal, {
        message: `Please try again`,
        onClose: () => {
          hideModal();
        },
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
    // do not hide modal if user toggles ready time off to avoid abrupt modal closure
    readyTime !== null && hideModal();
    getReadyTime();
  };

  const cancelPauseOrders = async didConfirm => {
    setIsLoading(true);

    if (!didConfirm) {
      return setIsLoading(false);
    }

    try {
      await deleteTempClosure(restaurant.id, tempClosureId);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      showModal(DialogModal, {
        message: `Please try again`,
        onClose: () => {
          hideModal();
        },
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
    hideModal();
    checkTempClosures();
  };

  const handlePauseOrders = async (fulfillMethod, start, end, reason) => {
    setIsLoading(true);
    setCountdown(''); // reset countdown timer to not flash old countdown
    try {
      await pauseOrders(restaurant.id, fulfillMethod, start, end, reason);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      showModal(DialogModal, {
        message: `Please try again`,
        onClose: () => {
          hideModal();
        },
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
    hideModal();
    checkTempClosures();
  };

  return (
    <>
      <Helmet title="Orders" />
      {errorMessage && <Banner variant="caution">{errorMessage}</Banner>}
      <Cover
        title="Orders"
        body={`Last Updated: ${pollTimeStamp}`}
        ctaElement={
          <CoverCTA
            readyTime={readyTime}
            restaurant={restaurant}
            closureFulfillMethod={closureFulfillMethod}
            countdown={countdown}
            liveRestaurant={liveRestaurant}
            ordersPaused={ordersPaused}
            showPauseOrdersModal={showPauseOrdersModal}
            showReadyTimeModal={showReadyTimeModal}
          />
        }
      />

      <OrdersList
        restaurant={restaurant}
        setErrorMessage={setErrorMessage}
        companyId={companyId}
        isLoading={isLoading}
        hasError={hasError}
        orders={orders}
        ordersList={ordersList}
        fetchOrdersList={fetchOrdersList}
      />
    </>
  );
};

export default OrdersLocation;
