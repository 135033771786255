import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ReactComponent as IconInfo,
  ReactComponent as IconNotice
} from 'images/icon-info.svg';
import { ReactComponent as IconSuccess } from 'images/icon-success.svg';

import styles from './styles.module.scss'; // eslint-disable-line css-modules/no-unused-class

const { bool, node, string } = PropTypes;

export default function AlertBanner({
  children,
  className,
  danger,
  success,
  notice
}) {
  return (
    <div
      className={classNames(styles.alert, className, {
        [styles.danger]: danger,
        [styles.success]: success,
        [styles.notice]: notice
      })}
      data-testid="alertBanner"
    >
      {danger && (
        <IconInfo
          className={classNames(
            styles.alertIconInfo,
            className,
            styles.dangerIcon
          )}
        />
      )}
      {success && (
        <IconSuccess
          className={classNames(
            styles.alertIconInfo,
            className,
            styles.successIcon
          )}
        />
      )}
      {notice && (
        <IconNotice
          className={classNames(
            styles.alertIconInfo,
            className,
            styles.noticeIcon
          )}
        />
      )}
      <div>{children}</div>
    </div>
  );
}

AlertBanner.propTypes = {
  children: node.isRequired,
  className: string,
  danger: bool,
  notice: bool,
  success: bool
};
