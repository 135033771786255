import React, { FC } from 'react';
import CardArticle from 'components/Onboarding/CardArticle';
import Restaurant from 'types/restaurant';

import acceptOrdersImg from 'images/onboarding/home-accept-orders.jpg';
import deliveryImg from 'images/onboarding/home-delivery.jpg';
import menuLinkGuide from 'images/onboarding/home-menu-link-guide.png';
import menuPhotosImg from 'images/onboarding/home-menu-photos.jpg';
import onboardingFaqsImg from 'images/onboarding/home-onboarding-faqs.jpg';
import toastImg from 'images/onboarding/home-toast-sot.jpg';

import { THIRD_PARTY_MENU_SOURCE } from 'helpers/constants';

import styles from './styles.module.scss';

interface WizardCompletedContentProps {
  restaurant: Restaurant;
}

const WizardCompletedContent: FC<WizardCompletedContentProps> = ({
  restaurant,
}) => {
  // do not show photos link if restaurant has toast menu as source of truth
  const isToastMenuSot = restaurant?.third_party_menu_source === THIRD_PARTY_MENU_SOURCE.toast;
  // only show menu cards if not menu third party
  const isThirdPartyMenuSot = restaurant?.has_third_party_menu_source;
  const completedContentMessage = () => (
    restaurant?.is_direct_live
      ? 'Thank you for submitting all of your details, a member of our onboarding team will contact you to coordinate the installation of the ordering widget for your website and finalize your account details.'
      : 'Thank you for submitting all of your details, a member of our onboarding team will contact you to finalize your account details.');

  return (
    <div>
      <h3 className={styles.heading}>
        {restaurant?.is_direct_live
          ? "You're almost there"
          : "You've submitted all your details 🎉"}
      </h3>
      <p className={styles.description}>{completedContentMessage()}</p>
      <div className={styles.cards}>
        {isToastMenuSot && (
          <CardArticle
            title="Your Toast POS Integration"
            imgSrc={toastImg}
            summary="Verify menu syncs, menu schedules, and resolve integration errors"
            internalLinkLocation={`/restaurant/${restaurant.id}/settings/integrations`}
          />
        )}
        {restaurant?.is_direct_live && (
          <CardArticle
            title="ChowNow menu link guide"
            imgSrc={menuLinkGuide}
            summary="How to add your ChowNow menu link to your website and optimize sales"
            linkLocation="https://get.chownow.com/wp-content/uploads/HowToLink_Direct-3.pdf"
          />
        )}
        <CardArticle
          title="Onboarding FAQs"
          imgSrc={onboardingFaqsImg}
          summary="Answers to questions you may have about getting started on ChowNow"
          linkLocation="https://support.chownow.com/restaurant/s/topic/0TO4w000000ohv3GAA/onboarding"
        />
        {!isThirdPartyMenuSot && (
          <CardArticle
            title="How to accept orders"
            imgSrc={acceptOrdersImg}
            summary="Learn how to accept orders and manage your day-to-day operations"
            linkLocation="https://chownow.wistia.com/medias/1r25h3xai0"
          />
        )}
        <CardArticle
          title="Delivery with ChowNow"
          imgSrc={deliveryImg}
          summary="Tools and tips to drive your delivery sales"
          linkLocation="https://get.chownow.com/delivery-resources"
        />
        {!isThirdPartyMenuSot ? (
          <CardArticle
            title="Menu photos tips and tricks"
            imgSrc={menuPhotosImg}
            summary="Easy tips for taking and showcasing with ChowNow"
            linkLocation="https://get.chownow.com/blog/4-tips-for-taking-enticing-photos-for-your-menu"
          />
        ) : (
          <CardArticle
            title="Add a cover photo to your menu"
            imgSrc={menuPhotosImg}
            summary="Upload a cover photo and logo to your menu to make it stand out"
            internalLinkLocation={`/restaurant/${restaurant?.id}/menu/manage-photos/upload`}
          />
        )}
      </div>
    </div>
  );
};

export default WizardCompletedContent;
