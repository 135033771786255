import styled from 'styled-components';
import { space } from 'styles/variables';
import { fontWeight } from 'styles/types';

export const PriceRow = styled.div<{ $isFirstElement?: boolean, $isBold: boolean }>`
  display: flex;

  ${({ $isFirstElement }) => !$isFirstElement && `margin-top: ${space.x2}`};

  font-weight: ${({ $isBold }) => ($isBold ? fontWeight.bold : fontWeight.regular)};
`;
