import { LD_PRIVATE_ATTRIBUTES } from './constants';

/**
 * Associates ChowNow user.id to LaunchDarkly identity.
 *
 * @public
 * @param {Object} user The initial Dashboard user object. We only need id and is_chownow_admin.
 * @param {Object} props Props from WithUser so we can access ldClient from LaunchDarkly.
 * @returns  Nothing. This is a void function that only performs a task.
 */

// Associates ChowNow user_id to LaunchDarkly identity
export function initLaunchDarklyUser(
  { id, group_id, is_chownow_admin, company, email, currentRestaurant },
  ldClient
) {
  const key = is_chownow_admin ? `internal-user-${id}` : `rp-user-${id}`;
  const privateAttributes = LD_PRIVATE_ATTRIBUTES;
  const chowNowUser = {
    key,
    custom: {
      group: group_id,
      user: id,
      company,
      currentRestaurant,
    },
    email: email,
    privateAttributeNames: privateAttributes,
  };

  const options = {
    privateAttributes,
  };

  // Limit PII sent to LD so we don't need to worry about it for CCPA
  ldClient && ldClient.identify(chowNowUser, options);
}
