import React, { Fragment, ReactNode } from 'react';
import {
  ModifierCategory,
  OrderItemNestedModsV5,
  TabletOrderItemV5,
} from 'types/order-details';
import { RefundItemInterface, getModifierSelections } from '../../util';
import RefundSubItem from '../../RefundSubItem';

export const renderSubItems = (
  item: OrderItemNestedModsV5 | TabletOrderItemV5,
  modifier_categories: ModifierCategory[],
  indentLevel: number,
  refundedItems: RefundItemInterface[],
  handleRefundUpdate: (
    qty: number,
    itemId: string,
    modifierId?: string | undefined
  ) => void,
  isReadOnly = false
): ReactNode => (
  <>
    {modifier_categories.map(category =>
      getModifierSelections([category]).map(selection => (
        <Fragment key={`${item.id}${selection.id}`}>
          <RefundSubItem
            parentId={item.id}
            key={`${item.id}${selection.id}`}
            item={selection}
            refundedItems={refundedItems}
            parentQuantity={item.quantity}
            handleRefundUpdate={handleRefundUpdate}
            indentLevel={indentLevel}
            catagoryName={category.name}
            isReadOnly={isReadOnly}
          />
          {selection &&
            Array.isArray(selection.modifier_categories) &&
            renderSubItems(
              item,
              selection.modifier_categories || [],
              indentLevel + 1,
              refundedItems,
              handleRefundUpdate,
              isReadOnly
            )}
        </Fragment>
      ))
    )}
  </>
);
