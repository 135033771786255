export enum NoFoodImageOptions {
  STOCK_IMAGE = 'stock-image',
  NO_IMAGE = 'no-image',
}

export enum BannerMessages {
  NO_FOOD_IMAGE_SELECTION = 'You need to select an option for your food image.',
  LOGO_AND_FOOD_IMAGE_UPLOADED = 'Your logo and image were submitted to our onboarding team — contact your account coordinator to make any updates.',
  LOGO_UPLOADED = 'Your logo was submitted to our onboarding team — contact your account coordinator to make any updates.',
  FOOD_IMAGE_UPLOADED = 'Your image was submitted to our onboarding team — contact your account coordinator to make any updates.',
  LOGO_AND_FOOD_IMAGE_FAILED = 'Your logo and image did not successfully upload. Please try again.',
  LOGO_FAILED = 'Your logo did not successfully upload. Please try again.',
  FOOD_IMAGE_FAILED = 'Your image did not successfully upload. Please try again.',
}
