import React, { FC } from 'react';
import { ReactComponent as IconReset } from 'images/icon-reset-16.svg';
import {
  ErrorContent,
  ErrorMessage,
  ErrorTitle,
  RefreshPageButton,
} from './styles';

interface ErrorStateProps {
  className?: string;
  errorMessage?: string;
}

const ErrorState: FC<ErrorStateProps> = ({ className, errorMessage }) => {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <ErrorContent className={className}>
      <ErrorTitle>Oops! There was an issue loading your data</ErrorTitle>
      {errorMessage && (
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      )}
      <RefreshPageButton
        icon={IconReset}
        label="Refresh Page"
        onClick={handleRefreshClick}
      />
    </ErrorContent>
  );
};

export default ErrorState;
