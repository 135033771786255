import React, { FC } from 'react';
import CardInfo from '@chownow/cocina-react-cardinfo';
import breakpoints from 'helpers/breakpoints';
import { useMediaQuery } from 'react-responsive';
import { transformHours24To12 } from 'helpers/transformHours24To12';
import { getDayAbbreviation } from 'helpers/dayAbbreviation';
import { FulfillmentDay } from 'types/restaurant';
import { sortByCustomDaysOrder } from '../../helpers';

import { CardInfoWrapper, Day, DayAndTimeWrapper, DayTitle, Hours, Range } from './styles';

interface RenderHoursProps {
  title: string;
  displayHours: FulfillmentDay[];
  fulfillmentType?: string;
  xMenuId?: string;
}

const RenderHours: FC<RenderHoursProps> = ({ title, displayHours, fulfillmentType, xMenuId }) => {
  // If fulfillmentType (schedule) is not provided, use xMenuId (menu third party) as id
  const id = fulfillmentType || xMenuId;
  const sortedDisplayHours = sortByCustomDaysOrder(displayHours);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.xsMax });

  return (
    <CardInfoWrapper>
      <CardInfo title={title} shouldLoadInCollapsed={!isDesktop}>
        <DayAndTimeWrapper>
          {sortedDisplayHours.map((day: FulfillmentDay) => (
            <Day key={`${id}-${day.day_id}`}>
              <DayTitle>{getDayAbbreviation(day.dow.toString())}</DayTitle>
              <Hours>
                {day.ranges.length > 0 ? (
                  day.ranges.map((range) => (
                    <Range key={`${id}-${day.day_id}-${range.from}`}>
                      {`${transformHours24To12(range.from, true)} - ${transformHours24To12(range.to, true)}`}
                    </Range>
                  ))
                ) : (
                  <Range key={`${id}-${day.day_id}-empty`}>—</Range>
                )}
              </Hours>
            </Day>
          ))}
        </DayAndTimeWrapper>
      </CardInfo>
    </CardInfoWrapper>
  );
};

export default RenderHours;
