import styled from 'styled-components';
import { containerWidths, screen, space } from 'styles/variables';

export const StyledContentContainer = styled.div<{ $isOldAdminBannerDisplayed: boolean }>`
  margin: 0 auto;
  max-width: ${containerWidths.containerWidthNarrow};
  min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '664px' : '567px')});
  padding: ${space.x7} ${space.x2} ${space.x8};
  width: calc(100% - ${space.x4});

  @media (min-width: 480px) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '623px' : '546px')});
  }

  @media (min-width: ${screen.smMin}) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '522px' : '466px')});
  }

  @media (min-width: ${screen.lgMin}) {
    max-width: ${containerWidths.containerWidth};
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '441px' : '385px')});
    padding-left: 0;
    padding-right: 0;
  }
`;

export const FooterWrapper = styled.div`
  padding: 0 ${space.x2};

  @media (min-width: ${screen.smMin}) {
    padding: 0;
  }
`;
