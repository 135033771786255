import React, { FC } from 'react';
import { semanticColors } from 'styles/colors';

interface IconTabletProps {
  className?: string;
  height?: string;
  width?: string;
  isTabletOn?: boolean;
}

export const IconTablet: FC<IconTabletProps> = ({ className, height = '24', width = '24', isTabletOn }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 3.5C3 2.11929 4.11929 1 5.5 1H18.5C19.8807 1 21 2.11929 21 3.5V20.5C21 21.8807 19.8807 23 18.5 23H5.5C4.11929 23 3 21.8807 3 20.5V3.5ZM5.5 2C4.67157 2 4 2.67157 4 3.5V20.5C4 21.3284 4.67157 22 5.5 22H18.5C19.3284 22 20 21.3284 20 20.5V3.5C20 2.67157 19.3284 2 18.5 2H5.5Z" fill="#142138" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 16.5C3.5 16.2239 3.72386 16 4 16H20C20.2761 16 20.5 16.2239 20.5 16.5C20.5 16.7761 20.2761 17 20 17H4C3.72386 17 3.5 16.7761 3.5 16.5Z" fill="#142138" />
    <path d="M13 19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5C11 18.9477 11.4477 18.5 12 18.5C12.5523 18.5 13 18.9477 13 19.5Z" fill="#142138" />
    <circle cx="20" cy="4" r="4" fill={isTabletOn ? `${semanticColors.content.success}` : `${semanticColors.content.caution}`} />
  </svg>
);
