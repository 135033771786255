import * as Sentry from '@sentry/browser';
import { forOwn } from 'lodash';

// Sentry Exception Logging
export function logException(message, context, tags) {
  const err = new Error(message);

  Sentry.withScope(scope => {
    forOwn(context, (value, key) => {
      scope.setExtra(key, value);
    });
    forOwn(tags, (value, key) => {
      scope.setTag(key, value);
    });

    Sentry.captureException(err);
  });

  window.console && console.error && console.error(message);
}

// Sentry Message Logging
export function logMessage(message, context, level) {
  Sentry.captureMessage(message, {
    extra: context,
    level: level, // one of 'info', 'warning', or 'error'
  });

  window.console && console.info && console.info(message, context, level);
}

export function initSentry(version) {
  const sentryOptions = {
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Ignore failed localStorage capability checks which will throw errors if user has strict browser
      // privacy settings or is in private browsing mode
      "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
      // This is also almost always due to storage capability checks being denied due to strict browser
      // privacy settings or private browsing mode
      'The operation is insecure.',
      // Generic / inspecific errors usually caught by window handler w/o any valuable context
      /object Object/i,
      "Cannot read property '_avast_submit' of undefined",
      // Chrome Bug: https://bugs.chromium.org/p/chromium/issues/detail?id=590375
      "undefined is not an object (evaluating '__gCrWeb.autofill.extractForms')",
      /from accessing a frame with origin "https:\/\/staticxx\.facebook\.com"/i,
      /from accessing a frame with origin "https:\/\/accounts\.google\.com"/i,
      "Can't execute code from a freed script",
      "Can't find variable: auto",
      "Can't find variable: chrome",
      /Can't find variable: SymBrowser_/i,
      /*
      Windows IE 11 desktop heap memory limits:
      https://support.microsoft.com/en-us/help/947246/you-may-
      receive-an-out-of-memory-error-message-because-of-the-desktop
      */
      'Out of memory',
      // Yandex referrer error
      'Access is denied.',
      'GetCurrentPosition component failed - err:',
      // Firefox Bug: https://github.com/getsentry/sentry-javascript/issues/2186
      "null is not an object (evaluating 'n.title')",
      "null is not an object (evaluating 'a.title')",
      // Google Translate Extension error: https://github.com/getsentry/sentry-javascript/issues/2418
      'a[b].target.className.indexOf is not a function',
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
      // https://community.adobe.com/t5/captivate/course-crashing-in-internet-explorer
      // -after-45-minutes-memory-leak-issue/td-p/11045967
      // https://social.msdn.microsoft.com/Forums/en-US/5a3381c5-b7fd-4277-80a8-61e2debbf525
      // /exception-gpu-device-instance-has-been-suspended?forum=winappswithhtml5
      // eslint-disable-next-line max-len
      'The GPU device instance has been suspended. Use GetDeviceRemovedReason to determine the appropriate action.',
      // eslint-disable-next-line max-len
      // Safari bug: https://stackoverflow.com/questions/65839034/sentry-error-indexsizeerror-the-index-is-not-in-the-allowed-range
      'IndexSizeError: The index is not in the allowed range.',
      // Firefox only error thrown when trying to call something before it's init'ed
      'NS_ERROR_NOT_INITIALIZED',
      // Only happening on Andriod Facebook Browser for 11 users. Not blocking user from checking out.
      // https://chownow.atlassian.net/browse/CN-13357
      'SyntaxError: Unexpected token >',
      // CN-14807 ignore certain TypeErrors that won't be addressed
      'TypeError: cancelled',
      'TypeError: Failed to fetch',
      // https://github.com/getsentry/sentry-javascript/issues/3040
      "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers')",
      // eslint-disable-next-line max-len
      "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedTextHandler.postMessage')",
      'TypeError: Preflight response is not successful',
      'TypeError: Network request failed',
      'TypeError: Failed to fetch',
      'TypeError: The request timed out.',
      'TypeError: The Internet connection appears to be offline.',
      'The network connection was lost.',
      // eslint-disable-next-line max-len
      "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    whitelistUrls: [/chownow\.com/],
  };

  /**
   * react-scripts / dotenv will fall back to the value in `.env` if the env
   * var is set to a falsey value (eg. '' or false) so a hard coded "disabled"
   * string value is used as a simple work around.
   */
  if (
    process.env.REACT_APP_SENTRY_URL &&
    process.env.REACT_APP_SENTRY_URL !== 'disabled'
  ) {
    Sentry.init({
      ...sentryOptions,
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: process.env.REACT_APP_DEPLOY_ENV || process.env.DEPLOY_ENV,
      release: version,
    });
  }
}
