import styled from 'styled-components';
import { label } from 'styles/types';
import { space } from 'styles/variables';

export const Heading = styled.h2`
  font: ${label.semi.x16};
  text-align: left;
  width: 100%;
`;

export const RadioRow = styled.div`
  align-items: center;
  display: flex;
  font: ${label.reg.x14};
  margin-top: ${space.x3};
  text-align: left;
  width: 100%;
`;

export const RadioWrapper = styled.div`
  margin-right: ${space.x1};
`;
