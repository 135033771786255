import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { screen, space } from 'styles/variables';
import { Button } from '@chownow/cn-web-components';
import { Link } from 'react-router-dom';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PageFooterContainer = styled.div`
  background-color: ${semanticColors.surface.primary};
  bottom: 0;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.16);
  display: flex;
  height: ${space.x10};
  justify-content: center;
  left: 0;
  margin-bottom: 0;
  margin-top: auto;
  position: fixed;
  width: 100%;
  /* To account for the z-index of 10 on the LoadingSpinner */
  z-index: 11;
`;

export const PageFooterContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 ${space.x3};
  width: 100%;
`;

export const PreviousButton = styled(Button)`
  margin-right: ${space.half};
  width: 50%;

  @media (min-width: ${screen.smMin}) {
    width: 200px;
  }
`;

export const SecondaryButton = styled(Button)<{ $hasPrimaryCta: boolean }>`
  margin-left: ${({ $hasPrimaryCta }) => ($hasPrimaryCta ? space.half : undefined)};
  width: ${({ $hasPrimaryCta }) => ($hasPrimaryCta ? '50%' : '100%')};

  @media (min-width: ${screen.smMin}) {
    width: 200px;
  }
`;

export const LinkToHome = styled(Link) <{ $hasPrimaryCta?: boolean, $hasSaveButton?: boolean, $variant?: 'primary' | 'outline' }>`
  ${({ $variant }) => $variant === 'primary' && `
    background-color: ${semanticColors.surface.inversePrimary};
    color: ${semanticColors.content.inversePrimary};

    &:hover {
      background-color: ${semanticColors.surface.active};
    }

    @media (min-width: ${screen.smMin}) {
      width: 152px;
    }
  `};

  ${({ $variant }) => $variant === 'outline' && `
    border: 1px solid ${semanticColors.border.primary};
    color: ${semanticColors.content.primary};

    &:hover {
      border: 1px solid ${semanticColors.border.secondary};
    }

    @media (min-width: ${screen.smMin}) {
      // To account for 1px border on each side
      width: 150px;
    }
  `};

  border-radius: 28px;
  display: flex;
  font: ${label.reg.x14};
  height: ${space.x2};
  justify-content: center;
  line-height: 1.15;
  margin-left: ${({ $hasPrimaryCta }) => ($hasPrimaryCta ? space.half : undefined)};
  margin-right: ${({ $hasSaveButton }) => ($hasSaveButton ? space.half : undefined)};
  padding: ${space.x2} ${space.x3};
  text-decoration: none;
  transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;
  white-space: nowrap;
  width: ${({ $hasPrimaryCta, $hasSaveButton }) => ($hasPrimaryCta || $hasSaveButton ? '50%' : '100%')};
`;
