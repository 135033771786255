import React, { useState, useContext, FC } from 'react';
import { ModalContext } from 'context/ModalContext';

import { Input, Button } from '@chownow/cn-web-components';

import { RevisedTotal, StyledModal, Title, Total } from './styles';
import { formatMoney } from '../../OrderDetailsContainer/OrderDetails/OrderCard/helpers';

interface EditPriceModalProps {
  onSubmit: (revisedTotal: number) => void;
  total: number;
  name: string;
}

const EditPriceModal: FC<EditPriceModalProps> = ({ onSubmit, total, name }) => {
  const { hideModal } = useContext(ModalContext);
  const [value, setValue] = useState('');
  const revisedTotal = value ? parseFloat(value) : 0;

  const handleOnSubmit = () => {
    onSubmit(revisedTotal);
    hideModal();
  };

  return (
    <StyledModal>
      <Title>Edit {name}</Title>
      <Input
        name="editPrice"
        value={value}
        onChange={e => setValue(e)}
        placeholder={`${total}`}
        label="Edit Price"
        shouldHideLabel
      />
      <Total>
        <div>Total</div>
        <div>{formatMoney(total)}</div>
      </Total>
      <RevisedTotal>
        <div>Revised Total</div>
        <div>{formatMoney(revisedTotal)}</div>
      </RevisedTotal>
      <Button
        disabled={revisedTotal >= total || !value}
        label="Submit"
        onClick={handleOnSubmit}
        isFullWidth
      />
    </StyledModal>
  );
};

export default EditPriceModal;
