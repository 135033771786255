import React, { FC } from 'react';

import Tooltip from '../Tooltip';

import { LeftContent } from '../../styles';
import { Price } from '../styles';
import { PriceRow } from './styles';
import { formatMoney } from '../helpers';

interface PriceLineProps {
  className?: string;
  label?: string;
  amount?: number | null;
  isFirstElement?: boolean;
  isBold?: boolean;
  tooltipTitle?: string;
  tooltipMessage?: string | null;
}

const PriceLine: FC<PriceLineProps> = ({
  className,
  label,
  amount,
  isFirstElement,
  isBold,
  tooltipTitle,
  tooltipMessage,
}) => {
  if (!label || typeof amount !== 'number') {
    return null;
  }

  return (
    <PriceRow className={className} $isFirstElement={isFirstElement} $isBold={isBold}>
      <LeftContent>
        {tooltipTitle && tooltipMessage ? (
          <Tooltip
            title={tooltipTitle}
            message={tooltipMessage}
            alignment="top"
          >
            {label}
          </Tooltip>
        ) : (
          label
        )}
      </LeftContent>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
      <Price $isReduction={amount < 0}>{formatMoney(amount)}</Price>
    </PriceRow>
  );
};

export default PriceLine;
