import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { fontWeight, paragraph } from 'styles/types';
import { screen, space } from 'styles/variables';

export const CheckboxWrap = styled.div`
  display: inline-block;
  font: ${paragraph.reg.x14};
  margin-right: ${space.x1};
`;

export const OriginalAmount = styled.div`
  color: ${semanticColors.content.secondary};
  display: inline-block;
  margin-right: ${space.x2};
  text-decoration: line-through;
`;

export const SubItem = styled.div<{ $hasNoMods: boolean}>`
  align-items: center;
  border-bottom: ${({ $hasNoMods }) => ($hasNoMods ? 0 : `1px solid ${semanticColors.border.primary}`)};
  flex-basis: 150px;
  justify-content: space-between;
  max-width: 100%;
  padding: ${({ $hasNoMods }) => ($hasNoMods ? 0 : `${space.x3} 0`)};
  position: relative;
  vertical-align: middle;

  &:first-child {
    min-height: 26px;
    padding-top: 0;
  }

  &:nth-last-child(2) {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Amount = styled.div<{ $isSelected: boolean; $isParent: boolean }>`
font: ${({ $isParent }) => ($isParent ? paragraph.semi.x14 : paragraph.reg.x14)};
  text-align: right;

  ${({ $isSelected }) =>
    $isSelected &&
    `
    color: ${semanticColors.content.active};
    display: inline-block;
    font-weight: ${fontWeight.semiBold};
  `}
`;

export const Quantity = styled.select`
  border: 0;
  cursor: pointer;
  position: relative;
`;

export const CategoryName = styled.span`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.reg.x14};
`;

export const Flex = styled.div<{ $disabledForMobile?: boolean }>`
  display: ${({ $disabledForMobile }) => !$disabledForMobile && 'flex'};
  flex: 1;

  @media (min-width: ${screen.smMin}) {
    display: flex;
  }
`;

export const IndentSpacer = styled.div<{ $indentLevel: number }>`
  width: ${({ $indentLevel }) => $indentLevel * 12}px;
  @media (min-width: ${screen.smMin}) {
    width: ${({ $indentLevel }) => $indentLevel * 24}px;
  }
`;

export const ItemName = styled.div<{ $isFree: boolean, $isParent: boolean, $indentLevel: number, $isReadOnly: boolean }>`
  flex: 1;
  font: ${({ $isParent }) => ($isParent ? paragraph.semi.x14 : paragraph.reg.x14)};

  @media (min-width: ${screen.smMin}) {
    margin-bottom: 0;
    margin-top: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DetailsWrap = styled.div<{ $isReadOnly: boolean; $indentLevel: number }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ $indentLevel }) => $indentLevel * 12}px;
  margin-top: ${space.x2};

  @media (min-width: ${screen.smMin}) {
    margin-left: 5%;
    margin-top: 0;
    ${({ $isReadOnly }) => !$isReadOnly && 'flex: 1;'};
  }

  @media (max-width: ${screen.smMin}) {
    ${({ $isReadOnly }) => $isReadOnly && 'display: none;'};
  }
`;

export const NestedModifiers = styled.div`
  margin-left: ${space.x3};
  width: 100%;
`;

export const SpecialInstructions = styled.div`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.italic.x14};
  margin-top: ${space.x2};
`;
