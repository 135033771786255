import { getCountdownTime } from 'helpers/dateTimeOld';

export interface CountdownResult {
  countdown: string;
  timerEnd: boolean;
}

export const calculateCountdown = (currentDate: Date, endTime: Date | null): CountdownResult => {
  if (!endTime) return { countdown: '', timerEnd: true };

  const getTimeDifference = getCountdownTime(currentDate, endTime);
  const [days, hours, minutes, seconds] = getTimeDifference;
  const timerEnd = days < 0; // days return negative for non-active closures

  let countdown = '';
  const labelDays = days === 1 ? 'day' : 'days';
  const labelSeconds = seconds === 1 || seconds === 0 ? 'sec' : 'secs';
  const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

  if (days > 0) {
    countdown = `${days} ${labelDays}`;
  } else if (minutes < 1) {
    countdown = `${seconds} ${labelSeconds}`;
  } else {
    countdown = `${formattedHours}:${formattedMinutes}`;
  }

  return { countdown, timerEnd };
};
