import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { screen, space } from 'styles/variables';
import { label } from 'styles/types';

export const CardInfoWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;

  @media (min-width: ${screen.smMin}) {
    margin: 0;
    max-width: 296px;
  }

  @media (min-width: ${screen.xsMin}) {
    max-width: 296px;
  }
`;

export const Day = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: space-between;

  @media (min-width: ${screen.xsMin}) {
    justify-content: flex-start;
  }
`;

export const DayAndTimeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x3};
`;

export const DayTitle = styled.h4`
  color: ${semanticColors.content.primary};
  font: ${label.reg.x14};
  margin: 0 ${space.x2} 0 0;
  width: 80px;
`;

export const Hours = styled.div`
  color: ${semanticColors.content.primary};
`;

export const Range = styled.div`
  color: ${semanticColors.content.primary};
  font: ${label.reg.x14};
  margin-bottom: ${space.x1};
`;
