import React, { FC } from 'react';

import { ReadyTimeButton } from './styles';

interface ReadyTimeProps {
  readyTime: string | null;
  showReadyTimeModal: () => void;
}

const ReadyTime: FC<ReadyTimeProps> = ({
  readyTime,
  showReadyTimeModal,
}) => {
  const isLoading = readyTime !== null && Number.isNaN(parseInt(readyTime, 10));
  const readyTimeSet = readyTime !== null;

  const onReadyTimeClick = () => {
    if (showReadyTimeModal) {
      showReadyTimeModal();
    }
  };

  return (
    <ReadyTimeButton
      dataTestId="ready-time-button"
      isLoading={isLoading}
      onClick={onReadyTimeClick}
      label={
        !readyTimeSet
          ? 'Set Ready Time'
          : readyTimeSet && `Ready Time · ${readyTime}min`
      }
    />
  );
};

export default ReadyTime;
