import React, { useState, useEffect } from 'react';

import { restaurantType } from 'helpers/prop-types';
import { getRestaurantMedia } from 'api/restaurant';

import MenuList from 'components/MenuList';
import BannerLogo from './BannerLogo';
import UploadSideBar from './UploadSideBar';
import ContentContainer from 'components/ContentContainer';
import ThirdPartySotMessaging from '../Restaurant/ThirdPartyMenuSotMessaging';

import { THIRD_PARTY_MENU_SOURCE } from 'helpers/constants';

import { CatalogContext } from 'context/CatalogContext';

function Photos(props) {
  const { restaurant } = props;
  const [selectedItem, setSelectedItem] = useState();
  const [media, setMedia] = useState({});
  // direct users to third party menu sot messaging if this is true
  const isThirdPartyMenuSot = restaurant?.has_third_party_menu_source;

  useEffect(() => {
    const requestRestaurantMedia = async () => {
      const response = await getRestaurantMedia(restaurant.id);

      if (!response.errors) {
        setMedia(response);
      }
    };

    requestRestaurantMedia();
  }, [restaurant]);

  function handleCloseSideBar() {
    setSelectedItem();
  }

  function updateMedia(mediaType, response) {
    setMedia({ ...media, [mediaType]: response });
  }

  return (
    <>
      <BannerLogo
        media={media}
        restaurant={restaurant}
        toggleSideBar={setSelectedItem}
      />
      <ContentContainer>
        {isThirdPartyMenuSot ? (
          <>
            <ThirdPartySotMessaging
              isManagePhotos
              isCuboh={restaurant?.third_party_menu_source === THIRD_PARTY_MENU_SOURCE.cuboh}
            />
            <CatalogContext.Provider value={{ updateMedia }}>
              <UploadSideBar
                closeSideBar={handleCloseSideBar}
                media={media}
                item={selectedItem}
                companyId={restaurant.company_id}
                restaurantId={restaurant.id}
              />
            </CatalogContext.Provider>
          </>
        ) : (
          <MenuList
            updateMedia={updateMedia}
            restaurant={restaurant}
            setSelectedItem={setSelectedItem}
            header={'Select an item to add a photo'}
          >
            <UploadSideBar
              closeSideBar={handleCloseSideBar}
              media={media}
              item={selectedItem}
              companyId={restaurant.company_id}
              restaurantId={restaurant.id}
            />
          </MenuList>
        )}
      </ContentContainer>
    </>
  );
}

Photos.propTypes = {
  restaurant: restaurantType,
};

export default Photos;
