import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as CashIcon } from 'images/icon-cash.svg';
import { ReactComponent as CloseIcon } from 'images/icon-close.svg';
import { ReactComponent as DrinkIcon } from 'images/icon-drink.svg';
import { ReactComponent as ReceiptIcon } from 'images/icon-receipt.svg';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';

import { RefundIcon } from '../styles';
import { Card, CloseButton, Heading, RefundButton, Title } from './styles';
import {
  canRefundOrder,
  canRefundOrderByAmount,
  canRefundOrderByItem,
  canRefundOrderFull,
} from '../../helpers';

interface MobileRefundMenuProps {
  onFullRefundClick: () => void;
  onRefundByAmountClick: () => void;
  order: OrderDetailsV6GetSuccessResponse;
}

const MobileRefundMenu: FC<MobileRefundMenuProps> = ({
  onFullRefundClick,
  onRefundByAmountClick,
  order,
}) => {
  const navigate = useNavigate();
  const flags = useFlags();

  if (!canRefundOrder(order, flags.refundByAmountRedesign as boolean)) {
    return null;
  }

  const buttons = [];

  if (canRefundOrderFull(order)) {
    buttons.push(
      <RefundButton key="full-refund" type="button" onClick={onFullRefundClick}>
        <RefundIcon>
          <ReceiptIcon />
        </RefundIcon>
        Full Refund
      </RefundButton>
    );
  }
  if (canRefundOrderByItem(order)) {
    buttons.push(
      <RefundButton
        key="refund-by-item"
        type="button"
        onClick={() => navigate('partial-refunds')}
      >
        <RefundIcon>
          <DrinkIcon />
        </RefundIcon>
        Refund by Item
      </RefundButton>
    );
  }
  if (flags.refundByAmountRedesign && canRefundOrderByAmount(order)) {
    buttons.push(
      <RefundButton
        key="refund-by-amount"
        type="button"
        onClick={onRefundByAmountClick}
      >
        <RefundIcon>
          <CashIcon />
        </RefundIcon>
        Refund by Amount
      </RefundButton>
    );
  }

  return (
    <Card
      onClick={e =>
        Array.from(document.querySelectorAll('button')).every(
          el => !el.contains(e.target as HTMLElement)
        ) && e.stopPropagation()}
    >
      <Heading>
        <Title>Refunds</Title>
        <CloseButton icon={CloseIcon} variant="link" />
      </Heading>
      {buttons}
    </Card>
  );
};

export default MobileRefundMenu;
