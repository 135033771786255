import React, { FC } from 'react';

import { ReactComponent as CloseIcon } from 'images/icon-close.svg';
import { ReactComponent as CheckIcon } from 'images/icon-badge-check.svg';
import { ReactComponent as UnavailableIcon } from 'images/icon-unavailable.svg';

import { AcknowledgeIcon } from '../../styles';
import { Title } from '../styles';
import { AcknowledgeButton, Card, CloseButton, Heading } from './styles';

interface MobileAcknowledgeMenuProps {
  onAccept: () => void;
  onCancel: () => void;
}

const MobileAcknowledgeMenu: FC<MobileAcknowledgeMenuProps> = ({
  onAccept,
  onCancel,
}) => (
  <Card
    onClick={e =>
      Array.from(document.querySelectorAll('button')).every(
        el => !el.contains(e.target as HTMLElement)
      ) && e.stopPropagation()}
  >
    <Heading>
      <Title>Accept or Cancel</Title>
      <CloseButton icon={CloseIcon} variant="link" />
    </Heading>
    <AcknowledgeButton type="button" onClick={onAccept}>
      <AcknowledgeIcon>
        <CheckIcon />
      </AcknowledgeIcon>
      Accept Order
    </AcknowledgeButton>
    <AcknowledgeButton type="button" onClick={onCancel}>
      <AcknowledgeIcon>
        <UnavailableIcon />
      </AcknowledgeIcon>
      Cancel Order
    </AcknowledgeButton>
  </Card>
);

export default MobileAcknowledgeMenu;
