import styled from 'styled-components';
import { ReactComponent as IconBadgeCheck } from 'images/icon-badge-check-md.svg';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const VerifiedCardContainer = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.primary};
  display: flex;
  font: ${label.semi.x16};
  justify-content: center;
  margin-bottom: 142px;
  padding: ${space.x5};
`;

export const StyledIconBadgeCheck = styled(IconBadgeCheck)`
  margin-right: ${space.x2};
  min-width: ${space.x3};

  path {
    fill: ${semanticColors.content.success};
  }
`;
