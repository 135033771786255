export const validateCustomETAInputValue = (inputValue: string): string => {
  const inputNumber = Number(inputValue);
  const isWholeNumber = inputNumber % 1 === 0;
  const isNumber = !Number.isNaN(inputNumber);
  const isWithinRange = inputNumber > 4 && inputNumber < 181;

  if (!isNumber) {
    return 'Must use numbers';
  }

  if (!isWholeNumber || !isWithinRange) {
    return 'Time must be between 5 and 180 minutes';
  }

  return '';
};
