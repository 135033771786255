import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import SquareMenu from 'containers/Restaurant/Integrations/SquarePos/SquareMenu';
import SquareNotFound from 'containers/Restaurant/Integrations/SquarePos/SquareNotFound';
import SquarePos from 'containers/Restaurant/Integrations/SquarePos';
import ToastPos from 'containers/Restaurant/Integrations/ToastPos';
import Restaurant from 'types/restaurant';

import IntegrationsList from './IntegrationsList';

interface IntegrationsProps {
  restaurant: Restaurant;
}
const Integrations: FC<IntegrationsProps> = ({ restaurant }) => (
  <Routes>
    <Route
      path="/*"
      element={<IntegrationsList restaurantId={restaurant.id} />}
    />
    <Route
      path="/square"
      element={<SquarePos restaurantId={restaurant.id} restaurant={restaurant} />}
    />
    <Route
      path="/square/not-found"
      element={<SquareNotFound restaurant={restaurant} />}
    />
    <Route
      path="/square/menu"
      element={<SquareMenu restaurantId={restaurant.id} />}
    />
    <Route
      path="/toast"
      element={<ToastPos restaurant={restaurant} />}
    />
  </Routes>
);

export default Integrations;
