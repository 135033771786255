import { useEffect, useState } from 'react';
import { requestCustomApi } from 'helpers/api';
import { FoodImageGetResponse, NoFoodImageOptions } from 'types/food-image';

const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

/**
 * Get food image data for Direct restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const useGetFoodImage = (uuid: string | null) => {
  const [isGetFoodImageRequestLoading, setIsGetFoodImageRequestLoading] =
    useState<boolean>(true);
  const [hasGetFoodImageRequestError, setHasGetFoodImageRequestError] =
    useState<boolean>(false);
  const [getFoodImageRequestResponse, setGetFoodImageRequestResponse] =
    useState<FoodImageGetResponse>();

  useEffect(() => {
    const getFoodImage = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: FoodImageGetResponse = await requestCustomApi(
          `v1/restaurant/${uuid}/food-image`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        // Remove the unnecessary id added by requestCustomApi
        delete response?.id;

        setGetFoodImageRequestResponse(response);
      } catch (err) {
        setHasGetFoodImageRequestError(true);
      } finally {
        setIsGetFoodImageRequestLoading(false);
      }
    };

    if (uuid) {
      void getFoodImage();
    }
  }, []);

  return {
    hasGetFoodImageRequestError,
    isGetFoodImageRequestLoading,
    getFoodImageRequestResponse,
  };
};

/**
 * Put food image data for Direct restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const usePutFoodImage = (uuid: string | null) => {
  const [isPutFoodImageRequestLoading, setIsPutFoodImageRequestLoading] =
    useState<boolean>(false);

  /**
   * Put food image data for Direct restaurant location
   * @param {boolean} hasFoodImage - Whether or not the restaurant has a food image
   * @param {NoFoodImageOptions} foodImageAltChoice - The choice if no image is provided
   */
  const putFoodImage = async (
    hasFoodImage: boolean,
    foodImageAltChoice: NoFoodImageOptions
  ) => {
    let hasError = false;

    setIsPutFoodImageRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/food-image`,
        env,
        // TODO: remove these once requestCustomApi() is converted over to
        // TS with optional params in CN-27549
        'PUT',
        {
          has_food_image: hasFoodImage,
          food_image_alt_choice: foodImageAltChoice,
        },
        undefined,
        undefined
      );
    } catch (err) {
      hasError = true;
    } finally {
      setIsPutFoodImageRequestLoading(false);
    }

    return hasError;
  };

  return {
    isPutFoodImageRequestLoading,
    putFoodImage,
  };
};
