import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import DELIVERY_FIXTURE from './GET-200-delivery-new';
import { ORDER_AHEAD_FIXTURE } from './GET-200-base-fixure';

const DELIVERY_FUTURE_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...DELIVERY_FIXTURE,
  is_order_ahead: true,
  fulfillment_times: {
    ...ORDER_AHEAD_FIXTURE,
  },
};

export default DELIVERY_FUTURE_FIXTURE;
