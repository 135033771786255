import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { space } from 'styles/variables';
import { ReactComponent as IconArrowOut } from 'images/icon-arrow-out-sm.svg';

export const Divider = styled.div`
  background-color: ${semanticColors.surface.tertiary};
  height: 1px;
  width: 100%;
`;

export const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: ${space.x2};
`;

export const StyledIconArrowOut = styled(IconArrowOut)`
  path {
    fill: ${semanticColors.content.primary};
  }
`;

export const StyledLink = styled.a`
  display: flex;
  margin-right: ${space.x1};
  text-decoration: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;
