import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { ReactComponent as IconSuccess } from 'images/icon-success.svg';

import styles from './styles.module.scss';

const ALERT_TIMEOUT = 3000;
let alertTimer;

function AlertTopBar(props) {
  const { message, setShowAlert, showAlert } = props;
  const [alertVisibility, setAlertVisibility] = useState();

  const defaultSpringProps = {
    transform: `translateY(-100%)`,
    opacity: 0,
    config: {
      tension: 250,
      friction: 20
    }
  };
  const [springProps, setSpringProps] = useSpring(() => defaultSpringProps);

  useEffect(() => clearTimeout(alertTimer), []);

  useEffect(() => {
    setSpringProps({
      ...defaultSpringProps.config,
      transform: alertVisibility
        ? `translateY(0)`
        : defaultSpringProps.transform,
      opacity: alertVisibility ? 1 : defaultSpringProps.opacity
    });
  }, [alertVisibility]);

  useEffect(() => {
    if (showAlert) {
      clearTimeout(alertTimer);
      setShowAlert();
      setAlertVisibility(true);

      alertTimer = setTimeout(() => {
        setAlertVisibility(false);
      }, ALERT_TIMEOUT);
    }
  }, [showAlert]);

  return (
    <div className={styles.alert}>
      <animated.div
        className={styles.alertContent}
        style={{
          transform: springProps.transform,
          opacity: springProps.opacity
        }}
      >
        <IconSuccess className={styles.iconSuccess} />
        <span className={styles.alertText}>{message}</span>
        <IconClose
          className={styles.iconClose}
          onClick={() => setAlertVisibility(false)}
        />
      </animated.div>
    </div>
  );
}

AlertTopBar.propTypes = {
  message: PropTypes.string,
  setShowAlert: PropTypes.func,
  showAlert: PropTypes.bool
};

export default AlertTopBar;
