import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import LoadingSpinner from 'components/LoadingSpinner';
import Footer from 'components/Footer';
import { Cover } from '@chownow/cn-web-components';

import { sanitizeAdminUrl } from './helpers';

import { Container, StyledIFrame, LoadingWrapper } from './styles';

interface EmbeddedAdminProps {
  src: string;
  title: string;
}

// DEV-NOTE: This component must have a key attribute otherwise it will not
// re - mount on a route switch
const EmbeddedAdmin: FC<EmbeddedAdminProps> = ({ src, title }) => {
  const adminUrl = process.env.REACT_APP_ADMIN_PYTHON_APP_URL;
  const flags = useFlags();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleOnLoad = (e: MessageEvent) => {
    const iframeLocation = get(e.data, 'cn_location') as string;
    const isAdminRestaurantUrl = /^\/restaurant\/\d+$/.test(iframeLocation);

    // Update url when navigating to location within i-frame
    if (isAdminRestaurantUrl) {
      navigate(`${iframeLocation}/settings/location-info`);
    }

    if (iframeLocation) setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    window.addEventListener('message', handleOnLoad);

    return () => window.removeEventListener('message', handleOnLoad);
  }, []);

  return (
    <>
      <Cover title={title} />
      <Container $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}>
        {isLoading && (
          <LoadingWrapper>
            <LoadingSpinner isAbsolute={false} withWrapper={false} />
          </LoadingWrapper>
        )}
      </Container>
      <Container $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}>
        <StyledIFrame
          src={`${sanitizeAdminUrl(adminUrl ?? '')}/${src}`}
          frameBorder="0"
          title="admin"
        />
        <Footer embeddedAdmin />
      </Container>
    </>
  );
};

export default EmbeddedAdmin;
