import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StatusDot from '@chownow/cocina-react-statusdot';
import { borderRadius, space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { caption, label, paragraph } from 'styles/types';

export const Divider = styled.div`
  background-color: ${semanticColors.border.primary};
  height: 1px;
  width: 100%;
`;

export const Title = styled.h2`
  font: ${label.md.x20};
`;

export const StyledLink = styled(Link)`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.primary};
  max-width: 100%;
  text-decoration: none;

  &:hover {
    background-color: ${semanticColors.surface.secondary};
  }
`;

export const Subtext = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x2} 0 0;
`;

export const Status = styled.div`
  font: ${caption.md.x10};
`;

export const StatusTextWrapper = styled.div`
  display: flex;
`;

export const StatusWrapper = styled.div`
  align-items: center;
  border-radius: 0 0 ${borderRadius.default} ${borderRadius.default};
  display: flex;
  justify-content: space-between;
  padding: ${space.x2} ${space.x3};
`;

export const StyledStatusDot = styled(StatusDot)`
  margin-right: ${space.x1};
`;

export const TextWrapper = styled.div`
  border-radius: ${borderRadius.default} ${borderRadius.default} 0 0;
  padding: ${space.x3};
`;
