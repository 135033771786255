export enum PhoneNumberFormat {
  PARENS = 'parens',
  DASHES = 'dashes',
}

/**
 * Expected input format: 1112223333
 * Default output format: 111-222-3333
 *
 * Output format can be specified by passing in a second argument using the PhoneNumberFormat enum
 * PhoneNumberFormat.PARENS: (111) 222-3333
 * PhoneNumberFormat.DASHES: 111-222-3333
 *
 * @param {string} phoneNumberString - Phone number string to prettify
 * @param {PhoneNumberFormat} PhoneNumberFormat - Format to return the phone number in
 */
export const prettifyPhoneNumber = (
  phoneNumberString: string,
  format: PhoneNumberFormat = PhoneNumberFormat.DASHES
) => {
  const cleaned = `${phoneNumberString.replace(/\D/g, '')}`;
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    switch (format) {
      case PhoneNumberFormat.PARENS:
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      case PhoneNumberFormat.DASHES:
        return `${match[1]}-${match[2]}-${match[3]}`;
      default:
        return `${match[1]}-${match[2]}-${match[3]}`;
    }
  } else {
    return phoneNumberString;
  }
};
