import styled from 'styled-components';
import { containerWidths, space } from 'styles/variables';
import Table from 'components/Table';
import StatusDot from '@chownow/cocina-react-statusdot';

interface CompanyTdProps {
  $isCompanyOrdersList: boolean;
}

export const StyledTable = styled(Table)`
  width: ${containerWidths.containerWidth};
`;

export const StatusWrap = styled.div`
  align-items: center;
  display: flex;
`;

export const ColumnLocation = styled.td`
  max-width: 100px;
`;

export const StyledStatusDot = styled(StatusDot)`
  margin-right: ${space.x1};
`;

export const Capitalize = styled.td`
  text-transform: capitalize;
`;

export const CompanyTd = styled.td<CompanyTdProps>`
  ${(props) =>
    props.$isCompanyOrdersList === true &&
    `
    width: 80px;
  `}
`;
