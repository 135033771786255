import styled from 'styled-components';
import { space } from 'styles/variables';
import Switch from '@chownow/cocina-react-switch';

export const SyncMenuSwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: ${space.x5} 0;
`;

export const StyledSwitch = styled(Switch)`
  margin-right: ${space.x2};
`;
