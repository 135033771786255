import React, { FC, useContext, useState } from 'react';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';

import { requestOrdersList } from 'api/orders/ordersList';
import { Filters, Order, OrdersListResponse } from 'types/orders-list';
import { Company } from 'types/company';
import { ModalContext } from 'context/ModalContext';

import useErrorHandler from 'hooks/useErrorHandler';
import { CONNECTION_FAILED_MODAL_COPY } from 'helpers/modals';
import ConnectionFailedModal from 'components/Modals/ConnectionFailedModal';

import { Cover } from '@chownow/cn-web-components';
import Banner from '@chownow/cocina-react-banner';
import OrdersList from 'components/OrdersList';

interface OrdersCompanyProps {
  company: Company;
}

const OrdersCompany: FC<OrdersCompanyProps> = ({ company }) => {
  const currentTime = format(new Date(), 'h:mm a');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrdersListResponse | null>(null);
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const { showModal } = useContext(ModalContext);
  const companyId = company?.id;

  useErrorHandler(hasError, () => {
    showModal(ConnectionFailedModal, {
      message: CONNECTION_FAILED_MODAL_COPY.ordersListError.message,
      title: 'Something went wrong.',
    });
  });

  const fetchOrdersList = async (filters: Filters, prevNextUrl?: string) => {
    setIsLoading(true);
    const paginatedUrl = prevNextUrl?.replace(`${process.env.REACT_APP_API_HOST}/`, '');
    try {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const ordersResponse = await requestOrdersList(
        'company',
        companyId,
        filters,
        paginatedUrl
      ) as OrdersListResponse;

      if (Array.isArray(ordersResponse.errors) && ordersResponse.errors.length > 0) {
        throw new Error();
      }

      setOrders(ordersResponse);
      setOrdersList(ordersResponse.results || []);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet title="Orders" />
      {errorMessage && <Banner variant="caution">{errorMessage}</Banner>}
      <Cover title="Orders" body={`Last Updated: ${currentTime}`} />
      <OrdersList
        companyId={company.id}
        isCompanyOrdersList
        isLoading={isLoading}
        orders={orders}
        ordersList={ordersList}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        fetchOrdersList={fetchOrdersList}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default OrdersCompany;
