export enum UploadStatus {
  UPLOADED = 'uploaded',
  UPLOAD_FAILED = 'upload_failed',
}

export interface MenuAsset {
  filename: string;
  file_id: string;
  notes: string;
  key: string;
  status: string;
  upload_status?: UploadStatus;
  created_at: string;
  updated_at: string;
}

export type MenuAssetsResponse = Array<MenuAsset>;

export interface MenuLink {
  url: string;
  notes?: string | null;
  created_at: string;
  updated_at: string;
}

export type MenuLinksResponse = Array<MenuLink>;

export interface MenuAssetsUploadFields {
  AWSAccessKeyId: string;
  acl: string;
  key: string;
  policy: string;
  signature: string;
  'x-amz-security-token': string;
}

export interface MenuAssetsUploadUrlResponse {
  fields: MenuAssetsUploadFields;
  file_id: string;
  url: string;
}
