import React, { FC } from 'react';
import Restaurant from 'types/restaurant';
import { getCardTitle } from 'containers/Restaurant/Integrations/ToastPos/helpers';
import RenderHours from '../RenderHours';
import { EmptyStateMessage, ScheduleCardWrapper, ScheduleWrapper, StateWrapperEmpty, Title } from './styles';

interface ScheduleProps {
  restaurant: Restaurant;
}

const Schedule: FC<ScheduleProps> = ({ restaurant }) => {
  const hasSchedule = Object.values(restaurant.fulfillment || {}).some(
    (fulfillment) =>
      fulfillment && fulfillment.display_hours && fulfillment.display_hours.length > 0
  );
  const { pickup, delivery, curbside, dine_in } = restaurant.fulfillment;

  const hasPickupSchedule = !!(pickup && pickup?.display_hours?.length > 0);
  const hasDeliverySchedule = !!(delivery && delivery?.display_hours?.length > 0);
  const hasCurbsideSchedule = !!(curbside && curbside?.display_hours?.length > 0);
  const hasDineInSchedule = !!(dine_in && dine_in?.display_hours?.length > 0);

  const cardTitle = getCardTitle(
    hasCurbsideSchedule,
    hasDineInSchedule
  );

  let content = null;

  if (!hasSchedule) {
    content = (
      <StateWrapperEmpty>
        <EmptyStateMessage>No Schedules Available</EmptyStateMessage>
      </StateWrapperEmpty>
    );
  } else {
    content = (
      <div>
        {(hasSchedule) && (
          <ScheduleCardWrapper>
            {hasPickupSchedule && (
              // Pickup hours always exists and is the source of truth for curbside and dine-in
              <RenderHours
                title={cardTitle}
                displayHours={pickup.display_hours}
                fulfillmentType="pickup"
              />
            )}
            {hasDeliverySchedule && (
              <RenderHours
                title="Delivery Hours"
                displayHours={delivery.display_hours}
                fulfillmentType="delivery"
              />
            )}
          </ScheduleCardWrapper>
        )}
      </div>
    );
  }

  return (
    <ScheduleWrapper>
      <Title>Schedule</Title>
      {content}
    </ScheduleWrapper>
  );
};

export default Schedule;
