import React, { FC } from 'react';

import DialogModal from 'components/Modals/DialogModal';

interface ConnectionFailedModalProps {
  message?: string;
  title?: string;
}

const ConnectionFailedModal: FC<ConnectionFailedModalProps> = ({
  message,
  title,
}) => {
  const modalMessage =
    message ||
    'An error occurred loading restaurant information.<br />Please try again.';
  const modalTitle = title || 'Whoops!';
  return (
    <DialogModal
      message={modalMessage}
      onClose={() => window.location.reload()}
      title={modalTitle}
    />
  );
};

export default ConnectionFailedModal;
