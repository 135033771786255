import styled from 'styled-components';
import { containerWidths, screen, space } from 'styles/variables';

export const ContentContainerWrapper = styled.div<{ $isOldAdminBannerDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${containerWidths.containerWidthNarrow};
  min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '322px' : '261px')});
  padding: ${space.x5} ${space.x2} 0;
  width: calc(100% - ${space.x4});

  @media (min-width: ${screen.lgMin}) {
    max-width: ${containerWidths.containerWidth};
    padding-left: 0;
    padding-right: 0;
  }
`;
