// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryHeaderWrap_12068970797349{margin-top:-1px}.activeCategory_12068970797349{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:180px}", "",{"version":3,"sources":["webpack://./src/components/MenuList/CategoryHeader/styles.module.scss"],"names":[],"mappings":"AAKA,mCACE,eAAA,CAGF,+BACE,aAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":["@import '~styles/variables';\n\n// Category Header Styles\n// ---------------------------------\n\n.categoryHeaderWrap {\n  margin-top: -1px;\n}\n\n.activeCategory {\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryHeaderWrap": "categoryHeaderWrap_12068970797349",
	"activeCategory": "activeCategory_12068970797349"
};
export default ___CSS_LOADER_EXPORT___;
