import CardInfo from 'components/CardInfo';
import ContentContainer from 'components/ContentContainer';
import styled from 'styled-components';
import { screen, space } from 'styles/variables';

export const StyledCardInfo = styled(CardInfo)`
  margin-bottom: ${space.x3};
`;

export const StyledContentContainer = styled(ContentContainer)<{ $isOldAdminBannerDisplayed: boolean }>`
  min-height: calc(100vh - 308px);

  @media (min-width: ${screen.lgMin}) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '316px' : '261px')});
  }
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div<{ $isFirstElement?: boolean }>`
  display: flex;

  ${({ $isFirstElement }) => !$isFirstElement && `margin-top: ${space.x3}`};
`;

export const ItemRow = styled.div<{ $isLastElement?: boolean }>`
  border-bottom: 1px solid red;
  display: flex;

  ${({ $isLastElement }) => $isLastElement && 'border-bottom: none'};
`;

export const LeftContent = styled.div`
  display: flex;
  margin-right: ${space.x2};
  min-width: 104px;
`;

export const LeftContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${space.x2};
  min-width: 104px;
`;

export const OrderDetailsContent = styled.div`
  margin-bottom: ${space.x4};
`;

export const RightContent = styled.span`
  display: flex;
  word-break: break-word;
`;
