import { Modal } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { space } from 'styles/variables';

export const StyledModal = styled(Modal)`
  color: ${semanticColors.content.primary};
  position: fixed;
  z-index: 1;
`;

export const Title = styled.h3`
  font: ${label.semi.x20};
  margin-bottom: ${space.x3};
`;

export const Message = styled.p`
  font: ${paragraph.reg.x14};
  margin-bottom: ${space.x3};
  margin-top: 0;
`;
