export interface StatusData {
  status: 'completed' | null;
  is_required: boolean;
  is_displayable: boolean;
  created_at: string | null;
  updated_at: string | null;
}

export interface ProgressResponse {
  delivery_details: StatusData;
  schedule: StatusData;
  shared_menu: StatusData;
  stripe_verification: StatusData;
  tax_rate: StatusData;
  website_access: StatusData;
  promotion: StatusData;
  logo_and_food_image: StatusData;
}

export enum OnboardingProgressStatus {
  COMPLETED = 'completed',
}

/* Must keep these in the same order as they are in the Wizard to ensure the missing steps
 * are displayed in the correct order on the homepage.
 * All possible Wizard steps (in order) that could come from the Progress API response
 */
export enum OnboardingWizardPages {
  SHARED_MENU = 'shared_menu',
  TAX_RATE = 'tax_rate',
  SCHEDULE = 'schedule',
  WEBSITE_ACCESS = 'website_access',
  LOGO_AND_FOOD_IMAGE = 'logo_and_food_image',
  PROMOTION = 'promotion',
  STRIPE_VERIFICATION = 'stripe_verification',
}

/* Path values for each Wizard step including the welcome page */
export enum WizardNavPagePaths {
  WELCOME = 'welcome',
  SHARE_MENU = 'share-menu',
  SALES_TAX = 'sales-tax',
  RESTAURANT_HOURS = 'restaurant-hours',
  WEBSITE_ACCESS = 'website-access',
  LOGO_AND_FOOD_IMAGE = 'logo-and-food-image',
  PROMOTION = 'promotion',
  PAYMENT_PROCESSING = 'payment-processing',
}
