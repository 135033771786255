import React, { FC } from 'react';
import { Tag } from '@chownow/cn-web-components';
import {
  StyledList,
  StyledListMiddleware,
  StyledUnorderedList,
  StyledStatusDot,
  TagContainer,
} from './styles';

interface TabletPopoverContentProps {
  isAutoAcceptEnabled?: boolean;
  isTablet?: boolean;
  isTabletOn: boolean;
  middlewareOrPosName?: Array<string>;
  tabletLastSeen: string;
  tabletVersion: string;
}

const TabletPopoverContent: FC<TabletPopoverContentProps> = ({
  isAutoAcceptEnabled,
  isTablet,
  isTabletOn,
  middlewareOrPosName,
  tabletLastSeen,
  tabletVersion,
}) => (
  <StyledUnorderedList>
    {isTablet && (middlewareOrPosName?.length ?? 0) > 0 && (
      <StyledListMiddleware>
        {middlewareOrPosName &&
          Array.isArray(middlewareOrPosName) &&
          middlewareOrPosName.map((item: string) => (
            <TagContainer key={item}>
              <Tag label={item} size="small" variant="outlined" />
            </TagContainer>
          ))}
      </StyledListMiddleware>
    )}
    <StyledList>
      <StyledStatusDot
        size="small"
        variant={isTabletOn ? 'success' : 'caution'}
      />
      Tablet is {isTabletOn ? 'on' : 'off'}
    </StyledList>
    <StyledList $noStatusDot>Version {tabletVersion}</StyledList>
    <StyledList $noStatusDot>Last seen {tabletLastSeen}</StyledList>
    <StyledList>
      <StyledStatusDot
        size="small"
        variant={isAutoAcceptEnabled ? 'success' : 'neutral'}
      />
      Auto accept {isAutoAcceptEnabled ? 'enabled' : 'disabled'}
    </StyledList>
  </StyledUnorderedList>
);

export default TabletPopoverContent;
