import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { paragraph } from 'styles/types';
import { space } from 'styles/variables';
import SelectStandard from '@chownow/cocina-react-selectstandard';

export const HoursRowWRapper = styled.div`
  align-items: start;
  display: flex;
  justify-content: left;
  margin-top: ${space.x3};
  width: 100%;

  @media (min-width: 494px) {
    align-items: center;
  }
`;

export const ClosedBox = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.secondary};
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${space.x1};
  color: ${semanticColors.content.primary};
  display: flex;
  font: ${paragraph.reg.x16};
  height: 46px;
  width: 126px;
`;

export const ClosedText = styled.span`
  padding: ${space.x1} ${space.x2};
  width: 100%;
`;

export const CopyToAllContainer = styled.div`
  margin: 0;

  @media (min-width: 557px) {
    margin-left: auto;
  }
`;

export const Day = styled.span`
  font: $label-14-reg;
  margin-right: ${space.x2};
`;

export const DayCheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 142px;
  min-width: 132px;
  width: 100%;
`;

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${space.x2};
  width: 100%;
`;

export const StyledSelectStandard = styled(SelectStandard)`
  min-width: 128px;
`;

export const To = styled.span`
  display: none;
  font: ${paragraph.reg.x16};
  margin: 0;

  @media (min-width: 557px) {
    display: block;
  }
`;
