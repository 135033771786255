import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';

import Amex from './Icons/Amex';
import ApplePay from './Icons/ApplePay';
import Blank from './Icons/Blank';
import Cash from './Icons/Cash';
import Discover from './Icons/Discover';
import GooglePay from './Icons/GooglePay';
import Jcb from './Icons/Jcb';
import MasterCard from './Icons/MasterCard';
import Visa from './Icons/Visa';

import styles from './styles.module.scss';

export const creditCardTypeToImage = {
  1: Visa,
  2: MasterCard,
  3: Amex,
  4: Discover,
  5: ApplePay,
  6: GooglePay,
  7: GooglePay,
  8: ApplePay,
  10: Jcb,
};

function getMobilePayment(cardType) {
  if (cardType === 'GooglePay' || cardType === 'AndroidPay') {
    return 'Google Pay';
  }
  if (cardType === 'ApplePay' || cardType === 'ChowNow Apple Pay') {
    return 'Apple Pay';
  }
  return false;
}

export default function PaymentMethod({ customer, className, isPaymentDeclined }) {
  const card = get(customer, 'billing.card');
  const cardType = card && card.type;
  const typeID = card && card.type_id;
  const lastFour = get(customer, 'billing.card.last_four', '');
  const isCashPayment = !lastFour && !card;
  const SVG = creditCardTypeToImage[typeID];

  if (isCashPayment) {
    return (
      <div className={classnames(styles.wrapper, className)}>
        <Cash />
        <p className={styles.cardNumber}>Cash Only</p>
      </div>
    );
  }
  if (getMobilePayment(cardType)) {
    return (
      <div className={classnames(styles.wrapper, className)}>
        {/* if card is not listed as one of our mapped cards, display blank card */}
        {SVG ? <SVG /> : <Blank />}
        <p className={styles.cardNumber}>{getMobilePayment(cardType)}</p>
      </div>
    );
  }

  return (
    <div className={classnames(styles.wrapper, className)}>
      {/* if card is not listed as one of our mapped cards, display blank card */}
      {SVG ? <SVG /> : <Blank />}
      <p className={styles.cardNumber}>
        Ending {lastFour}{isPaymentDeclined && (<span className={styles.declinedMessage}>{' '}(Declined)</span>)}
      </p>
    </div>
  );
}

PaymentMethod.propTypes = {
  customer: PropTypes.shape({
    billing: PropTypes.shape({
      card: PropTypes.shape({
        last_four: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
  className: PropTypes.string,
};
