import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FilterContext } from './FilterContext';

import { InputType, SelectedFiltersType, FilterButtonProps } from './FilterTypes';

import {
  StyledFilterButton,
  StyledButton,
  FilterMenu,
  DoneButton,
} from './styles';

const FilterButton = ({
  alignMenu = 'left',
  children,
  className = '',
  dataTestId,
  label: labelProp,
  onSetFilters = () => { /* Default empty function */ },
}: FilterButtonProps) => {
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLSpanElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>({});
  const [buttonLabel, setButtonLabel] = useState(labelProp);
  const [inputType, setInputType] = useState<InputType>('checkbox');
  const mutableSelectedFilters: SelectedFiltersType = { ...selectedFilters };

  const getbuttonLabel = () => {
    if (selectedFilters[labelProp].length === 0) return labelProp;

    return inputType === 'checkbox'
      ? `${labelProp}: ${selectedFilters[labelProp].length}`
      : selectedFilters[labelProp][0].label;
  };

  /** Called when the user clicks the "Done" button on filter menu to save changed filters */
  const onSaveFilters = () => {
    // set button label
    setButtonLabel(getbuttonLabel());
    // pass values to parent component
    onSetFilters(selectedFilters);
    // close Menu
    setIsOpen(false);
  };

  // Close menu on outside click
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        filterMenuRef.current &&
        filterButtonRef.current &&
        !filterMenuRef.current.contains(event.target as HTMLElement) &&
        !filterButtonRef.current.contains(event.target as HTMLElement)
      ) {
        if (isOpen && selectedFilters[labelProp].length > 0) {
          // Apply filters when clicking outside of dropdown
          onSaveFilters();
        } else {
          // If no selected filter, close the dropdown
          setIsOpen(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onSaveFilters]);

  /** Called when the user clicks on one filter to select or unselect it */
  const onSetSingleFilter = (
    filterCategory: string,
    label: string,
    value: string,
    checked: boolean
  ) => {
    // initialize filters array if it doesn't already exist
    if (!mutableSelectedFilters[filterCategory]) {
      mutableSelectedFilters[filterCategory] = [];
    }

    if (inputType === 'checkbox') {
      // Checkbox
      if (checked) {
        // Add filter
        mutableSelectedFilters[filterCategory].push({ label, value });
        setSelectedFilters(mutableSelectedFilters);
      } else {
        // remove filter
        mutableSelectedFilters[filterCategory] = mutableSelectedFilters[filterCategory].filter(
          (filter) => filter.label !== label
        );
        setSelectedFilters(mutableSelectedFilters);
      }
    } else {
      // Radio
      // eslint-disable-next-line no-lonely-if
      if (checked) {
        // Add filter
        mutableSelectedFilters[filterCategory] = [{ label, value }];
        setSelectedFilters(mutableSelectedFilters);
      }
    }
  };

  const memoizedContextValue = useMemo(() => ({
    onSelectFilter: onSetSingleFilter,
    selectedFilters,
  }), [onSetSingleFilter, selectedFilters]);

  return (
    <StyledFilterButton>
      <span ref={filterButtonRef}>
        <StyledButton
          className={className}
          dataTestId={dataTestId}
          label={buttonLabel}
          onClick={() => setIsOpen(!isOpen)}
          variant="outline"
        />
      </span>
      <FilterMenu
        $alignMenu={alignMenu}
        $isOpen={isOpen}
        ref={filterMenuRef}
      >
        <FilterContext.Provider value={memoizedContextValue}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child,
              { filterCategory: labelProp, setInputType, showSelectAll: true })
          )}
        </FilterContext.Provider>
        <DoneButton
          isFullWidth
          label="Done"
          onClick={onSaveFilters}
          size="small"
        />
      </FilterMenu>
    </StyledFilterButton>
  );
};
export default FilterButton;
