import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import breakpoints from 'helpers/breakpoints';

import { ReactComponent as IconSearch } from 'images/search-icon.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';
import styles from './styles.module.scss';

const DEFAULT_CATEGORY = 'Categories';

function MenuSearchBar(props) {
  const {
    searchQuery,
    setSearchQuery,
    setActiveCategory,
    setIsDropdownToggled,
    isSearchExpanded,
    setSearchExpanded,
    minimized
  } = props;
  const inputRef = createRef();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  function handleSearchClick() {
    inputRef.current.focus();
    setSearchExpanded(true);
    if (setIsDropdownToggled) {
      setIsDropdownToggled(false);
    }
  }

  return (
    <div
      className={classNames(styles.searchBarWrapper, {
        [styles.minimized]: !isDesktop && !isSearchExpanded && minimized
      })}
      onClick={handleSearchClick}
    >
      <IconSearch alt={'search icon'} className={styles.searchIcon} />
      <input
        type="text"
        name="search"
        placeholder="Search"
        className={styles.searchBar}
        onChange={e => {
          setSearchQuery(e.target.value);
          setActiveCategory(DEFAULT_CATEGORY);
        }}
        ref={inputRef}
        value={searchQuery}
        autoComplete="off"
      />
      {searchQuery && (
        <div
          className={styles.searchClear}
          onClick={() => setSearchQuery('')}
          role="button"
        >
          <IconClose alt={'close icon'} className={styles.closeIcon} />
        </div>
      )}
    </div>
  );
}

MenuSearchBar.propTypes = {
  isSearchExpanded: PropTypes.bool,
  minimized: PropTypes.bool,
  searchQuery: PropTypes.string,
  setActiveCategory: PropTypes.func,
  setIsDropdownToggled: PropTypes.func,
  setSearchExpanded: PropTypes.func,
  setSearchQuery: PropTypes.func
};

export default MenuSearchBar;
