import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { UserContext } from 'context/UserContext';

import Restaurant from 'types/restaurant';
import { Location } from 'types/pos/toast';
import { PosSquareResponse } from 'types/pos/square';

import IntegrationDetailsCardInfo from '../../IntegrationDetailsCardInfo';

interface SquareDetailsProps {
  restaurant: Restaurant;
  restaurantLocation: Location;
  posSquareResponse: PosSquareResponse;
}

const SquareDetails: FC<SquareDetailsProps> = ({
  restaurantLocation,
  restaurant,
  posSquareResponse,
}) => {
  const { user } = useContext(UserContext);

  const { address, name, phone_number } = restaurantLocation;

  return (
    <>
      <Helmet title="Integration Details" />
      <IntegrationDetailsCardInfo
        address={address}
        is_active={posSquareResponse.is_active}
        last_menu_sync_at={posSquareResponse.last_menu_sync_at}
        phone_number={phone_number}
        restaurant={restaurant}
        token_expiration={posSquareResponse.token_expiration}
        updated_at={posSquareResponse.updated_at}
        user={user}
        menu_link={`/restaurant/${restaurant.id}/settings/integrations/square/menu`}
        authentication={name}
      />
    </>
  );
};

export default SquareDetails;
