import React, { FC } from 'react';
import TableHeader from 'components/Table/TableHeader';
import { Filters, Order, IconComponent, SortOrderType, VariantType } from 'types/orders-list';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'helpers/dateTime';
import { formatMoney } from '@chownow/cn-web-utils/format';
import { ORDER_STATUS } from 'helpers/constants';

import { ReactComponent as IconCheck } from 'images/icon-check.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { ReactComponent as IconTime } from 'images/icon-time.svg';

import {
  Capitalize,
  ColumnLocation,
  CompanyTd,
  StyledTable,
  StyledStatusDot,
  StatusWrap,
} from './styles';

const VariantByStatus: Record<string, VariantType> = {
  Accepted: 'neutral',
  Submitted: 'success',
  Canceled: 'caution',
  Future: 'warning',
  New: 'active',
};

interface OrdersListTableProps {
  companyId: string,
  isCompanyOrdersList: boolean;
  isLoading: boolean;
  fetchOrdersList: (filters: Filters, url?: string) => void;
  filters: Filters;
  ordersList: Order[];
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const OrdersListTable: FC<OrdersListTableProps> = ({
  companyId,
  filters,
  isCompanyOrdersList,
  isLoading,
  ordersList,
  fetchOrdersList,
  setFilters,
}) => {
  const hasOrders = ordersList?.length > 0;
  const getOrderIcon = ({ status, is_order_ahead }: Order): IconComponent | undefined => {
    if (status === ORDER_STATUS.canceled) {
      return IconClose;
    } else if (status === ORDER_STATUS.accepted) {
      return IconCheck;
    } else if (status === ORDER_STATUS.submitted) {
      return is_order_ahead ? IconTime : IconCheck;
    }

    return undefined;
  };

  const getFulfillmentMethod = (order: Order) => {
    if (!order?.fulfillment_method) return '';
    if (order.fulfillment_method === 'dine_in') {
      return 'Dine-in';
    } else if (order.fulfillment_method === 'delivery') {
      return order.managed_delivery?.label || 'delivery';
    } else {
      return order.fulfillment_method;
    }
  };

  const handleOnSort = (label: keyof Filters) => {
    if (hasOrders) {
      let sort_order: SortOrderType;

      if (filters.order_by !== label) {
        sort_order = 'asc';
      } else {
        sort_order = filters.sort_order === 'desc' ? 'asc' : 'desc';
      }

      const newFilters: Filters = {
        ...filters,
        sort_order,
        order_by: label,
      };

      setFilters(newFilters);
      fetchOrdersList(newFilters);
    }
  };

  return (
    <StyledTable emptyMessage="No results">
      <thead>
        <tr>
          <TableHeader>Order #</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Type</TableHeader>
          {isCompanyOrdersList && <TableHeader>Location</TableHeader>}
          <TableHeader
            onClick={() => handleOnSort('created_at' as keyof Filters)}
            isSortable={hasOrders}
            sortOrder={filters.order_by === 'created_at' ? filters.sort_order : ''}
          >
            Placed
          </TableHeader>
          <TableHeader
            onClick={() => handleOnSort('expected_by' as keyof Filters)}
            isSortable={hasOrders}
            sortOrder={filters.order_by === 'expected_by' ? filters.sort_order : ''}
          >
            Expected By
          </TableHeader>
          <TableHeader>Customer</TableHeader>
          <TableHeader>Total</TableHeader>
        </tr>
      </thead>
      <tbody>
        {!isLoading &&
            ordersList.map((order, index) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
              const formattedMoney = formatMoney(order.total);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
              const formattedDateTime = formatDateTime(String(order.fulfillment_times.expected_by));

              return (
                <tr key={order.id} data-testid={`order-item-${index}`}>
                  <td>
                    <u>
                      <Link
                        to={`/restaurant/${order?.restaurant?.id}/orders/details/${order.id}`}
                      >
                        {order.id}
                      </Link>
                    </u>
                  </td>
                  <td>
                    <StatusWrap>
                      <StyledStatusDot
                        size="medium"
                        icon={getOrderIcon(order)}
                        variant={VariantByStatus[order.status] || 'neutral'}
                      />
                      <u>
                        <Link
                          to={`/restaurant/${order?.restaurant?.id}/orders/details/${order.id}`}
                        >
                          {(() => {
                            if (order.status === ORDER_STATUS.submitted) {
                              return order.is_order_ahead
                                ? ORDER_STATUS.future
                                : ORDER_STATUS.new;
                            } else {
                              const status =
                                order.status.toLowerCase() as keyof typeof ORDER_STATUS;

                              return ORDER_STATUS[status];
                            }
                          })()}
                        </Link>
                      </u>
                    </StatusWrap>
                  </td>

                  <Capitalize>
                    {getFulfillmentMethod(order)}
                  </Capitalize>
                  {isCompanyOrdersList && (
                    <ColumnLocation>
                      <u>
                        <Link
                          to={`/restaurant/${order?.restaurant?.id}/settings/location-info`}
                        >
                          {order?.restaurant?.name}
                        </Link>
                      </u>
                    </ColumnLocation>
                  )}
                  <CompanyTd $isCompanyOrdersList={isCompanyOrdersList}>
                    {order.fulfillment_times.created_at ? formatDateTime(order.fulfillment_times.created_at) : ''}
                  </CompanyTd>
                  <CompanyTd $isCompanyOrdersList={isCompanyOrdersList}>
                    {formattedDateTime}
                  </CompanyTd>
                  <td>
                    <u>
                      <Link
                        to={`/company/${companyId}/customer/${order?.customer?.id}`}
                      >
                        {order?.customer?.name}
                      </Link>
                    </u>
                  </td>
                  <td>{formattedMoney}</td>
                </tr>
              );
            })}
      </tbody>
    </StyledTable>
  );
};

export default OrdersListTable;
