import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const StyledInput = styled.input<{ $hasError: boolean }>`
  border: 1px solid ${semanticColors.border.primary};
  border-color: ${({ $hasError }) => (
    $hasError ?
      semanticColors.border.caution :
      semanticColors.border.primary
  )};
  border-radius: ${borderRadius.small};
  color: ${semanticColors.content.primary};
  font: ${paragraph.reg.x16};
  height: 48px;
  min-height: 48px;
  padding: 11px ${space.x2};
  width: 100%;

  &:hover,
  &:focus {
    border-color: ${({ $hasError }) => (
    $hasError ?
      semanticColors.border.caution :
      semanticColors.border.tertiary
  )};
  }

  &:focus-visible,
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ErrorSection = styled.p`
  color: ${semanticColors.content.secondary};
  display: flex;
  flex-direction: column;
  font: ${label.reg.x12};
  margin: ${space.x1} 0 0;
  width: 100%;
`;

export const Error = styled.span`
  color: ${semanticColors.content.caution};
`;
