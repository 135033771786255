import { Button } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const ConfirmPartialRefundModal = styled.div`
  text-align: left;
`;

export const Header = styled.div`
  font: ${label.semi.x20};
  margin-bottom: ${space.x3};
`;

export const Label = styled.div`
  color: ${semanticColors.content.tertiary};
  margin-top: ${space.x3};
`;

export const Input = styled.input`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  font-size: ${label.semi.x20};
  margin-top: ${space.x1};
  padding: ${space.x2};
  width: 100%;
`;

export const Textarea = styled.textarea`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  font-size: ${label.semi.x20};
  margin-top: ${space.x1};
  min-height: 105px;
  padding: ${space.x2};
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-top: ${space.x3};
`;
