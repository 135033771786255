import React, { FC } from 'react';
import { DaysOfTheWeek } from '../constants';

import { useRestaurantHoursContext } from '../RestaurantHoursContext/RestaurantHoursContextProvider';
import { Day, Hour, HoursContainer, HoursRow, SubmittedHoursCardWrapper, To } from './styles';
import { Divider, Heading3 } from '../styles';

const SubmittedHoursCard: FC = () => {
  const {
    deliveryHours,
    pickupHours,
    isDeliveryEnabled,
    isPickupEnabled,
  } = useRestaurantHoursContext();

  return (
    <SubmittedHoursCardWrapper
      data-testid="submitted-hours-card"
    >
      {isPickupEnabled && (
        <>
          <Heading3>Pickup Hours</Heading3>
          {Object.keys(DaysOfTheWeek).map((day: string) => {
            const hours = pickupHours.get(day);
            const isClosed = hours?.is_closed;

            return (
              <HoursRow key={`${day} - pickup hours`}>
                <Day>{day}</Day>
                {isClosed ? (
                  <span>Closed</span>
                ) : (
                  <HoursContainer>
                    <Hour>{hours?.ranges[0]?.start}</Hour>
                    <To>to</To>
                    <Hour>{hours?.ranges[0]?.end}</Hour>
                  </HoursContainer>
                )}
              </HoursRow>
            );
          })}
        </>
      )}
      {isPickupEnabled && isDeliveryEnabled && <Divider />}
      {isDeliveryEnabled && (
        <>
          <Heading3>Delivery Hours</Heading3>
          {Object.keys(DaysOfTheWeek).map((day: string) => {
            const hours = deliveryHours.get(day);
            const isClosed = hours?.is_closed;

            return (
              <HoursRow key={`${day} - delivery hours`}>
                <Day>{day}</Day>
                {isClosed ? (
                  <span>Closed</span>
                ) : (
                  <HoursContainer>
                    <Hour>{hours?.ranges[0]?.start}</Hour>
                    <To>to</To>
                    <Hour>{hours?.ranges[0]?.end}</Hour>
                  </HoursContainer>
                )}
              </HoursRow>
            );
          })}
        </>
      )}
    </SubmittedHoursCardWrapper>
  );
};

export default SubmittedHoursCard;
