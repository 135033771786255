import { useState } from 'react';
import { request } from 'helpers/api';
import { ToastDetailsResponse } from 'types/pos/toast';

export const useSubmitPosLocationForm = () => {
  const [hasSubmitPosLocationFormError, setHasSubmitPosLocationFormError] = useState(false);

  const submitPosLocationForm = async (
    posName: string,
    restaurantId: string,
    selectedLocation: string
  ): Promise<ToastDetailsResponse | null> => {
    try {
      const payload =
        posName === 'square'
          ? { location_id: selectedLocation }
          : { is_authorized: true };

      const response = await request(
        `pos/${posName}/restaurant/${restaurantId}`,
        payload,
        'PUT'
      ) as ToastDetailsResponse;

      return response;
    } catch (err) {
      setHasSubmitPosLocationFormError(true);

      return null;
    }
  };

  return {
    hasSubmitPosLocationFormError,
    submitPosLocationForm,
  };
};
