import React from 'react';

import { VerifiedCardContainer, StyledIconBadgeCheck } from './styles';

const VerifiedAccountCard = () => (
  <VerifiedCardContainer>
    <StyledIconBadgeCheck />
    Your Stripe account is active
  </VerifiedCardContainer>
);

export default VerifiedAccountCard;
