import React, { FC } from 'react';

import { OrderItemNestedModsV5, TabletOrderItemV5 } from 'types/order-details';
import { getModifierCount } from 'containers/Restaurant/Orders/OrderDetailsContainer/OrderDetails/helpers';

import { InfoRow } from '../../styles';

import { Price } from '../styles';

import {
  BoldLeftContent,
  ModifierCount,
  OrderItem,
  Quantity,
  SpecialInstructions,
} from './styles';
import { formatMoney } from '../helpers';

interface OrderCardItemProps {
  isFirstElement?: boolean;
  isLastElement?: boolean;
  item: OrderItemNestedModsV5 | TabletOrderItemV5;
}

const OrderCardItem: FC<OrderCardItemProps> = ({
  isFirstElement = false,
  isLastElement = false,
  item,
}) => {
  const modifierCount = getModifierCount(item.modifier_categories);

  return (
    <OrderItem $isLastElement={isLastElement}>
      <InfoRow key={item.id} $isFirstElement={isFirstElement}>
        <BoldLeftContent>
          <Quantity>{item.quantity}</Quantity>
          {item.name}
        </BoldLeftContent>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
        <Price>{formatMoney(item.item_sum_total)}</Price>
      </InfoRow>
      {modifierCount > 0 && (
        <ModifierCount>
          {modifierCount} modifier{modifierCount > 1 && 's'}
        </ModifierCount>
      )}
      {item.special_instructions && (
        <SpecialInstructions>{item.special_instructions}</SpecialInstructions>
      )}
    </OrderItem>
  );
};

export default OrderCardItem;
