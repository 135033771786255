import React from 'react';
import PropTypes from 'prop-types';

import { menuListItemType } from 'helpers/prop-types';

import MenuItem from '../MenuItem';

import styles from './styles.module.scss';

const MenuItems = props => {
  const {
    items,
    isAnimateOnDelete,
    onSelectItem,
    onOpenModal,
    showModifiers,
    handleToggleHideItem,
    dataTestId
  } = props;

  return (
    <ul className={styles.itemsList}>
      {items.map(item => (
        <MenuItem
          item={item}
          isAnimateOnDelete={isAnimateOnDelete}
          handleToggleHideItem={handleToggleHideItem}
          onSelectItem={onSelectItem}
          onOpenModal={onOpenModal}
          showModifiers={showModifiers}
          key={item.id}
          dataTestId={dataTestId}
        />
      ))}
    </ul>
  );
};

MenuItems.propTypes = {
  dataTestId: PropTypes.string,
  handleToggleHideItem: PropTypes.func,
  isAnimateOnDelete: PropTypes.bool,
  items: PropTypes.arrayOf(menuListItemType),
  onOpenModal: PropTypes.func,
  onSelectItem: PropTypes.func,
  showModifiers: PropTypes.bool
};

export default MenuItems;
