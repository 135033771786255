import { find } from 'lodash';

import { formatError, request } from 'helpers/api';
import { findDefaultSize } from 'helpers/catalog';

function getModifiers(itemCategoryIds, allCategories) {
  let itemModifiers = [];

  itemCategoryIds.forEach(id => {
    const category = find(allCategories, { id }) || [];

    category.modifiers.forEach(modifier => {
      itemModifiers.push({
        ...modifier,
        categoryName: category.name,
      });
    });
  });
  return itemModifiers;
}

/**
 * Builds a new ChowNow menu using the Square Catalog
 * @param  {string} restaurantId - ChowNow Restaurant ID
 */
export async function buildMenu(restaurantId) {
  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog`,
      null,
      'POST'
    );
    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Fetch the Square Item Catalog cache
 * @param {string} restaurantId - restaurant ID
 * @param {boolean} sync - when true, invalidates cache
 */
export async function requestSquareCatalog(restaurantId, sync) {
  const params = sync ? { sync: true } : {};
  let response;
  try {
    response = await request(
      `pos/square/restaurant/${restaurantId}/catalog`,
      params
    );
  } catch (err) {
    return formatError(err);
  }

  if (response.errors) return response;

  const { items: squareItems, modifier_categories } = response;
  const itemsWithDetails = squareItems.map(item => {
    // Remove default-size and assign it's props to base item
    const defaultSize = findDefaultSize(item);

    return {
      ...item,
      price: defaultSize.price,
      is_meta: true, // All square items will have nested sizes even if only one size
      modifiers: getModifiers(item.modifier_categories, modifier_categories),
    };
  });

  return {
    squareItems: itemsWithDetails,
    last_sync_at: response.last_sync_at,
  };
}

/**
 * Link a Square and CN item together by setting the CN Item `x_id`.
 * Linking is done by taking a Square Item's ID and it's default size then mapping the two to
 * a ChowNow Item's `x_id` property in the format <squareItemId>_<defaultSizeId>.
 * @param {object} context - catalog context
 * @param {string} restaurantId - restaurant ID
 * @param {squareItemType} squareItem - Square Catalog Item object
 * @param {string} CNId - ChowNow Item ID
 */
export async function postItemLink(context, restaurantId, squareId, CNId) {
  const { squareItems, CNItems, setCNItems } = context;
  const CNItem = find(CNItems, item => item.id === CNId);
  const squareItem = find(squareItems, item => item.id === squareId);
  const defaultSize = findDefaultSize(squareItem);
  const squareXID = `${squareItem.id}_${defaultSize.id}`;
  // If item is already linked, abort
  if (CNItem.x_id) return { errors: [] };

  const updatedCNItems = CNItems.map(item => {
    if (item.id === CNItem.id) {
      return {
        ...item,
        x_id: squareXID,
        serving_sizes: item.serving_sizes.map(size => {
          if (size.id === CNId) {
            return {
              ...size,
              x_id: squareXID,
            };
          } else {
            return size;
          }
        }),
      };
    } else {
      return item;
    }
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/menu-item/${CNId}`,
      { menu_item_id: CNId, x_id: squareXID },
      'PUT'
    );

    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Remove Square and CN item link by setting the CN Item `x_id` to null
 * @param {object} context - catalog context
 * @param {string} restaurantId - restaurant ID
 * @param {string} CNId - ChowNow Item ID
 */
export async function removeItemLink(context, restaurantId, CNId) {
  const { CNItems, setCNItems } = context;
  const updatedCNItems = CNItems.map(item => {
    if (item.id === CNId) {
      return {
        ...item,
        x_id: null,
        serving_sizes: item.serving_sizes.map(size => {
          if (size.id === CNId) {
            return {
              ...size,
              x_id: null,
            };
          } else {
            return size;
          }
        }),
      };
    }
    return item;
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/menu-item/${CNId}`,
      { menu_item_id: CNId, x_id: null },
      'PUT'
    );
    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Link a Square and CN item together by setting the CN Item `x_id`

 * @param {string} restaurantId - restaurant ID
 * @param {string} SquareModifierId - Square Item Modifier ID
 * @param {string} CNModifierId - ChowNow Item Modifier ID
 */
export async function postModifierLink(
  context,
  restaurantId,
  SquareModifierId,
  CNModifierId
) {
  const { CNItems, setCNItems } = context;
  const CNItem = find(CNItems, item => {
    return find(item.modifiers, size => size.id === CNModifierId);
  });

  const CNModifier = CNItem.modifiers.find(
    modifier => modifier.id === CNModifierId
  );
  // If item is already linked, abort
  if (!CNModifier || CNModifier.x_id) return { errors: [] };

  const updatedCNItems = CNItems.map(item => {
    return {
      ...item,
      modifiers: item.modifiers.map(modifier => {
        if (modifier.id === CNModifierId) {
          return {
            ...modifier,
            x_id: SquareModifierId,
          };
        } else {
          return modifier;
        }
      }),
    };
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/modifier/${CNModifier.id}`,
      { modifier_id: CNModifier.id, x_id: SquareModifierId },
      'PUT'
    );
    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Remove Square and CN item link by setting the CN Item `x_id` to null
 * @param {object} context - catalog context
 * @param {string} restaurantId - restaurant ID
 * @param {string} modifierId - ChowNow modifier ID
 */
export async function removeModifierLink(context, restaurantId, modifierId) {
  const { CNItems, setCNItems } = context;
  const updatedCNItems = CNItems.map(item => {
    return {
      ...item,
      modifiers: item.modifiers.map(modifier => {
        if (modifier.id === modifierId) {
          return {
            ...modifier,
            x_id: null,
          };
        } else {
          return modifier;
        }
      }),
    };
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/modifier/${modifierId}`,
      { modifier_id: modifierId, x_id: null },
      'PUT'
    );

    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Link a Square and CN item together by setting the CN Item `x_id`
 * @param {object} context - catalog context
 * @param {string} restaurantId - restaurant ID
 * @param {string} squareSizeId - Square Item Size ID
 * @param {string} CNSizeId - ChowNow Item Size ID
 */
export async function postSizeLink(
  context,
  restaurantId,
  squareSizeId,
  CNSizeId
) {
  const { CNItems, squareItems, setCNItems } = context;
  const CNItem = find(CNItems, item => {
    return find(item.serving_sizes, size => size.id === CNSizeId);
  });
  const squareItem = find(squareItems, item => {
    return find(item.serving_sizes, size => size.id === squareSizeId);
  });
  const CNSize = CNItem.serving_sizes.find(size => size.id === CNSizeId);
  const squareXID = `${squareItem.id}_${squareSizeId}`;

  // If item is already linked, abort
  if (!CNSize || CNSize.x_id) return { errors: [] };

  const updatedCNItems = CNItems.map(item => {
    if (item.id === CNItem.id) {
      return {
        ...item,
        serving_sizes: item.serving_sizes.map(size => {
          if (size.id === CNSizeId) {
            return {
              ...size,
              x_id: squareXID,
            };
          } else {
            return size;
          }
        }),
      };
    } else {
      return item;
    }
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/menu-item/${CNSize.id}`,
      { menu_item_id: CNSize.id, x_id: squareXID },
      'PUT'
    );
    return response;
  } catch (err) {
    return formatError(err);
  }
}

/**
 * Remove Square and CN item link by setting the CN Item `x_id` to null
 * @param {object} context - catalog context
 * @param {string} restaurantId - restaurant ID
 * @param {string} CNId - ChowNow Item ID
 */
export async function removeSizeLink(context, restaurantId, sizeId) {
  const { CNItems, setCNItems } = context;
  const CNItem = find(CNItems, item =>
    find(item.serving_sizes, size => size.id === sizeId)
  );
  const updatedCNItems = CNItems.map(item => {
    if (item.id === CNItem.id) {
      return {
        ...item,
        serving_sizes: item.serving_sizes.map(size => {
          if (size.id === sizeId) {
            return {
              ...size,
              x_id: null,
            };
          } else {
            return size;
          }
        }),
      };
    } else {
      return item;
    }
  });
  setCNItems(updatedCNItems);

  try {
    const response = await request(
      `pos/square/restaurant/${restaurantId}/catalog-map/menu-item/${sizeId}`,
      { menu_item_id: sizeId, x_id: null },
      'PUT'
    );
    return response;
  } catch (err) {
    return formatError(err);
  }
}
