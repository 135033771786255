import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';

export const SubmittedHoursCardWrapper = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
  padding: ${space.x3};
  text-align: left;

  @media (min-width: $screen-xs-min) {
    padding: ${space.x5};
  }
`;

export const Day = styled.span`
  min-width: 90px;
`;

export const Hour = styled.span`
  min-width: 80px;
`;

export const HoursContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 216px;
  width: 100%;
`;

export const HoursRow = styled.div`
  display: flex;
  gap: ${space.x2};
  margin-top: ${space.x3};
`;

export const To = styled.span`
  min-width: ${space.x3};
`;
