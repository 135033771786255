import styled from 'styled-components';
import { screen, space } from 'styles/variables';
import { semanticColors } from 'styles/colors';

import { DashboardGrid, DropdownButton, MenuSimple } from '@chownow/cn-web-components';

export const StyledDashboardGrid = styled(DashboardGrid)`
  margin: 0;
`;

export const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space.x5};
`;

export const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  gap: ${space.x1};
  justify-content: space-between;
`;

export const StyledDropdownButton = styled(DropdownButton)`
  text-transform: capitalize;
`;

export const Results = styled.div`
  color: ${semanticColors.content.primary};
  margin-bottom: ${space.x5};
`;

export const SearchBar = styled.div`
  min-width: 180px;
  position: relative;
  top: -1px;

  input[type='text'] {
    box-sizing: unset;
    height: 38px;
  }

  @media (min-width: ${screen.lgMin}) {
    min-width: 280px;
  }
`;

export const Reset = styled.span`
  cursor: pointer;
  padding-left: ${space.half};
  text-decoration: underline;
`;

export const TableWrap = styled.div`
  overflow-x: auto;
`;

export const SpinnerWrap = styled.div`
  margin-top: ${space.x12};
  overflow: hidden;
`;

export const SimpleCustomerName = styled(MenuSimple)`
  border-radius: ${space.x1} ${space.x1} 0 0;
`;

export const SimpleOrderTotal = styled(MenuSimple)`
  border-radius: 0 0 ${space.x1} ${space.x1};
`;
