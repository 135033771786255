import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotFoundIcon } from 'images/not-found.svg';
import {
  Container,
  Content,
  Message,
  StyledButton,
  StyledFooter,
} from './styles';

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Content>
        <Helmet title="Page Not Found" />
        <div role="img" aria-label="not found">
          <NotFoundIcon />
        </div>
        <Message>
          Oops! Something is not right.
          <br />
          We can&apos;t find the page you&apos;re looking for.
        </Message>
        <StyledButton label="Go to Homepage" onClick={() => navigate('/')} />
      </Content>
      <StyledFooter />
    </Container>
  );
};

export default Unauthorized;
