import React, { FC, useState } from 'react';
import pictogram from 'images/pictograms/picto-promo-lg.svg';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import { useGetPromotion, usePutPromotion } from 'api/onboarding/promotionApis';
import { isPromotionPreviouslySubmittedResponse } from 'types/promotion';
import Banner from '@chownow/cocina-react-banner';

import OnboardingContainer from '../OnboardingContainer';
import PageIntro from '../PageIntro';
import PageFooterPromotion from '../PageFooter/PageFooterPromotion';
import { useWizardNavigationContext } from '../WizardNavigationContext';
import PromotionCard from './content/PromotionCard';
import SubmittedPromotionCard from './content/SubmittedPromotionCard';
import { BannerMessage, PromotionOptions } from './constants';
import ErrorCard from '../ErrorCard';
import LoadingState from '../LoadingState';
import { getPromotionPutData, getPromotionType } from './helpers';

interface PromotionProps {
  restaurantUuid: string | null;
}

const Promotion: FC<PromotionProps> = ({ restaurantUuid }) => {
  const [selectedPromo, setSelectedPromo] = useState<PromotionOptions>();
  const [isErrorBannerDisplayed, setIsErrorBannerDisplayed] =
    useState<boolean>();
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();
  const {
    getPromotionRequestResponse,
    isGetPromotionRequestLoading,
    hasGetPromotionRequestError,
  } = useGetPromotion(restaurantUuid);
  const {
    isPutPromotionRequestLoading,
    postOnboardingPromotion,
  } = usePutPromotion();

  const handleOnSave = async () => {
    let hasError = false;

    if (selectedPromo) {
      const hasPutRequestError = await postOnboardingPromotion(
        restaurantUuid,
        getPromotionPutData(selectedPromo)
      );

      if (hasPutRequestError) {
        setIsErrorBannerDisplayed(true);

        window.scrollTo(0, 0);

        hasError = true;
      }
    }

    return hasError;
  };

  const isLoading = isWizNavProgressRequestLoading || isGetPromotionRequestLoading;
  const hasError = hasWizNavProgressRequestError || hasGetPromotionRequestError;
  const hasPreviouslySubmittedData = getPromotionRequestResponse &&
    isPromotionPreviouslySubmittedResponse(getPromotionRequestResponse);

  let content;

  if (hasError) {
    content = <ErrorCard />;
  } else if (isLoading) {
    content = <LoadingState />;
  } else if (hasPreviouslySubmittedData) {
    content = (
      <SubmittedPromotionCard
        submittedPromo={
          getPromotionRequestResponse &&
          getPromotionType(getPromotionRequestResponse)
        }
      />
    );
  } else {
    content = (
      <PromotionCard
        selectedPromo={selectedPromo}
        setSelectedPromo={setSelectedPromo}
      />
    );
  }

  return (
    <>
      {isErrorBannerDisplayed && (
        <Banner variant="caution">{BannerMessage.ERROR}</Banner>
      )}
      {hasPreviouslySubmittedData && (
        <Banner variant="success">{BannerMessage.SUCCESS}</Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Promo"
          title={ONB_WIZARD_INTRO.promotionTitle}
          description={ONB_WIZARD_INTRO.promotionDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterPromotion
            hasPresubmittedData={hasPreviouslySubmittedData}
            isLoading={isPutPromotionRequestLoading}
            isFormCompleted={!!selectedPromo}
            onSave={handleOnSave}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default Promotion;
