import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { format } from 'date-fns';

// format used on orders list page mobile
export function getOrderTime(dateTime) {
  const getDateTime = getDateForApiDateStr(dateTime);
  const orderTime = format(getDateTime, 'h:mm a');

  return orderTime;
}

// format used on order details page
export function getOrderDateTime(dateTime) {
  const getDateTime = getDateForApiDateStr(dateTime);

  if (!getDateTime) {
    return dateTime || '';
  }

  const dateAndTime = format(getDateTime, 'MMM d, yyyy h:mm a');
  return dateAndTime;
}

// format used on order details order ahead banner
export function getOrderDateTimeBanner(dateTime) {
  const getDateTime = getDateForApiDateStr(dateTime);
  const dateAndTime = format(getDateTime, 'E, MMM d, h:mm a');

  return dateAndTime;
}

// get date of order only, used on order ahead modal
export function getOrderDate(dateTime) {
  const getDateTime = getDateForApiDateStr(dateTime);
  const orderDate = format(getDateTime, 'MMM d');
  return orderDate;
}

export function getCountdownTime(startDateTime, endDateTime) {
  const date_future = endDateTime;
  const date_now = startDateTime;

  const seconds = Math.floor((date_future - date_now) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const calchours = hours - days * 24;
  const calcminutes = minutes - days * 24 * 60 - hours * 60;
  const calcseconds =
    seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return [days, calchours, calcminutes, calcseconds];
}
