import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';

import { REGISTRATION_REDIRECT_URL } from '@chownow/cn-web-utils/paths';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { makeServer } from './_mockapi/server';

import { MenuDataProvider } from 'context/MenuDataContext';
import { ModalProvider } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import HomeRedirect from 'components/HomeRedirect';
import NotFound from 'components/NotFound';
import Unauthorized from 'components/Unauthorized';
import Company from 'containers/Company';
import LegacyEmbed from 'containers/LegacyEmbed';
import UserAuth from 'containers/UserAuth';
import Restaurant from 'containers/Restaurant';
import WebIntegration from 'containers/WebIntegration';
import { getUrlParam } from 'helpers/url';
import { LD_PRIVATE_ATTRIBUTES } from 'helpers/constants';
import usePrevious from 'hooks/usePrevious';
import './App.css';

const isAnalyticsEnabled =
  !!process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
  process.env.NODE_ENV !== 'test';
const environment = process.env.REACT_APP_DEPLOY_ENV;

if (isAnalyticsEnabled) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

function App() {
  const location = useLocation();
  const [user, setUser] = useState();
  const flags = useFlags();
  const [mockServer, setMockServer] = useState();
  const restaurantId = getUrlParam('restaurant');
  const prevRestaurantId = usePrevious(restaurantId);
  const ldClient = useLDClient();
  // We've been getting intermittent network errors, apparently the solution is to turn off logging
  // We're going to try filtering out network errors first
  // https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error-
  ldClient.on(
    'error',
    err => err.message.indexOf('network error') === -1 && console.error(err)
  );

  useEffect(() => {
    logPageView(location);
    checkFeatureFlags();
    document.documentElement.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    const userType = user && user.group_id;
    ReactGA.set({
      dimension1: userType,
    });
  }, [user]);

  useEffect(() => {
    if (flags.mockApi && !mockServer) {
      if (environment !== 'prod') {
        makeServer({ environment });
        setMockServer(true);
      }
    }
  }, [flags]);

  /**
   * If the location changes, check if the restaurant id chanaged.
   * If restaurant id has changed, update LD user and re-evaluate feature flags.
   */

  function checkFeatureFlags() {
    if (prevRestaurantId !== restaurantId) {
      const user = ldClient?.getContext();
      if (user) {
        const newUser = {
          ...user,
          privateAttributeNames: LD_PRIVATE_ATTRIBUTES,
          custom: {
            ...user.custom,
            currentRestaurant: restaurantId,
          },
        };

        // Limit PII sent to LD so we don't need to worry about it for CCPA
        const options = { privateAttributes: LD_PRIVATE_ATTRIBUTES };
        ldClient.identify(newUser, options);
      }
    }
  }

  function logPageView(location) {
    // Google Analytics
    const pageviewUrl = location.pathname + location.search;

    if (isAnalyticsEnabled) {
      ReactGA.set({ page: pageviewUrl });
      ReactGA.pageview(pageviewUrl);
    }
  }

  const RegistrationRedirect = () => {
    window.location = REGISTRATION_REDIRECT_URL;
    return null;
  };

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | ChowNow Dashboard" />
      <UserContext.Provider value={{ user, setUser }}>
        <MenuDataProvider>
          <ModalProvider>
            <Routes>
              <Route exact path="/" element={<HomeRedirect />} />
              <Route path="/login/*" element={<UserAuth />} />
              {/* routing from Hermosa admin currently adds an extra "/" to all embed */}
              <Route path="/embed/*" element={<LegacyEmbed />} />
              <Route path="//embed/*" element={<LegacyEmbed />} />
              <Route
                path="/onboarding/registration"
                element={<RegistrationRedirect />}
              />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/company/:companyId/*" element={<Company />} />
              <Route path="/web-integration" element={<WebIntegration />} />
              <Route
                path="/restaurant/:restaurantId/*"
                element={<Restaurant />}
              />
              <Route element={<NotFound />} path="*" />
              {/* end */}
            </Routes>
          </ModalProvider>
        </MenuDataProvider>
      </UserContext.Provider>
    </HelmetProvider>
  );
}

export default App;
