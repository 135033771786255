import styled from 'styled-components';
import { screen, space } from 'styles/variables';

export const HeadingCTA = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: ${space.x2} 0;

  @media (min-width: ${screen.lgMin}) {
    flex-direction: row-reverse;
  }
`;
