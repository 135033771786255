import React, { FC, useState } from 'react';
import { RadioButton } from '@chownow/cn-web-components';
import { useUpdateOrderStatus } from 'api/orders/orderDetails';
import {
  formatRequestedTimeWithOffset,
  getCurrentTimestamp,
} from 'helpers/dateTime';

import {
  ApplyButton,
  ApplyButtonContainer,
  ApplyButtonTopDivider,
  BottomContent,
  ButtonGroup,
  ContentContainer,
  MainContent,
  StyledInput,
  StyledModal,
  Subtitle,
  Title,
  TitleDivider,
  TopContent,
} from './styles';
import { validateCustomETAInputValue } from '../helpers';

interface AcceptOrderModalProps {
  onClose: () => void;
  orderId: string | undefined;
  onError: (message: string) => void;
  onSuccess: () => Promise<void>;
}

const etaOptions = ['10', '15', '20', '30', '45', '60'];

const AcceptOrderModal: FC<AcceptOrderModalProps> = ({
  onClose,
  orderId,
  onError,
  onSuccess,
}) => {
  const [selectedETAOption, setSelectedETAOption] = useState<string>('');
  const [customETAInputValue, setCustomETAInputValue] = useState<string>('');
  const [customETAInputErrorMessage, setCustomETAInputErrorMessage] =
    useState<string>('');
  const { isUpdateOrderStatusRequestLoading, updateOrderStatus } =
    useUpdateOrderStatus();

  const handleETAOptionChange = (option: string) => {
    // If the user has already put in a custom value but clicks on one of the radio options, we want to clear the custom input field
    if (!selectedETAOption && !!customETAInputValue) {
      setCustomETAInputValue('');

      if (customETAInputErrorMessage) {
        setCustomETAInputErrorMessage('');
      }
    }

    setSelectedETAOption(option);
  };

  const handleCustomETAInputChange = (inputValue: string) => {
    if (!customETAInputValue && !!selectedETAOption) {
      setSelectedETAOption('');
    }

    // Clear error message if user inputs new value
    if (customETAInputErrorMessage) {
      setCustomETAInputErrorMessage('');
    }

    setCustomETAInputValue(inputValue);
  };

  const handleAcceptOrder = async (eta: string) => {
    if (orderId) {
      const errorMessage = await updateOrderStatus(
        orderId,
        'accept',
        parseInt(eta, 10)
      );

      if (errorMessage) {
        onError(errorMessage);
      } else {
        // If request is successful, re-fetch the order details response to get the updated order status
        await onSuccess();
      }
    }
  };

  const handleOnApply = () => {
    if (customETAInputValue) {
      const errorText = validateCustomETAInputValue(customETAInputValue);

      if (errorText) {
        setCustomETAInputErrorMessage(errorText);
      } else {
        void handleAcceptOrder(customETAInputValue);
      }
    } else {
      void handleAcceptOrder(selectedETAOption);
    }
  };

  const currentTimeString = getCurrentTimestamp();

  return (
    <StyledModal onModalClose={onClose}>
      <ContentContainer>
        <TopContent>
          <Title>Choose Time</Title>
          <TitleDivider />
          <MainContent>
            <Subtitle>
              Select how long it will take to get this order ready.
            </Subtitle>
            <ButtonGroup>
              {etaOptions.map(option => (
                <RadioButton
                  checked={selectedETAOption === option}
                  key={`${option}-minutes-eta`}
                  label={`${option} mins`}
                  name="eta-radio-button"
                  onChange={() => handleETAOptionChange(option)}
                  sublabel={`${formatRequestedTimeWithOffset(
                    currentTimeString,
                    option
                  )}`}
                  value={`${option}-minutes-eta`}
                />
              ))}
            </ButtonGroup>
            <StyledInput
              errorText={customETAInputErrorMessage}
              label="Custom Time"
              name="customETAInput"
              inputMode="numeric"
              onChange={handleCustomETAInputChange}
              placeholder="Enter minutes"
              value={customETAInputValue}
            />
          </MainContent>
        </TopContent>
        <BottomContent>
          <ApplyButtonTopDivider />
          <ApplyButtonContainer>
            <ApplyButton
              label="Apply"
              onClick={handleOnApply}
              disabled={
                (!selectedETAOption && !customETAInputValue) ||
                !!customETAInputErrorMessage
              }
              isLoading={isUpdateOrderStatusRequestLoading}
            />
          </ApplyButtonContainer>
        </BottomContent>
      </ContentContainer>
    </StyledModal>
  );
};

export default AcceptOrderModal;
