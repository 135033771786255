import styled from 'styled-components';
import { space } from 'styles/variables';
import { label, paragraph } from 'styles/types';
import { Button } from '@chownow/cn-web-components';
import { semanticColors } from 'styles/colors';

export const StyledButton = styled(Button)<{ $isVisible: boolean }>`
  color: ${({ $isVisible }) => ($isVisible ? semanticColors.content.active : semanticColors.content.primary)};
  position: relative;
  :focus {
    outline: none;
  }
`;

export const Row = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const Card = styled.div<{ $isVisible: boolean }>`
  animation: slideUpIn 0.3s;
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${space.half};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  font: ${paragraph.reg.x14};
  line-height: 16px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  padding: ${space.x1};
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  width: 200px;
  z-index: 1;
`;

export const Title = styled.h6`
  font: ${label.semi.x12};
  margin: 0;
`;

export const Message = styled.p`
  font: ${label.reg.x12};
  margin: 0;
`;

export const Container = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const ClickCapture = styled.div<{ $isVisible: boolean }>`
  bottom: 0;
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;
