import React, { FC } from 'react';

interface StickerLocalProps {
  className?: string;
  height?: string;
  width?: string;
  viewBox?: string;
}

const StickerLocal: FC<StickerLocalProps> = ({
  className,
  height = '120',
  width = '120',
  viewBox = '0 0 120 120',
}) => (
  <svg className={className} width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M120 50.033L118.049 7L0 35.2516L4.3883 80.957L18.3237 77.2324L21.506 113L113.66 81.8756L112.212 52.1124L120 50.033Z" fill="#00BD71" />
    <path d="M59.9539 26.8087L63.6723 28.2451C64.0491 28.3954 64.2753 28.7879 64.1999 29.1888L60.5653 49.5069C60.5234 49.7407 60.7579 49.9412 60.984 49.8493L65.0708 48.2793C65.5482 48.0956 66.0842 48.3712 66.2014 48.8722L67.1143 52.5801C67.2064 52.9642 67.0221 53.3651 66.662 53.5488L59.4096 57.1398C59.0914 57.2984 58.7061 57.24 58.4465 56.9978L55.2809 54.0582C55.0715 53.8662 54.9794 53.5906 55.0213 53.315L58.8736 27.4434C58.9574 26.934 59.4766 26.6334 59.9539 26.8087Z" fill="#F5F4F1" />
    <path d="M13.7176 39.0931L17.0507 39.5107C17.4695 39.5608 17.7877 39.9199 17.7961 40.3458L17.9719 55.0186C17.9719 55.3694 18.4828 55.4195 18.5498 55.0687L21.4809 40.1454C21.5647 39.7111 21.975 39.4105 22.4189 39.4606L25.2914 39.7863C25.7101 39.8364 26.0283 40.1788 26.0451 40.5963L26.5392 52.9475C26.5476 53.2231 26.9495 53.2482 26.9998 52.9726L29.9979 34.6838C30.0817 34.1493 30.6428 33.832 31.1452 34.024L34.4113 35.2767C34.7966 35.4187 35.0143 35.8195 34.9473 36.2204L29.7383 65.9668C29.6545 66.4429 29.1856 66.7518 28.7166 66.6516L24.7721 65.7831C24.4204 65.708 24.1524 65.4073 24.1105 65.0482L22.8209 54.1L20.1158 68.397C20.0321 68.8396 19.6134 69.1402 19.1611 69.0818L15.1497 68.5473C14.7477 68.4972 14.4379 68.1632 14.4127 67.754L12.7546 39.995C12.7294 39.4606 13.19 39.0263 13.7176 39.0931Z" fill="#F5F4F1" />
    <path d="M37.3676 36.3122L45.4491 32.5877C45.9683 32.3455 46.5797 32.6795 46.655 33.2474L47.1073 36.688C47.1575 37.0722 46.9398 37.4396 46.5797 37.59L42.0741 39.4021C41.7978 39.5107 41.5968 39.7696 41.5465 40.0619L40.8095 44.9472C40.7677 45.2228 41.0273 45.4399 41.2869 45.3648L43.3387 44.7635C43.816 44.6215 44.3101 44.9222 44.4106 45.4065L45.0555 48.5131C45.1309 48.8973 44.9382 49.2898 44.5865 49.4568L39.9051 51.6615C39.6455 51.7868 39.4612 52.0373 39.4194 52.3212L39.009 55.4362C38.9755 55.7201 39.2602 55.9372 39.5282 55.837L42.8111 54.5092C43.2801 54.3171 43.8077 54.576 43.95 55.052L44.7959 57.858C44.9131 58.2338 44.7456 58.643 44.4023 58.8434C42.6771 59.8288 37.7528 62.6264 37.6942 62.4678C37.6356 62.3091 35.2237 59.9624 34.3527 59.119C34.1601 58.9353 34.068 58.668 34.1015 58.4008L36.8735 36.9803C36.9153 36.6797 37.0996 36.4292 37.3676 36.3122Z" fill="#F5F4F1" />
    <path d="M84.4581 22.0319L87.607 22.6081C87.9922 22.6833 88.2853 23.009 88.3021 23.4015C88.4863 26.7503 89.2986 41.5066 89.2986 41.3312C89.2986 41.1642 93.1677 24.6959 94.1559 20.4536C94.2732 19.9358 94.8343 19.6602 95.32 19.869L98.6615 21.297C99.0467 21.464 99.2561 21.89 99.1556 22.2908L91.8194 50.342C91.6854 50.8514 91.1327 51.1187 90.6469 50.9015L85.5049 48.6384C85.2118 48.5131 85.0192 48.2292 84.9941 47.9118L83.4448 22.9255C83.4196 22.366 83.9137 21.9317 84.4581 22.0319Z" fill="#F5F4F1" />
    <path d="M98.2343 42.9012L100.956 19.9776C100.99 19.6769 101.182 19.418 101.459 19.3011L110.47 15.3344C110.981 15.1089 111.558 15.4262 111.659 15.9691L112.253 19.393C112.32 19.7938 112.103 20.178 111.726 20.3283L106.768 22.2658C106.492 22.3743 106.291 22.6165 106.24 22.9088L105.403 27.7357C105.319 28.2034 105.771 28.5875 106.224 28.4455L108.443 27.7357C108.954 27.5687 109.481 27.9111 109.548 28.4372L109.967 31.811C110.009 32.1618 109.825 32.5125 109.507 32.6712L104.155 35.402C103.921 35.5272 103.753 35.7444 103.703 36.0116L103.217 38.7424C103.133 39.2184 103.594 39.6109 104.055 39.4522L107.681 38.1578C108.167 37.9824 108.694 38.2747 108.803 38.7758L109.565 42.2582C109.649 42.6256 109.473 43.0098 109.138 43.1852L102.254 46.9014C101.902 47.0935 101.467 47.0099 101.199 46.7093L98.4521 43.5443C98.2762 43.3772 98.2092 43.1351 98.2343 42.9012Z" fill="#F5F4F1" />
    <path d="M92.0706 52.2711C93.0672 52.6386 94.583 53.1981 95.4456 53.5238C95.8308 53.6657 96.057 54.0582 95.99 54.4674L92.5396 74.6937C92.4726 75.0946 92.8578 75.4286 93.2431 75.3033L96.7855 74.1759C97.2378 74.034 97.7319 74.2845 97.8659 74.7438L98.8038 77.9256C98.9127 78.3097 98.7452 78.7189 98.4018 78.911L91.1913 82.9195C90.8479 83.1116 90.4208 83.0448 90.1528 82.7525L87.3139 79.7043C87.138 79.5123 87.0542 79.2534 87.0961 79.0029L90.9401 52.9559C91.0154 52.4047 91.5682 52.079 92.0706 52.2711Z" fill="#F5F4F1" />
    <path d="M68.1611 71.7708C68.6468 71.9295 69.166 71.6289 69.2665 71.1278C69.7606 68.5557 70.8158 61.5825 67.0807 60.689L63.8314 59.7203C63.689 59.6785 63.5467 59.6701 63.4043 59.7035C62.257 59.9624 57.3578 61.8331 55.3563 74.7522C53.3212 87.9051 57.0061 90.1933 57.7933 90.519C57.8687 90.5524 57.944 90.5691 58.0278 90.5774L62.0057 91.02C62.19 91.0367 62.3658 91.0033 62.525 90.9115C63.5215 90.3352 67.131 87.8633 67.9601 82.0844C68.0187 81.6752 67.7758 81.2827 67.3822 81.1491L65.6403 80.5728C65.2048 80.4309 64.7275 80.6564 64.5684 81.0823C63.9654 82.7107 62.4663 86.2933 61.0175 85.7756C59.1584 85.1075 59.6776 77.817 60.8082 73.3074C61.9471 68.7979 63.488 64.7726 64.4176 64.8729C65.1797 64.948 65.1043 68.5306 65.0038 70.0672C64.9787 70.4597 65.2216 70.8105 65.5901 70.9357L68.1611 71.7708Z" fill="#F5F4F1" />
    <path d="M33.9675 91.8718C34.9808 91.496 36.5552 90.8947 37.5267 90.5273C37.9957 90.3519 38.4982 90.6275 38.6322 91.1286L39.6287 94.8281C39.746 95.254 39.5534 95.6966 39.1681 95.8803L31.7985 99.5297C31.4718 99.6884 31.0866 99.6216 30.8354 99.3544L28.0634 96.4816C27.8708 96.2812 27.7786 95.9889 27.8205 95.705L31.8236 68.6392C31.9073 68.0797 32.4601 67.7373 32.9625 67.9377L36.2705 69.2154C36.6557 69.3657 36.8902 69.7916 36.8149 70.2175L33.197 91.1703C33.1216 91.6547 33.5488 92.0305 33.9675 91.8718Z" fill="#F5F4F1" />
    <path d="M79.3914 24.3285L76.7199 23.5017C76.6446 23.4767 76.5692 23.4683 76.4938 23.4599C70.8493 23.343 68.3788 34.8257 68.2532 35.427C68.2532 35.4437 68.2448 35.4604 68.2448 35.4771C65.9334 50.9182 69.8611 52.8056 70.3636 52.9809C70.3971 52.9977 70.439 53.006 70.4725 53.0227C71.2178 53.3902 79.0983 56.931 81.3595 41.682C83.7295 25.673 79.3914 24.3285 79.3914 24.3285ZM77.1219 40.5963C77.1219 40.613 77.1135 40.6381 77.1135 40.6548C77.0298 41.1725 75.7904 48.4129 73.3031 49.0643C70.9247 49.6823 72.2311 34.3414 75.8406 29.5312C76.2928 28.9299 77.2392 29.1637 77.3648 29.8986C77.6076 31.3517 77.7165 34.3664 77.1219 40.5963Z" fill="#F5F4F1" />
    <path d="M50.8088 65.4157L48.1373 64.5889C48.062 64.5639 47.9866 64.5555 47.9112 64.5472C42.2667 64.4303 39.7962 75.913 39.6706 76.5142C39.6706 76.5309 39.6622 76.5477 39.6622 76.5644C37.3508 92.0055 41.2785 93.8928 41.781 94.0682C41.8145 94.0849 41.8564 94.0932 41.8899 94.1099C42.6352 94.4774 50.5157 98.0182 52.7769 82.7692C55.1385 66.7602 50.8088 65.4157 50.8088 65.4157ZM48.5393 81.6919C48.5393 81.7086 48.5309 81.7337 48.5309 81.7504C48.4472 82.2681 47.2078 89.5085 44.7205 90.1599C42.3421 90.7779 43.6485 75.437 47.258 70.6268C47.7102 70.0255 48.6566 70.2593 48.7822 70.9942C49.0167 72.4473 49.1255 75.4537 48.5393 81.6919Z" fill="#F5F4F1" />
    <path d="M106.72 75.365C107.183 73.1387 106.134 71.0393 104.377 70.6759C102.62 70.3124 100.82 71.8225 100.357 74.0488C99.8938 76.2751 100.943 78.3745 102.7 78.7379C104.457 79.1014 106.257 77.5913 106.72 75.365Z" fill="#F5F4F1" />
    <path d="M85.4547 84.0719L84.6758 57.1481C84.6591 56.6805 84.2739 56.313 83.7965 56.3214L78.1018 56.4717C77.7333 56.48 77.415 56.7222 77.3062 57.073L68.5212 85.5334C68.3788 86.0094 68.6636 86.5021 69.1493 86.6107L72.1474 87.2871C72.5745 87.3873 73.0016 87.1451 73.144 86.7276L75.045 81.099C75.1204 80.8735 75.2879 80.6898 75.5056 80.5896L79.3747 78.8859C79.9442 78.6354 80.589 79.0613 80.5723 79.6793L80.4801 84.1137C80.4718 84.5981 80.8654 84.9906 81.3427 84.9822L84.6172 84.9405C85.0946 84.9405 85.4714 84.548 85.4547 84.0719ZM76.5859 72.7646L78.6629 67.0692C78.9895 66.184 80.2959 66.4011 80.321 67.3448L80.4131 71.4201C80.4215 71.7792 80.1954 72.1132 79.8604 72.2385L77.0968 73.2657C76.7786 73.3826 76.4771 73.0736 76.5859 72.7646Z" fill="#F5F4F1" />
  </svg>
);

export default StickerLocal;
