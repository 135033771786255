import React from 'react';
import { ErrorContent, ErrorMessage, RefreshButton } from './styles';

const handleClick = () => {
  window.location.reload();
};

const ErrorCard = () => (
  <ErrorContent>
    <ErrorMessage>Oops! There was an issue loading your data.</ErrorMessage>
    <RefreshButton label="Refresh Page" onClick={handleClick} />
  </ErrorContent>
);

export default ErrorCard;
