import React from 'react';

import { StyledIconContainer, StyledIconCaretUp, StyledIconCaretDown, StyledTableHeader, TableHeaderContainer } from './styles';

export interface TableHeaderProps {
  /** Table Header content */
  children: string;
  /** Optional CSS class to be applied */
  className?: string;
  /** Allow for sorting of columns */
  isSortable?: boolean;
  /** If true, table header will truncate to customWidth */
  shouldTruncate?: boolean;
  /** Optional sort order */
  sortOrder?: SortOrderType;
  /** Sets optional custom width if truncation is true */
  customWidth?: string;
  /** Optional onClick handler */
  onClick?: () => void;
}

export type SortOrderType = 'asc' | 'desc' | '';

const TableHeader = ({
  children,
  className = '',
  isSortable = false,
  shouldTruncate = false,
  sortOrder = '',
  customWidth = 'auto',
  onClick,
}: TableHeaderProps) => (
  <StyledTableHeader
    className={className}
    onClick={onClick}
    $shouldTruncate={shouldTruncate}
    $customWidth={customWidth}
    $isSortable={isSortable}
    role={isSortable ? 'button' : 'columnheader'}
    tabIndex={isSortable ? 0 : undefined}
  >
    {isSortable ? (
      <TableHeaderContainer>
        <StyledIconContainer>
          <StyledIconCaretUp
            aria-label="caret up"
            $sortOrder={sortOrder}
            role="img"
          />
          <StyledIconCaretDown
            aria-label="caret down"
            $sortOrder={sortOrder}
            role="img"
          />
        </StyledIconContainer>
        {children}
      </TableHeaderContainer>
    ) : (
      children
    )}
  </StyledTableHeader>
);

export default TableHeader;
