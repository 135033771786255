import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { ModalContext } from 'context/ModalContext';

import { Button } from '@chownow/cn-web-components';
import Checkbox from 'components/Checkbox';
import ConfirmRefundModal from 'components/Modals/ConfirmRefundModal';

import styles from './styles.module.scss';

function DiscountModal(props) {
  const { orderDetails = {}, setOrderDetails } = props;
  const { total, subtotal, tip, delivery_fee, sales_tax, misc_fee, id } =
    orderDetails;
  const { showModal } = useContext(ModalContext);
  const [value, setValue] = useState('');
  const [applySubtotal, setApplySubtotal] = useState(true);
  const [isByAmount, setIsByAmount] = useState();
  const [error, setError] = useState();
  const adjustedTotal = isByAmount
    ? total - value
    : applySubtotal
    ? total - (subtotal * value) / 100
    : applyPercentageDiscount(total);

  function handleToggleClick() {
    setValue('');
    setIsByAmount(!isByAmount);
  }

  function stringToFloat(string) {
    // Remove all characters except numbers and decimals
    return string.replace(/[^\d\.]/g, '');
  }

  function handleOnChange(e) {
    const value = e.target.value;
    const newValue = stringToFloat(value) || '';

    setValue(newValue);
  }

  function applyPercentageDiscount(fee) {
    const adjustedFee = fee - (fee * value) / 100;

    // round two decimal places or return original amount or 0 if null
    return adjustedFee ? Math.round(adjustedFee * 1e2) / 1e2 : fee || 0;
  }

  async function handleConfirm() {
    const adjustedSubtotal = isByAmount
      ? subtotal - value
      : applyPercentageDiscount(subtotal);

    if (applySubtotal && isByAmount && value > subtotal) {
      setError(
        'Subtotal discount cannot be greater than the original subtotal.'
      );
      return;
    }

    const payload = applySubtotal
      ? {
          subtotal: adjustedSubtotal || 0,
          tip: tip || 0,
          delivery_fee: delivery_fee || 0,
          tax: sales_tax || 0,
          fee: misc_fee.amount || 0,
        }
      : {
          subtotal: adjustedSubtotal || 0,
          tip: applyPercentageDiscount(tip),
          delivery_fee: applyPercentageDiscount(delivery_fee),
          tax: applyPercentageDiscount(sales_tax),
          fee: applyPercentageDiscount(misc_fee.amount),
        };

    showModal(ConfirmRefundModal, {
      showCloseIcon: true,
      orderId: id,
      payload: { ...payload, refund_type: 'partial_by_amount' },
    });
  }

  return (
    <div className={styles.discountModal}>
      <div className={styles.headerGroup}>
        <div className={styles.header}>Refund By Amount</div>
        <div>
          Discounts will be applied to the total of an order unless otherwise
          specified.
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.inputRow}>
        <span
          className={classNames(styles.inputWrap, {
            [styles.activeInput]: isByAmount,
          })}
        >
          <input
            type="text"
            name="search"
            maxLength={isByAmount ? null : '2'}
            placeholder="0"
            className={styles.percentInput}
            onChange={handleOnChange}
            value={value}
            autoComplete="off"
          ></input>
        </span>
        <div
          className={classNames(styles.toggle, {
            [styles.activeToggle]: isByAmount,
          })}
          onClick={handleToggleClick}
        >
          <div className={styles.innerToggle}></div>
        </div>
      </div>
      <div className={styles.checkboxRow}>
        <span
          onClick={() => {
            setApplySubtotal(!applySubtotal);
          }}
        >
          <Checkbox
            isSelected={isByAmount || applySubtotal}
            disabled={isByAmount}
          />
        </span>
        <span className={styles.checkboxText}>only apply to subtotal</span>
      </div>
      <div className={styles.divider} />
      <div className={styles.totals}>
        <div>
          <div>Subtotal</div>
          <div>{formatMoney(subtotal)}</div>
        </div>
        <div>
          <div>Tax</div>
          <div>{formatMoney(sales_tax || 0)}</div>
        </div>
        <div>
          <div>Service Fee</div>
          <div>{formatMoney(get(misc_fee, 'amount') || 0)}</div>
        </div>
        <div>
          <div>Tip</div>
          <div>{formatMoney(tip || 0)}</div>
        </div>
        <div>
          <div>Delivery</div>
          <div>{formatMoney(delivery_fee || 0)}</div>
        </div>
        <div className={styles.totalAmount}>
          <div>Total</div>
          <div>{formatMoney(total)}</div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.revisedTotal}>
        <div>Revised Total</div>
        <div>{formatMoney(adjustedTotal || 0)}</div>
      </div>
      <Button
        className={styles.button}
        onClick={handleConfirm}
        isFullWidth
        label={'Confirm Discount'}
        disabled={isNaN(value) || adjustedTotal <= 0 || adjustedTotal === total}
      />
    </div>
  );
}

export default DiscountModal;
