import styled from 'styled-components';
import { screen, space } from 'styles/variables';
import { semanticColors } from 'styles/colors';

export const AddMenuContainer = styled.div`
  width: 100%;

  button {
    display: inline;
  }
`;

export const ButtonRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Or = styled.span`
  padding: 0 ${space.x1};

  @media (min-width: ${screen.smMin}) {
    .or {
      padding: 0 ${space.x2};
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${semanticColors.content.caution};
`;
