export const transformHours24To12 = (time: string, addSpace: boolean | null = false) => {
  if (time) {
    const timeArr = time.split(':');
    const numericHour = Number(timeArr[0]);
    let suffix = 'AM';

    if (timeArr[0] === '00') {
      timeArr[0] = '12';
    } else if (timeArr[0] === '12') {
      suffix = 'PM';
    } else if (numericHour > 12) {
      timeArr[0] = (numericHour % 12).toString();
      suffix = 'PM';
    } else {
      timeArr[0] = numericHour.toString();
    }

    const formattedTime = timeArr.join(':') + (addSpace ? ' ' : '') + suffix;
    return formattedTime;
  } else {
    return '';
  }
};
