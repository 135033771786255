import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { fontFamilyBase } from 'styles/types';

export const FileUploadContainer = styled.div`
  display: inline-block;
`;

export const Input = styled.input`
  left: -99999rem;
  position: absolute;
`;

// Designed to look/behave like our component library's Button Outline.
export const CustomFileUpload = styled.label`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: 28px;
  color: ${semanticColors.content.primary};
  cursor: pointer;
  display: inline-block;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  line-height: 1em;
  padding: ${space.oneAndHalf} ${space.x2};
  transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;

  &:hover {
    border: 1px solid ${semanticColors.border.tertiary};
  }
`;
