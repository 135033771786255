import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';

import { ReactComponent as CNLogo } from 'images/logos/cn-logo-mark.svg';
import { ReactComponent as CNLogoFull } from 'images/logos/cn-logo-full.svg';

import styles from './styles.module.scss';

const LogoTopBar = () => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  return (
    <div className={styles.main}>
      <Link to={'/login'} aria-label="return to login page">
        {isDesktop ? (
          <CNLogoFull className={styles.iconLogo} />
        ) : (
          <CNLogo className={styles.iconLogo} />
        )}
      </Link>
    </div>
  );
};

export default LogoTopBar;
