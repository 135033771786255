import React from 'react';
import PropTypes from 'prop-types';

import { EDIT_TYPE } from 'helpers/constants';

import styles from './styles.module.scss';

function ItemAndModifierStatus(props) {
  const { hiddenItemCount, hiddenModifierCount, handleOnSelectType } = props;

  return (
    <div className={styles.menuStatusContent}>
      {(hiddenItemCount || hiddenModifierCount) > 0 && (
        <span>
          {`You have `}
          {!!hiddenItemCount && (
            <>
              <div
                className={styles.countLink}
                onClick={() => handleOnSelectType(EDIT_TYPE.items)}
              >
                {hiddenItemCount} menu item
                {hiddenItemCount === 1 ? '' : 's'}
              </div>
              {!hiddenModifierCount ? ' currently hidden. ' : ', and '}
            </>
          )}
          {!!hiddenModifierCount && (
            <>
              <div
                className={styles.countLink}
                onClick={() => handleOnSelectType(EDIT_TYPE.modifiers)}
              >
                {hiddenModifierCount} modifier
                {hiddenModifierCount === 1 ? '' : 's'}
              </div>
              {` currently hidden.`}
            </>
          )}
        </span>
      )}
    </div>
  );
}

export default ItemAndModifierStatus;

ItemAndModifierStatus.propTypes = {
  handleOnSelectType: PropTypes.func,
  hiddenItemCount: PropTypes.number,
  hiddenModifierCount: PropTypes.number
};
