import React, { FC } from 'react';

import PickupHours from '../PickupHours';
import PickupDeliveryHours from '../PickupDeliveryHours';
import { useRestaurantHoursContext } from '../RestaurantHoursContext/RestaurantHoursContextProvider';
import { SetHoursHeading, SetHoursCardWrapper } from './styles';

const SetHoursCard: FC = () => {
  const {
    isDeliveryEnabled,
    isPickupEnabled,
  } = useRestaurantHoursContext();

  return (
    <SetHoursCardWrapper>
      <SetHoursHeading>Set Hours</SetHoursHeading>
      {(isDeliveryEnabled && isPickupEnabled) && <PickupDeliveryHours />}
      {(!isDeliveryEnabled && isPickupEnabled) && <PickupHours /> }
    </SetHoursCardWrapper>
  );
};

export default SetHoursCard;
