import React, { FC } from 'react';

import {
  FooterContainer,
  Link,
  Links,
} from './styles';

const CPRA_LINK =
  'https://chownow-requests.my.onetrust.com/webform/67994022-0d2e-4da4-af0f-30b990afab7a/b16058fc-4ca6-4d7a-a18d-d18b292f7d11';

interface FooterProps {
  className?: string;
  embeddedAdmin?: boolean;
}

const Footer: FC<FooterProps> = ({ className, embeddedAdmin }) => (
  <FooterContainer className={className} $embeddedAdmin={embeddedAdmin}>
    <Links>
      <Link href={CPRA_LINK} rel="noreferrer" target="_blank">
        Do Not Sell Or Share My Personal Information
      </Link>
      <Link href={CPRA_LINK} rel="noreferrer" target="_blank">
        Limit The Use Of My Sensitive Personal Information
      </Link>
    </Links>
  </FooterContainer>
);

export default Footer;
