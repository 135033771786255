import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { request } from 'helpers/api';
import {
  CUBOH_ANALYTICS_URL,
  THIRD_PARTY_MENU_SOURCE,
  USER_GROUP_ID,
} from 'helpers/constants';

import { UserContext } from 'context/UserContext';

import { Outlink } from '@chownow/cn-web-components';
import NavLink from '../NavLink';
import NavSubList from '../NavSubList';

import styles from './styles.modules.scss';

function NavList(props) {
  const { companyId, url, restaurantId, handleRequestClose } = props;
  const {
    user: {
      is_chownow_admin: isChowNowAdmin,
      is_super_admin,
      is_region_admin,
      group_id,
    },
  } = useContext(UserContext);
  const [activeSublist, setActiveSublist] = useState();
  const [prevLocation, setPrevLocation] = useState('');
  const [restaurant, setRestaurant] = useState();
  const location = useLocation();
  const flags = useFlags();
  const displayHomeLink =
    restaurant?.is_onboarding && restaurant?.sfdc_restaurant_uuid;
  const isAdmin = is_super_admin || isChowNowAdmin;
  const shouldShowAnalyticsLink =
    flags.useCubohAnalytics && group_id === USER_GROUP_ID.company_admin;
  const shouldUseCubohMenu =
    restaurant?.third_party_menu_source === THIRD_PARTY_MENU_SOURCE.cuboh;
  function handleOnToggle(title) {
    setActiveSublist(title);
  }

  // If changing from hq to location level or vise-versa, collapse all sublists.
  useEffect(() => {
    const isRestaurantUrl = location.pathname.includes('/restaurant/');
    const isPrevRestaurantUrl = prevLocation.includes('/restaurant/');

    if (
      (isRestaurantUrl && !isPrevRestaurantUrl) ||
      (!isRestaurantUrl && isPrevRestaurantUrl)
    ) {
      setActiveSublist();
    }
    setPrevLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    const requestRestaurant = async () => {
      if (restaurantId && !isNaN(restaurantId)) {
        // Right now this is only needed for displaying the link to the home page
        // If this fails, we will just not show the home page link
        try {
          const restaurant = await request(`restaurant/${restaurantId}`);
          setRestaurant(restaurant);
        } catch (e) {
          console.error(e);
        }
      }
    };

    requestRestaurant();
  }, [restaurantId]);

  return !restaurantId ? (
    <>
      <NavLink
        path={`/company/${companyId}/orders`}
        url={url}
        handleRequestClose={handleRequestClose}
      >
        Orders
      </NavLink>
      {shouldShowAnalyticsLink && (
        <Outlink to={CUBOH_ANALYTICS_URL} className={styles.outlink}>
          Analytics
        </Outlink>
      )}
      <NavSubList
        path={`/company/${companyId}/reports`}
        subMenuTitle={'reports'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          path={`/company/${companyId}/reports/accounting`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Accounting
        </NavLink>
        <NavLink
          path={`/company/${companyId}/reports/customer-spending`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Customer Spending
        </NavLink>
        <NavLink
          path={`/company/${companyId}/reports/emailed`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Emailed
        </NavLink>
        <NavLink
          path={`/company/${companyId}/reports/promos`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Promos
        </NavLink>
        <NavLink
          path={`/company/${companyId}/reports/order-source`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Order Source
        </NavLink>
        <NavLink
          path={`/company/${companyId}/reports/order-overview`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Order Overview
        </NavLink>
      </NavSubList>
      <NavSubList
        path={`/company/${companyId}/settings`}
        subMenuTitle={'settings'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          path={`/company/${companyId}/settings/hq-info`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          HQ Info
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/company/${companyId}/settings/locations`}
          url={url}
        >
          Locations
        </NavLink>
        {!is_region_admin && (
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/company/${companyId}/settings/integrations`}
            url={url}
          >
            Integrations
          </NavLink>
        )}
        {!is_region_admin && (
          <NavLink
            path={`/company/${companyId}/settings/users`}
            url={url}
            handleRequestClose={handleRequestClose}
          >
            Users
          </NavLink>
        )}
      </NavSubList>
      {isAdmin && (
        <NavSubList
          path={`/company/${companyId}/cn-internal`}
          subMenuTitle={'CN Internal'}
          onToggle={handleOnToggle}
          activeSublist={activeSublist}
          setActiveSublist={setActiveSublist}
          companyId={companyId}
        >
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/company/${companyId}/sherlock`}
            url={url}
          >
            Sherlock
          </NavLink>
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/company/${companyId}/cn-internal/push-notifications`}
            url={url}
          >
            Push Notifications
          </NavLink>
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/company/${companyId}/cn-internal/restaurant-categories`}
            url={url}
          >
            Restaurant Categories
          </NavLink>
        </NavSubList>
      )}
    </>
  ) : (
    <>
      {displayHomeLink && (
        <NavLink
          path={`/restaurant/${restaurantId}`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Home
        </NavLink>
      )}
      <NavLink
        isMultiPage
        path={`/restaurant/${restaurantId}/orders`}
        url={url}
        handleRequestClose={handleRequestClose}
      >
        Orders
      </NavLink>
      <NavSubList
        path={`/restaurant/${restaurantId}/menu`}
        subMenuTitle={'menu'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/menu/edit-menus`}
          url={url}
          isUsingCuboh={shouldUseCubohMenu}
        >
          Edit Menus
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/menu/edit-items`}
          url={url}
          isUsingCuboh={shouldUseCubohMenu}
        >
          Edit Items
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/menu/manage-photos/upload`}
          url={url}
          isMultiPage
        >
          Manage Photos
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/menu/hide-show-items`}
          url={url}
          isUsingCuboh={shouldUseCubohMenu}
        >
          Hide/Show Items
        </NavLink>
      </NavSubList>
      <NavSubList
        path={`/restaurant/${restaurantId}/hours`}
        subMenuTitle={'hours'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/hours/edit-schedule`}
          url={url}
          isUsingCuboh={shouldUseCubohMenu}
        >
          Edit Schedule
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/hours/edit-closures`}
          url={url}
        >
          Edit Closures
        </NavLink>
      </NavSubList>
      <NavSubList
        path={`/restaurant/${restaurantId}/reports`}
        subMenuTitle={'reports'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          path={`/restaurant/${restaurantId}/reports/accounting`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Accounting
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/reports/customer-spending`}
          url={url}
        >
          Customer Spending
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/reports/emailed`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Emailed
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/reports/promos`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Promos
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/reports/order-source`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Order Source
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/reports/order-overview`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Order Overview
        </NavLink>
      </NavSubList>
      <NavSubList
        path={`/restaurant/${restaurantId}/settings`}
        subMenuTitle={'settings'}
        onToggle={handleOnToggle}
        activeSublist={activeSublist}
        setActiveSublist={setActiveSublist}
        companyId={companyId}
      >
        <NavLink
          path={`/restaurant/${restaurantId}/settings/location-info`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Location Info
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/settings/payment-processing`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Payment Processing
        </NavLink>
        <NavLink
          handleRequestClose={handleRequestClose}
          path={`/restaurant/${restaurantId}/settings/customizations`}
          url={url}
        >
          Customizations
        </NavLink>
        <NavLink
          isMultiPage
          path={`/restaurant/${restaurantId}/settings/integrations`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Integrations
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/settings/manage-promos`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Manage Promos
        </NavLink>
        <NavLink
          path={`/restaurant/${restaurantId}/settings/email-notifications`}
          url={url}
          handleRequestClose={handleRequestClose}
        >
          Email Notifications
        </NavLink>
      </NavSubList>
      {isAdmin && (
        <NavSubList
          path={`/restaurant/${restaurantId}/cn-internal`}
          subMenuTitle={'CN Internal'}
          onToggle={handleOnToggle}
          activeSublist={activeSublist}
          setActiveSublist={setActiveSublist}
          companyId={companyId}
        >
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/restaurant/${restaurantId}/cn-internal/push-notifications`}
            url={url}
          >
            Push Notifications
          </NavLink>
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/restaurant/${restaurantId}/cn-internal/add-ons`}
            url={url}
          >
            Add-Ons
          </NavLink>
          <NavLink
            handleRequestClose={handleRequestClose}
            path={`/restaurant/${restaurantId}/cn-internal/restaurant-categories`}
            url={url}
          >
            Restaurant Categories
          </NavLink>
        </NavSubList>
      )}
    </>
  );
}

NavList.propTypes = {
  companyId: PropTypes.string,
  handleRequestClose: PropTypes.func,
  restaurantId: PropTypes.string,
  url: PropTypes.string,
};

export default NavList;
