import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { screen, space } from 'styles/variables';
import Button from '@chownow/cocina-react-button';

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: -${space.x3};
  max-width: 200px;
  position: absolute;
  right: 0;
  width: 100%;

  @media (min-width: ${screen.xsMin}) {
    max-width: 300px;
  }

  @media (min-width: ${screen.xsMax}) {
    left: 0;
    max-width: 400px;
  }
`;

export const ProgressBar = styled.div<{ $isActive: boolean }>`
  align-items: center;
  background-color: ${({ $isActive }) => (
    $isActive
      ? semanticColors.surface.active
      : semanticColors.content.success
  )};
  border: 1px solid;
  border-color: ${({ $isActive }) => (
    $isActive
      ? semanticColors.surface.active
      : semanticColors.content.success
  )};
  border-radius: 12px;
  display: flex;
  height: 20px;
  justify-content: flex-end;
  transition: background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease;
  width: 0;
`;

export const ProgressBarOutline = styled.div`
  align-items: center;
  background-color: ${semanticColors.border.inverseSecondary};
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: flex-start;
  width: 100%;
`;

export const Text = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;

  @media (max-width: ${screen.smMax}) {
    display: none;
  }
`;

export const Progress = styled.div`
  color: ${semanticColors.surface.primary};
  font: ${label.md.x12};
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;
