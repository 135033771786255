import { Integration, IntegrationStatus } from 'types/pos/integrations';

export const getIntegrationStatus = (item: Integration) => {
  if (item.is_active) {
    return IntegrationStatus.ACTIVE;
  } else if (item.is_authorized) {
    return IntegrationStatus.PAUSED;
  } else {
    return IntegrationStatus.AVAILABLE;
  }
};

export const getIntegrationStatusText = (status: IntegrationStatus, itemName: string) => {
  switch (status) {
    case IntegrationStatus.ACTIVE:
      return `Orders are being forwarded to your ${itemName} POS`;
    case IntegrationStatus.AVAILABLE:
      return 'Integration is available and ready to be set up';
    case IntegrationStatus.PAUSED:
      return 'Your integration is paused and orders are not being forwarded';
    case IntegrationStatus.ERRORS:
      return "There's an error with your integration and orders are not being forwarded";
    default:
      return '';
  }
};
