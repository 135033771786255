import React, { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';
import { noop } from 'lodash';

const MenuDataContext = createContext<MenuDataContextValue>({
  setMenuLink: noop,
  setMenuUpload: noop,
  setMenuNotes: noop,
  menuNotes: '',
  menuLink: '',
  menuUpload: '',
});

interface MenuDataContextValue {
  setMenuLink: (link: string) => void;
  setMenuUpload: (file: File | string) => void;
  setMenuNotes: (notes: string) => void;
  menuNotes: string;
  menuLink: string;
  menuUpload: File | string;
}

const MenuDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const [menuLink, setMenuLink] = useState('');
  const [menuNotes, setMenuNotes] = useState('');
  const [menuUpload, setMenuUpload] = useState<File | string>('');

  const value = useMemo(
    () => ({
      setMenuLink,
      setMenuUpload,
      setMenuNotes,
      menuNotes,
      menuLink,
      menuUpload,
    }),
    [setMenuLink, setMenuUpload, setMenuNotes, menuNotes, menuLink, menuUpload]
  );

  return (
    <MenuDataContext.Provider value={value}>
      {children}
    </MenuDataContext.Provider>
  );
};

export { MenuDataContext, MenuDataProvider };
