import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss'; // eslint-disable-line css-modules/no-unused-class

function Badge(props) {
  const { children, className, status } = props;
  return (
    <div
      className={classNames(styles.badge, className, {
        [styles.success]: status === 'success',
        [styles.danger]: status === 'danger'
      })}
      data-testid="badge"
    >
      <span>{children}</span>
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string
};

export default Badge;
