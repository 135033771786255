import React, { ChangeEvent, FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { capitalize } from 'lodash';
import { Banner, DashboardGrid, Grid, Radio } from '@chownow/cn-web-components';
import { useSubmitPosLocationForm } from 'api/posIntegrations/posLocationForm';
import Restaurant from 'types/restaurant';
import { Location } from 'types/pos/toast';
import ContentContainer from 'components/ContentContainer';
import { PosLocationsErrorBannerMessages } from './constants';
import LoadingState from '../../LoadingState';

import {
  ButtonWrapper,
  Heading,
  LocationAddress,
  LocationName,
  LocationPickerWrapper,
  LocationsIntro,
  LocationWrap,
  RadioListItem,
  StyledButton,
} from './styles';

interface PosLocationsProps {
  locations: Location[];
  onLocationSelected: () => void;
  restaurant: Restaurant;
  posName: string;
}

const PosLocations: FC<PosLocationsProps> = ({
  locations,
  onLocationSelected,
  restaurant,
  posName,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { hasSubmitPosLocationFormError, submitPosLocationForm } =
    useSubmitPosLocationForm();

  const handleCloseBanner = () => setErrorMessage('');

  const handleOptionChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation(changeEvent.target.value);
  };

  const handleSubmitForm = async () => {
    // not part of our usual pattern but setting an isLoading state here to give users
    // a more seamless loading spinner between the request and redirection
    setIsLoading(true);

    const response = await submitPosLocationForm(
      posName,
      restaurant.id,
      selectedLocation
    );

    if (response !== null && !hasSubmitPosLocationFormError) {
      setErrorMessage('');
      onLocationSelected();
    } else {
      setIsLoading(false);
      setErrorMessage(
        PosLocationsErrorBannerMessages.ERROR_MESSAGE_LINKING_POS
      );
    }
  };

  const handleLinkPos = (formSubmitEvent: React.FormEvent<HTMLFormElement>) => {
    formSubmitEvent.preventDefault();

    void handleSubmitForm();
  };

  let content = (
    <DashboardGrid>
      <Grid md={12}>
        <LocationPickerWrapper>
          <Heading>Link Point of Sale System</Heading>
          <LocationsIntro>
            Please select which {capitalize(posName)} Point of Sale system
            should be assigned to {restaurant.name}.
          </LocationsIntro>
          <form onSubmit={handleLinkPos}>
            <ul>
              {locations.map(location => (
                <RadioListItem key={location.id}>
                  <Radio
                    checked={selectedLocation === location.id}
                    name="pos-location-picker-radio-button"
                    onChange={handleOptionChange}
                    value={location.id}
                    id={location.id}
                  />
                  <label htmlFor={location.id}>
                    <LocationWrap>
                      <LocationName>{location.name}</LocationName>
                      {location.address && (
                        <LocationAddress>
                          {Object.values(location.address)
                            .filter(Boolean)
                            .join(', ')}
                        </LocationAddress>
                      )}
                    </LocationWrap>
                  </label>
                </RadioListItem>
              ))}
            </ul>
            <ButtonWrapper>
              <StyledButton
                type="submit"
                disabled={!selectedLocation}
                label="Link Location to POS"
                variant="fill"
              />
            </ButtonWrapper>
          </form>
        </LocationPickerWrapper>
      </Grid>
    </DashboardGrid>
  );

  if (isLoading) {
    content = (
      <ContentContainer>
        <LoadingState />
      </ContentContainer>
    );
  }

  return (
    <>
      <Helmet title="Select Location" />
      {errorMessage && (
        <Banner variant="caution" onClose={handleCloseBanner}>
          {errorMessage}
        </Banner>
      )}
      {content}
    </>
  );
};

export default PosLocations;
