import React, { FC, useState } from 'react';
import Banner from '@chownow/cocina-react-banner';
import OnboardingContainer from 'containers/Onboarding/OnboardingContainer';
import PageFooterRestaurantHours from 'containers/Onboarding/PageFooter/PageFooterRestaurantHours';
import PageIntro from 'containers/Onboarding/PageIntro';
import OrderTypeCard from 'containers/Onboarding/RestaurantHours/OrderTypeCard';
import SetHoursCard from 'containers/Onboarding/RestaurantHours/SetHoursCard';
import { ONB_WIZARD_INTRO } from 'helpers/constants';
import pictogram from 'images/pictograms/picto-hours-lg.svg';
import { usePostRestaurantHours } from 'api/onboarding';

import {
  INVALID_HOURS_MESSAGE,
  RESPONSE_ERROR_MESSAGE,
  REVIEW_MESSAGE,
} from './constants';
import { useRestaurantHoursContext } from './RestaurantHoursContext/RestaurantHoursContextProvider';
import { transformRestaurantHoursPostData, validateHours } from './helpers';
import ErrorCard from '../ErrorCard';
import SubmittedHoursCard from './SubmittedHoursCard';
import LoadingState from '../LoadingState';
import { useWizardNavigationContext } from '../WizardNavigationContext';

const RestaurantHours: FC = () => {
  const [isErrorBannerDisplayed, setIsErrorBannerDisplayed] =
    useState<boolean>();
  const [errorBannerMesage, setErrorBannerMessage] = useState<string>();
  const { isPostRequestLoading, postRestaurantHours } =
    usePostRestaurantHours();
  const {
    deliveryHours,
    hasGetRequestError,
    hasPreviouslySubmittedHours,
    isDeliveryEnabled,
    isFormCompleted,
    isGetRequestLoading,
    isPickupEnabled,
    isSameHours,
    pickupHours,
    uuid,
  } = useRestaurantHoursContext();
  const { isWizNavProgressRequestLoading, hasWizNavProgressRequestError } =
    useWizardNavigationContext();

  const handleOnSave = async () => {
    const isValidHours = validateHours(
      isDeliveryEnabled,
      isPickupEnabled,
      isSameHours,
      deliveryHours,
      pickupHours
    );
    let hasError = false;

    if (isValidHours) {
      const submittedHours = transformRestaurantHoursPostData(
        isDeliveryEnabled,
        isPickupEnabled,
        isSameHours,
        deliveryHours,
        pickupHours
      );

      const hasPostRequestError = await postRestaurantHours(
        uuid,
        submittedHours
      );

      if (hasPostRequestError) {
        setErrorBannerMessage(RESPONSE_ERROR_MESSAGE);
        setIsErrorBannerDisplayed(true);

        window.scrollTo(0, 0);

        hasError = true;
      }
    } else {
      setErrorBannerMessage(INVALID_HOURS_MESSAGE);
      setIsErrorBannerDisplayed(true);

      window.scrollTo(0, 0);

      hasError = true;
    }

    return hasError;
  };

  let content;

  const hasError = hasGetRequestError || hasWizNavProgressRequestError;
  const isLoading = isGetRequestLoading || isWizNavProgressRequestLoading;

  if (hasError) {
    content = <ErrorCard />;
  } else if (isLoading) {
    content = <LoadingState />;
  } else if (hasPreviouslySubmittedHours) {
    content = <SubmittedHoursCard />;
  } else {
    content = (
      <>
        <OrderTypeCard />
        {(isDeliveryEnabled || isPickupEnabled) && <SetHoursCard />}
      </>
    );
  }

  return (
    <>
      {isErrorBannerDisplayed && (
        <Banner variant="caution">{errorBannerMesage}</Banner>
      )}
      {hasPreviouslySubmittedHours && (
        <Banner variant="success">{REVIEW_MESSAGE}</Banner>
      )}
      <OnboardingContainer>
        <PageIntro
          pictogram={pictogram}
          pictogramLabel="Clipboard"
          title={ONB_WIZARD_INTRO.restaurantHoursTitle}
          description={ONB_WIZARD_INTRO.restaurantHoursDesc}
        />
        {content}
        {!isLoading && !hasWizNavProgressRequestError && (
          <PageFooterRestaurantHours
            isLoading={isPostRequestLoading}
            onSave={handleOnSave}
            isFormCompleted={isFormCompleted || hasPreviouslySubmittedHours}
            shouldContinueWithoutSave={hasPreviouslySubmittedHours}
          />
        )}
      </OnboardingContainer>
    </>
  );
};

export default RestaurantHours;
