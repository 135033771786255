import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import { getUrlParam } from 'helpers/url';

import { WizardProgressBar } from 'components/WizardProgressBar';

import { ReactComponent as CNLogo } from 'images/logos/cn-logo-full.svg';
import { ReactComponent as CNLogoMobile } from 'images/logos/cn-logo-mark.svg';

import styles from './styles.module.scss';

interface LayoutWizardProps {
  uuid: string;
}

const LayoutWizard = ({ uuid }: LayoutWizardProps) => {
  const restaurantId = getUrlParam('restaurant');
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  return (
    <>
      <Helmet>
        <style>{'body { background-color: #f5f4f1; margin: 0}'}</style>
      </Helmet>
      <header className={styles.header}>
        <a
          href={`${process.env.PUBLIC_URL}restaurant/${restaurantId}`}
          className={styles.logoLink}
        >
          {isDesktop ? (
            <CNLogo className={styles.logo} />
          ) : (
            <CNLogoMobile className={styles.logo} />
          )}
        </a>
        <WizardProgressBar
          className={styles.progressBar}
          restaurantUuid={uuid}
        />
      </header>
    </>
  );
};

export default LayoutWizard;
