import { formatError, request } from 'helpers/api';
import { Filters, OrdersListResponse } from 'types/orders-list';

/**
 * Helper for requestOrdersList to build query params
*/
export const buildQueryParams = (type: string, id: string, filters: Filters): string => {
  const params = new URLSearchParams();

  if (filters.order_status) {
    params.append('order_status', filters.order_status);
  }
  params.append('date_range', filters.date_range || '1_week');
  if (filters.order_by) {
    params.append('order_by', filters.order_by);
  }
  if (filters.sort_order) {
    params.append('sort_order', filters.sort_order);
  }
  if (filters.q) {
    params.append('q', `${filters.search}:${filters.q}`);
  }
  if (filters.offset) {
    params.append('offset', String(filters.offset));
  }

  return `orders?${type}_id=${id}&${params.toString()}`;
};

/**
 * GET request for all orders from a company or restaurant location
 * @param  {string} type - request type - company or restaurant
 * @param  {string} id - ChowNow company or restaurant ID
 * @param  {string} filters - filter params to build request url
 * @param  {string} paginatedUrl - url for pagination requests (used in Pagination component)
 * @returns {object} - returns orders object for company or restaurant location
 */
export const requestOrdersList = async (
  type: string,
  id: string,
  filters: Filters,
  paginatedUrl?: string
): Promise<OrdersListResponse | undefined> => {
  const url = paginatedUrl || buildQueryParams(type, id, filters);

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await request(url);
    return response as OrdersListResponse;
  } catch (err) {
    return formatError(err);
  }
};
