// Colors used for styled components

const colors = {
  // -----------------------
  // BRAND - GLOBAL
  // -----------------------
  neutral: {
    x1: '#fff',
    x2: '#f2f2f2',
    x3: '#dbdbdb',
    x4: '#a3a3a3',
    x5: '#6e6e6e',
    x6: '#1f1f1f',
    x7: '#000',
  },
  success: {
    x1: '#e6f5ee',
    x2: '#21a369',
    x3: '#0e7a4a',
  },
  caution: {
    x1: '#fae9e6',
    x2: '#f5563d',
    x3: '#cc1b00',
  },
  opacity: {
    neutral1: {
      x8: 'rgba(255, 255, 255, 0.08)',
      x16: 'rgba(255, 255, 255, 0.16)',
      x24: 'rgba(255, 255, 255, 0.24)',
      x40: 'rgba(255, 255, 255, 0.4)',
      x64: 'rgba(255, 255, 255, 0.64)',
    },
    neutral7: {
      x8: 'rgba(0, 0, 0, 0.08)',
      x16: 'rgba(0, 0, 0, 0.16)',
      x24: 'rgba(0, 0, 0, 0.24)',
      x40: 'rgba(0, 0, 0, 0.4)',
      x64: 'rgba(0, 0, 0, 0.64)',
    },
  },
  // -----------------------
  // BRAND - PRIMARY
  // -----------------------
  paper: {
    x1: '#f5f4f1',
    x2: '#dad8d2',
    x3: '#b2afa4',
  },
  ink: {
    x1: '#929bad',
    x2: '#343e52',
    x3: '#142138',
  },
  berry: {
    x1: '#fae1e1',
    x2: '#f98686',
    x3: '#f94e4e',
  },
  fig: {
    x1: '#dadff7',
    x2: '#5268cc',
    x3: '#223ba8',
  },
  // -----------------------
  // BRAND - SECONDARY
  // -----------------------
  orange: {
    x1: '#fcd1c8',
    x2: '#f99885',
    x3: '#f9603d',
  },
  lemon: {
    x1: '#f8edcd',
    x2: '#f6c774',
    x3: '#fcef54',
  },
  basil: {
    x1: '#afeedf',
    x2: '#2e9952',
    x3: '#00bd71',
  },
  icee: {
    x1: '#dff4f4',
    x2: '#2738a3',
    x3: '#50cdf9',
  },
};

// -----------------------
// EFFECTS
// -----------------------
export const effects = {
  shadowStrong: colors.opacity.neutral7.x24,
  shadowSoft: colors.opacity.neutral7.x16,
  modalOverlay: colors.opacity.neutral7.x64,
  imageOverlay: colors.opacity.neutral7.x40,
};

// -----------------------
// SEMANTIC NAMING
// -----------------------
export const semanticColors = {
  border: {
    primary: colors.paper.x2,
    secondary: colors.paper.x3,
    tertiary: colors.ink.x3,
    active: colors.fig.x3,
    caution: colors.caution.x3,
    transparent: colors.opacity.neutral7.x24,
    inversePrimary: colors.ink.x1,
    inverseSecondary: colors.ink.x2,
    inverseTertiary: colors.neutral.x1,
    inverseCaution: colors.caution.x2,
    inverseTransparent: colors.opacity.neutral1.x24,
  },
  content: {
    primary: colors.ink.x3,
    secondary: colors.neutral.x5,
    tertiary: colors.paper.x3,
    accent: colors.fig.x2,
    active: colors.fig.x3,
    chownow: colors.berry.x3,
    success: colors.success.x3,
    caution: colors.caution.x3,
    inversePrimary: colors.neutral.x1,
    inverseSecondary: colors.ink.x1,
    inverseActive: colors.fig.x1,
    inverseCaution: colors.caution.x2,
  },
  surface: {
    primary: colors.neutral.x1,
    secondary: colors.paper.x1,
    tertiary: colors.paper.x2,
    active: colors.fig.x3,
    success: colors.success.x1,
    caution: colors.caution.x1,
    accentPrimary: colors.paper.x1,
    accentSecondary: colors.paper.x2,
    inversePrimary: colors.ink.x3,
    inverseSecondary: colors.ink.x2,
    overlay: colors.opacity.neutral7.x64,
  },
};
