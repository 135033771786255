import React from 'react';

import {
  PageFooterContainer,
  PageFooterContent,
  ButtonGroup,
  LinkToHome,
  PreviousButton,
  SecondaryButton,
} from './styles';
import { ActiveButtonTypes } from '../types';

type PageFooterProps = {
  activeButtons: ActiveButtonTypes[];
  hasPrevUrl?: boolean;
  isFormCompleted: boolean;
  isLoading?: boolean;
  nextUrl?: string;
  onSaveClick?: () => Promise<void> | void;
  onPreviousClick?: () => void;
  onNextClick: () => void;
};

const PageFooterBase: React.FC<PageFooterProps> = ({
  activeButtons,
  hasPrevUrl = false,
  isFormCompleted,
  isLoading,
  nextUrl = '',
  onSaveClick,
  onPreviousClick,
  onNextClick,
}) => {
  const isActive = (button: ActiveButtonTypes) => activeButtons.includes(button);

  return (
    <PageFooterContainer data-testid="page-footer">
      <PageFooterContent>
        <ButtonGroup role="group" aria-label="form button group">
          {isActive('previous') && (
            <PreviousButton
              label="Previous"
              platform="brand"
              size="medium"
              variant="outline"
              onClick={onPreviousClick}
            />
          )}
          {isActive('cancel') && (
            <LinkToHome
              $hasSaveButton={isFormCompleted}
              $variant="outline"
              reloadDocument
              to={nextUrl}
            >
              Cancel
            </LinkToHome>
          )}
          {isActive('skip for now') && (
            <SecondaryButton
              $hasPrimaryCta={hasPrevUrl}
              label="Skip for Now"
              onClick={onNextClick}
            />
          )}
          {isActive('skip for now link') && (
            <LinkToHome
              $hasPrimaryCta={hasPrevUrl}
              $variant="primary"
              reloadDocument
              to={nextUrl ?? ''}
            >
              Skip for Now
            </LinkToHome>
          )}
          {isActive('continue') && (
            <SecondaryButton
              $hasPrimaryCta={hasPrevUrl}
              label="Continue"
              onClick={onNextClick}
            />
          )}
          {isActive('save') && (
            <SecondaryButton
              $hasPrimaryCta
              isLoading={isLoading}
              label="Save"
              onClick={onSaveClick as () => void}
            />
          )}
          {isActive('save and continue') && (
            <SecondaryButton
              $hasPrimaryCta={hasPrevUrl}
              isLoading={isLoading}
              label="Save and Continue"
              onClick={onSaveClick as () => void}
            />
          )}
          {isActive('done') && (
            <LinkToHome
              $hasPrimaryCta
              $variant="primary"
              reloadDocument
              to={nextUrl}
            >
              Done
            </LinkToHome>
          )}
        </ButtonGroup>
      </PageFooterContent>
    </PageFooterContainer>
  );
};

export default PageFooterBase;
