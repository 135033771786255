import React, { FC, MouseEventHandler } from 'react';
import { ReactComponent as StripeLogo } from 'images/logos/stripe-logo.svg';

import ActivateButton from './styles';

interface StripeButtonProps {
  isLoading: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const StripeButton: FC<StripeButtonProps> = ({ isLoading, onClick }) => (
  <ActivateButton
    dataTestId="stripe-button-onboarding"
    isFullWidth
    isLoading={isLoading}
    label={(
      <>
        <span>Activate with </span>
        <StripeLogo />
      </>
    )}
    onClick={onClick}
    variant="outline"
  />
);

export default StripeButton;
