import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import styles from './styles.module.scss';

const MerchantAccountVerification: FC = () => {
  const { verificationResult } = useParams();

  return (
    <div className={styles.container}>
      {verificationResult === 'success' ? (
        <>
          <Helmet title="Restaurant Successfully Verified" />
          <div className={styles.icon}>
            <span role="img" aria-label="verification successful checkmark">
              ✅
            </span>
          </div>
          <p className={styles.message}>
            Your information has been submitted for verification and ChowNow has
            been notified.
          </p>
        </>
      ) : (
        <>
          <Helmet title="Restaurant Could Not Be Verified" />
          <div className={styles.icon}>
            <span role="img" aria-label="verification failed warning">
              ⚠️
            </span>
          </div>
          <p className={styles.message}>
            Looks like we&apos;ve encountered an issue and could not verify your
            restaurant. Please reach out to your ChowNow account coordinator.
          </p>
        </>
      )}
    </div>
  );
};

export default MerchantAccountVerification;
