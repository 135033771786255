import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';

export const CheckboxWrapper = styled.div`
  margin-right: ${space.oneAndHalf};
`;

export const Divider = styled.div`
  border-top: 1px solid ${semanticColors.border.primary};
  margin-top: ${space.x3};
`;

export const Heading3 = styled.h3`
  font: ${label.semi.x16};
  letter-spacing: 0.2px;
  margin-top: ${space.x3};
`;
