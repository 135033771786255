import { Dispatch, SetStateAction } from 'react';

export const nameValidator = (
  name: string,
  setNameError: Dispatch<SetStateAction<string>>
) => {
  const hasInvalidName = !name || name.length > 255;

  if (hasInvalidName) {
    setNameError('Please enter a name');
  }
  return hasInvalidName;
};
