import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetSquareDetails,
  usePutSquareDisable,
} from 'api/posIntegrations/square';

import { Button, Cover, DashboardGrid, Grid } from '@chownow/cn-web-components';
import Banner from '@chownow/cocina-react-banner';
import ContentContainer from 'components/ContentContainer';
import LoadingSpinner from 'components/LoadingSpinner';
import DialogModal from 'components/Modals/DialogModal';
import PosLocations from 'containers/Restaurant/Integrations/PosLocations';
import SquareDetails from 'containers/Restaurant/Integrations/SquarePos/SquareDetails';
import Restaurant from 'types/restaurant';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import ErrorState from '../../ErrorState';
import { StyledDialogModal } from './styles';

interface SquareProps {
  restaurant: Restaurant;
  restaurantId: string;
}

const Square: FC<SquareProps> = ({ restaurant, restaurantId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const {
    getSquareDetailsRequestResponse,
    isGetSquareDetailsRequestLoading,
    hasGetSquareDetailsRequestError,
    updateSquareIsActive,
  } = useGetSquareDetails(restaurantId);

  const { isPutSquareDisableRequestLoading, putSquareDisable } =
    usePutSquareDisable(restaurantId);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      getSquareDetailsRequestResponse &&
      !getSquareDetailsRequestResponse.has_access_token
    ) {
      // re-direct to square oauth url
      window.location.href = getSquareDetailsRequestResponse.oauth_url;
    }
  }, [getSquareDetailsRequestResponse]);

  async function handleIsActiveChange() {
    if (getSquareDetailsRequestResponse) {
      const { is_active } = getSquareDetailsRequestResponse;
      setErrorMessage('');
      setIsLoading(true);

      const updateError = await updateSquareIsActive(!is_active);

      if (updateError) {
        setErrorMessage(updateError);
      }
      setIsLoading(false);
    }
  }

  const toggleIsActive = () => {
    void handleIsActiveChange();
  };

  async function handleDisableIntegration() {
    const error = await putSquareDisable();

    if (error) {
      setErrorMessage(error);
    } else {
      navigate(
        `/restaurant/${restaurant.id}/settings/integrations`
      );
    }
  }

  function handleDisableModalClose(didConfirm: boolean) {
    if (didConfirm) {
      void handleDisableIntegration();
    } else {
      setShouldShowModal(false);
    }
  }

  function handleDisableClick() {
    setShouldShowModal(true);
  }

  const { location, locations, location_id, is_active } =
    getSquareDetailsRequestResponse || {};
  let squareContent;
  let coverButton;
  let secondaryCoverButton;

  if (hasGetSquareDetailsRequestError) {
    squareContent = <ErrorState />;
  } else if (isLoading || isGetSquareDetailsRequestLoading) {
    squareContent = <LoadingSpinner />;
  } else if (!location_id) {
    squareContent = (
      <PosLocations
        locations={locations || []}
        restaurant={restaurant}
        onLocationSelected={() => window.location.reload()}
        posName="square"
      />
    );
  } else if (location && getSquareDetailsRequestResponse) {
    squareContent = (
      <SquareDetails
        restaurantLocation={location}
        restaurant={restaurant}
        posSquareResponse={getSquareDetailsRequestResponse}
      />
    );
    coverButton = (
      <Button
        label={is_active ? 'Pause Integration' : 'Send Orders'}
        onClick={toggleIsActive}
      />
    );
    secondaryCoverButton = (
      <Button
        label="Disable Integration"
        onClick={handleDisableClick}
        variant="outline"
        isLoading={isPutSquareDisableRequestLoading}
        disabled={isPutSquareDisableRequestLoading}
      />
    );
  }

  return (
    <>
      {errorMessage && (
        <Banner variant="caution" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Banner>
      )}
      <Cover
        title="Square"
        ctaElement={coverButton}
        secondaryCtaElement={secondaryCoverButton}
      />
      {shouldShowModal && (
        <StyledDialogModal
          centerAlign
          onModalClose={() => setShouldShowModal(false)}
        >
          <DialogModal
            title={DIALOG_MODAL_COPY.disableIntegration.title}
            message={DIALOG_MODAL_COPY.disableIntegration.message}
            confirmLabel={DIALOG_MODAL_COPY.disableIntegration.confirmLabel}
            cancelLabel={DIALOG_MODAL_COPY.disableIntegration.cancelLabel}
            onClose={handleDisableModalClose}
          />
        </StyledDialogModal>
      )}
      <ContentContainer>
        <DashboardGrid>
          <Grid md={12}>{squareContent}</Grid>
        </DashboardGrid>
      </ContentContainer>
    </>
  );
};

export default Square;
