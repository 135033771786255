import styled from 'styled-components';
import StatusDot from '@chownow/cocina-react-statusdot';
import { screen, space } from 'styles/variables';
import { label } from 'styles/types';

export const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: ${space.x3} 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${screen.smMin}) {
    padding: 0;
  }
`;

export const ListTitle = styled.p`
  font: ${label.reg.x14};
  margin: 0 ${space.x2} 0 0;
  min-width: 120px;
  width: 120px;
  word-wrap: break-word;

  @media (min-width: ${screen.smMin}) {
    min-width: 200px;
  }
`;

export const ListDetails = styled.div`
  align-items: flex-start;
  display: flex;
  font: ${label.reg.x14};
  justify-content: space-between;
  margin: 0;
`;

export const MenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledStatusDot = styled(StatusDot)`
  margin-right: ${space.half};
`;
