import ContentContainer from 'components/ContentContainer';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { screen, space } from 'styles/variables';

export const PaymentProcessingContent = styled.div`
  color: ${semanticColors.content.primary};
  margin-bottom: ${space.x4};

  /* XXS devices and above (mobile, 576px and up) */
  @media (min-width: ${screen.xsMin}) {
    display: inherit;
  }

  /* Small devices and above (tablet, 768px and up) */
  @media (min-width: ${screen.smMin}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledContentContainer = styled(ContentContainer)<{ $isOldAdminBannerDisplayed: boolean }>`
  min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '479px' : '403px')});

  @media (min-width: 493px) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '459px' : '383px')});
  }

  @media (min-width: 690px) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '438px' : '383px')});
  }

  @media (min-width: ${screen.smMin}) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '438px' : '383px')});
  }

  @media (min-width: ${screen.lgMin}) {
    min-height: calc(100vh - ${({ $isOldAdminBannerDisplayed }) => ($isOldAdminBannerDisplayed ? '356px' : '302px')});
  }
`;
