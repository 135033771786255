import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import withUser from 'components/WithUser';
import DialogModal from 'components/Modals/DialogModal';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';
import { getUrlParam } from 'helpers/url';

const DEFAULT_COMPANY_ID = 1;

function HomeRedirect() {
  const { user } = useContext(UserContext);
  const { showModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const restaurantId = getUrlParam('restaurant');
  const companyId = getUrlParam('companyId');
  const { is_chownow_admin, is_super_admin } = user;
  const isAdmin = is_super_admin || is_chownow_admin;
  const currentURL = window.location.href;
  const redirectPath = pathname + search;

  useEffect(() => {
    if (!user) {
      window.location.replace(
        `${
          process.env.REACT_APP_ADMIN_PYTHON_APP_URL
        }/login?next=${encodeURIComponent(currentURL)}`
      );
    }
    if (user) {
      if (user.company_id) {
        // Old restaurant URL redirect
        if (restaurantId && !companyId) {
          navigate(`/company/${user.company_id}${redirectPath}`);
        } else {
          navigate(`/company/${user.company_id}`);
        }

        /**
         * If a user is a cn or super admin there won't be a company_id associated
         * w/ their account so they will be defaulted to company id 1.
         */
      } else if (isAdmin) {
        // Old restaurant URL warning
        if (restaurantId && !companyId) {
          showModal(DialogModal, {
            title: 'Invalid URL',
            message: `No company has been selected — add
            a company ID to the URL or go back to admin to choose one first.`,
          });
        }
        navigate(`/company/${DEFAULT_COMPANY_ID}`);
      }
    }
  }, []);

  return null;
}

export default withUser(HomeRedirect, true);
