import { useEffect, useState } from 'react';
import { requestCustomApi } from 'helpers/api';
import {
  OnboardingWizardPages,
  OnboardingProgressStatus,
  ProgressResponse,
} from 'types/progress';

/**
 * Progress - Onboarding Wizard GET Progress API
 * @param  {string | null} uuid - ChowNow Restaurant UUID
 * @param  {boolean} shouldMakeRequest - used for Restaurant Homepage in the case we don't need
 * to make a request because the restaurant is live
 */
export const useGetProgress = (
  uuid: string | null,
  shouldMakeRequest = true
) => {
  const [isGetProgressRequestLoading, setIsGetProgressRequestLoading] =
    useState<boolean>(shouldMakeRequest);
  const [hasGetProgressRequestError, setHasGetProgressRequestError] =
    useState<boolean>(false);
  const [getProgressRequestResponse, setGetProgressRequestResponse] =
    useState<ProgressResponse>();

  const getProgress = async (restaurantUuid: string | null | undefined) => {
    setIsGetProgressRequestLoading(true);
    try {
      if (shouldMakeRequest && (restaurantUuid || uuid)) {
        const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: ProgressResponse = await requestCustomApi(
          `v1/restaurant/${restaurantUuid || uuid}/progress`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        setGetProgressRequestResponse(response);
        setHasGetProgressRequestError(false);
      }
    } catch (err) {
      setHasGetProgressRequestError(true);
    } finally {
      setIsGetProgressRequestLoading(false);
    }
  };

  useEffect(() => {
    if (shouldMakeRequest && uuid) {
      getProgress(uuid).catch(() => {
        /* do nothing */
      });
    }
  }, []);

  return {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
    getProgress,
  };
};

/**
 * Onboarding Wizard POST progress API
 * @param  {string} uuid - ChowNow Restaurant UUID
 * @param  {OnboardingWizardPages} pageName - Page Name for wizard step
 * @param  {OnboardingProgressStatus} status - Wizard step progress status
 */
export const usePostProgress = () => {
  const [isPostProgressRequestLoading, setIsPostProgressRequestLoading] =
    useState<boolean>(false);

  const postOnboardingProgress = async (
    uuid: string | null,
    pageName: OnboardingWizardPages,
    status: OnboardingProgressStatus
  ) => {
    let hasError = false;
    const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

    setIsPostProgressRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/progress`,
        env,
        'POST',
        {
          page_name: pageName,
          status,
        },
        // TODO: remove these once requestCustomApi is converted over to TS with optional params
        undefined,
        undefined
      );
    } catch (err) {
      hasError = true;
    } finally {
      setIsPostProgressRequestLoading(false);
    }

    return hasError;
  };

  return {
    isPostProgressRequestLoading,
    postOnboardingProgress,
  };
};
