import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scrollToElement from 'scroll-to-element';
import { useMediaQuery } from 'react-responsive';

import { menuCategoryType } from 'helpers/prop-types';
import breakpoints from 'helpers/breakpoints';

import SlideInDown from 'components/Animations/SlideInDown';
import CategoryHeader from 'components/MenuList/CategoryHeader';
import MenuSearchBar from 'components/MenuList/MenuSearchBar';
import CategoryTypeSelector from 'components/MenuList//CategoryTypeSelector';

import { ReactComponent as IconChevron } from 'images/icon-chevron.svg';
import { ReactComponent as IconCategories } from 'images/icon-categories.svg';
import styles from './styles.module.scss';

function MenuCategoryNav(props) {
  const {
    onSelectType,
    activeCategory,
    categories,
    editMode,
    prevCategory,
    setActiveCategory,
    searchQuery,
    setSearchQuery,
    topSectionRef,
  } = props;
  const [isDropdownToggled, setIsDropdownToggled] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.xsMin });
  const SCROLL_OFFSET = onSelectType ? -190 : -120;

  function handleScroll(index) {
    if (!isDropdownToggled) return;

    setIsDropdownToggled(false);

    scrollToElement(`#category_${index}`, {
      offset: SCROLL_OFFSET,
      ease: 'inOutExpo',
      duration: 1500,
    });

    setIsSearchExpanded(false);
  }

  function toggleDropdown() {
    setSearchQuery('');
    setIsDropdownToggled(!isDropdownToggled);
  }

  const CategoryNav = () => {
    const categoryRef = useRef(null);

    // handle off-click event
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          categoryRef?.current &&
          !categoryRef?.current.contains(event.target)
        ) {
          setIsDropdownToggled(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [categoryRef]);

    return (
      <div
        className={classNames(styles.categoryNav, {
          [styles.minimized]: !isDesktop && isSearchExpanded,
        })}
        data-testid="CategoryDropdown"
        onClick={toggleDropdown}
        ref={categoryRef}
      >
        <IconCategories className={styles.iconCategories} />
        <span className={styles.navHeader}>
          <CategoryHeader
            activeCategory={activeCategory}
            categories={categories}
            prevCategory={prevCategory}
          />
        </span>
        <IconChevron className={styles.iconChevron} />
        {isDropdownToggled && (
          <SlideInDown className={styles.dropdownWrapper}>
            <div className={styles.dropdownContent} id="categoryDropdown">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={classNames(styles.category, {
                    [styles.active]: category.name === activeCategory,
                  })}
                  onClick={() => {
                    handleScroll(index);
                  }}
                >
                  <span data-testid="CategoryList">{category.name}</span>
                </div>
              ))}
            </div>
          </SlideInDown>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <div className={styles.appWidthWrap} ref={topSectionRef}>
          <div className={styles.categoryNavWrap}>
            {onSelectType && (
              <CategoryTypeSelector
                onSelectType={onSelectType}
                editMode={editMode}
              />
            )}
            <div className={styles.searchRow}>
              <CategoryNav />
              <MenuSearchBar
                minimized
                isSearchExpanded={isSearchExpanded}
                setSearchExpanded={setIsSearchExpanded}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setActiveCategory={setActiveCategory}
                setIsDropdownToggled={setIsDropdownToggled}
              />
            </div>
          </div>
        </div>
      </div>
      {/* TODO: Fix position/zindex issue */}
      {/* {isDropdownToggled && !isDesktop && (
        <SlideUpIn className={styles.slideWrapper}>
          <div className={styles.dropdownContent} id="categoryDropdown">
            <div className={styles.modalHeader}>Categories</div>
            <button className={styles.closeIcon} onClick={toggleDropdown}>
              <IconClose />
            </button>
            {categories.map((category, index) => (
              <div
                key={index}
                className={classNames(styles.category, {
                  [styles.active]: category.name === activeCategory
                })}
                onClick={() => {
                  setIsSearchExpanded(false);
                  handleScroll(index);
                }}
              >
                <div className={styles.name}>{category.name}</div>
                <div className={styles.count}> {category.items.length}</div>
              </div>
            ))}
          </div>
        </SlideUpIn>
      )} */}
    </>
  );
}

export default MenuCategoryNav;

MenuCategoryNav.propTypes = {
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(menuCategoryType),
  editMode: PropTypes.string,
  onSelectType: PropTypes.func,
  prevCategory: PropTypes.string,
  searchQuery: PropTypes.string,
  setActiveCategory: PropTypes.func,
  setSearchQuery: PropTypes.func,
  topSectionRef: PropTypes.shape({}),
};
