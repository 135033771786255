import { isEmpty } from 'lodash';
import { logoAndFoodImageFileNameRegex } from 'helpers/validators';
import {
  isGetFoodImagePreviouslySubmittedResponse,
  FoodImageGetResponse,
  NoFoodImageOptions,
} from 'types/food-image';
import {
  isGetLogoPreviouslySubmittedResponse,
  LogoGetResponse,
} from 'types/logo';

import { BannerMessages } from '../constants';

export const getFoodImagePreviouslySubmittedText = (
  response: FoodImageGetResponse | undefined,
  uploadSucceeded: boolean,
  filename: string | undefined,
  noFoodOption: NoFoodImageOptions | undefined
): string => {
  let previouslySubmittedText = '';

  if (isGetFoodImagePreviouslySubmittedResponse(response)) {
    if (response?.has_food_image && response?.food_image_file?.filename) {
      previouslySubmittedText = response.food_image_file.filename;
    } else if (
      !response?.has_food_image &&
      response?.food_image_alt_choice === NoFoodImageOptions.STOCK_IMAGE
    ) {
      previouslySubmittedText = 'Stock Image';
    } else if (
      !response?.has_food_image &&
      response?.food_image_alt_choice === NoFoodImageOptions.NO_IMAGE
    ) {
      previouslySubmittedText = 'No Image';
    }
  } else if (uploadSucceeded) {
    if (filename) {
      previouslySubmittedText = filename;
    } else if (noFoodOption === NoFoodImageOptions.STOCK_IMAGE) {
      previouslySubmittedText = 'Stock Image';
    } else if (noFoodOption === NoFoodImageOptions.NO_IMAGE) {
      previouslySubmittedText = 'No Image';
    }
  }

  return previouslySubmittedText;
};

export const getLogoPreviouslySubmittedText = (
  response: LogoGetResponse | undefined,
  uploadSucceeded: boolean,
  filename: string | undefined,
  hasNoLogo: boolean | undefined
): string => {
  let previouslySubmittedText = '';

  if (isGetLogoPreviouslySubmittedResponse(response)) {
    if (response?.has_logo && response?.logo_file?.filename) {
      previouslySubmittedText = response.logo_file.filename;
    } else if (!response?.has_logo) {
      previouslySubmittedText = 'ChowNow will create a text based logo for you';
    }
  } else if (uploadSucceeded) {
    if (filename) {
      previouslySubmittedText = filename;
    } else if (hasNoLogo) {
      previouslySubmittedText = 'ChowNow will create a text based logo for you';
    }
  }

  return previouslySubmittedText;
};

export const getSubmittedBannerMessage = (
  getFoodImageRequestResponse: FoodImageGetResponse | undefined,
  getLogoRequestResponse: LogoGetResponse | undefined
) => {
  if (
    !isEmpty(getFoodImageRequestResponse) &&
    !isEmpty(getLogoRequestResponse)
  ) {
    return BannerMessages.LOGO_AND_FOOD_IMAGE_UPLOADED;
  } else if (
    !isEmpty(getFoodImageRequestResponse) &&
    isEmpty(getLogoRequestResponse)
  ) {
    return BannerMessages.FOOD_IMAGE_UPLOADED;
  } else if (
    isEmpty(getFoodImageRequestResponse) &&
    !isEmpty(getLogoRequestResponse)
  ) {
    return BannerMessages.LOGO_UPLOADED;
  } else {
    return '';
  }
};

export const getErrorBannerMessage = (
  logoUploadFailed: boolean,
  foodImageUploadFailed: boolean
) => {
  if (logoUploadFailed && foodImageUploadFailed) {
    return BannerMessages.LOGO_AND_FOOD_IMAGE_FAILED;
  } else if (logoUploadFailed) {
    return BannerMessages.LOGO_FAILED;
  } else if (foodImageUploadFailed) {
    return BannerMessages.FOOD_IMAGE_FAILED;
  }
  return '';
};

export const validateFilename = (file: File) => {
  if (file.name.length > 50) {
    return 'Filename has to be shorter than or equal to 50 characters.';
  }
  if (!logoAndFoodImageFileNameRegex.test(file.name)) {
    return "Invalid file name - make sure your file doesn't contain any special characters and is in one of the accepted file types.";
  }
  return '';
};

export const validateFoodImage = (file: File, img: HTMLImageElement) => {
  let errorMsg = validateFilename(file);
  if (img.width < 750 || img.height < 1200) {
    errorMsg = 'File is too small. Please upload a file that is at least 750px by 1200px.';
  }
  return errorMsg;
};
