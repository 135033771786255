import { fetchWithProgress, formatError, request } from 'helpers/api';

/**
 * Fetch presigned S3 url and post image
 * @param {string} companyID - ChowNow Company ID
 * @param {string} itemID - Menu Item ID
 * @param {string} image - path to image
 * @param {string} name - original image name
 */
export async function postImagetoS3(path, image, name, setProgress) {
  let presignedUrlResponse;
  let postImageToS3Response;

  try {
    // Fetch presigned url for S3
    presignedUrlResponse = await request(path, null, 'POST');
  } catch (err) {
    return formatError(err);
  }

  // Create form for post body
  const s3FormData = new FormData();
  for (let key in presignedUrlResponse.fields) {
    s3FormData.append(key, presignedUrlResponse.fields[key]);
  }
  // add image file to form
  const imageFile = new File([image], name);
  s3FormData.append('file', imageFile);

  try {
    // post image to S3 through cloudflare CDN
    postImageToS3Response = await fetchWithProgress(
      presignedUrlResponse.url,
      'POST',
      s3FormData,
      setProgress
    );
  } catch (err) {
    return formatError(err);
  }

  if (postImageToS3Response.errors) {
    return postImageToS3Response;
  } else {
    return presignedUrlResponse;
  }
}
