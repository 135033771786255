import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { space } from 'styles/variables';

export const TruncationDisclaimer = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${label.md.x14};
  margin: ${space.x1} 0 0;
  text-align: left;
`;
