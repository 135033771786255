import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { get } from 'lodash';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
  buildMenu,
  postItemLink,
  requestSquareCatalog,
  removeItemLink,
} from 'api/square';
import { requestCatalog } from 'api/restaurant';
import { CatalogContext } from 'context/CatalogContext';
import { ITEM_TYPE, CATALOG_FILTERS } from 'helpers/constants';
import { ModalContext } from 'context/ModalContext';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import { getLinkedItems, getPartialItems } from 'helpers/catalog';
import useErrorHandler from 'hooks/useErrorHandler';

import withUser from 'components/WithUser';
import Back from 'components/Back';
import { Button } from '@chownow/cn-web-components';
import SquareList from 'components/SquareList';
import CatalogList from 'components/CatalogList';
import CatalogItem from 'components/CatalogItem';
import CatalogItemModal from 'components/Modals/CatalogItemModal';
import LoadingSpinner from 'components/LoadingSpinner';
import { ReactComponent as IconWarning } from 'images/icon-warning.svg';
import DialogModal from 'components/Modals/DialogModal';

import styles from './styles.module.scss';

function SquareMenu({ restaurantId }) {
  const [menuError, setMenuError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isDragMode, setIsDragMode] = useState(false);
  const [isLoadingCN, setIsLoadingCN] = useState(true);
  const [isLoadingSquare, setIsLoadingSquare] = useState(true);
  const [CNItems, setCNItems] = useState([]);
  const [filteredCNItems, setFilteredCNItems] = useState([]);
  const [CNFilter, setCNFilter] = useState(CATALOG_FILTERS.all);
  const [squareItems, setSquareItems] = useState([]);
  const [lastSync, setLastSync] = useState(null);
  const [openItemId, setOpenItemId] = useState(null);

  const { showModal } = useContext(ModalContext);

  function toggleShowItem(itemId) {
    if (openItemId) {
      enableBodyScroll();
    } else {
      disableBodyScroll();
    }
    setOpenItemId(itemId);
  }

  function getCatalogItem() {
    return CNItems.find(item => item.id === openItemId);
  }

  const navigate = useNavigate();

  useEffect(() => {
    fetchCNMenu();
    fetchSquareMenu();
  }, [restaurantId]);

  useEffect(() => {
    if (CNFilter === CATALOG_FILTERS.all || !CNFilter) {
      setFilteredCNItems(CNItems);
    } else if (CNFilter === CATALOG_FILTERS.linked) {
      setFilteredCNItems(getLinkedItems(CNItems));
    } else if (CNFilter === CATALOG_FILTERS.partial) {
      setFilteredCNItems(getPartialItems(CNItems));
    } else if (CNFilter === CATALOG_FILTERS.unlinked) {
      setFilteredCNItems(CNItems.filter(item => !item.x_id));
    }
  }, [CNItems, CNFilter]);

  function getLinkedCount() {
    return getLinkedItems(CNItems).length;
  }

  function getPartialCount() {
    return getPartialItems(CNItems).length;
  }

  function getUnlinkedCount() {
    return CNItems.length - CNItems.filter(item => item.x_id).length;
  }

  useErrorHandler(fetchError, () => {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.menuLoadError,
      onClose: () => navigate('./'),
    });
  });

  useErrorHandler(menuError, () => {
    showModal(DialogModal, {
      title: 'Something went wrong.',
      message: menuError.message,
      onClose: () => window.location.reload(),
    });
  });

  async function fetchCNMenu() {
    setIsLoadingCN(true);
    const catalog = await requestCatalog(restaurantId);

    if (catalog.errors) {
      setFetchError(true);
      setIsLoadingCN(false);
    } else {
      setIsLoadingCN(false);
      setCNItems(catalog.CNItems);
    }
  }

  async function fetchSquareMenu(sync) {
    setIsLoadingSquare(true);
    const response = await requestSquareCatalog(restaurantId, sync);

    if (response.errors) {
      setFetchError(true);
      setIsLoadingSquare(false);
    } else {
      setIsLoadingSquare(false);
      setSquareItems(response.squareItems);
      setLastSync(response.last_sync_at);
    }
  }

  async function linkItem(squareId, CNId) {
    const catalog = { squareItems, CNItems, setCNItems };
    const response = await postItemLink(catalog, restaurantId, squareId, CNId);

    if (response.errors) {
      setMenuError(get(response.errors[0], 'message'));
    } else {
      setOpenItemId(CNId);
    }
  }

  async function unlinkItem(CNId) {
    const catalog = { squareItems, CNItems, setCNItems };
    const response = await removeItemLink(catalog, restaurantId, CNId);

    if (response.errors) {
      setMenuError(get(response.errors[0], 'message'));
    } else {
      setOpenItemId(null);
    }
  }

  function toggleDragMode(isDragging) {
    setIsDragMode(isDragging);
  }

  function handleOnClick() {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.buildMenu,
      onClose: handleBuildMenu,
    });
  }

  async function handleBuildMenu(didConfirm) {
    if (didConfirm) {
      setIsLoadingCN(true);
      const response = await buildMenu(restaurantId);

      if (response.errors) {
        setMenuError(get(response.errors[0], 'message'));
      } else {
        fetchCNMenu();
      }
    }
  }

  function onFilter(filter) {
    setCNFilter(filter);
  }

  let catalogDisplay;
  if (filteredCNItems.length) {
    catalogDisplay = filteredCNItems.map(item => (
      <CatalogItem
        isCN
        isDragMode={isDragMode}
        isOpen={item.id === openItemId}
        item={item}
        key={item.id}
        toggleShowItem={toggleShowItem}
        unlinkItem={unlinkItem}
      />
    ));
  } else if (!CNItems.length) {
    catalogDisplay = (
      <div className={styles.emptyListBuildMenu}>
        <Button onClick={handleOnClick} label={'Build Menu'} />
      </div>
    );
  } else {
    catalogDisplay = (
      <div className={styles.emptyList}>
        <IconWarning className={styles.iconWarning} />
        <div>No Items to Display</div>
      </div>
    );
  }

  if (isLoadingCN) {
    return <LoadingSpinner />;
  } else if (fetchError) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Helmet title="Menu" />
      <Back />
      <h2 className={styles.header}>Menu</h2>
      <div className={styles.catalogs}>
        <CatalogContext.Provider value={{ CNItems, squareItems, setCNItems }}>
          <SquareList
            isDragMode={isDragMode}
            isLoading={isLoadingSquare}
            items={squareItems}
            itemType={ITEM_TYPE.menuItem}
            lastSyncDate={lastSync}
            linkItem={linkItem}
            onRefresh={fetchSquareMenu}
            toggleDragMode={toggleDragMode}
          />
          <CatalogList
            CNFilter={CNFilter}
            isDragMode={isDragMode}
            itemCount={CNItems.length}
            itemCountLinked={getLinkedCount}
            itemCountPartial={getPartialCount}
            itemCountUnlinked={getUnlinkedCount}
            items={CNItems}
            label="Menu Items"
            onFilter={onFilter}
          >
            {catalogDisplay}
          </CatalogList>
          {openItemId && (
            <CatalogItemModal
              restaurantId={restaurantId}
              item={getCatalogItem()}
              toggleShowItem={toggleShowItem}
              unlinkItem={unlinkItem}
            />
          )}
        </CatalogContext.Provider>
      </div>
    </div>
  );
}

SquareMenu.propTypes = {
  restaurantId: PropTypes.string,
};

export default withUser(SquareMenu, true);
