import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import PropTypes from 'prop-types';
import { find, get, isEqual } from 'lodash';
import { formatMoney } from '@chownow/cn-web-utils/format';
import classNames from 'classnames';

import { CatalogContext } from 'context/CatalogContext';
import { ModalContext } from 'context/ModalContext';
import { findDefaultSize } from 'helpers/catalog';
import { ITEM_TYPE } from 'helpers/constants';
import { squareItemType, menuItemType } from 'helpers/prop-types';

import SquareItemModal from 'components/Modals/SquareItemModal';

import { ReactComponent as IconDrag } from 'images/icon-drag.svg';

import styles from './styles.module.scss';

export default function SquareItem({
  item,
  itemType,
  linkItem,
  listScrollTop,
  toggleDragMode,
  CNItem,
  scrollParent,
}) {
  const modifierCount = item.modifiers ? item.modifiers.length : 0;
  const sizeCount = item.serving_sizes ? item.serving_sizes.length : 0;
  const itemRef = useRef();
  const catalog = useContext(CatalogContext);
  const isLinked = isItemLinked();
  const maxHeight = getMaxHeight();
  const modalParent = get(scrollParent, 'current');
  const scrollDistance = modalParent ? modalParent.scrollTop : window.scrollY;
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showModal } = useContext(ModalContext);
  const [springProps, setSpringProps] = useSpring(() => ({
    xyz: [0, 0, 0],
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0)',
    // Setting max-height is needed for the "collapse" animation
    maxHeight,
    onRest: (styleProps) => {
      if (isEqual(styleProps.value.xyz, [0, 0, 0])) {
        setIsDragging(false);
      }
    },
  }));
  const [wrapperSpringProps, setWrapperSpringProps] = useSpring(() => ({
    // Setting max-height is needed for the "collapse" animation
    maxHeight: maxHeight,
  }));

  const gestureBinds = useDrag(
    ({ event, down, movement: [mx, my], first, last, cancel, canceled }) => {
      if (canceled) return;

      const isNotDraggable = !event.target.closest('#draggable');

      if (!canceled && isNotDraggable && first) {
        return cancel();
      }
      if (first) {
        setIsDragging(true);
        toggleDragMode(true);
        document.body.style.cursor = 'move';
      } else if (last) {
        const dropTarget = event.target.closest('#cn-item');

        if (dropTarget) {
          const cnId = dropTarget.getAttribute('data-id');
          linkItem(item.id, cnId);
          setWrapperSpringProps(maxHeight);
        }
        document.body.style.cursor = 'unset';
        toggleDragMode(false);
      }
      setSpringProps({
        xyz: [down ? mx : 0, down ? my : 0, 0],
        boxShadow: `0 1px 3px 0 rgba(0, 0, 0, ${down ? '0.2' : '0'})`,
      });
    }
  );

  useEffect(() => {
    setWrapperSpringProps({
      maxHeight,
    });
  }, [catalog]);

  function toggleShowItem() {
    showModal(SquareItemModal, {
      isReadOnly: true,
      showCloseIcon: true,
      onClose: () => {
        setIsModalOpen(false);
      },
      item,
    });
    setIsModalOpen(true);
  }

  function getMaxHeight() {
    let maxHeight = 0;

    if (!isLinked) {
      maxHeight =
        sizeCount > 1 || modifierCount || itemType === ITEM_TYPE.modifier
          ? 131
          : 111;
    }
    return `${maxHeight}px`;
  }

  function isItemLinked() {
    let isLinked = false;
    if (itemType === ITEM_TYPE.modifier) {
      isLinked = !!find(CNItem.modifiers, {
        x_id: item.id,
      });
    } else if (itemType === ITEM_TYPE.size) {
      isLinked = !!find(CNItem.serving_sizes, (size) => {
        const sizeId = size.x_id && size.x_id.split('_')[1];
        return sizeId === item.id;
      });
    } else {
      const defaultSize = findDefaultSize(item);
      isLinked = !!find(catalog.CNItems, {
        // Check if CN item exists with the `x_id` value equal to <squareItemId>_<squareSizeId>
        x_id: `${item.id}_${defaultSize.id}`,
      });
    }

    return isLinked;
  }

  return (
    <animated.div
      style={{
        maxHeight: wrapperSpringProps.maxHeight,
        overflow: 'hidden',
        height:
          sizeCount > 1 || modifierCount || itemType === ITEM_TYPE.modifier
            ? '131px'
            : '111px',
      }}
    >
      <animated.div
        {...gestureBinds()}
        ref={itemRef}
        className={classNames(styles.item, {
          [styles.isDragging]: isDragging,
          [styles.linked]: isLinked,
          [styles.isModalOpen]: isModalOpen,
        })}
        style={{
          transform: springProps.xyz.interpolate(
            (x, y, z) =>
              `translate3d(${x}px, ${
                // due to the item being fixed, we need to adjust for scroll distance
                isDragging ? y - listScrollTop - scrollDistance : y
              }px, ${z}px)`
          ),
          boxShadow: springProps.boxShadow,
        }}
      >
        <div>
          <div className={styles.itemName}>{item.name || item.size}</div>
          <div className={styles.itemDetails}>
            {!!modifierCount &&
              `${modifierCount} ${
                modifierCount > 1 ? 'Modifiers' : 'Modifier'
              }`}
            {sizeCount > 1 && `${modifierCount ? ' •' : ''} ${sizeCount} Sizes`}
            {itemType === ITEM_TYPE.modifier && item.categoryName}
          </div>
          <div className={styles.itemPrice}>
            {formatMoney(item.price || 0)}
            {itemType === ITEM_TYPE.menuItem && (
              <span className={styles.detailLink} onClick={toggleShowItem}>
                Details
              </span>
            )}
          </div>
        </div>
        <div className={styles.dragWrapper} id="draggable">
          <IconDrag className={styles.dragIcon} />
        </div>
      </animated.div>
    </animated.div>
  );
}

SquareItem.propTypes = {
  CNItem: menuItemType,
  children: PropTypes.node,
  item: squareItemType,
  itemType: PropTypes.string,
  linkItem: PropTypes.func,
  listScrollTop: PropTypes.number,
  scrollParent: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  toggleDragMode: PropTypes.func,
};
