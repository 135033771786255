import React, { FC } from 'react';
import { sanitize } from 'dompurify';
import { Button } from '@chownow/cn-web-components';

import {
  ActivateButton,
  ButtonGroup,
  ContentLeft,
  FlexWrap,
  Header,
  HelpCard,
  HelpCardHeader,
  IconArrowOut,
  ListItem,
  OrderedList,
} from './styles';

interface AuthorizationInstructions {
  action_link: string;
  action_label: string;
  header: string;
  message: string;
}

interface ToastPosLandingPageContentProps {
  authorizationInstructions: AuthorizationInstructions | null;
}

const ToastPosLandingPageContent: FC<ToastPosLandingPageContentProps> = ({
  authorizationInstructions,
}) => {
  const createMarkup = (text: string) => ({ __html: sanitize(text) });

  const handleActionOnClick = () => {
    window.open(authorizationInstructions?.action_link, '_blank');
  };

  const handleRefreshOnClick = () => {
    window.location.reload();
  };

  return (
    <FlexWrap>
      <ContentLeft>
        <Header>
          {authorizationInstructions?.header}
        </Header>
        { /* eslint-disable react/no-danger */}
        <div
          dangerouslySetInnerHTML={createMarkup(
            authorizationInstructions?.message || ''
          )}
        />
        { /* eslint-enable react/no-danger */}
        <ButtonGroup>
          <ActivateButton
            /** Due to the way the Button component is written we can't pass the icon
             * through the 'icon' prop because it places it on the wrong side of the text.
             */
            label={(
              <>
                <span>{authorizationInstructions?.action_label}</span>
                <IconArrowOut />
              </>
            )}
            onClick={handleActionOnClick}
          />
          <Button
            label="Refresh Status"
            variant="outline"
            onClick={handleRefreshOnClick}
          />
        </ButtonGroup>
      </ContentLeft>
      <HelpCard>
        <HelpCardHeader>
          Once you link your Toast Account,
          this page will automatically refresh
          and display your integration details.<br /><br />
          If you still see this page:
        </HelpCardHeader>
        <OrderedList>
          <ListItem>Check that the IDs you entered match those provided on this page</ListItem>
          <ListItem>Check that you selected the correct Toast location to connect</ListItem>
          <ListItem>Reach out to ChowNow Customer Support</ListItem>
        </OrderedList>
      </HelpCard>
    </FlexWrap>
  );
};

export default ToastPosLandingPageContent;
