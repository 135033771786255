import React from 'react';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { itemType } from 'helpers/prop-types';

import styles from './styles.module.scss';

function OrderDate(props) {
  const { item } = props;

  return (
    <li className={styles.itemRow}>
      <div className={styles.itemQuantityName}>
        <div className={styles.itemQuantity}>{item.quantity}</div>
        <div className={styles.itemNames}>
          <div className={styles.itemContainer}>
            <div className={styles.itemName}>
              {item.name} ({item.size})
            </div>
            <div className={styles.itemPrice}>
              {formatMoney(item.per_item_total)}
            </div>
          </div>
          {item.modifier_categories.map(modifier => (
            <div key={modifier.id}>
              <div className={styles.modifierCategory}>{modifier.name}</div>
              {modifier.selections.map(selection => (
                <div className={styles.modifiers} key={selection.id}>
                  <div>{`${selection.name} `}</div>
                  <div>{formatMoney(selection.price * item.quantity)}</div>
                </div>
              ))}
            </div>
          ))}
          {item.special_instructions && (
            <div className={styles.specialInstructions}>
              {item.special_instructions}
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

OrderDate.propTypes = {
  item: itemType,
};

export default OrderDate;
