import React from 'react';
import { useParams } from 'react-router-dom';

import EmbeddedAdmin from 'containers/EmbeddedAdmin';

const Customer = () => {
  const { customer_id } = useParams();

  return (
    <EmbeddedAdmin
      src={`customer_by_cust_id/${customer_id}`}
      title="Customer Details"
      key="customer"
    />
  );
};

export default Customer;
