import React from 'react';

import { restaurantType } from 'helpers/prop-types';
import { THIRD_PARTY_MENU_SOURCE } from 'helpers/constants';

import { Cover } from '@chownow/cn-web-components';
import ContentContainer from 'components/ContentContainer';
import MenuList from 'components/MenuList';
import ThirdPartySotMessaging from '../Restaurant/ThirdPartyMenuSotMessaging';

function MenuEditor(props) {
  const { restaurant } = props;
  const thirdPartyMenuSot = restaurant?.third_party_menu_source;

  return thirdPartyMenuSot ? (
    <>
      <Cover title="Hide/Show Items" />
      <ThirdPartySotMessaging
        isCuboh={thirdPartyMenuSot === THIRD_PARTY_MENU_SOURCE.cuboh}
      />
    </>
  ) : (
    <ContentContainer>
      <MenuList
        showModifiers
        restaurant={restaurant}
        header="Hide/Show Items"
        subheader="Use the toggles below to temporarily '86' sold-out items on your menu. This change
          is visible to customers and takes effect immediately."
      />
    </ContentContainer>
  );
}

MenuEditor.propTypes = {
  restaurant: restaurantType,
};

export default MenuEditor;
