import React, { FC } from 'react';
import { useUpdateOrderStatus } from 'api/orders/orderDetails';

import { StyledModal, Subtitle, Title } from '../styles';
import { StyledButton } from './styles';

interface CancelOrderModalProps {
  onError: (message: string) => void;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  orderId: string | undefined;
}

const CancelOrderModal: FC<CancelOrderModalProps> = ({
  onError,
  onClose,
  onSuccess,
  orderId,
}) => {
  const { updateOrderStatus, isUpdateOrderStatusRequestLoading } = useUpdateOrderStatus();

  const handleConfirmCancelOrder = async () => {
    if (orderId) {
      const errorMessage = await updateOrderStatus(orderId, 'cancel', null);

      if (errorMessage) {
        onError(errorMessage);
      } else {
        // If request is successful, re-fetch the order details response to get the updated order status
        await onSuccess();
      }
    }
  };

  return (
    <StyledModal onModalClose={onClose}>
      <Title>Do you want to cancel this order?</Title>
      <Subtitle>
        Please confirm you would like cancel this order. You can not undo this action.
      </Subtitle>
      <StyledButton
        label="Confirm"
        onClick={() => { void handleConfirmCancelOrder(); }}
        isLoading={isUpdateOrderStatusRequestLoading}
      />
    </StyledModal>
  );
};

export default CancelOrderModal;
