import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilterDropdown from 'components/CatalogList/FilterDropdown';

import { ReactComponent as CnLogo } from 'images/logos/cn-logo-mark-berry.svg';

import styles from './styles.module.scss';

export default function CatalogList(props) {
  const {
    children,
    CNFilter,
    isDragMode,
    itemCount,
    itemCountLinked,
    itemCountPartial,
    itemCountUnlinked,
    label,
    onFilter
  } = props;

  return (
    <div className={styles.container}>
      <>
        <div
          className={classNames(styles.heading, {
            [styles.sublist]: label !== 'Menu Items'
          })}
        >
          <CnLogo className={styles.logo} />
          <h5 className={styles.label}>{label}</h5>
          <div className={styles.itemCount}>{`${itemCount} Item${
            itemCount === 1 ? '' : 's'
          }`}</div>
          {onFilter && (
            <FilterDropdown
              CNFilter={CNFilter}
              onFilter={onFilter}
              isDragMode={isDragMode}
              itemCount={itemCount}
              itemCountLinked={itemCountLinked}
              itemCountPartial={itemCountPartial}
              itemCountUnlinked={itemCountUnlinked}
            />
          )}
        </div>
      </>
      <ul className={styles.itemList}>{children}</ul>
    </div>
  );
}

CatalogList.propTypes = {
  CNFilter: PropTypes.string,
  children: PropTypes.node,
  isDragMode: PropTypes.bool,
  itemCount: PropTypes.number,
  itemCountLinked: PropTypes.func,
  itemCountPartial: PropTypes.func,
  itemCountUnlinked: PropTypes.func,
  label: PropTypes.string,
  onFilter: PropTypes.func
};
