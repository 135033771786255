import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParam, getUrlParam } from 'helpers/url';
import { WizardNavPagePaths } from 'types/progress';

import { getNavUrls } from '../../helpers';
import { useWizardNavigationContext } from '../../WizardNavigationContext';
import PageFooterBase from '../PageFooterBase';
import { ActiveButtonTypes } from '../types';

interface PageFooterShareMenuProps {
  isFormCompleted: boolean;
  isLoading: boolean;
}

const PageFooterShareMenu: React.FC<PageFooterShareMenuProps> = ({
  isLoading,
  isFormCompleted,
}) => {
  const [nextUrl, setNextUrl] = useState<string>('');
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const restaurantId = getUrlParam('restaurant');
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const homepageUrl = `/restaurant/${restaurantId}`;
  const navigate = useNavigate();
  const { wizardPages } = useWizardNavigationContext();

  useEffect(() => {
    if (isComingFromHomepage) {
      setNextUrl(homepageUrl);
    } else if (wizardPages && wizardPages.length > 1) {
      const { nextUrl: nextWizardUrl } = getNavUrls(
        WizardNavPagePaths.SHARE_MENU,
        wizardPages
      );

      setNextUrl(nextWizardUrl);
      setIsLastPage(nextWizardUrl === homepageUrl);
    }
  }, [isComingFromHomepage, wizardPages]);

  const handleClickSkip = () => {
    navigate(nextUrl);
  };

  const activeButtons: ActiveButtonTypes[] = [];

  if (isComingFromHomepage) {
    activeButtons.push('cancel');

    if (isFormCompleted) {
      activeButtons.push('done');
    }
  } else if (isLastPage) {
    if (isFormCompleted) {
      activeButtons.push('done');
    } else {
      activeButtons.push('skip for now link');
    }
  } else if (isFormCompleted) {
    activeButtons.push('continue');
  } else {
    activeButtons.push('skip for now');
  }

  return (
    <PageFooterBase
      activeButtons={activeButtons}
      isFormCompleted={isFormCompleted}
      isLoading={isLoading}
      nextUrl={nextUrl}
      onNextClick={handleClickSkip}
    />
  );
};

export default PageFooterShareMenu;
