import { BreadcrumbData } from 'use-react-router-breadcrumbs';

export const getDisplayedBreadcrumbs = (
  breadcrumbs: BreadcrumbData<string>[],
  hasHomeLink: boolean,
  restaurantId: string
): BreadcrumbData<string>[] => {
  let updatedBreadcrumbs: BreadcrumbData<string>[] = [...breadcrumbs];

  // Filter out the "Orders" breadcrumb for restaurants who don't have a "Home" page (aka not onboarding)
  if (restaurantId && !hasHomeLink) {
    updatedBreadcrumbs = updatedBreadcrumbs.filter((breadcrumb) => breadcrumb.match?.route?.breadcrumb !== 'Orders');
  // On company level, only show "Headquarters" on the Orders page
  } else if (!restaurantId && updatedBreadcrumbs[updatedBreadcrumbs.length - 1]?.match?.route?.breadcrumb === 'Orders') {
    updatedBreadcrumbs.pop();
  }

  return updatedBreadcrumbs;
};
