import styled from 'styled-components';
import { Button } from '@chownow/cn-web-components';
import { label } from 'styles/types';
import { space } from 'styles/variables';

const ActivateButton = styled(Button)`
  font: ${label.semi.x14};
  margin-top: ${space.x3};

  // Necessary to override svg path styles on the Button
  svg path {
    fill: url('#stripe-logo_svg__a');
  }
`;

export default ActivateButton;
