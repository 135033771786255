import React, { FC } from 'react';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';

import { LoadingContent, LoadingStateWrapper } from './styles';

interface LoadingStateProps {
  className?: string;
}

const LoadingState: FC<LoadingStateProps> = ({ className }) => (
  <LoadingStateWrapper className={className}>
    <LoadingContent>
      <LoadingSpinner isCentered size="x-large" />
    </LoadingContent>
  </LoadingStateWrapper>
);

export default LoadingState;
