import { format, isToday } from 'date-fns';

export const getFormattedDate = (date: string): string => {
  if (!date) return '';

  return isToday(new Date(date))
    ? `Today at ${format(new Date(date), 'h:mm a')}`
    : format(new Date(date), 'MMM do, yyyy h:mm a');
};

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';

  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d+)?(\d{3})(\d{3})(\d{4})$/);

  if (!match) return phoneNumber;

  return `${match[1] ? `+${match[1]} ` : ''}(${match[2]}) ${match[3]}-${
    match[4]
  }`;
};
