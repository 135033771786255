import React from 'react';
import { StatusDot } from '@chownow/cn-web-components';
import { formatDateTime } from 'helpers/dateTime';

import { OrderStatus } from '../../constants';
import { StyledIconBadgeCheck, StyledIconBadgeClose, StyledIconTime } from '../styles';

export const getStatusIcon = (status: string, isFutureOrder: boolean) => {
  if (status === OrderStatus.SUBMITTED) {
    if (isFutureOrder) {
      return <StyledIconTime />;
    } else {
      return <StatusDot size="medium" variant="success" />;
    }
  } else if (status === OrderStatus.ACCEPTED) {
    return <StyledIconBadgeCheck />;
  } else { // Canceled status
    return <StyledIconBadgeClose />;
  }
};

export const getStatusText = (status: string, isFutureOrder: boolean): string => {
  if (status === OrderStatus.SUBMITTED) {
    if (isFutureOrder) {
      return 'Future';
    } else {
      return 'New';
    }
  } else {
    return status;
  }
};

export const getLastAction = (
  grabbed_by: string,
  is_chownow_admin: boolean,
  is_super_admin: boolean,
  status: string,
  updated_at: string
) => {
  const isAutoAcceptGrabbedBy = grabbed_by === 'CN Auto Accept';
  const updateAt = formatDateTime(updated_at);

  // Strip out everything except user email
  let email = grabbed_by && grabbed_by.replace(/@[^@]+@[^@]*$/, '');

  // If the user is not a super user nor CN admin and the email has a ChowNow domain,
  // we want to hide the email and return 'ChowNow team' instead.
  const hasChowNowEmail = /^[A-Za-z0-9._%+-]+@chownow.com$/.test(email);

  if (!is_super_admin && !is_chownow_admin && hasChowNowEmail) {
    email = 'ChowNow team';
  }

  // If order status is canceled and there is no designated grabbed_by user,
  // that means that the order was automatically canceled due to inactivity.
  if (status === OrderStatus.CANCELED && !grabbed_by) {
    return 'Order was automatically canceled due to time limit';
  } else if (grabbed_by && updateAt) {
    return `${isAutoAcceptGrabbedBy ? 'ChowNow auto accepted' : email} at ${updateAt}`;
  } else {
    return '';
  }
};
