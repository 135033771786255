import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Restaurant from 'types/restaurant';

import OrderDetailsRefunds from './OrderDetailsRefunds';
import OrderDetailsOld from './OrderDetailsOld';
import OrdersLocation from './OrdersLocation';
import OrdersUnauthorized from './OrdersUnauthorized';
import OrderDetailsContainer from './OrderDetailsContainer';

interface OrdersProps {
  restaurant: Restaurant;
}

const Orders: FC<OrdersProps> = ({ restaurant }) => {
  const flags = useFlags();

  let orderDetailsComponent;

  if (flags.orderDetailsRedesign) {
    orderDetailsComponent = <OrderDetailsContainer restaurant={restaurant} />;
  } else if (flags.refundFeature) {
    orderDetailsComponent = <OrderDetailsRefunds restaurant={restaurant} />;
  } else {
    // OrderDetailsOld can be deprecated once we remove refund by amount users from the orderdetailsredesign flag
    orderDetailsComponent = <OrderDetailsOld restaurant={restaurant} />;
  }

  return (
    <Routes>
      <Route
        path="/details/:orderId/*"
        element={orderDetailsComponent}
      />
      <Route
        path="/unauthorized"
        element={<OrdersUnauthorized restaurant={restaurant} />}
      />
      <Route
        path="/*"
        element={<OrdersLocation restaurant={restaurant} />}
      />
    </Routes>
  );
};

export default Orders;
