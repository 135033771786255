import { createContext } from 'react';
import { UserType } from 'types/user';

export interface UserContextType {
  user: UserType;
  setUser: (user: UserType) => void;
}

export const UserContext = createContext<UserContextType>({
  user: {
    company_id: null,
    currentRestaurant: '',
    email: '',
    first_name: '',
    group_id: '',
    id: '',
    is_active: false,
    is_chownow_admin: false,
    is_region_admin: false,
    is_super_admin: false,
    last_name: '',
    phone: null,
    version: '',
    warnings: [],
  },
  setUser: () => ({}),
});
