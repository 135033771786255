import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';

import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

function TextModal(props) {
  const { hideModal } = useContext(ModalContext);
  const { dataTestId, title, message, children } = props;

  return (
    <div className={styles.textModal}>
      <IconClose className={styles.iconClose} onClick={() => hideModal()} />
      {title && <h1 className={styles.title}>{title}</h1>}
      {message && (
        <p className={styles.message} data-testid={dataTestId}>
          {message}
        </p>
      )}
      {children}
    </div>
  );
}

TextModal.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string
};

export default TextModal;
