import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetIntegrations } from 'api/posIntegrations/integrations';
import {
  isIntegrationsListResponse,
  isIntegrationsSuccessResponse,
} from 'types/pos/integrations';
import ContentContainer from 'components/ContentContainer';
import { Cover, DashboardGrid } from '@chownow/cn-web-components';
import CardStatus from 'components/CardStatus';
import { ReactComponent as IconTablet } from 'images/icon-tablet-24.svg';

import {
  ListWrapper,
  NoIntegrationsMessage,
  NoIntegrationsWrapper,
  StyledGrid,
} from './styles';
import { getIntegrationStatus, getIntegrationStatusText } from './helpers';
import ErrorState from '../../ErrorState';
import LoadingState from '../../LoadingState';

enum VariantByStatus {
  ACTIVE = 'success',
  AVAILABLE = 'active',
  ERRORS = 'caution',
  PAUSED = 'neutral',
}

interface IntegrationsListProps {
  restaurantId: string;
}

const IntegrationsList: FC<IntegrationsListProps> = ({
  restaurantId,
}) => {
  const {
    hasGetIntegrationsRequestError,
    isGetIntegrationsRequestLoading,
    getIntegrationsRequestResponse,
  } = useGetIntegrations(restaurantId);

  const isListResponse = isIntegrationsListResponse(
    getIntegrationsRequestResponse
  );

  let content = isIntegrationsSuccessResponse(
    getIntegrationsRequestResponse
  ) && (
    <DashboardGrid>
      <StyledGrid md={12}>
        {isListResponse ? (
          <ListWrapper>
            {getIntegrationsRequestResponse.map(item => {
              const status = getIntegrationStatus(item);

              return (
                <CardStatus
                  key={item.name}
                  linkUrl={`/restaurant/${restaurantId}/settings/integrations/${item?.name}`}
                  subtext={getIntegrationStatusText(status, item.name)}
                  status={status}
                  statusDotVariant={VariantByStatus[status]}
                  title={item?.name}
                />
              );
            })}
          </ListWrapper>
        ) : (
          <NoIntegrationsWrapper>
            <IconTablet />
            <NoIntegrationsMessage>
              No integrations available. Please contact your Restaurant Success
              Manager if you would like to enable one.
            </NoIntegrationsMessage>
          </NoIntegrationsWrapper>
        )}
      </StyledGrid>
    </DashboardGrid>
  );

  if (hasGetIntegrationsRequestError) {
    content = (
      <DashboardGrid>
        <StyledGrid md={12}>
          <ErrorState />
        </StyledGrid>
      </DashboardGrid>
    );
  }

  return (
    <>
      <Helmet title="Integrations" />
      <Cover title="Integrations" />
      <ContentContainer>
        {isGetIntegrationsRequestLoading ? <LoadingState /> : content}
      </ContentContainer>
    </>
  );
};

export default IntegrationsList;
