import {
  ModifierCategory,
  SelectedNestedModifier,
  OrderDetailsV6GetSuccessResponse,
} from 'types/order-details';

export interface RefundFee {
  name: string;
  amount: number;
  refund: number;
}

export interface NestedRefundInterface {
  id: string;
  refund_quantity: number;
  price: number | null;
  refund_modifiers: NestedRefundInterface[];
}

export interface RefundItemInterface {
  id: string;
  refund_quantity: number;
  price: number;
  refund_modifiers: NestedRefundInterface[];
}

export interface NestedRefundPayload {
  id: string;
  refund_quantity: number;
  refund_modifiers?: NestedRefundPayload[];
}

export const getModifierSelections = (categories: ModifierCategory[]) => {
  // convert selections to modifiers
  const selectionArray: SelectedNestedModifier[] = [];

  categories.forEach(category => {
    category.selections?.forEach(selection => selectionArray.push(selection));
  });

  return selectionArray;
};

export const getRefundTotal = (refundedItems: NestedRefundInterface[]) => {
  let total = 0;
  refundedItems.forEach(item => {
    total += (item.price ?? 0) * item.refund_quantity;
    if (item.refund_modifiers) {
      total += getRefundTotal(item.refund_modifiers);
    }
  });
  return total;
};

export const getRevisedTotal = (
  orderDetails: OrderDetailsV6GetSuccessResponse,
  refundedItems: RefundItemInterface[]
) => {
  /* calculate revised total by subtracting each item/modifier at
       its selected price*quantity from the subtotal */
  let total = orderDetails.subtotal ?? 0;
  refundedItems.forEach(item => {
    total -= item.price * item.refund_quantity;
    if (item.refund_modifiers) {
      total -= getRefundTotal(item.refund_modifiers);
    }
  });
  return total;
};

export const validateItems = (refundedItems: RefundItemInterface[]) => {
  // disable refund button if no items selected
  const hasRefundedItem = refundedItems.find(
    item => item.refund_quantity || getRefundTotal(item.refund_modifiers) > 0
  );

  return hasRefundedItem;
};

export const flattenRefundedModifiers = (
  item: NestedRefundPayload,
  refundedModifiers: NestedRefundPayload[] = []
) => {
  if (!item.refund_modifiers && refundedModifiers.length > 0) {
    item.refund_modifiers = [];
  }
  refundedModifiers.forEach(modifier => {
    item.refund_modifiers?.push({
      id: modifier.id,
      refund_quantity: modifier.refund_quantity,
    });
    if (modifier.refund_modifiers && modifier.refund_modifiers.length > 0) {
      flattenRefundedModifiers(item, modifier.refund_modifiers);
    }
  });
};

export const convertToPayloadStructure = (
  refundedItems: RefundItemInterface[]
) => {
  const payload: NestedRefundPayload[] = [];
  refundedItems.forEach(item => {
    const nestedItem: NestedRefundPayload = {
      id: item.id,
      refund_quantity: item.refund_quantity,
      refund_modifiers: [],
    };
    if (item.refund_modifiers && item.refund_modifiers.length > 0) {
      flattenRefundedModifiers(nestedItem, item.refund_modifiers);
    }
    payload.push(nestedItem);
  });
  return payload;
};
