import React, { FC, useContext, useState } from 'react';

import { Button } from '@chownow/cn-web-components';

import MenuInput from 'containers/Onboarding/ShareMenu/MenuInput';
import FileUpload from 'containers/Onboarding/ShareMenu/FileUpload';

import { MenuDataContext } from 'context/MenuDataContext';

import { AddMenuContainer, ButtonRow, Or } from './styles';

interface AddMenuProps {
  setFileName: (name: string) => void;
  fileName: string;
  setModalErrorMessage: (message: string) => void;
  setHasFileError: (hasError: boolean) => void;
}

const AddMenu: FC<AddMenuProps> = ({
  setFileName,
  fileName,
  setModalErrorMessage,
  setHasFileError,
}) => {
  const [isEnterURL, setIsEnterURL] = useState<boolean>(false);
  const [isUploadFile, setIsUploadFile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setMenuUpload } = useContext(MenuDataContext);

  const handleEnterURLClick = () => {
    setModalErrorMessage('');
    setErrorMessage('');
    setIsUploadFile(false);
    setIsEnterURL(true);
  };

  const handleUploadFile = (file: File) => {
    setFileName(file.name);
    setIsUploadFile(true);
    setIsEnterURL(false);
    setMenuUpload(file.name ?? '');
  };

  const handleUploadError = (message: string) => {
    setErrorMessage(message);
    setHasFileError(true);
  };

  const handleCancel = () => {
    if (isUploadFile) setMenuUpload('');
    setIsEnterURL(false);
    setIsUploadFile(false);
    setErrorMessage('');
    setHasFileError(false);
  };

  return (
    <AddMenuContainer>
      {!isEnterURL && !isUploadFile && (
        <ButtonRow>
          <Button
            label="Enter URL"
            platform="brand"
            size="small"
            variant="outline"
            onClick={handleEnterURLClick}
          />
          <Or>Or</Or>
          <FileUpload
            onClickUploadFile={handleUploadFile}
            onUploadError={handleUploadError}
          />
        </ButtonRow>
      )}
      {isEnterURL && <MenuInput onClickCancel={handleCancel} />}
      {isUploadFile && (
        <MenuInput
          fileName={fileName}
          onClickCancel={handleCancel}
          isDisabled
          errorMessage={errorMessage}
        />
      )}
    </AddMenuContainer>
  );
};

export default AddMenu;
