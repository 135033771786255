import React, { FC } from 'react';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import { RefundItemInterface } from '../util';
import RefundItem from '../RefundItem';
import RefundSubItem from '../RefundSubItem';
import { renderSubItems } from './helpers';

interface RefundItemsProps {
  order: OrderDetailsV6GetSuccessResponse;
  refundedItems: RefundItemInterface[];
  handleRefundAll: ((qty: number, id: string) => void) | undefined;
  handleRefundUpdate: (
    qty: number,
    itemId: string,
    modifierId?: string | undefined
  ) => void;
  isReadOnly?: boolean;
  isOpen?: boolean;
}

const RefundItems: FC<RefundItemsProps> = ({
  order,
  refundedItems,
  handleRefundAll,
  handleRefundUpdate,
  isReadOnly = false,
  isOpen = false,
}) => (
  <div>
    {order.items?.map(item => (
      <RefundItem key={`${item.id}item`} item={item} isOpen={isOpen}>
        <RefundSubItem
          key={`${item.id}main`}
          item={item}
          refundedItems={refundedItems}
          handleRefundAll={handleRefundAll}
          handleRefundUpdate={handleRefundUpdate}
          indentLevel={0}
          isReadOnly={isReadOnly}
        />
        {!!item.modifier_categories.length && (
          <>
            <RefundSubItem
              key={`${item.id}sub`}
              item={item}
              refundedItems={refundedItems}
              handleRefundUpdate={handleRefundUpdate}
              indentLevel={0}
              isReadOnly={isReadOnly}
            />
            {renderSubItems(
              item,
              item.modifier_categories,
              1,
              refundedItems,
              handleRefundUpdate,
              isReadOnly
            )}
          </>
        )}
      </RefundItem>
    ))}
  </div>
);

export default RefundItems;
