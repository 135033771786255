/* eslint-disable no-confusing-arrow */

import styled from 'styled-components';
import { heights, space } from 'styles/variables';

export const Container = styled.div<{ $isOldAdminBannerDisplayed?: boolean }>`
  box-sizing: border-box;
  height: ${({ $isOldAdminBannerDisplayed }) =>
    $isOldAdminBannerDisplayed
      ? `calc(100% - ${heights.navBarHeight})`
      : '100%'};
  left: 0;
  padding: 132px 0 ${space.x6};
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  margin: 0;
  padding-left: ${space.x4};
`;

export const StyledIFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
