import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { screen, space, zIndex } from 'styles/variables';
import { label, paragraph } from 'styles/types';
import { Modal } from '@chownow/cn-web-components';

export const StyledModal = styled(Modal)`
  &&& {
    background-color: ${semanticColors.surface.primary};
    position: fixed;

    @media (max-width: ${screen.xsMax}) {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: unset;
    }
  }

  /* Cover component has a z-index of 1 which is making the "Accept or Cancel" button show ontop of the Modal */
  z-index: ${zIndex.modalBase};
`;

export const Title = styled.h1`
  color: ${semanticColors.content.primary};
  font: ${label.semi.x20};
`;

export const Subtitle = styled.h2`
  color: ${semanticColors.content.primary};
  font: ${paragraph.reg.x14};
  margin: ${space.x5} 0 ${space.x2};
`;
