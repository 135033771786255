import React, { FC, useState } from 'react';
import { Button, Checkbox } from '@chownow/cn-web-components';

import { useRestaurantHoursContext } from '../RestaurantHoursContext/RestaurantHoursContextProvider';
import { HoursSection } from '../constants';
import HOURS from './constants';
import {
  ClosedBox,
  ClosedText,
  CopyToAllContainer,
  Day,
  DayCheckboxWrapper,
  HoursRowWRapper,
  SelectContainer,
  StyledSelectStandard,
  To,
} from './styles';
import { CheckboxWrapper } from '../styles';

interface HoursRowProps {
  day: string;
  hoursSection: HoursSection;
}

const HoursRow: FC<HoursRowProps> = ({ day, hoursSection }) => {
  const {
    checkDayCopyable,
    copyDayToAll,
    getDayEndTime,
    getDayStartTime,
    getDayIsCopyable,
    setDayOpen,
    setDayClosed,
    setDayEndTime,
    setDayStartTime,
  } = useRestaurantHoursContext();
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const handleCheckboxOnChange = () => {
    if (isClosed) {
      setDayOpen(day, hoursSection);
    } else {
      setDayClosed(day, hoursSection);
    }

    checkDayCopyable(day, hoursSection);
    setIsClosed(prevIsClosed => !prevIsClosed);
  };

  const handleStartTimeSelectOnChange = (selectedStartTime: string) => {
    setDayStartTime(day, hoursSection, selectedStartTime);
    checkDayCopyable(day, hoursSection);
  };

  const handleEndTimeSelectOnChange = (selectedEndTime: string) => {
    setDayEndTime(day, hoursSection, selectedEndTime);
    checkDayCopyable(day, hoursSection);
  };

  const startTime = getDayStartTime(day, hoursSection);
  const endTime = getDayEndTime(day, hoursSection);

  return (
    <HoursRowWRapper>
      <DayCheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={!isClosed}
            onChange={handleCheckboxOnChange}
            value={day}
          />
        </CheckboxWrapper>
        <Day>{day}</Day>
      </DayCheckboxWrapper>
      <SelectContainer>
        {isClosed && (
          <ClosedBox>
            <ClosedText>Closed</ClosedText>
          </ClosedBox>
        )}
        {!isClosed && (
          <>
            <StyledSelectStandard
              onChange={handleStartTimeSelectOnChange}
              placeholder="select"
              value={startTime}
            >
              {HOURS.map(hour => (
                <option key={`open-${hour}`}>{hour}</option>
              ))}
            </StyledSelectStandard>
            <To>to</To>
            <StyledSelectStandard
              disabled={!startTime}
              onChange={handleEndTimeSelectOnChange}
              placeholder="select"
              value={endTime}
            >
              {HOURS.map(hour => (
                <option key={`closed-${hour}`}>{hour}</option>
              ))}
            </StyledSelectStandard>
          </>
        )}
        {getDayIsCopyable(day, hoursSection) && (
          <CopyToAllContainer>
            <Button
              variant="link"
              onClick={() => copyDayToAll(day, hoursSection)}
              label="Copy to All"
              size="small"
            />
          </CopyToAllContainer>
        )}
      </SelectContainer>
    </HoursRowWRapper>
  );
};

export default HoursRow;
