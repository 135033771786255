import React, { FC } from 'react';

import { useRestaurantHoursContext } from '../RestaurantHoursContext/RestaurantHoursContextProvider';
import { OrderTypeCardWrapper, RadioGroup, StyledRadioButton } from './styles';

const OrderTypeCard: FC = () => {
  const {
    isDeliveryEnabled,
    isPickupEnabled,
    resetAllHours,
    setIsDeliveryEnabled,
    setIsPickupEnabled,
    setIsSameHours,
  } = useRestaurantHoursContext();

  const handleOrderTypeClick = (isDeliverySelected: boolean) => {
    resetAllHours();
    setIsDeliveryEnabled(isDeliverySelected);
    setIsPickupEnabled(true);
    setIsSameHours(false);
  };

  return (
    <OrderTypeCardWrapper
      $hasSelectedOrderType={isDeliveryEnabled || isPickupEnabled}
    >
      <h2>What type of orders do you take? Select one option below:</h2>
      <RadioGroup
        role="radiogroup"
        aria-label="order type button group"
      >
        <StyledRadioButton
          checked={isDeliveryEnabled && isPickupEnabled}
          label="Pickup & Delivery"
          name="restaurant-hours-radio-button"
          onChange={() => handleOrderTypeClick(true)}
          value="pickupDelivery"
        />
        <StyledRadioButton
          checked={!isDeliveryEnabled && isPickupEnabled}
          label="Pickup Only"
          name="restaurant-hours-radio-button"
          onChange={() => handleOrderTypeClick(false)}
          value="pickupOnly"
        />
      </RadioGroup>
    </OrderTypeCardWrapper>
  );
};

export default OrderTypeCard;
