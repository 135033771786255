import { request } from 'helpers/api';

export const useLogout = () => {
  const logout = async () => {
    try {
      await request(
        'admin/logout',
        {},
        'POST'
      );
      return { hasLogoutError: false };
    } catch (err) {
      const logoutErrorMessage = 'Sorry, an error has occurred. Please try again.';
      return { hasLogoutError: true, logoutErrorMessage };
    }
  };

  return {
    logout,
  };
};
