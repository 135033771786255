import React, { FC } from 'react';
import { getSearchParam } from 'helpers/url';
import Restaurant from 'types/restaurant';
import { useGetStripeVerificationUrl } from 'api/stripe';
import ErrorCard from 'containers/Onboarding/ErrorCard';

import StripeButton from '../StripeButton';
import {
  ActivationErrorCardContainer,
  ButtonContainer,
  Reminders,
} from './styles';

interface ActivationErrorCardProps {
  restaurant: Restaurant;
  urlPath: string;
}

const ActivationErrorCard: FC<ActivationErrorCardProps> = ({
  restaurant,
  urlPath,
}) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const {
    hasGetStripeVerificationUrlRequestError,
    isGetStripeVerificationUrlRequestLoading,
    getStripeVerificationUrl,
  } = useGetStripeVerificationUrl();

  const handleActivateClick = () => {
    const navigateToStripeVerificationUrl = async () => {
      const response = await getStripeVerificationUrl(restaurant, urlPath);

      if (response?.url) {
        window.location.href = `${response.url}${
          isComingFromHomepage ? '&origin=homepage' : ''
        }`;
      }
    };

    void navigateToStripeVerificationUrl();
  };

  if (hasGetStripeVerificationUrlRequestError) return <ErrorCard />;

  return (
    <ActivationErrorCardContainer>
      <ButtonContainer>
        <h2>Activate your account</h2>
        <StripeButton
          isLoading={isGetStripeVerificationUrlRequestLoading}
          onClick={handleActivateClick}
        />
      </ButtonContainer>
      <Reminders>
        <h2>Common issues:</h2>
        <ul>
          <li>Spelling Errors</li>
          <li>Stripe needs your full social security number</li>
          <li>
            Issues with ID verification or the quality of driver&apos;s license
            upload
          </li>
          <li>Incorrect EIN/Federal Tax ID</li>
          <li>Incorrect banking information (account and/or routing)</li>
        </ul>
      </Reminders>
    </ActivationErrorCardContainer>
  );
};

export default ActivationErrorCard;
