import { numericStringRegex } from 'helpers/validators';
import { JOSIAHS_BAD_PASSWORDS } from 'helpers/constants';

export const passwordValidator = (password: string): string => {
  if (numericStringRegex.test(password)) {
    return 'You need at least some letters in your password.';
  }

  if (password.length < 8) {
    return 'Password must be at least 8 characters and contain one number.';
  }

  if (
    (password.toUpperCase() === password ||
      password.toLowerCase() === password) &&
    !password.match(/\d/)
  ) {
    return 'Please choose a less common password.';
  }

  if (JOSIAHS_BAD_PASSWORDS.includes(password)) {
    return 'Please choose a less common password.';
  }

  return '';
};
