import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, screen, space } from 'styles/variables';
import Input from 'components/Input';

export const InputCard = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
  padding: ${space.x3};

  @media (min-width: ${screen.smMin}) {
    padding: ${space.x5};
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;
