import React, { FC } from 'react';
import { Routes, Route } from 'react-router';
import Restaurant from 'types/restaurant';

import OrderDetails from './OrderDetails';
import CustomerInfo from './CustomerInfo';

interface OrderDetailsContainerProps {
  restaurant: Restaurant;
}

const OrderDetailsContainer: FC<OrderDetailsContainerProps> = ({ restaurant }) => (
  <Routes>
    <Route
      path="/*"
      element={<OrderDetails restaurant={restaurant} />}
    />
    <Route
      path="/customer/:customerId"
      element={<CustomerInfo />}
    />
  </Routes>
);

export default OrderDetailsContainer;
