import { RefundStatus } from 'types/order-details';

export const FEE_MESSAGES: { [key: string]: string } = {
  'Service Fee':
    'This restaurant adds this fee to cover costs and continue serving your community. 100% goes to the restaurant.',
  Gratuity:
    'This restaurant adds this fee to cover costs and continue serving your community. 100% goes to the restaurant.',
  'Support Local Fee':
    "This fee goes to ChowNow and keeps us 100% commission-free for restaurants. That means they don't have to raise their menu prices, so you save money on your orders. It's good for you, restaurants, and us.",
};

export const REFUND_TYPES: { [key: string]: string } = {
  full: 'Full Refund',
  partial_by_amount: 'Refund by Amount',
  partial_by_item: 'Refund by Item',
};

export const REFUND_STATUS_MESSAGES: { [key: string]: string } = {
  [RefundStatus.AUTOMATICALLY_PROCESSED]: 'Refunded',
  [RefundStatus.PENDING_AUTOMATIC_PROCESSING]: 'Refund Processing',
  [RefundStatus.PENDING_CUSTOMER_SUPPORT]: 'Pending Customer Support',
  [RefundStatus.PENDING_RESTAURANT_FEEDBACK]: 'Refund Processing',
  [RefundStatus.PROCESSED_MANUALLY_BY_CS]: 'Refunded',
};
