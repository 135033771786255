import React from 'react';
import { Checkbox } from '@chownow/cn-web-components';

import HoursRow from '../HoursRow';
import { DaysOfTheWeek, HoursSection } from '../constants';
import { useRestaurantHoursContext } from '../RestaurantHoursContext/RestaurantHoursContextProvider';
import { SameHours } from './styles';
import { Divider, CheckboxWrapper, Heading3 } from '../styles';

const PickupDeliveryHours = () => {
  const { isSameHours, resetAllHours, setIsSameHours } = useRestaurantHoursContext();

  const handleSameHoursOnChange = () => {
    resetAllHours();
    setIsSameHours(prevValue => !prevValue);
  };

  return (
    <div data-testid="pickup-delivery-hours">
      <SameHours>
        <CheckboxWrapper>
          <Checkbox
            checked={isSameHours}
            onChange={handleSameHoursOnChange}
            value=""
          />
        </CheckboxWrapper>
        <span>Pickup and delivery hours are the same</span>
      </SameHours>
      <Divider />
      {isSameHours ? (
        <>
          {Object.keys(DaysOfTheWeek).map((day) => <HoursRow day={day} key={`pickup-delivery-${day}`} hoursSection={HoursSection.PickupDelivery} />)}
        </>
      ) : (
        <>
          <Heading3>Pickup Hours</Heading3>
          {Object.keys(DaysOfTheWeek).map((day) => <HoursRow day={day} key={`pickup-${day}`} hoursSection={HoursSection.Pickup} />)}
          <Divider />
          <Heading3>Delivery Hours</Heading3>
          {Object.keys(DaysOfTheWeek).map((day) => <HoursRow day={day} key={`delivery-${day}`} hoursSection={HoursSection.Delivery} />)}
        </>
      )}
    </div>
  );
};

export default PickupDeliveryHours;
