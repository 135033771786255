import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { format } from 'date-fns';
import classNames from 'classnames';

import { menuItemType } from 'helpers/prop-types';
import { ITEM_TYPE } from 'helpers/constants';
import { CatalogContext } from 'context/CatalogContext';

import SquareItem from 'components/SquareItem';
import LoadingSpinner from 'components/LoadingSpinner';

import { ReactComponent as IconRefresh } from 'images/icon-refresh.svg';
import { ReactComponent as SquareLogo } from 'images/logos/square-logo.svg';
import { ReactComponent as IconWarning } from 'images/icon-warning.svg';

import styles from './styles.module.scss';

export default function SquareList(props) {
  const {
    isDragMode,
    items,
    itemType,
    lastSyncDate,
    linkItem,
    onRefresh,
    toggleDragMode,
    isLoading,
    CNItem,
    scrollParent,
  } = props;
  const [listScrollTop, setListScrollTop] = useState(0);
  const catalog = useContext(CatalogContext);
  const linkedCount = getLinkedCount();

  function handleOnScroll(event) {
    setListScrollTop(event.target.scrollTop);
  }

  function getLinkedCount() {
    let squareCount;

    if (itemType === ITEM_TYPE.modifier) {
      squareCount = items.filter(sqItem => {
        return find(CNItem.modifiers, { x_id: sqItem.id });
      }).length;
    } else if (itemType === ITEM_TYPE.size) {
      squareCount = items.filter(sqItem => {
        return find(CNItem.serving_sizes, size => {
          if (size.x_id) {
            return size.x_id.includes(sqItem.id);
          }
        });
      }).length;
    } else {
      squareCount = catalog.CNItems.filter(item => item.x_id).length;
    }
    // Linked count must be derived from total Square Items - CN linked items
    return Math.max(0, items.length - squareCount);
  }

  function getLabel() {
    let label;

    if (itemType === ITEM_TYPE.modifier) {
      label = ITEM_TYPE.modifier;
    } else if (itemType === ITEM_TYPE.size) {
      label = ITEM_TYPE.size;
    } else {
      label = ITEM_TYPE.menuItem;
    }
    return label;
  }

  return (
    <>
      <div className={styles.squareCatalog}>
        <div className={styles.container}>
          <>
            <div className={styles.heading}>
              <SquareLogo className={styles.logo} />
              <h5 className={styles.label}>{`Unlinked ${getLabel()}s`}</h5>
              <div className={styles.itemCount}>{`${linkedCount} Item${
                linkedCount === 1 ? '' : 's'
              }`}</div>
              {onRefresh && (
                <button
                  className={classNames(styles.refreshBtn, {
                    [styles.isDragMode]: isDragMode,
                  })}
                  onClick={onRefresh}
                >
                  Refresh <IconRefresh className={styles.iconRefresh} />
                </button>
              )}
            </div>
            {onRefresh && (
              <div className={styles.syncDate}>
                {`Last Updated: `}
                {lastSyncDate
                  ? format(new Date(lastSyncDate), 'MMM do, yyyy')
                  : format(new Date(), 'MMM do, yyyy')}
              </div>
            )}
          </>

          {isLoading ? (
            <div className={styles.spinnerWrapper}>
              <LoadingSpinner isAbsolute={false} withWrapper={false} />
            </div>
          ) : getLinkedCount() === 0 ? (
            <div className={styles.emptyList}>
              <IconWarning className={styles.iconWarning} />
              <div>No Items to Display</div>
            </div>
          ) : (
            <ul
              className={classNames(styles.itemList, {
                [styles.noScroll]: isDragMode,
              })}
              onScroll={handleOnScroll}
            >
              {items.map(item => (
                <SquareItem
                  itemType={itemType}
                  listScrollTop={listScrollTop}
                  linkItem={linkItem}
                  itemId={item.id}
                  key={item.id}
                  item={item}
                  toggleDragMode={toggleDragMode}
                  CNItem={CNItem}
                  scrollParent={scrollParent}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

SquareList.propTypes = {
  CNItem: menuItemType,
  isDragMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  itemType: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  lastSyncDate: PropTypes.string,
  linkItem: PropTypes.func,
  onRefresh: PropTypes.func,
  scrollParent: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  toggleDragMode: PropTypes.func,
};
