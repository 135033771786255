import React from 'react';
import { ReactComponent as IconBell } from 'images/icon-bell.svg';

import styles from './styles.module.scss';

const OldAdminBanner = () => (
  <div className={styles.oldAdminBanner}>
    <IconBell className={styles.iconBell} />
    {`Welcome to our refreshed web experience. `}
    <b>
      <a href="https://www.surveymonkey.com/r/N7B7MVQ" target="_blank">
        Tell us
      </a>
    </b>
    {` what you think! `}
    <i>
      {`(Looking for the `}
      <b>
        <a
          href={process.env.REACT_APP_ADMIN_PYTHON_APP_URL}
        >{`old version?)`}</a>
      </b>
    </i>
  </div>
);

export default OldAdminBanner;
