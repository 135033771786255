import React, { FC } from 'react';

import { handleKeyDown } from 'helpers/accessibility';

import { AddMenuCardContainer, BadgePlusIcon } from './styles';

interface AddMenuCardProps {
  onAddMenuClick: () => void;
}

const AddMenuCard: FC<AddMenuCardProps> = ({ onAddMenuClick }) => (
  <AddMenuCardContainer
    onClick={onAddMenuClick}
    onKeyDown={e => handleKeyDown(e, onAddMenuClick)}
    tabIndex={0}
    role="button"
    aria-label="click to add menu"
  >
    <BadgePlusIcon />
    <p>Add menu</p>
  </AddMenuCardContainer>
);

export default AddMenuCard;
