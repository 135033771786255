import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { useSpring, animated } from '@react-spring/web';

import { menuCategoryType } from 'helpers/prop-types';

import styles from './styles.module.scss';

function CategoryHeader(props) {
  const { activeCategory, categories, prevCategory } = props;
  const reset = prevCategory !== activeCategory;
  const prevIndex = findIndex(categories, { name: prevCategory });
  const activeIndex = findIndex(categories, { name: activeCategory });
  const [springProps, setSpringProps] = useSpring(() => ({
    config: { duration: 150 },
    opacity: 1,
    immediate: true
  }));
  useEffect(() => {
    setSpringProps({
      config: { duration: 150 },
      from: {
        opacity: 0,
        transform: `translate3d(0,${
          prevIndex < activeIndex ? '100%' : '-100%'
        },0)`
      },
      opacity: 1,
      reset,
      immediate: activeCategory === 'Categories',
      transform: 'translate3d(0,0,0)'
    });
  }, [activeCategory]);

  return (
    <animated.div style={springProps} className={styles.categoryHeaderWrap}>
      <span className={styles.activeCategory} data-testid="SelectedCategory">
        {activeCategory}
      </span>
    </animated.div>
  );
}

export default CategoryHeader;

CategoryHeader.propTypes = {
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(menuCategoryType),
  prevCategory: PropTypes.string
};
