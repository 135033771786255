export const getLastPath = (path: string) => {
  const noQueryParamPath = path.split('?')[0];

  if (noQueryParamPath.lastIndexOf('/') === -1) {
    return '';
  } else {
    return noQueryParamPath.substring(noQueryParamPath.lastIndexOf('/') + 1);
  }
};

export const getAppOrderDirectDomain = () => {
  let domain;

  if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
    domain = 'web.dev.chownow.com:3000/order';
  } else if (process.env.REACT_APP_DEPLOY_ENV === 'prod') {
    domain = 'direct.chownow.com/order';
  } else {
    domain = `app-order-direct.${process.env.REACT_APP_DEPLOY_ENV}.svpn.chownow.com/order`;
  }

  return `https://${domain}`;
};

export const getUrlParam = (param: string) => {
  const currentURL = window.location.href;
  const regex = new RegExp(`\\/${param}\\/(\\d+)`);
  const urlParam = regex.exec(currentURL);

  return urlParam && urlParam[1];
};

export const getSearchParam = (param: string) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const searchParam = searchParams.get(`${param}`);

  return searchParam && searchParam;
};
