import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';

import breakpoints from 'helpers/breakpoints';
import { companyType } from 'helpers/prop-types';
import { getNameFromLocations } from 'helpers/restaurant';
import { getAppOrderDirectDomain, getUrlParam } from 'helpers/url';
import { UserContext } from 'context/UserContext';
import { ModalContext } from 'context/ModalContext';

import { Button } from '@chownow/cn-web-components';
import LocationPickerModal from 'components/Modals/LocationPickerModal';
import Sidebar from 'components/Sidebar';
import Popover from 'components/Popover';
import OldAdminBanner from './OldAdminBanner';
import Breadcrumbs from './Breadcrumbs';
import PopoverContent from './PopoverContent';
import StatusContainer from './StatusContainer';

import { ReactComponent as IconHamburger } from 'images/icon-hamburger.svg';
import { IconMore } from 'images/IconMore';

import styles from './styles.module.scss';
import LayoutWizard from 'components/LayoutWizard';

const Layout = ({ children, company, restaurant, isOnboarding }) => {
  const flags = useFlags();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });
  const isTablet = useMediaQuery({ maxWidth: breakpoints.smMin });
  const restaurantId = getUrlParam('restaurant');
  const restaurantName = getNameFromLocations(restaurantId, company);
  const [restaurantData, setRestaurantData] = useState({});
  const { showModal, isModalOpen } = useContext(ModalContext);
  const { hideModal } = useContext(ModalContext);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isTabletOn = restaurantId
    ? restaurantData?.tablet?.seen_recently
    : false;
  const tabletVersion = restaurantData?.tablet?.version ?? 'unknown';
  const tabletLastSeen = restaurantData?.tablet?.last_seen ?? 'unknown';
  const isAutoAcceptEnabled =
    restaurantData?.order_auto_accept_configuration?.is_enabled ?? 'unknown';
  const hasPos =
    restaurantData?.pos?.name &&
    restaurantData?.pos?.is_enabled &&
    restaurantData?.pos?.is_authorized &&
    restaurantData?.pos?.is_active;
  const middlewareOrPosName = restaurantId
    ? [
        restaurantData?.middleware_integrator,
        hasPos && restaurantData?.pos?.name,
      ].filter(Boolean)
    : null;

  useEffect(() => {
    if (restaurantId && restaurant) {
      setRestaurantData(restaurant);
    } else {
      setRestaurantData(null);
    }
  }, [restaurant, restaurantId]);

  const {
    user: { first_name, is_chownow_admin, is_super_admin },
  } = useContext(UserContext);

  useEffect(() => {
    const sidebarElement = document.getElementById('sidebar');

    if (isSideMenuOpen && !isModalOpen) {
      disableBodyScroll(sidebarElement);
    } else {
      enableBodyScroll(sidebarElement);
    }
  }, [isSideMenuOpen, isModalOpen]);

  useEffect(() => {
    setIsSideMenuOpen(false);
  }, [isDesktop]);

  const toggleSideMenu = () => {
    if (!isDesktop) {
      setIsSideMenuOpen(!isSideMenuOpen);
    }
  };

  const handleOnClick = id => {
    if (!id) {
      navigate(`/company/${company.id}`);
    } else if (!restaurantId) {
      navigate(`/restaurant/${id}`);
    } else {
      const locationUrl = location.pathname + location.search;

      navigate(
        locationUrl.replace(`restaurant/${restaurantId}`, `restaurant/${id}`)
      );
    }

    window.scroll(0, 1);

    hideModal();

    if (isSideMenuOpen) toggleSideMenu();
  };

  const handlePickerClick = () => {
    showModal(LocationPickerModal, {
      mobileFullView: true,
      showCloseIcon: true,
      className: styles.locationPickerModal,
      modalProps: {
        locations: company.locations,
        companyId: company.id,
        restaurantId: restaurantId,
        title: 'Change Location',
        handleOnClick: handleOnClick,
        showAllLocations: true,
      },
    });
  };

  return (
    <>
      {isOnboarding && <LayoutWizard uuid={restaurant?.sfdc_restaurant_uuid} />}
      {!flags.a2DOldAdminBanner && <OldAdminBanner />}
      {!isDesktop && (
        <header className={styles.header}>
          <div className={styles.restaurantName}>
            {restaurantName || 'Headquarters'}
          </div>
          <div className={styles.headerRight}>
            <IconHamburger
              className={classNames(styles.logo, styles.hamburgerIcon)}
              onClick={toggleSideMenu}
            />
          </div>
        </header>
      )}
      <div className={styles.navigation}>
        <div className={styles.companyDetails}>
          <Breadcrumbs
            restaurantName={restaurantName}
            restaurantId={restaurantId}
            hasHomeLink={
              restaurant?.is_onboarding && restaurant?.sfdc_restaurant_uuid
            }
          />
        </div>
        <div className={styles.headerRight}>
          {flags.a2DGlobalInfoAndTabletIndicators ? (
            <>
              {restaurantId && (
                <StatusContainer
                  isAutoAcceptEnabled={isAutoAcceptEnabled}
                  isTablet={isTablet}
                  isTabletOn={isTabletOn}
                  middlewareOrPosName={middlewareOrPosName}
                  tabletLastSeen={tabletLastSeen}
                  tabletVersion={tabletVersion}
                />
              )}
              <Popover
                ariaLabel="Click to view more informational links"
                icon={<IconMore />}
                type="moreInfo"
              >
                <PopoverContent
                  companyId={company.id}
                  restaurantId={restaurantId}
                />
              </Popover>
            </>
          ) : (
            restaurantId && (
              <Button
                className={styles.viewMenu}
                variant="link"
                label="Preview Menu"
                onClick={() =>
                  (window.location.href = `${getAppOrderDirectDomain()}/${
                    company.id
                  }/locations/${restaurantId}?menu_status=preview`)
                }
              />
            )
          )}
        </div>
      </div>
      <main className={styles.content}>
        <Sidebar
          company={company}
          firstName={first_name}
          isAdmin={is_super_admin || is_chownow_admin}
          isOpen={isSideMenuOpen}
          handlePickerClick={handlePickerClick}
          onRequestClose={toggleSideMenu}
          showAllLocations
        />
        <div className={styles.container}>{children}</div>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  company: companyType,
};

export default Layout;
