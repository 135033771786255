import React, { FC, ReactNode } from 'react';
import Footer from 'components/Footer';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentContainerWrapper } from './styles';

interface ContentContainerProps {
  children: ReactNode;
  className?: string;
}

const ContentContainer: FC<ContentContainerProps> = ({ children, className }) => {
  const flags = useFlags();

  return (
    <ContentContainerWrapper
      className={className}
      $isOldAdminBannerDisplayed={!flags.a2DOldAdminBanner}
    >
      {children}
      <Footer />
    </ContentContainerWrapper>
  );
};

export default ContentContainer;
