import React, { FC } from 'react';
import { ReactComponent as IconArrowRight } from 'images/icon-arrow-right-16.svg';

import {
  Divider,
  Status,
  StatusTextWrapper,
  StatusWrapper,
  StyledLink,
  StyledStatusDot,
  Subtext,
  TextWrapper,
  Title,
} from './styles';

interface CardStatusProps {
  linkUrl: string;
  status: string;
  subtext: string;
  statusDotVariant: 'success' | 'caution' | 'neutral' | 'active';
  title: string;
}

const CardStatus: FC<CardStatusProps> = ({
  linkUrl,
  status,
  subtext,
  statusDotVariant,
  title,
}) => (
  <StyledLink to={linkUrl}>
    <TextWrapper>
      <Title>{title}</Title>
      <Subtext>{subtext}</Subtext>
    </TextWrapper>
    <Divider />
    <StatusWrapper>
      <StatusTextWrapper>
        <StyledStatusDot variant={statusDotVariant} size="medium" />
        <Status>{status}</Status>
      </StatusTextWrapper>
      <IconArrowRight />
    </StatusWrapper>
  </StyledLink>
);

export default CardStatus;
