import styled from 'styled-components';
import Input from 'components/Input';
import { semanticColors } from 'styles/colors';

interface StyledInputSearchProps {
  $isSimple?: boolean;
}

export const StyledInputSearch = styled(Input)<StyledInputSearchProps>`
  input {
    background-color: ${semanticColors.surface.secondary};
    border-color: ${semanticColors.surface.secondary};
    border-radius: 28px;
    box-shadow: unset;
    text-indent: 26px;
  }

  ${(props) =>
    props.$isSimple === true &&
  `
  input {
    background-color: transparent;
    border: none;

    &[disabled] {
      color: ${semanticColors.content.tertiary};
    }
  }
  `}
`;
