import { Modal, ToggleButton } from '@chownow/cn-web-components';
import Input from 'components/Input';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const StyledModal = styled(Modal)`
  color: ${semanticColors.content.primary};
  position: fixed;
  z-index: 1;
`;

export const Title = styled.h3`
  font: ${label.semi.x20};
  margin-bottom: ${space.x3};
`;

export const Message = styled.p`
  font: ${paragraph.reg.x14};
  margin-bottom: ${space.x3};
  margin-top: 0;
`;

export const Section = styled.div`
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  margin-bottom: ${space.x3};
  padding: ${space.x3};
`;

export const StyledInput = styled(Input)`
  margin-bottom: ${space.x3};
`;

export const StyledTextArea = styled(Input)`
  &&& textarea {
    resize: none;
  }
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${space.x3};
`;

export const RowTopAligned = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${space.x3};
`;

export const StyledToggleButton = styled(ToggleButton)`
  &&& {
    margin: 0;
    margin-left: ${space.x2};
    max-height: 48px;
    width: 160px;

    button: {
      max-height: 48px;
    }
  }
`;

export const Label = styled.label`
  font: ${label.reg.x14};
  margin-left: ${space.x1};
`;

export const LineItem = styled.div`
  display: flex;
  font: ${label.reg.x14};
  justify-content: space-between;
`;

export const Revision = styled(LineItem)`
  border-top: 1px solid ${semanticColors.border.primary};
  color: ${semanticColors.content.accent};
  margin-top: ${space.x2};
  padding-top: ${space.x2};
`;
