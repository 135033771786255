import React, { ChangeEventHandler, FC, useId, useState } from 'react';
import { ReactComponent as IconTrash } from 'images/icon-trash-16.svg';

import {
  ErrorMessage,
  FilenameDisplay,
  FileSelectorWrapper,
  HiddenInput,
  Row,
  TrashButton,
  UploadButton,
} from './styles';

interface FileSelectorProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  file: File | undefined;
  filesAccepted?: string;
  setFile: (file: File | undefined) => void;
  validate?: (file: File | undefined) => Promise<string> | string;
}

const FileSelector: FC<FileSelectorProps> = ({
  children,
  isDisabled = false,
  file,
  filesAccepted,
  setFile,
  validate = () => '',
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const id = `${useId()}-file-selector`;

  const processFile = (selectedFile: File | undefined, error: string) => {
    if (error) {
      setErrorMessage(error);
    } else {
      setFile(selectedFile);
      setErrorMessage('');
    }
  };

  const handleFileSelection: ChangeEventHandler<HTMLInputElement> = event => {
    const selectedFile = event.target.files?.[0];
    const res = validate(selectedFile);

    if (res instanceof Promise) {
      void res.then(error => processFile(selectedFile, error));
    } else {
      processFile(selectedFile, res);
    }
  };

  const handleFileClear = () => {
    setFile(undefined);
  };

  return (
    <FileSelectorWrapper>
      {!file ? (
        <>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <UploadButton htmlFor={id} aria-disabled={isDisabled}>
            {children}
          </UploadButton>
          <HiddenInput
            type="file"
            id={id}
            disabled={isDisabled}
            accept={filesAccepted}
            onChange={handleFileSelection}
          />
        </>
      ) : (
        <Row>
          <FilenameDisplay
            contentEditable={false}
            disabled={!!file.name}
            shouldHideLabel
            name=""
            label=""
            value={file?.name}
          />
          <TrashButton icon={IconTrash} onClick={handleFileClear} isInverse />
        </Row>
      )}
    </FileSelectorWrapper>
  );
};

export default FileSelector;
