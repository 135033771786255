// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".itemsList_187910887743{display:block}@media(min-width: 768px){.itemsList_187910887743{display:flex;flex-wrap:wrap;justify-content:space-between;padding-top:12px}}", "",{"version":3,"sources":["webpack://./src/components/MenuList/MenuItems/styles.module.scss"],"names":[],"mappings":"AAKA,wBACE,aAAA,CAIF,yBACE,wBACE,YAAA,CACA,cAAA,CACA,6BAAA,CACA,gBAAA,CAAA","sourcesContent":["@import '~styles/variables';\n\n// Menu Items Styles\n// ---------------------------------\n\n.itemsList {\n  display: block;\n}\n\n/* Small devices and above (tablet, 768px and up) */\n@media (min-width: $screen-sm-min) {\n  .itemsList {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    padding-top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsList": "itemsList_187910887743"
};
export default ___CSS_LOADER_EXPORT___;
