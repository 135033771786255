import React, { useRef, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { partial, find, findIndex } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import classNames from 'classnames';
import { animated, useSpring } from '@react-spring/web';
import { DashboardGrid, Grid } from '@chownow/cn-web-components';

import {
  requestCatalog,
  updateItemVisibility,
  updateModifierVisibility,
} from 'api/restaurant';
import { getNextAvailableTime } from 'helpers/restaurant';
import { EDIT_TYPE } from 'helpers/constants';
import { restaurantType } from 'helpers/prop-types';
import { InView } from 'react-intersection-observer';
import LoadingSpinner from 'components/LoadingSpinner';
import usePrevious from 'hooks/usePrevious';
import { CatalogContext } from 'context/CatalogContext';
import { ModalContext } from 'context/ModalContext';
import breakpoints from 'helpers/breakpoints';

import MenuItems from './MenuItems';
import ItemAndModifierStatus from './ItemAndModifierStatus';
import DialogModal from 'components/Modals/DialogModal';
import TextModal from 'components/Modals/TextModal';
import MenuCategoryNav from './MenuCategoryNav';
import AlertTopBar from 'components/Alert/AlertTopBar';
import { getIsModifiersParam } from 'helpers/queryParams';

import styles from './styles.module.scss';

const CATEGORY_OBSERVER_OFFSET = 250; // $navbar-height + $category-nav-height-desktop + $searchbar-height
const DEFAULT_CATEGORY = 'Categories';

function MenuList(props) {
  const {
    children,
    restaurant,
    showModifiers,
    setSelectedItem,
    updateMedia,
    header,
    subheader,
  } = props;

  let lasPos = window.pageYOffset;
  const isModifiersParam = getIsModifiersParam(useLocation().search);

  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(
    isModifiersParam ? EDIT_TYPE.modifiers : EDIT_TYPE.items
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [activeCategory, setActiveCategory] = useState(DEFAULT_CATEGORY);
  const prevCategory = usePrevious(activeCategory);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [modifierCategories, setModifierCategories] = useState([]);
  const [itemCategories, setItemCategories] = useState([]);
  const [hiddenMenuToggle, setHiddenMenuToggle] = useState([false]);
  const [hideCurrentlyHidden, setHideCurrentlyHidden] = useState(false);
  const { hideModal, showModal } = useContext(ModalContext);
  const topSectionRef = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lgMin });
  const isDoubleColumn = useMediaQuery({ minWidth: breakpoints.smMin });
  const defaultSpringProps = {
    config: { friction: 38, tension: 350, opacity: 1 },
  };
  const [springProps, setSpringProps] = useSpring(() => defaultSpringProps);
  useEffect(() => {
    setSpringProps({
      ...defaultSpringProps.config,
      height: hiddenMenuToggle ? `${expandedHeight}px` : '0px',
      opacity: hiddenMenuToggle ? 1 : 0,
    });
  }, [hiddenMenuToggle, handleToggleHideItem]);

  // get number of items that are toggled off and hidden
  const hiddenItemCount = itemCategories.reduce(
    (count, menuItem) =>
      count + menuItem.items.filter(item => !item.is_visible).length,
    0
  );
  // get number of modifiers that are toggled off and hidden
  const hiddenModifierCount = modifierCategories.reduce(
    (count, menuModifier) =>
      count + menuModifier.items.filter(item => !item.is_visible).length,
    0
  );

  const selectedCategories =
    editMode === EDIT_TYPE.items ? itemCategories : modifierCategories;

  const hiddenItemsMods = selectedCategories
    .map(menuItemCategory =>
      menuItemCategory.items.filter(item => !item.is_visible)
    )
    .flat(1);

  // eslint-disable-next-line max-len
  // for 2 column view, rounds up to nearest even number then divide by 2 (2 rows) * height of individual block
  const expandedHeight = isDoubleColumn
    ? Math.round(hiddenItemsMods.length / 2) * 146
    : hiddenItemsMods.length * 146;

  useEffect(() => {
    fetchCatalog();
    setActiveCategory(DEFAULT_CATEGORY);
  }, [restaurant.id]);

  useEffect(() => {
    if (!selectedCategories) return;

    setFilteredCategories(
      selectedCategories.reduce((categoriesArray, category) => {
        const items = category.items;
        const filteredItems = items.filter(item => {
          return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
        if (filteredItems.length) {
          categoriesArray.push({
            ...category,
            items: filteredItems,
          });
        }
        return categoriesArray;
      }, [])
    );
  }, [searchQuery, selectedCategories]);

  useEffect(() => {
    // Align to top to prevent jarring page changes
    if (isDesktop) {
      document.documentElement.scrollTop = 0;
    }
    searchQuery.length > 0
      ? setHideCurrentlyHidden(true)
      : setHideCurrentlyHidden(false);
    setActiveCategory(DEFAULT_CATEGORY);
  }, [searchQuery, editMode]);

  async function fetchCatalog() {
    setIsLoading(true);
    const nextAvailableTime = getNextAvailableTime(restaurant);
    const catalog = await requestCatalog(restaurant.id, nextAvailableTime);
    if (!catalog.error && catalog.menuCategories) {
      addItemsToCategories(catalog);
    }
    setIsLoading(false);
  }

  function addItemsToCategories(catalog) {
    const mappedItemCategories = catalog.menuCategories
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(category => {
        const categoryWithItems = category.items?.reduce((items, id) => {
          const CNItem = find(catalog.CNItems, { id });
          return CNItem ? [...items, CNItem] : items;
        }, []);
        return {
          name: category.name,
          items: categoryWithItems,
        };
      });

    const mappedModifierCategories = catalog.modifierCategories
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(category => {
        const categoryWithModifier = category.modifiers?.reduce(
          (modifiers, id) => {
            const CNModifier = find(catalog.modifiers, { id });
            return CNModifier ? [...modifiers, CNModifier] : modifiers;
          },
          []
        );
        return {
          name: category.name,
          min_qty: category.min_qty,
          items: categoryWithModifier,
        };
      });

    setModifierCategories(mappedModifierCategories || []);
    setItemCategories(mappedItemCategories || []);
  }

  function handleIntersection(category, inView, entry) {
    const boundingClientRect = entry ? entry.boundingClientRect : null;

    if (!boundingClientRect) return;

    const isAboveHeader = boundingClientRect.y < CATEGORY_OBSERVER_OFFSET;
    const categoryIndex = findIndex(selectedCategories, {
      name: category.name,
    });
    /*
    We can determine if the element is at the top of the window if its
    y-intersection is less than scroll distance
    */
    const scrollDistance = Math.abs(lasPos - window.pageYOffset);
    const isLessThanScroll =
      boundingClientRect.y < scrollDistance + CATEGORY_OBSERVER_OFFSET;

    lasPos = window.pageYOffset;

    if (!inView && isAboveHeader) {
      setActiveCategory(category.name);
    } else if (
      inView &&
      selectedCategories[categoryIndex - 1] &&
      isLessThanScroll
    ) {
      // when scrolling in reverse we want to grab the category above
      setActiveCategory(selectedCategories[categoryIndex - 1].name);
    } else if (inView && isLessThanScroll) {
      setActiveCategory(DEFAULT_CATEGORY);
    }
  }

  function validateHideModifier(id, isVisible) {
    if (!isVisible || editMode === EDIT_TYPE.items) return true;

    const { items, min_qty } = selectedCategories.find(category =>
      category.items.find(item => item.id === id)
    );
    const visibleCount = items.filter(item => item.is_visible).length;

    return !min_qty || visibleCount !== min_qty;
  }

  async function handleToggleHideItem(id, isVisible) {
    // Validate that the number of modifiers is not lower than required minimum
    if (!validateHideModifier(id, isVisible)) {
      return showModal(DialogModal, {
        message:
          'The number of hidden modifiers cannot be less than the required minimum.',
        onClose: hideModal,
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }

    const isItemMode = editMode === EDIT_TYPE.items;
    const prevCategories = { ...selectedCategories };
    const updatedCategories = selectedCategories.map(category => {
      const updatedItems = category.items.map(item => {
        return item.id === id || item.meta_id === id
          ? { ...item, is_visible: !isVisible }
          : item;
      });
      return { ...category, items: updatedItems };
    });

    isItemMode
      ? setItemCategories(updatedCategories || [])
      : setModifierCategories(updatedCategories || []);

    setAlertMessage(
      `${isItemMode ? 'Item' : 'Modifier'} successfully ${
        isVisible ? 'removed from' : 'added back to'
      } your menu`
    );
    setShowAlert(true);

    const { errors } = isItemMode
      ? await updateItemVisibility(restaurant.id, id, isVisible)
      : await updateModifierVisibility(restaurant.id, id, isVisible);

    if (errors) {
      // if errors, undo previous changes. This is necessary to allow for rapid hide/unhide
      isItemMode
        ? setItemCategories(prevCategories || [])
        : setModifierCategories(prevCategories || []);
    }
  }

  function handleOnSelectItem(item) {
    setSelectedItem(item);
  }

  function onOpenModal(itemName, itemDescription) {
    showModal(TextModal, {
      mobileFullView: true,
      message: itemDescription,
      onClose: hideModal,
      title: itemName,
    });
  }

  function handleOnSelectType(type) {
    const baseUrl = `/restaurant/${restaurant.id}/menu/hide-show-items`;

    setEditMode(type);
    type === EDIT_TYPE.items
      ? navigate(baseUrl)
      : navigate(`${baseUrl}?modifiers=true`);
  }

  function handleHiddenToggle() {
    setHiddenMenuToggle(!hiddenMenuToggle);
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <CatalogContext.Provider
      value={{
        categories: filteredCategories,
        setCategories: setFilteredCategories,
        updateMedia,
      }}
    >
      <Helmet title="Menu" />
      <>
        <div className={styles.itemListWrapper}>
          <div>
            <h2
              className={classNames(styles.menuStatusHeader, {
                [styles.noSubheader]: !subheader,
              })}
            >
              {header}
            </h2>
            <div className={styles.subHeader}>{subheader}</div>
          </div>
          <Sticky
            stickyStyle={{
              top: isDesktop ? '0px' : '32px',
              zIndex: '1',
            }}
            className={styles.stickyWrap}
            topOffset={isDesktop ? 0 : -32}
          >
            <MenuCategoryNav
              onSelectType={showModifiers && handleOnSelectType}
              activeCategory={activeCategory}
              editMode={editMode}
              categories={filteredCategories}
              prevCategory={prevCategory}
              searchQuery={searchQuery}
              topSectionRef={topSectionRef}
              setActiveCategory={setActiveCategory}
              setSearchQuery={setSearchQuery}
            />
          </Sticky>
          {showModifiers && !hideCurrentlyHidden && (
            <div className={styles.hiddenMenu}>
              {hiddenItemsMods.length > 0 && (
                <div
                  className={styles.hiddenToggle}
                  onClick={() => {
                    handleHiddenToggle();
                  }}
                >
                  <span
                    className={classNames(styles.categoryName, {
                      [styles.hiddenItemsOpen]: hiddenMenuToggle,
                    })}
                  >
                    Currently Hidden
                  </span>
                </div>
              )}
              <>
                <ItemAndModifierStatus
                  hiddenItemCount={hiddenItemCount}
                  hiddenModifierCount={hiddenModifierCount}
                  handleOnSelectType={handleOnSelectType}
                  restaurant={restaurant}
                />
                <animated.div style={springProps}>
                  <MenuItems
                    isAnimateOnDelete
                    items={hiddenItemsMods}
                    handleToggleHideItem={handleToggleHideItem}
                    onSelectItem={handleOnSelectItem}
                    onOpenModal={onOpenModal}
                    showModifiers={showModifiers}
                    dataTestId={
                      searchQuery
                        ? 'SearchedItemName'
                        : editMode === EDIT_TYPE.items
                        ? 'MenuItemName'
                        : 'ModifierName'
                    }
                  />
                </animated.div>
              </>
            </div>
          )}
          <DashboardGrid>
            <Grid sm={4} md={12}>
              {filteredCategories.length ? (
                filteredCategories.map((category, index) => (
                  <div
                    key={index}
                    id={`category_${index}`}
                    className={styles.categorySection}
                  >
                    <InView
                      rootMargin={`-${CATEGORY_OBSERVER_OFFSET}px 0px 0px 0px`}
                      onChange={partial(handleIntersection, category)}
                    >
                      <span className={styles.categoryName}>
                        {category.name}
                      </span>
                    </InView>
                    <MenuItems
                      items={category.items}
                      handleToggleHideItem={handleToggleHideItem}
                      onSelectItem={handleOnSelectItem}
                      onOpenModal={onOpenModal}
                      showModifiers={showModifiers}
                      dataTestId={
                        searchQuery
                          ? 'SearchedItemName'
                          : editMode === EDIT_TYPE.items
                          ? 'MenuItemName'
                          : 'ModifierName'
                      }
                    />
                  </div>
                ))
              ) : (
                <h3 className={styles.emptyStateMessage}>
                  Sorry, no menu items found
                </h3>
              )}
            </Grid>
          </DashboardGrid>
        </div>
      </>
      {children}
      <AlertTopBar
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={alertMessage}
      />
    </CatalogContext.Provider>
  );
}

MenuList.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  restaurant: restaurantType,
  setSelectedItem: PropTypes.func,
  showModifiers: PropTypes.bool,
  subheader: PropTypes.string,
  updateMedia: PropTypes.func,
};

export default MenuList;
