import { useEffect, useState } from 'react';
import { request } from 'helpers/api';
import { SalesTaxResponse } from 'types/sales-tax';

/**
 * Get sales tax for restaurant location
 * @param {string} restaurantId - ChowNow Restaurant ID
 */
export const useGetSalesTax = (restaurantId: string | null) => {
  const [isGetRequestLoading, setIsGetRequestLoading] = useState<boolean>(true);
  const [hasGetRequestError, setHasGetRequestError] = useState<boolean>(false);
  const [getRequestResponse, setGetRequestResponse] =
    useState<SalesTaxResponse>();

  useEffect(() => {
    const requestSalesTax = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: SalesTaxResponse = await request(
          `restaurant/${restaurantId}/onboarding/sales-tax`
        );

        setGetRequestResponse(response);
      } catch (err) {
        setHasGetRequestError(true);
      } finally {
        setIsGetRequestLoading(false);
      }
    };

    requestSalesTax().catch(() => {
      /* do nothing yet */
    });
  }, [restaurantId]);

  return { hasGetRequestError, isGetRequestLoading, getRequestResponse };
};

/**
 * Update sales tax value for restaurant location
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} salesTax - Sales tax amount as string float
 */
export const usePutSalesTax = () => {
  const [isPutRequestLoading, setIsPutRequestLoading] =
    useState<boolean>(false);

  const putSalesTax = async (restaurantId: string, salesTax: number) => {
    let hasPutRequestError = false;

    setIsPutRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await request(
        `restaurant/${restaurantId}/onboarding/sales-tax`,
        { sales_tax: salesTax },
        'PUT'
      );
    } catch (err) {
      hasPutRequestError = true;
    } finally {
      setIsPutRequestLoading(false);
    }

    return hasPutRequestError;
  };

  return { isPutRequestLoading, putSalesTax };
};
