import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';
import { label } from 'styles/types';

export const FileSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space.x3};
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
`;

export const TrashButton = styled(Button)`
  margin-left: ${space.x1};
`;

export const FilenameDisplay = styled(Input)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 374px;

  input[type='text'] {
    padding: ${space.half};
  }

  svg {
    display: none;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

// Designed to look/behave like our component library's Button Outline.
export const UploadButton = styled.label`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: 28px;
  color: ${semanticColors.content.primary};
  cursor: pointer;
  font: ${label.reg.x14};
  max-width: 162px;
  padding: ${space.x2};
  text-align: center;
  transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;

  &[aria-disabled='true'] {
    background-color: ${semanticColors.surface.tertiary};
    border: 1px solid ${semanticColors.surface.tertiary};
    cursor: default;
  }

  &[aria-disabled='false']:hover {
    border: 1px solid ${semanticColors.border.tertiary};
  }
`;

export const ErrorMessage = styled.p`
  color: ${semanticColors.content.caution};
  display: block;
  font: ${label.reg.x14};
  margin: 0 0 ${space.x3};
`;
