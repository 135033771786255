import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';
import copyIcon from 'images/icon-copy.png';

export default function CodeSnippet({ label, value }) {
  const input = useRef();

  function copyToClipboard() {
    input.current.select();
    document.execCommand('copy');
  }

  return (
    <div>
      <label>{label}</label>
      <div className={styles.snippetSection}>
        <input
          type="text"
          ref={input}
          value={value}
          className={classnames(styles.input, styles.snippetInput)}
          readOnly
        />
        <button className={styles.copyBtn} onClick={copyToClipboard}>
          <img src={copyIcon} alt="Copy" className={styles.copyIcon} />
        </button>
      </div>
    </div>
  );
}

CodeSnippet.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
