// Types used for styled components

export const fontFamilyBase =
  '"Matter", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const letterSpacing = {
  base: '0',
  lg: '0.2px',
  headline: {
    x64: '-1.6px',
    x48: '-1px',
    x40: '-0.6px',
    x32: '-0.4px',
    x24: '-0.2px',
  },
};

export const headline = {
  reg: {
    x24: `${fontWeight.regular} 24px/28px ${fontFamilyBase}`,
    x32: `${fontWeight.regular} 32px/36px ${fontFamilyBase}`,
    x40: `${fontWeight.regular} 40px/44px  ${fontFamilyBase}`,
    x48: `${fontWeight.regular} 48px/52px ${fontFamilyBase}`,
    x64: `${fontWeight.regular} 64px/64px ${fontFamilyBase}`,
  },
  md: {
    x24: `${fontWeight.medium} 24px/28px ${fontFamilyBase}`,
    x32: `${fontWeight.medium} 32px/36px ${fontFamilyBase}`,
    x40: `${fontWeight.medium} 40px/44px  ${fontFamilyBase}`,
    x48: `${fontWeight.medium} 48px/52px ${fontFamilyBase}`,
    x64: `${fontWeight.medium} 64px/64px ${fontFamilyBase}`,
  },
};

export const label = {
  reg: {
    x12: `${fontWeight.regular} 12px/16px ${fontFamilyBase}`,
    x14: `${fontWeight.regular} 14px/16px ${fontFamilyBase}`,
    x16: `${fontWeight.regular} 16px/24px ${fontFamilyBase}`,
    x20: `${fontWeight.regular} 20px/24px ${fontFamilyBase}`,
  },
  md: {
    x12: `${fontWeight.medium} 12px/16px ${fontFamilyBase}`,
    x14: `${fontWeight.medium} 14px/16px ${fontFamilyBase}`,
    x16: `${fontWeight.medium} 16px/24px ${fontFamilyBase}`,
    x20: `${fontWeight.medium} 20px/24px ${fontFamilyBase}`,
  },
  semi: {
    x12: `${fontWeight.semiBold} 12px/16px ${fontFamilyBase}`,
    x14: `${fontWeight.semiBold} 14px/16px ${fontFamilyBase}`,
    x16: `${fontWeight.semiBold} 16px/24px ${fontFamilyBase}`,
    x20: `${fontWeight.semiBold} 20px/24px ${fontFamilyBase}`,
  },
};

export const paragraph = {
  reg: {
    x14: `${fontWeight.regular} 14px/20px ${fontFamilyBase}`,
    x16: `${fontWeight.regular} 16px/24px ${fontFamilyBase}`,
    x20: `${fontWeight.regular} 20px/28px ${fontFamilyBase}`,
  },
  italic: {
    x14: `italic 14px/20px ${fontFamilyBase}`,
    x16: `italic 16px/24px ${fontFamilyBase}`,
    x20: `italic 20px/28px ${fontFamilyBase}`,
  },
  semi: {
    x14: `${fontWeight.semiBold} 14px/20px ${fontFamilyBase}`,
    x16: `${fontWeight.semiBold} 16px/24px ${fontFamilyBase}`,
    x20: `${fontWeight.semiBold} 20px/28px ${fontFamilyBase}`,
  },
};

// to be used with text-transform: uppercase
export const caption = {
  md: {
    x10: `${fontWeight.medium} 10px/16px ${fontFamilyBase}`,
    x12: `${fontWeight.medium} 12px/16px  ${fontFamilyBase}`,
  },
};
