import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'intersection-observer';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';

import packageInfo from '../package.json';
import { initSentry } from 'helpers/loggers';

import * as serviceWorker from './serviceWorker';

import App from './App';

import './styles/index.scss';

initSentry(packageInfo.version);

(async () => {
  datadogRum.init({
    applicationId: process.env.DATA_DOG_APPLICATION_ID,
    clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'cn-dashboard',
    version: packageInfo.version,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    env: process.env.REACT_APP_DEPLOY_ENV,
    trackUserInteractions: true,
    trackLongTasks: false,
    trackResources: false,
    defaultPrivacyLevel: 'mask-user-input',
  });

  const root = createRoot(document.getElementById('root'));

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LDProvider>
    </React.StrictMode>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
