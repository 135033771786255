import { MenuError } from 'types/pos/integration-errors';

export const generateFileData = (menuError: MenuError) => {
  const keys = Object.keys(menuError.error_items[0]);
  let data = `${keys
    .map(k => k.replaceAll('_', ' ').toUpperCase())
    .join(',')}\n`;
  menuError.error_items.forEach(item => {
    const row = keys.map(key => `"${item[key]}"`).join(',');
    data += `${row}\n`;
  });
  return data;
};
