import { ScheduleHours } from 'types/restaurant-hours';

const defaultDisabledHoursState: ScheduleHours[] = [
  {
    day_of_week: 'Monday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Tuesday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Wednesday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Thursday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Friday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Saturday',
    is_closed: true,
    ranges: [],
  },
  {
    day_of_week: 'Sunday',
    is_closed: true,
    ranges: [],
  },
];

export default defaultDisabledHoursState;
