import { Dispatch, SetStateAction } from 'react';
import { EMAIL_VALIDATION_REGEX } from '@chownow/cn-web-utils/validation';

export const emailValidator = (
  email: string,
  setEmailError: Dispatch<SetStateAction<string>>
) => {
  const hasInvalidEmail =
    !email ||
    !(EMAIL_VALIDATION_REGEX as RegExp).test(email) ||
    email.length > 255;

  if (hasInvalidEmail) {
    setEmailError('Please enter a valid email address');
  }

  return hasInvalidEmail;
};
