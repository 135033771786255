import { useEffect, useState } from 'react';
import { request } from 'helpers/api';
import { IntegrationsResponse, isIntegrationsEmptyWithIdResponse } from 'types/pos/integrations';

/**
 * Get list of integrations for restaurant location
 * @param {string} restaurantId - ChowNow Restaurant ID
 */
export const useGetIntegrations = (restaurantId: string) => {
  const [
    isGetIntegrationsRequestLoading,
    setIsGetIntegrationsRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetIntegrationsRequestError, setHasGetIntegrationsRequestError] =
    useState<boolean>(false);
  const [getIntegrationsRequestResponse, setGetIntegrationsRequestResponse] =
    useState<IntegrationsResponse>();

  const getIntegrations = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: IntegrationsResponse = await request(
        `pos/restaurant/${restaurantId}/integrations`
      );

      // The request() function transforms an empty object {} response into an array of one
      // empty object and adds an id property [{ id: '1' }] so we need a way to determine
      // if the response is actually an empty response so that we can remove the object with id
      // and just have a clean empty array to check the length of when mapping over
      // the integrations
      if (isIntegrationsEmptyWithIdResponse(response)) {
        response.shift();
      }

      setGetIntegrationsRequestResponse(response);
    } catch (err) {
      setHasGetIntegrationsRequestError(true);
    } finally {
      setIsGetIntegrationsRequestLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantId) {
      void getIntegrations();
    }
  }, [restaurantId]);

  return {
    hasGetIntegrationsRequestError,
    isGetIntegrationsRequestLoading,
    getIntegrationsRequestResponse,
    getIntegrations,
  };
};
