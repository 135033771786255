import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import Whoops from 'components/Whoops';
import { Button } from '@chownow/cn-web-components';

import { restaurantType } from 'helpers/prop-types';

import styles from './styles.module.scss';

const SquareNotFound = ({ restaurant }) => {
  return (
    <div className={styles.container}>
      <Helmet title="Page Not Found" />
      <Whoops message="We were unable to secure an integration. Please try again." />
      <div className={styles.buttonSection}>
        <Link to={`/restaurant/${restaurant.id}/settings/integrations/square`}>
          <Button label={'Try Authorizing Again'} className={styles.button} />
        </Link>
        <Link
          className={classnames(styles.btnLink, styles.btnOutline)}
          to={`/restaurant/${restaurant.id}/settings/integrations`}
        >
          <Button label={'Return to Dashboard'} />
        </Link>
      </div>
    </div>
  );
};

SquareNotFound.propTypes = {
  restaurant: restaurantType,
};

export default SquareNotFound;
