import { createPortal } from 'react-dom';

const CropInstructionsPortal = ({ children }) => {
  const portal = document.getElementById('crop-instructions-portal');

  return portal
    ? createPortal(
        children,
        document.getElementById('crop-instructions-portal')
      )
    : null;
};

export default CropInstructionsPortal;
