import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from 'helpers/url';
import { CUBOH_MENU_URL } from 'helpers/constants';

import {
  ErrorContent,
  ErrorContentWrapper,
  ErrorMessage,
  ViewIntegrationButton,
} from './styles';

interface ThirdPartyMenuSotMessagingProps {
  isManagePhotos?: boolean;
  isCuboh?: boolean;
}

const ThirdPartyMenuSotMessaging: FC<ThirdPartyMenuSotMessagingProps> = ({
  isManagePhotos = false,
  isCuboh = false,
}) => {
  const navigate = useNavigate();
  const restaurantId = getUrlParam('restaurant');
  const isManagePhotosToast = isManagePhotos && !isCuboh;

  const handleViewIntegration = () => {
    if (isManagePhotosToast) {
      window.open(
        'https://www.toasttab.com/restaurants/admin/menus/homepage#',
        '_blank'
      );
    } else if (isCuboh) {
      window.open(CUBOH_MENU_URL, '_blank');
    } else {
      navigate(
        `/restaurant/${restaurantId}/settings/integrations`
      );
    }
  };

  function getButtonText() {
    if (isManagePhotosToast) {
      return 'Visit Toast Dashboard';
    } else if (isCuboh) {
      return 'Visit Menu Editor';
    }

    return 'View Integration';
  }

  function getMessage() {
    if (isManagePhotosToast) {
      return 'Your menu photos are managed by Toast, please go to your Toast Account to make edits.';
    } else if (isCuboh) {
      return 'Your menu, photos, and schedule are managed in the menu editor. Please go to the menu editor to make changes.';
    }

    return 'Please go to your Integration Page to view menu syncs, menu schedules and resolve integration errors.';
  }

  return (
    <ErrorContentWrapper>
      <ErrorContent $isManagePhotosToast={isManagePhotosToast}>
        <ErrorMessage>{getMessage()}</ErrorMessage>
        <ViewIntegrationButton
          label={getButtonText()}
          onClick={handleViewIntegration}
        />
      </ErrorContent>
    </ErrorContentWrapper>
  );
};

export default ThirdPartyMenuSotMessaging;
