import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import DELIVERY_FIXTURE from './GET-200-delivery-new';

const MANAGED_DELIVERY_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...DELIVERY_FIXTURE,
  tips: {
    managed_delivery_tip: {
      amount: 3.50,
      label: 'Courier Tip',
    },
    restaurant_tip: {
      amount: 5,
      label: 'Restaurant Tip',
    },
    total: 0,
  },
  single_use_items: {
    requested: true,
    label: 'Include utensils, condiments, etc.',
  },
  fulfillment_times: {
    created_at: '202310241703',
    updated_at: '202310241704',
    requested_fulfillment: null,
    estimated_pickup: null,
    estimated_arrival: null,
    original_estimated_arrival: '202311101413',
  },
  managed_delivery: {
    actual_delivery_time: null,
    actual_pickup_time: null,
    courier_name: 'Lilo Pelekai',
    estimated_delivery_time: null,
    estimated_pickup_time: null,
    id: '5',
    label: 'DoorDash',
    last_updated_at: '2023-10-25T00:04:00Z',
    message: 'This order will be picked up by DoorDash - please package accordingly. All tips go to courier.',
    status: null,
    tracking_url: null,
  },
};

export default MANAGED_DELIVERY_FIXTURE;
