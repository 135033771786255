import styled from 'styled-components';
import { Grid } from '@chownow/cn-web-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, screen, space } from 'styles/variables';

export const ListWrapper = styled.div`
  display: grid;
  grid-gap: ${space.x5};
  grid-template-columns: 1fr;

  @media (min-width: ${screen.xsMin}) {
    grid-gap: ${space.x3};
    grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
  }

  @media (min-width: ${screen.lgMin}) {
    grid-template-columns: repeat(3, minmax(296px, 1fr));
  }
`;

export const NoIntegrationsMessage = styled.p`
  font: ${label.reg.x16};
  margin: ${space.x2} 0 0 0;
`;

export const NoIntegrationsWrapper = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.secondary};
  display: flex;
  flex-direction: column;
  padding: ${space.x5};

  svg {
    path {
      fill: ${semanticColors.content.secondary};
    }
  }
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: ${space.x6};
`;
