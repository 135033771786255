import { FulfillmentTimes, OrderDetailsV6GetSuccessResponse } from 'types/order-details';

const BASE_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  discounts: [],
  customer: {
    dine_in: null,
    billing: {
      address: {
        zip: '96814',
        city: null,
        country_alpha2: 'US',
        name: null,
        street_address1: null,
        formatted_address: ',   96814',
        address_string: '\n  96814',
        place_formatted_address: 'None, None,  96814',
        state: null,
        country: null,
        geo: '0.0000000,0.0000000',
        latitude: null,
        longitude: null,
        id: '1987535',
        is_google_place: false,
        street_address2: null,
      },
      card_number: '... 1111',
      card_type: 'Visa',
      id: '1505288',
      card_exp_date: '12/2034',
      cardholder_name: null,
      card: {
        exp_month: 12,
        is_visible: true,
        type_id: '1',
        name: '',
        address: {
          zip: '96814',
          city: null,
          country_alpha2: 'US',
          name: null,
          street_address1: null,
          formatted_address: ',   96814',
          address_string: '\n  96814',
          place_formatted_address: 'None, None,  96814',
          state: null,
          country: null,
          geo: '0.0000000,0.0000000',
          latitude: null,
          longitude: null,
          id: '1987535',
          is_google_place: false,
          street_address2: null,
        },
        last_four: '1111',
        exp_year: 2034,
        type: 'Visa',
        id: '1505288',
        cvv_valid: true,
        is_last_submitted: false,
      },
      is_last_submitted: false,
    },
    phone_number: '9999999999',
    delivery: null,
    curbside: null,
    is_optout: true,
    last_name: 'Doe',
    email: 'test@chownow.com',
    first_name: 'Jane',
    id: '2',
  },
  total: 13.26,
  items: [
    {
      size: 'Regular',
      name: 'Bruschetta',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 6.95,
      price: 6.95,
      per_item_total: 6.95,
      special_instructions: null,
      quantity: 1,
      instructions: null,
      id: 'aa151bd7e04349a99cc9ea7ff55a60ea',
      description: 'Bruschetta with crushed tomato, cilantro, basil and garlic with crisp toast ',
      modifier_categories: [],
    },
    {
      size: 'Large',
      name: 'Fountain Soda',
      tax_rate: 10,
      is_taxable: true,
      item_sum_total: 3.25,
      price: 3.25,
      per_item_total: 3.25,
      special_instructions: null,
      quantity: 1,
      instructions: null,
      id: '06d912ff4d6b45d68516c35afe7c35ac',
      description: null,
      modifier_categories: [
        {
          id: 'cec86868cbb4444b83e6675be88b50e2',
          selections: [
            {
              name: 'Diet Coke',
              is_taxable: true,
              price: 0,
              id: 'e41c5802e0834d3b9f364a6093395a7d',
              description: null,
            },
          ],
          name: 'Soda Choices',
        },
      ],
    },
  ],
  delivery_instructions: null,
  fulfill_method: 'pickup',
  status: 'Submitted',
  id: '8',
  marketplace_diner_savings: null,
  product_source: 'Order Direct',
  is_order_ahead: false,
  grabbed_by: 'test@chownow.com@111.11.1.1@dashboard',
  tips: {
    managed_delivery_tip: {
      amount: null,
      label: 'Courier Tip',
    },
    restaurant_tip: {
      amount: 2.04,
      label: 'Restaurant Tip',
    },
    total: 2.04,
  },
  fulfillment_times: {
    created_at: '202310251355',
    updated_at: '202310251355',
    requested_fulfillment: null,
    estimated_pickup: null,
    estimated_arrival: null,
    original_estimated_arrival: null,
  },
  marketplace: null,
  refund_amount: 0,
  sales_tax: 1.02,
  should_acknowledge: true,
  is_post_pay: false,
  credits: [],
  misc_fee: {
    label: 'Gratuity',
    amount: null,
  },
  special_instructions: null,
  delivery_fee: 0,
  accepted_at: null,
  tip_label: 'Restaurant Tip',
  item_count: 2,
  marketplace_diner_fee: null,
  managed_delivery: null,
  adjustments: {
    can_adjust_tip: true,
    tip: {
      description: '100% of your tip supports the restaurant and its staff who prepare and pack your order',
      tip_options: [
        {
          rate: 20,
          amount: 2.04,
          is_current_tip: true,
        },
        {
          rate: 26,
          amount: 2.65,
          is_current_tip: false,
        },
        {
          rate: 32,
          amount: 3.26,
          is_current_tip: false,
        },
        {
          rate: 38,
          amount: 3.88,
          is_current_tip: false,
        },
      ],
    },
  },
  subtotal: 10.2,
  restaurant: {
    address: {
      zip: '90405',
      city: 'Santa Monica',
      country_alpha2: 'US',
      name: null,
      street_address1: '2801 Ocean Park Blvd.',
      formatted_address: '2801 Ocean Park Blvd., Santa Monica CA 90405',
      address_string: '2801 Ocean Park Blvd.\nSanta Monica CA 90405',
      place_formatted_address: '2801 Ocean Park Blvd., Santa Monica, CA 90405',
      state: 'CA',
      country: 'USA',
      geo: '34.0191269,-118.4558258',
      latitude: 34.0191269,
      longitude: -118.4558258,
      id: '72835',
      is_google_place: false,
      street_address2: null,
    },
    name: 'The H Cafe - Santa Monica',
    locale_name: 'Santa Monica',
    phone: '8887072469',
    is_discover_live: true,
    short_name: 'The H Cafe',
    is_direct_live: true,
    status: {
      id: '1',
      name: 'Active',
      code: 'ACTIVE',
    },
    id: '1',
    company_id: '1',
  },
  refund_info: null,
  is_authenticated_order: true,
  total_due: 13.26,
  amount_for_transaction_fees: 13.26,
  item_total: 10.2,
  version: '6.0',
};

export const ORDER_AHEAD_FIXTURE: FulfillmentTimes = {
  created_at: '202311201652',
  updated_at: '202311201652',
  requested_fulfillment: '202311202315',
  estimated_pickup: null,
  estimated_arrival: null,
  original_estimated_arrival: null,
};

export const CANCELED_ORDER = {
  status: 'Canceled',
  adjustments: {
    can_adjust_tip: false,
    tip: null,
  },
  refund_info: {
    name: null,
    reason: null,
    refund_type: 'full',
    refund_status: 'Automatically Processed',
    refunded_at: '202404041031',
  },
  refund_amount: 13.26,
};

export default BASE_FIXTURE;
