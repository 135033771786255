import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { fontFamilyBase, label } from 'styles/types';
import { borderRadius, space } from 'styles/variables';

export const ReviewAccountCardContainer = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  color: ${semanticColors.content.primary};
  font-family: ${fontFamilyBase};
  margin-bottom: 142px;
  padding: ${space.x5};
`;

export const TopText = styled.p`
  font: ${label.semi.x16};
  margin: 0;
`;

export const BottomText = styled.p`
  font: ${label.reg.x14};
  margin: ${space.x1} 0 0 0;
`;
