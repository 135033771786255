import styled from 'styled-components';
import { space } from 'styles/variables';
import { label } from 'styles/types';
import { semanticColors } from 'styles/colors';
import { RadioButton } from '@chownow/cn-web-components';

import { cardStyles } from '../styles';

export const BottomCardWrapper = styled.div`
  ${cardStyles};

  margin-bottom: 142px;
`;

export const NoFoodImageHeading = styled.h3<{ $hasNoFoodImageSelectionError: boolean }>`
  color: ${({ $hasNoFoodImageSelectionError }) => (
    $hasNoFoodImageSelectionError ? semanticColors.content.caution : semanticColors.content.primary
  )};
  font: ${label.semi.x14};
  margin: ${space.x3} 0 0;
`;

export const NoFoodImageRadioButtonsRow = styled.div`
  display: flex;
  gap: ${space.x2};
  margin: ${space.x3} 0 0;
`;

export const StyledRadioButton = styled(RadioButton)`
  width: 200px;
`;
