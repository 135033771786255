import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'helpers/breakpoints';
import Restaurant from 'types/restaurant';
import { Filters, OrdersListResponse, Order, SearchLabels, SortOrderType } from 'types/orders-list';
import { ORDER_STATUS } from 'helpers/constants';
import {
  Button,
  Grid,
  MenuSimple,
} from '@chownow/cn-web-components';
import FilterButton from 'components/FilterButton';
import FilterList from 'components/FilterButton/FilterList';
import Filter from 'components/FilterButton/Filter';
import InputSearch from 'components/InputSearch';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';
import ContentContainer from 'components/ContentContainer';
import OrdersFilterModal from 'components/Modals/OrdersFilterModal';
import { ModalContext } from 'context/ModalContext';
import { ReactComponent as SearchIcon } from 'images/search-icon-16.svg';

import OrdersListTable from './OrdersListTable';
import Pagination from './Pagination';

import { buildFilterQuery, mapDateRangeToLabel } from './helpers';

import {
  ButtonGroup,
  FilterContainer,
  Reset,
  Results,
  SearchBar,
  SimpleCustomerName,
  SimpleOrderTotal,
  SpinnerWrap,
  StyledDashboardGrid,
  StyledDropdownButton,
  TableWrap,
} from './styles';

const CURRENCY_REGEX = /^[0-9]+\.[0-9]{2}$/;
const NUMERAL_REGEX = /^\d+$/;

interface OrdersListProps {
  isLoading: boolean;
  isCompanyOrdersList?: boolean;
  orders: OrdersListResponse | null;
  ordersList: Order[];
  fetchOrdersList: (filters: Filters, url?: string) => void;
  setErrorMessage: (message: string) => void;
  companyId: string;
  restaurant?: Restaurant;
}

const OrdersList: FC<OrdersListProps> = ({
  isLoading,
  isCompanyOrdersList,
  orders = [],
  ordersList = [],
  fetchOrdersList,
  setErrorMessage,
  companyId,
  restaurant,
}) => {
  const navigate = useNavigate();
  const isSmallMinScreen = useMediaQuery({ minWidth: breakpoints.smMin });
  const { showModal } = useContext(ModalContext);
  const queryParams = new URLSearchParams(useLocation().search);
  const queryParamStatus = queryParams.get('filterby');
  const queryParamDateRange = queryParams.get('date_range');
  const queryParamOffset = queryParams.get('offset');
  const queryParamSortOrder = queryParams.get('sort_order');
  const defaultSortOrder = queryParamSortOrder === 'asc' || queryParamSortOrder === 'desc' ? queryParamSortOrder : 'desc';
  const queryParamOrderBy = queryParams.get('order_by');
  // if queryParam is New or Future from an old bookmark, clear it to avoid error modal
  const selectedUrlFilter = ['New', 'Future'].includes(queryParamStatus ?? '') ? '' : queryParamStatus;
  const restaurantId = restaurant?.id;

  const [filters, setFilters] = useState<Filters>({
    order_status: selectedUrlFilter || '',
    q: '',
    search: 'order',
    date_range: queryParamDateRange || '1_week',
    order_by: queryParamOrderBy || 'created_at',
    sort_order: defaultSortOrder,
    offset: queryParamOffset || '',
  });

  const filterOrderStatusOptions = [
    { label: 'All Orders', value: '' },
    { label: ORDER_STATUS.accepted, value: ORDER_STATUS.accepted },
    { label: ORDER_STATUS.submitted, value: ORDER_STATUS.submitted },
    { label: ORDER_STATUS.canceled, value: ORDER_STATUS.canceled },
  ];

  const filterDateRangeOptions = [
    { label: 'Last 24 Hours', value: '24_hours' },
    { label: 'Last 7 Days', value: '1_week' },
    { label: 'Last 30 Days', value: '1_month' },
    { label: 'Last 3 Months', value: '3_months' },
    { label: 'Last 6 Months', value: '6_months' },
  ];

  const SEARCH_LABELS: SearchLabels = {
    customer: 'Customer Name',
    order: 'Order Number',
    total: 'Order Total',
  };

  useEffect(() => {
    const filterQuery = buildFilterQuery(filters);

    navigate(filterQuery);

    // makes a new api request when user clicks the browser back button so accurate data is displayed
    const handlePopstate = () => {
      const newFilters = {
        ...filters,
        offset: queryParamOffset || '',
        sort_order: defaultSortOrder as SortOrderType,
      };
      fetchOrdersList(newFilters);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [filters, navigate, restaurantId]);

  const submitSearch = () => {
    const searchVal = filters.q;
    const categoryVal = filters.search;

    if (searchVal && categoryVal === 'order' && !NUMERAL_REGEX.test(searchVal)) {
      setErrorMessage("Invalid order number. Try a number like '1234567'.");
    } else if (
      searchVal &&
      categoryVal === 'total' &&
      !NUMERAL_REGEX.test(searchVal) &&
      !CURRENCY_REGEX.test(searchVal)
    ) {
      setErrorMessage("Invalid order total. Try something like '12.34'.");
    } else {
      const newFilters = {
        ...filters,
        offset: '',
        sort_order: defaultSortOrder as string,
      };

      setErrorMessage('');
      setFilters(newFilters);
      fetchOrdersList(newFilters);
    }
  };

  let fetchTimeout: ReturnType<typeof setTimeout> | undefined;

  const debounceSearch = () => {
    if (fetchTimeout !== undefined) {
      clearTimeout(fetchTimeout as unknown as number);
    }

    fetchTimeout = setTimeout(() => {
      submitSearch();
    }, 500);
  };

  useEffect(() => {
    // add a slight delay to the search input to avoid returning error message while user is still typing
    const delay = 700;
    const timeoutId = setTimeout(() => {
      debounceSearch();
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [filters.q]);

  const resetSearch = () => {
    setErrorMessage('');
    setFilters({ ...filters, q: '', offset: '' });
  };

  const handleFilter = (filter: string, type: 'order_status' | 'date_range') => {
    setFilters((prevFilters: Filters) => {
      const newFilters: Filters = { ...prevFilters };

      if (type === 'order_status') {
        const filterName = filter === 'New' ? '' : filter;
        newFilters.order_status = filterName;
      } else if (type === 'date_range') {
        newFilters.date_range = filter;
      }

      newFilters.offset = '';

      fetchOrdersList(newFilters);

      return newFilters;
    });
  };

  const handleMobileSearchClick = () => {
    showModal(OrdersFilterModal, {
      mobileFullView: true,
      showCloseIcon: true,
      filters,
      setFilters,
    });
  };

  return (
    <ContentContainer>
      <StyledDashboardGrid>
        <Grid md={12}>
          <FilterContainer>
            <ButtonGroup>
              <FilterButton
                label={filters.order_status || 'All Orders'}
                onSetFilters={(selectedFilters: Record<string, { value: string }[]>) => {
                  const selectedFilter = selectedFilters[filters.order_status || 'All Orders'];
                  if (selectedFilter && selectedFilter.length > 0) {
                    handleFilter(selectedFilter[0].value, 'order_status');
                  }
                }}
              >
                <FilterList>
                  {filterOrderStatusOptions.map(option => (
                    <Filter
                      key={option.value}
                      value={option.value}
                      defaultFilter={filters.order_status === (option.value || '')}
                    >
                      {option.label}
                    </Filter>
                  ))}
                </FilterList>
              </FilterButton>
              <FilterButton
                label={mapDateRangeToLabel(filters.date_range) || 'Last 7 Days'}
                onSetFilters={(selectedFilters: Record<string, { value: string }[]>) => {
                  const selectedFilter = selectedFilters[mapDateRangeToLabel(filters.date_range) || 'Last 7 Days'];
                  if (selectedFilter && selectedFilter.length > 0) {
                    handleFilter(selectedFilter[0].value, 'date_range');
                  }
                }}
              >
                <FilterList>
                  {filterDateRangeOptions.map(option => (
                    <Filter
                      key={option.value}
                      value={option.value}
                      defaultFilter={filters.date_range === option.value}
                    >
                      {option.label}
                    </Filter>
                  ))}
                </FilterList>
              </FilterButton>
            </ButtonGroup>
            {isSmallMinScreen && (
              <ButtonGroup>
                <StyledDropdownButton
                  label={SEARCH_LABELS[filters.search as keyof SearchLabels]}
                  variant="outline"
                  size="small"
                >
                  <SimpleCustomerName
                    dataTestId="orders-list-menu-simple-customer-name"
                    handleClick={() => setFilters({ ...filters, search: 'customer', q: '' })}
                    subhead="Customer Name"
                  />
                  <MenuSimple
                    handleClick={() => setFilters({ ...filters, search: 'order', q: '' })}
                    subhead="Order Number"
                  />
                  <SimpleOrderTotal
                    dataTestId="orders-list-menu-simple-order-total"
                    handleClick={() => setFilters({ ...filters, search: 'total', q: '' })}
                    subhead="Order Total"
                  />
                </StyledDropdownButton>
                <SearchBar>
                  <InputSearch
                    onChange={query => setFilters({ ...filters, q: query })}
                    value={filters.q}
                  />
                </SearchBar>
              </ButtonGroup>
            )}
            {!isSmallMinScreen && (
              <ButtonGroup>
                <Button
                  size="small"
                  icon={SearchIcon}
                  variant="outline"
                  onClick={handleMobileSearchClick}
                />
              </ButtonGroup>
            )}
          </FilterContainer>
          <Results>
            {filters.q ? (
              <>
                {'Results for "'}{filters.q}{'" '}
                <Reset
                  onClick={resetSearch}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      resetSearch();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Reset
                </Reset>
              </>

            ) : (
              <>
                All Results
              </>
            )}
          </Results>
          <TableWrap>
            {isLoading ? (
              <SpinnerWrap>
                <LoadingSpinner isCentered size="x-large" />
              </SpinnerWrap>
            ) : (
              <OrdersListTable
                companyId={companyId}
                fetchOrdersList={fetchOrdersList}
                filters={filters}
                isCompanyOrdersList={isCompanyOrdersList}
                isLoading={isLoading}
                ordersList={ordersList}
                setFilters={setFilters}
              />
            )}
          </TableWrap>
          {!isLoading && (orders?.next || orders?.previous) && (
            <Pagination
              setFilters={setFilters}
              fetchOrdersList={fetchOrdersList}
              filters={filters}
              orders={orders}
            />
          )}
        </Grid>
      </StyledDashboardGrid>
    </ContentContainer>
  );
};

export default OrdersList;
