// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon-envelope{padding-right:5px}.loadingContainer_10833783739649{margin:160px auto;max-width:600px}", "",{"version":3,"sources":["webpack://./src/containers/LegacyEmbed/Disbursements/styles.module.scss"],"names":[],"mappings":"AAIE,eACE,iBAAA,CAIJ,iCACE,iBAAA,CACA,eAAA","sourcesContent":["@import '~styles/variables';\n\n/* stylelint-disable */\n:global {\n  .icon-envelope {\n    padding-right: 5px;\n  }\n}\n\n.loadingContainer {\n  margin: ($space-10 * 2) auto;\n  max-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": "loadingContainer_10833783739649"
};
export default ___CSS_LOADER_EXPORT___;
