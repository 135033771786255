import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { borderRadius, screen, space } from 'styles/variables';
import { ReactComponent as IconBadgeCheckComp } from 'images/icon-badge-check-md.svg';
import { ReactComponent as IconCautionComp } from 'images/icon-caution-md.svg';
import { ReactComponent as IconArrowOutComp } from 'images/icon-arrow-out-sm.svg';
import { ReactComponent as IconCheckComp } from 'images/icon-check-sm.svg';
import StripeButton from './StripeButton';

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screen.xsMin}) {
    max-width: 336px;
  }

  @media (min-width: ${screen.lgMin}) {
    max-width: 456px;
  }
`;

export const Subtext = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x2} 0 0 0;
`;

export const Status = styled.h2`
  font: ${label.semi.x20};
  margin: ${space.x2} 0 0 0;
`;

export const ContentRight = styled.div`
  margin-top: ${space.x5};
  width: 100%;

  @media (min-width: ${screen.xsMin}) {
    max-width: 336px;
  }

  @media (min-width: ${screen.smMin}) {
    margin-top: 0;
    max-width: 300px;
  }

  @media (min-width: ${screen.lgMin}) {
    max-width: 375px;
  }
`;

export const ErrorMessage = styled.p`
  font: ${label.semi.x20};
  margin: 0;
`;

export const InfoCard = styled.div`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.extra};
  padding: ${space.x3};

  li {
    margin-top: ${space.x2};
  }
`;

export const InfoLabel = styled.p`
  font: ${label.semi.x14};
  margin: 0;
`;

export const InfoTitle = styled.p`
  font: ${paragraph.semi.x14};
  margin: 0;
`;

export const InfoText = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x2} 0 0 0;
`;

export const BulletPoints = styled.ul`
  margin-left: 14px;

  li {
    list-style: disc;
  }

  li span {
    font: ${paragraph.reg.x14};
  }
`;

export const Checkmarks = styled.ul`
  li span {
    font: ${label.reg.x14};
  }
`;

export const IconCheck = styled(IconCheckComp)`
  margin-right: ${space.x1};
  vertical-align: text-bottom;

  path {
    fill: ${semanticColors.content.primary};
  }
`;

export const IconArrowOut = styled(IconArrowOutComp)`
  margin-left: ${space.x1};

  path {
    fill: ${semanticColors.content.inversePrimary};
  }
`;

export const IconBadgeCheck = styled(IconBadgeCheckComp)`
  path {
    fill: ${semanticColors.content.success};
  }
`;

export const IconCaution = styled(IconCautionComp)`
  path {
    fill: ${semanticColors.content.caution};
  }
`;

export const StatusButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${space.x3};
  width: 100%;

  @media (min-width: ${screen.xsMin}) {
    margin-top: ${space.x3};
  }
`;

export const AccountStatusButton = styled(StripeButton)`
  margin-top: ${space.x3};
`;

export const RefreshPageButton = styled(StripeButton)`
  margin-top: ${space.x3};
  width: 100%;
`;

export const ActivateButton = styled(StripeButton)`
  width: 100%;
`;

export const ErrorCard = styled.div`
  display: flex;
  flex-direction: column;
`;
