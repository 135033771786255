import React, { FC, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import breakpoints from 'helpers/breakpoints';
import Checkbox from 'components/Checkbox';
import { ModalContext } from 'context/ModalContext';
import EditPriceModal from '../EditPriceModal';

import {
  Amount,
  CheckboxWrap,
  DetailsWrap,
  EditPrice,
  ItemName,
  OriginalAmount,
} from './styles';

interface FeeItemProps {
  fee: {
    amount: number;
    name: string;
    refund: number;
  };
  handleUpdateFee: (name: string, amount: number) => void;
}

const FeeItem: FC<FeeItemProps> = ({ fee, handleUpdateFee }) => {
  const { amount, refund, name } = fee;
  const { showModal } = useContext(ModalContext);
  const isTouched = refund < amount;
  const isDesktop = useMediaQuery({ minWidth: breakpoints.smMin });

  const handleCheckboxOnClick = () => {
    const newAmount = refund ? 0 : amount;

    handleUpdateFee(name, newAmount);
  };

  const handleEditOnClick = () => {
    showModal(EditPriceModal, {
      total: amount,
      name,
      onSubmit: (revisedTotal: number) => handleUpdateFee(name, revisedTotal),
    });
  };

  return (
    <>
      <ItemName>{name}</ItemName>
      <DetailsWrap>
        {!!amount && (
          <div>
            <CheckboxWrap onClick={handleCheckboxOnClick}>
              <Checkbox name={name} isSelected={isTouched} />
            </CheckboxWrap>
            Refund
          </div>
        )}

        <EditPrice onClick={handleEditOnClick}>Adjust</EditPrice>

        <div>
          <OriginalAmount>{isTouched && isDesktop && amount.toFixed(2)}</OriginalAmount>

          <Amount $isSelected={isTouched}>
            {isTouched ? refund.toFixed(2) : amount.toFixed(2)}
          </Amount>
        </div>
      </DetailsWrap>
    </>
  );
};

export default FeeItem;
