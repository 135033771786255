import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateOrderStatus } from 'api/restaurant';
import { ModalContext } from 'context/ModalContext';
import { ORDER_ACTION, ORDER_STATUS, REFUND_TYPE } from 'helpers/constants';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Cover } from '@chownow/cn-web-components';
import DropDownMenu from 'components/DropDownMenu';
import AcceptOrderAheadModal from 'components/Modals/AcceptOrderAheadModal';
import AcceptOrderModal from 'components/Modals/AcceptOrderModal';
import ConfirmRefundModal from 'components/Modals/ConfirmRefundModal';
import DialogModal from 'components/Modals/DialogModal';
import DialogModalSecondary from 'components/Modals/DialogModalSecondary';
import DiscountModal from 'components/Modals/DiscountModal';

import styles from './styles.module.scss';

function CTABlock(props) {
  const {
    orderDetails,
    setOrderDetails,
    setIsLoading,
    setOrderStatus,
    orderListRoute,
  } = props;
  const {
    status,
    is_order_ahead,
    managed_delivery,
    total,
    credits,
    discounts,
  } = orderDetails;
  const orderAheadSubmitted =
    status === ORDER_STATUS.submitted && is_order_ahead;
  const orderSubmitted = status === ORDER_STATUS.submitted && !is_order_ahead;
  const orderCanceled = status === ORDER_STATUS.canceled;
  const isPromo = discounts?.length;
  const showRefunds =
    !orderCanceled &&
    !orderDetails.refund_amount &&
    !orderDetails.refund_info &&
    status === ORDER_STATUS.accepted;
  const isInvalidOrder = managed_delivery;
  const refundType =
    REFUND_TYPE[orderDetails.refund_info?.refund_type] || 'Refund Applied';
  const navigate = useNavigate();

  const { hideModal, showModal } = useContext(ModalContext);
  const flags = useFlags();

  function chooseTime() {
    showModal(AcceptOrderModal, {
      acceptOrCancelOrder: acceptOrCancelOrder,
    });
  }

  function showInvalidModal() {
    let copy;

    if (managed_delivery) {
      copy = DIALOG_MODAL_COPY.refundPartnerError;
    } else {
      copy = DIALOG_MODAL_COPY.refundDiscountsError;
    }

    showModal(DialogModal, {
      ...copy,
    });
  }

  function acceptTime() {
    showModal(AcceptOrderAheadModal, {
      acceptOrCancelOrder: acceptOrCancelOrder,
      requestedFulfillTime:
        orderDetails.fulfillment_times.requested_fulfillment,
    });
  }

  function confirmCancelOrder() {
    showModal(DialogModal, {
      ...DIALOG_MODAL_COPY.cancelOrder,
      onClose: handleCancelOrder,
    });
  }

  function handleCancelOrder(didConfirm) {
    if (didConfirm) {
      acceptOrCancelOrder(ORDER_ACTION.cancel);
    }
  }

  function handleFullRefund() {
    if (isInvalidOrder) {
      showInvalidModal();
    } else {
      showModal(DialogModalSecondary, {
        ...DIALOG_MODAL_COPY.fullRefund,
        onClose: processFullRefund,
        buttonColor: 'black',
      });
    }
  }

  function handleRefundByAmount() {
    if (isInvalidOrder) {
      showInvalidModal();
    } else {
      showModal(DiscountModal, {
        showCloseIcon: true,
        mobileFullView: true,
        orderDetails,
        setOrderDetails,
      });
    }
  }

  function handleRefundByItem() {
    if (isInvalidOrder || isPromo) {
      showInvalidModal();
    } else {
      navigate(`partial-refunds`);
    }
  }

  async function acceptOrCancelOrder(action, eta) {
    setIsLoading(true);
    const etaNum = parseInt(eta, 10);

    const response = await updateOrderStatus(orderDetails.id, action, etaNum);

    if (response.errors) {
      setIsLoading(false);
      hideModal();
      showModal(DialogModal, {
        message: `${
          response.errors[0]
            ? response.errors[0].message
            : 'error updating status'
        }`,
        onClose: () => {
          window.location.reload();
          hideModal();
        },
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
    setIsLoading(false);
    setOrderStatus(orderDetails.status);
  }

  function processFullRefund(didConfirm) {
    if (didConfirm) {
      // Timeout allows previous modal to close
      setTimeout(() => {
        showModal(ConfirmRefundModal, {
          showCloseIcon: true,
          orderId: orderDetails.id,
          payload: { refund_type: 'full' },
        });
      });
    }
    hideModal();
  }

  const CTAElement = () => {
    return (
      <div className={styles.ctaBlock}>
        {orderAheadSubmitted && (
          <DropDownMenu
            label="Accept or Decline"
            secondary
            modalHeader="Accept or Decline"
          >
            <li
              onClick={() => {
                acceptTime();
              }}
            >
              Accept order...
            </li>
            <li
              onClick={() => {
                confirmCancelOrder();
              }}
            >
              Decline order
            </li>
          </DropDownMenu>
        )}
        {orderSubmitted && (
          <DropDownMenu
            label="Accept or Decline"
            secondary
            modalHeader="Accept or Decline"
          >
            <li
              onClick={() => {
                chooseTime();
              }}
            >
              Accept order...
            </li>
            <li
              onClick={() => {
                confirmCancelOrder();
              }}
            >
              Decline order
            </li>
          </DropDownMenu>
        )}
        {showRefunds && (
          <DropDownMenu label="Refunds" modalHeader="Refunds">
            {flags.fullRefund && (
              <li onClick={handleFullRefund}>Full Refund</li>
            )}
            {flags.refundByItem && (
              <li
                onClick={handleRefundByItem}
                className={isPromo && styles.disabled}
              >
                Refund By Item
              </li>
            )}
            {flags.refundByAmount && (
              <li onClick={handleRefundByAmount}>Refund By Amount</li>
            )}
          </DropDownMenu>
        )}
        <div>
          <div className={styles.statusChip}>{orderDetails.status}</div>
        </div>
        {(orderDetails.refund_info || !!orderDetails.refund_amount) && (
          <div>
            <div className={styles.statusChip}>{refundType}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Cover title={`Order #${orderDetails.id}`} ctaElement={<CTAElement />} />
  );
}

export default CTABlock;
