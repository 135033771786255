import React, { useState, useContext } from 'react';
import classnames from 'classnames';

import { refundOrder } from 'api/order';
import { ModalContext } from 'context/ModalContext';
import { useNavigate } from 'react-router-dom';
import { DIALOG_MODAL_COPY } from 'helpers/modals';
import { ERROR_CODES } from 'helpers/constants';

import DialogModal from 'components/Modals/DialogModal';
import { Button } from '@chownow/cn-web-components';

import styles from './styles.module.scss';

function ConfirmRefundModal(props) {
  const { payload, orderId, redirectUrl } = props;
  const { hideModal, showModal } = useContext(ModalContext);
  const [formData, setFormData] = useState({ name: '', reason: '' });
  const navigate = useNavigate();

  function handleOnChange(e) {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleErrors(response) {
    const error = response.errors[0];

    if (error.code === ERROR_CODES.refundType) {
      showModal(DialogModal, {
        ...DIALOG_MODAL_COPY.refundTypeError,
      });
    } else {
      showModal(DialogModal, {
        title: 'Something went wrong.',
        message: error.message,
      });
    }
  }

  async function handleOnSubmit() {
    const finalPayload = {
      ...payload,
      ...formData,
    };

    const response = await refundOrder(orderId, finalPayload);

    if (!response.errors) {
      hideModal();
      if (redirectUrl) {
        navigate(redirectUrl);
      }
      location.reload();
    } else {
      handleErrors(response);
    }
  }

  return (
    <div className={styles.confirmRefundModal}>
      <div className={styles.header}>Confirm Refund</div>
      <div>
        Please enter your name and reason for refund. A copy of the receipt will
        automatically be sent to the diner.
      </div>
      <div className={styles.label}>Approved By</div>
      <input
        className={styles.input}
        placeholder="John Smith"
        name="name"
        value={formData.name}
        onChange={handleOnChange}
        maxLength={100}
      />
      <div className={styles.label}>Reason</div>
      <textarea
        className={classnames(styles.input, styles.textarea)}
        placeholder="Description..."
        name="reason"
        value={formData.reason}
        onChange={handleOnChange}
        maxLength={255}
      />
      <Button
        className={styles.button}
        onClick={handleOnSubmit}
        disabled={!formData.name || !formData.reason}
        label={'Confirm Refund'}
        isFullWidth
      />
    </div>
  );
}

export default ConfirmRefundModal;
