import React from 'react';
import classNames from 'classnames';

import { REFUND_STATUS } from 'helpers/constants';
import { formatMoney } from '@chownow/cn-web-utils/format';

import styles from './styles.module.scss';

function RefundInfo(props) {
  const { orderDetails } = props;
  const { refund_info } = orderDetails;
  const status = refund_info.refund_status;
  const REFUND_LABELS = {
    partial_by_item: 'Item(s) Refunded',
    partial_by_amount: 'Amount Refunded',
    full: 'Fully Refunded',
  };

  return (
    <div className={styles.refundInfo}>
      <div className={styles.header}>
        <div>{REFUND_LABELS[refund_info?.refund_type] || 'Refund Applied'}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.divider} />
        <div className={styles.row}>
          <div>Status</div>
          <div
            className={classNames(styles.status, {
              [styles.pending]:
                status === REFUND_STATUS.pendingAutomaticProcessing,
              [styles.blocked]:
                status === REFUND_STATUS.pendingCustomerSupport ||
                status === REFUND_STATUS.pendingRestaurantFeedback,
              [styles.processed]:
                status === REFUND_STATUS.automaticallyProcessed ||
                status === REFUND_STATUS.processedManuallyByCs,
            })}
          >
            {status}
          </div>
        </div>
        <div className={styles.row}>
          <div>Approved By</div>
          <div>{refund_info.name}</div>
        </div>
        <div className={styles.row}>
          <div>Reason</div>
          <div>{refund_info.reason}</div>
        </div>
      </div>
    </div>
  );
}

export default RefundInfo;
