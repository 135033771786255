import React, { FC } from 'react';

import Restaurant from 'types/restaurant';

import { PauseOrdersButton, OrderingNotLive, StyledOutlink } from './styles';

interface PauseOrdersProps {
  closureFulfillMethod: string;
  countdown: string;
  liveRestaurant: boolean;
  ordersPaused: string;
  restaurant: Restaurant;
  showPauseOrdersModal: () => void;
}

const PauseOrders: FC<PauseOrdersProps> = ({
  closureFulfillMethod,
  countdown,
  liveRestaurant,
  ordersPaused,
  restaurant,
  showPauseOrdersModal,
}) => {
  const isLoading =
    ordersPaused !== 'notPaused' && ordersPaused !== 'multiple' && !countdown;

  if (!liveRestaurant) {
    return (
      <OrderingNotLive>
        Ordering Not Live
      </OrderingNotLive>
    );
  }

  let buttonContent;

  if (ordersPaused === 'notPaused') {
    buttonContent = 'Pause Orders';
  } else if (ordersPaused === 'single') {
    buttonContent = `${closureFulfillMethod || 'Orders'} Paused · ${countdown}`;
  } else {
    buttonContent = (
      <StyledOutlink
        to={`${process.env.REACT_APP_ADMIN_PYTHON_APP_URL}/restaurant/${restaurant.id}/menus/temp-closures`} // eslint-disable-line
      >
        View Temp. Closures
      </StyledOutlink>
    );
  }

  return (
    <PauseOrdersButton
      dataTestId="pause-orders-button"
      isLoading={isLoading}
      onClick={() => {
        showPauseOrdersModal();
      }}
      label={buttonContent}
      variant="outline"
    />
  );
};

export default PauseOrders;
