import React, { FC, ComponentType, ReactElement } from 'react';
import { Button } from '@chownow/cn-web-components';

interface StripeButtonProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: ComponentType<any>;
  isLoading?: boolean;
  label?: ReactElement | string;
  onClick: () => void;
}

const StripeButton: FC<StripeButtonProps> = ({ className, icon, isLoading, label, onClick }) => (
  <Button
    className={className}
    dataTestId="stripe-button"
    icon={icon}
    isFullWidth
    isLoading={isLoading}
    // Currently this is the only way display the icon to the right of the text.
    // Until the button is updated to utilize the children prop instead of the label prop,
    // this will have to suffice.
    // @ts-expect-error see explanation above
    label={isLoading ? undefined : label}
    onClick={onClick}
  />
);

export default StripeButton;
