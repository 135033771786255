import { SquareIntegrationsErrorBannerMessages } from 'containers/Restaurant/Integrations/SquarePos/constants';
import { formatError, request } from 'helpers/api';
import { useEffect, useState } from 'react';
import { PosSquareResponse, UpdateSquareResponse } from 'types/pos/square';

/**
 * Fetch the Square Data
 * @param {string} restaurantId - restaurant ID
 */
export const useGetSquareDetails = (restaurantId: string | null) => {
  const [
    isGetSquareDetailsRequestLoading,
    setIsGetSquareDetailsRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetSquareDetailsRequestError, setHasGetSquareDetailsRequestError] =
    useState<boolean>(false);
  const [getSquareDetailsRequestResponse, setGetSquareDetailsRequestResponse] =
    useState<PosSquareResponse | null>(null);

  useEffect(() => {
    const getSquareDetails = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: PosSquareResponse = await request(
          `pos/square/restaurant/${restaurantId}`
        );

        setGetSquareDetailsRequestResponse(response);
      } catch (err) {
        setHasGetSquareDetailsRequestError(true);
      } finally {
        setIsGetSquareDetailsRequestLoading(false);
      }
    };

    if (restaurantId) {
      void getSquareDetails();
    }
  }, [restaurantId]);

  const updateSquareIsActive = async (isActive: boolean) => {
    if (!getSquareDetailsRequestResponse) {
      return '';
    }
    try {
      const updateResponse = (await request(
        `pos/square/restaurant/${restaurantId}`,
        {
          is_active: isActive,
        },
        'PUT'
      )) as UpdateSquareResponse;
      setGetSquareDetailsRequestResponse({
        ...getSquareDetailsRequestResponse,
        is_active: updateResponse.is_active,
        updated_at: updateResponse.updated_at,
      });
    } catch (err) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (formatError(err?.message ?? err)?.errors?.[0]?.message as string) ??
        SquareIntegrationsErrorBannerMessages.ERROR_MESSAGE_START_PAUSE_ORDERS
      );
    }
    return '';
  };

  return {
    hasGetSquareDetailsRequestError,
    isGetSquareDetailsRequestLoading,
    getSquareDetailsRequestResponse,
    updateSquareIsActive,
  };
};

export const usePutSquareDisable = (restaurantId: string) => {
  const [
    isPutSquareDisableRequestLoading,
    setIsPutSquareDisableRequestLoading,
  ] = useState<boolean>(false);

  const putSquareDisable = async (): Promise<string> => {
    if (!restaurantId) {
      return '';
    }
    setIsPutSquareDisableRequestLoading(true);
    try {
      await request(
        `pos/square/restaurant/${restaurantId}/oauth?action=revoke`
      );
    } catch (err) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (formatError(err?.message ?? err)?.errors?.[0]?.message as string) ??
        SquareIntegrationsErrorBannerMessages.ERROR_MESSAGE_DISABLE_INTEGRATION
      );
    } finally {
      setIsPutSquareDisableRequestLoading(false);
    }
    return '';
  };

  return {
    isPutSquareDisableRequestLoading,
    putSquareDisable,
  };
};
