import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find } from 'lodash';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { CatalogContext } from 'context/CatalogContext';
import { findSquareItemId } from 'helpers/catalog';

import LinkedStatus from 'components/LinkedStatus';

import styles from './styles.module.scss';

export default function CatalogItem({
  isCN,
  isDragMode,
  item,
  isOpen,
  toggleShowItem,
  unlinkItem
}) {
  const catalog = useContext(CatalogContext);
  const isReadOnly = !isCN;
  const modifierCount = item.modifiers ? item.modifiers.length : 0;
  const squareItemId = findSquareItemId(item);
  const linkedOrPartialItem = find(catalog.squareItems, { id: squareItemId });
  const sizesAndModifiers = [...item.serving_sizes, ...item.modifiers];
  const linkedItemStatus =
    item.x_id && !find(sizesAndModifiers, { x_id: null });
  const partialItemStatus =
    item.x_id && find(sizesAndModifiers, { x_id: null });

  return (
    <>
      <li
        className={classNames(styles.itemContainer, {
          [styles.highlightContainer]: isOpen,
          [styles.dragMode]: isDragMode && !item.x_id
        })}
        onClick={() => {
          toggleShowItem(item.id);
        }}
        id="cn-item"
        data-id={item.id}
      >
        <div className={styles.itemName}>{item.name}</div>
        <div className={styles.itemDetails}>
          {!!modifierCount && (
            <>
              {`${modifierCount} ${
                modifierCount > 1 ? 'Modifiers' : 'Modifier'
              }`}
            </>
          )}
          {item.serving_sizes && item.serving_sizes.length > 1 && (
            <>{`${modifierCount ? ' •' : ''} ${
              item.serving_sizes.length
            } Sizes`}</>
          )}
        </div>
        <div className={styles.itemPrice}>
          {formatMoney(item.price || 0)}
          {isReadOnly && <span className={styles.detailLink}>Details</span>}
        </div>
        <div className={classNames({ [styles.disabled]: isDragMode })}>
          <LinkedStatus
            CNId={item.id}
            linkedOrPartialItem={linkedOrPartialItem}
            linkedItemStatus={linkedItemStatus}
            partialItemStatus={partialItemStatus}
            unlinkItem={unlinkItem}
          />
        </div>
      </li>
    </>
  );
}

CatalogItem.propTypes = {
  isCN: PropTypes.bool,
  isDragMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  item: PropTypes.shape(),
  toggleShowItem: PropTypes.func,
  unlinkItem: PropTypes.func
};
