import React, { useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import withUser from 'components/WithUser';
import Alert from 'components/Alert/AlertBanner';
import Badge from 'components/Badge';
import CodeSnippet from './CodeSnippet';
import ButtonSection from './ButtonSection';

import { UserContext } from 'context/UserContext';
import { request } from 'helpers/api';

import styles from './styles.module.scss';

function WebIntegration() {
  const [activeCodeOptionTab, setActiveCodeOptionTab] = useState('general');
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [locations, setLocations] = useState(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const COMPANY_ID_DEBOUNCE = 500;

  useEffect(() => {
    if (!user.is_chownow_admin && !user.is_super_admin) {
      navigate('/unauthorized');
    }
  }, []);

  const handleCompanyIdChange = debounce(value => {
    setCompanyId(value.trim());
    setCompanyName('');
    setFormTouched(true);
    setLocations(null);
    setHasError(false);
    getCompany(value);
  }, COMPANY_ID_DEBOUNCE);

  function getCompany(selectedId) {
    if (selectedId === '') {
      return;
    }

    request(`company/${selectedId}`)
      .then(resp => {
        setCompanyName(resp.name);
        setLocations(resp.locations);
      })
      .catch(() => {
        setHasError(true);
      });
  }

  const slideOutScript = companyId // eslint-disable-next-line max-len
    ? `<script src="https://cf.chownowcdn.com/latest/static/integrations/ordering-modal.min.js" data-chownow-company-id="${companyId}"></script>`
    : '';
  const linkUrl = companyId
    ? `https://order.chownow.com/order/${companyId}/locations?add_cn_ordering_class=true`
    : '';
  return (
    <div className={styles.main}>
      <div>
        <div className={styles.companyIdCard}>
          <label className={styles.companyIdLabel}>Company ID</label>
          <input
            type="text"
            placeholder="Enter Company Id"
            onChange={e => handleCompanyIdChange(e.target.value)}
            className={classnames({
              [styles.input]: true,
              [styles.companyIdInput]: true,
              [styles.hasError]: !companyId && formTouched,
            })}
          />
        </div>
        <div className={styles.companyNameCard}>
          {companyName && <Badge status="success">{companyName}</Badge>}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            <div className={styles.codeOptionsBar}>
              <h2
                className={classnames(styles.subHeading, {
                  [styles.active]: activeCodeOptionTab === 'general',
                })}
                tabIndex="0"
                role="button"
                onClick={() => setActiveCodeOptionTab('general')}
                onKeyUp={e =>
                  e.keyCode === 13 && setActiveCodeOptionTab('general')
                }
              >
                General Code
              </h2>
              <h2
                className={classnames(styles.subHeading, {
                  [styles.active]: activeCodeOptionTab === 'locations',
                })}
                tabIndex="0"
                role="button"
                onClick={() => setActiveCodeOptionTab('locations')}
                onKeyUp={e =>
                  e.keyCode === 13 && setActiveCodeOptionTab('locations')
                }
              >
                Location Specific
                {locations && (
                  <Badge className={styles.locationsBadge} status="success">
                    {locations.length}
                  </Badge>
                )}
              </h2>
            </div>
            <div>
              {hasError && (
                <Alert danger className={styles.alert}>
                  Whoops, something went wrong! Check your ID and try again.
                </Alert>
              )}
            </div>
            {activeCodeOptionTab === 'general' ? (
              <div>
                <CodeSnippet label="Slide-out Script" value={slideOutScript} />
                <CodeSnippet label="Link URL" value={linkUrl} />
              </div>
            ) : (
              <div>
                {locations && locations.length
                  ? locations.map(restaurant => {
                      // eslint-disable-next-line max-len
                      const locationUrl = `https://order.chownow.com/order/${companyId}/locations/${restaurant.id}?add_cn_ordering_class=true`;
                      return (
                        <div key={`restaurant${restaurant.id}`}>
                          <CodeSnippet
                            label={`${restaurant.name} (${restaurant.id})`}
                            value={locationUrl}
                          />
                        </div>
                      );
                    })
                  : locations && (
                      <p className={styles.error}>
                        Oh no... this company has no locations!
                      </p>
                    )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <ButtonSection companyId={companyId} />
        </div>
      </div>
    </div>
  );
}

export default withUser(WebIntegration, true);
