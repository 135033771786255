export interface ConfigError {
  display_text: string;
}

export interface MenuError {
  display_text: string;
  menu_name: string;
  error_items: {
    [key: string]: string | string[] | null;
  }[];
}

export interface IntegrationErrorSuccessResponse {
  config_errors: ConfigError[] | null;
  menu_errors: MenuError[] | null;
}

export interface IntegrationErrorErrorResponse {
  message: string;
}

export type IntegrationErrorResponse =
  | IntegrationErrorSuccessResponse
  | IntegrationErrorErrorResponse;

export const isIntegrationError403Response = (
  response: IntegrationErrorResponse
): response is IntegrationErrorSuccessResponse =>
  !!response &&
  Object.prototype.hasOwnProperty.call(response, 'message');

export const isIntegrationErrorSuccessResponse = (
  response: IntegrationErrorResponse | undefined
): response is IntegrationErrorSuccessResponse =>
  !!response &&
  !Object.prototype.hasOwnProperty.call(response, 'message');
