import { useEffect, useState } from 'react';
import { formatError, request } from 'helpers/api';
import { ToastDetailsResponse } from 'types/pos/toast';
import { Error4xx } from 'types/errors/hermosa';
import { ToastIntegrationsErrorBannerMessages } from 'containers/Restaurant/Integrations/ToastPos/ToastDetails/constants';

/**
 * Get Toast details for restaurant location
 * @param {string} restaurantId - ChowNow Restaurant ID
 */

export const useGetToastDetails = (restaurantId: string | null) => {
  const [
    isGetToastDetailsRequestLoading,
    setIsGetToastDetailsRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetToastDetailsRequestError, setHasGetToastDetailsRequestError] =
    useState<boolean>(false);
  const [getToastDetailsRequestResponse, setGetToastDetailsRequestResponse] =
    useState<ToastDetailsResponse | null>(null);
  const [getToastDetailsRequestErrorMessage, setGetToastDetailsRequestErrorMessage] =
    useState<string | null>(null);

  useEffect(() => {
    const getToastDetails = async () => {
      try {
        const response: ToastDetailsResponse = await request(
          `pos/toast/restaurant/${restaurantId}`
        ) as ToastDetailsResponse;

        setGetToastDetailsRequestResponse(response);
      } catch (err) {
        const formattedError = formatError(err) as { errors: Error4xx[] };
        const errorMessage = formattedError.errors[0]?.message ?? '';

        setHasGetToastDetailsRequestError(true);
        setGetToastDetailsRequestErrorMessage(errorMessage);
        setIsGetToastDetailsRequestLoading(false);
      } finally {
        setIsGetToastDetailsRequestLoading(false);
      }
    };

    if (restaurantId) {
      void getToastDetails();
    }
  }, [restaurantId]);

  const updateToastDetailsRequestResponse = (putResponse: ToastDetailsResponse) => {
    setGetToastDetailsRequestResponse(putResponse);
  };

  return {
    hasGetToastDetailsRequestError,
    isGetToastDetailsRequestLoading,
    getToastDetailsRequestResponse,
    getToastDetailsRequestErrorMessage,
    updateToastDetailsRequestResponse,
  };
};

/**
 * Update is_active value for Toast location (send/stop orders)
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} isActive - is_active state from response (is restaurant actively sending orders)
 */
export const usePutSendStopOrders = () => {
  const [isSendStopOrdersPutRequestLoading, setIsSendStopOrdersPutRequestLoading] =
    useState<boolean>(false);
  const [hasSendStopOrdersPutRequestError, setHasSendStopOrdersPutRequestError] =
    useState<boolean>(false);
  const [sendStopOrdersErrorMessage, setSendStopOrdersErrorMessage] =
    useState<string | null>(null);
  const [sendStopOrdersErrorCode, setSendStopOrdersErrorCode] =
    useState<string | null>(null);

  const putSendStopOrders = async (
    restaurantId: string,
    isActive: boolean
  ): Promise<ToastDetailsResponse | null> => {
    setIsSendStopOrdersPutRequestLoading(true);

    try {
      const response = await request(
        `pos/toast/restaurant/${restaurantId}`,
        { is_active: !isActive },
        'PUT'
      ) as ToastDetailsResponse;

      setIsSendStopOrdersPutRequestLoading(false);

      return response;
    } catch (err) {
      const formattedError = formatError(err) as { errors: Error4xx[] };
      const errorMessage = formattedError.errors[0]?.message ??
        ToastIntegrationsErrorBannerMessages.ERROR_MESSAGE_START_PAUSE_ORDERS;
      const errorCode = formattedError.errors[0]?.code ?? '';

      setSendStopOrdersErrorCode(errorCode);
      setSendStopOrdersErrorMessage(errorMessage);
      setHasSendStopOrdersPutRequestError(true);
      setIsSendStopOrdersPutRequestLoading(false);

      return null;
    } finally {
      setIsSendStopOrdersPutRequestLoading(false);
    }
  };

  return {
    hasSendStopOrdersPutRequestError,
    isSendStopOrdersPutRequestLoading,
    putSendStopOrders,
    sendStopOrdersErrorCode,
    sendStopOrdersErrorMessage,
  };
};

/**
 * Update is_menu_syncing_enabled value for Toast location (enable/disable menu syncing)
 * @param  {string} restaurantId - ChowNow Restaurant ID
 * @param  {string} isMenuSyncingEnabled - is_menu_syncing_enabled state from response
 */
export const usePutSyncMenu = () => {
  const [isSyncMenuPutRequestLoading, setIsSyncMenuPutRequestLoading] =
    useState<boolean>(false);
  const [hasSyncMenuPutRequestError, setHasSyncMenuPutRequestError] =
    useState<boolean>(false);
  const [syncMenuPutRequestErrorMessage, setSyncMenuPutRequestErrorMessage] =
    useState<string | null>(null);

  const putSyncMenu = async (
    restaurantId: string,
    isMenuSyncingEnabled: boolean
  ): Promise<ToastDetailsResponse | null> => {
    setIsSyncMenuPutRequestLoading(true);

    try {
      const response = await request(
        `pos/toast/restaurant/${restaurantId}`,
        { is_menu_syncing_enabled: !isMenuSyncingEnabled },
        'PUT'
      ) as ToastDetailsResponse;

      setIsSyncMenuPutRequestLoading(false);

      return response;
    } catch (err) {
      const formattedError = formatError(err) as { errors: Error4xx[] };
      const errorMessage = formattedError.errors[0]?.message ??
        ToastIntegrationsErrorBannerMessages.ERROR_MESSAGE_SYNC_MENUS;

      setSyncMenuPutRequestErrorMessage(errorMessage);
      setHasSyncMenuPutRequestError(true);
      setIsSyncMenuPutRequestLoading(false);

      return null;
    } finally {
      setIsSyncMenuPutRequestLoading(false);
    }
  };

  return {
    hasSyncMenuPutRequestError,
    isSyncMenuPutRequestLoading,
    putSyncMenu,
    syncMenuPutRequestErrorMessage,
  };
};
