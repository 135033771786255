import React, { FC, useState } from 'react';

import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import { ReactComponent as UtensilsIcon } from 'images/icon-utensils.svg';
import { ReactComponent as NoteIcon } from 'images/icon-note.svg';

import { InfoContent, StyledCardInfo } from '../styles';

import {
  Credits,
  FullOrderButton,
  Info,
  InstructionContainer,
  InstructionIcon,
  RefundNote,
  StyledHR,
  StyledPaymentMethod,
  TextBlock,
} from './styles';

import OrderCardItem from './OrderCardItem';
import PriceLine from './PriceLine';
import FullOrderModal from './FullOrderModal';

const FEE_MESSAGES: { [key: string]: string } = {
  'Service Fee':
    'This restaurant adds this fee to cover costs and continue serving your community. 100% goes to the restaurant.',
  Gratuity:
    'This restaurant adds this fee to cover costs and continue serving your community. 100% goes to the restaurant.',
  'Support Local Fee':
    "This fee goes to ChowNow and keeps us 100% commission-free for restaurants. That means they don't have to raise their menu prices, so you save money on your orders. It's good for you, restaurants, and us.",
};

interface OrderCardProps {
  order: OrderDetailsV6GetSuccessResponse;
}

const OrderCard: FC<OrderCardProps> = ({ order }) => {
  const {
    subtotal,
    total,
    total_due,
    tips,
    delivery_fee,
    refund_amount,
    refund_info,
    sales_tax,
    single_use_items,
    special_instructions,
    misc_fee,
    marketplace_diner_fee,
    items,
    discounts,
    credits,
  } = order;

  const [shouldSeeFullOrderModal, setShouldSeeFullOrderModal] = useState(false);

  const shouldDisplayPriceBreakdown =
    (!refund_info && !refund_amount) || refund_info?.refund_type === 'full';

  const isPaymentDeclined = order.status === 'Declined';
  const hasManagedDeliveryTip = !!tips?.managed_delivery_tip?.amount;
  const hasRestaurantTip = !!tips?.restaurant_tip?.amount;
  // Only show View Full Order button if items have modifiers
  const canShowViewFullOrderButton = items?.some((item) => item.modifier_categories.length > 0);

  return (
    <StyledCardInfo title="Order">
      {!refund_info && (single_use_items || special_instructions) && (
        <InstructionContainer>
          {single_use_items && (
            <TextBlock>
              <InstructionIcon>
                {React.createElement(UtensilsIcon)}
              </InstructionIcon>
              {single_use_items.label}
            </TextBlock>
          )}
          {special_instructions && (
            <TextBlock>
              <InstructionIcon>{React.createElement(NoteIcon)}</InstructionIcon>
              {special_instructions}
            </TextBlock>
          )}
        </InstructionContainer>
      )}
      {shouldSeeFullOrderModal && (
        <FullOrderModal
          order={order}
          onClose={() => setShouldSeeFullOrderModal(false)}
        />
      )}
      {items?.map((item, index) => (
        <OrderCardItem
          key={item.id}
          item={item}
          isFirstElement={index === 0}
          isLastElement={index === items.length - 1}
        />
      ))}
      {canShowViewFullOrderButton && (
        <FullOrderButton
          label="View Full Order"
          isFullWidth
          onClick={() => setShouldSeeFullOrderModal(true)}
        />
      )}
      {order.product_source?.toLowerCase()?.indexOf('yelp') === -1 ? (
        <StyledPaymentMethod
          customer={order.customer}
          isPaymentDeclined={isPaymentDeclined}
        />
      ) : (
        <StyledHR />
      )}
      {shouldDisplayPriceBreakdown && (
        <InfoContent>
          {!!discounts?.length && (
            <>
              {discounts.map((discount, index) => (
                <PriceLine
                  key={discount.name}
                  label="Promo"
                  amount={
                    discount.amount && discount.amount > 0
                      ? -1 * discount.amount
                      : discount.amount
                  }
                  tooltipTitle={discount.name}
                  tooltipMessage={discount.description}
                  isFirstElement={!index}
                />
              ))}
            </>
          )}
          <PriceLine
            label="Subtotal"
            amount={subtotal}
            isFirstElement={!discounts?.length}
          />
          <PriceLine label="Taxes" amount={sales_tax} />
          {hasManagedDeliveryTip && (
            <PriceLine
              label={tips?.managed_delivery_tip.label}
              amount={tips?.managed_delivery_tip.amount}
            />
          )}
          {hasRestaurantTip && (
            <PriceLine
              label={tips?.restaurant_tip.label}
              amount={tips?.restaurant_tip.amount}
            />
          )}
          {order.fulfill_method === 'delivery' && (
            <PriceLine
              label="Delivery Fee"
              amount={delivery_fee}
              tooltipTitle="Delivery Fee"
              tooltipMessage={
                order.managed_delivery
                  ? 'ChowNow connects restaurants to delivery partners so they can serve more diners. This fee helps them cover costs.'
                  : 'This restaurant adds this fee to support their drivers and serve more diners. 100% goes to the restaurant.'
              }
            />
          )}
          <PriceLine
            label={marketplace_diner_fee?.label}
            amount={marketplace_diner_fee?.amount}
            tooltipTitle={marketplace_diner_fee?.label}
            tooltipMessage="This fee goes to ChowNow and keeps us 100% commission-free for restaurants. That means they don't have to raise their menu prices, so you save money on your orders. It's good for you, restaurants, and us."
          />
          <PriceLine
            label={misc_fee?.label}
            amount={misc_fee?.amount}
            tooltipTitle={misc_fee?.label}
            tooltipMessage={
              FEE_MESSAGES[misc_fee?.label || ''] || FEE_MESSAGES.Gratuity
            }
          />
          <PriceLine label="Total" amount={total} isBold />
          {credits && credits.length > 0 && (
            <Credits>
              {credits.map(credit => (
                <PriceLine
                  key={credit.name}
                  label={credit.name}
                  amount={Math.abs(credit.amount ?? 0) * -1}
                  tooltipTitle={credit.name}
                  tooltipMessage={credit.description}
                />
              ))}
              <PriceLine label="Customer Paid" amount={total_due} isBold />
            </Credits>
          )}
        </InfoContent>
      )}
      {!shouldDisplayPriceBreakdown && (
        <RefundNote>
          <Info>
            A refund has been applied to this order, which may be why actual
            totals are not reflected here.
          </Info>
        </RefundNote>
      )}
    </StyledCardInfo>
  );
};

export default OrderCard;
