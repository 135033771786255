import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImageUploader from 'components/ImageUploader';
import { animated, useSpring } from '@react-spring/web';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
  TIMEOUTS,
  PHOTO_TYPE,
  CROP_VALIDATION_OPTIONS,
} from 'helpers/constants';
import usePrevious from 'hooks/usePrevious';
import { CropImageContext } from 'context/CropImageContext';
import { imageType } from 'helpers/prop-types';

import AlertTopBar from 'components/Alert/AlertTopBar';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { ReactComponent as IconWarning } from 'images/icon-warning.svg';
import styles from './styles.module.scss';
let closeSidebarTimeout;

function UploadSidebar(props) {
  const { closeSideBar, item = {}, companyId, restaurantId, media } = props;
  const sideBarRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const prevItem = usePrevious(item) || {};
  const photoType = /\d/.test(item.id) || !item.id ? 'item' : item.id;
  const restrictions = CROP_VALIDATION_OPTIONS[photoType];
  const isLogo = item.id === PHOTO_TYPE.logo;
  const isCover = item.id === PHOTO_TYPE.cover;
  const isRestaurantAsset = isLogo || isCover;

  const defaultSpringProps = {
    transform: `translateX(100%)`,
    opacity: 0,
  };
  const [springProps, setSpringProps] = useSpring(() => defaultSpringProps);
  const slideStyles = {
    transform: springProps.transform,
    opacity: springProps.opacity,
  };

  useEffect(() => {
    return () => {
      clearTimeout(closeSidebarTimeout);
    };
  }, []);

  useEffect(() => {
    if (item?.id) {
      setIsOpen(true);
    }
  }, [item]);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(sideBarRef.current, {
        allowTouchMove: el => {
          // Allow touch move during bodylock on only react-crop-elements
          return (
            el &&
            (el.className === 'ReactCrop__crop-selection' ||
              el.className === 'ReactCrop__image' ||
              el.className?.indexOf('drag-handle') !== -1)
          );
        },
      });
    } else {
      enableBodyScroll(sideBarRef.current);
    }

    setSpringProps({
      transform: isOpen ? `translateX(0)` : defaultSpringProps.transform,
      opacity: isOpen ? 1 : defaultSpringProps.opacity,
      config: isOpen
        ? { friction: 28, tension: 225 }
        : defaultSpringProps.config,
    });
  }, [isOpen]);

  function handleSubmitImage(cancel, method) {
    if (!cancel) {
      let statusText;
      switch (method) {
        case 'DELETE':
          statusText = 'removed';
          break;
        case 'UPDATED':
          statusText = 'updated';
          break;
        default:
          statusText = 'added';
      }
      setAlertMessage(`Photo successfully ${statusText}`);
      setIsOpen(false);

      closeSidebarTimeout = setTimeout(() => {
        closeSideBar();
        setShowAlert(true);
      }, TIMEOUTS.closeSideBar);
    }
  }

  function handleCloseSidebar() {
    setIsOpen(false);
    setTimeout(() => {
      closeSideBar();
    }, TIMEOUTS.closeSideBar);
  }

  function getHeaderText() {
    if (!item) return '';
    if (isRestaurantAsset) {
      if (isCover && !media?.cover) {
        return `Add ${item.id} Photo`;
      } else if (isCover && media?.cover) {
        return `Edit ${item.id} photo`;
      } else if (isLogo && !media?.logo) {
        return `Add ${item.id}`;
      } else {
        return `Photo for ${item.id}`;
      }
    } else {
      const itemName = item?.name || prevItem?.name;
      return !item?.image
        ? `Add a photo to ${itemName}`
        : `Photo for ${itemName}`;
    }
  }

  function getImage() {
    return isRestaurantAsset ? media[item?.id] : item?.image;
  }

  return (
    <>
      <animated.div
        className={styles.overlay}
        onClick={handleCloseSidebar}
        style={{
          opacity: springProps.opacity,
          pointerEvents: isOpen ? 'initial' : 'none',
        }}
      />
      <animated.div
        className={styles.sideBar}
        style={slideStyles}
        ref={sideBarRef}
      >
        <div id="photo-button-portal" className={styles.photoButtonPortal}>
          <div className={styles.row}>
            <IconClose
              className={classNames(styles.iconClose, {
                [styles.disabled]: isLoading,
              })}
              onClick={!isLoading ? handleCloseSidebar : () => {}}
            />
          </div>
          <animated.div style={slideStyles}>
            <div className={styles.header}>{getHeaderText()}</div>
            <div id="crop-instructions-portal" />
            <animated.div style={slideStyles}>
              <CropImageContext.Provider
                value={{
                  closeSideBar: handleSubmitImage,
                  image: getImage(),
                  itemId: item?.id,
                  circleCrop: item?.id === PHOTO_TYPE.logo,
                  restaurantId,
                  companyId,
                  restrictions,
                }}
              >
                <ImageUploader
                  isActive={isOpen}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </CropImageContext.Provider>
            </animated.div>
          </animated.div>
          {isRestaurantAsset && isLogo && (
            <div className={styles.disclaimer}>
              <IconWarning className={styles.iconWarning} />
              Note: we're working to bring ${item?.id} photos to your menu, but you won't see them just yet.
            </div>
          )}
        </div>
      </animated.div>
      <AlertTopBar
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={alertMessage}
      />
    </>
  );
}

UploadSidebar.propTypes = {
  closeSideBar: PropTypes.func,
  companyId: PropTypes.string,
  item: PropTypes.shape({}),
  media: imageType,
  restaurantId: PropTypes.string,
};

export default UploadSidebar;
