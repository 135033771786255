import React, { FC } from 'react';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';
import { LoadingSpinnerContainer } from './styles';

const LoadingState: FC = () => (
  <LoadingSpinnerContainer>
    <LoadingSpinner isCentered size="x-large" />
  </LoadingSpinnerContainer>
);

export default LoadingState;
