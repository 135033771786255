import React, { FC } from 'react';

import Restaurant from 'types/restaurant';
import PauseOrders from 'containers/Restaurant/Orders/PauseOrders';
import ReadyTime from 'containers/Restaurant/Orders/ReadyTime';

import {
  HeadingCTA,
} from './styles';

interface CoverCTAProps {
  readyTime: number | null;
  restaurant: Restaurant;
  closureFulfillMethod: string;
  countdown: string;
  liveRestaurant: boolean;
  ordersPaused: string;
  showPauseOrdersModal: () => void;
  showReadyTimeModal: () => void;
}

const CoverCTA: FC<CoverCTAProps> = ({
  readyTime,
  restaurant,
  closureFulfillMethod,
  countdown,
  liveRestaurant,
  ordersPaused,
  showPauseOrdersModal,
  showReadyTimeModal,
}) => {
  const isASAPOnly = restaurant.allows_order_for_now && !restaurant.order_ahead;

  return (
    <HeadingCTA>
      {isASAPOnly && (
        <ReadyTime
          readyTime={readyTime}
          showReadyTimeModal={showReadyTimeModal}
        />
      )}
      <PauseOrders
        closureFulfillMethod={closureFulfillMethod}
        countdown={countdown}
        liveRestaurant={liveRestaurant}
        ordersPaused={ordersPaused}
        restaurant={restaurant}
        showPauseOrdersModal={showPauseOrdersModal}
      />
    </HeadingCTA>
  );
};

export default CoverCTA;
