import React, { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DropdownButton, DropdownButtonItem } from '@chownow/cn-web-components';

import breakpoints from 'helpers/breakpoints';

import { ReactComponent as CashIcon } from 'images/icon-cash.svg';
import { ReactComponent as DrinkIcon } from 'images/icon-drink.svg';
import { ReactComponent as ReceiptIcon } from 'images/icon-receipt.svg';
import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';

import MobileRefundMenu from './MobileRefundMenu';
import { RefundIcon } from './styles';
import FullRefundModal from './FullRefundModal';
import RefundByAmountModal from './RefundByAmountModal';
import {
  canRefundOrder,
  canRefundOrderByAmount,
  canRefundOrderByItem,
  canRefundOrderFull,
} from '../helpers';

interface RefundMenuProps {
  order: OrderDetailsV6GetSuccessResponse;
  setErrorMessage: (message: string) => void;
  setSuccessMessage: (message: string) => void;
  onRefundSuccess: () => Promise<void>;
}

const RefundMenu: FC<RefundMenuProps> = ({
  order,
  setErrorMessage,
  setSuccessMessage,
  onRefundSuccess,
}) => {
  const [shouldShowFullRefundModal, setShouldShowFullRefundModal] =
    useState(false);
  const [shouldShowRefundByAmountModal, setShouldShowRefundByAmountModal] =
    useState(false);
  const isDesktop = useMediaQuery({ minWidth: breakpoints.mdMin });
  const navigate = useNavigate();
  const flags = useFlags();

  if (!canRefundOrder(order, flags.refundByAmountRedesign as boolean)) {
    return null;
  }

  const buttons = [];
  if (canRefundOrderFull(order)) {
    buttons.push(
      <DropdownButtonItem
        key="full-refund"
        onClick={() => setShouldShowFullRefundModal(true)}
      >
        <RefundIcon>
          <ReceiptIcon />
        </RefundIcon>
        Full Refund
      </DropdownButtonItem>
    );
  }
  if (canRefundOrderByItem(order)) {
    buttons.push(
      <DropdownButtonItem
        key="refund-by-item"
        onClick={() => navigate('partial-refunds')}
      >
        <RefundIcon>
          <DrinkIcon />
        </RefundIcon>
        Refund by Item
      </DropdownButtonItem>
    );
  }
  if (flags.refundByAmountRedesign && canRefundOrderByAmount(order)) {
    buttons.push(
      <DropdownButtonItem
        key="refund-by-amount"
        onClick={() => setShouldShowRefundByAmountModal(true)}
      >
        <RefundIcon>
          <CashIcon />
        </RefundIcon>
        Refund by Amount
      </DropdownButtonItem>
    );
  }

  // NOTE: Any update to the secondaryCTAElement should also be reflected in MobileRefundMenu
  return (
    <>
      {shouldShowFullRefundModal && (
        <FullRefundModal
          order={order}
          onClose={() => setShouldShowFullRefundModal(false)}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          onRefundSuccess={onRefundSuccess}
        />
      )}
      {shouldShowRefundByAmountModal && (
        <RefundByAmountModal
          onClose={() => setShouldShowRefundByAmountModal(false)}
          order={order}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          onRefundSuccess={onRefundSuccess}
        />
      )}
      {isDesktop ? (
        <DropdownButton
          alignMenu="right"
          label="Refund"
          variant="outline"
          size="medium"
        >
          {buttons}
        </DropdownButton>
      ) : (
        <DropdownButton variant="outline" label="Refund" size="medium">
          <MobileRefundMenu
            onFullRefundClick={() => setShouldShowFullRefundModal(true)}
            onRefundByAmountClick={() => setShouldShowRefundByAmountModal(true)}
            order={order}
          />
        </DropdownButton>
      )}
    </>
  );
};

export default RefundMenu;
