import styled from 'styled-components';
import { label } from 'styles/types';
import { space } from 'styles/variables';

import { cardStyles } from '../styles';

export const CardWrapper = styled.div<{ $hasSelectedOption: boolean }>`
  ${cardStyles};

  margin-bottom: ${({ $hasSelectedOption }) => ($hasSelectedOption ? '' : '142px')};
`;

export const RadioRow = styled.div`
  align-items: center;
  display: flex;
  font: ${label.reg.x14};
  justify-content: left;
  margin-top: ${space.x3};
  width: 100%;
`;

export const RadioWrapper = styled.div`
  margin-right: ${space.x1};
`;
