import PropTypes from 'prop-types';

const { arrayOf, bool, number, oneOfType, shape, string } = PropTypes;

export const locationsType = arrayOf(
  shape({
    address: shape({}),
    id: string,
    name: string,
  })
);

export const locationType = shape({
  address: shape({
    address_line_1: string,
    address_line_2: string,
    administrative_district_level_1: string,
    country: string,
    locality: string,
    postal_code: string,
  }),
  business_email: string,
  id: string,
  name: string,
  phone_number: string,
  status: string,
});

const modifier = shape({
  id: string,
  name: string,
  price: number,
  description: string,
  is_taxable: bool,
  is_default: bool,
});

const modifierCategory = shape({
  id: string,
  name: string,
  modifiers: arrayOf(modifier),
  min_qty: number,
  max_qty: number,
});

export const menuItemType = shape({
  id: string,
  name: string,
  description: string,
  price: number,
  size: string,
  modifiers: arrayOf(modifier),
  delivery_charge: number,
  tax_rate: number,
  is_meta: bool,
});

export const squareItemType = shape({
  id: string,
  abbreviaton: string,
  description: string,
  display_order: number,
  modifiers: arrayOf(modifierCategory),
  name: string,
  serving_sizes: arrayOf(
    shape({
      id: string,
      size: string,
      price: number,
      display_order: number,
    })
  ),
});

const modifierSelections = shape({
  description: string,
  id: string,
  is_taxable: bool,
  name: string,
  price: number,
});

const modifierCategories = shape({
  id: string,
  name: string,
  selections: arrayOf(modifierSelections),
  length: number,
});

export const itemType = shape({
  description: string,
  id: string,
  instructions: string,
  item_sum_total: number,
  modifier_categories: arrayOf(modifierCategories),
  name: string,
  per_item_total: number,
  price: number,
  quantity: number,
  size: string,
  special_instructions: string,
});

export const ordersType = arrayOf(
  shape({
    customer: shape({
      first_name: string,
      id: string,
      is_optout: bool,
      last_name: string,
    }),
    fulfill_method: string,
    fulfillment_times: shape({
      estimated_arrival: string,
      created_at: string,
      updated_at: string,
      original_estimated_arrival: string,
      requested_fulfillment: string,
      estimated_pickup: string,
    }),
    id: string,
    is_order_ahead: bool,
    is_post_pay: bool,
    managed_delivery: bool,
    should_acknowledge: bool,
    status: string,
    total: number,
  })
);

export const orderDetailsType = shape({
  alternate_pickup_person: string,
  delivery_fee: number,
  delivery_instructions: string,
  fulfill_method: string,
  grabbed_by: string,
  id: string,
  is_order_ahead: bool,
  is_post_pay: bool,
  item_total: number,
  refund_amount: number,
  sales_tax: number,
  should_acknowledge: bool,
  special_instructions: string,
  status: string,
  subtotal: number,
  total: number,
  total_due: number,
});

export const stripeProcessorsType = shape({
  activation_date: string,
  cdd_compliant: string,
  cdd_required: string,
  hosted_onboarding: shape({
    expires_at: string,
    url: string,
  }),
  is_active: bool,
  updated_at: string,
  x_id: string,
  x_status: string,
});

export const s3ImageType = shape({
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    previewUrl: PropTypes.string,
  }),
});

export const imageType = shape({
  base_url: string,
  crop_height: number,
  crop_width: number,
  file_key: string,
});

export const addressType = shape({
  address_string: string,
  city: string,
  country: string,
  country_alpha2: string,
  formatted_address: string,
  geo: string,
  id: oneOfType([number, string]), // API returns restaurant address id as number
  is_google_place: bool,
  latitude: number,
  longitude: number,
  name: string,
  place_formatted_address: string,
  state: string,
  street_address1: string,
  street_address2: string,
  zip: string,
});

export const companyType = shape({
  id: string,
  name: string,
  phone: string,
  address: addressType,
});

export const restaurantType = shape({
  id: string,
  name: string,
  company_id: string,
});

export const menuListItemType = shape({
  categoryName: string,
  delivery_charge: number,
  description: string,
  id: string,
  is_meta: bool,
  modifier_categories: arrayOf(string),
  name: string,
  sizeId: string,
  image: imageType,
});

export const menuCategoryType = shape({
  childIds: arrayOf(string),
  id: string,
  items: arrayOf(menuListItemType),
  name: string,
});

export const itemModifierDetailType = shape({
  description: string,
  id: string,
  is_default: bool,
  is_taxable: bool,
  name: string,
  price: number,
});

export const orderItemType = shape({
  id: string,
  modifierList: arrayOf(shape(itemModifierDetailType)),
  modifierPrice: number,
  name: string,
  price: number,
  quantity: number,
  size: string,
  specialInstructions: string,
  tracking_id: string,
});
