import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilterItem from 'components/CatalogList/FilterItem';

import { ReactComponent as DownArrow } from 'images/icon-chevron.svg';

import styles from './styles.module.scss';

export default function FilterDropdown(props) {
  const {
    CNFilter,
    isDragMode,
    itemCount,
    itemCountLinked,
    itemCountPartial,
    itemCountUnlinked,
    onFilter
  } = props;
  const [showDropDown, setShowDropdown] = useState(false);

  function toggleDropdown() {
    setShowDropdown(!showDropDown);
  }

  return (
    <button
      className={classNames(styles.filterBtn, {
        [styles.isDragMode]: isDragMode
      })}
      onClick={toggleDropdown}
    >
      {CNFilter}
      <DownArrow className={styles.downArrow} />
      {showDropDown && (
        <>
          <div className={styles.offClickLayer} onClick={toggleDropdown} />
          <div className={styles.filterDropDown}>
            <FilterItem
              CNFilter={CNFilter}
              itemCount={itemCount}
              itemCountLinked={itemCountLinked}
              itemCountPartial={itemCountPartial}
              itemCountUnlinked={itemCountUnlinked}
              onFilter={onFilter}
            />
          </div>
        </>
      )}
    </button>
  );
}

FilterDropdown.propTypes = {
  CNFilter: PropTypes.string,
  isDragMode: PropTypes.bool,
  itemCount: PropTypes.number,
  itemCountLinked: PropTypes.func,
  itemCountPartial: PropTypes.func,
  itemCountUnlinked: PropTypes.func,
  onFilter: PropTypes.func
};
