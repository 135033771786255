import React, { Dispatch, FC, SetStateAction } from 'react';
import { Radio } from '@chownow/cn-web-components';

import { CardWrapper } from '../styles';
import { Heading, RadioRow, RadioWrapper } from './styles';
import { PromotionMessages, PromotionOptions } from '../../constants';

export interface PromotionCardProps {
  selectedPromo: PromotionOptions | undefined;
  setSelectedPromo: Dispatch<SetStateAction<PromotionOptions | undefined>>;
}

const PromotionCard: FC<PromotionCardProps> = ({
  selectedPromo,
  setSelectedPromo,
}) => (
  <CardWrapper>
    <Heading>Select a promo:</Heading>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={selectedPromo === PromotionOptions.PERCENTAGE_20}
          name="promo-radio-button"
          onChange={() => setSelectedPromo(PromotionOptions.PERCENTAGE_20)}
          value="promo-percentage-20"
        />
      </RadioWrapper>
      {PromotionMessages.PERCENTAGE_20}
    </RadioRow>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={selectedPromo === PromotionOptions.PERCENTAGE_10}
          name="promo-radio-button"
          onChange={() => setSelectedPromo(PromotionOptions.PERCENTAGE_10)}
          value="promo-percentage-10"
        />
      </RadioWrapper>
      {PromotionMessages.PERCENTAGE_10}
    </RadioRow>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={selectedPromo === PromotionOptions.DOLLAR_AMOUNT_5}
          name="promo-radio-button"
          onChange={() => setSelectedPromo(PromotionOptions.DOLLAR_AMOUNT_5)}
          value="promo-dollar-amount-5"
        />
      </RadioWrapper>
      {PromotionMessages.DOLLAR_AMOUNT_5}
    </RadioRow>
    <RadioRow>
      <RadioWrapper>
        <Radio
          checked={selectedPromo === PromotionOptions.NO_PROMO}
          name="promo-radio-button"
          onChange={() => setSelectedPromo(PromotionOptions.NO_PROMO)}
          value="promo-none"
        />
      </RadioWrapper>
      {PromotionMessages.NO_PROMO}
    </RadioRow>
  </CardWrapper>
);

export default PromotionCard;
