import React, { FC } from 'react';
import { getSearchParam } from 'helpers/url';
import { useGetStripeVerificationUrl } from 'api/stripe';

import { StatusContentProps } from './types';
import {
  AccountStatusButton,
  ContentLeft,
  IconArrowOut,
  IconCaution,
  Status,
  Subtext,
} from './styles';

const AccountFailedContent: FC<StatusContentProps> = ({ restaurant, urlPath }) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const { getStripeVerificationUrl } = useGetStripeVerificationUrl();

  const handleActivateAccountClick = () => {
    const navigateToStripeVerificationUrl = async () => {
      const response = await getStripeVerificationUrl(restaurant, urlPath);

      if (response?.url) {
        window.location.href = `${response.url}${isComingFromHomepage ? '&origin=homepage' : ''}`;
      }
    };

    void navigateToStripeVerificationUrl();
  };

  return (
    <ContentLeft>
      <IconCaution />
      <Status>Something went wrong with your Stripe activation</Status>
      <Subtext>
        Please try to activate your Stripe account again. If this error
        persists, please contact ChowNow Support.
      </Subtext>
      <Subtext>
        The button below will link you out to resubmit your details.
      </Subtext>
      <AccountStatusButton
        label={(
          <>
            <span>Activate Stripe Account</span>
            <IconArrowOut />
          </>
        )}
        onClick={handleActivateAccountClick}
      />
    </ContentLeft>
  );
};

export default AccountFailedContent;
