import styled from 'styled-components';
import { space } from 'styles/variables';
import Popover from 'components/Popover';
import TabletPopoverContent from '../TabletPopoverContent';

export const StyledTabletPopoverContent = styled(TabletPopoverContent)`
  margin: 0 ${space.x1} 0 ${space.x2};
`;

export const TagContainer = styled.div`
  margin-right: ${space.x2};
`;

export const StyledPopover = styled(Popover)`
  margin-right: ${space.x2};
`;
