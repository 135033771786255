import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, screen, space } from 'styles/variables';

import { ReactComponent as IconChevron } from 'images/icon-chevron.svg';

export const Item = styled.div`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  margin-bottom: ${space.x3};
  min-height: 75px;
  overflow: hidden;
  padding: ${space.x3};
  position: relative;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${screen.smMin}) {
    padding: ${space.x3} ${space.x3} ${space.x3} ${space.x8};
  }
`;

export const IconExpand = styled.div`
  align-items: center;
  background-color: ${semanticColors.content.primary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${space.x2};
  justify-content: center;
  position: absolute;
  right: ${space.x3};
  top: 29px;
  width: ${space.x2};

  @media (min-width: ${screen.smMin}) {
    left: ${space.x3};
    right: unset;
  }
`;

export const ChevronIcon = styled(IconChevron)<{ $isActive: boolean }>`
  height: ${space.x1};
  transform: ${({ $isActive }) => ($isActive ? 'rotate(270deg)' : 'rotate(90deg)')};
  width: ${space.x1};

  path {
    fill: ${semanticColors.content.inversePrimary};
  }
`;
