import { Modal } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { space } from 'styles/variables';

export const StyledDialogModal = styled(Modal)`
  background-color: ${semanticColors.surface.primary};
  position: fixed !important;
  text-align: center;
  z-index: 10;

  > div {
    overflow: hidden;
  }

  svg {
    display: none;
  }

  h1 {
    color: ${semanticColors.content.primary};
    font: ${label.semi.x20};
    margin-bottom: ${space.x3};
  }

  p {
    color: ${semanticColors.content.primary};
    font: ${paragraph.reg.x14};
    margin-bottom: ${space.x3};
  }
`;
