import { HoursRange, ScheduleHours, ScheduleResponse } from 'types/restaurant-hours';
import { transformHours12To24 } from 'helpers/transformHours12To24';
import { transformHours24To12 } from 'helpers/transformHours24To12';

import { HoursData } from '../types';
import { DaysOfTheWeek } from '../constants';
import defaultDisabledHoursState from './__fixtures__/transformed-post-default-disabled-hours.fixture';

export const areNoHoursSelected = (hours: Map<string, HoursData>) => {
  let noHoursSelected = true;

  hours.forEach((hoursData: HoursData) => {
    const hoursRange = hoursData.ranges[0];
    const isDayClosed = hoursData.is_closed;
    const areAnyHoursSelected = !hoursData.is_closed && (!!hoursRange?.start || !!hoursRange?.end);

    if (isDayClosed || areAnyHoursSelected) {
      noHoursSelected = false;
    }
  });

  return noHoursSelected;
};

export const generateDefaultHoursState = () => {
  const defaultHours = new Map<string, HoursData>();

  Object.keys(DaysOfTheWeek).forEach((day: string) => {
    defaultHours.set(day, {
      is_closed: false,
      ranges: [{
        start: '',
        end: '',
      }],
    });
  });

  return defaultHours;
};

export const transformRestaurantHoursGetData = (
  isEnabled: boolean,
  scheduleHours: ScheduleHours[] | undefined,
  currentHours: Map<string, HoursData>
): Map<string, HoursData> => {
  if (isEnabled) {
    scheduleHours?.forEach(({ day_of_week, is_closed, ranges }: ScheduleHours) => {
      const transformedRanges: HoursRange[] = ranges.map((range) => ({
        start: transformHours24To12(range.start),
        end: transformHours24To12(range.end),
      }));

      currentHours.set(day_of_week, {
        is_closed,
        ranges: transformedRanges,
      });
    });
  }

  return currentHours;
};

const generateSubmittedHours = (hours: Map<string, HoursData>) => {
  const submittedHours: ScheduleHours[] = [];

  Object.keys(DaysOfTheWeek).forEach((day: string) => {
    const isClosed = hours.get(day)?.is_closed;

    const hoursData: ScheduleHours = {
      day_of_week: day,
      is_closed: isClosed,
      ranges: isClosed ? [] : [
        {
          start: '',
          end: '',
        },
      ],
    };

    if (!isClosed) {
      const dayHours = hours.get(day)?.ranges[0];

      if (dayHours?.start && dayHours?.end) {
        hoursData.ranges[0] = {
          start: transformHours12To24(dayHours.start),
          end: transformHours12To24(dayHours.end),
        };
      }
    }

    submittedHours.push(hoursData);
  });

  return submittedHours;
};

export const transformRestaurantHoursPostData = (
  isDeliveryEnabled: boolean | undefined,
  isPickupEnabled: boolean | undefined,
  isSameHours: boolean,
  deliveryHours: Map<string, HoursData>,
  pickupHours: Map<string, HoursData>): ScheduleResponse => {
  const postData: ScheduleResponse = {
    schedule: {
      pickup_and_delivery_same: isSameHours,
      pickup: {
        is_enabled: isPickupEnabled,
        hours: generateSubmittedHours(pickupHours),
      },
      delivery: {
        is_enabled: isDeliveryEnabled,
        hours: [],
      },
    },
  };

  if (isDeliveryEnabled && postData.schedule?.delivery?.hours) {
    postData.schedule.delivery.hours = generateSubmittedHours(deliveryHours);
  } else if (!isDeliveryEnabled && postData.schedule?.delivery?.hours) {
    postData.schedule.delivery.hours = defaultDisabledHoursState;
  }

  return postData;
};

export const updateDayHours = (
  day: string,
  prevHours: Map<string, HoursData>,
  updatedDayData: HoursData
) => {
  const updatedHours = new Map<string, HoursData>(prevHours);

  updatedHours.set(day, updatedDayData);

  return updatedHours;
};

export const validateHours = (
  isDeliveryEnabled: boolean,
  isPickupEnabled: boolean,
  isSameHours: boolean,
  deliveryHours: Map<string, HoursData>,
  pickupHours: Map<string, HoursData>
): boolean => {
  let isValidHours = true;

  if (isSameHours) {
    Object.keys(DaysOfTheWeek).forEach((day: string) => {
      const isPickupDayClosed = pickupHours.get(day)?.is_closed;
      const pickupDayHours = pickupHours.get(day)?.ranges[0];

      const isDeliveryDayClosed = deliveryHours.get(day)?.is_closed;
      const deliveryDayHours = deliveryHours.get(day)?.ranges[0];

      // Are the corresponding day hours different for pickup and delivery
      const hasDifferentHours = (deliveryDayHours?.start !== pickupDayHours?.start)
        || (deliveryDayHours?.end !== pickupDayHours?.end);
      // If the day is not closed, check that both start and end time are set
      const isMissingPickupHours = !isPickupDayClosed && (!pickupDayHours?.start
        || !pickupDayHours?.end);
      const isMissingDeliveryHours = !isDeliveryDayClosed && (!deliveryDayHours?.start
        || !deliveryDayHours?.end);

      if (hasDifferentHours || isMissingPickupHours || isMissingDeliveryHours) {
        isValidHours = false;
      }
    });
  } else {
    Object.keys(DaysOfTheWeek).forEach((day: string) => {
      const isPickupDayClosed = pickupHours.get(day)?.is_closed;
      const pickupDayHours = pickupHours.get(day)?.ranges[0];

      const isDeliveryDayClosed = deliveryHours.get(day)?.is_closed;
      const deliveryDayHours = deliveryHours.get(day)?.ranges[0];

      // If the day is not closed, validate that both start and end times were selected
      // If either the start and/or end time was not selected, the hours are INVALID
      // If both the start and end time were selected, the hours are VALID
      if (
        isPickupEnabled &&
        !isPickupDayClosed &&
        (!pickupDayHours?.start || !pickupDayHours?.end)) {
        isValidHours = false;
      }

      if (
        isDeliveryEnabled &&
        !isDeliveryDayClosed &&
        (!deliveryDayHours?.start || !deliveryDayHours?.end)
      ) {
        isValidHours = false;
      }
    });
  }

  return isValidHours;
};
