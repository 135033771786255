import styled from 'styled-components';
import { Button } from '@chownow/cn-web-components';
import { semanticColors } from 'styles/colors';
import { space } from 'styles/variables';

export const StyledFilterButton = styled.div`
  position: relative;
`;

export const StyledButton = styled(Button)`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${space.x1};
  display: inline-block;
  height: ${space.x5};
  padding: ${space.x1} ${space.x2};

  &:active {
    border-color: ${semanticColors.border.tertiary};
  }
`;

interface FilterMenuProps {
  $alignMenu?: 'left' | 'right';
  $isOpen?: boolean;
}

export const FilterMenu = styled.div<FilterMenuProps>`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${space.x1};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  margin-top: ${({ $isOpen }) => ($isOpen ? space.x1 : 0)};
  max-width: 600px;
  min-width: 200px;
  padding: ${space.x2};
  position: absolute;
  top: calc(3em);
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  width: max-content;
  z-index: ${({ $isOpen }) => ($isOpen ? 1 : 'auto')};

  ${(props) =>
    props.$alignMenu === 'right' &&
    `
    right: 0;
  `}
`;

export const DoneButton = styled(Button)`
  min-width: 176px;
`;
