const containerWidth = 856;
const xxsMax = 575;
const xsMin = 576;
const smMin = 768;
const mdMin = 992;
const lgMin = 1200;
const xlMin = 1440;

export default {
  containerWidth,
  xxsMax,
  xsMin,
  xsMax: smMin - 1,
  smMin,
  smMax: mdMin - 1,
  mdMin,
  mdMax: lgMin - 1,
  lgMin,
  lgMax: xlMin - 1,
  xlMin
};
