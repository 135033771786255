import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as IconDropdown } from 'images/icon-dropdown.svg';

import styles from './styles.module.scss';

export default function ListDropdown(props) {
  const {
    children,
    isCollapsed,
    isDropdown,
    isReadOnly,
    itemCount,
    label
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    !isCollapsed && (isDropdown || !isReadOnly)
  );

  return (
    <div>
      {isDropdown && !!itemCount && (
        <div
          className={classNames(styles.dropdown, {
            [styles.readOnly]: isReadOnly
          })}
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <IconDropdown
            className={classNames(styles.iconDropdown, {
              [styles.dropdownOpen]: isDropdownOpen
            })}
          />
          <div>
            <span className={styles.dropdownHeader}>{label}</span>
            <span className={styles.dropdownCount}>{`${itemCount} Total`}</span>
          </div>
        </div>
      )}

      {isDropdownOpen && !!itemCount && children}
    </div>
  );
}

ListDropdown.propTypes = {
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  isDropdown: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  itemCount: PropTypes.number,
  label: PropTypes.string
};
