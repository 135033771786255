export const validators = {
  bankRouting: {
    message: 'Invalid routing number',
    validate: (value = '') => {
      // http://www.brainjar.com/js/validation/default.asp
      // Run through each digit and calculate the total.
      let n = 0;

      for (let i = 0; i < value.length; i += 3) {
        n +=
          parseInt(value.charAt(i), 10) * 3 +
          parseInt(value.charAt(i + 1), 10) * 7 +
          parseInt(value.charAt(i + 2), 10);
      }

      // If the resulting sum is an even multiple of ten (but not zero),
      // the aba routing number is good.
      if (n !== 0 && n % 10 === 0) return true;
      // Canadian routing number is 8 digits and can include a dash after 5th digit
      else if (/^\d{5}-?\d{3}$/.test(value)) {
        return true;
      }

      return false;
    },
  },
};

interface OrderChooseTimeValues {
  time: string;
  customTime: string;
}

export const orderChooseTimeValidator = (values: OrderChooseTimeValues) => {
  const errors: { time?: string } = {};
  const time = Number(values.time);

  if ((!values.time && !values.customTime) || time < 1) {
    errors.time = '* Time required';
  } else if (Number.isNaN(time)) {
    errors.time = '* Please only enter numbers. Max time is 180 minutes';
  } else if (time > 180) {
    errors.time = '* Max time is 180 minutes';
  }

  return errors;
};

interface IntegrationLoginValues {
  email: string;
  password: string;
}

export const integrationLoginValidator = (values: IntegrationLoginValues) => {
  const errors: { email?: string; password?: string } = {};

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

interface OrderAheadValues {
  confirmOrderTime: string;
  extraTime: string;
  time: string;
}

export const orderAheadValidator = (values: OrderAheadValues) => {
  const errors: { orderAhead?: string } = {};

  if (values.confirmOrderTime === 'extraTime' && !values.time) {
    errors.orderAhead = '* Please select time needed';
  }

  return errors;
};

interface PauseOrdersValues {
  time: string;
  reason: string;
  fulfillMethod: string;
}

export const pauseOrdersValidator = (values: PauseOrdersValues) => {
  const errors: { pauseOrders?: string } = {};

  if (!values.time) {
    errors.pauseOrders = '* Time required';
  } else if (!values.reason) {
    errors.pauseOrders = '* Reason required';
  } else if (!values.fulfillMethod) {
    errors.pauseOrders = '* Fulfillment method required';
  }

  return errors;
};

interface PrepTimeValues {
  time: string;
  customTime: string;
}

export const prepTimeValidator = (values: PrepTimeValues) => {
  const errors: { prepTime?: string } = {};
  const time = Number(values.time);

  if ((!values.time && !values.customTime) || time < 5) {
    errors.prepTime = '* Time in mins 5 and over required';
  } else if (Number.isNaN(time)) {
    errors.prepTime =
      '* Please only enter numbers. Min time is 5 mins, max time is 180 mins';
  } else if (time > 180) {
    errors.prepTime = '* Max time is 180 minutes';
  }

  return errors;
};

// Regex for validating a string as containing only numeric digits (no alpha chars or formatting)
export const numericStringRegex = /^\d*$/;

export const menuFileUploadValidator = (file: File) => {
  const filePath = file?.name;
  const fileSizeMax = 10000000;
  const allowedExtensions =
    /(\.jpg|\.jpeg|\.png|\.tiff|\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.csv)$/i;
  const isFileAllowed = allowedExtensions.exec(filePath);
  let errorMessage = '';

  // Check if file exceeds 10 MB
  if (file.size >= fileSizeMax) {
    errorMessage =
      'Sorry the file you uploaded is too large. Please upload a file with a maximum size of 10 MB.';
  }

  // Check if file matches allowed file types
  if (isFileAllowed === null) {
    errorMessage =
      "Sorry we can't use this file type. Please upload a .jpg, .jpeg, .png, .tiff, .pdf, .doc, .docx, .xls, .csv";
  }

  return errorMessage;
};

// allows 0-9 numerical, a-z lowercase A-Z uppercase,
// underscore, period, hyphen, left right parenthesis, and space
// extension is case insensitive
export const assetFileNameRegex =
  /^[0-9a-zA-Z_\-.()&$@=;:+,?!{}^%\\[\]"'><~#| ]+\.(?:jpg|jpeg|png|tiff|pdf|doc|docx|xls|xlsx|csv)$/i;

export const logoAndFoodImageFileNameRegex =
  /^[0-9a-zA-Z_\-.()&$@=;:+,?!{}^%\\[\]"'><~#| ]+\.(?:[a-zA-Z]+)$/i;

export const urlRegex =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/iu;

// regex to check if max char limit is 500 and under
export const maxCharLimit500 = /^.{0,500}$/;

// regex to check if max char limit is 50 and under
export const maxCharLimit50 = /^.{0,50}$/;

// regex to check for the xxx-xxx-xxxx phone number format
export const PHONE_NUMBER_REGEX = /\d{3}-\d{3}-\d{4}$/;

// regex to check 123.45 format
export const VALID_REFUND_AMOUNT = /^\d*(\.\d{0,2})?$/;

// regex to check two digit percent format
export const VALID_REFUND_PERCENT = /^\d{0,2}$/;
