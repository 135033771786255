import styled from 'styled-components';
import { Button } from '@chownow/cn-web-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { space } from 'styles/variables';

export const ErrorStateWrapper = styled.div`
  margin: auto 0;
`;

export const ErrorContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${space.x5} 0;
  padding: ${space.x5} 10px;
`;

export const ErrorMessage = styled.p`
  color: ${semanticColors.content.primary};
  font: ${label.reg.x16};
  margin: 0;
`;

export const ResetButton = styled(Button)`
  margin-top: ${space.x3};
`;
