import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { isEventTargetNode } from 'helpers/events';

import { PopoverWrapper, PopoverContent, StyledButton } from './styles';

interface PopoverProps {
  ariaLabel: string;
  children: ReactNode;
  className?: string;
  icon: ReactNode;
  type: string;
}

const Popover: FC<PopoverProps> = ({
  ariaLabel,
  children,
  className,
  icon,
  type,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      isEventTargetNode(event.target) &&
      !popoverRef.current.contains(event.target)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);

    return () =>
      document.removeEventListener('click', handleOutsideClick, true);
  }, [handleOutsideClick]);

  return (
    <PopoverWrapper
      className={className}
      ref={popoverRef}
      $type={type}
      $isOpen={isPopoverOpen}
    >
      <StyledButton
        aria-label={ariaLabel}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        type="button"
        $isOpen={isPopoverOpen}
      >
        {icon}
      </StyledButton>
      {isPopoverOpen && <PopoverContent>{children}</PopoverContent>}
    </PopoverWrapper>
  );
};

export default Popover;
