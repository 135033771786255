import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { paragraph } from 'styles/types';

export const SelectedPromoDescription = styled.p`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.italic.x14};
  margin: 0;
  text-align: left;
  width: 100%;
`;
