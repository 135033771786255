export const sanitizeAdminUrl = (adminUrl: string) => {
  const hasSecureProtocol = /^https:\/\/.+/.test(adminUrl);

  let sanitizedAdminUrl = adminUrl;

  const queryParamIndex = adminUrl.indexOf('?');

  // If the protocol is http, replace it with https
  if (!hasSecureProtocol) {
    if (adminUrl.indexOf('http://') >= 0) {
      sanitizedAdminUrl = `https://${sanitizedAdminUrl.split('http://')[1]}`;
    } else {
      return '';
    }
  }

  // Strip any query params out, if present
  if (queryParamIndex >= 0) {
    const [adminUrlWithoutQueryParams] = adminUrl.split('?');

    sanitizedAdminUrl = adminUrlWithoutQueryParams;
  }

  const adminDomain = sanitizedAdminUrl?.split('https://')[1];
  const isProdDomain = adminDomain === 'admin.chownow.com/admin';
  const isDevDomain = adminDomain === 'admin.dev.chownow.com/admin';
  const isQaDomain = /^admin\.[A-Za-z0-9]+\.svpn\.chownow\.com\/admin/.test(adminDomain ?? '');
  const isValidDomain = isProdDomain || isDevDomain || isQaDomain;

  if (!isValidDomain) {
    return '';
  }

  return sanitizedAdminUrl;
};
