import React from 'react';
import PropTypes from 'prop-types';
import CodeSnippet from '../CodeSnippet';

import styles from './styles.module.scss';
import breakpoints from 'helpers/breakpoints';

export default function ButtonCode({ buttonStyles, buttonText, companyId }) {
  // eslint-disable-next-line max-len
  const stringedStyles = `background-color: ${buttonStyles.backgroundColor}; border: ${buttonStyles.border}; border-radius: ${buttonStyles.borderRadius}; color: ${buttonStyles.color}; text-decoration: none; text-transform: ${buttonStyles.textTransform};`;
  const htmlButton = companyId // eslint-disable-next-line max-len
    ? `<a class="chownow-order-online" style="${stringedStyles} padding: 10px 40px;" href="https://order.chownow.com/order/${companyId}/locations" target="_blank">${buttonText}</a>`
    : '';
  const navButton = companyId // eslint-disable-next-line max-len
    ? `<span style="${stringedStyles} padding: 10px;">${buttonText}</span>`
    : '';
  const topAnchor = companyId // eslint-disable-next-line max-len
    ? `<style>.cn-cta-anchored-top {align-items: center; background-color: ${
        buttonStyles.backgroundColor // eslint-disable-next-line max-len
      }; border-radius: 0px 0px 4px 4px; color: ${
        buttonStyles.color // eslint-disable-next-line max-len
      }; display: flex; font-size: 14px; font-weight: bold; height: 40px; justify-content: center; letter-spacing: 1px; position: fixed; right: 25px; text-decoration: none; text-transform: ${
        buttonStyles.textTransform // eslint-disable-next-line max-len
      }; top: 0; width: 200px; z-index: 5;} @media (max-width: ${
        breakpoints.xsMax // eslint-disable-next-line max-len
      }px) {.cn-cta-anchored-top {display: none;}}</style><a class="chownow-order-online cn-cta-anchored-top" href="https://order.chownow.com/order/${companyId}/locations" target="_blank">${buttonText}</a>`
    : '';
  const mobileAnchor = companyId // eslint-disable-next-line max-len
    ? `<style>.cn-cta-mobile-container {display: none;} @media (max-width: ${
        breakpoints.xsMax // eslint-disable-next-line max-len
      }px) {body {margin-bottom: 150px;} .cn-cta-mobile-container {align-items: center; background-color: ${
        buttonStyles.backgroundColor // eslint-disable-next-line max-len
      }; bottom: 0; box-sizing: border-box; display: flex; justify-content: center; left: 0; padding: 16px 32px 16px 32px; position: fixed; width: 100%; z-index: 5;} .cn-cta-mobile-btn {background-color: #FFFFFF; border-radius: 4px; color: ${
        buttonStyles.backgroundColor // eslint-disable-next-line max-len
      } !important; font-size: 14px; font-weight: bold; letter-spacing: 1.1px; padding: 14px; text-align: center; text-decoration: none; text-transform: ${
        buttonStyles.textTransform // eslint-disable-next-line max-len
      }; width: 100%;}}</style><div class="cn-cta-mobile-container"><a class="chownow-order-online cn-cta-mobile-btn" href="https://order.chownow.com/order/${companyId}/locations" target="_blank">${buttonText}</a></div>`
    : '';

  return (
    <div>
      <h2 className={styles.subHeading}>Button HTML</h2>
      <CodeSnippet label="HTML Button" value={htmlButton} />
      <CodeSnippet
        label="Navigation Button (Squarespace & Wordpress)"
        value={navButton}
      />
      <CodeSnippet label="Anchored Top" value={topAnchor} />
      <CodeSnippet label="Mobile Anchor" value={mobileAnchor} />
    </div>
  );
}

ButtonCode.propTypes = {
  buttonStyles: PropTypes.objectOf(PropTypes.string),
  buttonText: PropTypes.string,
  companyId: PropTypes.string
};
