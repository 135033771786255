import React, { FC } from 'react';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';

import { LoadingContent, LoadingStateWrapper } from './styles';

const LoadingState: FC = () => (
  <LoadingStateWrapper>
    <LoadingContent>
      <LoadingSpinner isCentered size="x-large" />
    </LoadingContent>
  </LoadingStateWrapper>
);

export default LoadingState;
