import { isArray, isPlainObject, mapValues } from 'lodash';

export function nullToEmptyString(object) {
  return mapValues(object, val => {
    if (isPlainObject(val)) {
      return nullToEmptyString(val);
    } else if (isArray(val)) {
      return val.map(item => {
        return nullToEmptyString(item);
      });
    } else {
      return val === null || val === undefined ? '' : val;
    }
  });
}

export function formatDateAndTime(string) {
  const year = string.slice(0, 4);
  const monthDay = string.slice(5, 10);
  const date = `${monthDay}-${year}`;

  let time;
  const hour = string.slice(11, 13);
  const minutes = string.slice(14, 16);
  if (parseInt(hour) > 12) {
    time = `${hour - 12}:${minutes}pm`;
  } else if (parseInt(hour) === 12) {
    time = `${hour}:${minutes}pm`;
  } else {
    time = `${hour}:${minutes}am`;
  }

  return `${date} ${time}`;
}

export function arrayToSentence(arr, conjunction) {
  const last = arr.pop();
  return `${arr.join(', ')} ${conjunction} ${last}`;
}

/**
 * Returns date and time in API String format: 202101011200
 * @param {string} dateTime - date and time that needs to be converted to API string
 * @param {string} offset - accepts optional offset time in minutes ('15' = 15 minutes)
 * @returns {string} - if offset time is passed, returns dateTime + offset in API string format,
 * if only dateTime is passed, returns dateTime in API string format
 */
export function getApiStrForDate(dateTime, offset) {
  const timezoneOffset = dateTime.getTimezoneOffset();
  const todayInMilliseconds = dateTime.getTime();
  const offsetTimeInMilliseconds = offset ? offset * 60000 : 0;
  const todayPlusOffsetInMilliseconds =
    todayInMilliseconds + offsetTimeInMilliseconds;
  const timezoneAdjusted = todayPlusOffsetInMilliseconds - timezoneOffset;
  const today = new Date(timezoneAdjusted);

  // Jan is considered 0, so need to add 1 for standard month format
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const year = today.getFullYear();
  const hour = today.getHours();
  const min = today.getMinutes();

  // need to adjust to add 0 for months Jan - Sept
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMin = min < 10 ? `0${min}` : min;

  const formattedNow = `${year}${formattedMonth}${formattedDay}${formattedHour}${formattedMin}`;

  return formattedNow;
}
