import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import Badge from 'components/Badge';
import InfoBlockHover from 'components/InfoBlockHover';
import DialogModal from 'components/Modals/DialogModal';
import SherlockModal from 'components/Modals/SherlockModal';
import { ReactComponent as NextArrow } from 'images/icon-chevron-right.svg';

import styles from './styles.module.scss';

import { request } from 'helpers/api';
import { UserContext } from 'context/UserContext';
import { ModalContext } from 'context/ModalContext';
import { formatDateAndTime } from 'helpers/format';

const { shape, string } = PropTypes;

function CompanySherlock({ company }) {
  const { user } = useContext(UserContext);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [sherlockCompanyInfo, setSherlockCompanyInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newSherlockUrl = useRef('');
  const [hasEditError, setHasEditError] = useState(false);
  const [hasGetError, setHasGetError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { showModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const sherlockUrl = sherlockCompanyInfo.websiteUrl;
  const API_REQUEST_TIMEOUT = 3000;

  useEffect(() => {
    if (!user.is_chownow_admin && !user.is_super_admin) {
      navigate('/unauthorized');
      return;
    }

    // Only make Sherlock calls if API base URL is set
    if (process.env.REACT_APP_SHERLOCK_API_HOST) {
      // Load the Sherlock details

      request(
        `company/${company.id}`,
        null,
        'GET',
        process.env.REACT_APP_SHERLOCK_API_HOST,
        null,
        API_REQUEST_TIMEOUT
      )
        .then(sherlockCompany => {
          setSherlockCompanyInfo(sherlockCompany);
          newSherlockUrl.current = sherlockCompany.websiteUrl;
        })
        .catch(err => {
          setHasGetError(true);
          if (err.name === 'AbortError') {
            setErrorMessage('Please connect to VPN and try again.');
          } else {
            setErrorMessage(err.error);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (hasGetError || hasEditError) {
      const callback = () => navigate(`/company/${company.id}`);

      showModal(DialogModal, {
        message: `Error occurred loading the company from Sherlock: ${
          errorMessage || 'Please try again later'
        } `,
        onClose: callback,
        title: 'Something went wrong',
        confirmLabel: 'Close',
      });
    }
  }, [hasGetError, hasEditError, errorMessage]);

  useEffect(() => {
    if (isModalOpen) {
      showModal(SherlockModal, {
        initialValue: newSherlockUrl.current,
        onClose: submitUrlUpdate,
        onChange: changeUrl,
      });
    }
  }, [isModalOpen]);

  function setBadgeStatus(status) {
    return status ? 'success' : 'danger';
  }

  function setBadgeWording(status) {
    return status ? 'Healthy' : 'Unhealthy';
  }

  function toggleHistory() {
    setIsHistoryOpen(!isHistoryOpen);
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function changeUrl(e) {
    newSherlockUrl.current = e.target.value;
  }

  function submitUrlUpdate(didUpdateUrl) {
    if (didUpdateUrl) {
      request(
        `company/${company.id}/update-website-url`,
        { websiteUrl: newSherlockUrl.current },
        'PUT',
        process.env.REACT_APP_SHERLOCK_API_HOST
      )
        .then(updatedCompany => {
          setSherlockCompanyInfo(updatedCompany);
          toggleModal();
        })
        .catch(err => {
          setErrorMessage(err.error);
          toggleModal();
          setHasEditError(true);
        });
    } else {
      newSherlockUrl.current = sherlockUrl;
      toggleModal();
    }
  }

  const salesforceLink = `https://chownow.my.salesforce.com/${sherlockCompanyInfo.salesForceRecordId}`;

  return (
    <div className={styles.container}>
      <Helmet title="Sherlock" />
      <h2 className={styles.mainHeading}>Sherlock</h2>
      {!process.env.REACT_APP_SHERLOCK_API_HOST && (
        <p>Hmm... looks like Sherlock isn't ready yet.</p>
      )}
      {process.env.REACT_APP_SHERLOCK_API_HOST && (
        <>
          <div className={styles.blockContainer}>
            <h4 className={styles.subheading}>Website Information</h4>
            <ul className={styles.informationCard}>
              <li className={styles.itemRow}>
                <div className={classnames(styles.itemLabel, styles.listItem)}>
                  Website URL
                </div>
                <div className={styles.listItem}>
                  <a
                    href={sherlockCompanyInfo.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.listItemUrl}
                  >
                    {sherlockCompanyInfo.websiteUrl}
                  </a>
                  <span onClick={toggleModal} className={styles.editLink}>
                    Edit
                  </span>
                </div>
              </li>
              <li className={styles.itemRow}>
                <div className={classnames(styles.itemLabel, styles.listItem)}>
                  SalesForce Brand Record
                </div>
                <div
                  className={classnames(styles.listItem, styles.listItemUrl)}
                >
                  <a
                    href={salesforceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {salesforceLink}
                  </a>
                </div>
              </li>
              <li className={styles.itemRow}>
                <div className={classnames(styles.itemLabel, styles.listItem)}>
                  Last Checked by Sherlock
                </div>
                {sherlockCompanyInfo.siteLastCheckedDate && (
                  <div className={styles.listItem}>
                    {formatDateAndTime(sherlockCompanyInfo.siteLastCheckedDate)}
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className={styles.blockContainer}>
            <h4 className={styles.subheading}>Sherlock Stats</h4>
            <ul className={styles.informationCard}>
              <li className={styles.itemRow}>
                <div className={styles.itemLabel}>
                  Overall Status
                  <Badge
                    className={styles.badge}
                    status={setBadgeStatus(sherlockCompanyInfo.isChowNowFound)}
                  >
                    {setBadgeWording(sherlockCompanyInfo.isChowNowFound)}
                  </Badge>
                </div>
                <div className={styles.informationIcon}>
                  <InfoBlockHover className={styles.sherlockToolTip}>
                    Overall Status lets you know whether a ChowNow ordering link
                    has been found on the client site or not. If ChowNow link is
                    found, the status will be healthy. If no link is found, the
                    status will be unhealthy.
                  </InfoBlockHover>
                </div>
              </li>
              <li className={styles.itemRow}>
                <div className={styles.itemLabel}>
                  Active
                  <Badge
                    className={styles.badge}
                    status={setBadgeStatus(sherlockCompanyInfo.isActive)}
                  >
                    {setBadgeWording(sherlockCompanyInfo.isActive)}
                  </Badge>
                </div>
                <div className={styles.informationIcon}>
                  <InfoBlockHover className={styles.sherlockToolTip}>
                    Active lets you know whether a client is currently live on
                    the platform or if they are churned. If they are live, the
                    status will be healthy. If they have churned, the status
                    will be unhealthy.
                  </InfoBlockHover>
                </div>
              </li>
              <li className={styles.itemRow}>
                <div className={styles.itemLabel}>
                  Website Security
                  <Badge
                    className={styles.badge}
                    status={setBadgeStatus(sherlockCompanyInfo.isSiteSecure)}
                  >
                    {setBadgeWording(sherlockCompanyInfo.isSiteSecure)}
                  </Badge>
                </div>
                <div className={styles.informationIcon}>
                  <InfoBlockHover className={styles.sherlockToolTip}>
                    Website Security lets you know whether a client's website
                    has SSL or not. SSL is required for embeded ordering
                    experience to work. If they have SSL, the status will be
                    healthy. If they do not have SSL, the status will be
                    unhealthy.
                  </InfoBlockHover>
                </div>
              </li>
              <li className={styles.itemRow}>
                <div className={styles.itemLabel}>
                  Using ChowNow Integration Script
                  <Badge
                    className={styles.badge}
                    status={setBadgeStatus(
                      sherlockCompanyInfo.isChowNowScriptFound
                    )}
                  >
                    {setBadgeWording(sherlockCompanyInfo.isChowNowScriptFound)}
                  </Badge>
                </div>
                <div className={styles.informationIcon}>
                  <InfoBlockHover className={styles.sherlockToolTip}>
                    Using ChowNow Integration Script lets you know whether a
                    client's website has the integration script installed. The
                    script allows the embeded ordering experience on client's
                    websites. If script is found, the status will be healthy. If
                    no script is found, the status will be unhealthy.
                  </InfoBlockHover>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.dropdown} tabIndex={1} onClick={toggleHistory}>
            History
            <NextArrow
              className={classnames({
                [styles.nextArrow]: true,
                [styles.down]: isHistoryOpen,
              })}
            />
          </div>
          {isHistoryOpen && (
            <ul className={styles.informationCard}>
              <li className={classnames(styles.itemRow, styles.historyListRow)}>
                <div className={styles.listItem}>Date of Change</div>
                <div className={styles.listItem}>Active</div>
                <div className={styles.listItem}>Overall Status</div>
              </li>
              {sherlockCompanyInfo.history &&
                sherlockCompanyInfo.navigate.map((row, idx) => (
                  <li key={`row_${row.date}_${idx}`} className={styles.itemRow}>
                    <div className={styles.listItem}>
                      {formatDateAndTime(row.inspectedDate)}
                    </div>
                    <div className={styles.listItem}>
                      <Badge status={setBadgeStatus(row.isActive)}>
                        {setBadgeWording(row.isActive)}
                      </Badge>
                    </div>
                    <div className={styles.listItem}>
                      <Badge status={setBadgeStatus(row.isChowNowFound)}>
                        {setBadgeWording(row.isChowNowFound)}
                      </Badge>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
}

CompanySherlock.propTypes = {
  company: shape({
    id: string,
    name: string,
  }),
};

CompanySherlock.defaultProps = {
  company: {},
};

export default CompanySherlock;
