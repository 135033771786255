import React from 'react';
import Restaurant from 'types/restaurant';

import { ReviewAccountCardContainer, TopText, BottomText } from './styles';

interface ReviewAccountCardProps {
  restaurant: Restaurant;
}

const ReviewAccountCard = ({ restaurant }: ReviewAccountCardProps) => {
  const { id } = restaurant;
  return (
    <ReviewAccountCardContainer>
      <TopText>Stripe is reviewing your business information.</TopText>
      <BottomText data-testid="message">
        We will let you know if you need to provide additional details. You can
        resume editing or check the status of your Stripe account on the{' '}
        <a
          href={`/restaurant/${id}/settings/payment-processing`}
        >
          payment processing page
        </a>{' '}
        of your dashboard.
      </BottomText>
    </ReviewAccountCardContainer>
  );
};

export default ReviewAccountCard;
