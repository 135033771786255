import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function Outlink(props) {
  const { alt, children, dataTestId, to } = props;

  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      alt={alt}
      className={classNames(styles.link, props.className)}
      data-testid={dataTestId}
    >
      {children}
    </a>
  );
}

Outlink.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  to: PropTypes.string.isRequired
};

export default Outlink;
