import { request } from 'helpers/api';
import { useEffect, useState } from 'react';
import { MenuThirdPartyResponse } from 'types/pos/menu-third-party';

/**
 * Fetch Third Party Menu Data
 * @param {string} restaurantId - restaurant ID
 * @param {string} source - third party source (e.g. 'toast'). defaults to 'toast'
 */
export const useGetMenuThirdParty = (
  restaurantId: string | null,
  source: string
) => {
  const [
    isGetMenuThirdPartyRequestLoading,
    setIsGetMenuThirdPartyRequestLoading,
  ] = useState<boolean>(true);
  const [hasGetMenuThirdPartyRequestError, setHasGetMenuThirdPartyRequestError] =
    useState<boolean>(false);
  const [getMenuThirdPartyRequestResponse, setGetMenuThirdPartyRequestResponse] =
    useState<MenuThirdPartyResponse | null>(null);

  useEffect(() => {
    const getMenuThirdParty = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: MenuThirdPartyResponse = await request(
          `restaurant/${restaurantId}/menu-third-party?source=${source}`
        );

        setGetMenuThirdPartyRequestResponse(response);
      } catch (err) {
        setHasGetMenuThirdPartyRequestError(true);
      } finally {
        setIsGetMenuThirdPartyRequestLoading(false);
      }
    };

    if (restaurantId) {
      void getMenuThirdParty();
    }
  }, [restaurantId]);

  return {
    hasGetMenuThirdPartyRequestError,
    isGetMenuThirdPartyRequestLoading,
    getMenuThirdPartyRequestResponse,
  };
};
