import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { fontWeight } from 'styles/types';
import { screen, space } from 'styles/variables';

export const ItemName = styled.div`
  margin-bottom: ${space.x2};
  min-width: 40%;
  text-transform: capitalize;

  @media (min-width: ${screen.smMin}) {
    margin-bottom: 0;
  }
`;

export const CheckboxWrap = styled.div`
  display: inline-block;
  margin-right: ${space.x1};
`;

export const OriginalAmount = styled.div`
  color: ${semanticColors.content.secondary};
  display: inline-block;
  margin-right: ${space.x2};
  text-decoration: line-through;
`;

export const EditPrice = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

export const Amount = styled.div<{ $isSelected: boolean }>`
  display: inline-block;
  text-align: right;

  ${({ $isSelected }) =>
    $isSelected &&
    `
    color: ${semanticColors.content.active};
    font-weight: ${fontWeight.semiBold};
  `}
`;

export const DetailsWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${screen.smMin}) {
    margin-left: 5%;
    width: 100%;
  }
`;
