import React, { FC } from 'react';

import {
  RefundStatus,
  RefundType,
  OrderDetailsV6GetSuccessResponse,
} from 'types/order-details';

import { ReactComponent as UtensilsIcon } from 'images/icon-utensils.svg';
import { ReactComponent as NoteIcon } from 'images/icon-note.svg';

import {
  InfoContent,
  InfoRow,
  LeftContent,
  RightContent,
  StyledCardInfo,
} from '../../styles';

import { StyledHR, StyledPaymentMethod } from '../styles';

import PriceLine from '../PriceLine';
import { formatMoney } from '../helpers';
import {
  InstructionContainer,
  InstructionIcon,
  RevisedTotal,
  TextBlock,
} from './styles';
import {
  FEE_MESSAGES,
  REFUND_STATUS_MESSAGES,
  REFUND_TYPES,
} from './constants';

interface OrderCardProps {
  order: OrderDetailsV6GetSuccessResponse;
}

const OrderCard: FC<OrderCardProps> = ({ order }) => {
  const {
    subtotal,
    total_due,
    tips,
    delivery_fee,
    sales_tax,
    single_use_items,
    special_instructions,
    misc_fee,
    marketplace_diner_fee,
    discounts,
    refund_amount,
    refund_info,
  } = order;

  const isFullRefund = refund_info?.refund_type === RefundType.FULL;
  const hasManagedDeliveryTip = !!tips?.managed_delivery_tip.amount;
  const hasRestaurantTip = !!tips?.restaurant_tip.amount;

  return (
    <StyledCardInfo title="Refunded Order">
      <InfoContent>
        <InfoRow $isFirstElement>
          <LeftContent>Type</LeftContent>
          <RightContent>
            {REFUND_TYPES[refund_info?.refund_type || ''] || 'Refund Applied'}
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Status</LeftContent>
          <RightContent>
            {REFUND_STATUS_MESSAGES[
              refund_info?.refund_status ||
                RefundStatus.PENDING_CUSTOMER_SUPPORT
            ] || 'Refund Processing'}
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Amount</LeftContent>
          <RightContent>
            {formatMoney(-1 * Math.abs(refund_amount || 0))}
          </RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Reason</LeftContent>
          <RightContent>{refund_info?.reason}</RightContent>
        </InfoRow>
        <InfoRow>
          <LeftContent>Approved by</LeftContent>
          <RightContent>{refund_info?.name}</RightContent>
        </InfoRow>
      </InfoContent>
      {
        (single_use_items || special_instructions) && (
          <InstructionContainer>
            {single_use_items && (
              <TextBlock>
                <InstructionIcon>{React.createElement(UtensilsIcon)}</InstructionIcon>
                {single_use_items.label}
              </TextBlock>
            )}
            {special_instructions && (
              <TextBlock>
                <InstructionIcon>{React.createElement(NoteIcon)}</InstructionIcon>
                {special_instructions}
              </TextBlock>
            )}
          </InstructionContainer>
        )
      }
      {!isFullRefund &&
        order.product_source?.toLowerCase()?.indexOf('yelp') === -1 && (
          <StyledPaymentMethod customer={order.customer} />
      )}
      {!isFullRefund &&
        order.product_source?.toLowerCase()?.indexOf('yelp') !== -1 && (
          <StyledHR />
      )}
      {!isFullRefund && (
        <InfoContent>
          {!!discounts?.length && (
            <>
              {discounts.map((discount, index) => (
                <PriceLine
                  key={discount.id}
                  label="Promo"
                  amount={
                    discount.amount && discount.amount > 0
                      ? -1 * discount.amount
                      : discount.amount
                  }
                  tooltipTitle={discount.name}
                  tooltipMessage={discount.description}
                  isFirstElement={index === 0}
                />
              ))}
            </>
          )}
          <PriceLine
            label="Subtotal"
            amount={subtotal}
            isFirstElement={!discounts?.length}
          />
          <PriceLine label="Taxes" amount={sales_tax} />
          {hasManagedDeliveryTip && (
            <PriceLine
              label={tips?.managed_delivery_tip.label}
              amount={tips?.managed_delivery_tip.amount}
            />
          )}
          {hasRestaurantTip && (
            <PriceLine
              label={tips?.restaurant_tip.label}
              amount={tips?.restaurant_tip.amount}
            />
          )}
          {order.fulfill_method === 'delivery' && (
            <PriceLine
              label="Delivery Fee"
              amount={delivery_fee}
              tooltipTitle="Delivery Fee"
              tooltipMessage={
                order.managed_delivery
                  ? 'ChowNow connects restaurants to delivery partners so they can serve more diners. This fee helps them cover costs.'
                  : 'This restaurant adds this fee to support their drivers and serve more diners. 100% goes to the restaurant.'
              }
            />
          )}
          <PriceLine
            label={marketplace_diner_fee?.label}
            amount={marketplace_diner_fee?.amount}
            tooltipTitle={marketplace_diner_fee?.label}
            tooltipMessage="This fee goes to ChowNow and keeps us 100% commission-free for restaurants. That means they don't have to raise their menu prices, so you save money on your orders. It's good for you, restaurants, and us."
          />
          <PriceLine
            label={misc_fee?.label}
            amount={misc_fee?.amount}
            tooltipTitle={misc_fee?.label}
            tooltipMessage={
              FEE_MESSAGES[misc_fee?.label || ''] || FEE_MESSAGES.Gratuity
            }
          />
          {order.refund_info?.refund_type !== RefundType.FULL && (
            <RevisedTotal label="Revised Total" amount={total_due} isBold />
          )}
        </InfoContent>
      )}
    </StyledCardInfo>
  );
};

export default OrderCard;
