export enum WebsiteAccessOptions {
  RESTAURANT_PARTNER = 'restaurant_partner',
  THIRD_PARTY_DEVELOPER = 'third_party_developer',
  CHOWNOW = 'chownow',
}

export const WEBSITE_ACCESS_CHOWNOW_OPTION =
  "I'd like ChowNow to add it for me (recommended)";
export const WEBSITE_ACCESS_RESTAURANT_PARTNER_OPTION = 'I will add it myself';
export const WEBSITE_ACCESS_THIRD_PARTY_OPTION =
  'I have a web developer or 3rd party contact';

export enum WebsiteAccessBannerMessages {
  SUCCESS_MESSAGE = 'Website instructions are usually emailed within 3 business days of submitting an email address. Reach out to your account coordinator to update this information or for help.',
  SUCCESS_MESSAGE_CHOWNOW = 'This information has been submitted to our onboarding team - contact your account coordinator to make any updates.',
  ERROR_MESSAGE = 'There was an issue - please try again.',
}

export const WEBSITE_ACCESS_BANNERS = {
  restaurant_partner: WebsiteAccessBannerMessages.SUCCESS_MESSAGE,
  third_party_developer: WebsiteAccessBannerMessages.SUCCESS_MESSAGE,
  chownow: WebsiteAccessBannerMessages.SUCCESS_MESSAGE_CHOWNOW,
};
