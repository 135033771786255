import { useEffect, useState } from 'react';
import { requestCustomApi } from 'helpers/api';
import { LogoGetResponse } from 'types/logo';

const env: string = process.env.REACT_APP_ONBOARDING_API_HOST || '';

/**
 * Get logo data for Direct restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const useGetLogo = (uuid: string | null) => {
  const [isGetLogoRequestLoading, setIsGetLogoRequestLoading] =
    useState<boolean>(true);
  const [hasGetLogoRequestError, setHasGetLogoRequestError] =
    useState<boolean>(false);
  const [getLogoRequestResponse, setGetLogoRequestResponse] =
    useState<LogoGetResponse>();

  useEffect(() => {
    const getLogo = async () => {
      setIsGetLogoRequestLoading(true);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: LogoGetResponse = await requestCustomApi(
          `v1/restaurant/${uuid}/logo`,
          env,
          // TODO: remove these once requestCustomApi() is converted over to
          // TS with optional params in CN-27549
          undefined,
          undefined,
          undefined,
          undefined
        );

        // Remove the unnecessary id added by requestCustomApi
        delete response?.id;

        setGetLogoRequestResponse(response);
      } catch (err) {
        setHasGetLogoRequestError(true);
      } finally {
        setIsGetLogoRequestLoading(false);
      }
    };

    if (uuid) {
      void getLogo();
    }
  }, []);

  return {
    hasGetLogoRequestError,
    isGetLogoRequestLoading,
    getLogoRequestResponse,
  };
};

/**
 * Put logo data for Direct restaurant location
 * @param {string} uuid - ChowNow Restaurant UUID
 */
export const usePutLogo = (uuid: string | null) => {
  const [isPutLogoRequestLoading, setIsPutLogoRequestLoading] =
    useState<boolean>(false);

  /**
   * @param {boolean} hasLogo - Whether or not the restaurant has a logo
   */
  const putLogo = async (hasLogo: boolean) => {
    let hasError = false;
    setIsPutLogoRequestLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await requestCustomApi(
        `v1/restaurant/${uuid}/logo`,
        env,
        // TODO: remove these once requestCustomApi() is converted over to
        // TS with optional params in CN-27549
        'PUT',
        {
          has_logo: hasLogo,
        },
        undefined,
        undefined
      );
    } catch (err) {
      hasError = true;
    } finally {
      setIsPutLogoRequestLoading(false);
    }

    return hasError;
  };

  return {
    isPutLogoRequestLoading,
    putLogo,
  };
};
