import { getUrlParam } from 'helpers/url';
import {
  OnboardingWizardPages,
  ProgressResponse,
  StatusData,
  WizardNavPagePaths,
} from 'types/progress';

// Uncomment when new flags are added
// interface DirectFlags {
//   logo_and_food_image: boolean;
// }

export const setDirectPagesNotRequired = (
  // directFlags: DirectFlags,
  progressResponse: ProgressResponse
) => {
  const transformedResponse: ProgressResponse = { ...progressResponse };

  // Keeping code as an example for future use
  // If the Logo and Food Image flag is disabled and the user is a Direct RP (is_required is TRUE),
  // mark the page as not required to hide from users
  // if (
  //   !directFlags.logo_and_food_image &&
  //   progressResponse?.logo_and_food_image
  // ) {
  //   transformedResponse = {
  //     ...transformedResponse,
  //     logo_and_food_image: {
  //       status: progressResponse?.logo_and_food_image?.status,
  //       is_required: false,
  //       is_displayable: false,
  //       created_at: progressResponse?.logo_and_food_image?.created_at,
  //       updated_at: progressResponse?.logo_and_food_image?.updated_at,
  //     },
  //   };
  // }

  return transformedResponse;
};

export const getWizardPages = (
  progressResponse: ProgressResponse | undefined
) => {
  // Sort the required pages into the correct wizard order
  const requiredPages = Object.values(OnboardingWizardPages).filter(
    page =>
      progressResponse?.[page as keyof ProgressResponse]?.is_required &&
      progressResponse?.[page as keyof ProgressResponse]?.is_displayable
  );

  // Transform the response values to the correct page path
  const transformedPages: WizardNavPagePaths[] = [];

  requiredPages.forEach(page => {
    switch (page) {
      case OnboardingWizardPages.SHARED_MENU:
        return transformedPages.push(WizardNavPagePaths.SHARE_MENU);
      case OnboardingWizardPages.TAX_RATE:
        return transformedPages.push(WizardNavPagePaths.SALES_TAX);
      case OnboardingWizardPages.SCHEDULE:
        return transformedPages.push(WizardNavPagePaths.RESTAURANT_HOURS);
      case OnboardingWizardPages.WEBSITE_ACCESS:
        return transformedPages.push(WizardNavPagePaths.WEBSITE_ACCESS);
      case OnboardingWizardPages.LOGO_AND_FOOD_IMAGE:
        return transformedPages.push(WizardNavPagePaths.LOGO_AND_FOOD_IMAGE);
      case OnboardingWizardPages.PROMOTION:
        return transformedPages.push(WizardNavPagePaths.PROMOTION);
      case OnboardingWizardPages.STRIPE_VERIFICATION:
        return transformedPages.push(WizardNavPagePaths.PAYMENT_PROCESSING);
      default:
        return null;
    }
  });

  transformedPages.unshift(WizardNavPagePaths.WELCOME);

  return transformedPages;
};

export const getCompletedWizardPageCount = (
  progressResponse: ProgressResponse | undefined
) => {
  if (!progressResponse) {
    return 0;
  }

  const completedPages = Object.values(progressResponse).filter(
    page =>
      (page as StatusData).status === 'completed' &&
      (page as StatusData).is_displayable &&
      (page as StatusData).is_required
  );

  return completedPages.length;
};

export const getNavUrls = (
  currentPage: WizardNavPagePaths,
  wizardPages: WizardNavPagePaths[] | undefined
) => {
  const restaurantId = getUrlParam('restaurant');
  const urlPrefix = `/restaurant/${restaurantId}/onboarding/`;

  let nextUrl = '';
  let prevUrl = '';

  if (wizardPages) {
    const currentPageIndex = wizardPages.indexOf(currentPage);
    const isLastPage = currentPageIndex === wizardPages.length - 1;

    // Welcome page and first Onboarding Wizard page will never have a "Previous" button
    if (
      currentPage === WizardNavPagePaths.WELCOME ||
      (currentPageIndex === 1 && !isLastPage)
    ) {
      nextUrl = `${urlPrefix}${wizardPages[currentPageIndex + 1]}`;
      // In the case that there is only 1 wizard step (not including the WelcomeWizard page)
    } else if (currentPageIndex === 1 && isLastPage) {
      nextUrl = `/restaurant/${restaurantId}`;
    } else if (isLastPage) {
      nextUrl = `/restaurant/${restaurantId}`;
      prevUrl = `${urlPrefix}${wizardPages[currentPageIndex - 1]}`;
    } else {
      nextUrl = `${urlPrefix}${wizardPages[currentPageIndex + 1]}`;
      prevUrl = `${urlPrefix}${wizardPages[currentPageIndex - 1]}`;
    }
  }

  return {
    nextUrl,
    prevUrl,
  };
};
