import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';

const SlideUpIn = ({ children, className, delay }) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const springProps = useSpring({
    config: { tension: 400, friction: 35 },
    from: {
      transform: 'translateY(100%)'
    },
    to: { transform: 'translateY(0)' },
    onRest: () => {
      // remove transform after animation to allowed fixed position children
      setIsAnimating(false);
    },
    delay
  });

  return (
    <animated.div
      style={{
        transform: isAnimating && springProps.transform,
        opacity: springProps.opacity
      }}
      className={classNames(className)}
    >
      {children}
    </animated.div>
  );
};

SlideUpIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number
};

export default SlideUpIn;
