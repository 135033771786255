import { Button } from '@chownow/cn-web-components';
import React, { FC } from 'react';
import { Message, StyledModal, Title } from './styles';

interface FullRefundModalProps {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const FullRefundModal: FC<FullRefundModalProps> = ({
  isLoading,
  onClose,
  onConfirm,
}) => (
  <StyledModal onModalClose={onClose}>
    <Title>Confirm Refund</Title>
    <Message>
      Please confirm you would like to issue a refund. Refunds can only be
      applied once. Please allow 3-7 business days for the credit to go through.
    </Message>
    <Button
      size="medium"
      label="Confirm"
      isFullWidth
      isLoading={isLoading}
      onClick={onConfirm}
    />
  </StyledModal>
);

export default FullRefundModal;
