import { NestedRefundInterface } from '../../util';

/**
 * Recursively counts the number of refund modifiers in a nested structure (nested mods)
 * @param categories - The array of categories to count refund modifiers
 * @returns The total count of refund modifiers
 */
export const getModifierCount = (categories: NestedRefundInterface[]) => {
  let count = categories.length;
  categories.forEach(category => {
    if (category.refund_modifiers) {
      count += getModifierCount(category.refund_modifiers);
    }
  });
  return count;
};
