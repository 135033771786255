import React, { FC, Dispatch, SetStateAction } from 'react';
import { Checkbox } from '@chownow/cn-web-components';

import { NoLogoText, TopCardWrapper } from './styles';
import {
  CheckboxRow,
  CheckboxText,
  Heading,
  PreviouslySubmittedText,
  Subtext,
} from '../styles';
import FileSelector from '../FileSelector';
import { validateFilename } from '../../helpers';

interface RestaurantLogoCardProps {
  hasNoLogoFile: boolean;
  logoFile: File | undefined;
  previouslySubmittedText: string;
  setHasNoLogoFile: Dispatch<SetStateAction<boolean>>;
  setLogoFile: (file: File | undefined) => void;
}

const RestaurantLogoCard: FC<RestaurantLogoCardProps> = ({
  hasNoLogoFile,
  logoFile,
  previouslySubmittedText,
  setHasNoLogoFile,
  setLogoFile,
}) => {
  const validateLogo = (file: File | undefined) => {
    if (file) {
      return validateFilename(file);
    }
    return '';
  };

  return (
    <TopCardWrapper>
      <Heading>Restaurant logo</Heading>
      {previouslySubmittedText ? (
        <PreviouslySubmittedText>
          {previouslySubmittedText}
        </PreviouslySubmittedText>
      ) : (
        <>
          <Subtext>
            Logo file can be .ai (preferred) .jpg, .png, or .tiff.
          </Subtext>
          <FileSelector
            isDisabled={hasNoLogoFile}
            file={logoFile}
            setFile={setLogoFile}
            filesAccepted=".ai,.jpg,.png,.tiff"
            validate={validateLogo}
          >
            Upload Logo
          </FileSelector>
          <CheckboxRow>
            <Checkbox
              checked={hasNoLogoFile}
              onChange={() => setHasNoLogoFile(!hasNoLogoFile)}
              value=""
              disabled={!!logoFile}
            />
            <CheckboxText>I don&apos;t have a logo file</CheckboxText>
          </CheckboxRow>
          {hasNoLogoFile && (
            <NoLogoText>
              ChowNow will create a text based logo for you
            </NoLogoText>
          )}
        </>
      )}
    </TopCardWrapper>
  );
};

export default RestaurantLogoCard;
