import { createContext, useContext } from 'react';
import { WizardNavPagePaths } from 'types/progress';

export interface WizardNavigationContextValue {
  wizardPages: WizardNavPagePaths[];
  isWizNavProgressRequestLoading: boolean;
  hasWizNavProgressRequestError: boolean;
}

export const WizardNavigationContext = createContext<WizardNavigationContextValue>({
  wizardPages: [],
  isWizNavProgressRequestLoading: false,
  hasWizNavProgressRequestError: false,
});

export const useWizardNavigationContext = () => useContext(WizardNavigationContext);
