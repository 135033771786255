import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'images/icon-close.svg';
import { semanticColors } from 'styles/colors';
import { screen, space, zIndex } from 'styles/variables';
import { headline, label, paragraph } from 'styles/types';

export const WizardChecklistShade = styled.div`
  background-color: rgba(0, 0, 0, 0.64);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${parseInt(zIndex.modalBase, 10) - 1};
`;

export const WizardChecklistWrapper = styled.div<{ $isOpen: boolean }>`
  background-color: ${semanticColors.surface.primary};
  bottom: 0;
  box-sizing: border-box;
  color: ${semanticColors.content.primary};
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  max-width: 512px;
  overflow-y: auto;
  padding: ${space.x7} ${space.x4} 0 ${space.x4};
  position: fixed;
  right: ${({ $isOpen }) => ($isOpen ? 0 : '-513px')};
  top: 0;
  transition: right 0.5s ease;
  width: 100%;
  z-index: ${zIndex.modalBase};
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: ${space.x3};
  top: ${space.x3};
  width: ${space.x3};
`;

export const Title = styled.h2`
  font: ${headline.md.x32};
`;

export const Paragraph = styled.p`
  font: ${paragraph.reg.x14};
  margin-bottom: ${space.x5};

  /* Small devices and above (tablet, 768px and up) */
  @media (min-width: ${screen.smMin}) {
    margin-bottom: ${space.x7};
  }
`;

export const Step = styled.div`
  display: flex;
  font: ${label.md.x20};
  margin-bottom: ${space.x5};

  /* Small devices and above (tablet, 768px and up) */
  @media (min-width: ${screen.smMin}) {
    margin-bottom: ${space.x7};
  }
`;

export const StepLabel = styled.div`
  margin-left: ${space.x2};
`;
