import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from '@chownow/cn-web-utils/format';

import ListDropdown from 'components/ListDropdown';

import styles from './styles.module.scss';

export default function SquareItemModal({ item }) {
  const itemModifiers = item.modifiers || [];

  return (
    <div className={styles.squareItemModal}>
      <h1 className={styles.heading}>{item.name}</h1>
      {item.description && (
        <p className={styles.description}>{item.description}</p>
      )}
      <div className={styles.price}>{formatMoney(item.price || 0)}</div>
      {item.serving_sizes.length > 1 && (
        <ListDropdown
          label="Sizes"
          isDropdown
          itemCount={item.serving_sizes.length}
        >
          {item.serving_sizes.map(size => (
            <div className={styles.subItem} key={size.id}>
              <div>
                <div>{size.size}</div>
              </div>
              <div className={styles.price}>{formatMoney(size.price || 0)}</div>
            </div>
          ))}
        </ListDropdown>
      )}
      <ListDropdown
        label="Modifiers"
        isDropdown
        itemCount={itemModifiers.length}
      >
        {item.modifiers.map(modifier => (
          <div className={styles.subItem} key={modifier.id}>
            <div>
              <div>{modifier.name}</div>
              <div className={styles.description}>{modifier.categoryName}</div>
            </div>
            <div className={styles.price}>
              {formatMoney(modifier.price || 0)}
            </div>
          </div>
        ))}
      </ListDropdown>
    </div>
  );
}

SquareItemModal.propTypes = {
  item: PropTypes.shape()
};
