import React, { FC, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useGetToastIntegrationErrors } from 'api/posIntegrations/integrationErrors';
import {
  useGetToastDetails,
  usePutSendStopOrders,
  usePutSyncMenu,
} from 'api/posIntegrations/toast';
import { UserContext } from 'context/UserContext';
import { ModalContext } from 'context/ModalContext';
import { ToastIntegrationsErrorBannerMessages } from 'containers/Restaurant/Integrations/ToastPos/ToastDetails/constants';

import {
  Button,
  Cover,
  DashboardGrid,
  Grid,
} from '@chownow/cn-web-components';
import TextModal from 'components/Modals/TextModal';
import Banner from '@chownow/cocina-react-banner';
import ContentContainer from 'components/ContentContainer';

import { isIntegrationErrorSuccessResponse } from 'types/pos/integration-errors';
import Restaurant from 'types/restaurant';

import { THIRD_PARTY_MENU_SOURCE } from 'helpers/constants';

import ErrorState from 'containers/Restaurant/ErrorState';
import LoadingState from 'containers/Restaurant/LoadingState';
import IntegrationDetailsCardInfo from 'containers/Restaurant/Integrations/IntegrationDetailsCardInfo';
import IntegrationErrorsCard from 'containers/Restaurant/Integrations/IntegrationErrorsCard';
import Schedule from './Schedule';
import Menus from './Menus';

import { StyledSwitch, SyncMenuSwitchWrapper } from './styles';

interface ToastDetailsProps {
  restaurant: Restaurant;
}

const ToastDetails: FC<ToastDetailsProps> = ({ restaurant }) => {
  const userContext = useContext(UserContext);
  const user = userContext?.user;
  const {
    hasGetToastDetailsRequestError,
    isGetToastDetailsRequestLoading,
    getToastDetailsRequestResponse,
    updateToastDetailsRequestResponse,
    getToastDetailsRequestErrorMessage,
  } = useGetToastDetails(restaurant.id);
  const {
    getToastIntegrationErrorsRequestResponse,
    hasGetToastIntegrationErrorsRequestError,
    isGetToastIntegrationErrorsRequestLoading,
  } = useGetToastIntegrationErrors(restaurant.id);
  const { hideModal, showModal } = useContext(ModalContext);
  let location;
  let is_active: boolean | undefined;
  let updated_at: string | undefined;
  let token_expiration: string | null = null;
  let last_menu_sync_at: string | undefined;
  let is_menu_syncing_enabled: boolean | undefined = false;

  if (getToastDetailsRequestResponse) {
    ({
      location,
      is_active,
      updated_at,
      token_expiration,
      last_menu_sync_at,
      is_menu_syncing_enabled,
    } = getToastDetailsRequestResponse);
  }
  const { address, phone_number } = location || {};
  const [errorMessage, setErrorMessage] = useState('');
  const {
    hasSendStopOrdersPutRequestError,
    isSendStopOrdersPutRequestLoading,
    putSendStopOrders,
    sendStopOrdersErrorCode,
    sendStopOrdersErrorMessage,
  } = usePutSendStopOrders();
  const {
    hasSyncMenuPutRequestError,
    isSyncMenuPutRequestLoading,
    putSyncMenu,
    syncMenuPutRequestErrorMessage,
  } = usePutSyncMenu();
  const handleCloseBanner = () => setErrorMessage('');
  const hasToastMenuSoT = restaurant?.third_party_menu_source === THIRD_PARTY_MENU_SOURCE.toast;
  const coverBody = (
    <p>
      This menu is managed by Toast, please go to your{' '}
      <a href="https://www.toasttab.com/restaurants/admin/menus/homepage#" target="_blank" rel="noopener noreferrer">
        Toast Account
      </a>
      {' '}to make edits.
    </p>
  );

  const updateErrorMessage = (error: string) => {
    setErrorMessage(error);
  };

  const handleSendStopOrders = async () => {
    setErrorMessage('');
    const response = await putSendStopOrders(restaurant.id, !!is_active);
    if (response && !hasSendStopOrdersPutRequestError) {
      updateToastDetailsRequestResponse(response);
    } else if (sendStopOrdersErrorCode === '56001') {
      setErrorMessage(`${ToastIntegrationsErrorBannerMessages.ERROR_MESSAGE_CONFIG} ${sendStopOrdersErrorMessage}`);
    } else {
      setErrorMessage(sendStopOrdersErrorMessage ?? '');
    }
  };

  const handleSyncMenu = async () => {
    setErrorMessage('');
    const response = await putSyncMenu(
      restaurant.id,
      !!is_menu_syncing_enabled
    );

    if (response && !hasSyncMenuPutRequestError) {
      updateToastDetailsRequestResponse(response);
    } else {
      setErrorMessage(syncMenuPutRequestErrorMessage ?? '');
    }
  };

  useEffect(() => {
    const toastErrorMessage = sendStopOrdersErrorMessage || syncMenuPutRequestErrorMessage;

    if (toastErrorMessage) {
      if (sendStopOrdersErrorCode === '56001') {
        setErrorMessage(`${ToastIntegrationsErrorBannerMessages.ERROR_MESSAGE_CONFIG} ${toastErrorMessage}`);
      } else {
        setErrorMessage(toastErrorMessage);
      }
    }
  }, [sendStopOrdersErrorMessage, syncMenuPutRequestErrorMessage, sendStopOrdersErrorCode]);

  const onOpenErrorModal = () => {
    showModal(TextModal, {
      mobileFullView: true,
      message: errorMessage,
      onClose: hideModal,
    });
  };

  let content = (
    <>
      {!hasGetToastIntegrationErrorsRequestError &&
        isIntegrationErrorSuccessResponse(getToastIntegrationErrorsRequestResponse) && (
          <IntegrationErrorsCard
            integrationErrorResponse={
              getToastIntegrationErrorsRequestResponse
            }
          />
      )}
      <IntegrationDetailsCardInfo
        address={address}
        is_active={is_active}
        last_menu_sync_at={last_menu_sync_at}
        phone_number={phone_number}
        restaurant={restaurant}
        token_expiration={token_expiration}
        updated_at={updated_at}
        user={user}
      />
      <SyncMenuSwitchWrapper>
        <StyledSwitch
          onToggle={() => {
            void handleSyncMenu();
          }}
          isToggled={is_menu_syncing_enabled}
        />
        Sync Menus Automatically
      </SyncMenuSwitchWrapper>
      {hasToastMenuSoT &&
        <Schedule restaurant={restaurant} />}
      {hasToastMenuSoT &&
        <Menus restaurant={restaurant} updateErrorMessage={updateErrorMessage} />}
    </>
  );

  if (hasGetToastDetailsRequestError) {
    content = (
      <ErrorState errorMessage={getToastDetailsRequestErrorMessage} />
    );
  }

  const isLoading =
    isGetToastDetailsRequestLoading ||
    isSendStopOrdersPutRequestLoading ||
    isGetToastIntegrationErrorsRequestLoading ||
    isSyncMenuPutRequestLoading;

  return (
    <>
      <Helmet title="Integration Details" />
      {errorMessage && (
        <Banner variant="caution" onClose={handleCloseBanner} onViewMoreClick={onOpenErrorModal} shouldTruncate>
          {errorMessage}
        </Banner>
      )}
      {hasGetToastDetailsRequestError ? (
        // do not display Cover CTA button if there's a request error
        <Cover title="Toast" />
      ) : (
        <Cover
          title="Toast"
          body={hasToastMenuSoT ? coverBody : ''}
          ctaElement={(
            <Button
              isLoading={
                isSendStopOrdersPutRequestLoading ||
                isGetToastDetailsRequestLoading
              }
              variant="fill"
              label={is_active ? 'Pause Integration' : 'Send Orders'}
              onClick={() => {
                void handleSendStopOrders();
              }}
            />
          )}
        />
      )}
      <ContentContainer>
        {isLoading ?
          (
            <LoadingState />
          ) :
          (
            <DashboardGrid>
              <Grid md={12}>
                {content}
              </Grid>
            </DashboardGrid>
          )}
      </ContentContainer>
    </>
  );
};

export default ToastDetails;
