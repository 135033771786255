import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import PICKUP_FIXTURE from './GET-200-pickup-new';
import { ORDER_AHEAD_FIXTURE } from './GET-200-base-fixure';

const PICKUP_FUTURE_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...PICKUP_FIXTURE,
  is_order_ahead: true,
  fulfillment_times: {
    ...ORDER_AHEAD_FIXTURE,
  },
};

export default PICKUP_FUTURE_FIXTURE;
