import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { headline, letterSpacing } from 'styles/types';
import { containerWidths, screen, space } from 'styles/variables';

export const PageIntroWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;

  img {
    margin-top: ${space.x5};
  }

  h1 {
    color: ${semanticColors.content.primary};
    font: ${headline.md.x24};
    letter-spacing: ${letterSpacing.headline.x24};
    margin-top: ${space.x1};
  }

  p {
    margin-bottom: ${space.x5};
    margin-top: ${space.x1};
  }

  @media (min-width: ${screen.smMin}) {
    width: ${containerWidths.onboardingPageIntroWidth};

    img {
      margin-top: ${space.x7};
    }

    h1 {
      font: ${headline.md.x32};
      letter-spacing: ${letterSpacing.headline.x32};
      margin-top: ${space.x2};
    }

    p {
      margin-bottom: ${space.x7};
      margin-top: ${space.x2};
    }
  }
`;
