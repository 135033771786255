import { PromotionPreviouslySubmittedResponse, PromotionPutRequestBody } from 'types/promotion';

import { PromotionOptions } from '../constants';

export const getPromotionType =
  (response: PromotionPreviouslySubmittedResponse): PromotionOptions => {
    if (response.discount_type === 'percentage') {
      if (response.discount_value === 20) {
        return PromotionOptions.PERCENTAGE_20;
      } else {
        return PromotionOptions.PERCENTAGE_10;
      }
    } else if (response.discount_type === 'dollar_amount') {
      if (response.discount_value === 5) {
        return PromotionOptions.DOLLAR_AMOUNT_5;
      } else {
        // This still allows restaurants who have previously selected the $2 off promo (prior to us removing it as a selectable option)
        // see their option selected in the read only view
        return PromotionOptions.DOLLAR_AMOUNT_2;
      }
    } else {
      return PromotionOptions.NO_PROMO;
    }
  };

export const getPromotionPutData =
  (promotion: PromotionOptions): PromotionPutRequestBody => {
    if (promotion === PromotionOptions.PERCENTAGE_20) {
      return ({
        discount_type: 'percentage',
        discount_value: 20,
        minimum_subtotal: 0,
        single_use: true,
      });
    } else if (promotion === PromotionOptions.PERCENTAGE_10) {
      return ({
        discount_type: 'percentage',
        discount_value: 10,
        minimum_subtotal: 0,
        single_use: true,
      });
    } else if (promotion === PromotionOptions.DOLLAR_AMOUNT_5) {
      return ({
        discount_type: 'dollar_amount',
        discount_value: 5,
        minimum_subtotal: 20,
        single_use: true,
      });
    } else {
      return ({
        discount_type: null,
        discount_value: 0,
        minimum_subtotal: 0,
        single_use: true,
      });
    }
  };
