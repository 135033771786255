import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@chownow/cn-web-components';
import LoadingSpinner from '@chownow/cocina-react-loadingspinner';
import AddMenu from 'containers/Onboarding/ShareMenu/AddMenu';
import MenuNotes from 'containers/Onboarding/ShareMenu/MenuNotes';
import { MenuDataContext } from 'context/MenuDataContext';
import { ModalContext } from 'context/ModalContext';
import {
  useGetMenuAssets,
  usePostMenuAsset,
  usePostMenuLink,
} from 'api/onboarding';
import { ReactComponent as IconCaution } from 'images/icon-caution-unfilled.svg';
import { maxCharLimit500, urlRegex } from 'helpers/validators';

import styles from './styles.module.scss';

const ASSET_UPLOAD_DELAY = 1000;
const ERROR_MESSAGE =
  'There was an issue submitting your menu. Please try again.';
const STATUS_SUCCESS = 'uploaded';

interface ShareMenuModalProps {
  uuid: string;
  getMenuLinksAndAssets: () => void;
}

const ShareMenuModal = ({
  uuid,
  getMenuLinksAndAssets,
}: ShareMenuModalProps) => {
  const { hideModal } = useContext(ModalContext);
  const {
    menuLink,
    menuNotes,
    menuUpload,
    setMenuNotes,
    setMenuUpload,
    setMenuLink,
  } = useContext(MenuDataContext);
  const [hasFileError, setHasFileError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fetchMenuAssetCount, setFetchMenuAssetCount] = useState<number>(0);
  const {
    isGetRequestMenuAssetsLoading,
    hasGetRequestMenuAssetsError,
    getRequestMenuAssetsResponse,
    getMenuAssets,
  } = useGetMenuAssets();
  const {
    isPostMenuLinkLoading,
    hasPostMenuLinkError,
    postMenuLink,
    postMenuLinkSuccess,
    postMenuLinkError,
  } = usePostMenuLink();
  const {
    fileId,
    isPostMenuAssetLoading,
    hasPostMenuAssetError,
    postMenuAsset,
    postMenuAssetSuccess,
    postMenuAssetError,
  } = usePostMenuAsset();

  // make sure previous entries have been cleared when user opens up a modal again
  useEffect(() => {
    setMenuNotes('');
    setMenuUpload('');
    setMenuLink('');
    setHasFileError(false);
  }, []);

  useEffect(() => {
    if (
      hasPostMenuAssetError ||
      hasPostMenuLinkError ||
      hasGetRequestMenuAssetsError ||
      postMenuAssetError ||
      postMenuLinkError
    ) {
      setIsLoading(false);

      if (postMenuAssetError) {
        setErrorMessage(postMenuAssetError);
      } else if (postMenuLinkError) {
        setErrorMessage(postMenuLinkError);
      } else {
        setErrorMessage(ERROR_MESSAGE);
      }
    }
  }, [
    hasPostMenuAssetError,
    hasPostMenuLinkError,
    hasGetRequestMenuAssetsError,
    postMenuAssetError,
    postMenuLinkError,
  ]);

  useEffect(() => {
    if (postMenuLinkSuccess) {
      getMenuLinksAndAssets();
      setIsLoading(false);
      hideModal();
    }
  }, [postMenuLinkSuccess]);

  useEffect(() => {
    if (postMenuAssetSuccess) {
      void getMenuAssets(uuid);
    }
  }, [postMenuAssetSuccess]);

  const checkIfMenuAssetUploaded = () => {
    // If there's no filename match, an error may have occured between s3 and salesforce
    // display error message if the filename does not appear after 1 minute
    if (fetchMenuAssetCount < 20) {
      setTimeout(() => {
        // asset file_id must match recently uploaded file_id and have a upload_status of uploaded
        const menuLoaded = getRequestMenuAssetsResponse?.find(
          asset =>
            asset.file_id === fileId && asset.upload_status === STATUS_SUCCESS
        );

        if (menuLoaded) {
          getMenuLinksAndAssets();
          setIsLoading(false);
          hideModal();
        } else {
          setFetchMenuAssetCount(fetchMenuAssetCount + 1);
          void getMenuAssets(uuid);
        }
      }, ASSET_UPLOAD_DELAY);
    } else {
      setIsLoading(false);
      setErrorMessage(ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if (getRequestMenuAssetsResponse) {
      checkIfMenuAssetUploaded();
    }
  }, [getRequestMenuAssetsResponse]);

  const handleSubmitMenu = () => {
    setIsLoading(true);

    if (menuUpload) {
      void postMenuAsset(uuid, menuUpload, menuNotes);
    } else if (!urlRegex.test(menuLink)) {
      // FE validation for menu link to match BE validation
      setIsLoading(false);
      setErrorMessage('Invalid menu link, please enter a valid url');
    } else if (!maxCharLimit500.test(menuLink)) {
      setIsLoading(false);
      setErrorMessage(
        'Menu link has to be shorter than or equal to 500 characters'
      );
    } else {
      void postMenuLink(uuid, menuLink, menuNotes);
    }

    setMenuUpload('');
    setMenuLink('');
    setMenuNotes('');
  };

  const shouldDisableMenuNotes = !menuLink && !menuUpload;

  const loadingStatus = () => {
    if (!postMenuAssetSuccess) {
      return 'Uploading...';
    } else {
      return 'Finalizing...';
    }
  };

  if (
    isPostMenuLinkLoading ||
    isPostMenuAssetLoading ||
    isGetRequestMenuAssetsLoading ||
    isLoading
  ) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}>
          <LoadingSpinner isCentered size="x-large" />
        </div>
        <div className={styles.loadingStatus}>{loadingStatus()}</div>
      </div>
    );
  }

  return (
    <div className={styles.shareMenuModal}>
      <p className={styles.modalTitle}>Your Menu</p>
      <p>
        Menu link can be to your website or another online ordering platform
        (ex. Grubhub, UberEats, DoorDash, etc.). Menu file can be .jpg, .png,
        .tiff, .pdf, .doc, .docx, .xls, .xlsx, or .csv.
      </p>
      <AddMenu
        fileName={fileName}
        setFileName={setFileName}
        setModalErrorMessage={setErrorMessage}
        setHasFileError={setHasFileError}
      />
      <MenuNotes isDisabled={shouldDisableMenuNotes} />
      {errorMessage && (
        <div className={styles.errorMessageContainer}>
          <IconCaution className={styles.iconCaution} />
          <span className={styles.errorMessage}>{errorMessage}</span>
        </div>
      )}
      <Button
        label="Submit"
        platform="brand"
        size="small"
        variant="fill"
        disabled={shouldDisableMenuNotes || hasFileError}
        onClick={handleSubmitMenu}
      />
    </div>
  );
};

export default ShareMenuModal;
