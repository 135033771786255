import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label } from 'styles/types';
import { borderRadius, screen, space } from 'styles/variables';

export const SetHoursCardWrapper = styled.div`
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
  padding: ${space.x3};
  text-align: left;

  /* XSmall devices and above (tablet, 576px and up) */
  @media (min-width: ${screen.xsMin}) {
    padding: ${space.x5};
  }
`;

export const SetHoursHeading = styled.div`
  font: ${label.semi.x16};
  letter-spacing: 0.2px;
  margin: 0;
`;
