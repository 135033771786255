import React, { FC, PropsWithChildren, useMemo } from 'react';
import { getSearchParam } from 'helpers/url';
import { useGetProgress } from 'api/onboarding/progressApis';

import { WizardNavigationContext, WizardNavigationContextValue } from '.';
import { getWizardPages, setDirectPagesNotRequired } from '../helpers';

interface WizardNavigationContextProviderProps extends PropsWithChildren {
  uuid: string | null;
}

const WizardNavigationContextProvider: FC<
  WizardNavigationContextProviderProps
> = ({ children, uuid }) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';

  const {
    hasGetProgressRequestError,
    isGetProgressRequestLoading,
    getProgressRequestResponse,
  } = useGetProgress(uuid, !isComingFromHomepage);

  const response =
    getProgressRequestResponse &&
    setDirectPagesNotRequired(getProgressRequestResponse);

  const wizardNavigationContentValue = useMemo<WizardNavigationContextValue>(
    () => ({
      wizardPages: getWizardPages(response),
      isWizNavProgressRequestLoading: isGetProgressRequestLoading,
      hasWizNavProgressRequestError: hasGetProgressRequestError,
    }),
    [response, isGetProgressRequestLoading, hasGetProgressRequestError]
  );

  return (
    <WizardNavigationContext.Provider value={wizardNavigationContentValue}>
      {children}
    </WizardNavigationContext.Provider>
  );
};

export default WizardNavigationContextProvider;
