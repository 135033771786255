import React from 'react';
import classnames from 'classnames';

import Tooltip from 'components/Tooltip';
import { TagNotice } from '@chownow/cn-web-components';
import { formatMoney } from '@chownow/cn-web-utils/format';
import { orderDetailsType } from 'helpers/prop-types';
import { TOOLTIP_COPY } from 'helpers/modals';

import styles from './styles.module.scss';

function OrderTotals(props) {
  const { orderDetails } = props;
  const { discounts, marketplace_diner_fee } = orderDetails;

  return (
    <div className={styles.orderTotals}>
      {!!discounts.length && (
        <>
          {discounts.map(discount => {
            return (
              <div className={styles.orderTotalLine}>
                <Tooltip title={discount.name} message={discount.description}>
                  <span
                    className={classnames(styles.totalsRowTitle, styles.tip)}
                  >
                    Promo
                  </span>
                </Tooltip>
                <div>-{formatMoney(discount.amount)}</div>
              </div>
            );
          })}
        </>
      )}
      <div className={styles.orderTotalLine}>
        <span className={classnames(styles.totalsRowTitle, styles.subTotal)}>
          Subtotal
        </span>
        <div>{formatMoney(orderDetails.subtotal)}</div>
      </div>
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Taxes</span>
        <span>{formatMoney(orderDetails.sales_tax)}</span>
      </div>
      <div className={styles.orderTotalLine}>
        <Tooltip
          title={orderDetails.misc_fee.label}
          message={TOOLTIP_COPY.customLabelFee.message}
        >
          <span className={classnames(styles.totalsRowTitle, styles.tip)}>
            Service Fee
          </span>
        </Tooltip>
        <span>
          {orderDetails.misc_fee.amount
            ? formatMoney(orderDetails.misc_fee.amount)
            : '$0.00'}
        </span>
      </div>
      {orderDetails.fulfill_method === 'delivery' && (
        <div className={styles.orderTotalLine}>
          <Tooltip
            title={TOOLTIP_COPY.deliveryFee.title}
            message={TOOLTIP_COPY.deliveryFee.message}
          >
            <span className={classnames(styles.totalsRowTitle, styles.tip)}>
              Delivery Fee
            </span>
          </Tooltip>
          <span>{formatMoney(orderDetails.delivery_fee)}</span>
        </div>
      )}
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Tip</span>
        <span>
          {orderDetails.tip ? formatMoney(orderDetails.tip) : '$0.00'}
        </span>
      </div>
      {marketplace_diner_fee && (
        <div className={styles.orderTotalLine}>
          <Tooltip
            title={TOOLTIP_COPY.supportLocal.title}
            message={marketplace_diner_fee.description}
          >
            <span className={classnames(styles.totalsRowTitle, styles.tip)}>
              Support Local Restaurants
            </span>
          </Tooltip>
          <span>
            {marketplace_diner_fee.amount
              ? formatMoney(marketplace_diner_fee.amount)
              : '$0.00'}
          </span>
        </div>
      )}
      {orderDetails.credits.length > 0 && (
        <>
          {orderDetails.credits.map(credit => (
            <div className={styles.orderTotalLine} key={credit.id}>
              <span className={styles.totalsRowTitle}>{credit.name}</span>
              <span>-{formatMoney(credit.amount)}</span>
            </div>
          ))}
          <div className={styles.orderTotalLine}>
            <span className={styles.totalsRowTitle}>Customer Paid:</span>
            <span>{formatMoney(orderDetails.total_due)}</span>
          </div>
        </>
      )}
      <div className={classnames(styles.orderTotalLine, styles.finalTotal)}>
        <span className={styles.totalsRowTitle}>{`${
          orderDetails.refund_amount ? 'Revised ' : ''
        }Total`}</span>
        <span>
          {orderDetails.credits.length > 0
            ? formatMoney(orderDetails.total)
            : formatMoney(orderDetails.total_due)}
        </span>
      </div>
      {!!orderDetails.refund_amount && (
        <>
          <div
            className={classnames(styles.orderTotalLine, styles.revisedTotal)}
          >
            <div>Refunded</div>
            <div>-{formatMoney(orderDetails.refund_amount)}</div>
          </div>
          <div className={styles.infoBlock}>
            <TagNotice
              size="small"
              label="A refund has been applied to this order.
            Charges above have been updated to reflect this change."
            />
          </div>
        </>
      )}
    </div>
  );
}

OrderTotals.propTypes = {
  orderDetails: orderDetailsType,
};

export default OrderTotals;
