import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { prettifyPhoneNumber } from 'helpers/phoneNumber';

import { BottomCardWrapper, StyledInput } from './styles';
import { Heading } from '../styles';
import { WebsiteAccessOptions } from '../../constants';

interface InfoCardProps {
  email: string;
  emailError: string;
  name: string;
  nameError: string;
  phoneNumber: string;
  phoneNumberError: string;
  selectedOption: WebsiteAccessOptions | undefined;
  setEmail: Dispatch<SetStateAction<string>>;
  setEmailError: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setNameError: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setPhoneNumberError: Dispatch<SetStateAction<string>>;
  setWebsiteAdminUrl: Dispatch<SetStateAction<string>>;
  setWebsiteAdminUrlError: Dispatch<SetStateAction<string>>;
  setWebsiteAdminUsername: Dispatch<SetStateAction<string>>;
  setWebsiteAdminUsernameError: Dispatch<SetStateAction<string>>;
  setWebsiteAdminPassword: Dispatch<SetStateAction<string>>;
  setWebsiteAdminPasswordError: Dispatch<SetStateAction<string>>;
  websiteAdminUrl: string;
  websiteAdminUrlError: string;
  websiteAdminUsername: string;
  websiteAdminUsernameError: string;
  websiteAdminPassword: string;
  websiteAdminPasswordError: string;
}

const InfoCard: FC<InfoCardProps> = ({
  email,
  emailError,
  name,
  nameError,
  phoneNumber,
  phoneNumberError,
  selectedOption,
  setEmail,
  setEmailError,
  setName,
  setNameError,
  setPhoneNumber,
  setPhoneNumberError,
  setWebsiteAdminUrl,
  setWebsiteAdminUrlError,
  setWebsiteAdminUsername,
  setWebsiteAdminUsernameError,
  setWebsiteAdminPassword,
  setWebsiteAdminPasswordError,
  websiteAdminUrl,
  websiteAdminUrlError,
  websiteAdminUsername,
  websiteAdminUsernameError,
  websiteAdminPassword,
  websiteAdminPasswordError,
}) => {
  const bottomCardRef = useRef<HTMLDivElement>(null);

  let content;

  useEffect(() => {
    if (bottomCardRef?.current) {
      bottomCardRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [selectedOption]);

  const handleEmailOnChange = (inputValue: string) => {
    setEmail(inputValue);

    if (emailError) {
      setEmailError('');
    }
  };

  const handleNameOnChange = (inputValue: string) => {
    setName(inputValue);

    if (nameError) {
      setNameError('');
    }
  };

  const handlePhoneNumberOnChange = (inputValue: string) => {
    setPhoneNumber(prettifyPhoneNumber(inputValue));

    if (phoneNumberError) {
      setPhoneNumberError('');
    }
  };

  const handleWebsiteAdminUrlOnChange = (inputValue: string) => {
    setWebsiteAdminUrl(inputValue);

    if (websiteAdminUrlError) {
      setWebsiteAdminUrlError('');
    }
  };

  const handleWebsiteAdminUsernameOnChange = (inputValue: string) => {
    setWebsiteAdminUsername(inputValue);

    if (websiteAdminUsernameError) {
      setWebsiteAdminUsernameError('');
    }
  };

  const handleWebsiteAdminPasswordOnChange = (inputValue: string) => {
    setWebsiteAdminPassword(inputValue);

    if (websiteAdminPasswordError) {
      setWebsiteAdminPasswordError('');
    }
  };

  if (selectedOption === WebsiteAccessOptions.RESTAURANT_PARTNER) {
    content = (
      <>
        <Heading>
          Which email address should we send the unique coding instructions to?
        </Heading>
        <StyledInput
          errorText={emailError}
          inputMode="email"
          label="Email Address"
          name="input-email-address"
          onChange={handleEmailOnChange}
          placeholder="bob@chownow.com"
          type="email"
          value={email}
        />
      </>
    );
  } else if (selectedOption === WebsiteAccessOptions.THIRD_PARTY_DEVELOPER) {
    content = (
      <>
        <Heading>
          What is your web developer or 3rd party&apos;s contact information?
        </Heading>
        <StyledInput
          errorText={nameError}
          inputMode="text"
          label="Name"
          name="input-name"
          onChange={handleNameOnChange}
          placeholder="Bob Smith"
          value={name}
        />
        <StyledInput
          errorText={emailError}
          inputMode="email"
          label="Email Address"
          name="input-email-address"
          onChange={handleEmailOnChange}
          placeholder="bob@chownow.com"
          type="email"
          value={email}
        />
        <StyledInput
          errorText={phoneNumberError}
          inputMode="tel"
          label="Phone Number (Optional)"
          name="input-phone-number"
          onChange={handlePhoneNumberOnChange}
          placeholder="555-555-5555"
          value={phoneNumber}
        />
      </>
    );
  } else if (selectedOption === WebsiteAccessOptions.CHOWNOW) {
    content = (
      <>
        <Heading>What are your website credentials?</Heading>
        <StyledInput
          errorText={websiteAdminUrlError}
          inputMode="url"
          label="Website Admin URL"
          name="input-admin-url"
          onChange={handleWebsiteAdminUrlOnChange}
          placeholder="www.squarespace.com"
          value={websiteAdminUrl}
        />
        <StyledInput
          errorText={websiteAdminUsernameError}
          inputMode="text"
          label="Website Username"
          name="input-username"
          onChange={handleWebsiteAdminUsernameOnChange}
          placeholder="bob@chownow.com"
          value={websiteAdminUsername}
        />
        <StyledInput
          errorText={websiteAdminPasswordError}
          inputMode="text"
          label="Website Password"
          name="input-password"
          onChange={handleWebsiteAdminPasswordOnChange}
          value={websiteAdminPassword}
        />
      </>
    );
  }

  return (
    <div ref={bottomCardRef}>
      <BottomCardWrapper>{content}</BottomCardWrapper>
    </div>
  );
};

export default InfoCard;
