import { Dispatch, SetStateAction } from 'react';
import { PHONE_NUMBER_REGEX } from 'helpers/validators';

export const phoneNumberValidator = (
  phoneNumber: string,
  setPhoneNumberError: Dispatch<SetStateAction<string>>
) => {
  const hasInvalidPhoneNumber = phoneNumber
    ? !PHONE_NUMBER_REGEX.test(phoneNumber)
    : false;

  if (hasInvalidPhoneNumber) {
    setPhoneNumberError('Please enter a valid phone number');
  }

  return hasInvalidPhoneNumber;
};
