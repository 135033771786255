import React, { FC } from 'react';
import { ReactComponent as IconReset } from 'images/icon-reset-16.svg';
import { ErrorCard, ErrorMessage, RefreshPageButton } from './styles';

interface ErrorContentProps {
  buttonText: string;
  errorMessage: string;
  isLoading?: boolean;
  onClick: () => void | Promise<void>;
}

const ErrorContent: FC<ErrorContentProps> = ({
  buttonText,
  errorMessage,
  isLoading,
  onClick,
}) => {
  const handleButtonClick = () => {
    void onClick();
  };

  return (
    <ErrorCard>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <RefreshPageButton
        icon={IconReset}
        isLoading={isLoading}
        label={buttonText}
        onClick={handleButtonClick}
      />
    </ErrorCard>
  );
};

export default ErrorContent;
