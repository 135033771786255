import { Button } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { fontWeight, label } from 'styles/types';
import { borderRadius, screen, space } from 'styles/variables';

export const PartialRefundsContainer = styled.div`
  padding-bottom: 120px;
`;

export const RefundItemsSectionHeader = styled.div`
  font: ${label.semi.x16};
  margin: 0 0 ${space.x2};
`;

export const RefundFeesSectionHeader = styled.div`
  font: ${label.semi.x16};
  margin: ${space.x5} 0 ${space.x2};
`;

export const Item = styled.div`
  border: 1px solid ${semanticColors.border.primary};
  border-radius: ${borderRadius.default};
  box-sizing: border-box;
  height: unset;
  padding: ${space.x3};
  position: relative;

  @media (min-width: ${screen.smMin}) {
    padding-left: ${space.x7};
  }
`;

export const SubItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${semanticColors.border.primary};
  flex-basis: 150px;
  justify-content: space-between;
  padding: ${space.x3} 0;
  position: relative;
  width: 100%;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media (min-width: ${screen.smMin}) {
    display: flex;
  }
`;

export const Totals = styled.div`
  background-color: ${semanticColors.surface.secondary};
  border-radius: ${borderRadius.default};
  margin-top: ${space.x5};
  padding: ${space.x3};
`;

export const TotalsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RevisedRow = styled(TotalsRow)`
  border-top: 1px solid ${semanticColors.border.primary};
  color: ${semanticColors.content.active};
  font-weight: ${fontWeight.semiBold};
  margin-top: ${space.x3};
  padding-top: ${space.x3};
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${space.x3};
`;

export const CancelButton = styled(Button)`
  margin-right: ${space.x2};
`;
