import React, { FC } from 'react';
import { getSearchParam } from 'helpers/url';
import { useGetStripeVerificationUrl } from 'api/stripe';
import ErrorContent from './ErrorContent';
import {
  ActivateButton,
  ContentLeft,
  ContentRight,
  IconArrowOut,
  IconBadgeCheck,
  InfoCard,
  InfoText,
  InfoTitle,
  Status,
  StatusButtons,
  Subtext,
} from './styles';
import { StatusContentProps } from './types';

const ActiveContent: FC<StatusContentProps> = ({ restaurant, urlPath }) => {
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const {
    hasGetStripeVerificationUrlRequestError,
    isGetStripeVerificationUrlRequestLoading,
    getStripeVerificationUrl,
  } = useGetStripeVerificationUrl();

  const handleActivateClick = () => {
    const navigateToStripeVerificationUrl = async () => {
      const response = await getStripeVerificationUrl(restaurant, urlPath);

      if (response?.url) {
        window.location.href = `${response.url}${isComingFromHomepage ? '&origin=homepage' : ''}`;
      }
    };

    void navigateToStripeVerificationUrl();
  };

  if (hasGetStripeVerificationUrlRequestError) {
    return (
      <ErrorContent
        buttonText="Try again"
        errorMessage="Oops! There was an issue redirecting you to Stripe"
        isLoading={isGetStripeVerificationUrlRequestLoading}
        onClick={handleActivateClick}
      />
    );
  }

  return (
    <>
      <ContentLeft>
        <IconBadgeCheck />
        <Status>Your Stripe account is active</Status>
        <Subtext>
          You are able to accept and process orders.
          You can still make updates to your Stripe account information anytime.
        </Subtext>
        <StatusButtons>
          <ActivateButton
            isLoading={isGetStripeVerificationUrlRequestLoading}
            /** Due to the way the Button component is written we can't pass the icon
             * through the 'icon' prop because it places it on the wrong side of the text.
             */
            label={(
              <>
                <span>Manage Stripe Account</span>
                <IconArrowOut />
              </>
            )}
            onClick={handleActivateClick}
          />
        </StatusButtons>
      </ContentLeft>
      <ContentRight>
        <InfoCard>
          <InfoTitle>
            If you would like to update your banking information, please send the
            following to billing@chownow.com:
          </InfoTitle>
          <div>
            <InfoText>A voided check copy for your new bank account</InfoText>
            <InfoText>or</InfoText>
            <InfoText>
              A bank-issued document that has the routing number, account number,
              and bank name
            </InfoText>
          </div>
        </InfoCard>
      </ContentRight>
    </>
  );
};

export default ActiveContent;
