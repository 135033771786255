import React, { useContext, useState, useEffect } from 'react';
import { Route, Link, Routes, useNavigate, useParams } from 'react-router-dom';
import { DashboardGrid, Grid } from '@chownow/cn-web-components';
import CardInfo from '@chownow/cocina-react-cardinfo';
import { Helmet } from 'react-helmet-async';
import { get } from 'lodash';

import { requestOrderDetails } from 'api/restaurant';
import { ModalContext } from 'context/ModalContext';
import useErrorHandler from 'hooks/useErrorHandler';
import LoadingSpinner from 'components/LoadingSpinner';
import { prettifyPhoneNumber } from '@chownow/cn-web-utils/format';
import { CONNECTION_FAILED_MODAL_COPY } from 'helpers/modals';
import { restaurantType } from 'helpers/prop-types';

import ConnectionFailedModal from 'components/Modals/ConnectionFailedModal';
import CTABlock from './CTABlock';
import EmbeddedAdmin from 'containers/EmbeddedAdmin';
import PartialRefunds from 'containers/Restaurant/Orders/PartialRefunds';
import ListItem from './ListItem';
import OrderTotals from './OrderTotals';
import OrderInfo from './OrderInfo';
import RefundInfo from './RefundInfo';
import PaymentMethod from 'components/PaymentMethod';
import ContentContainer from 'components/ContentContainer';

import styles from './styles.module.scss';

function OrderDetails(props) {
  const { restaurant } = props;
  const { orderId } = useParams();
  const orderListRoute = `/restaurant/${restaurant.id}/orders`;
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState();
  const { showModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({
    discounts: [],
    fulfillment_times: {},
    items: [{}],
  });
  const customerId = get(orderDetails, 'customer.id');
  const deliverDetails = get(orderDetails, 'customer.delivery');

  useEffect(() => {
    fetchOrderDetails();
  }, [restaurant.id, orderStatus]);

  useErrorHandler(hasError, () => {
    showModal(ConnectionFailedModal, {
      message: CONNECTION_FAILED_MODAL_COPY.orderDetailsError.message,
      title: 'Something went wrong.',
    });
  });

  async function fetchOrderDetails() {
    setIsLoading(true);
    const orderDetails = await requestOrderDetails(orderId);

    if (!orderDetails.errors && orderDetails.restaurant.id !== restaurant.id) {
      navigate(orderListRoute);
    } else if (!orderDetails.errors) {
      setOrderDetails(orderDetails);
    } else {
      setHasError(true);
    }

    setIsLoading(false);
  }

  if (hasError) {
    return null;
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Routes>
          <Route
            path={`/customer/${customerId}`}
            element={
              <EmbeddedAdmin
                src={`customer_by_cust_id/${customerId}`}
                title="Customer Info"
                key={`${customerId}/customer_by_cust_id`}
              />
            }
          />
          <Route
            path="/partial-refunds"
            element={
              <PartialRefunds
                restaurant={restaurant}
                orderDetails={orderDetails}
              />
            }
          />
          <Route
            index
            element={
              <>
                <Helmet title="Order Details" />
                <CTABlock
                  orderDetails={orderDetails}
                  setOrderDetails={setOrderDetails}
                  setIsLoading={setIsLoading}
                  setOrderStatus={setOrderStatus}
                  orderListRoute={orderListRoute}
                />
                <ContentContainer>
                  <DashboardGrid>
                    <Grid sm={4} md={7}>
                      <CardInfo
                        title="Customer Info"
                        className={styles.infoCard}
                      >
                        <div className={styles.infoBlock}>
                          <div>
                            <div>Name</div>
                            <div>Phone Number</div>
                            <div>Email</div>
                            {deliverDetails && <div>Address</div>}
                          </div>
                          <div className={styles.infoRowBody}>
                            <Link to={`customer/${customerId}`}>
                              {`${orderDetails.customer.first_name} `}
                              {`${orderDetails.customer.last_name} `}
                            </Link>
                            <div>
                              {prettifyPhoneNumber(
                                orderDetails.customer.phone_number
                              )}
                            </div>
                            <div className={styles.infoRowBody}>
                              {orderDetails.customer.email}
                            </div>

                            {deliverDetails && (
                              <div>
                                {deliverDetails.address.formatted_address}
                              </div>
                            )}
                          </div>
                        </div>
                      </CardInfo>
                      <CardInfo title="Order Info" className={styles.infoCard}>
                        <OrderInfo orderDetails={orderDetails} />
                      </CardInfo>
                      {orderDetails.refund_info && (
                        <RefundInfo orderDetails={orderDetails} />
                      )}
                    </Grid>
                    <Grid sm={4} md={5}>
                      <CardInfo
                        title="Order Summary"
                        className={styles.infoCard}
                      >
                        {orderDetails.items.map(item => (
                          <ListItem item={item} key={item.id} />
                        ))}
                        <PaymentMethod customer={orderDetails.customer} />
                        <OrderTotals orderDetails={orderDetails} />
                      </CardInfo>
                    </Grid>
                  </DashboardGrid>
                </ContentContainer>
              </>
            }
          />
        </Routes>
      </>
    );
  }
}

OrderDetails.propTypes = {
  restaurant: restaurantType,
};

export default OrderDetails;
