import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import { TIMEOUTS } from 'helpers/constants';
import { arrayToSentence } from 'helpers/format';

import useClickDisable from 'hooks/useClickDisable';
import { CropImageContext } from 'context/CropImageContext';

import ImageCropper from './ImageCropper';

import styles from './styles.module.scss';

const MAX_IMAGE_SIZE_BYTES = 1e7; // 10MB;
const ACCEPTED_FILE_TYPES = ['.jpg', '.jpeg', '.png', '.gif'];

function ImageUploader(props) {
  const { isActive, isLoading, setIsLoading } = props;

  const [imageData, setImageData] = useState();
  const [imageError, setImageError] = useState();
  const [imageStatus, setImageStatus] = useState();

  const { image, restrictions } = useContext(CropImageContext);

  useEffect(() => {
    setTimeout(() => {
      resetUploader();
    }, TIMEOUTS.closeSideBar);
  }, [isActive]);

  function resetUploader() {
    if (imageData) {
      imageData.remove(); // remove cached image
      setImageData();
    }
    if (setImageStatus) {
      setImageStatus();
      setImageError();
    }
  }

  useClickDisable(isLoading);

  function handleChangeStatus(data, status) {
    setImageStatus(status);
    if (status === 'preparing') {
      // clear previous error on subsequent upload attempts
      setImageError();
    } else if (status === 'error_file_size') {
      setImageError(
        `File size cannot be greater than ${MAX_IMAGE_SIZE_BYTES / 1e6} MB`
      );
      // remove invalid image
      data.remove();
    } else if (status === 'rejected_file_type') {
      setImageError(
        `File type is not supported. Accepted file types include ${arrayToSentence(
          ACCEPTED_FILE_TYPES,
          'or'
        )}`
      );
    } else if (status === 'done' && !imageError) {
      setImageData(data);
    }
  }

  function handleValidate({ remove, meta: { height, width } }) {
    if (width < restrictions.minWidth || height < restrictions.minHeight) {
      setImageError(
        `Image must be at least ${restrictions.minWidth}
         x ${restrictions.minHeight}`
      );
      // remove invalid image
      remove();
      // must return true value to fail validation
      return true;
    }
  }

  return (
    <div
      className={classNames(styles.dropboxWrapper, {
        [styles.hasImage]: imageStatus === 'done' || image,
      })}
    >
      <ImageCropper
        imageData={imageData}
        imageError={imageError}
        imageStatus={imageStatus}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      {!image && (
        <Dropzone
          accept={ACCEPTED_FILE_TYPES.join(',')}
          classNames={{
            dropzone: styles.dropzone,
            inputLabel: styles.inputLabel,
            dropzoneActive: styles.dropzoneActive,
          }}
          inputContent="Upload"
          maxFiles={1}
          maxSizeBytes={MAX_IMAGE_SIZE_BYTES}
          multiple={false}
          onChangeStatus={handleChangeStatus}
          PreviewComponent={null}
          validate={handleValidate}
        />
      )}
    </div>
  );
}

ImageUploader.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
};

export default ImageUploader;
