import styled from 'styled-components';
import { space } from 'styles/variables';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { LeftContent } from '../../styles';

export const OrderItem = styled.div<{ $isLastElement?: boolean }>`
  ${({ $isLastElement }) =>
    !$isLastElement &&
    `
      border-bottom: 1px solid ${semanticColors.border.primary};
      padding-bottom: ${space.x3};
    `}
`;

export const BoldLeftContent = styled(LeftContent)`
  font: ${label.semi.x14};
`;

export const Quantity = styled.span`
  max-width: ${space.x3};
  min-width: ${space.x3};
  width: ${space.x3};
`;

export const Modifiers = styled.div`
  font: ${label.reg.x14};
  margin-left: ${space.x3};
  margin-top: ${space.x2};
`;

export const SpecialInstructions = styled.div`
  color: ${semanticColors.content.secondary};
  font: ${paragraph.italic.x14};
  margin-left: ${space.x3};
  margin-top: ${space.x2};
`;

export const ModifierCount = styled.div`
  color: ${semanticColors.content.primary};
  font: ${paragraph.reg.x14};
  margin-left: ${space.x3};
  margin-top: ${space.x2};
`;
