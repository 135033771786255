import { Modal } from '@chownow/cn-web-components';
import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { label, paragraph } from 'styles/types';
import { screen, space, zIndex } from 'styles/variables';

export const ErrorModal = styled(Modal)`
  background-color: ${semanticColors.surface.primary};
  z-index: ${zIndex.raised};

  > button {
    z-index: ${zIndex.modal};
  }

  > div {
    max-width: 600px;
    padding: 0;
  }

  @media (max-width: ${screen.xsMax}) {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: unset;

    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;
export const Heading = styled.div`
  border-bottom: 1px solid ${semanticColors.border.primary};
  padding: ${space.x3} ${space.x5};
`;

export const MenuTitle = styled.h3`
  font: ${label.semi.x20};
`;

export const DisplayText = styled.p`
  font: ${paragraph.reg.x14};
  margin: ${space.x1} 0 0 0;
`;

export const Content = styled.div`
  overflow-y: auto;
  padding: ${space.x3} ${space.x5};

  @media (min-width: ${screen.smMin}) {
    max-height: 426px;
  }

  td {
    word-break: break-word;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${semanticColors.border.primary};
  display: flex;
  flex-flow: column;
  padding: ${space.x2};
`;
