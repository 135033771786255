import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParam, getUrlParam } from 'helpers/url';
import { WizardNavPagePaths } from 'types/progress';
import { getNavUrls } from '../../helpers';
import { useWizardNavigationContext } from '../../WizardNavigationContext';
import PageFooterBase from '../PageFooterBase';
import { ActiveButtonTypes } from '../types';

interface PageFooterPaymentProcessingProps {
  isFormCompleted: boolean;
}

const PageFooterPaymentProcessing: React.FC<
  PageFooterPaymentProcessingProps
> = ({ isFormCompleted }) => {
  const [nextUrl, setNextUrl] = useState<string>('');
  const [prevUrl, setPrevUrl] = useState<string>('');
  const restaurantId = getUrlParam('restaurant');
  const isComingFromHomepage = getSearchParam('origin') === 'homepage';
  const homepageUrl = `/restaurant/${restaurantId}`;
  const navigate = useNavigate();
  const { wizardPages } = useWizardNavigationContext();

  useEffect(() => {
    if (isComingFromHomepage) {
      setNextUrl(homepageUrl);
      setPrevUrl('');
    } else if (wizardPages && wizardPages.length > 1) {
      const { nextUrl: nextWizardUrl, prevUrl: prevWizardUrl } = getNavUrls(
        WizardNavPagePaths.PAYMENT_PROCESSING,
        wizardPages
      );

      setNextUrl(nextWizardUrl);
      setPrevUrl(prevWizardUrl);
    }
  }, [isComingFromHomepage, wizardPages]);

  const handleClickPrevious = () => {
    navigate(prevUrl);
  };

  const handleClickSkip = () => {
    navigate(nextUrl);
  };

  const hasPrevUrl = prevUrl.length > 0;

  const activeButtons: ActiveButtonTypes[] = [];

  if (isComingFromHomepage) {
    activeButtons.push('cancel');
    if (isFormCompleted) {
      activeButtons.push('done');
    }
  } else {
    if (hasPrevUrl) {
      activeButtons.push('previous');
    }
    if (!isFormCompleted) {
      activeButtons.push('skip for now link');
    } else {
      activeButtons.push('done');
    }
  }

  return (
    <PageFooterBase
      activeButtons={activeButtons}
      hasPrevUrl={hasPrevUrl}
      isFormCompleted={isFormCompleted}
      onPreviousClick={handleClickPrevious}
      onNextClick={handleClickSkip}
      nextUrl={nextUrl}
    />
  );
};

export default PageFooterPaymentProcessing;
