import React, { ChangeEventHandler, useEffect, useState } from 'react';

import { indexOf, noop } from 'lodash';
import { VALID_REFUND_AMOUNT, VALID_REFUND_PERCENT } from 'helpers/validators';
import { Error, ErrorSection, InputContainer, StyledInput } from './styles';

export interface RefundByAmountInputProps {
  amountType: '%' | '$';
  /** Error message to display */
  errorText?: string;
  /** Callback function when input is changed. */
  onChange?: (value: number) => void;
}

const RefundByAmountInput = ({
  amountType,
  errorText,
  onChange = noop,
}: RefundByAmountInputProps) => {
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const inputEl = document.getElementById(
      'refund-by-amount-input'
    ) as HTMLInputElement;
    inputEl?.focus();
    if (amountType === '%' && inputEl.selectionEnd === amount.length) {
      inputEl.setSelectionRange(amount.length - 1, amount.length - 1);
    } else if (amountType === '$' && inputEl.selectionEnd === 0) {
      inputEl.setSelectionRange(1, 1);
    }
  }, [amount]);

  useEffect(() => {
    setAmount('');
    onChange(0);
  }, [amountType]);

  const onAmountChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    let parsedValue = value;
    if (indexOf(value, '$') !== -1 || indexOf(value, '%') !== -1) {
      parsedValue =
        amountType === '%'
          ? value.substring(0, value.length - 1)
          : value.substring(1);
    }
    if (
      (amountType === '$' && VALID_REFUND_AMOUNT.test(parsedValue)) ||
      (amountType === '%' && VALID_REFUND_PERCENT.test(parsedValue)) ||
      parsedValue === '' ||
      parsedValue === '.'
    ) {
      const num = parseFloat(parsedValue);
      onChange(Number.isNaN(num) ? 0 : num);
      if (parsedValue !== '') {
        if (amountType === '%') {
          parsedValue += '%';
        } else {
          parsedValue = `$${parsedValue}`;
        }
      }
      setAmount(parsedValue);
    } else {
      // Putting in a setTimeout so that this runs after the input display updates (Event Queue clears)
      setTimeout(() => {
        const inputEl = document.getElementById(
          'refund-by-amount-input'
        ) as HTMLInputElement;
        inputEl?.focus();
        if (amountType === '%' && inputEl.selectionEnd === amount.length) {
          inputEl.setSelectionRange(amount.length - 1, amount.length - 1);
        } else if (amountType === '$' && inputEl.selectionEnd === 0) {
          inputEl.setSelectionRange(1, 1);
        }
      }, 0);
    }
  };

  return (
    <InputContainer>
      <StyledInput
        $hasError={!!errorText}
        id="refund-by-amount-input"
        onChange={onAmountChange}
        placeholder={amountType === '%' ? '0%' : '$0.00'}
        type="text"
        value={amount}
        aria-label="refund-by-amount-input"
      />
      {!!errorText && (
        <ErrorSection>
          <Error id="refund-by-amount-input-help-text">{errorText}</Error>
        </ErrorSection>
      )}
    </InputContainer>
  );
};

export default RefundByAmountInput;
