import styled from 'styled-components';
import { semanticColors } from 'styles/colors';
import { borderRadius, space } from 'styles/variables';
import { label } from 'styles/types';
import { Button } from '@chownow/cn-web-components';

export const ErrorContent = styled.div`
  align-items: center;
  background-color: ${semanticColors.surface.primary};
  border-radius: ${borderRadius.small};
  display: flex;
  flex-direction: column;
  margin-bottom: 142px;
  padding: ${space.x5} 10px;
`;

export const ErrorMessage = styled.p`
  color: ${semanticColors.content.primary};
  font: ${label.reg.x16};
  margin: 0;
`;

export const RefreshButton = styled(Button)`
  margin-top: ${space.x3};
`;
