import React from 'react';
import styles from './styles.module.scss';

const Terms = () => (
  <div className={styles.terms}>
    {`By logging in you are agreeing to our `}
    <a href={'https://get.chownow.com/registermyresto'} target="_blank">
      Restaurant Agreement
    </a>
    {` and `}
    <a href={'https://get.chownow.com/privacy-policy'} target="_blank">
      Privacy Policy
    </a>
    .
  </div>
);

export default Terms;
