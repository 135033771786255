import React, { FC, useState } from 'react';
import { RadioButton } from '@chownow/cn-web-components';
import { useUpdateOrderStatus } from 'api/orders/orderDetails';
import {
  formatOrderDate,
  formatOrderTime,
  formatRequestedTimeWithOffset,
} from 'helpers/dateTime';

import { StyledModal, Subtitle, Title } from '../styles';
import {
  AcceptButton,
  AcceptButtonTopDivider,
  BottomContent,
  ButtonGroup,
  ButtonGroupExtraTime,
  ContentContainer,
  TitleDivider,
  TopContent,
} from './styles';

interface AcceptOrderAheadModalProps {
  onClose: () => void;
  orderId: string | undefined;
  onError: (message: string) => void;
  onSuccess: () => Promise<void>;
  requestedFulfillTime: string;
}

const etaOptions = ['10', '15', '30'];

const AcceptOrderAheadModal: FC<AcceptOrderAheadModalProps> = ({
  onClose,
  orderId,
  onError,
  onSuccess,
  requestedFulfillTime,
}) => {
  const [selectedETAOption, setSelectedETAOption] = useState<
    string | 'default'
  >('default');
  const [timeSelection, setTimeSelection] = useState<'default' | 'add'>(
    'default'
  );
  const { isUpdateOrderStatusRequestLoading, updateOrderStatus } =
    useUpdateOrderStatus();

  const formattedRequestedFulfillDate = formatOrderDate(requestedFulfillTime);
  const formattedRequestedFulfillTime = formatOrderTime(requestedFulfillTime);

  const handleETAOptionChange = (option: string) => {
    setSelectedETAOption(option);
    if (etaOptions.includes(option)) {
      setTimeSelection('add');
    }
  };

  const handleTimeSelectionChange = (selection: 'default' | 'add') => {
    setTimeSelection(selection);
    if (selection === 'default') {
      setSelectedETAOption('default');
    } else {
      setSelectedETAOption('');
    }
  };

  const handleAcceptOrder = async (eta: string) => {
    if (!orderId) return;
    const parsedETA = eta === 'default' ? 0 : parseInt(eta, 10);
    const errorMessage = await updateOrderStatus(orderId, 'accept', parsedETA);

    if (errorMessage) {
      onError(errorMessage);
    } else {
      await onSuccess();
    }
  };

  const handleOnAccept = () => {
    void handleAcceptOrder(selectedETAOption);
  };

  return (
    <StyledModal onModalClose={onClose}>
      <ContentContainer>
        <TopContent>
          <Title>Confirm Order Time</Title>
          <TitleDivider />
          <Subtitle>Confirm future order with requested time</Subtitle>
          <ButtonGroup>
            <RadioButton
              checked={selectedETAOption === 'default'}
              label={`${formattedRequestedFulfillDate}`}
              name="time-selection-radio-button"
              onChange={() => handleTimeSelectionChange('default')}
              sublabel={`${formattedRequestedFulfillTime}`}
              value="default"
            />
            <RadioButton
              checked={timeSelection === 'add'}
              label="Add more time"
              name="time-selection-radio-button"
              onChange={() => handleTimeSelectionChange('add')}
              value="add"
            />
          </ButtonGroup>
          {timeSelection === 'add' && (
            <>
              <Subtitle>Select extra time</Subtitle>
              <ButtonGroupExtraTime>
                {etaOptions.map(option => (
                  <RadioButton
                    checked={selectedETAOption === option}
                    key={`${option}-minutes-eta`}
                    label={`+${option} mins`}
                    name="eta-radio-button"
                    onChange={() => handleETAOptionChange(option)}
                    sublabel={`${formatRequestedTimeWithOffset(
                      requestedFulfillTime,
                      option
                    )}`}
                    value={option}
                  />
                ))}
              </ButtonGroupExtraTime>
            </>
          )}
        </TopContent>
        <BottomContent>
          <AcceptButtonTopDivider />
          <AcceptButton
            label="Accept"
            onClick={handleOnAccept}
            disabled={!selectedETAOption && timeSelection === 'add'}
            isLoading={isUpdateOrderStatusRequestLoading}
          />
        </BottomContent>
      </ContentContainer>
    </StyledModal>
  );
};

export default AcceptOrderAheadModal;
