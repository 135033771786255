import {
  MenuAssetsResponse,
  MenuLinksResponse,
  UploadStatus,
} from 'types/share-menu';

export const getMenuAssetsSuccess = (
  menuAssetsResponse: MenuAssetsResponse | undefined
) =>
  menuAssetsResponse?.filter(
    asset => asset.upload_status === UploadStatus.UPLOADED
  );

export const getMenuItemsCount = (
  menuAssetsResponse: MenuAssetsResponse | undefined,
  menuLinksResponse: MenuLinksResponse | undefined
) => {
  // filter out returned menu items that are not uploaded upload_status
  const menuAssetsSuccessCount = menuAssetsResponse
    ? getMenuAssetsSuccess(menuAssetsResponse)?.length
    : 0;
  const menuLinksCount = menuLinksResponse?.length ?? 0;

  return (menuAssetsSuccessCount || 0) + menuLinksCount;
};
