import React from 'react';

import { formatMoney } from '@chownow/cn-web-utils/format';
import { orderDetailsType } from 'helpers/prop-types';

import styles from './styles.module.scss';

function OrderTotals(props) {
  const { orderDetails } = props;
  const membershipDiscount = orderDetails.discounts.some(
    discount => discount.name
  );
  const membershipDiscountName = orderDetails.discounts.map(
    discount => discount.name
  );
  const membershipDiscountAmount = orderDetails.discounts.map(discount =>
    formatMoney(discount.amount)
  );
  const hasRefund = !!orderDetails.refund_amount;

  return (
    <div className={styles.orderTotals}>
      {membershipDiscount && (
        <>
          <div className={styles.orderTotalLine}>
            <span className={styles.totalsRowTitle}>Item total:</span>
            <div>{formatMoney(orderDetails.item_total)}</div>
          </div>
          <div className={styles.orderTotalLine}>
            <span className={styles.totalsRowTitle}>
              {membershipDiscountName}:
            </span>
            <span>-{membershipDiscountAmount}</span>
          </div>
        </>
      )}
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Subtotal:</span>
        <div>{formatMoney(orderDetails.subtotal)}</div>
      </div>
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Taxes:</span>
        <span>{formatMoney(orderDetails.sales_tax)}</span>
      </div>
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>
          {orderDetails.misc_fee.label}:
        </span>
        <span>
          {orderDetails.misc_fee.amount
            ? formatMoney(orderDetails.misc_fee.amount)
            : '$0.00'}
        </span>
      </div>
      {orderDetails.fulfill_method === 'delivery' && (
        <div className={styles.orderTotalLine}>
          <span className={styles.totalsRowTitle}>Delivery Fee:</span>
          <span>{formatMoney(orderDetails.delivery_fee)}</span>
        </div>
      )}
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Tip:</span>
        <span>
          {orderDetails.tip ? formatMoney(orderDetails.tip) : '$0.00'}
        </span>
      </div>
      <div className={styles.orderTotalLine}>
        <span className={styles.totalsRowTitle}>Total:</span>
        <span>
          {orderDetails.credits.length > 0
            ? formatMoney(orderDetails.total)
            : formatMoney(orderDetails.total_due)}
        </span>
      </div>
      {orderDetails.credits.length > 0 && (
        <>
          {orderDetails.credits.map(credit => (
            <div className={styles.orderTotalLine} key={credit.id}>
              <span className={styles.totalsRowTitle}>{credit.name}:</span>
              <span>-{formatMoney(credit.amount)}</span>
            </div>
          ))}
          <div className={styles.orderTotalLine}>
            <span className={styles.totalsRowTitle}>Customer Paid:</span>
            <span>{formatMoney(orderDetails.total_due)}</span>
          </div>
        </>
      )}
      {hasRefund && (
        <div className={styles.orderRefunds}>
          This order has been refunded for{' '}
          {formatMoney(orderDetails.refund_amount)}, which may be why actual
          totals do not reflect totals implied by items on the order.
        </div>
      )}
    </div>
  );
}

OrderTotals.propTypes = {
  orderDetails: orderDetailsType,
};

export default OrderTotals;
