import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { get } from 'lodash';
import {
  useNavigate,
  useParams,
  Routes,
  Navigate,
  Route,
} from 'react-router-dom';

import Whoops from 'components/Whoops';
import Layout from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import DialogModal from 'components/Modals/DialogModal';
import withUser from 'components/WithUser';
import Sherlock from 'containers/Company/Sherlock';
import EmbeddedAdmin from 'containers/EmbeddedAdmin';
import OrdersCompany from 'containers/Company/OrdersCompany';
import Customer from 'containers/Customer';
import RestaurantRedirect from 'containers/RestaurantRedirect';

import { request } from 'helpers/api';
import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';
import styles from './styles.module.scss';

const Company = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState({});
  const { showModal } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const { companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user.company_id !== companyId &&
      !user.is_super_admin &&
      !user.is_chownow_admin
    ) {
      navigate('/unauthorized');
      return;
    }

    // Load the Company details
    request(`company/${companyId}`, null, 'GET', null, null, null, '4.0')
      .then(company => {
        /**
         * In the future if the API returns location status, may need to check user role against this and
         * redirect non-CN Admin users to Pending page if location is in "Onboarding" status.
         * See CN-7214 / CN-7312 for details.
         */
        setCompany(company);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        // show different messaging if company id in url is not valid
        const message = isNaN(companyId)
          ? 'Invalid company ID, please check your url or company ID and try again.'
          : 'An error occurred loading the company information. Please refresh to try again.';
        showModal(DialogModal, {
          message: message,
          onClose: () => {
            navigate(`/`);
          },
          title: 'Something went wrong',
          confirmLabel: 'Return to Dashboard',
        });
      });
  }, [window.location.pathname]);

  let content;
  if (isLoading) {
    content = <LoadingSpinner />;
  } else if (!get(company, 'id')) {
    content = (
      <div className={styles.whoopsContainer}>
        <Whoops message="Invalid company ID, please check your url or company ID and try again." />
      </div>
    );
  }

  return (
    <>
      <Layout company={company} restaurant={null}>
        {content || (
          <Routes>
            <Route
              path="/"
              element={<Navigate to={`/company/${companyId}/orders`} replace />}
            />
            <Route
              exact
              path="/sherlock"
              element={<Sherlock company={company} />}
            />
            <Route
              path="/restaurant/:restaurantId/*"
              element={<RestaurantRedirect />}
            />
            <Route
              path="/orders"
              element={<OrdersCompany company={company} />}
            />

            {/* ADMIN REPORT */}
            <Route
              path="/reports/customer-spending"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}/report/customer_orders_report`}
                  title="Customer Spending"
                  key="Customer Spending"
                />
              }
            />
            <Route
              path="/reports/accounting"
              element={
                <EmbeddedAdmin
                  src="report/disbursements_v2"
                  title="Accounting"
                  key="Accounting"
                />
              }
            />
            <Route
              path="/reports/promos"
              element={
                <EmbeddedAdmin
                  src="report/promotions"
                  title="Promos"
                  key="Promos"
                />
              }
            />
            <Route
              path="/reports/order-source"
              element={
                <EmbeddedAdmin
                  src="report/transaction_source"
                  title="Order Source"
                  key="Order Source"
                />
              }
            />
            <Route
              path="/reports/order-overview"
              element={
                <EmbeddedAdmin
                  src="report/orders"
                  title="Order Overview"
                  key="Order Overview"
                />
              }
            />
            <Route
              path="/reports/emailed"
              element={
                <EmbeddedAdmin
                  src="report/email"
                  title="Emailed"
                  key="Emailed"
                />
              }
            />

            {/* ADMIN SETTINGS */}
            <Route
              path="/settings/hq-info"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}`}
                  title="HQ Info"
                  key="HQ Info"
                />
              }
            />
            <Route
              path="/settings/locations"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}/restaurants`}
                  title="Locations"
                  key="Locations"
                />
              }
            />
            <Route
              path="/settings/integrations"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}/integration`}
                  title="Integrations"
                  key="Integrations"
                />
              }
            />
            <Route
              path="/settings/users"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}/users`}
                  title="Users"
                  key="Users"
                />
              }
            />

            {/* ADMIN CN INTERNAL */}
            <Route
              path="/cn-internal/push-notifications"
              element={
                <EmbeddedAdmin
                  src={`company/${company.id}/push_notification`}
                  title="Push Notifications"
                  key="Push Notifications"
                />
              }
            />
            <Route
              path="/cn-internal/restaurant-categories"
              element={
                <EmbeddedAdmin
                  src="restaurant/categories"
                  title="Restaurant Categories"
                  key="Restaurant Categories"
                />
              }
            />
            <Route path="/customer/:customer_id" element={<Customer />} />
          </Routes>
        )}
      </Layout>
    </>
  );
};

export default withUser(Company, true);
