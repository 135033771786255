import { SalesTaxResponse } from 'types/sales-tax';

// Backend sets default value of 99 for newly created restaurants.
export const getSalesTax = (response: SalesTaxResponse | undefined): string => {
  if (!response?.sales_tax || response?.sales_tax === 99) {
    return '';
  } else {
    return response?.sales_tax.toString();
  }
};

export const afterDecimal = (num: number) => {
  if (Number.isInteger(num)) {
    return 0;
  }

  return num.toString().split('.')[1].length;
};

export const formatSalesTax = (value: string) => {
  const numValue = parseFloat(value);
  const decimalCount = afterDecimal(numValue);

  if (decimalCount > 3) {
    return +numValue.toFixed(3);
  }

  return numValue;
};
