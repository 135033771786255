import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { ModalContext } from 'context/ModalContext';

import { Button } from '@chownow/cn-web-components';
import { ReactComponent as IconClose } from 'images/icon-close.svg';

import styles from './styles.module.scss';

interface DialogModalProps {
  buttonColor?: string;
  cancelLabel?: string;
  children?: React.ReactNode;
  className?: string;
  closeIcon?: boolean;
  confirmLabel?: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  message?: string;
  onClose?: (didConfirm: boolean) => void;
  title?: string;
}

const DialogModal: FC<DialogModalProps> = ({
  className,
  dataTestId,
  icon,
  title,
  message,
  buttonColor,
  onClose,
  closeIcon,
  confirmLabel,
  cancelLabel,
  children,
}) => {
  const { hideModal } = useContext(ModalContext);

  const createMarkup = (text: string) => ({ __html: sanitize(text) });

  const handleOnClose = (didConfirm: boolean) => {
    if (onClose) {
      onClose(didConfirm);
    }
    hideModal();
  };

  return (
    <div
      className={classNames(styles.dialogModal, className, {
        [styles.extraSpace]: closeIcon,
      })}
    >
      {closeIcon && (
        <IconClose
          className={styles.iconClose}
          onClick={() => handleOnClose(false)}
        />
      )}
      {icon && <div className={styles.icon}>{icon}</div>}
      {title && <h1 className={styles.title}>{title}</h1>}
      {message && (
        <p
          className={styles.message}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(message)}
          data-testid={dataTestId}
        />
      )}
      {children}
      <Button
        className={classNames(styles.confirmLabel, {
          // this is a style change for the interim until we switch all basil modals to black
          [styles.confirmLabelBlack]: buttonColor === 'black',
        })}
        onClick={() => handleOnClose(true)}
        label={confirmLabel || 'OK'}
      />
      {cancelLabel && (
        <Button
          variant="outline"
          onClick={() => handleOnClose(false)}
          label={cancelLabel}
        />
      )}
    </div>
  );
};

export default DialogModal;
