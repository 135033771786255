import { minBy, find } from 'lodash';

/*
Square item's do not contain a default size, like CN items. Instead we define
the square item default size by the item with the lowest 'display_order'.
If `display_order` is only null, we take the first size instead.
*/
export function findDefaultSize(item) {
  if (item.serving_sizes && item.serving_sizes.length) {
    return minBy(item.serving_sizes, 'display_order') || item.serving_sizes[0];
  } else {
    return item;
  }
}

/*
  In the `x_id` prop, the first part of the string corresponds to the linked
  Square Item Id. The second part is the linked default size.
  */
export function findSquareItemId(item) {
  return item.x_id ? item.x_id.split('_')[0] : null;
}

/*
To get a linked catalog item we have to check to see if an item is
linked (contains x_id) and all sizes and modifiers are linked (no x_id: null).
*/
export function getLinkedItems(CNItems) {
  let linkedItems;
  linkedItems = CNItems.filter(item => {
    const sizesAndModifiers = [...item.serving_sizes, ...item.modifiers];
    return item.x_id && !find(sizesAndModifiers, { x_id: null });
  });
  return linkedItems;
}

/*
To get a partially linked catalog item we have to check to see if an item is
linked (contains x_id) and it has to have at least 1 size or modifier unlinked.
*/
export function getPartialItems(CNItems) {
  let partialItems;
  partialItems = CNItems.filter(item => {
    const sizesAndModifiers = [...item.serving_sizes, ...item.modifiers];
    return item.x_id && find(sizesAndModifiers, { x_id: null });
  });
  return partialItems;
}
