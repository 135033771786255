import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ModalContext } from 'context/ModalContext';
import { ORDER_ACTION } from 'helpers/constants';
import { getOrderDate, getOrderTime } from 'helpers/dateTimeOld';
import { isToday } from 'date-fns';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { Formik, Form, Field } from 'formik';
import { orderAheadValidator } from 'helpers/validators';

import { ReactComponent as IconClose } from 'images/icon-close.svg';
import { Button } from '@chownow/cn-web-components';

import styles from './styles.module.scss';

function AcceptOrderAheadModal(props) {
  const { acceptOrCancelOrder, requestedFulfillTime } = props;
  const { hideModal } = useContext(ModalContext);
  const orderFulfillDate = getOrderDate(requestedFulfillTime);
  const orderFulfillTime = getOrderTime(requestedFulfillTime);
  const formInitialValues = {
    confirmOrderTime: '',
    extraTime: '',
    time: '',
  };

  function acceptOrder(time) {
    acceptOrCancelOrder(ORDER_ACTION.accept, time);
    hideModal();
  }

  function isFulfillToday() {
    const formattedOrderFulfillDate =
      getDateForApiDateStr(requestedFulfillTime);
    const isFulfillToday = isToday(formattedOrderFulfillDate);

    if (isFulfillToday) {
      return 'Today';
    } else {
      return orderFulfillDate;
    }
  }

  return (
    <div className={styles.acceptOrderAheadModal}>
      <button className={styles.iconClose} onClick={() => hideModal()}>
        <IconClose />
      </button>
      <h3 className={styles.modalTitle}>Confirm order time</h3>
      <Formik
        validate={orderAheadValidator}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={formInitialValues}
        onSubmit={values => {
          acceptOrder(values.time);
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {errors && (
              <div className={styles.errorMessage}>{errors.orderAhead}</div>
            )}

            <div role="group" aria-labelledby="preset-time-group">
              <div>
                <Field
                  id="accept"
                  type="radio"
                  name="confirmOrderTime"
                  value="accept"
                  checked={values.confirmOrderTime === 'accept'}
                  onClick={() => setFieldValue('time', '0')}
                />
                <label htmlFor="accept" className={styles.label}>
                  {`This order will be ready ${isFulfillToday()} at ${orderFulfillTime}`}
                </label>
              </div>
              <div>
                <Field
                  id="extraTime"
                  type="radio"
                  name="confirmOrderTime"
                  value="extraTime"
                  checked={values.confirmOrderTime === 'extraTime'}
                  onClick={() => setFieldValue('time', '')}
                />
                <label htmlFor="extraTime" className={styles.label}>
                  Add extra time
                </label>
              </div>
            </div>

            <div role="group" aria-labelledby="preset-time-group">
              <div className={styles.subTitle}>
                We'll let the customer know you need more time
              </div>
              <fieldset
                className={styles.timeFields}
                disabled={values.confirmOrderTime !== 'extraTime'}
              >
                <div className={styles.timeButtons}>
                  <Field
                    id="15"
                    type="radio"
                    name="time"
                    value="15"
                    checked={values.time === '15'}
                  />
                  <label htmlFor="15" className={styles.timeLabel}>
                    <div className={styles.time}>15</div>
                    <div className={styles.mins}>Mins</div>
                  </label>
                  <Field
                    id="30"
                    type="radio"
                    name="time"
                    value="30"
                    checked={values.time === '30'}
                  />
                  <label htmlFor="30" className={styles.timeLabel}>
                    <div className={styles.time}>30</div>
                    <div className={styles.mins}>Mins</div>
                  </label>
                  <Field
                    id="45"
                    type="radio"
                    name="time"
                    value="45"
                    checked={values.time === '45'}
                  />
                  <label htmlFor="45" className={styles.timeLabel}>
                    <div className={styles.time}>45</div>
                    <div className={styles.mins}>Mins</div>
                  </label>
                  <Field
                    id="60"
                    type="radio"
                    name="time"
                    value="60"
                    checked={values.time === '60'}
                  />
                  <label htmlFor="60" className={styles.timeLabel}>
                    <div className={styles.time}>60</div>
                    <div className={styles.mins}>Mins</div>
                  </label>
                  <Field
                    id="90"
                    type="radio"
                    name="time"
                    value="90"
                    checked={values.time === '90'}
                  />
                  <label htmlFor="90" className={styles.timeLabel}>
                    <div className={styles.time}>90</div>
                    <div className={styles.mins}>Mins</div>
                  </label>
                </div>
              </fieldset>
            </div>

            <Button
              type="submit"
              className={styles.button}
              disabled={!values.time}
              label={'Accept Order'}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

AcceptOrderAheadModal.propTypes = {
  acceptOrCancelOrder: PropTypes.func,
  requestedFulfillTime: PropTypes.string,
};

export default AcceptOrderAheadModal;
