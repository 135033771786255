import { Button } from '@chownow/cn-web-components';
import Input from 'components/Input';
import { EMAIL_VALIDATION_REGEX } from '@chownow/cn-web-utils/validation';
import { resetPassword } from 'api/login';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { getSearchParam } from 'helpers/url';

import styles from './styles.module.scss';

const ActivateAccount = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const redirectUri = getSearchParam('next');
  const prefilledEmail = getSearchParam('email');
  const activateAccountRedirectUri = redirectUri
    ? `&next=${encodeURIComponent(redirectUri)}`
    : '';

  useEffect(() => {
    prefilledEmail && setEmail(prefilledEmail);
  }, []);

  const handleResetPassword = async () => {
    const response = await resetPassword(email, redirectUri, true);
    setIsLoading(true);

    if (response.errors) {
      setIsLoading(false);
      setEmailError('Sorry, an error has occurred. Please try again.');
    } else if (!response.errors) {
      setIsLoading(false);
      navigate(
        `/login/activate-account-credentials?email=${encodeURIComponent(
          prefilledEmail ? prefilledEmail : email
        )}${activateAccountRedirectUri}`
      );
    }
  };

  const handleOnLoginClick = e => {
    e.preventDefault(); // do not refresh on submit

    if (!email) {
      setEmailError('Email is required.');
    } else if (!EMAIL_VALIDATION_REGEX.test(email)) {
      setEmailError('Invalid email address.');
    } else {
      handleResetPassword();
    }
  };

  return (
    <>
      <Helmet title="Login" />
      <div className={styles.main}>
        <h1 className={styles.header}>Activate your account</h1>
        {isLoading ? (
          <LoadingSpinner isAbsolute={false} withWrapper={false} />
        ) : (
          <>
            <form className={styles.card}>
              <div className={styles.inputWrap}>
                {/* if prefilled email from param exists, disable email input otherwise allow user to enter email */}
                {prefilledEmail ? (
                  <Input
                    className={styles.input}
                    errorText={emailError}
                    helpText="This is the email address on your ChowNow account"
                    label="Email Address"
                    type="email"
                    value={prefilledEmail}
                    disabled
                  />
                ) : (
                  <Input
                    className={styles.input}
                    errorText={emailError}
                    helpText="This is the email address on your ChowNow account"
                    label="Email Address"
                    onChange={setEmail}
                    type="email"
                    value={email}
                  />
                )}
              </div>
              <Button
                className={styles.button}
                isFullWidth
                label="Send Verification Code"
                onClick={handleOnLoginClick}
                type="submit"
              />
            </form>
            <div className={styles.note}>
              Already have a ChowNow account?{' '}
              <Link to={'/login'} aria-label="Log in">
                Log in here
              </Link>
              .
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ActivateAccount;
