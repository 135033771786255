import styled, { css } from 'styled-components';
import { ReactComponent as IconCaretUp } from 'images/icon-caret-up.svg';
import { ReactComponent as IconCaretDown } from 'images/icon-caret-down.svg';
import { semanticColors } from 'styles/colors';
import { space } from 'styles/variables';
import { caption } from 'styles/types';

interface StyledTableHeaderProps {
  $shouldTruncate?: boolean;
  $customWidth?: string;
  $isSortable?: boolean;
}

interface StyledIconSwitchProps {
  $sortOrder?: string;
}

export const StyledTableHeader = styled.th<StyledTableHeaderProps>`
  border-bottom: solid 1px;
  box-sizing: border-box;
  font: ${caption.md.x12};
  letter-spacing: 1.2px;
  padding: ${space.x3} 0;
  text-transform: uppercase;

  ${({ $shouldTruncate, $customWidth, $isSortable }) => css`
    ${$shouldTruncate &&
    css`
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: ${$customWidth ? `${$customWidth}px` : 'auto'};
    `}

    ${$isSortable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
  `}
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: ${space.x1};
`;

export const StyledIconCaretUp = styled(IconCaretUp) <StyledIconSwitchProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  path {
    fill: ${({ $sortOrder }) => ($sortOrder === 'asc' ? `${semanticColors.content.primary}` : `${semanticColors.content.secondary}`)};
    transition: fill 0.1s ease-in-out;
  }
`;

export const StyledIconCaretDown = styled(IconCaretDown) <StyledIconSwitchProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  path {
    fill: ${({ $sortOrder }) => ($sortOrder === 'desc' ? `${semanticColors.content.primary}` : `${semanticColors.content.secondary}`)};
    transition: fill 0.3s ease-in-out;
  }
`;
