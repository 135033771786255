import { OrderDetailsV6GetSuccessResponse } from 'types/order-details';
import BASE_FIXTURE from './GET-200-base-fixure';

const CURBSIDE_FIXTURE: OrderDetailsV6GetSuccessResponse = {
  ...BASE_FIXTURE,
  customer: {
    ...BASE_FIXTURE.customer,
    curbside: {
      vehicle: {
        vehicle_type: 'Coupe',
        color: 'Green',
      },
      instructions: null,
    },
  },
  fulfill_method: 'curbside',
};

export default CURBSIDE_FIXTURE;
